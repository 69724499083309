/*
 * Generated by PEG.js 0.10.0.
 *
 * http://pegjs.org/
 */

"use strict";

var util = require("./parserHelpers");

function peg$subclass(child, parent) {
  function ctor() { this.constructor = child; }
  ctor.prototype = parent.prototype;
  child.prototype = new ctor();
}

function peg$SyntaxError(message, expected, found, location) {
  this.message  = message;
  this.expected = expected;
  this.found    = found;
  this.location = location;
  this.name     = "SyntaxError";

  if (typeof Error.captureStackTrace === "function") {
    Error.captureStackTrace(this, peg$SyntaxError);
  }
}

peg$subclass(peg$SyntaxError, Error);

peg$SyntaxError.buildMessage = function(expected, found) {
  var DESCRIBE_EXPECTATION_FNS = {
        literal: function(expectation) {
          return "\"" + literalEscape(expectation.text) + "\"";
        },

        "class": function(expectation) {
          var escapedParts = "",
              i;

          for (i = 0; i < expectation.parts.length; i++) {
            escapedParts += expectation.parts[i] instanceof Array
              ? classEscape(expectation.parts[i][0]) + "-" + classEscape(expectation.parts[i][1])
              : classEscape(expectation.parts[i]);
          }

          return "[" + (expectation.inverted ? "^" : "") + escapedParts + "]";
        },

        any: function(expectation) {
          return "any character";
        },

        end: function(expectation) {
          return "end of input";
        },

        other: function(expectation) {
          return expectation.description;
        }
      };

  function hex(ch) {
    return ch.charCodeAt(0).toString(16).toUpperCase();
  }

  function literalEscape(s) {
    return s
      .replace(/\\/g, '\\\\')
      .replace(/"/g,  '\\"')
      .replace(/\0/g, '\\0')
      .replace(/\t/g, '\\t')
      .replace(/\n/g, '\\n')
      .replace(/\r/g, '\\r')
      .replace(/[\x00-\x0F]/g,          function(ch) { return '\\x0' + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return '\\x'  + hex(ch); });
  }

  function classEscape(s) {
    return s
      .replace(/\\/g, '\\\\')
      .replace(/\]/g, '\\]')
      .replace(/\^/g, '\\^')
      .replace(/-/g,  '\\-')
      .replace(/\0/g, '\\0')
      .replace(/\t/g, '\\t')
      .replace(/\n/g, '\\n')
      .replace(/\r/g, '\\r')
      .replace(/[\x00-\x0F]/g,          function(ch) { return '\\x0' + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return '\\x'  + hex(ch); });
  }

  function describeExpectation(expectation) {
    return DESCRIBE_EXPECTATION_FNS[expectation.type](expectation);
  }

  function describeExpected(expected) {
    var descriptions = new Array(expected.length),
        i, j;

    for (i = 0; i < expected.length; i++) {
      descriptions[i] = describeExpectation(expected[i]);
    }

    descriptions.sort();

    if (descriptions.length > 0) {
      for (i = 1, j = 1; i < descriptions.length; i++) {
        if (descriptions[i - 1] !== descriptions[i]) {
          descriptions[j] = descriptions[i];
          j++;
        }
      }
      descriptions.length = j;
    }

    switch (descriptions.length) {
      case 1:
        return descriptions[0];

      case 2:
        return descriptions[0] + " or " + descriptions[1];

      default:
        return descriptions.slice(0, -1).join(", ")
          + ", or "
          + descriptions[descriptions.length - 1];
    }
  }

  function describeFound(found) {
    return found ? "\"" + literalEscape(found) + "\"" : "end of input";
  }

  return "Expected " + describeExpected(expected) + " but " + describeFound(found) + " found.";
};

function peg$parse(input, options) {
  options = options !== void 0 ? options : {};

  var peg$FAILED = {},

      peg$startRuleFunctions = { StatusEffect: peg$parseStatusEffect, LegendMateriaEffect: peg$parseLegendMateriaEffect },
      peg$startRuleFunction  = peg$parseStatusEffect,

      peg$c0 = ",",
      peg$c1 = peg$literalExpectation(",", false),
      peg$c2 = ".",
      peg$c3 = peg$literalExpectation(".", false),
      peg$c4 = function(head, tail) {
          const result = util.pegList(head, tail, 2).filter(i => i != null);
          util.separateStatusAndSb(result);
          util.checkSelfSkillTrigger(result);
          return result;
        },
      peg$c5 = "",
      peg$c6 = function() { return []; },
      peg$c7 = /^[+\-]/,
      peg$c8 = peg$classExpectation(["+", "-"], false, false),
      peg$c9 = "?",
      peg$c10 = peg$literalExpectation("?", false),
      peg$c11 = function(stats) { return NaN; },
      peg$c12 = "%",
      peg$c13 = peg$literalExpectation("%", false),
      peg$c14 = "(ignoring the buff stacking caps)",
      peg$c15 = peg$literalExpectation("(ignoring the buff stacking caps)", false),
      peg$c16 = "for",
      peg$c17 = peg$literalExpectation("for", false),
      peg$c18 = function(stats, value, ignoreBuffCaps, condition) {
          const result = { type: 'statMod', stats, value, condition };
          if (ignoreBuffCaps) {
            result.ignoreBuffCaps = true;
          }
          return result;
        },
      peg$c19 = "+",
      peg$c20 = peg$literalExpectation("+", false),
      peg$c21 = "% for each hit taken by damaging attacks, up to +",
      peg$c22 = peg$literalExpectation("% for each hit taken by damaging attacks, up to +", false),
      peg$c23 = function(stat, increment, max) { return { type: 'statBuildup', stat, increment, max, damaged: true }; },
      peg$c24 = "% for each hit dealt with",
      peg$c25 = peg$literalExpectation("% for each hit dealt with", false),
      peg$c26 = "abilities, up to +",
      peg$c27 = peg$literalExpectation("abilities, up to +", false),
      peg$c28 = function(stat, increment, school, max) { return { type: 'statBuildup', stat, increment, max, school }; },
      peg$c29 = "damaging",
      peg$c30 = peg$literalExpectation("damaging", false),
      peg$c31 = "jump",
      peg$c32 = peg$literalExpectation("jump", false),
      peg$c33 = "Mimic",
      peg$c34 = peg$literalExpectation("Mimic", false),
      peg$c35 = "attacks",
      peg$c36 = peg$literalExpectation("attacks", false),
      peg$c37 = "that deal",
      peg$c38 = peg$literalExpectation("that deal", false),
      peg$c39 = "damage",
      peg$c40 = peg$literalExpectation("damage", false),
      peg$c41 = function(stat, increment, requiresDamage, skillType, jump, mimic, e) { return e; },
      peg$c42 = ", up to +",
      peg$c43 = peg$literalExpectation(", up to +", false),
      peg$c44 = function(stat, increment, requiresDamage, skillType, jump, mimic, element, max) { return { type: 'statBuildup', stat, increment, max, skillType, element, requiresDamage: !!requiresDamage, jump: !!jump, mimic: !!mimic }; },
      peg$c45 = "Increases the duration of stat",
      peg$c46 = peg$literalExpectation("Increases the duration of stat", false),
      peg$c47 = "buffs",
      peg$c48 = peg$literalExpectation("buffs", false),
      peg$c49 = "debuffs",
      peg$c50 = peg$literalExpectation("debuffs", false),
      peg$c51 = "by",
      peg$c52 = peg$literalExpectation("by", false),
      peg$c53 = function(what, value) {
          return { type: 'statModDurationUp', what, value };
        },
      peg$c54 = "Increases base",
      peg$c55 = peg$literalExpectation("Increases base", false),
      peg$c56 = "% base",
      peg$c57 = peg$literalExpectation("% base", false),
      peg$c58 = function(dest, value, src) { return { type: 'statShare', src, dest, value }; },
      peg$c59 = "critical chance =",
      peg$c60 = peg$literalExpectation("critical chance =", true),
      peg$c61 = function(value) {
          const result = { type: 'critChance' };
          if (typeof value === 'object' && !Array.isArray(value)) {
            Object.assign(result, value);
          } else {
            result.value = value;
          }
          return result;
        },
      peg$c62 = "Critical hits deal",
      peg$c63 = peg$literalExpectation("Critical hits deal", false),
      peg$c64 = "% more damage (additive with the base critical coefficient)",
      peg$c65 = peg$literalExpectation("% more damage (additive with the base critical coefficient)", false),
      peg$c66 = function(value) { return Object.assign({ type: 'critDamage' }, value); },
      peg$c67 = "hit rate by",
      peg$c68 = peg$literalExpectation("hit rate by", false),
      peg$c69 = function(sign, value) { return { type: 'hitRate', value: sign * value }; },
      peg$c70 = "Increases the chance of inflicting Status by",
      peg$c71 = peg$literalExpectation("Increases the chance of inflicting Status by", false),
      peg$c72 = function(value) { return { type: 'statusChance' , value }; },
      peg$c73 = "Increases the chance of being inflicted with",
      peg$c74 = peg$literalExpectation("Increases the chance of being inflicted with", false),
      peg$c75 = function(status, value) { return { type: 'statusChance', value, status }; },
      peg$c76 = "allows",
      peg$c77 = peg$literalExpectation("Allows", true),
      peg$c78 = "to stack",
      peg$c79 = peg$literalExpectation("to stack", false),
      peg$c80 = ", up to",
      peg$c81 = peg$literalExpectation(", up to", false),
      peg$c82 = function(status, statusWithLevel) { return statusWithLevel.startsWith(status) && statusWithLevel.substring(status.length).match(/^ \d+$/); },
      peg$c83 = function(status, statusWithLevel) { return { type: 'statusStacking', status, level: +statusWithLevel.substring(status.length) }; },
      peg$c84 = "Prevents",
      peg$c85 = peg$literalExpectation("Prevents", false),
      peg$c86 = "once",
      peg$c87 = peg$literalExpectation("once", false),
      peg$c88 = function(head, tail) { return { type: 'preventStatus', status: util.pegList(head, tail, 1) }; },
      peg$c89 = "wait",
      peg$c90 = peg$literalExpectation("Wait", true),
      peg$c91 = "speed x",
      peg$c92 = peg$literalExpectation("speed x", false),
      peg$c93 = function(value) { return { type: 'speed', value }; },
      peg$c94 = "cast",
      peg$c95 = peg$literalExpectation("Cast", true),
      peg$c96 = "speed x999",
      peg$c97 = peg$literalExpectation("speed x999", false),
      peg$c98 = "9",
      peg$c99 = peg$literalExpectation("9", false),
      peg$c100 = function(forAbilities) { return Object.assign({ type: 'instacast' }, forAbilities); },
      peg$c101 = "Cast speed x",
      peg$c102 = peg$literalExpectation("Cast speed x", false),
      peg$c103 = "for [School] attacks, or any attack if no [School] is specified",
      peg$c104 = peg$literalExpectation("for [School] attacks, or any attack if no [School] is specified", false),
      peg$c105 = function(value) {
          return { type: 'castSpeed', school: util.placeholder, value };
        },
      peg$c106 = "speed",
      peg$c107 = peg$literalExpectation("speed", false),
      peg$c108 = function(value, forAbilities) { return Object.assign({ type: 'castSpeed', value }, forAbilities); },
      peg$c109 = "cast speed",
      peg$c110 = peg$literalExpectation("cast speed", false),
      peg$c111 = function(what, value) { return Object.assign({ type: 'castSpeed', value }, what); },
      peg$c112 = "plus x",
      peg$c113 = peg$literalExpectation("plus x", false),
      peg$c114 = "for each",
      peg$c115 = peg$literalExpectation("for each", false),
      peg$c116 = "used for the duration of the status, up to x",
      peg$c117 = peg$literalExpectation("used for the duration of the status, up to x", false),
      peg$c118 = function(value, increment, requiresAttack, max) {
          return { type: 'castSpeedBuildup', value, increment, max, requiresAttack };
        },
      peg$c119 = "begins",
      peg$c120 = peg$literalExpectation("Begins", true),
      peg$c121 = "the round with full ATB gauge",
      peg$c122 = peg$literalExpectation("the round with full ATB gauge", false),
      peg$c123 = function() { return { type: 'fullAtbRoundStart' }; },
      peg$c124 = "increase",
      peg$c125 = peg$literalExpectation("Increase", true),
      peg$c126 = "ATB charge speed by x999",
      peg$c127 = peg$literalExpectation("ATB charge speed by x999", false),
      peg$c128 = function() { return { type: 'instantAtb' }; },
      peg$c129 = "ATB charge speed by x",
      peg$c130 = peg$literalExpectation("ATB charge speed by x", false),
      peg$c131 = function(value) { return { type: 'atbSpeed', value }; },
      peg$c132 = function(what) { return what; },
      peg$c133 = "for BLK, WHT, BLU, SUM or NAT attacks that deal magical damage",
      peg$c134 = peg$literalExpectation("for BLK, WHT, BLU, SUM or NAT attacks that deal magical damage", false),
      peg$c135 = function() { return { magical: true }; },
      peg$c136 = function(skillType) { return { skillType }; },
      peg$c137 = "for abilities that deal",
      peg$c138 = peg$literalExpectation("for abilities that deal", false),
      peg$c139 = function(element) { return { element }; },
      peg$c140 = "for Jump attacks",
      peg$c141 = peg$literalExpectation("for Jump attacks", false),
      peg$c142 = function() { return { jump: true }; },
      peg$c143 = "for magical damage",
      peg$c144 = peg$literalExpectation("for magical damage", false),
      peg$c145 = function(skill) { return { skill }; },
      peg$c146 = "evades",
      peg$c147 = peg$literalExpectation("Evades", true),
      peg$c148 = "the next",
      peg$c149 = peg$literalExpectation("the next", false),
      peg$c150 = "PHY",
      peg$c151 = peg$literalExpectation("PHY", false),
      peg$c152 = "physical, missing HP or fixed damage or NAT",
      peg$c153 = peg$literalExpectation("physical, missing HP or fixed damage or NAT", false),
      peg$c154 = "physical or fractional damage",
      peg$c155 = peg$literalExpectation("physical or fractional damage", false),
      peg$c156 = function(level) { return { type: 'physicalBlink', level: level || 1 }; },
      peg$c157 = "non-PHY, non-NIN",
      peg$c158 = peg$literalExpectation("non-PHY, non-NIN", false),
      peg$c159 = "magical, fractional or missing HP damage",
      peg$c160 = peg$literalExpectation("magical, fractional or missing HP damage", false),
      peg$c161 = function(level) { return { type: 'magicBlink', level: level || 1 }; },
      peg$c162 = "attack",
      peg$c163 = peg$literalExpectation("attack", false),
      peg$c164 = "s",
      peg$c165 = peg$literalExpectation("s", false),
      peg$c166 = "that could be evaded with Physical or Magical Blink, lower priority",
      peg$c167 = peg$literalExpectation("that could be evaded with Physical or Magical Blink, lower priority", false),
      peg$c168 = function(level) { return { type: 'dualBlink', level: level || 1 }; },
      peg$c169 = "reduces",
      peg$c170 = peg$literalExpectation("Reduces", true),
      peg$c171 = "the damage of the next",
      peg$c172 = peg$literalExpectation("the damage of the next", false),
      peg$c173 = "damage to 0",
      peg$c174 = peg$literalExpectation("damage to 0", false),
      peg$c175 = function(element) { return { type: 'elementBlink', element, level: 1 }; },
      peg$c176 = "Reduces",
      peg$c177 = peg$literalExpectation("Reduces", false),
      peg$c178 = "damage taken to 0, up to an amount",
      peg$c179 = peg$literalExpectation("damage taken to 0, up to an amount", false),
      peg$c180 = "of damage",
      peg$c181 = peg$literalExpectation("of damage", false),
      peg$c182 = "equal to",
      peg$c183 = peg$literalExpectation("equal to", false),
      peg$c184 = "% of the character's maximum HP",
      peg$c185 = peg$literalExpectation("% of the character's maximum HP", false),
      peg$c186 = function(element, value) {
          return { type: 'stoneskin', element, value };
        },
      peg$c187 = "% of the Magicite's maximum HP",
      peg$c188 = peg$literalExpectation("% of the Magicite's maximum HP", false),
      peg$c189 = function(element, value) {
          return { type: 'magiciteStoneskin', element, value };
        },
      peg$c190 = "Reduces damage taken from",
      peg$c191 = peg$literalExpectation("Reduces damage taken from", false),
      peg$c192 = "attacks to 0, up to",
      peg$c193 = peg$literalExpectation("attacks to 0, up to", false),
      peg$c194 = function(skillType, damage) {
          return { type: 'fixedStoneskin', skillType, damage };
        },
      peg$c195 = "Reduces damage taken by",
      peg$c196 = peg$literalExpectation("Reduces damage taken by", false),
      peg$c197 = "% for the next",
      peg$c198 = peg$literalExpectation("% for the next", false),
      peg$c199 = function(value, count) { return count; },
      peg$c200 = function(value) { return 1; },
      peg$c201 = function(value, attackCount) { return { type: 'damageBarrier', value, attackCount }; },
      peg$c202 = "returns",
      peg$c203 = peg$literalExpectation("Returns", true),
      peg$c204 = "the damage taken to the attacker",
      peg$c205 = peg$literalExpectation("the damage taken to the attacker", false),
      peg$c206 = "as",
      peg$c207 = peg$literalExpectation("as", false),
      peg$c208 = function(value, e) { return e; },
      peg$c209 = "capped at 99999",
      peg$c210 = peg$literalExpectation("capped at 99999", false),
      peg$c211 = function(value, element, overflow) {
          return { type: 'radiantShield', value, element, overflow: !!overflow };
        },
      peg$c212 = "all",
      peg$c213 = peg$literalExpectation("all", false),
      peg$c214 = "of",
      peg$c215 = peg$literalExpectation("of", false),
      peg$c216 = function() { return 100; },
      peg$c217 = "% of",
      peg$c218 = peg$literalExpectation("% of", false),
      peg$c219 = function(value) { return value; },
      peg$c220 = "Redirect single-target BLK and WHT attacks to a random member of the opposite group",
      peg$c221 = peg$literalExpectation("Redirect single-target BLK and WHT attacks to a random member of the opposite group", false),
      peg$c222 = function() { return { type: 'reflect' }; },
      peg$c223 = "abilities",
      peg$c224 = peg$literalExpectation("abilities", false),
      peg$c225 = "don't consume uses",
      peg$c226 = peg$literalExpectation("don't consume uses", false),
      peg$c227 = function(awoken, rankBoost, rankCast, dualcast, instacast, castSpeed) { return !rankCast || util.isEqual(awoken, rankCast); },
      peg$c228 = function(awoken, rankBoost, rankCast, dualcast, instacast, castSpeed) { return !dualcast || util.isEqual(awoken, dualcast); },
      peg$c229 = function(awoken, rankBoost, rankCast, dualcast, instacast, castSpeed) { return !instacast || util.isEqual(awoken, instacast); },
      peg$c230 = function(awoken, rankBoost, rankCast, dualcast, instacast, castSpeed) { return !castSpeed || util.isEqual(awoken, castSpeed.type); },
      peg$c231 = function(awoken, rankBoost, rankCast, dualcast, instacast, castSpeed) { return { type: 'awoken', awoken, rankBoost: !!rankBoost, rankCast: !!rankCast, dualcast: !!dualcast, instacast: !!instacast, castSpeed: castSpeed ? castSpeed.value : undefined }; },
      peg$c232 = function(school) { return { school }; },
      peg$c233 = "and deal 5/10/15/20/30% more damage at ability rank 1/2/3/4/5",
      peg$c234 = peg$literalExpectation("and deal 5/10/15/20/30% more damage at ability rank 1/2/3/4/5", false),
      peg$c235 = ", cast speed x2.00/2.25/2.50/2.75/3.00 for",
      peg$c236 = peg$literalExpectation(", cast speed x2.00/2.25/2.50/2.75/3.00 for", false),
      peg$c237 = "abilities at ability rank 1/2/3/4/5",
      peg$c238 = peg$literalExpectation("abilities at ability rank 1/2/3/4/5", false),
      peg$c239 = function(type) { return type; },
      peg$c240 = ", dualcasts",
      peg$c241 = peg$literalExpectation(", dualcasts", false),
      peg$c242 = ", cast speed x999",
      peg$c243 = peg$literalExpectation(", cast speed x999", false),
      peg$c244 = ", cast speed x",
      peg$c245 = peg$literalExpectation(", cast speed x", false),
      peg$c246 = function(value, type) { return { type, value }; },
      peg$c247 = function(head, tail) { return { type: 'switchDraw', elements: util.pegList(head, tail, 2) }; },
      peg$c248 = "grants",
      peg$c249 = peg$literalExpectation("Grants", true),
      peg$c250 = "[Attach",
      peg$c251 = peg$literalExpectation("[Attach", false),
      peg$c252 = "] after using a",
      peg$c253 = peg$literalExpectation("] after using a", false),
      peg$c254 = "n",
      peg$c255 = peg$literalExpectation("n", false),
      peg$c256 = "ability",
      peg$c257 = peg$literalExpectation("ability", false),
      peg$c258 = function(element1, element2) { return element1 === element2; },
      peg$c259 = function(element1, element2) { return element1; },
      peg$c260 = "after using a",
      peg$c261 = peg$literalExpectation("after using a", false),
      peg$c262 = function(elements1, elements2) { return elements1.length > 1 && util.isEqual(elements1, elements2); },
      peg$c263 = function(elements1, elements2) { return { type: 'switchDraw', elements: elements1 }; },
      peg$c264 = function(elements1, elements2) { return Array.isArray(elements1.elements) && elements1.elements.length > 1 && util.isEqual(elements1.elements, elements2); },
      peg$c265 = function(elements1, elements2) { return { type: 'switchDrawStacking', elements: elements1.elements, level: elements1.level }; },
      peg$c266 = "damage dealt by",
      peg$c267 = peg$literalExpectation("damage dealt by", false),
      peg$c268 = ", cumulable",
      peg$c269 = peg$literalExpectation(", cumulable", false),
      peg$c270 = function(sign, element, value, trigger) {
          return { type: 'elementAttack', element, value: util.scalarify(util.arrayify(value).map(i => i * sign)), trigger };
        },
      peg$c271 = "resistance",
      peg$c272 = peg$literalExpectation("Resistance", true),
      peg$c273 = function(element, value) { return { type: 'elementResist', element, value }; },
      peg$c274 = "Replaces Attack command, increases",
      peg$c275 = peg$literalExpectation("Replaces Attack command, increases", false),
      peg$c276 = "damage dealt by 50/80/120% (abilities) or 80/100/120% (Soul Breaks),",
      peg$c277 = peg$literalExpectation("damage dealt by 50/80/120% (abilities) or 80/100/120% (Soul Breaks),", false),
      peg$c278 = "resistance +20%",
      peg$c279 = peg$literalExpectation("resistance +20%", false),
      peg$c280 = function(element, element2) {
          return { type: 'enElement', element };
        },
      peg$c281 = "Allow to stack Attach",
      peg$c282 = peg$literalExpectation("Allow to stack Attach", false),
      peg$c283 = ", up to Attach",
      peg$c284 = peg$literalExpectation(", up to Attach", false),
      peg$c285 = "3",
      peg$c286 = peg$literalExpectation("3", false),
      peg$c287 = function(element, element2) {
          return { type: 'enElementStacking', element };
        },
      peg$c288 = "Increase Attach",
      peg$c289 = peg$literalExpectation("Increase Attach", false),
      peg$c290 = "Level by",
      peg$c291 = peg$literalExpectation("Level by", false),
      peg$c292 = "and increase Max Attach Element Level by 2, up to Attach",
      peg$c293 = peg$literalExpectation("and increase Max Attach Element Level by 2, up to Attach", false),
      peg$c294 = function(element, level, element2) {
          return { type: 'enElementWithStacking', element, level };
        },
      peg$c295 = "Decrease Attach",
      peg$c296 = peg$literalExpectation("Decrease Attach", false),
      peg$c297 = function(element, level) { return { type: 'loseEnElement', element, level }; },
      peg$c298 = "Decrease any attached element's level by",
      peg$c299 = peg$literalExpectation("Decrease any attached element's level by", false),
      peg$c300 = function(level) { return { type: 'loseEnElement', level }; },
      peg$c301 = "abilities deal",
      peg$c302 = peg$literalExpectation("abilities deal", false),
      peg$c303 = "% more damage for each",
      peg$c304 = peg$literalExpectation("% more damage for each", false),
      peg$c305 = "ability used, up to +",
      peg$c306 = peg$literalExpectation("ability used, up to +", false),
      peg$c307 = function(school, increment, schoolUsed, max) { return school === schoolUsed; },
      peg$c308 = function(school, increment, schoolUsed, max) { return { type: 'abilityBuildup', school, schoolUsed, increment, max }; },
      peg$c309 = "deal 5/10/15/20/30% more damage at ability rank 1/2/3/4/5",
      peg$c310 = peg$literalExpectation("deal 5/10/15/20/30% more damage at ability rank 1/2/3/4/5", false),
      peg$c311 = function(what) { return Object.assign({ type: 'rankBoost' }, what); },
      peg$c312 = "deal",
      peg$c313 = peg$literalExpectation("deal", false),
      peg$c314 = "more damage",
      peg$c315 = peg$literalExpectation("more damage", false),
      peg$c316 = function(what, value, trigger, condition) {
          return Object.assign({ type: 'damageUp', value, trigger, condition }, what);
        },
      peg$c317 = "increases",
      peg$c318 = peg$literalExpectation("Increases", true),
      peg$c319 = function(skillType, value) { return { type: 'damageUp', skillType, value }; },
      peg$c320 = function(element, value, trigger, condition) { return { type: 'damageUp', element, value, trigger, condition }; },
      peg$c321 = "% when exploiting elemental weakness",
      peg$c322 = peg$literalExpectation("% when exploiting elemental weakness", false),
      peg$c323 = "es",
      peg$c324 = peg$literalExpectation("es", false),
      peg$c325 = function(value) { return { type: 'damageUp', vsWeak: true, value }; },
      peg$c326 = function(value, condition) { return { type: 'damageUp', value, condition }; },
      peg$c327 = "attacks by",
      peg$c328 = peg$literalExpectation("attacks by", false),
      peg$c329 = function(element, value) { return { type: 'damageResist', value, element }; },
      peg$c330 = function(skillType, value) { return { type: 'damageResist', value, skillType }; },
      peg$c331 = "% chance to reduce damage taken by",
      peg$c332 = peg$literalExpectation("% chance to reduce damage taken by", false),
      peg$c333 = function(chance, value, condition) { return { type: 'damageResist', value, chance, condition }; },
      peg$c334 = "Attacks dealt by",
      peg$c335 = peg$literalExpectation("Attacks dealt by", false),
      peg$c336 = "realm members deal",
      peg$c337 = peg$literalExpectation("realm members deal", false),
      peg$c338 = "% more damage",
      peg$c339 = peg$literalExpectation("% more damage", false),
      peg$c340 = function(realm, value) { return { type: 'realmBoost', realm, value }; },
      peg$c341 = "dualcasts",
      peg$c342 = peg$literalExpectation("dualcasts", true),
      peg$c343 = "consuming an extra ability use",
      peg$c344 = peg$literalExpectation("consuming an extra ability use", false),
      peg$c345 = function(what) { return Object.assign({ type: 'abilityDouble' }, what); },
      peg$c346 = function(count, what) { return Object.assign({ type: 'multicast', count, chance: 100 }, what); },
      peg$c347 = "% chance to",
      peg$c348 = peg$literalExpectation("% chance to", false),
      peg$c349 = function(chance, chanceIsUncertain, count, what) {
          return Object.assign({ type: 'multicast', count, chance }, what, chanceIsUncertain ? { chanceIsUncertain: true } : undefined);
        },
      peg$c350 = "abilities that deal",
      peg$c351 = peg$literalExpectation("abilities that deal", false),
      peg$c352 = function(chance, count, element) { return { type: 'multicast', count, chance, element }; },
      peg$c353 = "every",
      peg$c354 = peg$literalExpectation("every", false),
      peg$c355 = function(chance, count, perUses, what) { return Object.assign({ type: 'multicast', count, chance, perUses }, what); },
      peg$c356 = function(count, what) {
          return Object.assign({ type: 'multicastAbility', count }, what);
        },
      peg$c357 = peg$literalExpectation("cast", false),
      peg$c358 = function(count) { return count; },
      peg$c359 = "changes",
      peg$c360 = peg$literalExpectation("Changes", true),
      peg$c361 = "the air time of Jump attacks to 0.01 seconds",
      peg$c362 = peg$literalExpectation("the air time of Jump attacks to 0.01 seconds", false),
      peg$c363 = function() { return { type: 'noAirTime' }; },
      peg$c364 = "physical",
      peg$c365 = peg$literalExpectation("physical", true),
      peg$c366 = function() { return { skillType: 'PHY' }; },
      peg$c367 = "magical",
      peg$c368 = peg$literalExpectation("magical", true),
      peg$c369 = peg$literalExpectation("jump", true),
      peg$c370 = "mimic",
      peg$c371 = peg$literalExpectation("mimic", true),
      peg$c372 = function() { return { mimic: true }; },
      peg$c373 = "sets",
      peg$c374 = peg$literalExpectation("Sets", true),
      peg$c375 = "damage cap to 99999 for all attacks",
      peg$c376 = peg$literalExpectation("damage cap to 99999 for all attacks", false),
      peg$c377 = function() { return { type: 'breakDamageCap' }; },
      peg$c378 = "the damage cap for",
      peg$c379 = peg$literalExpectation("the damage cap for", false),
      peg$c380 = "attacks to 99999",
      peg$c381 = peg$literalExpectation("attacks to 99999", false),
      peg$c382 = function(skillType, what) { return Object.assign({ type: 'breakDamageCap', skillType }, what); },
      peg$c383 = "Increases the damage",
      peg$c384 = peg$literalExpectation("Increases the damage", false),
      peg$c385 = "/healing",
      peg$c386 = peg$literalExpectation("/healing", false),
      peg$c387 = "cap by",
      peg$c388 = peg$literalExpectation("cap by", false),
      peg$c389 = function(value) { return { type: 'damageCap', value }; },
      peg$c390 = "automatically",
      peg$c391 = peg$literalExpectation("Automatically", true),
      peg$c392 = "restores HP, up to",
      peg$c393 = peg$literalExpectation("restores HP, up to", false),
      peg$c394 = "HP",
      peg$c395 = peg$literalExpectation("HP", false),
      peg$c396 = function(value) { return Object.assign({ type: 'hpStock' }, value); },
      peg$c397 = "heals",
      peg$c398 = peg$literalExpectation("Heals", true),
      peg$c399 = "% max HP every",
      peg$c400 = peg$literalExpectation("% max HP every", false),
      peg$c401 = function(percentHp, interval) { return { type: 'regen', percentHp, interval }; },
      peg$c402 = "HP every",
      peg$c403 = peg$literalExpectation("HP every", false),
      peg$c404 = function(value, interval) { return { type: 'fixedHpRegen', value, interval }; },
      peg$c405 = "Damages for",
      peg$c406 = peg$literalExpectation("Damages for", false),
      peg$c407 = "max HP every",
      peg$c408 = peg$literalExpectation("max HP every", false),
      peg$c409 = function(fractionHp, interval) { return { type: 'poison', fractionHp, interval }; },
      peg$c410 = peg$literalExpectation("Abilities", true),
      peg$c411 = "restore",
      peg$c412 = peg$literalExpectation("restore", false),
      peg$c413 = "% more HP",
      peg$c414 = peg$literalExpectation("% more HP", false),
      peg$c415 = function(value) { return { type: 'healUp', value }; },
      peg$c416 = "healing done by",
      peg$c417 = peg$literalExpectation("healing done by", false),
      peg$c418 = "abilities by",
      peg$c419 = peg$literalExpectation("abilities by", false),
      peg$c420 = function(s) { return s; },
      peg$c421 = function(school, value) { return { type: 'healUp', value, school }; },
      peg$c422 = "abilities and soulbreaks by",
      peg$c423 = peg$literalExpectation("abilities and soulbreaks by", false),
      peg$c424 = function(skillType, value) { return { type: 'healUp', value, skillType }; },
      peg$c425 = "abilities restore",
      peg$c426 = peg$literalExpectation("abilities restore", false),
      peg$c427 = "Take",
      peg$c428 = peg$literalExpectation("Take", false),
      peg$c429 = function(value) { return { type: 'pain', value }; },
      peg$c430 = "damage taken by",
      peg$c431 = peg$literalExpectation("damage taken by", false),
      peg$c432 = function(sign, value) { return { type: 'damageTaken', value: sign * value }; },
      peg$c433 = "Healing restores",
      peg$c434 = peg$literalExpectation("Healing restores", false),
      peg$c435 = "% less HP",
      peg$c436 = peg$literalExpectation("% less HP", false),
      peg$c437 = function(value) { return { type: 'barHeal', value }; },
      peg$c438 = "healing received by",
      peg$c439 = peg$literalExpectation("healing received by", false),
      peg$c440 = function(value) { return { type: 'empowerHeal', value }; },
      peg$c441 = "second",
      peg$c442 = peg$literalExpectation("second", false),
      peg$c443 = function() { return 1; },
      peg$c444 = "seconds",
      peg$c445 = peg$literalExpectation("seconds", false),
      peg$c446 = function(interval) { return interval; },
      peg$c447 = "HP = 0 when set",
      peg$c448 = peg$literalExpectation("HP = 0 when set", false),
      peg$c449 = function() { return { type: 'ko' }; },
      peg$c450 = "KO'd, can't act, persists after battle",
      peg$c451 = peg$literalExpectation("KO'd, can't act, persists after battle", false),
      peg$c452 = "Prevents KO once, restoring HP for 1% maximum HP",
      peg$c453 = peg$literalExpectation("Prevents KO once, restoring HP for 1% maximum HP", false),
      peg$c454 = function() { return { type: 'lastStand' }; },
      peg$c455 = "Removes KO (",
      peg$c456 = peg$literalExpectation("Removes KO (", false),
      peg$c457 = "% HP)",
      peg$c458 = peg$literalExpectation("% HP)", false),
      peg$c459 = function(value) { return { type: 'raise', value }; },
      peg$c460 = "Automatically removes KO (",
      peg$c461 = peg$literalExpectation("Automatically removes KO (", false),
      peg$c462 = "% HP) once",
      peg$c463 = peg$literalExpectation("% HP) once", false),
      peg$c464 = function(value) { return { type: 'reraise', value }; },
      peg$c465 = "Causes Doom with a",
      peg$c466 = peg$literalExpectation("Causes Doom with a", false),
      peg$c467 = "seconds timer",
      peg$c468 = peg$literalExpectation("seconds timer", false),
      peg$c469 = function(timer) { return { type: 'doom', timer }; },
      peg$c470 = "the character's Doom timer by",
      peg$c471 = peg$literalExpectation("the character's Doom timer by", false),
      peg$c472 = "when set",
      peg$c473 = peg$literalExpectation("when set", false),
      peg$c474 = function(sign, value) { return { type: 'doomTimer', value: value * sign }; },
      peg$c475 = "restores",
      peg$c476 = peg$literalExpectation("Restores", true),
      peg$c477 = "HP for",
      peg$c478 = peg$literalExpectation("HP for", false),
      peg$c479 = "% of the damage dealt with",
      peg$c480 = peg$literalExpectation("% of the damage dealt with", false),
      peg$c481 = function(value, what) { return Object.assign({type: 'drainHp', value }, what); },
      peg$c482 = "% chance of restoring HP to the user for",
      peg$c483 = peg$literalExpectation("% chance of restoring HP to the user for", false),
      peg$c484 = "single-target",
      peg$c485 = peg$literalExpectation("single-target", false),
      peg$c486 = function(chance, value, singleTarget, what) {
          return Object.assign({type: 'drainHp', value, chance, singleTarget: !!singleTarget }, what);
        },
      peg$c487 = "enemy",
      peg$c488 = peg$literalExpectation("enemy", false),
      peg$c489 = "attacks with",
      peg$c490 = peg$literalExpectation("attacks with", false),
      peg$c491 = function(when, enemy, skillType, counter) {
          return Object.assign({ type: 'counter', skillType, enemyOnly: !!enemy, counter }, when);
        },
      peg$c492 = "counters",
      peg$c493 = peg$literalExpectation("counters", true),
      peg$c494 = function() { return {}; },
      peg$c495 = "% chance of countering",
      peg$c496 = peg$literalExpectation("% chance of countering", false),
      peg$c497 = "% chance to counter",
      peg$c498 = peg$literalExpectation("% chance to counter", false),
      peg$c499 = function(chance) { return { chance }; },
      peg$c500 = "Attack",
      peg$c501 = peg$literalExpectation("Attack", false),
      peg$c502 = function() { return undefined; },
      peg$c503 = function(skill) { return { type: 'skill', skill }; },
      peg$c504 = "an ability (single,",
      peg$c505 = peg$literalExpectation("an ability (single,", false),
      peg$c506 = peg$literalExpectation("physical", false),
      peg$c507 = peg$literalExpectation("magical", false),
      peg$c508 = ")",
      peg$c509 = peg$literalExpectation(")", false),
      peg$c510 = function(attackMultiplier, damageType) {
          const overrideSkillType = damageType === 'physical' ? 'PHY' : 'BLK';
          return { type: 'attack', numAttacks: 1, attackMultiplier, overrideSkillType };
        },
      peg$c511 = "an ability (",
      peg$c512 = peg$literalExpectation("an ability (", false),
      peg$c513 = function(simpleSkill) {
          return { type: 'simpleSkill', simpleSkill };
        },
      peg$c514 = "While front row,",
      peg$c515 = peg$literalExpectation("While front row,", false),
      peg$c516 = "% chance to cover",
      peg$c517 = peg$literalExpectation("% chance to cover", false),
      peg$c518 = "attacks that target",
      peg$c519 = peg$literalExpectation("attacks that target", false),
      peg$c520 = ", reducing damage taken by",
      peg$c521 = peg$literalExpectation(", reducing damage taken by", false),
      peg$c522 = function(needsFront, chance, skillType, who, damageReduce) {
          return { type: 'cover', chance, skillType, damageReduce, who, needsFront: needsFront ? true : undefined };
        },
      peg$c523 = "allies",
      peg$c524 = peg$literalExpectation("allies", false),
      peg$c525 = "back row allies",
      peg$c526 = peg$literalExpectation("back row allies", false),
      peg$c527 = function() { return 'backRow'; },
      peg$c528 = function(immune, counter) { return util.isEqual(immune.skillType, counter.skillType) && !immune.ranged && !immune.nonRanged; },
      peg$c529 = function(immune, counter) { return Object.assign(counter, { immune: true }); },
      peg$c530 = "and",
      peg$c531 = peg$literalExpectation("and", false),
      peg$c532 = function(chance, head, tail, trigger, triggerDetail, condition, tail2, o) { return o; },
      peg$c533 = ". Only one of these effects can trigger at a time",
      peg$c534 = peg$literalExpectation(". Only one of these effects can trigger at a time", false),
      peg$c535 = function(chance, head, tail, trigger, triggerDetail, condition, tail2, onceOnly, exclusive) {
          wantInfinitive[0] = false;

          // Validate that what we think is a "once only" effect actually is - if it refers to a
          // different skill, then it's not actually "once only."
          if (!onceOnly || !onceOnly.skill) {
            return true;
          }
          const castSkill = head.type === 'castSkill' ? head : tail.find(i => i.type === 'castSkill');
          return castSkill && castSkill.type === 'castSkill' && castSkill.skill === onceOnly.skill;
        },
      peg$c536 = function(chance, head, tail, trigger, triggerDetail, condition, tail2, onceOnly, exclusive) {
          return util.addCondition({
            type: 'triggeredEffect',
            ...chance,
            effects: util.pegMultiList(head, [[tail, 3], [tail2, 2]], true),
            trigger,
            onceOnly: onceOnly == null ? undefined : onceOnly.onceOnly,
            triggerDetail,
            exclusive: !!exclusive
          }, condition);
        },
      peg$c537 = function(trigger, head, tail, triggerDetail) {
          return { type: 'triggeredEffect', trigger, effects: util.pegList(head, tail, 2), triggerDetail };
        },
      peg$c538 = "Triggers the following effects",
      peg$c539 = peg$literalExpectation("Triggers the following effects", false),
      peg$c540 = ":",
      peg$c541 = peg$literalExpectation(":", false),
      peg$c542 = "% chance of",
      peg$c543 = peg$literalExpectation("% chance of", false),
      peg$c544 = function(chance, status, who, trigger) { return !who || who === 'self'; },
      peg$c545 = function(chance, status, who, trigger) {
          return { type: 'triggeredEffect', chance, effects: [status], trigger };
        },
      peg$c546 = function(chance, chanceIsUncertain) {
          wantInfinitive[0] = true;
          return { chance, chanceIsUncertain: chanceIsUncertain ? true : undefined };
        },
      peg$c547 = function(effect) { return effect; },
      peg$c548 = peg$literalExpectation("cast", true),
      peg$c549 = function(skill) { return { type: 'castSkill', skill }; },
      peg$c550 = "randomly",
      peg$c551 = peg$literalExpectation("randomly", true),
      peg$c552 = "casts",
      peg$c553 = peg$literalExpectation("casts", false),
      peg$c554 = function(skill) { return { type: 'randomCastSkill', skill }; },
      peg$c555 = function(skill) { return { type: 'castSimpleSkill', skill }; },
      peg$c556 = "removes",
      peg$c557 = peg$literalExpectation("removes", true),
      peg$c558 = "from the user",
      peg$c559 = peg$literalExpectation("from the user", false),
      peg$c560 = function(status) {
          return { type: 'grantStatus', status: { status: { type: 'standardStatus', name: status } }, verb: 'removes' };
        },
      peg$c561 = function(verb, statuses, statusClauses) {
          const result = { type: 'grantStatus', status: statuses, verb };
          for (const i of statusClauses) {
            Object.assign(result, i);
          }
          if (result.duration) {
            util.applyDuration(result.status, result.duration);
          }
          return result;
        },
      peg$c562 = "causes",
      peg$c563 = peg$literalExpectation("Causes", true),
      peg$c564 = function(statuses, duration) {
          const result = { type: 'directGrantStatus', status: statuses };
          if (duration) {
            util.applyDuration(result.status, duration);
          }
          return result;
        },
      peg$c565 = peg$literalExpectation("restore", true),
      peg$c566 = function(fixedHp, who) { return { type: 'heal', fixedHp, who }; },
      peg$c567 = "the user's",
      peg$c568 = peg$literalExpectation("the user's", false),
      peg$c569 = "the target's",
      peg$c570 = peg$literalExpectation("the target's", false),
      peg$c571 = "their",
      peg$c572 = peg$literalExpectation("their", false),
      peg$c573 = function(who, healPercent) {
          return {
            type: 'healPercent',
            healPercent,
            who,
          }
        },
      peg$c574 = "damages",
      peg$c575 = peg$literalExpectation("damages", false),
      peg$c576 = "the",
      peg$c577 = peg$literalExpectation("the", false),
      peg$c578 = "user",
      peg$c579 = peg$literalExpectation("user", false),
      peg$c580 = "current",
      peg$c581 = peg$literalExpectation("current", false),
      peg$c582 = function(damagePercent) { return text().startsWith('max') ? 'max' : 'curr'; },
      peg$c583 = function(damagePercent, maxOrCurrent) {
          return {
            type: 'recoilHp',
            damagePercent,
            maxOrCurrent,
          };
        },
      peg$c584 = "remove",
      peg$c585 = peg$literalExpectation("remove", true),
      peg$c586 = "negative",
      peg$c587 = peg$literalExpectation("negative", false),
      peg$c588 = "positive",
      peg$c589 = peg$literalExpectation("positive", false),
      peg$c590 = "status",
      peg$c591 = peg$literalExpectation("status", false),
      peg$c592 = "effects",
      peg$c593 = peg$literalExpectation("effects", false),
      peg$c594 = function(dispelOrEsuna, who, perUses) {
          return { type: 'dispelOrEsuna', dispelOrEsuna, who, perUses };
        },
      peg$c595 = peg$literalExpectation("casts", true),
      peg$c596 = "the last ability used by any ally",
      peg$c597 = peg$literalExpectation("the last ability used by any ally", false),
      peg$c598 = function(occurrence) {
          return {
            type: 'mimic',
            count: occurrence,
          };
        },
      peg$c599 = "% chance to remove",
      peg$c600 = peg$literalExpectation("% chance to remove", false),
      peg$c601 = function(chance) { return text().trim().split(/, | and /); },
      peg$c602 = "to the user after being afflicted with",
      peg$c603 = peg$literalExpectation("to the user after being afflicted with", false),
      peg$c604 = function(chance, status1) { return text().trim().split(/, | or /); },
      peg$c605 = function(chance, status1, status2) { return util.isEqual(status1, status2); },
      peg$c606 = function(chance, status1, status2) { return { type: 'autoCure', chance, status: status1 }; },
      peg$c607 = function(status) { return status.condition != null },
      peg$c608 = function(status) { return Object.assign(status, { type: 'conditionalStatus' } ); },
      peg$c609 = "SB points",
      peg$c610 = peg$literalExpectation("SB points", false),
      peg$c611 = function(value) { return { type: 'gainSb', value }; },
      peg$c612 = peg$literalExpectation("Removes", true),
      peg$c613 = function(value) { return { type: 'gainSb', value: -value }; },
      peg$c614 = "grant",
      peg$c615 = peg$literalExpectation("grant", false),
      peg$c616 = "% more SB points",
      peg$c617 = peg$literalExpectation("% more SB points", false),
      peg$c618 = function(what, value) { return Object.assign({ type: 'sbGainUp', value }, what); },
      peg$c619 = peg$literalExpectation("Attacks", true),
      peg$c620 = "that exploit an elemental weakness grant",
      peg$c621 = peg$literalExpectation("that exploit an elemental weakness grant", false),
      peg$c622 = function(value) { return { type: 'sbGainUp', value, vsWeak: true }; },
      peg$c623 = function(value) { return { type: 'sbGainUp', value }; },
      peg$c624 = "exploiting",
      peg$c625 = peg$literalExpectation("Exploiting", true),
      peg$c626 = "elemental weakness grants",
      peg$c627 = peg$literalExpectation("elemental weakness grants", false),
      peg$c628 = "% more Soul Break points (additive with the default 50% bonus)",
      peg$c629 = peg$literalExpectation("% more Soul Break points (additive with the default 50% bonus)", false),
      peg$c630 = "LB points",
      peg$c631 = peg$literalExpectation("LB points", false),
      peg$c632 = function(value) { return { type: 'gainLb', value }; },
      peg$c633 = function(value) { return { type: 'gainLb', value: -value }; },
      peg$c634 = "taunts",
      peg$c635 = peg$literalExpectation("Taunts", true),
      peg$c636 = function(skillType) { return { type: 'taunt', skillType }; },
      peg$c637 = ", absorbs",
      peg$c638 = peg$literalExpectation(", absorbs", true),
      peg$c639 = "attacks to restore 1 consumed ability use",
      peg$c640 = peg$literalExpectation("attacks to restore 1 consumed ability use", false),
      peg$c641 = function(taunt, skillType) { return util.isEqual(taunt.skillType, skillType); },
      peg$c642 = function(taunt, skillType) { return { type: 'runic', skillType }; },
      peg$c643 = "can't",
      peg$c644 = peg$literalExpectation("Can't", true),
      peg$c645 = "be hit by",
      peg$c646 = peg$literalExpectation("be hit by", false),
      peg$c647 = "ranged",
      peg$c648 = peg$literalExpectation("ranged", false),
      peg$c649 = "non-ranged",
      peg$c650 = peg$literalExpectation("non-ranged", false),
      peg$c651 = function(ranged, nonRanged, skillType) {
          return {
            type: 'immuneAttacks',
            skillType,
            ranged: !!ranged,
            nonRanged: !!nonRanged,
          }
        },
      peg$c652 = "Can't be hit by any attack",
      peg$c653 = peg$literalExpectation("Can't be hit by any attack", false),
      peg$c654 = function() {
          return {
            type: 'immuneAttacks',
          }
        },
      peg$c655 = "damage received to 0",
      peg$c656 = peg$literalExpectation("damage received to 0", false),
      peg$c657 = function(what) { return { type: 'zeroDamage', what }; },
      peg$c658 = "Reduces to 0 all damage received from non-NIN attacks that deal magical damage and all damage received from BLK, WHT, BLU or SUM attacks",
      peg$c659 = peg$literalExpectation("Reduces to 0 all damage received from non-NIN attacks that deal magical damage and all damage received from BLK, WHT, BLU or SUM attacks", false),
      peg$c660 = function() { return { type: 'zeroDamage', what: 'magical' }; },
      peg$c661 = "Reduces to 0 all damage received from non-NIN attacks that deal physical damage and all damage received from PHY attacks",
      peg$c662 = peg$literalExpectation("Reduces to 0 all damage received from non-NIN attacks that deal physical damage and all damage received from PHY attacks", false),
      peg$c663 = function() { return { type: 'zeroDamage', what: 'physical' }; },
      peg$c664 = "Reduces to 0 all damage received from NIN attacks",
      peg$c665 = peg$literalExpectation("Reduces to 0 all damage received from NIN attacks", false),
      peg$c666 = function() { return { type: 'zeroDamage', what: 'NIN' }; },
      peg$c667 = "all attacks",
      peg$c668 = peg$literalExpectation("all attacks", false),
      peg$c669 = function() { return { type: 'evadeAll' }; },
      peg$c670 = "Multiplies all damage received by",
      peg$c671 = peg$literalExpectation("Multiplies all damage received by", false),
      peg$c672 = function(value) { return Object.assign({ type: 'multiplyDamage' }, value); },
      peg$c673 = "forces",
      peg$c674 = peg$literalExpectation("Forces", true),
      peg$c675 = "default action, affects targeting",
      peg$c676 = peg$literalExpectation("default action, affects targeting", false),
      peg$c677 = ", resets ATB when set or removed",
      peg$c678 = peg$literalExpectation(", resets ATB when set or removed", false),
      peg$c679 = function() { return { type: 'berserk' }; },
      peg$c680 = "Forces a random available action, excluding Defend, affects targeting, Berserk, Confuse, Paralyze, Stop are prioritized",
      peg$c681 = peg$literalExpectation("Forces a random available action, excluding Defend, affects targeting, Berserk, Confuse, Paralyze, Stop are prioritized", false),
      peg$c682 = function() { return { type: 'abilityBerserk' }; },
      peg$c683 = "a specified action, affects targeting, resets ATB when removed",
      peg$c684 = peg$literalExpectation("a specified action, affects targeting, resets ATB when removed", false),
      peg$c685 = function() { return { type: 'rage' }; },
      peg$c686 = "lasts",
      peg$c687 = peg$literalExpectation("lasts", false),
      peg$c688 = "turn",
      peg$c689 = peg$literalExpectation("turn", false),
      peg$c690 = function(value) { return { type: 'turnDuration', duration: { value, units: 'turns' } }; },
      peg$c691 = "removed",
      peg$c692 = peg$literalExpectation("Removed", true),
      peg$c693 = "if",
      peg$c694 = peg$literalExpectation("if", false),
      peg$c695 = "when",
      peg$c696 = peg$literalExpectation("when", false),
      peg$c697 = "hasn't",
      peg$c698 = peg$literalExpectation("hasn't", false),
      peg$c699 = "doesn't have",
      peg$c700 = peg$literalExpectation("doesn't have", false),
      peg$c701 = "any",
      peg$c702 = peg$literalExpectation("any", false),
      peg$c703 = function(any, status) { return { type: 'removedUnlessStatus', any: !!any, status }; },
      peg$c704 = "after triggering",
      peg$c705 = peg$literalExpectation("after triggering", false),
      peg$c706 = "or if",
      peg$c707 = peg$literalExpectation("or if", false),
      peg$c708 = "user hasn't Synchro Mode",
      peg$c709 = peg$literalExpectation("user hasn't Synchro Mode", false),
      peg$c710 = function(count) { return { onceOnly: count || true }; },
      peg$c711 = "after",
      peg$c712 = peg$literalExpectation("after", false),
      peg$c713 = "is cast",
      peg$c714 = peg$literalExpectation("is cast", false),
      peg$c715 = "or if user hasn't Synchro Mode",
      peg$c716 = peg$literalExpectation("or if user hasn't Synchro Mode", false),
      peg$c717 = function(skill, count) { return { onceOnly: count || true, skill }; },
      peg$c718 = "after casting",
      peg$c719 = peg$literalExpectation("after casting", false),
      peg$c720 = "when effect is triggered",
      peg$c721 = peg$literalExpectation("when effect is triggered", false),
      peg$c722 = "once per battle",
      peg$c723 = peg$literalExpectation("once per battle", false),
      peg$c724 = function() {return {onceOnly:true}; },
      peg$c725 = function(trigger) { return { type: 'removedAfterTrigger', trigger }; },
      peg$c726 = "keeps",
      peg$c727 = peg$literalExpectation("Keeps", true),
      peg$c728 = "track of the",
      peg$c729 = peg$literalExpectation("track of the", false),
      peg$c730 = "level, up to level",
      peg$c731 = peg$literalExpectation("level, up to level", false),
      peg$c732 = function(status, max) { return { type: 'trackStatusLevel', status, max, current: util.placeholder }; },
      peg$c733 = "used",
      peg$c734 = peg$literalExpectation("Used", true),
      peg$c735 = "for tracking",
      peg$c736 = peg$literalExpectation("for tracking", false),
      peg$c737 = "level",
      peg$c738 = peg$literalExpectation("level", false),
      peg$c739 = function(status) { return { type: 'trackStatusLevel', status }; },
      peg$c740 = "track of",
      peg$c741 = peg$literalExpectation("track of", false),
      peg$c742 = "level, initially set at level",
      peg$c743 = peg$literalExpectation("level, initially set at level", false),
      peg$c744 = "with a maximum level of",
      peg$c745 = peg$literalExpectation("with a maximum level of", false),
      peg$c746 = function(status, current, max) { return { type: 'trackStatusLevel', status, max, current }; },
      peg$c747 = "level by",
      peg$c748 = peg$literalExpectation("level by", false),
      peg$c749 = function(sign, status, value, trigger) {
          return { type: 'changeStatusLevel', status, value: value * sign, trigger };
        },
      peg$c750 = "level to",
      peg$c751 = peg$literalExpectation("level to", false),
      peg$c752 = function(status, value, trigger) {
          return { type: 'setStatusLevel', status, value, trigger };
        },
      peg$c753 = "when the",
      peg$c754 = peg$literalExpectation("when the", false),
      peg$c755 = "level is increased",
      peg$c756 = peg$literalExpectation("level is increased", false),
      peg$c757 = function(status, value, status2) { return status === status2; },
      peg$c758 = function(status, value, status2) { return { type: 'statusLevelBooster', status, value }; },
      peg$c759 = "affects",
      peg$c760 = peg$literalExpectation("Affects", true),
      peg$c761 = "certain Burst Commands",
      peg$c762 = peg$literalExpectation("certain Burst Commands", false),
      peg$c763 = function() { return { type: 'burstToggle' }; },
      peg$c764 = "number of",
      peg$c765 = peg$literalExpectation("number of", false),
      peg$c766 = "uses of",
      peg$c767 = peg$literalExpectation("uses of", false),
      peg$c768 = "casts of",
      peg$c769 = peg$literalExpectation("casts of", false),
      peg$c770 = function(skill) { return { type: 'trackUses', skill }; },
      peg$c771 = "Used to determine the effect of",
      peg$c772 = peg$literalExpectation("Used to determine the effect of", false),
      peg$c773 = "usage",
      peg$c774 = peg$literalExpectation("usage", false),
      peg$c775 = "tracks",
      peg$c776 = peg$literalExpectation("Tracks", true),
      peg$c777 = "track of the number of",
      peg$c778 = peg$literalExpectation("track of the number of", false),
      peg$c779 = peg$literalExpectation("used", false),
      peg$c780 = function(element) { return { type: 'trackUses', element }; },
      peg$c781 = "uses",
      peg$c782 = peg$literalExpectation("uses", false),
      peg$c783 = "count is shared between",
      peg$c784 = peg$literalExpectation("count is shared between", false),
      peg$c785 = "elements",
      peg$c786 = peg$literalExpectation("elements", false),
      peg$c787 = function(elements) { return null; },
      peg$c788 = "modifies",
      peg$c789 = peg$literalExpectation("Modifies", true),
      peg$c790 = "behavior of",
      peg$c791 = peg$literalExpectation("behavior of", false),
      peg$c792 = function(skill) { return { type: 'modifiesSkill', skill }; },
      peg$c793 = "removed if the user hasn't Burst Mode",
      peg$c794 = peg$literalExpectation("removed if the user hasn't Burst Mode", false),
      peg$c795 = function() { return { type: 'burstOnly' }; },
      peg$c796 = "reset upon refreshing Burst Mode",
      peg$c797 = peg$literalExpectation("reset upon refreshing Burst Mode", false),
      peg$c798 = function() { return { type: 'burstReset' }; },
      peg$c799 = "reset upon refreshing",
      peg$c800 = peg$literalExpectation("reset upon refreshing", false),
      peg$c801 = function(status) { return { type: 'statusReset', status }; },
      peg$c802 = "Used to track whether a Guardian Summon is active",
      peg$c803 = peg$literalExpectation("Used to track whether a Guardian Summon is active", false),
      peg$c804 = function() { return { type: 'trackGuardian' }; },
      peg$c805 = "removed after duration expires, Guardian Summon's HP is fully depleted or the associated Guardian Summon Finisher ability is triggered",
      peg$c806 = peg$literalExpectation("removed after duration expires, Guardian Summon's HP is fully depleted or the associated Guardian Summon Finisher ability is triggered", false),
      peg$c807 = function() { return { type: 'guardianReset' }; },
      peg$c808 = "replaces",
      peg$c809 = peg$literalExpectation("Replaces", true),
      peg$c810 = "the Attack command",
      peg$c811 = peg$literalExpectation("the Attack command", false),
      peg$c812 = function() { return null; },
      peg$c813 = "the Attack and Defend commands",
      peg$c814 = peg$literalExpectation("the Attack and Defend commands", false),
      peg$c815 = "disables",
      peg$c816 = peg$literalExpectation("Disables", true),
      peg$c817 = function(skillType, jump) { return { type: 'disableAttacks', skillType, jump: !!jump }; },
      peg$c818 = "behaviour",
      peg$c819 = peg$literalExpectation("behaviour", false),
      peg$c820 = "arrests",
      peg$c821 = peg$literalExpectation("Arrests", true),
      peg$c822 = "ATB charge rate, can't act",
      peg$c823 = peg$literalExpectation("ATB charge rate, can't act", false),
      peg$c824 = ", resets ATB when set",
      peg$c825 = peg$literalExpectation(", resets ATB when set", false),
      peg$c826 = function() { return { type: 'paralyze' }; },
      peg$c827 = "act",
      peg$c828 = peg$literalExpectation("act", false),
      peg$c829 = "resets",
      peg$c830 = peg$literalExpectation("Resets", true),
      peg$c831 = "ATB when set",
      peg$c832 = peg$literalExpectation("ATB when set", false),
      peg$c833 = function() { return { type: 'stun' }; },
      peg$c834 = "will",
      peg$c835 = peg$literalExpectation("Will", true),
      peg$c836 = "remove any",
      peg$c837 = peg$literalExpectation("remove any", false),
      peg$c838 = "active targeting",
      peg$c839 = peg$literalExpectation("Active Targeting", true),
      peg$c840 = "upon selecting the random action",
      peg$c841 = peg$literalExpectation("upon selecting the random action", false),
      peg$c842 = "No gameplay effects",
      peg$c843 = peg$literalExpectation("No gameplay effects", false),
      peg$c844 = "persists",
      peg$c845 = peg$literalExpectation("Persists", true),
      peg$c846 = "after battle",
      peg$c847 = peg$literalExpectation("after battle", false),
      peg$c848 = "counts",
      peg$c849 = peg$literalExpectation("Counts", true),
      peg$c850 = "towards Game Over",
      peg$c851 = peg$literalExpectation("towards Game Over", false),
      peg$c852 = "% chance to increase Gil gained at the end of battle by",
      peg$c853 = peg$literalExpectation("% chance to increase Gil gained at the end of battle by", false),
      peg$c854 = function(chance, value, condition) {
          return { type: 'gilUp', chance, value, condition };
        },
      peg$c855 = peg$literalExpectation("after", true),
      peg$c856 = "using",
      peg$c857 = peg$literalExpectation("using", false),
      peg$c858 = "casting",
      peg$c859 = peg$literalExpectation("casting", false),
      peg$c860 = "dealing damage with",
      peg$c861 = peg$literalExpectation("dealing damage with", false),
      peg$c862 = "the user uses",
      peg$c863 = peg$literalExpectation("the user uses", false),
      peg$c864 = "or soulbreak",
      peg$c865 = peg$literalExpectation("or soulbreak", false),
      peg$c866 = "on an enemy",
      peg$c867 = peg$literalExpectation("on an enemy", false),
      peg$c868 = "that",
      peg$c869 = peg$literalExpectation("that", false),
      peg$c870 = function(requiresDamage1, count, requiresDamage2, skillType, element, school, jump, mimic, requiresAttack, allowsSoulBreak) {
            return { type: 'ability', skillType, element, school, count, jump: jump ? true : undefined, mimic: mimic ? true : undefined, requiresDamage: requiresDamage1 === 'dealing damage with' || !!requiresDamage2, requiresAttack, allowsSoulBreak: allowsSoulBreak ? true : undefined };
          },
      peg$c871 = peg$literalExpectation("if", true),
      peg$c872 = "the granting user has used",
      peg$c873 = peg$literalExpectation("the granting user has used", false),
      peg$c874 = function(count, requiresDamage2, element, school, jump, requiresAttack) {
            // Tyro AASB variant
            return { type: 'ability', element, school, count, jump: !!jump, requiresDamage: !!requiresDamage2, requiresAttack };
          },
      peg$c875 = peg$literalExpectation("when", true),
      peg$c876 = "user triggers",
      peg$c877 = peg$literalExpectation("user triggers", false),
      peg$c878 = "during the status",
      peg$c879 = peg$literalExpectation("during the status", false),
      peg$c880 = function(count, element, school, jump, requiresAttack) {
            // Arcane Dyad variant
            return { type: 'ability', element, school, count, jump: !!jump, requiresAttack };
          },
      peg$c881 = peg$literalExpectation("every", true),
      peg$c882 = function(count, element, school, jump, requiresAttack) {
            return { type: 'ability', element, school, count: { values: count }, jump: !!jump, requiresAttack };
          },
      peg$c883 = "dealing a critical hit",
      peg$c884 = peg$literalExpectation("dealing a critical hit", false),
      peg$c885 = function() { return { type: 'crit' }; },
      peg$c886 = "exploiting elemental weakness",
      peg$c887 = peg$literalExpectation("exploiting elemental weakness", false),
      peg$c888 = function() { return { type: 'vsWeak' }; },
      peg$c889 = peg$literalExpectation("removed", false),
      peg$c890 = function() { return { type: 'whenRemoved' }; },
      peg$c891 = function(interval) { return { type: 'auto', interval }; },
      peg$c892 = "upon",
      peg$c893 = peg$literalExpectation("upon", true),
      peg$c894 = "taking damage",
      peg$c895 = peg$literalExpectation("taking damage", false),
      peg$c896 = function(skillType) { return { type: 'damaged', skillType }; },
      peg$c897 = peg$literalExpectation("by", true),
      peg$c898 = "dealing damage",
      peg$c899 = peg$literalExpectation("dealing damage", false),
      peg$c900 = function() { return { type: 'dealDamage' }; },
      peg$c901 = "when Reraise is triggered",
      peg$c902 = peg$literalExpectation("when Reraise is triggered", false),
      peg$c903 = function() { return { type: 'triggerStatus', status: 'Reraise' }; },
      peg$c904 = "is removed",
      peg$c905 = peg$literalExpectation("is removed", false),
      peg$c906 = function(status) { return { type: 'loseStatus', status }; },
      peg$c907 = "times",
      peg$c908 = peg$literalExpectation("times", false),
      peg$c909 = function(skill) {
          // Hack: Generic skill names have to include number slash lists, so manually extract the number
          // slash list.
          parsedNumberResult = util.removeTrailingNumberSlashList(skill);
          return parsedNumberResult != null;
        },
      peg$c910 = function(skill) {
          // Hack: "or" is a valid skill name, but in this context, assume it's separating synchro commands.
          skill = parsedNumberResult.skill;
          if (skill.match(/ or /)) {
            skill = skill.split(/ or /);
          }
          return { type: 'skill', skill, count: parsedNumberResult.count, plus: parsedNumberResult.plus };
        },
      peg$c911 = function(skill, count) {
          // Hack: "or" is a valid skill name, but in this context, assume it's separating synchro commands.
          if (skill.match(/ or /)) {
            skill = skill.split(/ or /);
          }
          return { type: 'skill', skill, count };
        },
      peg$c912 = "is triggered",
      peg$c913 = peg$literalExpectation("is triggered", false),
      peg$c914 = function(skill, count) { return { type: 'skillTriggered', skill, count }; },
      peg$c915 = "if user has triggered",
      peg$c916 = peg$literalExpectation("if user has triggered", false),
      peg$c917 = "either",
      peg$c918 = peg$literalExpectation("either", false),
      peg$c919 = function(count, plus, skill) { return { type: 'skill', skill, count, plus: !!plus }; },
      peg$c920 = function(count, skill) { return { type: 'skill', skill, count }; },
      peg$c921 = "after using",
      peg$c922 = peg$literalExpectation("after using", false),
      peg$c923 = "taking",
      peg$c924 = peg$literalExpectation("taking", false),
      peg$c925 = "damage from a",
      peg$c926 = peg$literalExpectation("damage from a", false),
      peg$c927 = "attack used by another ally",
      peg$c928 = peg$literalExpectation("attack used by another ally", false),
      peg$c929 = function(element, skillType) { return { type: 'damagedByAlly', skillType, element }; },
      peg$c930 = "using a single-target heal",
      peg$c931 = peg$literalExpectation("using a single-target heal", false),
      peg$c932 = function() { return { type: 'singleHeal' }; },
      peg$c933 = "HP fall",
      peg$c934 = peg$literalExpectation("HP fall", false),
      peg$c935 = "below",
      peg$c936 = peg$literalExpectation("below", false),
      peg$c937 = function(value) { return { type: 'lowHp', value }; },
      peg$c938 = "user crosses",
      peg$c939 = peg$literalExpectation("user crosses", false),
      peg$c940 = "damage dealt during the status",
      peg$c941 = peg$literalExpectation("damage dealt during the status", false),
      peg$c942 = function(value1, value2) {
            // For ADSBs
            return { type: 'damageDuringStatus', value: [value1, value2] };
          },
      peg$c943 = function(value1) {
            // For ADSBs
            return { type: 'damageDuringStatus', value: [value1] };
          },
      peg$c944 = "an ally",
      peg$c945 = peg$literalExpectation("an ally", false),
      peg$c946 = "that deals elemental damage (excluding NE)",
      peg$c947 = peg$literalExpectation("that deals elemental damage (excluding NE)", false),
      peg$c948 = function(count, requiresDamage, element, school, jump, requiresAttack, anyElemental) {
            if (anyElemental) {
              element = ['Fire', 'Ice', 'Lightning', 'Earth', 'Wind', 'Water', 'Holy', 'Dark', 'Poison'];
            }
            return { type: 'allyAbility', element, school, count, jump: !!jump, requiresDamage, requiresAttack };
          },
      peg$c949 = "after using a single-target",
      peg$c950 = peg$literalExpectation("after using a single-target", false),
      peg$c951 = "ability that restores HP on an ally",
      peg$c952 = peg$literalExpectation("ability that restores HP on an ally", false),
      peg$c953 = function(school) { return { type: 'singleHeal', school }; },
      peg$c954 = "after taking damage from an enemy",
      peg$c955 = peg$literalExpectation("after taking damage from an enemy", false),
      peg$c956 = function() { return { type: 'damaged' }; },
      peg$c957 = "ability that deals",
      peg$c958 = peg$literalExpectation("ability that deals", false),
      peg$c959 = function(count, element) { return { type: 'ability', element, count, requiresDamage: true }; },
      peg$c960 = function(count, school, once) { return { type: 'ability', school, once }; },
      peg$c961 = function() { return false; },
      peg$c962 = function() { return true; },
      peg$c963 = function(useCount) { return useCount; },
      peg$c964 = "or",
      peg$c965 = peg$literalExpectation("or", false),
      peg$c966 = function(value1, value2) { return { values: [value1, value2] }; },
      peg$c967 = "/",
      peg$c968 = peg$literalExpectation("/", false),
      peg$c969 = function(values) { return { values }; },
      peg$c970 = function(values, plus) { return { values, plus: !!plus }; },
      peg$c971 = function() { return { values: 1 }; },
      peg$c972 = "while under",
      peg$c973 = peg$literalExpectation("while under", false),
      peg$c974 = function(status) { return null; },
      peg$c975 = "if the triggering ability is",
      peg$c976 = peg$literalExpectation("if the triggering ability is", false),
      peg$c977 = function(skill1or2) { return null; },
      peg$c978 = "that deals",
      peg$c979 = peg$literalExpectation("that deals", false),
      peg$c980 = "if the triggering ability deals",
      peg$c981 = peg$literalExpectation("if the triggering ability deals", false),
      peg$c982 = ", can trigger on dualcasts",
      peg$c983 = peg$literalExpectation(", can trigger on dualcasts", false),
      peg$c984 = "and/or",
      peg$c985 = peg$literalExpectation("and/or", false),
      peg$c986 = function(skill1, skill2) { return [skill1, skill2]; },
      peg$c987 = function(skill1or2) {
              // Variation for "or" or "and" instead of "and/or" - these may be part of a skill name themselves.
              // TODO: Consistently using and/or would be better.
              return (skill1or2.match(/ (?:or|and) |\//g) || []).length === 1;
            },
      peg$c988 = function(skill1or2) { return skill1or2.split(/ (?:or|and) |\//); },
      peg$c989 = "single",
      peg$c990 = peg$literalExpectation("single", false),
      peg$c991 = "group",
      peg$c992 = peg$literalExpectation("group", false),
      peg$c993 = "random",
      peg$c994 = peg$literalExpectation("random", false),
      peg$c995 = function(skillType, isAoE) { wantInfinitive.push(false); return true; },
      peg$c996 = function(skillType, isAoE, head, tail) {
          wantInfinitive.pop();
          // We don't currently capture "random."
          return { skillType, isAoE: isAoE === 'group', effects: util.pegList(head, tail, 2) };
        },
      peg$c997 = "hybrid",
      peg$c998 = peg$literalExpectation("hybrid", false),
      peg$c999 = "x",
      peg$c1000 = peg$literalExpectation("x", false),
      peg$c1001 = function(hybrid, n) { return n; },
      peg$c1002 = function(hybrid, numAttacks, attackMultiplier, n) { return n; },
      peg$c1003 = function(hybrid, numAttacks, attackMultiplier, hybridMultiplier, ranged, element) {
          return {
            type: 'attack',
            numAttacks: numAttacks || 1,
            attackMultiplier,
            hybridMultiplier,
            overrideElement: element,
            isRanged: !!ranged,
            isHybrid: !!hybrid
          };
        },
      peg$c1004 = "restores HP (",
      peg$c1005 = peg$literalExpectation("restores HP (", false),
      peg$c1006 = function(value) {
          return { type: 'heal', amount: { healFactor: value } };
        },
      peg$c1007 = peg$literalExpectation("restores", false),
      peg$c1008 = function(value, who) {
          return { type: 'heal', amount: { fixedHp: value }, who };
        },
      peg$c1009 = "restores HP for",
      peg$c1010 = peg$literalExpectation("restores HP for", false),
      peg$c1011 = "% of the target's maximum HP",
      peg$c1012 = peg$literalExpectation("% of the target's maximum HP", false),
      peg$c1013 = function(healPercent) {
          return { type: 'healPercent', healPercent };
        },
      peg$c1014 = function(head, tail, value) {
          return { type: 'statMod', stats: util.pegList(head, tail, 1, true), value };
        },
      peg$c1015 = "damages undeads",
      peg$c1016 = peg$literalExpectation("damages undeads", false),
      peg$c1017 = function() {
          return { type: 'damagesUndead' };
        },
      peg$c1018 = function(verb, statuses, statusClauses) {
          const result = { type: 'status', verb, statuses };
          for (const i of statusClauses) {
            Object.assign(result, i);
          }
          if (result.duration) {
            util.applyDuration(result.statuses, result.duration);
            delete result.duration;
          }
          return result;
        },
      peg$c1019 = function(smartEther, who) {
          return { type: 'status', who, statuses: [{ status: smartEther }] };
        },
      peg$c1020 = peg$literalExpectation("heals", false),
      peg$c1021 = "to",
      peg$c1022 = peg$literalExpectation("to", false),
      peg$c1023 = "restores HP to",
      peg$c1024 = peg$literalExpectation("restores HP to", false),
      peg$c1025 = "the user for",
      peg$c1026 = peg$literalExpectation("the user for", false),
      peg$c1027 = "% of the damage dealt",
      peg$c1028 = peg$literalExpectation("% of the damage dealt", false),
      peg$c1029 = function(healPercent) {
          return { type: 'drainHp', healPercent };
        },
      peg$c1030 = function(duration) { return { duration }; },
      peg$c1031 = ", if in the party,",
      peg$c1032 = peg$literalExpectation(", if in the party,", false),
      peg$c1033 = function(who, toCharacter) { return { who, toCharacter }; },
      peg$c1034 = function(who) { return { who }; },
      peg$c1035 = function(toCharacter) { return { toCharacter }; },
      peg$c1036 = "to undeads",
      peg$c1037 = peg$literalExpectation("to undeads", false),
      peg$c1038 = function() { return { ifUndead: true }; },
      peg$c1039 = function(condition) { return { condition }; },
      peg$c1040 = function(clause) {
          return clause;
        },
      peg$c1041 = function(head, conj, status) { return { ...status, conj }; },
      peg$c1042 = function(head, tail) {
          return [head, ...tail];
        },
      peg$c1043 = "(",
      peg$c1044 = peg$literalExpectation("(", false),
      peg$c1045 = "%)",
      peg$c1046 = peg$literalExpectation("%)", false),
      peg$c1047 = function(status, n) { return n; },
      peg$c1048 = function(status, chance, duration) {
          const result = {
            status
          };
          if (chance) {
            result.chance = chance;
          }
          if (duration) {
            result.duration = duration;
          }
          return result;
        },
      peg$c1049 = peg$otherExpectation("status with level"),
      peg$c1050 = function(name, value) {
          return { type:'statusLevel', name, value, set: true };
        },
      peg$c1051 = function(name, value, max) {
          return { type:'statusLevel', name, value, max };
        },
      peg$c1052 = function(value, name, max) { return { type:'statusLevel', name, value, max }; },
      peg$c1053 = function(name) {
              statusLevelMatch = name.match(/(.*) ((?:[+-]?\d+)(?:\/[+-]?\d+)*)$/);
              return statusLevelMatch;
            },
      peg$c1054 = function(name, max) { return { type:'statusLevel', name: statusLevelMatch[1], value: util.scalarify(statusLevelMatch[2].split('/').map(i => +i)), max }; },
      peg$c1055 = function(name) { return { type:'statusLevel', name, value: 1, set: true }; },
      peg$c1056 = "(max",
      peg$c1057 = peg$literalExpectation("(max", false),
      peg$c1058 = function(name) { return { type: 'standardStatus', name }; },
      peg$c1059 = "equipping",
      peg$c1060 = peg$literalExpectation("equipping", false),
      peg$c1061 = "a",
      peg$c1062 = peg$literalExpectation("a", false),
      peg$c1063 = function() { return text(); },
      peg$c1064 = /^[a-z\- ]/,
      peg$c1065 = peg$classExpectation([["a", "z"], "-", " "], false, false),
      peg$c1066 = function(article, equipped) { return { type: 'equipped', article, equipped: equipped.join('') }; },
      peg$c1067 = "scaling with",
      peg$c1068 = peg$literalExpectation("scaling with", false),
      peg$c1069 = function(status) { return { type: 'scaleWithStatusLevel', status }; },
      peg$c1070 = "at",
      peg$c1071 = peg$literalExpectation("at", false),
      peg$c1072 = "levels",
      peg$c1073 = peg$literalExpectation("levels", false),
      peg$c1074 = function(status, value) { return { type: 'statusLevel', status, value }; },
      peg$c1075 = "has",
      peg$c1076 = peg$literalExpectation("has", false),
      peg$c1077 = function(status, value, plus) { return { type: 'statusLevel', status, value, plus: !!plus }; },
      peg$c1078 = "=",
      peg$c1079 = peg$literalExpectation("=", false),
      peg$c1080 = ">",
      peg$c1081 = peg$literalExpectation(">", false),
      peg$c1082 = function(status, value) { return { type: 'statusLevel', status, value: value + 1, plus: true }; },
      peg$c1083 = "least",
      peg$c1084 = peg$literalExpectation("least", false),
      peg$c1085 = function(value, status) { return { type: 'statusLevel', status, value }; },
      peg$c1086 = "[Doom]",
      peg$c1087 = peg$literalExpectation("[Doom]", false),
      peg$c1088 = "Doom",
      peg$c1089 = peg$literalExpectation("Doom", false),
      peg$c1090 = "with",
      peg$c1091 = peg$literalExpectation("with", false),
      peg$c1092 = function() { return { type: 'ifDoomed' }; },
      peg$c1093 = "target",
      peg$c1094 = peg$literalExpectation("target", false),
      peg$c1095 = function(who, head, tail) { return util.pegList(head, tail, 1); },
      peg$c1096 = function(who, status) {
          return {
            type: 'statusList',
            status: status.map(i => ({ status: i })), // Convert to StatusWithPercent[]
            who: who === 'user' ? 'self' : 'target',
          };
        },
      peg$c1097 = function(who, withoutWith, any, head, tail) { return util.pegList(head, tail, 1, true); },
      peg$c1098 = function(who, withoutWith, any, status) {
          return {
            type: 'status',
            status,  // In string form - callers must separate by comma, "or", etc.
            who: who === 'user' ? 'self' : 'target',
            any: !!any,
            withoutWith,
          };
        },
      peg$c1099 = "if current number of combined Attach Element statuses on party members are a majority Attach",
      peg$c1100 = peg$literalExpectation("if current number of combined Attach Element statuses on party members are a majority Attach", false),
      peg$c1101 = ". In the case of ties the prior listed order is used to determine status granted",
      peg$c1102 = peg$literalExpectation(". In the case of ties the prior listed order is used to determine status granted", false),
      peg$c1103 = ". Considers number of stacks on a character as well (Attach Fire at level 2 counts as 2). In the case of ties the prior listed order is used to determine status granted",
      peg$c1104 = peg$literalExpectation(". Considers number of stacks on a character as well (Attach Fire at level 2 counts as 2). In the case of ties the prior listed order is used to determine status granted", false),
      peg$c1105 = function(element) {
          return { type: 'conditionalEnElement', element };
        },
      peg$c1106 = function(useCount) { return { type: 'scaleUseCount', useCount }; },
      peg$c1107 = "activations",
      peg$c1108 = peg$literalExpectation("activations", false),
      peg$c1109 = function() { return { type: 'scaleWithUses' }; },
      peg$c1110 = "scaling",
      peg$c1111 = peg$literalExpectation("scaling", false),
      peg$c1112 = "scal.",
      peg$c1113 = peg$literalExpectation("scal.", false),
      peg$c1114 = function(skill) { return { type: 'scaleWithSkillUses', skill }; },
      peg$c1115 = function(useCount, skill) { return { type: 'afterUseCount', skill, useCount }; },
      peg$c1116 = function(count) { return { type: 'afterUseCount', useCount: { from: count, to: count } }; },
      peg$c1117 = "on",
      peg$c1118 = peg$literalExpectation("on", false),
      peg$c1119 = "first",
      peg$c1120 = peg$literalExpectation("first", false),
      peg$c1121 = "use",
      peg$c1122 = peg$literalExpectation("use", false),
      peg$c1123 = function() { return { type: 'afterUseCount', useCount: { from: 1, to: 1 } }; },
      peg$c1124 = function(first) { return { type: 'afterUseCount', useCount: { from: first } }; },
      peg$c1125 = "are",
      peg$c1126 = peg$literalExpectation("are", false),
      peg$c1127 = "alive",
      peg$c1128 = peg$literalExpectation("alive", false),
      peg$c1129 = function() { return { type: 'alliesAlive' }; },
      peg$c1130 = "is",
      peg$c1131 = peg$literalExpectation("is", false),
      peg$c1132 = function(character) { return { type: 'characterAlive', character }; },
      peg$c1133 = function(character) { return { type: 'characterAlive', character, all: true }; },
      peg$c1134 = "not alive/alive",
      peg$c1135 = peg$literalExpectation("not alive/alive", false),
      peg$c1136 = function(character) { return { type: 'characterAlive', character, withoutWith: 'withoutWith' }; },
      peg$c1137 = function(count, character) { return { type: 'characterAlive', character, count }; },
      peg$c1138 = "not alive",
      peg$c1139 = peg$literalExpectation("not alive", false),
      peg$c1140 = function(character) { return { type: 'characterAlive', character, withoutWith: 'without' }; },
      peg$c1141 = "in",
      peg$c1142 = peg$literalExpectation("in", false),
      peg$c1143 = "party",
      peg$c1144 = peg$literalExpectation("party", false),
      peg$c1145 = function(count, character) { return { type: 'characterInParty', character, count }; },
      peg$c1146 = function(count, character) { return { type: 'characterInParty', character, count, all: true }; },
      peg$c1147 = "females are in the party",
      peg$c1148 = peg$literalExpectation("females are in the party", false),
      peg$c1149 = function(count) { return { type: 'femalesInParty', count }; },
      peg$c1150 = "females",
      peg$c1151 = peg$literalExpectation("females", false),
      peg$c1152 = "female allies",
      peg$c1153 = peg$literalExpectation("female allies", false),
      peg$c1154 = "are alive",
      peg$c1155 = peg$literalExpectation("are alive", false),
      peg$c1156 = function(count) { return { type: 'femalesAlive', count }; },
      peg$c1157 = "or more female allies are alive",
      peg$c1158 = peg$literalExpectation("or more female allies are alive", false),
      peg$c1159 = function(count) { return { type: 'femalesAlive', count, plus: true }; },
      peg$c1160 = "there",
      peg$c1161 = peg$literalExpectation("there", false),
      peg$c1162 = "characters",
      peg$c1163 = peg$literalExpectation("characters", false),
      peg$c1164 = function(count, realm) { return { type: 'realmCharactersInParty', realm, count }; },
      peg$c1165 = "characters are alive",
      peg$c1166 = peg$literalExpectation("characters are alive", false),
      peg$c1167 = "character is alive",
      peg$c1168 = peg$literalExpectation("character is alive", false),
      peg$c1169 = "allies are alive",
      peg$c1170 = peg$literalExpectation("allies are alive", false),
      peg$c1171 = "members are alive",
      peg$c1172 = peg$literalExpectation("members are alive", false),
      peg$c1173 = function(count, plus, realm) { return { type: 'realmCharactersAlive', realm, count, plus: !!plus }; },
      peg$c1174 = "or more females are in the party",
      peg$c1175 = peg$literalExpectation("or more females are in the party", false),
      peg$c1176 = "party members",
      peg$c1177 = peg$literalExpectation("party members", false),
      peg$c1178 = function(count) { return { type: 'charactersAlive', count }; },
      peg$c1179 = "air",
      peg$c1180 = peg$literalExpectation("air", false),
      peg$c1181 = function(count) { return { type: 'alliesJump', count }; },
      peg$c1182 = "user's",
      peg$c1183 = peg$literalExpectation("user's", false),
      peg$c1184 = "timer",
      peg$c1185 = peg$literalExpectation("timer", false),
      peg$c1186 = function(value) { return { type: 'doomTimer', value }; },
      peg$c1187 = function(value) { return { type: 'hpBelowPercent', value }; },
      peg$c1188 = function(value) { return { type: 'hpAtLeastPercent', value }; },
      peg$c1189 = "points",
      peg$c1190 = peg$literalExpectation("points", false),
      peg$c1191 = function(value, plus) { return { type: 'soulBreakPoints', value, plus: !!plus }; },
      peg$c1192 = "of the target's stats are lowered",
      peg$c1193 = peg$literalExpectation("of the target's stats are lowered", false),
      peg$c1194 = function(count) { return { type: 'targetStatBreaks', count }; },
      peg$c1195 = "if target has",
      peg$c1196 = peg$literalExpectation("if target has", false),
      peg$c1197 = "of ATK, DEF, MAG, RES or MND reduced",
      peg$c1198 = peg$literalExpectation("of ATK, DEF, MAG, RES or MND reduced", false),
      peg$c1199 = "ailments",
      peg$c1200 = peg$literalExpectation("ailments", false),
      peg$c1201 = function(count) { return { type: 'targetStatusAilments', count }; },
      peg$c1202 = "if exploiting elemental weakness",
      peg$c1203 = peg$literalExpectation("if exploiting elemental weakness", false),
      peg$c1204 = "if exploiting",
      peg$c1205 = peg$literalExpectation("if exploiting", false),
      peg$c1206 = "weakness",
      peg$c1207 = peg$literalExpectation("weakness", false),
      peg$c1208 = function(element) { return { type: 'vsWeak', element }; },
      peg$c1209 = "front",
      peg$c1210 = peg$literalExpectation("front", false),
      peg$c1211 = "row",
      peg$c1212 = peg$literalExpectation("row", false),
      peg$c1213 = function() { return { type: 'inFrontRow' }; },
      peg$c1214 = "took",
      peg$c1215 = peg$literalExpectation("took", false),
      peg$c1216 = "taken",
      peg$c1217 = peg$literalExpectation("taken", false),
      peg$c1218 = "hits",
      peg$c1219 = peg$literalExpectation("hits", false),
      peg$c1220 = function(count, skillType) { return { type: 'hitsTaken', count, skillType }; },
      peg$c1221 = function(count) { return { type: 'attacksTaken', count }; },
      peg$c1222 = "actions",
      peg$c1223 = peg$literalExpectation("actions", false),
      peg$c1224 = function(count) { return { type: 'damagingActions', count }; },
      peg$c1225 = "other",
      peg$c1226 = peg$literalExpectation("other", false),
      peg$c1227 = "users",
      peg$c1228 = peg$literalExpectation("users", false),
      peg$c1229 = function(count, school) { return { type: 'otherAbilityUsers', count, school }; },
      peg$c1230 = "different",
      peg$c1231 = peg$literalExpectation("different", false),
      peg$c1232 = function(count, school) { return { type: 'differentAbilityUses', count, school }; },
      peg$c1233 = "during",
      peg$c1234 = peg$literalExpectation("during", false),
      peg$c1235 = function(count, school) { return { type: 'abilitiesUsedDuringStatus', count, school }; },
      peg$c1236 = function(count, school) { return { type: 'abilitiesUsed', count, school }; },
      peg$c1237 = function(count, element) { return { type: 'attacksDuringStatus', count, element }; },
      peg$c1238 = "damage was dealt during the status",
      peg$c1239 = peg$literalExpectation("damage was dealt during the status", false),
      peg$c1240 = function(value) {
          lastDamageDuringStatus = util.lastValue(value);
          lastDamageDuringStatusElement = undefined;
          return { type: 'damageDuringStatus', value };
        },
      peg$c1241 = "user dealt",
      peg$c1242 = peg$literalExpectation("user dealt", false),
      peg$c1243 = "damage during the status",
      peg$c1244 = peg$literalExpectation("damage during the status", false),
      peg$c1245 = "damage with",
      peg$c1246 = peg$literalExpectation("damage with", false),
      peg$c1247 = "attacks during the status",
      peg$c1248 = peg$literalExpectation("attacks during the status", false),
      peg$c1249 = function(value, element) {
          lastDamageDuringStatus = util.lastValue(value);
          lastDamageDuringStatusElement = element;
          return { type: 'damageDuringStatus', value, element };
        },
      peg$c1250 = "final",
      peg$c1251 = peg$literalExpectation("final", false),
      peg$c1252 = "threshold",
      peg$c1253 = peg$literalExpectation("threshold", false),
      peg$c1254 = "was",
      peg$c1255 = peg$literalExpectation("was", false),
      peg$c1256 = "met",
      peg$c1257 = peg$literalExpectation("met", false),
      peg$c1258 = function() { return { type: 'damageDuringStatus', value: lastDamageDuringStatus, element: lastDamageDuringStatusElement }; },
      peg$c1259 = function(school, count) { return { type: 'abilitiesUsed', count, school }; },
      peg$c1260 = "rank",
      peg$c1261 = peg$literalExpectation("rank", false),
      peg$c1262 = "1/2/3/4/5",
      peg$c1263 = peg$literalExpectation("1/2/3/4/5", false),
      peg$c1264 = "triggering",
      peg$c1265 = peg$literalExpectation("triggering", false),
      peg$c1266 = function() { return { type: 'rankBased' }; },
      peg$c1267 = function(status) { return { type: 'status', status, who: 'self' }; },
      peg$c1268 = function(value, stat) { return { type: 'statThreshold', stat, value }; },
      peg$c1269 = "at the beginning of the battle",
      peg$c1270 = peg$literalExpectation("at the beginning of the battle", false),
      peg$c1271 = function() { return { type: 'battleStart' }; },
      peg$c1272 = "hasn't/has",
      peg$c1273 = peg$literalExpectation("hasn't/has", false),
      peg$c1274 = function() { return 'withoutWith'; },
      peg$c1275 = function() { return 'without'; },
      peg$c1276 = "based on triggering ability's element",
      peg$c1277 = peg$literalExpectation("based on triggering ability's element", false),
      peg$c1278 = function() { return !wantInfinitive[wantInfinitive.length - 1]; },
      peg$c1279 = function() { return wantInfinitive[wantInfinitive.length - 1]; },
      peg$c1280 = "smart",
      peg$c1281 = peg$literalExpectation("smart", true),
      peg$c1282 = "ether",
      peg$c1283 = peg$literalExpectation("ether", false),
      peg$c1284 = function(school, amount) {
          const result = { type: 'smartEther', amount };
          if (school) {
            result.school = school;
          }
          return result;
        },
      peg$c1285 = peg$literalExpectation("grant", true),
      peg$c1286 = "cause",
      peg$c1287 = peg$literalExpectation("cause", true),
      peg$c1288 = "doesn't",
      peg$c1289 = peg$literalExpectation("doesn't", true),
      peg$c1290 = peg$literalExpectation("remove", false),
      peg$c1291 = function() {
          let result = text().toLowerCase().replace(/\s+/g, ' ');
          if (result !== 'doesn\'t remove' && !result.endsWith('s')) {
            result += 's';
          }
          return result;
        },
      peg$c1292 = peg$otherExpectation("status effect"),
      peg$c1293 = "[",
      peg$c1294 = peg$literalExpectation("[", false),
      peg$c1295 = /^[^\]]/,
      peg$c1296 = peg$classExpectation(["]"], true, false),
      peg$c1297 = "]",
      peg$c1298 = peg$literalExpectation("]", false),
      peg$c1299 = function(name) { return name.join(''); },
      peg$c1300 = "s)",
      peg$c1301 = peg$literalExpectation("s)", false),
      peg$c1302 = /^[A-Z]/,
      peg$c1303 = peg$classExpectation([["A", "Z"]], false, false),
      peg$c1304 = /^[A-Za-z0-9\-]/,
      peg$c1305 = peg$classExpectation([["A", "Z"], ["a", "z"], ["0", "9"], "-"], false, false),
      peg$c1306 = "the user",
      peg$c1307 = peg$literalExpectation("the user", false),
      peg$c1308 = function() { return 'self'; },
      peg$c1309 = function(head, tail) { return util.pegList(head, tail, 1, true); },
      peg$c1310 = "he",
      peg$c1311 = peg$literalExpectation("he", false),
      peg$c1312 = "she",
      peg$c1313 = peg$literalExpectation("she", false),
      peg$c1314 = "they",
      peg$c1315 = peg$literalExpectation("they", false),
      peg$c1316 = "???",
      peg$c1317 = peg$literalExpectation("???", false),
      peg$c1318 = function(head, tail) {
          // Hack: Allow both slash-separated lists and comma-separated lists.  Assume
          // that "or" is a separator, not part of the skill name.
          return { options: util.flatten(util.pegList(head, tail, 3).map(i => i.split(/ or /))) };
        },
      peg$c1319 = function(skill) { return skill; },
      peg$c1320 = "hit",
      peg$c1321 = peg$literalExpectation("hit", false),
      peg$c1322 = "rate",
      peg$c1323 = peg$literalExpectation("rate", false),
      peg$c1324 = /^[%+]/,
      peg$c1325 = peg$classExpectation(["%", "+"], false, false),
      peg$c1326 = "...",
      peg$c1327 = peg$literalExpectation("...", false),
      peg$c1328 = "&",
      peg$c1329 = peg$literalExpectation("&", false),
      peg$c1330 = " ",
      peg$c1331 = peg$literalExpectation(" ", false),
      peg$c1332 = "an",
      peg$c1333 = peg$literalExpectation("an", false),
      peg$c1334 = /^[=*+\-]/,
      peg$c1335 = peg$classExpectation(["=", "*", "+", "-"], false, false),
      peg$c1336 = /^[%]/,
      peg$c1337 = peg$classExpectation(["%"], false, false),
      peg$c1338 = "Black Magic",
      peg$c1339 = peg$literalExpectation("Black Magic", false),
      peg$c1340 = "White Magic",
      peg$c1341 = peg$literalExpectation("White Magic", false),
      peg$c1342 = /^[A-Za-z\-0-9\/]/,
      peg$c1343 = peg$classExpectation([["A", "Z"], ["a", "z"], "-", ["0", "9"], "/"], false, false),
      peg$c1344 = "Only",
      peg$c1345 = peg$literalExpectation("Only", false),
      peg$c1346 = function() {
          return text();
        },
      peg$c1347 = /^[a-zA-Z\-'\/]/,
      peg$c1348 = peg$classExpectation([["a", "z"], ["A", "Z"], "-", "'", "/"], false, false),
      peg$c1349 = "!",
      peg$c1350 = peg$literalExpectation("!", false),
      peg$c1351 = function(value, valueIsUncertain, units) {
          return { value, units, valueIsUncertain: valueIsUncertain ? true : undefined };
        },
      peg$c1352 = "sec.",
      peg$c1353 = peg$literalExpectation("sec.", false),
      peg$c1354 = function() {
          let result = text();
          if (result === 'sec.') {
            return 'seconds';
          }
          if (!result.endsWith('s')) {
            result += 's';
          }
          return result;
        },
      peg$c1355 = peg$otherExpectation("stat"),
      peg$c1356 = "ATK",
      peg$c1357 = peg$literalExpectation("ATK", false),
      peg$c1358 = "DEF",
      peg$c1359 = peg$literalExpectation("DEF", false),
      peg$c1360 = "MAG",
      peg$c1361 = peg$literalExpectation("MAG", false),
      peg$c1362 = "RES",
      peg$c1363 = peg$literalExpectation("RES", false),
      peg$c1364 = "MND",
      peg$c1365 = peg$literalExpectation("MND", false),
      peg$c1366 = "SPD",
      peg$c1367 = peg$literalExpectation("SPD", false),
      peg$c1368 = "ACC",
      peg$c1369 = peg$literalExpectation("ACC", false),
      peg$c1370 = "EVA",
      peg$c1371 = peg$literalExpectation("EVA", false),
      peg$c1372 = function() {
          return text().toLowerCase();
        },
      peg$c1373 = peg$otherExpectation("stat list"),
      peg$c1374 = "[Stats]",
      peg$c1375 = peg$literalExpectation("[Stats]", false),
      peg$c1376 = function() { return util.placeholder; },
      peg$c1377 = "from",
      peg$c1378 = peg$literalExpectation("from", false),
      peg$c1379 = function(who) { return who; },
      peg$c1380 = "the target",
      peg$c1381 = peg$literalExpectation("the target", false),
      peg$c1382 = function() { return 'target'; },
      peg$c1383 = "all enemies",
      peg$c1384 = peg$literalExpectation("all enemies", false),
      peg$c1385 = function() { return 'enemies'; },
      peg$c1386 = "a single ally",
      peg$c1387 = peg$literalExpectation("a single ally", false),
      peg$c1388 = function() { return 'ally'; },
      peg$c1389 = "all allies",
      peg$c1390 = peg$literalExpectation("all allies", false),
      peg$c1391 = "in the",
      peg$c1392 = peg$literalExpectation("in the", false),
      peg$c1393 = "back",
      peg$c1394 = peg$literalExpectation("back", false),
      peg$c1395 = "character's",
      peg$c1396 = peg$literalExpectation("character's", false),
      peg$c1397 = function(row) { return row === "character's" ? 'sameRow' : row + 'Row'; },
      peg$c1398 = function(row) {
          return row || 'party';
        },
      peg$c1399 = "allies in the same row",
      peg$c1400 = peg$literalExpectation("allies in the same row", false),
      peg$c1401 = function() { return 'sameRow'; },
      peg$c1402 = "the lowest HP% ally",
      peg$c1403 = peg$literalExpectation("the lowest HP% ally", false),
      peg$c1404 = function() { return 'lowestHpAlly'; },
      peg$c1405 = "a random ally without status",
      peg$c1406 = peg$literalExpectation("a random ally without status", false),
      peg$c1407 = function() { return 'allyWithoutStatus'; },
      peg$c1408 = "a random ally with negative",
      peg$c1409 = peg$literalExpectation("a random ally with negative", false),
      peg$c1410 = function() { return 'allyWithNegativeStatus'; },
      peg$c1411 = "a random ally with KO",
      peg$c1412 = peg$literalExpectation("a random ally with KO", false),
      peg$c1413 = function() { return 'allyWithKO'; },
      peg$c1414 = function(head, tail) { return util.pegList(head, tail, 1); },
      peg$c1415 = function(perUses) { return perUses; },
      peg$c1416 = "activation",
      peg$c1417 = peg$literalExpectation("activation", false),
      peg$c1418 = peg$otherExpectation("skill type"),
      peg$c1419 = "WHT",
      peg$c1420 = peg$literalExpectation("WHT", false),
      peg$c1421 = "BLK",
      peg$c1422 = peg$literalExpectation("BLK", false),
      peg$c1423 = "BLU",
      peg$c1424 = peg$literalExpectation("BLU", false),
      peg$c1425 = "SUM",
      peg$c1426 = peg$literalExpectation("SUM", false),
      peg$c1427 = "NAT",
      peg$c1428 = peg$literalExpectation("NAT", false),
      peg$c1429 = "NIN",
      peg$c1430 = peg$literalExpectation("NIN", false),
      peg$c1431 = function() { return "PHY"; },
      peg$c1432 = peg$otherExpectation("skill type list"),
      peg$c1433 = peg$otherExpectation("element"),
      peg$c1434 = "Fire",
      peg$c1435 = peg$literalExpectation("Fire", false),
      peg$c1436 = "Ice",
      peg$c1437 = peg$literalExpectation("Ice", false),
      peg$c1438 = "Lightning",
      peg$c1439 = peg$literalExpectation("Lightning", false),
      peg$c1440 = "Earth",
      peg$c1441 = peg$literalExpectation("Earth", false),
      peg$c1442 = "Wind",
      peg$c1443 = peg$literalExpectation("Wind", false),
      peg$c1444 = "Water",
      peg$c1445 = peg$literalExpectation("Water", false),
      peg$c1446 = "Holy",
      peg$c1447 = peg$literalExpectation("Holy", false),
      peg$c1448 = "Dark",
      peg$c1449 = peg$literalExpectation("Dark", false),
      peg$c1450 = "ness",
      peg$c1451 = peg$literalExpectation("ness", false),
      peg$c1452 = function() { return "Dark"; },
      peg$c1453 = "Poison",
      peg$c1454 = peg$literalExpectation("Poison", false),
      peg$c1455 = "NE",
      peg$c1456 = peg$literalExpectation("NE", false),
      peg$c1457 = "Non-Elemental",
      peg$c1458 = peg$literalExpectation("Non-Elemental", false),
      peg$c1459 = function() { return "NE"; },
      peg$c1460 = "[Element]",
      peg$c1461 = peg$literalExpectation("[Element]", false),
      peg$c1462 = peg$otherExpectation("element list"),
      peg$c1463 = peg$otherExpectation("element list or slash-separated alternatives"),
      peg$c1464 = function(elements) { return elements; },
      peg$c1465 = function(elements) { return { options: elements }; },
      peg$c1466 = "/[Attach",
      peg$c1467 = peg$literalExpectation("/[Attach", false),
      peg$c1468 = function(head, tail) { return util.pegList(head, tail, 2, true); },
      peg$c1469 = "with Stacking]",
      peg$c1470 = peg$literalExpectation("with Stacking]", false),
      peg$c1471 = function(head, level, tail) { return { elements: util.pegList(head, tail, 2, true), level }; },
      peg$c1472 = peg$otherExpectation("ability school"),
      peg$c1473 = "Bard",
      peg$c1474 = peg$literalExpectation("Bard", false),
      peg$c1475 = "Celerity",
      peg$c1476 = peg$literalExpectation("Celerity", false),
      peg$c1477 = "Combat",
      peg$c1478 = peg$literalExpectation("Combat", false),
      peg$c1479 = "Dancer",
      peg$c1480 = peg$literalExpectation("Dancer", false),
      peg$c1481 = "Darkness",
      peg$c1482 = peg$literalExpectation("Darkness", false),
      peg$c1483 = "Dragoon",
      peg$c1484 = peg$literalExpectation("Dragoon", false),
      peg$c1485 = "Heavy",
      peg$c1486 = peg$literalExpectation("Heavy", false),
      peg$c1487 = "Knight",
      peg$c1488 = peg$literalExpectation("Knight", false),
      peg$c1489 = "Machinist",
      peg$c1490 = peg$literalExpectation("Machinist", false),
      peg$c1491 = "Monk",
      peg$c1492 = peg$literalExpectation("Monk", false),
      peg$c1493 = "Ninja",
      peg$c1494 = peg$literalExpectation("Ninja", false),
      peg$c1495 = "Samurai",
      peg$c1496 = peg$literalExpectation("Samurai", false),
      peg$c1497 = "Sharpshooter",
      peg$c1498 = peg$literalExpectation("Sharpshooter", false),
      peg$c1499 = "Special",
      peg$c1500 = peg$literalExpectation("Special", false),
      peg$c1501 = "Spellblade",
      peg$c1502 = peg$literalExpectation("Spellblade", false),
      peg$c1503 = "Summoning",
      peg$c1504 = peg$literalExpectation("Summoning", false),
      peg$c1505 = "Support",
      peg$c1506 = peg$literalExpectation("Support", false),
      peg$c1507 = "Thief",
      peg$c1508 = peg$literalExpectation("Thief", false),
      peg$c1509 = "Witch",
      peg$c1510 = peg$literalExpectation("Witch", false),
      peg$c1511 = peg$otherExpectation("school list"),
      peg$c1512 = "Soul",
      peg$c1513 = peg$literalExpectation("Soul", false),
      peg$c1514 = "Break",
      peg$c1515 = peg$literalExpectation("Break", false),
      peg$c1516 = "SB",
      peg$c1517 = peg$literalExpectation("SB", false),
      peg$c1518 = "maximum",
      peg$c1519 = peg$literalExpectation("maximum", false),
      peg$c1520 = "max",
      peg$c1521 = peg$literalExpectation("max", false),
      peg$c1522 = function(head, tail) { for (const i of tail) { if (i[1].y !== head.y) { return false; } }; return true; },
      peg$c1523 = function(head, tail) {
          const list = util.pegList(head, tail, 1, false);
          return { x: util.scalarify(list.map(i => i.x)), y: list[0].y };
        },
      peg$c1524 = function(head, tail, y) {
          return { x: util.pegList(head, tail, 1, true), y };
        },
      peg$c1525 = function(x, y) { return { x, y }; },
      peg$c1526 = peg$otherExpectation("realm"),
      peg$c1527 = "Core/Beyond",
      peg$c1528 = peg$literalExpectation("Core/Beyond", false),
      peg$c1529 = "Beyond",
      peg$c1530 = peg$literalExpectation("Beyond", false),
      peg$c1531 = "Core",
      peg$c1532 = peg$literalExpectation("Core", false),
      peg$c1533 = "IX",
      peg$c1534 = peg$literalExpectation("IX", false),
      peg$c1535 = "IV",
      peg$c1536 = peg$literalExpectation("IV", false),
      peg$c1537 = "FFT",
      peg$c1538 = peg$literalExpectation("FFT", false),
      peg$c1539 = "III",
      peg$c1540 = peg$literalExpectation("III", false),
      peg$c1541 = "II",
      peg$c1542 = peg$literalExpectation("II", false),
      peg$c1543 = "I",
      peg$c1544 = peg$literalExpectation("I", false),
      peg$c1545 = "VIII",
      peg$c1546 = peg$literalExpectation("VIII", false),
      peg$c1547 = "VII",
      peg$c1548 = peg$literalExpectation("VII", false),
      peg$c1549 = "VI",
      peg$c1550 = peg$literalExpectation("VI", false),
      peg$c1551 = "V",
      peg$c1552 = peg$literalExpectation("V", false),
      peg$c1553 = "XV",
      peg$c1554 = peg$literalExpectation("XV", false),
      peg$c1555 = "XIV",
      peg$c1556 = peg$literalExpectation("XIV", false),
      peg$c1557 = "XIII",
      peg$c1558 = peg$literalExpectation("XIII", false),
      peg$c1559 = "XII",
      peg$c1560 = peg$literalExpectation("XII", false),
      peg$c1561 = "XI",
      peg$c1562 = peg$literalExpectation("XI", false),
      peg$c1563 = "X",
      peg$c1564 = peg$literalExpectation("X", false),
      peg$c1565 = "KH",
      peg$c1566 = peg$literalExpectation("KH", false),
      peg$c1567 = "Type-0",
      peg$c1568 = peg$literalExpectation("Type-0", false),
      peg$c1569 = "FF10",
      peg$c1570 = peg$literalExpectation("FF10", false),
      peg$c1571 = function() { return 'X'; },
      peg$c1572 = "FF11",
      peg$c1573 = peg$literalExpectation("FF11", false),
      peg$c1574 = function() { return 'XI'; },
      peg$c1575 = "FF12",
      peg$c1576 = peg$literalExpectation("FF12", false),
      peg$c1577 = function() { return 'XII'; },
      peg$c1578 = "FF13",
      peg$c1579 = peg$literalExpectation("FF13", false),
      peg$c1580 = function() { return 'XIII'; },
      peg$c1581 = "FF14",
      peg$c1582 = peg$literalExpectation("FF14", false),
      peg$c1583 = function() { return 'XIV'; },
      peg$c1584 = "FF15",
      peg$c1585 = peg$literalExpectation("FF15", false),
      peg$c1586 = function() { return 'XV'; },
      peg$c1587 = "FF1",
      peg$c1588 = peg$literalExpectation("FF1", false),
      peg$c1589 = function() { return 'I'; },
      peg$c1590 = "FF2",
      peg$c1591 = peg$literalExpectation("FF2", false),
      peg$c1592 = function() { return 'II'; },
      peg$c1593 = "FF3",
      peg$c1594 = peg$literalExpectation("FF3", false),
      peg$c1595 = function() { return 'III'; },
      peg$c1596 = "FF4",
      peg$c1597 = peg$literalExpectation("FF4", false),
      peg$c1598 = function() { return 'IV'; },
      peg$c1599 = "FF5",
      peg$c1600 = peg$literalExpectation("FF5", false),
      peg$c1601 = function() { return 'V'; },
      peg$c1602 = "FF6",
      peg$c1603 = peg$literalExpectation("FF6", false),
      peg$c1604 = function() { return 'VI'; },
      peg$c1605 = "FF7",
      peg$c1606 = peg$literalExpectation("FF7", false),
      peg$c1607 = function() { return 'VII'; },
      peg$c1608 = "FF8",
      peg$c1609 = peg$literalExpectation("FF8", false),
      peg$c1610 = function() { return 'VIII'; },
      peg$c1611 = "FF9",
      peg$c1612 = peg$literalExpectation("FF9", false),
      peg$c1613 = function() { return 'IX'; },
      peg$c1614 = "FF IX",
      peg$c1615 = peg$literalExpectation("FF IX", false),
      peg$c1616 = function() { return "IX"; },
      peg$c1617 = "FF IV",
      peg$c1618 = peg$literalExpectation("FF IV", false),
      peg$c1619 = function() { return "IV"; },
      peg$c1620 = "FF FFT",
      peg$c1621 = peg$literalExpectation("FF FFT", false),
      peg$c1622 = function() { return "FFT"; },
      peg$c1623 = "FF III",
      peg$c1624 = peg$literalExpectation("FF III", false),
      peg$c1625 = function() { return "III"; },
      peg$c1626 = "FF II",
      peg$c1627 = peg$literalExpectation("FF II", false),
      peg$c1628 = function() { return "II"; },
      peg$c1629 = "FF I",
      peg$c1630 = peg$literalExpectation("FF I", false),
      peg$c1631 = function() { return "I"; },
      peg$c1632 = "FF VIII",
      peg$c1633 = peg$literalExpectation("FF VIII", false),
      peg$c1634 = function() { return "VIII"; },
      peg$c1635 = "FF VII",
      peg$c1636 = peg$literalExpectation("FF VII", false),
      peg$c1637 = function() { return "VII"; },
      peg$c1638 = "FF VI",
      peg$c1639 = peg$literalExpectation("FF VI", false),
      peg$c1640 = function() { return "VI"; },
      peg$c1641 = "FF V",
      peg$c1642 = peg$literalExpectation("FF V", false),
      peg$c1643 = function() { return "V"; },
      peg$c1644 = "FF XV",
      peg$c1645 = peg$literalExpectation("FF XV", false),
      peg$c1646 = function() { return "XV"; },
      peg$c1647 = "FF XIV",
      peg$c1648 = peg$literalExpectation("FF XIV", false),
      peg$c1649 = function() { return "XIV"; },
      peg$c1650 = "FF XIII",
      peg$c1651 = peg$literalExpectation("FF XIII", false),
      peg$c1652 = function() { return "XIII"; },
      peg$c1653 = "FF XII",
      peg$c1654 = peg$literalExpectation("FF XII", false),
      peg$c1655 = function() { return "XII"; },
      peg$c1656 = "FF XI",
      peg$c1657 = peg$literalExpectation("FF XI", false),
      peg$c1658 = function() { return "XI"; },
      peg$c1659 = "FF X",
      peg$c1660 = peg$literalExpectation("FF X", false),
      peg$c1661 = function() { return "X"; },
      peg$c1662 = "Tactics",
      peg$c1663 = peg$literalExpectation("Tactics", false),
      peg$c1664 = "9999",
      peg$c1665 = peg$literalExpectation("9999", false),
      peg$c1666 = /^[1-9]/,
      peg$c1667 = peg$classExpectation([["1", "9"]], false, false),
      peg$c1668 = function() { return parseInt(text()); },
      peg$c1669 = peg$literalExpectation("increases", true),
      peg$c1670 = peg$literalExpectation("reduces", true),
      peg$c1671 = function() { return -1; },
      peg$c1672 = "decreases",
      peg$c1673 = peg$literalExpectation("decreases", true),
      peg$c1674 = ", and",
      peg$c1675 = peg$literalExpectation(", and", false),
      peg$c1676 = function() { return 'and'; },
      peg$c1677 = function() { return 'or'; },
      peg$c1678 = function() { return ','; },
      peg$c1679 = function() { return '/'; },
      peg$c1680 = peg$otherExpectation("numeric text"),
      peg$c1681 = /^[a-zA-Z\-]/,
      peg$c1682 = peg$classExpectation([["a", "z"], ["A", "Z"], "-"], false, false),
      peg$c1683 = function(numberString) { parsedNumberResult = util.parseNumberString(numberString.join('')); return parsedNumberResult != null; },
      peg$c1684 = function(numberString) { return parsedNumberResult; },
      peg$c1685 = peg$otherExpectation("decimal number"),
      peg$c1686 = /^[0-9.]/,
      peg$c1687 = peg$classExpectation([["0", "9"], "."], false, false),
      peg$c1688 = function() { return parseFloat(text()) },
      peg$c1689 = peg$otherExpectation("integer"),
      peg$c1690 = /^[0-9]/,
      peg$c1691 = peg$classExpectation([["0", "9"]], false, false),
      peg$c1692 = function() { return parseInt(text(), 10); },
      peg$c1693 = peg$otherExpectation("integer or X"),
      peg$c1694 = function(value) { return { value }; },
      peg$c1695 = peg$otherExpectation("percentage"),
      peg$c1696 = "X%",
      peg$c1697 = peg$literalExpectation("X%", false),
      peg$c1698 = peg$otherExpectation("signed integer"),
      peg$c1699 = function(sign, value) { return parseInt(sign + value.join(''), 10); },
      peg$c1700 = peg$otherExpectation("signed integer or X"),
      peg$c1701 = function() { return util.placeholder },
      peg$c1702 = "-X",
      peg$c1703 = peg$literalExpectation("-X", false),
      peg$c1704 = function() { return util.negativePlaceholder },
      peg$c1705 = peg$otherExpectation("integer (optionally negative)"),
      peg$c1706 = "-",
      peg$c1707 = peg$literalExpectation("-", false),
      peg$c1708 = function(sign, value) { return parseInt(text(), 10); },
      peg$c1709 = peg$otherExpectation("slash-separated decimal numbers"),
      peg$c1710 = function(head, tail) { return util.pegSlashList(head, tail); },
      peg$c1711 = peg$otherExpectation("slash-separated list of multipliers"),
      peg$c1712 = peg$otherExpectation("slash-separated integers"),
      peg$c1713 = peg$otherExpectation("slash-separated integers or range"),
      peg$c1714 = function(from, to) { return { from, to }; },
      peg$c1715 = peg$otherExpectation("slash-separated percent integers"),
      peg$c1716 = function(list) { return list; },
      peg$c1717 = peg$otherExpectation("slash-separated integer ranges"),
      peg$c1718 = function(from, to) { return from; },
      peg$c1719 = peg$otherExpectation("slash-separated signed integers"),
      peg$c1720 = function(sign, values) {
          const applySign = (i) => sign === '-' ? -i : i;
          if (Array.isArray(values)) {
            return values.map(applySign);
          } else {
            return applySign(values);
          }
        },
      peg$c1721 = peg$otherExpectation("integers separated with commas and 'and'"),
      peg$c1722 = "twice",
      peg$c1723 = peg$literalExpectation("twice", false),
      peg$c1724 = function() { return 2; },
      peg$c1725 = "time",
      peg$c1726 = peg$literalExpectation("time", false),
      peg$c1727 = "third",
      peg$c1728 = peg$literalExpectation("third", false),
      peg$c1729 = function() { return 3; },
      peg$c1730 = "fourth",
      peg$c1731 = peg$literalExpectation("fourth", false),
      peg$c1732 = function() { return 4; },
      peg$c1733 = "fifth",
      peg$c1734 = peg$literalExpectation("fifth", false),
      peg$c1735 = function() { return 5; },
      peg$c1736 = "dual",
      peg$c1737 = peg$literalExpectation("dual", true),
      peg$c1738 = "triple",
      peg$c1739 = peg$literalExpectation("triple", true),
      peg$c1740 = "quad",
      peg$c1741 = peg$literalExpectation("quad", true),
      peg$c1742 = "quint",
      peg$c1743 = peg$literalExpectation("quint", true),
      peg$c1744 = "sext",
      peg$c1745 = peg$literalExpectation("sext", true),
      peg$c1746 = function() { return 6; },
      peg$c1747 = function(numerator, denominator) { return { numerator, denominator }; },
      peg$c1748 = /^[A-Za-z]/,
      peg$c1749 = peg$classExpectation([["A", "Z"], ["a", "z"]], false, false),
      peg$c1750 = peg$otherExpectation("whitespace"),
      peg$c1751 = /^[ \t\n\r]/,
      peg$c1752 = peg$classExpectation([" ", "\t", "\n", "\r"], false, false),

      peg$currPos          = 0,
      peg$savedPos         = 0,
      peg$posDetailsCache  = [{ line: 1, column: 1 }],
      peg$maxFailPos       = 0,
      peg$maxFailExpected  = [],
      peg$silentFails      = 0,

      peg$resultsCache = {},

      peg$result;

  if ("startRule" in options) {
    if (!(options.startRule in peg$startRuleFunctions)) {
      throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
    }

    peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
  }

  function text() {
    return input.substring(peg$savedPos, peg$currPos);
  }

  function location() {
    return peg$computeLocation(peg$savedPos, peg$currPos);
  }

  function expected(description, location) {
    location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

    throw peg$buildStructuredError(
      [peg$otherExpectation(description)],
      input.substring(peg$savedPos, peg$currPos),
      location
    );
  }

  function error(message, location) {
    location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

    throw peg$buildSimpleError(message, location);
  }

  function peg$literalExpectation(text, ignoreCase) {
    return { type: "literal", text: text, ignoreCase: ignoreCase };
  }

  function peg$classExpectation(parts, inverted, ignoreCase) {
    return { type: "class", parts: parts, inverted: inverted, ignoreCase: ignoreCase };
  }

  function peg$anyExpectation() {
    return { type: "any" };
  }

  function peg$endExpectation() {
    return { type: "end" };
  }

  function peg$otherExpectation(description) {
    return { type: "other", description: description };
  }

  function peg$computePosDetails(pos) {
    var details = peg$posDetailsCache[pos], p;

    if (details) {
      return details;
    } else {
      p = pos - 1;
      while (!peg$posDetailsCache[p]) {
        p--;
      }

      details = peg$posDetailsCache[p];
      details = {
        line:   details.line,
        column: details.column
      };

      while (p < pos) {
        if (input.charCodeAt(p) === 10) {
          details.line++;
          details.column = 1;
        } else {
          details.column++;
        }

        p++;
      }

      peg$posDetailsCache[pos] = details;
      return details;
    }
  }

  function peg$computeLocation(startPos, endPos) {
    var startPosDetails = peg$computePosDetails(startPos),
        endPosDetails   = peg$computePosDetails(endPos);

    return {
      start: {
        offset: startPos,
        line:   startPosDetails.line,
        column: startPosDetails.column
      },
      end: {
        offset: endPos,
        line:   endPosDetails.line,
        column: endPosDetails.column
      }
    };
  }

  function peg$fail(expected) {
    if (peg$currPos < peg$maxFailPos) { return; }

    if (peg$currPos > peg$maxFailPos) {
      peg$maxFailPos = peg$currPos;
      peg$maxFailExpected = [];
    }

    peg$maxFailExpected.push(expected);
  }

  function peg$buildSimpleError(message, location) {
    return new peg$SyntaxError(message, null, null, location);
  }

  function peg$buildStructuredError(expected, found, location) {
    return new peg$SyntaxError(
      peg$SyntaxError.buildMessage(expected, found),
      expected,
      found,
      location
    );
  }

  function peg$parseStatusEffect() {
    var s0, s1, s2, s3, s4, s5, s6;

    var key    = peg$currPos * 255 + 0,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseEffectClause();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 44) {
        s4 = peg$c0;
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1); }
      }
      if (s4 === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 46) {
          s4 = peg$c2;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c3); }
        }
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parse_();
        if (s5 !== peg$FAILED) {
          s6 = peg$parseEffectClause();
          if (s6 !== peg$FAILED) {
            s4 = [s4, s5, s6];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 44) {
          s4 = peg$c0;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1); }
        }
        if (s4 === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 46) {
            s4 = peg$c2;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c3); }
          }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parse_();
          if (s5 !== peg$FAILED) {
            s6 = peg$parseEffectClause();
            if (s6 !== peg$FAILED) {
              s4 = [s4, s5, s6];
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c4(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$c5;
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c6();
      }
      s0 = s1;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseLegendMateriaEffect() {
    var s0, s1, s2, s3, s4, s5, s6;

    var key    = peg$currPos * 255 + 1,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseLmEffectClause();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 44) {
        s4 = peg$c0;
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1); }
      }
      if (s4 === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 46) {
          s4 = peg$c2;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c3); }
        }
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parse_();
        if (s5 !== peg$FAILED) {
          s6 = peg$parseLmEffectClause();
          if (s6 !== peg$FAILED) {
            s4 = [s4, s5, s6];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 44) {
          s4 = peg$c0;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1); }
        }
        if (s4 === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 46) {
            s4 = peg$c2;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c3); }
          }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parse_();
          if (s5 !== peg$FAILED) {
            s6 = peg$parseLmEffectClause();
            if (s6 !== peg$FAILED) {
              s4 = [s4, s5, s6];
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c4(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$c5;
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c6();
      }
      s0 = s1;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseEffectClause() {
    var s0;

    var key    = peg$currPos * 255 + 2,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$parseSwitchDraw();
    if (s0 === peg$FAILED) {
      s0 = peg$parseSwitchDrawAlt();
      if (s0 === peg$FAILED) {
        s0 = peg$parseSwitchDrawStacking();
        if (s0 === peg$FAILED) {
          s0 = peg$parseTriggeredEffect();
          if (s0 === peg$FAILED) {
            s0 = peg$parseCommonEffectClause();
            if (s0 === peg$FAILED) {
              s0 = peg$parseTurnDuration();
              if (s0 === peg$FAILED) {
                s0 = peg$parseRemovedUnlessStatus();
                if (s0 === peg$FAILED) {
                  s0 = peg$parseRemovedAfterTrigger();
                  if (s0 === peg$FAILED) {
                    s0 = peg$parseTrackStatusLevel();
                    if (s0 === peg$FAILED) {
                      s0 = peg$parseChangeStatusLevel();
                      if (s0 === peg$FAILED) {
                        s0 = peg$parseSetStatusLevel();
                        if (s0 === peg$FAILED) {
                          s0 = peg$parseStatusLevelBooster();
                          if (s0 === peg$FAILED) {
                            s0 = peg$parseBurstToggle();
                            if (s0 === peg$FAILED) {
                              s0 = peg$parseTrackUses();
                              if (s0 === peg$FAILED) {
                                s0 = peg$parseSharedCount();
                                if (s0 === peg$FAILED) {
                                  s0 = peg$parseModifiesSkill();
                                  if (s0 === peg$FAILED) {
                                    s0 = peg$parseBurstOnly();
                                    if (s0 === peg$FAILED) {
                                      s0 = peg$parseBurstReset();
                                      if (s0 === peg$FAILED) {
                                        s0 = peg$parseTrackGuardian();
                                        if (s0 === peg$FAILED) {
                                          s0 = peg$parseGuardianReset();
                                          if (s0 === peg$FAILED) {
                                            s0 = peg$parseStatusReset();
                                            if (s0 === peg$FAILED) {
                                              s0 = peg$parseReplaceAttack();
                                              if (s0 === peg$FAILED) {
                                                s0 = peg$parseReplaceAttackDefend();
                                                if (s0 === peg$FAILED) {
                                                  s0 = peg$parseDisableAttacks();
                                                  if (s0 === peg$FAILED) {
                                                    s0 = peg$parseAi();
                                                    if (s0 === peg$FAILED) {
                                                      s0 = peg$parseParalyze();
                                                      if (s0 === peg$FAILED) {
                                                        s0 = peg$parseStun();
                                                        if (s0 === peg$FAILED) {
                                                          s0 = peg$parseResetTarget();
                                                          if (s0 === peg$FAILED) {
                                                            s0 = peg$parseNoEffect();
                                                            if (s0 === peg$FAILED) {
                                                              s0 = peg$parsePersists();
                                                              if (s0 === peg$FAILED) {
                                                                s0 = peg$parseGameOver();
                                                                if (s0 === peg$FAILED) {
                                                                  s0 = peg$parseUnknown();
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseLmEffectClause() {
    var s0;

    var key    = peg$currPos * 255 + 3,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$parseAutoCureLm();
    if (s0 === peg$FAILED) {
      s0 = peg$parseTriggeredEffect();
      if (s0 === peg$FAILED) {
        s0 = peg$parseCommonEffectClause();
        if (s0 === peg$FAILED) {
          s0 = peg$parseStatBuildupLm();
          if (s0 === peg$FAILED) {
            s0 = peg$parseStatModDurationUpLm();
            if (s0 === peg$FAILED) {
              s0 = peg$parseStatShareLm();
              if (s0 === peg$FAILED) {
                s0 = peg$parseFullAtbRoundStartLm();
                if (s0 === peg$FAILED) {
                  s0 = peg$parseDamageResistLm();
                  if (s0 === peg$FAILED) {
                    s0 = peg$parseMulticastLm();
                    if (s0 === peg$FAILED) {
                      s0 = peg$parseHealUpLm();
                      if (s0 === peg$FAILED) {
                        s0 = peg$parseDrainHpLm();
                        if (s0 === peg$FAILED) {
                          s0 = peg$parseGilUpLm();
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseCommonEffectClause() {
    var s0;

    var key    = peg$currPos * 255 + 4,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$parseStatMod();
    if (s0 === peg$FAILED) {
      s0 = peg$parseCritChance();
      if (s0 === peg$FAILED) {
        s0 = peg$parseCritDamage();
        if (s0 === peg$FAILED) {
          s0 = peg$parseHitRate();
          if (s0 === peg$FAILED) {
            s0 = peg$parseKo();
            if (s0 === peg$FAILED) {
              s0 = peg$parseLastStand();
              if (s0 === peg$FAILED) {
                s0 = peg$parseRaise();
                if (s0 === peg$FAILED) {
                  s0 = peg$parseReraise();
                  if (s0 === peg$FAILED) {
                    s0 = peg$parseStatusChance();
                    if (s0 === peg$FAILED) {
                      s0 = peg$parseStatusStacking();
                      if (s0 === peg$FAILED) {
                        s0 = peg$parsePreventStatus();
                        if (s0 === peg$FAILED) {
                          s0 = peg$parseSpeed();
                          if (s0 === peg$FAILED) {
                            s0 = peg$parseInstacast();
                            if (s0 === peg$FAILED) {
                              s0 = peg$parseSchoolCastSpeed();
                              if (s0 === peg$FAILED) {
                                s0 = peg$parseCastSpeedBuildup();
                                if (s0 === peg$FAILED) {
                                  s0 = peg$parseCastSpeed();
                                  if (s0 === peg$FAILED) {
                                    s0 = peg$parseInstantAtb();
                                    if (s0 === peg$FAILED) {
                                      s0 = peg$parseAtbSpeed();
                                      if (s0 === peg$FAILED) {
                                        s0 = peg$parsePhysicalBlink();
                                        if (s0 === peg$FAILED) {
                                          s0 = peg$parseMagicBlink();
                                          if (s0 === peg$FAILED) {
                                            s0 = peg$parseDualBlink();
                                            if (s0 === peg$FAILED) {
                                              s0 = peg$parseElementBlink();
                                              if (s0 === peg$FAILED) {
                                                s0 = peg$parseStoneskin();
                                                if (s0 === peg$FAILED) {
                                                  s0 = peg$parseMagiciteStoneskin();
                                                  if (s0 === peg$FAILED) {
                                                    s0 = peg$parseFixedStoneskin();
                                                    if (s0 === peg$FAILED) {
                                                      s0 = peg$parseDamageBarrier();
                                                      if (s0 === peg$FAILED) {
                                                        s0 = peg$parseRadiantShield();
                                                        if (s0 === peg$FAILED) {
                                                          s0 = peg$parseReflect();
                                                          if (s0 === peg$FAILED) {
                                                            s0 = peg$parseAwoken();
                                                            if (s0 === peg$FAILED) {
                                                              s0 = peg$parseElementAttack();
                                                              if (s0 === peg$FAILED) {
                                                                s0 = peg$parseElementResist();
                                                                if (s0 === peg$FAILED) {
                                                                  s0 = peg$parseEnElement();
                                                                  if (s0 === peg$FAILED) {
                                                                    s0 = peg$parseEnElementStacking();
                                                                    if (s0 === peg$FAILED) {
                                                                      s0 = peg$parseEnElementWithStacking();
                                                                      if (s0 === peg$FAILED) {
                                                                        s0 = peg$parseLoseEnElement();
                                                                        if (s0 === peg$FAILED) {
                                                                          s0 = peg$parseLoseAnyEnElement();
                                                                          if (s0 === peg$FAILED) {
                                                                            s0 = peg$parseAbilityBuildup();
                                                                            if (s0 === peg$FAILED) {
                                                                              s0 = peg$parseRankBoost();
                                                                              if (s0 === peg$FAILED) {
                                                                                s0 = peg$parseDamageUp();
                                                                                if (s0 === peg$FAILED) {
                                                                                  s0 = peg$parseAltDamageUp();
                                                                                  if (s0 === peg$FAILED) {
                                                                                    s0 = peg$parseRealmBoost();
                                                                                    if (s0 === peg$FAILED) {
                                                                                      s0 = peg$parseAbilityDouble();
                                                                                      if (s0 === peg$FAILED) {
                                                                                        s0 = peg$parseMulticast();
                                                                                        if (s0 === peg$FAILED) {
                                                                                          s0 = peg$parseMulticastAbility();
                                                                                          if (s0 === peg$FAILED) {
                                                                                            s0 = peg$parseNoAirTime();
                                                                                            if (s0 === peg$FAILED) {
                                                                                              s0 = peg$parseBreakDamageCapAll();
                                                                                              if (s0 === peg$FAILED) {
                                                                                                s0 = peg$parseBreakDamageCap();
                                                                                                if (s0 === peg$FAILED) {
                                                                                                  s0 = peg$parseDamageCap();
                                                                                                  if (s0 === peg$FAILED) {
                                                                                                    s0 = peg$parseHpStock();
                                                                                                    if (s0 === peg$FAILED) {
                                                                                                      s0 = peg$parseRegen();
                                                                                                      if (s0 === peg$FAILED) {
                                                                                                        s0 = peg$parseFixedHpRegen();
                                                                                                        if (s0 === peg$FAILED) {
                                                                                                          s0 = peg$parsePoison();
                                                                                                          if (s0 === peg$FAILED) {
                                                                                                            s0 = peg$parseHealUp();
                                                                                                            if (s0 === peg$FAILED) {
                                                                                                              s0 = peg$parsePain();
                                                                                                              if (s0 === peg$FAILED) {
                                                                                                                s0 = peg$parseDamageTaken();
                                                                                                                if (s0 === peg$FAILED) {
                                                                                                                  s0 = peg$parseBarHeal();
                                                                                                                  if (s0 === peg$FAILED) {
                                                                                                                    s0 = peg$parseEmpowerHeal();
                                                                                                                    if (s0 === peg$FAILED) {
                                                                                                                      s0 = peg$parseDoom();
                                                                                                                      if (s0 === peg$FAILED) {
                                                                                                                        s0 = peg$parseDoomTimer();
                                                                                                                        if (s0 === peg$FAILED) {
                                                                                                                          s0 = peg$parseDrainHp();
                                                                                                                          if (s0 === peg$FAILED) {
                                                                                                                            s0 = peg$parseCounterWithImmune();
                                                                                                                            if (s0 === peg$FAILED) {
                                                                                                                              s0 = peg$parseCounter();
                                                                                                                              if (s0 === peg$FAILED) {
                                                                                                                                s0 = peg$parseCover();
                                                                                                                                if (s0 === peg$FAILED) {
                                                                                                                                  s0 = peg$parseConditionalStatus();
                                                                                                                                  if (s0 === peg$FAILED) {
                                                                                                                                    s0 = peg$parseGainSb();
                                                                                                                                    if (s0 === peg$FAILED) {
                                                                                                                                      s0 = peg$parseSbGainUp();
                                                                                                                                      if (s0 === peg$FAILED) {
                                                                                                                                        s0 = peg$parseGainLb();
                                                                                                                                        if (s0 === peg$FAILED) {
                                                                                                                                          s0 = peg$parseDirectGrantStatus();
                                                                                                                                          if (s0 === peg$FAILED) {
                                                                                                                                            s0 = peg$parseRunic();
                                                                                                                                            if (s0 === peg$FAILED) {
                                                                                                                                              s0 = peg$parseTaunt();
                                                                                                                                              if (s0 === peg$FAILED) {
                                                                                                                                                s0 = peg$parseImmuneAttackSkills();
                                                                                                                                                if (s0 === peg$FAILED) {
                                                                                                                                                  s0 = peg$parseImmuneAttacks();
                                                                                                                                                  if (s0 === peg$FAILED) {
                                                                                                                                                    s0 = peg$parseZeroDamage();
                                                                                                                                                    if (s0 === peg$FAILED) {
                                                                                                                                                      s0 = peg$parseEvadeAll();
                                                                                                                                                      if (s0 === peg$FAILED) {
                                                                                                                                                        s0 = peg$parseMultiplyDamage();
                                                                                                                                                        if (s0 === peg$FAILED) {
                                                                                                                                                          s0 = peg$parseBerserk();
                                                                                                                                                          if (s0 === peg$FAILED) {
                                                                                                                                                            s0 = peg$parseRage();
                                                                                                                                                            if (s0 === peg$FAILED) {
                                                                                                                                                              s0 = peg$parseAbilityBerserk();
                                                                                                                                                            }
                                                                                                                                                          }
                                                                                                                                                        }
                                                                                                                                                      }
                                                                                                                                                    }
                                                                                                                                                  }
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatMod() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    var key    = peg$currPos * 255 + 5,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseStatListOrPlaceholder();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseSignedIntegerOrX();
        if (s3 === peg$FAILED) {
          s3 = peg$currPos;
          if (peg$c7.test(input.charAt(peg$currPos))) {
            s4 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c8); }
          }
          if (s4 === peg$FAILED) {
            s4 = null;
          }
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 63) {
              s5 = peg$c9;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c10); }
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s3;
              s4 = peg$c11(s1);
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }
        if (s3 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 37) {
            s4 = peg$c12;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c13); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$currPos;
            s6 = peg$parse_();
            if (s6 !== peg$FAILED) {
              if (input.substr(peg$currPos, 33) === peg$c14) {
                s7 = peg$c14;
                peg$currPos += 33;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c15); }
              }
              if (s7 !== peg$FAILED) {
                s6 = [s6, s7];
                s5 = s6;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$currPos;
              peg$silentFails++;
              s7 = peg$currPos;
              s8 = peg$parse_();
              if (s8 !== peg$FAILED) {
                if (input.substr(peg$currPos, 3) === peg$c16) {
                  s9 = peg$c16;
                  peg$currPos += 3;
                } else {
                  s9 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c17); }
                }
                if (s9 !== peg$FAILED) {
                  s8 = [s8, s9];
                  s7 = s8;
                } else {
                  peg$currPos = s7;
                  s7 = peg$FAILED;
                }
              } else {
                peg$currPos = s7;
                s7 = peg$FAILED;
              }
              peg$silentFails--;
              if (s7 === peg$FAILED) {
                s6 = void 0;
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseCondition();
                  if (s8 === peg$FAILED) {
                    s8 = null;
                  }
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c18(s1, s3, s5, s8);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatBuildupLm() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17, s18, s19, s20, s21, s22;

    var key    = peg$currPos * 255 + 6,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseStatList();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 43) {
          s3 = peg$c19;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c20); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseInteger();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 49) === peg$c21) {
              s5 = peg$c21;
              peg$currPos += 49;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c22); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parseInteger();
              if (s6 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 37) {
                  s7 = peg$c12;
                  peg$currPos++;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c13); }
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c23(s1, s4, s6);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseStatList();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        if (s2 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 43) {
            s3 = peg$c19;
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c20); }
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parseInteger();
            if (s4 !== peg$FAILED) {
              if (input.substr(peg$currPos, 25) === peg$c24) {
                s5 = peg$c24;
                peg$currPos += 25;
              } else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c25); }
              }
              if (s5 !== peg$FAILED) {
                s6 = peg$parse_();
                if (s6 !== peg$FAILED) {
                  s7 = peg$parseSchool();
                  if (s7 !== peg$FAILED) {
                    s8 = peg$parse_();
                    if (s8 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 18) === peg$c26) {
                        s9 = peg$c26;
                        peg$currPos += 18;
                      } else {
                        s9 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c27); }
                      }
                      if (s9 !== peg$FAILED) {
                        s10 = peg$parseInteger();
                        if (s10 !== peg$FAILED) {
                          if (input.charCodeAt(peg$currPos) === 37) {
                            s11 = peg$c12;
                            peg$currPos++;
                          } else {
                            s11 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c13); }
                          }
                          if (s11 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c28(s1, s4, s7, s10);
                            s0 = s1;
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseStatList();
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          if (s2 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 43) {
              s3 = peg$c19;
              peg$currPos++;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c20); }
            }
            if (s3 !== peg$FAILED) {
              s4 = peg$parseInteger();
              if (s4 !== peg$FAILED) {
                if (input.substr(peg$currPos, 25) === peg$c24) {
                  s5 = peg$c24;
                  peg$currPos += 25;
                } else {
                  s5 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c25); }
                }
                if (s5 !== peg$FAILED) {
                  s6 = peg$parse_();
                  if (s6 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 8) === peg$c29) {
                      s7 = peg$c29;
                      peg$currPos += 8;
                    } else {
                      s7 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c30); }
                    }
                    if (s7 === peg$FAILED) {
                      s7 = null;
                    }
                    if (s7 !== peg$FAILED) {
                      s8 = peg$parse_();
                      if (s8 !== peg$FAILED) {
                        s9 = peg$parseSkillType();
                        if (s9 === peg$FAILED) {
                          s9 = null;
                        }
                        if (s9 !== peg$FAILED) {
                          s10 = peg$parse_();
                          if (s10 !== peg$FAILED) {
                            if (input.substr(peg$currPos, 4) === peg$c31) {
                              s11 = peg$c31;
                              peg$currPos += 4;
                            } else {
                              s11 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c32); }
                            }
                            if (s11 === peg$FAILED) {
                              s11 = null;
                            }
                            if (s11 !== peg$FAILED) {
                              s12 = peg$parse_();
                              if (s12 !== peg$FAILED) {
                                if (input.substr(peg$currPos, 5) === peg$c33) {
                                  s13 = peg$c33;
                                  peg$currPos += 5;
                                } else {
                                  s13 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c34); }
                                }
                                if (s13 === peg$FAILED) {
                                  s13 = null;
                                }
                                if (s13 !== peg$FAILED) {
                                  s14 = peg$parse_();
                                  if (s14 !== peg$FAILED) {
                                    if (input.substr(peg$currPos, 7) === peg$c35) {
                                      s15 = peg$c35;
                                      peg$currPos += 7;
                                    } else {
                                      s15 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c36); }
                                    }
                                    if (s15 !== peg$FAILED) {
                                      s16 = peg$currPos;
                                      s17 = peg$parse_();
                                      if (s17 !== peg$FAILED) {
                                        if (input.substr(peg$currPos, 9) === peg$c37) {
                                          s18 = peg$c37;
                                          peg$currPos += 9;
                                        } else {
                                          s18 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c38); }
                                        }
                                        if (s18 !== peg$FAILED) {
                                          s19 = peg$parse_();
                                          if (s19 !== peg$FAILED) {
                                            s20 = peg$parseElement();
                                            if (s20 !== peg$FAILED) {
                                              s21 = peg$parse_();
                                              if (s21 !== peg$FAILED) {
                                                if (input.substr(peg$currPos, 6) === peg$c39) {
                                                  s22 = peg$c39;
                                                  peg$currPos += 6;
                                                } else {
                                                  s22 = peg$FAILED;
                                                  if (peg$silentFails === 0) { peg$fail(peg$c40); }
                                                }
                                                if (s22 !== peg$FAILED) {
                                                  peg$savedPos = s16;
                                                  s17 = peg$c41(s1, s4, s7, s9, s11, s13, s20);
                                                  s16 = s17;
                                                } else {
                                                  peg$currPos = s16;
                                                  s16 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s16;
                                                s16 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s16;
                                              s16 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s16;
                                            s16 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s16;
                                          s16 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s16;
                                        s16 = peg$FAILED;
                                      }
                                      if (s16 === peg$FAILED) {
                                        s16 = null;
                                      }
                                      if (s16 !== peg$FAILED) {
                                        if (input.substr(peg$currPos, 9) === peg$c42) {
                                          s17 = peg$c42;
                                          peg$currPos += 9;
                                        } else {
                                          s17 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c43); }
                                        }
                                        if (s17 !== peg$FAILED) {
                                          s18 = peg$parseInteger();
                                          if (s18 !== peg$FAILED) {
                                            if (input.charCodeAt(peg$currPos) === 37) {
                                              s19 = peg$c12;
                                              peg$currPos++;
                                            } else {
                                              s19 = peg$FAILED;
                                              if (peg$silentFails === 0) { peg$fail(peg$c13); }
                                            }
                                            if (s19 !== peg$FAILED) {
                                              peg$savedPos = s0;
                                              s1 = peg$c44(s1, s4, s7, s9, s11, s13, s16, s18);
                                              s0 = s1;
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s0;
                                          s0 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatModDurationUpLm() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    var key    = peg$currPos * 255 + 7,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 30) === peg$c45) {
      s1 = peg$c45;
      peg$currPos += 30;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c46); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 5) === peg$c47) {
          s3 = peg$c47;
          peg$currPos += 5;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c48); }
        }
        if (s3 === peg$FAILED) {
          if (input.substr(peg$currPos, 7) === peg$c49) {
            s3 = peg$c49;
            peg$currPos += 7;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c50); }
          }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c51) {
              s5 = peg$c51;
              peg$currPos += 2;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c52); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseInteger();
                if (s7 !== peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 37) {
                    s8 = peg$c12;
                    peg$currPos++;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c13); }
                  }
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c53(s3, s7);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatShareLm() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10;

    var key    = peg$currPos * 255 + 8,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 14) === peg$c54) {
      s1 = peg$c54;
      peg$currPos += 14;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c55); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseStat();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c51) {
              s5 = peg$c51;
              peg$currPos += 2;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c52); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseInteger();
                if (s7 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 6) === peg$c56) {
                    s8 = peg$c56;
                    peg$currPos += 6;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c57); }
                  }
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parse_();
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parseStat();
                      if (s10 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c58(s3, s7, s10);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseCritChance() {
    var s0, s1, s2;

    var key    = peg$currPos * 255 + 9,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 17).toLowerCase() === peg$c59) {
      s1 = input.substr(peg$currPos, 17);
      peg$currPos += 17;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c60); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsePercentSlashList();
      if (s2 === peg$FAILED) {
        s2 = peg$parsePercentOrX();
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c61(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseCritDamage() {
    var s0, s1, s2, s3, s4;

    var key    = peg$currPos * 255 + 10,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 18) === peg$c62) {
      s1 = peg$c62;
      peg$currPos += 18;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c63); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseIntegerOrX();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 59) === peg$c64) {
            s4 = peg$c64;
            peg$currPos += 59;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c65); }
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c66(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseHitRate() {
    var s0, s1, s2, s3, s4, s5, s6;

    var key    = peg$currPos * 255 + 11,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseIncreasesOrReduces();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 11) === peg$c67) {
          s3 = peg$c67;
          peg$currPos += 11;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c68); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseInteger();
            if (s5 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 37) {
                s6 = peg$c12;
                peg$currPos++;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c13); }
              }
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c69(s1, s5);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatusChance() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    var key    = peg$currPos * 255 + 12,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 44) === peg$c70) {
      s1 = peg$c70;
      peg$currPos += 44;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c71); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseIntegerOrX();
        if (s3 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 37) {
            s4 = peg$c12;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c13); }
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c72(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 44) === peg$c73) {
        s1 = peg$c73;
        peg$currPos += 44;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c74); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseStatusName();
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            if (s4 !== peg$FAILED) {
              if (input.substr(peg$currPos, 2) === peg$c51) {
                s5 = peg$c51;
                peg$currPos += 2;
              } else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c52); }
              }
              if (s5 !== peg$FAILED) {
                s6 = peg$parse_();
                if (s6 !== peg$FAILED) {
                  s7 = peg$parseInteger();
                  if (s7 !== peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 37) {
                      s8 = peg$c12;
                      peg$currPos++;
                    } else {
                      s8 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c13); }
                    }
                    if (s8 === peg$FAILED) {
                      s8 = null;
                    }
                    if (s8 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c75(s3, s7);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatusStacking() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    var key    = peg$currPos * 255 + 13,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c76) {
      s1 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c77); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 8) === peg$c78) {
          s3 = peg$c78;
          peg$currPos += 8;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c79); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseGenericName();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 7) === peg$c80) {
                s6 = peg$c80;
                peg$currPos += 7;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c81); }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseGenericName();
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = peg$currPos;
                    s9 = peg$c82(s5, s8);
                    if (s9) {
                      s9 = void 0;
                    } else {
                      s9 = peg$FAILED;
                    }
                    if (s9 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c83(s5, s8);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parsePreventStatus() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    var key    = peg$currPos * 255 + 14,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8) === peg$c84) {
      s1 = peg$c84;
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c85); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseGenericName();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = [];
            s6 = peg$currPos;
            s7 = peg$parseAndList();
            if (s7 !== peg$FAILED) {
              s8 = peg$parseGenericName();
              if (s8 !== peg$FAILED) {
                s7 = [s7, s8];
                s6 = s7;
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
            while (s6 !== peg$FAILED) {
              s5.push(s6);
              s6 = peg$currPos;
              s7 = peg$parseAndList();
              if (s7 !== peg$FAILED) {
                s8 = peg$parseGenericName();
                if (s8 !== peg$FAILED) {
                  s7 = [s7, s8];
                  s6 = s7;
                } else {
                  peg$currPos = s6;
                  s6 = peg$FAILED;
                }
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 4) === peg$c86) {
                  s7 = peg$c86;
                  peg$currPos += 4;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c87); }
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c88(s3, s5);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSpeed() {
    var s0, s1, s2, s3, s4;

    var key    = peg$currPos * 255 + 15,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c89) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c90); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 7) === peg$c91) {
          s3 = peg$c91;
          peg$currPos += 7;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c92); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseDecimalNumber();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c93(s4);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseInstacast() {
    var s0, s1, s2, s3, s4, s5, s6;

    var key    = peg$currPos * 255 + 16,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c94) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c95); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 10) === peg$c96) {
          s3 = peg$c96;
          peg$currPos += 10;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c97); }
        }
        if (s3 !== peg$FAILED) {
          s4 = [];
          if (input.charCodeAt(peg$currPos) === 57) {
            s5 = peg$c98;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c99); }
          }
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            if (input.charCodeAt(peg$currPos) === 57) {
              s5 = peg$c98;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c99); }
            }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseForAbilities();
              if (s6 === peg$FAILED) {
                s6 = null;
              }
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c100(s6);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSchoolCastSpeed() {
    var s0, s1, s2, s3, s4;

    var key    = peg$currPos * 255 + 17,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 12) === peg$c101) {
      s1 = peg$c101;
      peg$currPos += 12;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c102); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseIntegerOrX();
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 63) === peg$c103) {
            s4 = peg$c103;
            peg$currPos += 63;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c104); }
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c105(s2);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseCastSpeed() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 255 + 18,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c94) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c95); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 5) === peg$c106) {
          s3 = peg$c106;
          peg$currPos += 5;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c107); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseMultiplierSlashList();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseForAbilities();
                if (s7 === peg$FAILED) {
                  s7 = null;
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c108(s5, s7);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseElementOrSchoolList();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        if (s2 !== peg$FAILED) {
          if (input.substr(peg$currPos, 10) === peg$c109) {
            s3 = peg$c109;
            peg$currPos += 10;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c110); }
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            if (s4 !== peg$FAILED) {
              s5 = peg$parseMultiplierSlashList();
              if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c111(s1, s5);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseCastSpeedBuildup() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14;

    var key    = peg$currPos * 255 + 19,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c94) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c95); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 7) === peg$c91) {
          s3 = peg$c91;
          peg$currPos += 7;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c92); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseDecimalNumber();
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 6) === peg$c112) {
                s6 = peg$c112;
                peg$currPos += 6;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c113); }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parseDecimalNumber();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 8) === peg$c114) {
                      s9 = peg$c114;
                      peg$currPos += 8;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c115); }
                    }
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parse_();
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parseAbilityOrAttack();
                        if (s11 !== peg$FAILED) {
                          s12 = peg$parse_();
                          if (s12 !== peg$FAILED) {
                            if (input.substr(peg$currPos, 44) === peg$c116) {
                              s13 = peg$c116;
                              peg$currPos += 44;
                            } else {
                              s13 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c117); }
                            }
                            if (s13 !== peg$FAILED) {
                              s14 = peg$parseDecimalNumber();
                              if (s14 !== peg$FAILED) {
                                peg$savedPos = s0;
                                s1 = peg$c118(s4, s7, s11, s14);
                                s0 = s1;
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseFullAtbRoundStartLm() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 20,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c119) {
      s1 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c120); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 29) === peg$c121) {
          s3 = peg$c121;
          peg$currPos += 29;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c122); }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c123();
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseInstantAtb() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 21,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8).toLowerCase() === peg$c124) {
      s1 = input.substr(peg$currPos, 8);
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c125); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 24) === peg$c126) {
          s3 = peg$c126;
          peg$currPos += 24;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c127); }
        }
        if (s3 !== peg$FAILED) {
          s4 = [];
          if (input.charCodeAt(peg$currPos) === 57) {
            s5 = peg$c98;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c99); }
          }
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            if (input.charCodeAt(peg$currPos) === 57) {
              s5 = peg$c98;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c99); }
            }
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c128();
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAtbSpeed() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 22,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8).toLowerCase() === peg$c124) {
      s1 = input.substr(peg$currPos, 8);
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c125); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 21) === peg$c129) {
          s3 = peg$c129;
          peg$currPos += 21;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c130); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseDecimalNumber();
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c131(s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseForAbilities() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 23,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3) === peg$c16) {
      s1 = peg$c16;
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c17); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseElementOrSchoolList();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseAbilityOrAttack();
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c132(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 62) === peg$c133) {
        s1 = peg$c133;
        peg$currPos += 62;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c134); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c135();
      }
      s0 = s1;
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 3) === peg$c16) {
          s1 = peg$c16;
          peg$currPos += 3;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c17); }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          if (s2 !== peg$FAILED) {
            s3 = peg$parseSkillTypeList();
            if (s3 !== peg$FAILED) {
              s4 = peg$parse_();
              if (s4 !== peg$FAILED) {
                if (input.substr(peg$currPos, 7) === peg$c35) {
                  s5 = peg$c35;
                  peg$currPos += 7;
                } else {
                  s5 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c36); }
                }
                if (s5 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c136(s3);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.substr(peg$currPos, 23) === peg$c137) {
            s1 = peg$c137;
            peg$currPos += 23;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c138); }
          }
          if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            if (s2 !== peg$FAILED) {
              s3 = peg$parseElementList();
              if (s3 !== peg$FAILED) {
                s4 = peg$parse_();
                if (s4 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 6) === peg$c39) {
                    s5 = peg$c39;
                    peg$currPos += 6;
                  } else {
                    s5 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c40); }
                  }
                  if (s5 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c139(s3);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            if (input.substr(peg$currPos, 16) === peg$c140) {
              s1 = peg$c140;
              peg$currPos += 16;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c141); }
            }
            if (s1 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c142();
            }
            s0 = s1;
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              if (input.substr(peg$currPos, 18) === peg$c143) {
                s1 = peg$c143;
                peg$currPos += 18;
              } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c144); }
              }
              if (s1 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c135();
              }
              s0 = s1;
              if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (input.substr(peg$currPos, 3) === peg$c16) {
                  s1 = peg$c16;
                  peg$currPos += 3;
                } else {
                  s1 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c17); }
                }
                if (s1 !== peg$FAILED) {
                  s2 = peg$parse_();
                  if (s2 !== peg$FAILED) {
                    s3 = peg$parseAnySkillName();
                    if (s3 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c145(s3);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              }
            }
          }
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parsePhysicalBlink() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15;

    var key    = peg$currPos * 255 + 24,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c146) {
      s1 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c147); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 8) === peg$c148) {
          s3 = peg$c148;
          peg$currPos += 8;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c149); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseInteger();
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 3) === peg$c150) {
                  s7 = peg$c150;
                  peg$currPos += 3;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c151); }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseAttacksThatDeal();
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parse_();
                      if (s10 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 43) === peg$c152) {
                          s11 = peg$c152;
                          peg$currPos += 43;
                        } else {
                          s11 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c153); }
                        }
                        if (s11 !== peg$FAILED) {
                          s12 = peg$parse_();
                          if (s12 !== peg$FAILED) {
                            s13 = peg$parseAttacksThatDeal();
                            if (s13 !== peg$FAILED) {
                              s14 = peg$parse_();
                              if (s14 !== peg$FAILED) {
                                if (input.substr(peg$currPos, 29) === peg$c154) {
                                  s15 = peg$c154;
                                  peg$currPos += 29;
                                } else {
                                  s15 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c155); }
                                }
                                if (s15 !== peg$FAILED) {
                                  peg$savedPos = s0;
                                  s1 = peg$c156(s5);
                                  s0 = s1;
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseMagicBlink() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11;

    var key    = peg$currPos * 255 + 25,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c146) {
      s1 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c147); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 8) === peg$c148) {
          s3 = peg$c148;
          peg$currPos += 8;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c149); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseInteger();
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 16) === peg$c157) {
                  s7 = peg$c157;
                  peg$currPos += 16;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c158); }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseAttacksThatDeal();
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parse_();
                      if (s10 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 40) === peg$c159) {
                          s11 = peg$c159;
                          peg$currPos += 40;
                        } else {
                          s11 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c160); }
                        }
                        if (s11 !== peg$FAILED) {
                          peg$savedPos = s0;
                          s1 = peg$c161(s5);
                          s0 = s1;
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseDualBlink() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10;

    var key    = peg$currPos * 255 + 26,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c146) {
      s1 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c147); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 8) === peg$c148) {
          s3 = peg$c148;
          peg$currPos += 8;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c149); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseNumberString();
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 6) === peg$c162) {
                  s7 = peg$c162;
                  peg$currPos += 6;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c163); }
                }
                if (s7 !== peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 115) {
                    s8 = peg$c164;
                    peg$currPos++;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c165); }
                  }
                  if (s8 === peg$FAILED) {
                    s8 = null;
                  }
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parse_();
                    if (s9 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 67) === peg$c166) {
                        s10 = peg$c166;
                        peg$currPos += 67;
                      } else {
                        s10 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c167); }
                      }
                      if (s10 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c168(s5);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseElementBlink() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    var key    = peg$currPos * 255 + 27,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7).toLowerCase() === peg$c169) {
      s1 = input.substr(peg$currPos, 7);
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c170); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 22) === peg$c171) {
          s3 = peg$c171;
          peg$currPos += 22;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c172); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseAttacksThatDeal();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseElement();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 11) === peg$c173) {
                      s9 = peg$c173;
                      peg$currPos += 11;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c174); }
                    }
                    if (s9 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c175(s7);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAttacksThatDeal() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 28,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6) === peg$c162) {
      s1 = peg$c162;
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c163); }
    }
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 115) {
        s2 = peg$c164;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c165); }
      }
      if (s2 === peg$FAILED) {
        s2 = null;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 9) === peg$c37) {
            s4 = peg$c37;
            peg$currPos += 9;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c38); }
          }
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 115) {
              s5 = peg$c164;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c165); }
            }
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              s1 = [s1, s2, s3, s4, s5];
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStoneskin() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12;

    var key    = peg$currPos * 255 + 29,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7) === peg$c176) {
      s1 = peg$c176;
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c177); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseElement();
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 34) === peg$c178) {
              s5 = peg$c178;
              peg$currPos += 34;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c179); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 9) === peg$c180) {
                  s7 = peg$c180;
                  peg$currPos += 9;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c181); }
                }
                if (s7 === peg$FAILED) {
                  s7 = null;
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 8) === peg$c182) {
                      s9 = peg$c182;
                      peg$currPos += 8;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c183); }
                    }
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parse_();
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parseInteger();
                        if (s11 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 31) === peg$c184) {
                            s12 = peg$c184;
                            peg$currPos += 31;
                          } else {
                            s12 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c185); }
                          }
                          if (s12 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c186(s3, s11);
                            s0 = s1;
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseMagiciteStoneskin() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12;

    var key    = peg$currPos * 255 + 30,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7) === peg$c176) {
      s1 = peg$c176;
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c177); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseElement();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 34) === peg$c178) {
              s5 = peg$c178;
              peg$currPos += 34;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c179); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 9) === peg$c180) {
                  s7 = peg$c180;
                  peg$currPos += 9;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c181); }
                }
                if (s7 === peg$FAILED) {
                  s7 = null;
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 8) === peg$c182) {
                      s9 = peg$c182;
                      peg$currPos += 8;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c183); }
                    }
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parse_();
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parseInteger();
                        if (s11 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 30) === peg$c187) {
                            s12 = peg$c187;
                            peg$currPos += 30;
                          } else {
                            s12 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c188); }
                          }
                          if (s12 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c189(s3, s11);
                            s0 = s1;
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseFixedStoneskin() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    var key    = peg$currPos * 255 + 31,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 25) === peg$c190) {
      s1 = peg$c190;
      peg$currPos += 25;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c191); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseSkillTypeAndList();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 19) === peg$c192) {
              s5 = peg$c192;
              peg$currPos += 19;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c193); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseInteger();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 6) === peg$c39) {
                      s9 = peg$c39;
                      peg$currPos += 6;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c40); }
                    }
                    if (s9 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c194(s3, s7);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseDamageBarrier() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10;

    var key    = peg$currPos * 255 + 32,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 23) === peg$c195) {
      s1 = peg$c195;
      peg$currPos += 23;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c196); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseInteger();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 14) === peg$c197) {
            s4 = peg$c197;
            peg$currPos += 14;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c198); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              s6 = peg$currPos;
              s7 = peg$parseInteger();
              if (s7 === peg$FAILED) {
                s7 = peg$parseNumberString();
              }
              if (s7 !== peg$FAILED) {
                s8 = peg$parse_();
                if (s8 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 6) === peg$c162) {
                    s9 = peg$c162;
                    peg$currPos += 6;
                  } else {
                    s9 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c163); }
                  }
                  if (s9 !== peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 115) {
                      s10 = peg$c164;
                      peg$currPos++;
                    } else {
                      s10 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c165); }
                    }
                    if (s10 === peg$FAILED) {
                      s10 = null;
                    }
                    if (s10 !== peg$FAILED) {
                      peg$savedPos = s6;
                      s7 = peg$c199(s3, s7);
                      s6 = s7;
                    } else {
                      peg$currPos = s6;
                      s6 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s6;
                    s6 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s6;
                  s6 = peg$FAILED;
                }
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
              if (s6 === peg$FAILED) {
                s6 = peg$currPos;
                if (input.substr(peg$currPos, 6) === peg$c162) {
                  s7 = peg$c162;
                  peg$currPos += 6;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c163); }
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s6;
                  s7 = peg$c200(s3);
                }
                s6 = s7;
              }
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c201(s3, s6);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseRadiantShield() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12;

    var key    = peg$currPos * 255 + 33,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7).toLowerCase() === peg$c202) {
      s1 = input.substr(peg$currPos, 7);
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c203); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseRadiantShieldValue();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 32) === peg$c204) {
              s5 = peg$c204;
              peg$currPos += 32;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c205); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$currPos;
              s7 = peg$parse_();
              if (s7 !== peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c206) {
                  s8 = peg$c206;
                  peg$currPos += 2;
                } else {
                  s8 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c207); }
                }
                if (s8 !== peg$FAILED) {
                  s9 = peg$parse_();
                  if (s9 !== peg$FAILED) {
                    s10 = peg$parseElement();
                    if (s10 !== peg$FAILED) {
                      s11 = peg$parse_();
                      if (s11 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 6) === peg$c39) {
                          s12 = peg$c39;
                          peg$currPos += 6;
                        } else {
                          s12 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c40); }
                        }
                        if (s12 !== peg$FAILED) {
                          peg$savedPos = s6;
                          s7 = peg$c208(s3, s10);
                          s6 = s7;
                        } else {
                          peg$currPos = s6;
                          s6 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s6;
                        s6 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s6;
                      s6 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s6;
                    s6 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s6;
                  s6 = peg$FAILED;
                }
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
              if (s6 === peg$FAILED) {
                s6 = null;
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$currPos;
                s8 = peg$parse_();
                if (s8 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 15) === peg$c209) {
                    s9 = peg$c209;
                    peg$currPos += 15;
                  } else {
                    s9 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c210); }
                  }
                  if (s9 !== peg$FAILED) {
                    s8 = [s8, s9];
                    s7 = s8;
                  } else {
                    peg$currPos = s7;
                    s7 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s7;
                  s7 = peg$FAILED;
                }
                if (s7 === peg$FAILED) {
                  s7 = null;
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c211(s3, s6, s7);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseRadiantShieldValue() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 34,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3) === peg$c212) {
      s1 = peg$c212;
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c213); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c214) {
          s3 = peg$c214;
          peg$currPos += 2;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c215); }
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c216();
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseInteger();
      if (s1 !== peg$FAILED) {
        if (input.substr(peg$currPos, 4) === peg$c217) {
          s2 = peg$c217;
          peg$currPos += 4;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c218); }
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c219(s1);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseReflect() {
    var s0, s1;

    var key    = peg$currPos * 255 + 35,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 83) === peg$c220) {
      s1 = peg$c220;
      peg$currPos += 83;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c221); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c222();
    }
    s0 = s1;

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAwoken() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15;

    var key    = peg$currPos * 255 + 36,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseAwokenType();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 9) === peg$c223) {
          s3 = peg$c223;
          peg$currPos += 9;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c224); }
        }
        if (s3 === peg$FAILED) {
          if (input.substr(peg$currPos, 7) === peg$c35) {
            s3 = peg$c35;
            peg$currPos += 7;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c36); }
          }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 18) === peg$c225) {
              s5 = peg$c225;
              peg$currPos += 18;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c226); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseAwokenRankBoost();
                if (s7 === peg$FAILED) {
                  s7 = null;
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseAwokenRankCast();
                  if (s8 === peg$FAILED) {
                    s8 = null;
                  }
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseAwokenDualcast();
                    if (s9 === peg$FAILED) {
                      s9 = null;
                    }
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parseAwokenInstacast();
                      if (s10 === peg$FAILED) {
                        s10 = null;
                      }
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parseAwokenCastSpeed();
                        if (s11 === peg$FAILED) {
                          s11 = null;
                        }
                        if (s11 !== peg$FAILED) {
                          peg$savedPos = peg$currPos;
                          s12 = peg$c227(s1, s7, s8, s9, s10, s11);
                          if (s12) {
                            s12 = void 0;
                          } else {
                            s12 = peg$FAILED;
                          }
                          if (s12 !== peg$FAILED) {
                            peg$savedPos = peg$currPos;
                            s13 = peg$c228(s1, s7, s8, s9, s10, s11);
                            if (s13) {
                              s13 = void 0;
                            } else {
                              s13 = peg$FAILED;
                            }
                            if (s13 !== peg$FAILED) {
                              peg$savedPos = peg$currPos;
                              s14 = peg$c229(s1, s7, s8, s9, s10, s11);
                              if (s14) {
                                s14 = void 0;
                              } else {
                                s14 = peg$FAILED;
                              }
                              if (s14 !== peg$FAILED) {
                                peg$savedPos = peg$currPos;
                                s15 = peg$c230(s1, s7, s8, s9, s10, s11);
                                if (s15) {
                                  s15 = void 0;
                                } else {
                                  s15 = peg$FAILED;
                                }
                                if (s15 !== peg$FAILED) {
                                  peg$savedPos = s0;
                                  s1 = peg$c231(s1, s7, s8, s9, s10, s11);
                                  s0 = s1;
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAwokenType() {
    var s0, s1;

    var key    = peg$currPos * 255 + 37,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseSchoolAndOrList();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c232(s1);
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseElementAndOrList();
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c139(s1);
      }
      s0 = s1;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAwokenRankBoost() {
    var s0;

    var key    = peg$currPos * 255 + 38,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    if (input.substr(peg$currPos, 61) === peg$c233) {
      s0 = peg$c233;
      peg$currPos += 61;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c234); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAwokenRankCast() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 39,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 42) === peg$c235) {
      s1 = peg$c235;
      peg$currPos += 42;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c236); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseAwokenType();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 35) === peg$c237) {
              s5 = peg$c237;
              peg$currPos += 35;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c238); }
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c239(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAwokenDualcast() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 40,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 11) === peg$c240) {
      s1 = peg$c240;
      peg$currPos += 11;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c241); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseAwokenType();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 9) === peg$c223) {
              s5 = peg$c223;
              peg$currPos += 9;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c224); }
            }
            if (s5 === peg$FAILED) {
              if (input.substr(peg$currPos, 7) === peg$c35) {
                s5 = peg$c35;
                peg$currPos += 7;
              } else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c36); }
              }
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c239(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAwokenInstacast() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    var key    = peg$currPos * 255 + 41,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 17) === peg$c242) {
      s1 = peg$c242;
      peg$currPos += 17;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c243); }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      if (input.charCodeAt(peg$currPos) === 57) {
        s3 = peg$c98;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c99); }
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        if (input.charCodeAt(peg$currPos) === 57) {
          s3 = peg$c98;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c99); }
        }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 3) === peg$c16) {
            s4 = peg$c16;
            peg$currPos += 3;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c17); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseAwokenType();
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 9) === peg$c223) {
                    s8 = peg$c223;
                    peg$currPos += 9;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c224); }
                  }
                  if (s8 === peg$FAILED) {
                    if (input.substr(peg$currPos, 7) === peg$c35) {
                      s8 = peg$c35;
                      peg$currPos += 7;
                    } else {
                      s8 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c36); }
                    }
                  }
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c239(s6);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAwokenCastSpeed() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    var key    = peg$currPos * 255 + 42,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 14) === peg$c244) {
      s1 = peg$c244;
      peg$currPos += 14;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c245); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseDecimalNumber();
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 3) === peg$c16) {
            s4 = peg$c16;
            peg$currPos += 3;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c17); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseAwokenType();
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 9) === peg$c223) {
                    s8 = peg$c223;
                    peg$currPos += 9;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c224); }
                  }
                  if (s8 === peg$FAILED) {
                    if (input.substr(peg$currPos, 7) === peg$c35) {
                      s8 = peg$c35;
                      peg$currPos += 7;
                    } else {
                      s8 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c36); }
                    }
                  }
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c246(s2, s6);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSwitchDraw() {
    var s0, s1, s2, s3, s4, s5, s6;

    var key    = peg$currPos * 255 + 43,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseSwitchDrawPart();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 44) {
        s4 = peg$c0;
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1); }
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parse_();
        if (s5 !== peg$FAILED) {
          s6 = peg$parseSwitchDrawPart();
          if (s6 !== peg$FAILED) {
            s4 = [s4, s5, s6];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 44) {
            s4 = peg$c0;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseSwitchDrawPart();
              if (s6 !== peg$FAILED) {
                s4 = [s4, s5, s6];
                s3 = s4;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c247(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSwitchDrawPart() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12;

    var key    = peg$currPos * 255 + 44,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c248) {
      s1 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c249); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 7) === peg$c250) {
          s3 = peg$c250;
          peg$currPos += 7;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c251); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseElement();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 15) === peg$c252) {
                s6 = peg$c252;
                peg$currPos += 15;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c253); }
              }
              if (s6 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 110) {
                  s7 = peg$c254;
                  peg$currPos++;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c255); }
                }
                if (s7 === peg$FAILED) {
                  s7 = null;
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseElement();
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parse_();
                      if (s10 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 7) === peg$c256) {
                          s11 = peg$c256;
                          peg$currPos += 7;
                        } else {
                          s11 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c257); }
                        }
                        if (s11 !== peg$FAILED) {
                          peg$savedPos = peg$currPos;
                          s12 = peg$c258(s5, s9);
                          if (s12) {
                            s12 = void 0;
                          } else {
                            s12 = peg$FAILED;
                          }
                          if (s12 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c259(s5, s9);
                            s0 = s1;
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSwitchDrawAlt() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11;

    var key    = peg$currPos * 255 + 45,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c248) {
      s1 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c249); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseEnElementSlashList();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 13) === peg$c260) {
              s5 = peg$c260;
              peg$currPos += 13;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c261); }
            }
            if (s5 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 110) {
                s6 = peg$c254;
                peg$currPos++;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c255); }
              }
              if (s6 === peg$FAILED) {
                s6 = null;
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseElementSlashList();
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parse_();
                    if (s9 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 7) === peg$c256) {
                        s10 = peg$c256;
                        peg$currPos += 7;
                      } else {
                        s10 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c257); }
                      }
                      if (s10 !== peg$FAILED) {
                        peg$savedPos = peg$currPos;
                        s11 = peg$c262(s3, s8);
                        if (s11) {
                          s11 = void 0;
                        } else {
                          s11 = peg$FAILED;
                        }
                        if (s11 !== peg$FAILED) {
                          peg$savedPos = s0;
                          s1 = peg$c263(s3, s8);
                          s0 = s1;
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSwitchDrawStacking() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10;

    var key    = peg$currPos * 255 + 46,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c248) {
      s1 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c249); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseEnElementStackingSlashList();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 13) === peg$c260) {
              s5 = peg$c260;
              peg$currPos += 13;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c261); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseElementSlashList();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 7) === peg$c256) {
                      s9 = peg$c256;
                      peg$currPos += 7;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c257); }
                    }
                    if (s9 !== peg$FAILED) {
                      peg$savedPos = peg$currPos;
                      s10 = peg$c264(s3, s7);
                      if (s10) {
                        s10 = void 0;
                      } else {
                        s10 = peg$FAILED;
                      }
                      if (s10 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c265(s3, s7);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseElementAttack() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11;

    var key    = peg$currPos * 255 + 47,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseIncreasesOrReduces();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseElement();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 15) === peg$c266) {
              s5 = peg$c266;
              peg$currPos += 15;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c267); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parsePercentSlashList();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 11) === peg$c268) {
                      s9 = peg$c268;
                      peg$currPos += 11;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c269); }
                    }
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parse_();
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parseTrigger();
                        if (s11 === peg$FAILED) {
                          s11 = null;
                        }
                        if (s11 !== peg$FAILED) {
                          peg$savedPos = s0;
                          s1 = peg$c270(s1, s3, s7, s11);
                          s0 = s1;
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseElementResist() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 255 + 48,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseElementOrPlaceholder();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 10).toLowerCase() === peg$c271) {
          s3 = input.substr(peg$currPos, 10);
          peg$currPos += 10;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c272); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseSignedIntegerOrX();
            if (s5 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 37) {
                s6 = peg$c12;
                peg$currPos++;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c13); }
              }
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 11) === peg$c268) {
                  s7 = peg$c268;
                  peg$currPos += 11;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c269); }
                }
                if (s7 === peg$FAILED) {
                  s7 = null;
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c273(s1, s5);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseEnElement() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    var key    = peg$currPos * 255 + 49,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 34) === peg$c274) {
      s1 = peg$c274;
      peg$currPos += 34;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c275); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseElement();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 68) === peg$c276) {
              s5 = peg$c276;
              peg$currPos += 68;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c277); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseElement();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 15) === peg$c278) {
                      s9 = peg$c278;
                      peg$currPos += 15;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c279); }
                    }
                    if (s9 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c280(s3, s7);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseEnElementStacking() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    var key    = peg$currPos * 255 + 50,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 21) === peg$c281) {
      s1 = peg$c281;
      peg$currPos += 21;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c282); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseElement();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 14) === peg$c283) {
              s5 = peg$c283;
              peg$currPos += 14;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c284); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseElement();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 51) {
                      s9 = peg$c285;
                      peg$currPos++;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c286); }
                    }
                    if (s9 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c287(s3, s7);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseEnElementWithStacking() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13;

    var key    = peg$currPos * 255 + 51,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 15) === peg$c288) {
      s1 = peg$c288;
      peg$currPos += 15;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c289); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseElement();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 8) === peg$c290) {
              s5 = peg$c290;
              peg$currPos += 8;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c291); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseInteger();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 56) === peg$c292) {
                      s9 = peg$c292;
                      peg$currPos += 56;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c293); }
                    }
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parse_();
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parseElement();
                        if (s11 !== peg$FAILED) {
                          s12 = peg$parse_();
                          if (s12 !== peg$FAILED) {
                            if (input.charCodeAt(peg$currPos) === 51) {
                              s13 = peg$c285;
                              peg$currPos++;
                            } else {
                              s13 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c286); }
                            }
                            if (s13 !== peg$FAILED) {
                              peg$savedPos = s0;
                              s1 = peg$c294(s3, s7, s11);
                              s0 = s1;
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseLoseEnElement() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 255 + 52,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 15) === peg$c295) {
      s1 = peg$c295;
      peg$currPos += 15;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c296); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseElement();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 8) === peg$c290) {
              s5 = peg$c290;
              peg$currPos += 8;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c291); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseInteger();
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c297(s3, s7);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseLoseAnyEnElement() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 53,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 40) === peg$c298) {
      s1 = peg$c298;
      peg$currPos += 40;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c299); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseInteger();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c300(s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAbilityBuildup() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13;

    var key    = peg$currPos * 255 + 54,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseSchool();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 14) === peg$c301) {
          s3 = peg$c301;
          peg$currPos += 14;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c302); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseInteger();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 22) === peg$c303) {
                s6 = peg$c303;
                peg$currPos += 22;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c304); }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseSchool();
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parse_();
                    if (s9 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 21) === peg$c305) {
                        s10 = peg$c305;
                        peg$currPos += 21;
                      } else {
                        s10 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c306); }
                      }
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parseInteger();
                        if (s11 !== peg$FAILED) {
                          if (input.charCodeAt(peg$currPos) === 37) {
                            s12 = peg$c12;
                            peg$currPos++;
                          } else {
                            s12 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c13); }
                          }
                          if (s12 !== peg$FAILED) {
                            peg$savedPos = peg$currPos;
                            s13 = peg$c307(s1, s5, s8, s11);
                            if (s13) {
                              s13 = void 0;
                            } else {
                              s13 = peg$FAILED;
                            }
                            if (s13 !== peg$FAILED) {
                              peg$savedPos = s0;
                              s1 = peg$c308(s1, s5, s8, s11);
                              s0 = s1;
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseRankBoost() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 55,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseDamageUpType();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 7) === peg$c35) {
          s3 = peg$c35;
          peg$currPos += 7;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c36); }
        }
        if (s3 === peg$FAILED) {
          if (input.substr(peg$currPos, 9) === peg$c223) {
            s3 = peg$c223;
            peg$currPos += 9;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c224); }
          }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 57) === peg$c309) {
              s5 = peg$c309;
              peg$currPos += 57;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c310); }
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c311(s1);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseDamageUp() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13;

    var key    = peg$currPos * 255 + 56,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseDamageUpType();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 7) === peg$c35) {
          s3 = peg$c35;
          peg$currPos += 7;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c36); }
        }
        if (s3 === peg$FAILED) {
          if (input.substr(peg$currPos, 9) === peg$c223) {
            s3 = peg$c223;
            peg$currPos += 9;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c224); }
          }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 4) === peg$c312) {
              s5 = peg$c312;
              peg$currPos += 4;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c313); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parsePercentSlashList();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 11) === peg$c314) {
                      s9 = peg$c314;
                      peg$currPos += 11;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c315); }
                    }
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parse_();
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parseTrigger();
                        if (s11 === peg$FAILED) {
                          s11 = null;
                        }
                        if (s11 !== peg$FAILED) {
                          s12 = peg$parse_();
                          if (s12 !== peg$FAILED) {
                            s13 = peg$parseCondition();
                            if (s13 === peg$FAILED) {
                              s13 = null;
                            }
                            if (s13 !== peg$FAILED) {
                              peg$savedPos = s0;
                              s1 = peg$c316(s1, s7, s11, s13);
                              s0 = s1;
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAltDamageUp() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11;

    var key    = peg$currPos * 255 + 57,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 9).toLowerCase() === peg$c317) {
      s1 = input.substr(peg$currPos, 9);
      peg$currPos += 9;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c318); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseSkillType();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 15) === peg$c266) {
              s5 = peg$c266;
              peg$currPos += 15;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c267); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseInteger();
                if (s7 !== peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 37) {
                    s8 = peg$c12;
                    peg$currPos++;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c13); }
                  }
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c319(s3, s7);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 9).toLowerCase() === peg$c317) {
        s1 = input.substr(peg$currPos, 9);
        peg$currPos += 9;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c318); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseElementAndList();
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            if (s4 !== peg$FAILED) {
              if (input.substr(peg$currPos, 15) === peg$c266) {
                s5 = peg$c266;
                peg$currPos += 15;
              } else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c267); }
              }
              if (s5 !== peg$FAILED) {
                s6 = peg$parse_();
                if (s6 !== peg$FAILED) {
                  s7 = peg$parsePercentSlashList();
                  if (s7 !== peg$FAILED) {
                    s8 = peg$parse_();
                    if (s8 !== peg$FAILED) {
                      s9 = peg$parseTrigger();
                      if (s9 === peg$FAILED) {
                        s9 = null;
                      }
                      if (s9 !== peg$FAILED) {
                        s10 = peg$parse_();
                        if (s10 !== peg$FAILED) {
                          s11 = peg$parseCondition();
                          if (s11 === peg$FAILED) {
                            s11 = null;
                          }
                          if (s11 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c320(s3, s7, s9, s11);
                            s0 = s1;
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 9).toLowerCase() === peg$c317) {
          s1 = input.substr(peg$currPos, 9);
          peg$currPos += 9;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c318); }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          if (s2 !== peg$FAILED) {
            if (input.substr(peg$currPos, 15) === peg$c266) {
              s3 = peg$c266;
              peg$currPos += 15;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c267); }
            }
            if (s3 !== peg$FAILED) {
              s4 = peg$parse_();
              if (s4 !== peg$FAILED) {
                s5 = peg$parseInteger();
                if (s5 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 36) === peg$c321) {
                    s6 = peg$c321;
                    peg$currPos += 36;
                  } else {
                    s6 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c322); }
                  }
                  if (s6 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 2) === peg$c323) {
                      s7 = peg$c323;
                      peg$currPos += 2;
                    } else {
                      s7 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c324); }
                    }
                    if (s7 === peg$FAILED) {
                      s7 = null;
                    }
                    if (s7 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c325(s5);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.substr(peg$currPos, 9).toLowerCase() === peg$c317) {
            s1 = input.substr(peg$currPos, 9);
            peg$currPos += 9;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c318); }
          }
          if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            if (s2 !== peg$FAILED) {
              if (input.substr(peg$currPos, 15) === peg$c266) {
                s3 = peg$c266;
                peg$currPos += 15;
              } else {
                s3 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c267); }
              }
              if (s3 !== peg$FAILED) {
                s4 = peg$parse_();
                if (s4 !== peg$FAILED) {
                  s5 = peg$parsePercentSlashList();
                  if (s5 !== peg$FAILED) {
                    s6 = peg$parse_();
                    if (s6 !== peg$FAILED) {
                      s7 = peg$parseCondition();
                      if (s7 === peg$FAILED) {
                        s7 = null;
                      }
                      if (s7 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c326(s5, s7);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseDamageResistLm() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    var key    = peg$currPos * 255 + 58,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 23) === peg$c195) {
      s1 = peg$c195;
      peg$currPos += 23;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c196); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseElementAndList();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 10) === peg$c327) {
              s5 = peg$c327;
              peg$currPos += 10;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c328); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseInteger();
                if (s7 !== peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 37) {
                    s8 = peg$c12;
                    peg$currPos++;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c13); }
                  }
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c329(s3, s7);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 23) === peg$c195) {
        s1 = peg$c195;
        peg$currPos += 23;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c196); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseSkillType();
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            if (s4 !== peg$FAILED) {
              if (input.substr(peg$currPos, 10) === peg$c327) {
                s5 = peg$c327;
                peg$currPos += 10;
              } else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c328); }
              }
              if (s5 !== peg$FAILED) {
                s6 = peg$parse_();
                if (s6 !== peg$FAILED) {
                  s7 = peg$parseInteger();
                  if (s7 !== peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 37) {
                      s8 = peg$c12;
                      peg$currPos++;
                    } else {
                      s8 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c13); }
                    }
                    if (s8 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c330(s3, s7);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseInteger();
        if (s1 !== peg$FAILED) {
          if (input.substr(peg$currPos, 34) === peg$c331) {
            s2 = peg$c331;
            peg$currPos += 34;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c332); }
          }
          if (s2 !== peg$FAILED) {
            s3 = peg$parse_();
            if (s3 !== peg$FAILED) {
              s4 = peg$parseInteger();
              if (s4 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 37) {
                  s5 = peg$c12;
                  peg$currPos++;
                } else {
                  s5 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c13); }
                }
                if (s5 !== peg$FAILED) {
                  s6 = peg$parse_();
                  if (s6 !== peg$FAILED) {
                    s7 = peg$parseCondition();
                    if (s7 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c333(s1, s4, s7);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseRealmBoost() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    var key    = peg$currPos * 255 + 59,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 16) === peg$c334) {
      s1 = peg$c334;
      peg$currPos += 16;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c335); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseRealm();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 18) === peg$c336) {
              s5 = peg$c336;
              peg$currPos += 18;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c337); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseInteger();
                if (s7 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 13) === peg$c338) {
                    s8 = peg$c338;
                    peg$currPos += 13;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c339); }
                  }
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c340(s3, s7);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAbilityDouble() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 255 + 60,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 9).toLowerCase() === peg$c341) {
      s1 = input.substr(peg$currPos, 9);
      peg$currPos += 9;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c342); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseElementOrSchoolList();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 9) === peg$c223) {
              s5 = peg$c223;
              peg$currPos += 9;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c224); }
            }
            if (s5 === peg$FAILED) {
              if (input.substr(peg$currPos, 7) === peg$c35) {
                s5 = peg$c35;
                peg$currPos += 7;
              } else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c36); }
              }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 30) === peg$c343) {
                  s7 = peg$c343;
                  peg$currPos += 30;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c344); }
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c345(s3);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseMulticast() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    var key    = peg$currPos * 255 + 61,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseMulticastVerb();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 115) {
        s2 = peg$c164;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c165); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          s4 = peg$parseElementOrSchoolList();
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 9) === peg$c223) {
                s6 = peg$c223;
                peg$currPos += 9;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c224); }
              }
              if (s6 === peg$FAILED) {
                if (input.substr(peg$currPos, 7) === peg$c35) {
                  s6 = peg$c35;
                  peg$currPos += 7;
                } else {
                  s6 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c36); }
                }
              }
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c346(s1, s4);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseInteger();
      if (s1 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 63) {
          s2 = peg$c9;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c10); }
        }
        if (s2 === peg$FAILED) {
          s2 = null;
        }
        if (s2 !== peg$FAILED) {
          if (input.substr(peg$currPos, 11) === peg$c347) {
            s3 = peg$c347;
            peg$currPos += 11;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c348); }
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            if (s4 !== peg$FAILED) {
              s5 = peg$parseMulticastVerb();
              if (s5 !== peg$FAILED) {
                s6 = peg$parse_();
                if (s6 !== peg$FAILED) {
                  s7 = peg$parseElementOrSchoolList();
                  if (s7 !== peg$FAILED) {
                    s8 = peg$parse_();
                    if (s8 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 9) === peg$c223) {
                        s9 = peg$c223;
                        peg$currPos += 9;
                      } else {
                        s9 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c224); }
                      }
                      if (s9 === peg$FAILED) {
                        if (input.substr(peg$currPos, 7) === peg$c35) {
                          s9 = peg$c35;
                          peg$currPos += 7;
                        } else {
                          s9 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c36); }
                        }
                      }
                      if (s9 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c349(s1, s2, s5, s7);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseMulticastLm() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12;

    var key    = peg$currPos * 255 + 62,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseInteger();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 11) === peg$c347) {
        s2 = peg$c347;
        peg$currPos += 11;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c348); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          s4 = peg$parseMulticastVerb();
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 19) === peg$c350) {
                s6 = peg$c350;
                peg$currPos += 19;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c351); }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseElementList();
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parse_();
                    if (s9 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 6) === peg$c39) {
                        s10 = peg$c39;
                        peg$currPos += 6;
                      } else {
                        s10 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c40); }
                      }
                      if (s10 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c352(s1, s4, s8);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseInteger();
      if (s1 !== peg$FAILED) {
        if (input.substr(peg$currPos, 11) === peg$c347) {
          s2 = peg$c347;
          peg$currPos += 11;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c348); }
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parse_();
          if (s3 !== peg$FAILED) {
            s4 = peg$parseMulticastVerb();
            if (s4 !== peg$FAILED) {
              s5 = peg$parse_();
              if (s5 !== peg$FAILED) {
                if (input.substr(peg$currPos, 5) === peg$c353) {
                  s6 = peg$c353;
                  peg$currPos += 5;
                } else {
                  s6 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c354); }
                }
                if (s6 !== peg$FAILED) {
                  s7 = peg$parse_();
                  if (s7 !== peg$FAILED) {
                    s8 = peg$parseOrdinal();
                    if (s8 !== peg$FAILED) {
                      s9 = peg$parse_();
                      if (s9 !== peg$FAILED) {
                        s10 = peg$parseElementOrSchoolAndOrSlashList();
                        if (s10 !== peg$FAILED) {
                          s11 = peg$parse_();
                          if (s11 !== peg$FAILED) {
                            if (input.substr(peg$currPos, 7) === peg$c256) {
                              s12 = peg$c256;
                              peg$currPos += 7;
                            } else {
                              s12 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c257); }
                            }
                            if (s12 !== peg$FAILED) {
                              peg$savedPos = s0;
                              s1 = peg$c355(s1, s4, s8, s10);
                              s0 = s1;
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseMulticastAbility() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    var key    = peg$currPos * 255 + 63,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseMulticastVerb();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 115) {
        s2 = peg$c164;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c165); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 8) === peg$c148) {
            s4 = peg$c148;
            peg$currPos += 8;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c149); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseElementOrSchoolList();
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 7) === peg$c256) {
                    s8 = peg$c256;
                    peg$currPos += 7;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c257); }
                  }
                  if (s8 === peg$FAILED) {
                    if (input.substr(peg$currPos, 9) === peg$c223) {
                      s8 = peg$c223;
                      peg$currPos += 9;
                    } else {
                      s8 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c224); }
                    }
                  }
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c356(s1, s6);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseMulticastVerb() {
    var s0, s1, s2;

    var key    = peg$currPos * 255 + 64,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseTuple();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c94) {
        s2 = peg$c94;
        peg$currPos += 4;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c357); }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c358(s1);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseNoAirTime() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 65,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7).toLowerCase() === peg$c359) {
      s1 = input.substr(peg$currPos, 7);
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c360); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 44) === peg$c361) {
          s3 = peg$c361;
          peg$currPos += 44;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c362); }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c363();
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseDamageUpType() {
    var s0, s1;

    var key    = peg$currPos * 255 + 66,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$parseElementSchoolOrSkillTypeList();
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 8).toLowerCase() === peg$c364) {
        s1 = input.substr(peg$currPos, 8);
        peg$currPos += 8;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c365); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c366();
      }
      s0 = s1;
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 7).toLowerCase() === peg$c367) {
          s1 = input.substr(peg$currPos, 7);
          peg$currPos += 7;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c368); }
        }
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c135();
        }
        s0 = s1;
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.substr(peg$currPos, 4).toLowerCase() === peg$c31) {
            s1 = input.substr(peg$currPos, 4);
            peg$currPos += 4;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c369); }
          }
          if (s1 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c142();
          }
          s0 = s1;
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            if (input.substr(peg$currPos, 5).toLowerCase() === peg$c370) {
              s1 = input.substr(peg$currPos, 5);
              peg$currPos += 5;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c371); }
            }
            if (s1 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c372();
            }
            s0 = s1;
          }
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseBreakDamageCapAll() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 67,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c373) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c374); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 35) === peg$c375) {
          s3 = peg$c375;
          peg$currPos += 35;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c376); }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c377();
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseBreakDamageCap() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    var key    = peg$currPos * 255 + 68,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c373) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c374); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 18) === peg$c378) {
          s3 = peg$c378;
          peg$currPos += 18;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c379); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseSkillTypeAndList();
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseElementOrSchoolList();
                if (s7 === peg$FAILED) {
                  s7 = null;
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 16) === peg$c380) {
                      s9 = peg$c380;
                      peg$currPos += 16;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c381); }
                    }
                    if (s9 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c382(s5, s7);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseDamageCap() {
    var s0, s1, s2, s3, s4, s5, s6;

    var key    = peg$currPos * 255 + 69,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 20) === peg$c383) {
      s1 = peg$c383;
      peg$currPos += 20;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c384); }
    }
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 8) === peg$c385) {
        s2 = peg$c385;
        peg$currPos += 8;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c386); }
      }
      if (s2 === peg$FAILED) {
        s2 = null;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 6) === peg$c387) {
            s4 = peg$c387;
            peg$currPos += 6;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c388); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseInteger();
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c389(s6);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseHpStock() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 255 + 70,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 13).toLowerCase() === peg$c390) {
      s1 = input.substr(peg$currPos, 13);
      peg$currPos += 13;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c391); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 18) === peg$c392) {
          s3 = peg$c392;
          peg$currPos += 18;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c393); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseIntegerOrX();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c394) {
                  s7 = peg$c394;
                  peg$currPos += 2;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c395); }
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c396(s5);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseRegen() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    var key    = peg$currPos * 255 + 71,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c397) {
      s1 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c398); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c16) {
          s3 = peg$c16;
          peg$currPos += 3;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c17); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseInteger();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 14) === peg$c399) {
                s6 = peg$c399;
                peg$currPos += 14;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c400); }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseSecondsInterval();
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c401(s5, s8);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseFixedHpRegen() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    var key    = peg$currPos * 255 + 72,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c397) {
      s1 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c398); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c16) {
          s3 = peg$c16;
          peg$currPos += 3;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c17); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseInteger();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 8) === peg$c402) {
                  s7 = peg$c402;
                  peg$currPos += 8;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c403); }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseSecondsInterval();
                    if (s9 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c404(s5, s9);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parsePoison() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 255 + 73,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 11) === peg$c405) {
      s1 = peg$c405;
      peg$currPos += 11;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c406); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseFraction();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 12) === peg$c407) {
              s5 = peg$c407;
              peg$currPos += 12;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c408); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseSecondsInterval();
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c409(s3, s7);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseHealUp() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10;

    var key    = peg$currPos * 255 + 74,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 9).toLowerCase() === peg$c223) {
      s1 = input.substr(peg$currPos, 9);
      peg$currPos += 9;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c410); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 7) === peg$c411) {
          s3 = peg$c411;
          peg$currPos += 7;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c412); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseInteger();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 9) === peg$c413) {
                s6 = peg$c413;
                peg$currPos += 9;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c414); }
              }
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c415(s5);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 9).toLowerCase() === peg$c317) {
        s1 = input.substr(peg$currPos, 9);
        peg$currPos += 9;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c318); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        if (s2 !== peg$FAILED) {
          if (input.substr(peg$currPos, 15) === peg$c416) {
            s3 = peg$c416;
            peg$currPos += 15;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c417); }
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            if (s4 !== peg$FAILED) {
              s5 = peg$currPos;
              s6 = peg$parseSchoolAndOrList();
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 12) === peg$c418) {
                    s8 = peg$c418;
                    peg$currPos += 12;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c419); }
                  }
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = s5;
                    s6 = peg$c420(s6);
                    s5 = s6;
                  } else {
                    peg$currPos = s5;
                    s5 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s5;
                  s5 = peg$FAILED;
                }
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
              if (s5 === peg$FAILED) {
                s5 = null;
              }
              if (s5 !== peg$FAILED) {
                s6 = peg$parse_();
                if (s6 !== peg$FAILED) {
                  s7 = peg$parseInteger();
                  if (s7 !== peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 37) {
                      s8 = peg$c12;
                      peg$currPos++;
                    } else {
                      s8 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c13); }
                    }
                    if (s8 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c421(s5, s7);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 9).toLowerCase() === peg$c317) {
          s1 = input.substr(peg$currPos, 9);
          peg$currPos += 9;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c318); }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          if (s2 !== peg$FAILED) {
            if (input.substr(peg$currPos, 15) === peg$c416) {
              s3 = peg$c416;
              peg$currPos += 15;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c417); }
            }
            if (s3 !== peg$FAILED) {
              s4 = peg$parse_();
              if (s4 !== peg$FAILED) {
                s5 = peg$parseSkillType();
                if (s5 !== peg$FAILED) {
                  s6 = peg$parse_();
                  if (s6 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 27) === peg$c422) {
                      s7 = peg$c422;
                      peg$currPos += 27;
                    } else {
                      s7 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c423); }
                    }
                    if (s7 !== peg$FAILED) {
                      s8 = peg$parse_();
                      if (s8 !== peg$FAILED) {
                        s9 = peg$parseInteger();
                        if (s9 !== peg$FAILED) {
                          if (input.charCodeAt(peg$currPos) === 37) {
                            s10 = peg$c12;
                            peg$currPos++;
                          } else {
                            s10 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c13); }
                          }
                          if (s10 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c424(s5, s9);
                            s0 = s1;
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseHealUpLm() {
    var s0, s1, s2, s3, s4, s5, s6;

    var key    = peg$currPos * 255 + 75,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseSkillType();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 17) === peg$c425) {
          s3 = peg$c425;
          peg$currPos += 17;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c426); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseInteger();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 9) === peg$c413) {
                s6 = peg$c413;
                peg$currPos += 9;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c414); }
              }
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c424(s1, s5);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parsePain() {
    var s0, s1, s2, s3, s4;

    var key    = peg$currPos * 255 + 76,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4) === peg$c427) {
      s1 = peg$c427;
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c428); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseInteger();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 13) === peg$c338) {
            s4 = peg$c338;
            peg$currPos += 13;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c339); }
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c429(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseDamageTaken() {
    var s0, s1, s2, s3, s4, s5, s6;

    var key    = peg$currPos * 255 + 77,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseIncreasesOrReduces();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 15) === peg$c430) {
          s3 = peg$c430;
          peg$currPos += 15;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c431); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseInteger();
            if (s5 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 37) {
                s6 = peg$c12;
                peg$currPos++;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c13); }
              }
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c432(s1, s5);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseBarHeal() {
    var s0, s1, s2, s3, s4;

    var key    = peg$currPos * 255 + 78,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 16) === peg$c433) {
      s1 = peg$c433;
      peg$currPos += 16;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c434); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseInteger();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 9) === peg$c435) {
            s4 = peg$c435;
            peg$currPos += 9;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c436); }
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c437(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseEmpowerHeal() {
    var s0, s1, s2, s3, s4, s5, s6;

    var key    = peg$currPos * 255 + 79,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 9).toLowerCase() === peg$c317) {
      s1 = input.substr(peg$currPos, 9);
      peg$currPos += 9;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c318); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 19) === peg$c438) {
          s3 = peg$c438;
          peg$currPos += 19;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c439); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseInteger();
            if (s5 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 37) {
                s6 = peg$c12;
                peg$currPos++;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c13); }
              }
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c440(s5);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSecondsInterval() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 80,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6) === peg$c441) {
      s1 = peg$c441;
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c442); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c443();
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseDecimalNumber();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        if (s2 !== peg$FAILED) {
          if (input.substr(peg$currPos, 7) === peg$c444) {
            s3 = peg$c444;
            peg$currPos += 7;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c445); }
          }
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c446(s1);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseKo() {
    var s0, s1;

    var key    = peg$currPos * 255 + 81,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 15) === peg$c447) {
      s1 = peg$c447;
      peg$currPos += 15;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c448); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c449();
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 38) === peg$c450) {
        s1 = peg$c450;
        peg$currPos += 38;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c451); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c449();
      }
      s0 = s1;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseLastStand() {
    var s0, s1;

    var key    = peg$currPos * 255 + 82,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 48) === peg$c452) {
      s1 = peg$c452;
      peg$currPos += 48;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c453); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c454();
    }
    s0 = s1;

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseRaise() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 83,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 12) === peg$c455) {
      s1 = peg$c455;
      peg$currPos += 12;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c456); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseInteger();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 5) === peg$c457) {
          s3 = peg$c457;
          peg$currPos += 5;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c458); }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c459(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseReraise() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 84,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 26) === peg$c460) {
      s1 = peg$c460;
      peg$currPos += 26;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c461); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseInteger();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 10) === peg$c462) {
          s3 = peg$c462;
          peg$currPos += 10;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c463); }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c464(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseDoom() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 85,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 18) === peg$c465) {
      s1 = peg$c465;
      peg$currPos += 18;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c466); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseInteger();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 13) === peg$c467) {
              s5 = peg$c467;
              peg$currPos += 13;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c468); }
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c469(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseDoomTimer() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 255 + 86,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseIncreasesOrReduces();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 29) === peg$c470) {
          s3 = peg$c470;
          peg$currPos += 29;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c471); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseInteger();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 8) === peg$c472) {
                  s7 = peg$c472;
                  peg$currPos += 8;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c473); }
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c474(s1, s5);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseDrainHp() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11;

    var key    = peg$currPos * 255 + 87,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8).toLowerCase() === peg$c475) {
      s1 = input.substr(peg$currPos, 8);
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c476); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 6) === peg$c477) {
          s3 = peg$c477;
          peg$currPos += 6;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c478); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseInteger();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 26) === peg$c479) {
                  s7 = peg$c479;
                  peg$currPos += 26;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c480); }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseElementOrSchoolList();
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parse_();
                      if (s10 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 9) === peg$c223) {
                          s11 = peg$c223;
                          peg$currPos += 9;
                        } else {
                          s11 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c224); }
                        }
                        if (s11 === peg$FAILED) {
                          if (input.substr(peg$currPos, 7) === peg$c35) {
                            s11 = peg$c35;
                            peg$currPos += 7;
                          } else {
                            s11 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c36); }
                          }
                        }
                        if (s11 !== peg$FAILED) {
                          peg$savedPos = s0;
                          s1 = peg$c481(s5, s9);
                          s0 = s1;
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseDrainHpLm() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12;

    var key    = peg$currPos * 255 + 88,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseInteger();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 40) === peg$c482) {
        s2 = peg$c482;
        peg$currPos += 40;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c483); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          s4 = peg$parseInteger();
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 26) === peg$c479) {
                s6 = peg$c479;
                peg$currPos += 26;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c480); }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 13) === peg$c484) {
                    s8 = peg$c484;
                    peg$currPos += 13;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c485); }
                  }
                  if (s8 === peg$FAILED) {
                    s8 = null;
                  }
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parse_();
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parseElementOrSchoolList();
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parse_();
                        if (s11 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 9) === peg$c223) {
                            s12 = peg$c223;
                            peg$currPos += 9;
                          } else {
                            s12 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c224); }
                          }
                          if (s12 === peg$FAILED) {
                            if (input.substr(peg$currPos, 7) === peg$c35) {
                              s12 = peg$c35;
                              peg$currPos += 7;
                            } else {
                              s12 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c36); }
                            }
                          }
                          if (s12 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c486(s1, s4, s8, s10);
                            s0 = s1;
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseCounter() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    var key    = peg$currPos * 255 + 89,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseCounterWhen();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 5) === peg$c487) {
          s3 = peg$c487;
          peg$currPos += 5;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c488); }
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseSkillTypeAndList();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 12) === peg$c489) {
                  s7 = peg$c489;
                  peg$currPos += 12;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c490); }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseCounterResponse();
                    if (s9 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c491(s1, s3, s5, s9);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseCounterWhen() {
    var s0, s1, s2;

    var key    = peg$currPos * 255 + 90,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8).toLowerCase() === peg$c492) {
      s1 = input.substr(peg$currPos, 8);
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c493); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c494();
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseInteger();
      if (s1 !== peg$FAILED) {
        if (input.substr(peg$currPos, 22) === peg$c495) {
          s2 = peg$c495;
          peg$currPos += 22;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c496); }
        }
        if (s2 === peg$FAILED) {
          if (input.substr(peg$currPos, 19) === peg$c497) {
            s2 = peg$c497;
            peg$currPos += 19;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c498); }
          }
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c499(s1);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseCounterResponse() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 255 + 91,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6) === peg$c500) {
      s1 = peg$c500;
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c501); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c502();
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseAnySkillName();
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c503(s1);
      }
      s0 = s1;
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 19) === peg$c504) {
          s1 = peg$c504;
          peg$currPos += 19;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c505); }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          if (s2 !== peg$FAILED) {
            s3 = peg$parseDecimalNumber();
            if (s3 !== peg$FAILED) {
              s4 = peg$parse_();
              if (s4 !== peg$FAILED) {
                if (input.substr(peg$currPos, 8) === peg$c364) {
                  s5 = peg$c364;
                  peg$currPos += 8;
                } else {
                  s5 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c506); }
                }
                if (s5 === peg$FAILED) {
                  if (input.substr(peg$currPos, 7) === peg$c367) {
                    s5 = peg$c367;
                    peg$currPos += 7;
                  } else {
                    s5 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c507); }
                  }
                }
                if (s5 !== peg$FAILED) {
                  s6 = peg$parse_();
                  if (s6 !== peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 41) {
                      s7 = peg$c508;
                      peg$currPos++;
                    } else {
                      s7 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c509); }
                    }
                    if (s7 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c510(s3, s5);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.substr(peg$currPos, 12) === peg$c511) {
            s1 = peg$c511;
            peg$currPos += 12;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c512); }
          }
          if (s1 !== peg$FAILED) {
            s2 = peg$parseSimpleSkill();
            if (s2 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 41) {
                s3 = peg$c508;
                peg$currPos++;
              } else {
                s3 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c509); }
              }
              if (s3 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c513(s2);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseCover() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14;

    var key    = peg$currPos * 255 + 92,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 16) === peg$c514) {
      s1 = peg$c514;
      peg$currPos += 16;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c515); }
    }
    if (s1 === peg$FAILED) {
      s1 = null;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseInteger();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 17) === peg$c516) {
            s4 = peg$c516;
            peg$currPos += 17;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c517); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseSkillTypeAndList();
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 19) === peg$c518) {
                    s8 = peg$c518;
                    peg$currPos += 19;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c519); }
                  }
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parse_();
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parseCoverWho();
                      if (s10 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 26) === peg$c520) {
                          s11 = peg$c520;
                          peg$currPos += 26;
                        } else {
                          s11 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c521); }
                        }
                        if (s11 !== peg$FAILED) {
                          s12 = peg$parse_();
                          if (s12 !== peg$FAILED) {
                            s13 = peg$parseInteger();
                            if (s13 !== peg$FAILED) {
                              if (input.charCodeAt(peg$currPos) === 37) {
                                s14 = peg$c12;
                                peg$currPos++;
                              } else {
                                s14 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c13); }
                              }
                              if (s14 !== peg$FAILED) {
                                peg$savedPos = s0;
                                s1 = peg$c522(s1, s3, s6, s10, s13);
                                s0 = s1;
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseCoverWho() {
    var s0, s1;

    var key    = peg$currPos * 255 + 93,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6) === peg$c523) {
      s1 = peg$c523;
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c524); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c502();
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 15) === peg$c525) {
        s1 = peg$c525;
        peg$currPos += 15;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c526); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c527();
      }
      s0 = s1;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseCounterWithImmune() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 94,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseImmuneAttackSkills();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 44) {
        s2 = peg$c0;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          s4 = peg$parseCounter();
          if (s4 !== peg$FAILED) {
            peg$savedPos = peg$currPos;
            s5 = peg$c528(s1, s4);
            if (s5) {
              s5 = void 0;
            } else {
              s5 = peg$FAILED;
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c529(s1, s4);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseTriggeredEffect() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15;

    var key    = peg$currPos * 255 + 95,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseTriggerChance();
    if (s1 === peg$FAILED) {
      s1 = null;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseTriggerableEffect();
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          s4 = [];
          s5 = peg$currPos;
          s6 = peg$parse_();
          if (s6 !== peg$FAILED) {
            if (input.substr(peg$currPos, 3) === peg$c530) {
              s7 = peg$c530;
              peg$currPos += 3;
            } else {
              s7 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c531); }
            }
            if (s7 !== peg$FAILED) {
              s8 = peg$parse_();
              if (s8 !== peg$FAILED) {
                s9 = peg$parseTriggerableEffect();
                if (s9 !== peg$FAILED) {
                  s6 = [s6, s7, s8, s9];
                  s5 = s6;
                } else {
                  peg$currPos = s5;
                  s5 = peg$FAILED;
                }
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            s5 = peg$currPos;
            s6 = peg$parse_();
            if (s6 !== peg$FAILED) {
              if (input.substr(peg$currPos, 3) === peg$c530) {
                s7 = peg$c530;
                peg$currPos += 3;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c531); }
              }
              if (s7 !== peg$FAILED) {
                s8 = peg$parse_();
                if (s8 !== peg$FAILED) {
                  s9 = peg$parseTriggerableEffect();
                  if (s9 !== peg$FAILED) {
                    s6 = [s6, s7, s8, s9];
                    s5 = s6;
                  } else {
                    peg$currPos = s5;
                    s5 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s5;
                  s5 = peg$FAILED;
                }
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseTrigger();
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseTriggerDetail();
                  if (s8 === peg$FAILED) {
                    s8 = null;
                  }
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parse_();
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parseCondition();
                      if (s10 === peg$FAILED) {
                        s10 = null;
                      }
                      if (s10 !== peg$FAILED) {
                        s11 = [];
                        s12 = peg$currPos;
                        if (input.charCodeAt(peg$currPos) === 44) {
                          s13 = peg$c0;
                          peg$currPos++;
                        } else {
                          s13 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c1); }
                        }
                        if (s13 !== peg$FAILED) {
                          s14 = peg$parse_();
                          if (s14 !== peg$FAILED) {
                            s15 = peg$parseBareTriggerableEffect();
                            if (s15 !== peg$FAILED) {
                              s13 = [s13, s14, s15];
                              s12 = s13;
                            } else {
                              peg$currPos = s12;
                              s12 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s12;
                            s12 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s12;
                          s12 = peg$FAILED;
                        }
                        while (s12 !== peg$FAILED) {
                          s11.push(s12);
                          s12 = peg$currPos;
                          if (input.charCodeAt(peg$currPos) === 44) {
                            s13 = peg$c0;
                            peg$currPos++;
                          } else {
                            s13 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c1); }
                          }
                          if (s13 !== peg$FAILED) {
                            s14 = peg$parse_();
                            if (s14 !== peg$FAILED) {
                              s15 = peg$parseBareTriggerableEffect();
                              if (s15 !== peg$FAILED) {
                                s13 = [s13, s14, s15];
                                s12 = s13;
                              } else {
                                peg$currPos = s12;
                                s12 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s12;
                              s12 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s12;
                            s12 = peg$FAILED;
                          }
                        }
                        if (s11 !== peg$FAILED) {
                          s12 = peg$currPos;
                          if (input.charCodeAt(peg$currPos) === 44) {
                            s13 = peg$c0;
                            peg$currPos++;
                          } else {
                            s13 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c1); }
                          }
                          if (s13 !== peg$FAILED) {
                            s14 = peg$parse_();
                            if (s14 !== peg$FAILED) {
                              s15 = peg$parseOnceOnly();
                              if (s15 !== peg$FAILED) {
                                peg$savedPos = s12;
                                s13 = peg$c532(s1, s2, s4, s6, s8, s10, s11, s15);
                                s12 = s13;
                              } else {
                                peg$currPos = s12;
                                s12 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s12;
                              s12 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s12;
                            s12 = peg$FAILED;
                          }
                          if (s12 === peg$FAILED) {
                            s12 = null;
                          }
                          if (s12 !== peg$FAILED) {
                            if (input.substr(peg$currPos, 49) === peg$c533) {
                              s13 = peg$c533;
                              peg$currPos += 49;
                            } else {
                              s13 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c534); }
                            }
                            if (s13 === peg$FAILED) {
                              s13 = null;
                            }
                            if (s13 !== peg$FAILED) {
                              peg$savedPos = peg$currPos;
                              s14 = peg$c535(s1, s2, s4, s6, s8, s10, s11, s12, s13);
                              if (s14) {
                                s14 = void 0;
                              } else {
                                s14 = peg$FAILED;
                              }
                              if (s14 !== peg$FAILED) {
                                peg$savedPos = s0;
                                s1 = peg$c536(s1, s2, s4, s6, s8, s10, s11, s12, s13);
                                s0 = s1;
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseTrigger();
      if (s1 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 44) {
          s2 = peg$c0;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1); }
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parse_();
          if (s3 !== peg$FAILED) {
            s4 = peg$parseTriggerableEffect();
            if (s4 !== peg$FAILED) {
              s5 = peg$parse_();
              if (s5 !== peg$FAILED) {
                s6 = [];
                s7 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 44) {
                  s8 = peg$c0;
                  peg$currPos++;
                } else {
                  s8 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c1); }
                }
                if (s8 === peg$FAILED) {
                  if (input.substr(peg$currPos, 3) === peg$c530) {
                    s8 = peg$c530;
                    peg$currPos += 3;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c531); }
                  }
                }
                if (s8 !== peg$FAILED) {
                  s9 = peg$parse_();
                  if (s9 !== peg$FAILED) {
                    s10 = peg$parseTriggerableEffect();
                    if (s10 !== peg$FAILED) {
                      s8 = [s8, s9, s10];
                      s7 = s8;
                    } else {
                      peg$currPos = s7;
                      s7 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s7;
                    s7 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s7;
                  s7 = peg$FAILED;
                }
                while (s7 !== peg$FAILED) {
                  s6.push(s7);
                  s7 = peg$currPos;
                  if (input.charCodeAt(peg$currPos) === 44) {
                    s8 = peg$c0;
                    peg$currPos++;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c1); }
                  }
                  if (s8 === peg$FAILED) {
                    if (input.substr(peg$currPos, 3) === peg$c530) {
                      s8 = peg$c530;
                      peg$currPos += 3;
                    } else {
                      s8 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c531); }
                    }
                  }
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parse_();
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parseTriggerableEffect();
                      if (s10 !== peg$FAILED) {
                        s8 = [s8, s9, s10];
                        s7 = s8;
                      } else {
                        peg$currPos = s7;
                        s7 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s7;
                      s7 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s7;
                    s7 = peg$FAILED;
                  }
                }
                if (s6 !== peg$FAILED) {
                  s7 = peg$parse_();
                  if (s7 !== peg$FAILED) {
                    s8 = peg$parseTriggerDetail();
                    if (s8 === peg$FAILED) {
                      s8 = null;
                    }
                    if (s8 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c537(s1, s4, s6, s8);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 30) === peg$c538) {
          s1 = peg$c538;
          peg$currPos += 30;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c539); }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          if (s2 !== peg$FAILED) {
            s3 = peg$parseTrigger();
            if (s3 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 58) {
                s4 = peg$c540;
                peg$currPos++;
              } else {
                s4 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c541); }
              }
              if (s4 !== peg$FAILED) {
                s5 = peg$parse_();
                if (s5 !== peg$FAILED) {
                  s6 = peg$parseTriggerableEffect();
                  if (s6 !== peg$FAILED) {
                    s7 = peg$parse_();
                    if (s7 !== peg$FAILED) {
                      s8 = [];
                      s9 = peg$currPos;
                      if (input.charCodeAt(peg$currPos) === 44) {
                        s10 = peg$c0;
                        peg$currPos++;
                      } else {
                        s10 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c1); }
                      }
                      if (s10 === peg$FAILED) {
                        if (input.substr(peg$currPos, 3) === peg$c530) {
                          s10 = peg$c530;
                          peg$currPos += 3;
                        } else {
                          s10 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c531); }
                        }
                      }
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parse_();
                        if (s11 !== peg$FAILED) {
                          s12 = peg$parseTriggerableEffect();
                          if (s12 !== peg$FAILED) {
                            s10 = [s10, s11, s12];
                            s9 = s10;
                          } else {
                            peg$currPos = s9;
                            s9 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s9;
                          s9 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s9;
                        s9 = peg$FAILED;
                      }
                      while (s9 !== peg$FAILED) {
                        s8.push(s9);
                        s9 = peg$currPos;
                        if (input.charCodeAt(peg$currPos) === 44) {
                          s10 = peg$c0;
                          peg$currPos++;
                        } else {
                          s10 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c1); }
                        }
                        if (s10 === peg$FAILED) {
                          if (input.substr(peg$currPos, 3) === peg$c530) {
                            s10 = peg$c530;
                            peg$currPos += 3;
                          } else {
                            s10 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c531); }
                          }
                        }
                        if (s10 !== peg$FAILED) {
                          s11 = peg$parse_();
                          if (s11 !== peg$FAILED) {
                            s12 = peg$parseTriggerableEffect();
                            if (s12 !== peg$FAILED) {
                              s10 = [s10, s11, s12];
                              s9 = s10;
                            } else {
                              peg$currPos = s9;
                              s9 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s9;
                            s9 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s9;
                          s9 = peg$FAILED;
                        }
                      }
                      if (s8 !== peg$FAILED) {
                        s9 = peg$parse_();
                        if (s9 !== peg$FAILED) {
                          s10 = peg$parseTriggerDetail();
                          if (s10 === peg$FAILED) {
                            s10 = null;
                          }
                          if (s10 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c537(s3, s6, s8, s10);
                            s0 = s1;
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parseInteger();
          if (s1 !== peg$FAILED) {
            if (input.substr(peg$currPos, 11) === peg$c542) {
              s2 = peg$c542;
              peg$currPos += 11;
            } else {
              s2 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c543); }
            }
            if (s2 !== peg$FAILED) {
              s3 = peg$parse_();
              if (s3 !== peg$FAILED) {
                s4 = peg$parseSmartEtherStatus();
                if (s4 !== peg$FAILED) {
                  s5 = peg$parse_();
                  if (s5 !== peg$FAILED) {
                    s6 = peg$parseWho();
                    if (s6 === peg$FAILED) {
                      s6 = null;
                    }
                    if (s6 !== peg$FAILED) {
                      s7 = peg$parse_();
                      if (s7 !== peg$FAILED) {
                        s8 = peg$parseTrigger();
                        if (s8 !== peg$FAILED) {
                          peg$savedPos = peg$currPos;
                          s9 = peg$c544(s1, s4, s6, s8);
                          if (s9) {
                            s9 = void 0;
                          } else {
                            s9 = peg$FAILED;
                          }
                          if (s9 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c545(s1, s4, s6, s8);
                            s0 = s1;
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseTriggerChance() {
    var s0, s1, s2, s3, s4;

    var key    = peg$currPos * 255 + 96,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseInteger();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 63) {
        s2 = peg$c9;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c10); }
      }
      if (s2 === peg$FAILED) {
        s2 = null;
      }
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 11) === peg$c347) {
          s3 = peg$c347;
          peg$currPos += 11;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c348); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c546(s1, s2);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseTriggerableEffect() {
    var s0;

    var key    = peg$currPos * 255 + 97,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$parseCastSkill();
    if (s0 === peg$FAILED) {
      s0 = peg$parseRandomCastSkill();
      if (s0 === peg$FAILED) {
        s0 = peg$parseCastSimpleSkill();
        if (s0 === peg$FAILED) {
          s0 = peg$parseGainSb();
          if (s0 === peg$FAILED) {
            s0 = peg$parseSimpleRemoveStatus();
            if (s0 === peg$FAILED) {
              s0 = peg$parseGrantStatus();
              if (s0 === peg$FAILED) {
                s0 = peg$parseHeal();
                if (s0 === peg$FAILED) {
                  s0 = peg$parseHealPercent();
                  if (s0 === peg$FAILED) {
                    s0 = peg$parseRecoilHp();
                    if (s0 === peg$FAILED) {
                      s0 = peg$parseSmartEtherStatus();
                      if (s0 === peg$FAILED) {
                        s0 = peg$parseDispelOrEsuna();
                        if (s0 === peg$FAILED) {
                          s0 = peg$parseMimic();
                          if (s0 === peg$FAILED) {
                            s0 = peg$parseCritChance();
                            if (s0 === peg$FAILED) {
                              s0 = peg$parseCastSpeed();
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseBareTriggerableEffect() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 98,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseTriggerableEffect();
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$silentFails++;
      s3 = peg$currPos;
      s4 = peg$parse_();
      if (s4 !== peg$FAILED) {
        s5 = peg$parseTrigger();
        if (s5 === peg$FAILED) {
          if (input.substr(peg$currPos, 3) === peg$c530) {
            s5 = peg$c530;
            peg$currPos += 3;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c531); }
          }
        }
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      peg$silentFails--;
      if (s3 === peg$FAILED) {
        s2 = void 0;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c547(s1);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseCastSkill() {
    var s0, s1, s2, s3, s4;

    var key    = peg$currPos * 255 + 99,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c94) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c548); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseS();
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          s4 = peg$parseAnySkillOrOptions();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c549(s4);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseRandomCastSkill() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 100,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8).toLowerCase() === peg$c550) {
      s1 = input.substr(peg$currPos, 8);
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c551); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 5) === peg$c552) {
          s3 = peg$c552;
          peg$currPos += 5;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c553); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseAnySkillOrOptions();
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c554(s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseCastSimpleSkill() {
    var s0, s1, s2, s3, s4, s5, s6;

    var key    = peg$currPos * 255 + 101,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c94) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c548); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseS();
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 12) === peg$c511) {
            s4 = peg$c511;
            peg$currPos += 12;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c512); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parseSimpleSkill();
            if (s5 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 41) {
                s6 = peg$c508;
                peg$currPos++;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c509); }
              }
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c555(s5);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSimpleRemoveStatus() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 102,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7).toLowerCase() === peg$c556) {
      s1 = input.substr(peg$currPos, 7);
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c557); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseGenericName();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 13) === peg$c558) {
              s5 = peg$c558;
              peg$currPos += 13;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c559); }
            }
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c560(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseGrantStatus() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 103,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseStatusVerb();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseStatusList();
        if (s3 !== peg$FAILED) {
          s4 = [];
          s5 = peg$parseStatusClause();
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            s5 = peg$parseStatusClause();
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c561(s1, s3, s4);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseDirectGrantStatus() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 104,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c248) {
      s1 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c249); }
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 6).toLowerCase() === peg$c562) {
        s1 = input.substr(peg$currPos, 6);
        peg$currPos += 6;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c563); }
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseStatusList();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseDuration();
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c564(s3, s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseHeal() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    var key    = peg$currPos * 255 + 105,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7).toLowerCase() === peg$c411) {
      s1 = input.substr(peg$currPos, 7);
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c565); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseS();
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          s4 = peg$parseInteger();
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 2) === peg$c394) {
                s6 = peg$c394;
                peg$currPos += 2;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c395); }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseWho();
                  if (s8 === peg$FAILED) {
                    s8 = null;
                  }
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c566(s4, s8);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseHealPercent() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17;

    var key    = peg$currPos * 255 + 106,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7).toLowerCase() === peg$c411) {
      s1 = input.substr(peg$currPos, 7);
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c565); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseS();
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c394) {
            s4 = peg$c394;
            peg$currPos += 2;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c395); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseWho();
              if (s6 === peg$FAILED) {
                s6 = null;
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 3) === peg$c16) {
                    s8 = peg$c16;
                    peg$currPos += 3;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c17); }
                  }
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parse_();
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parseInteger();
                      if (s10 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 4) === peg$c217) {
                          s11 = peg$c217;
                          peg$currPos += 4;
                        } else {
                          s11 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c218); }
                        }
                        if (s11 !== peg$FAILED) {
                          s12 = peg$parse_();
                          if (s12 !== peg$FAILED) {
                            if (input.substr(peg$currPos, 10) === peg$c567) {
                              s13 = peg$c567;
                              peg$currPos += 10;
                            } else {
                              s13 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c568); }
                            }
                            if (s13 === peg$FAILED) {
                              if (input.substr(peg$currPos, 12) === peg$c569) {
                                s13 = peg$c569;
                                peg$currPos += 12;
                              } else {
                                s13 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c570); }
                              }
                              if (s13 === peg$FAILED) {
                                if (input.substr(peg$currPos, 5) === peg$c571) {
                                  s13 = peg$c571;
                                  peg$currPos += 5;
                                } else {
                                  s13 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c572); }
                                }
                              }
                            }
                            if (s13 !== peg$FAILED) {
                              s14 = peg$parse_();
                              if (s14 !== peg$FAILED) {
                                s15 = peg$parseMaximum();
                                if (s15 !== peg$FAILED) {
                                  s16 = peg$parse_();
                                  if (s16 !== peg$FAILED) {
                                    if (input.substr(peg$currPos, 2) === peg$c394) {
                                      s17 = peg$c394;
                                      peg$currPos += 2;
                                    } else {
                                      s17 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c395); }
                                    }
                                    if (s17 !== peg$FAILED) {
                                      peg$savedPos = s0;
                                      s1 = peg$c573(s6, s10);
                                      s0 = s1;
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseRecoilHp() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14;

    var key    = peg$currPos * 255 + 107,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7) === peg$c574) {
      s1 = peg$c574;
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c575); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c576) {
          s3 = peg$c576;
          peg$currPos += 3;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c577); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 4) === peg$c578) {
              s5 = peg$c578;
              peg$currPos += 4;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c579); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 3) === peg$c16) {
                  s7 = peg$c16;
                  peg$currPos += 3;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c17); }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseDecimalNumberSlashList();
                    if (s9 !== peg$FAILED) {
                      if (input.charCodeAt(peg$currPos) === 37) {
                        s10 = peg$c12;
                        peg$currPos++;
                      } else {
                        s10 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c13); }
                      }
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parse_();
                        if (s11 !== peg$FAILED) {
                          s12 = peg$currPos;
                          s13 = peg$parseMaximum();
                          if (s13 === peg$FAILED) {
                            if (input.substr(peg$currPos, 7) === peg$c580) {
                              s13 = peg$c580;
                              peg$currPos += 7;
                            } else {
                              s13 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c581); }
                            }
                          }
                          if (s13 !== peg$FAILED) {
                            peg$savedPos = s12;
                            s13 = peg$c582(s9);
                          }
                          s12 = s13;
                          if (s12 !== peg$FAILED) {
                            s13 = peg$parse_();
                            if (s13 !== peg$FAILED) {
                              if (input.substr(peg$currPos, 2) === peg$c394) {
                                s14 = peg$c394;
                                peg$currPos += 2;
                              } else {
                                s14 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c395); }
                              }
                              if (s14 !== peg$FAILED) {
                                peg$savedPos = s0;
                                s1 = peg$c583(s9, s12);
                                s0 = s1;
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseDispelOrEsuna() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12;

    var key    = peg$currPos * 255 + 108,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c584) {
      s1 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c585); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseS();
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 8) === peg$c586) {
            s4 = peg$c586;
            peg$currPos += 8;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c587); }
          }
          if (s4 === peg$FAILED) {
            if (input.substr(peg$currPos, 8) === peg$c588) {
              s4 = peg$c588;
              peg$currPos += 8;
            } else {
              s4 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c589); }
            }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 6) === peg$c590) {
                s6 = peg$c590;
                peg$currPos += 6;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c591); }
              }
              if (s6 === peg$FAILED) {
                s6 = null;
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 7) === peg$c592) {
                    s8 = peg$c592;
                    peg$currPos += 7;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c593); }
                  }
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parse_();
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parseWho();
                      if (s10 === peg$FAILED) {
                        s10 = null;
                      }
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parse_();
                        if (s11 !== peg$FAILED) {
                          s12 = peg$parsePerUses();
                          if (s12 === peg$FAILED) {
                            s12 = null;
                          }
                          if (s12 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c594(s4, s10, s12);
                            s0 = s1;
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseMimic() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 109,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c552) {
      s1 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c595); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 33) === peg$c596) {
          s3 = peg$c596;
          peg$currPos += 33;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c597); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseOccurrence();
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c598(s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAutoCureLm() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14;

    var key    = peg$currPos * 255 + 110,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseInteger();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 18) === peg$c599) {
        s2 = peg$c599;
        peg$currPos += 18;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c600); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          s4 = peg$currPos;
          s5 = peg$parseGenericName();
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            if (s6 !== peg$FAILED) {
              s7 = [];
              s8 = peg$currPos;
              s9 = peg$parseAndList();
              if (s9 !== peg$FAILED) {
                s10 = peg$parseGenericName();
                if (s10 !== peg$FAILED) {
                  s9 = [s9, s10];
                  s8 = s9;
                } else {
                  peg$currPos = s8;
                  s8 = peg$FAILED;
                }
              } else {
                peg$currPos = s8;
                s8 = peg$FAILED;
              }
              while (s8 !== peg$FAILED) {
                s7.push(s8);
                s8 = peg$currPos;
                s9 = peg$parseAndList();
                if (s9 !== peg$FAILED) {
                  s10 = peg$parseGenericName();
                  if (s10 !== peg$FAILED) {
                    s9 = [s9, s10];
                    s8 = s9;
                  } else {
                    peg$currPos = s8;
                    s8 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s8;
                  s8 = peg$FAILED;
                }
              }
              if (s7 !== peg$FAILED) {
                peg$savedPos = s4;
                s5 = peg$c601(s1);
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 38) === peg$c602) {
                s6 = peg$c602;
                peg$currPos += 38;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c603); }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  s8 = peg$currPos;
                  s9 = peg$parseGenericName();
                  if (s9 !== peg$FAILED) {
                    s10 = peg$parse_();
                    if (s10 !== peg$FAILED) {
                      s11 = [];
                      s12 = peg$currPos;
                      s13 = peg$parseOrList();
                      if (s13 !== peg$FAILED) {
                        s14 = peg$parseGenericName();
                        if (s14 !== peg$FAILED) {
                          s13 = [s13, s14];
                          s12 = s13;
                        } else {
                          peg$currPos = s12;
                          s12 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s12;
                        s12 = peg$FAILED;
                      }
                      while (s12 !== peg$FAILED) {
                        s11.push(s12);
                        s12 = peg$currPos;
                        s13 = peg$parseOrList();
                        if (s13 !== peg$FAILED) {
                          s14 = peg$parseGenericName();
                          if (s14 !== peg$FAILED) {
                            s13 = [s13, s14];
                            s12 = s13;
                          } else {
                            peg$currPos = s12;
                            s12 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s12;
                          s12 = peg$FAILED;
                        }
                      }
                      if (s11 !== peg$FAILED) {
                        peg$savedPos = s8;
                        s9 = peg$c604(s1, s4);
                        s8 = s9;
                      } else {
                        peg$currPos = s8;
                        s8 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s8;
                      s8 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s8;
                    s8 = peg$FAILED;
                  }
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = peg$currPos;
                    s9 = peg$c605(s1, s4, s8);
                    if (s9) {
                      s9 = void 0;
                    } else {
                      s9 = peg$FAILED;
                    }
                    if (s9 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c606(s1, s4, s8);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseConditionalStatus() {
    var s0, s1, s2;

    var key    = peg$currPos * 255 + 111,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseGrantStatus();
    if (s1 !== peg$FAILED) {
      peg$savedPos = peg$currPos;
      s2 = peg$c607(s1);
      if (s2) {
        s2 = void 0;
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c608(s1);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseGainSb() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 255 + 112,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c248) {
      s1 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c249); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseInteger();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 9) === peg$c609) {
              s5 = peg$c609;
              peg$currPos += 9;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c610); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 8) === peg$c472) {
                  s7 = peg$c472;
                  peg$currPos += 8;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c473); }
                }
                if (s7 === peg$FAILED) {
                  s7 = null;
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c611(s3);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 7).toLowerCase() === peg$c556) {
        s1 = input.substr(peg$currPos, 7);
        peg$currPos += 7;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c612); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseInteger();
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            if (s4 !== peg$FAILED) {
              if (input.substr(peg$currPos, 9) === peg$c609) {
                s5 = peg$c609;
                peg$currPos += 9;
              } else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c610); }
              }
              if (s5 !== peg$FAILED) {
                s6 = peg$parse_();
                if (s6 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 8) === peg$c472) {
                    s7 = peg$c472;
                    peg$currPos += 8;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c473); }
                  }
                  if (s7 === peg$FAILED) {
                    s7 = null;
                  }
                  if (s7 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c613(s3);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSbGainUp() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    var key    = peg$currPos * 255 + 113,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseElementOrSchoolList();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 9) === peg$c223) {
          s3 = peg$c223;
          peg$currPos += 9;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c224); }
        }
        if (s3 === peg$FAILED) {
          if (input.substr(peg$currPos, 7) === peg$c35) {
            s3 = peg$c35;
            peg$currPos += 7;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c36); }
          }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 5) === peg$c614) {
              s5 = peg$c614;
              peg$currPos += 5;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c615); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseInteger();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 16) === peg$c616) {
                      s9 = peg$c616;
                      peg$currPos += 16;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c617); }
                    }
                    if (s9 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c618(s1, s7);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 7).toLowerCase() === peg$c35) {
        s1 = input.substr(peg$currPos, 7);
        peg$currPos += 7;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c619); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        if (s2 !== peg$FAILED) {
          if (input.substr(peg$currPos, 40) === peg$c620) {
            s3 = peg$c620;
            peg$currPos += 40;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c621); }
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            if (s4 !== peg$FAILED) {
              s5 = peg$parseInteger();
              if (s5 !== peg$FAILED) {
                s6 = peg$parse_();
                if (s6 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 16) === peg$c616) {
                    s7 = peg$c616;
                    peg$currPos += 16;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c617); }
                  }
                  if (s7 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c622(s5);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 9).toLowerCase() === peg$c223) {
          s1 = input.substr(peg$currPos, 9);
          peg$currPos += 9;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c410); }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          if (s2 !== peg$FAILED) {
            if (input.substr(peg$currPos, 5) === peg$c614) {
              s3 = peg$c614;
              peg$currPos += 5;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c615); }
            }
            if (s3 !== peg$FAILED) {
              s4 = peg$parse_();
              if (s4 !== peg$FAILED) {
                s5 = peg$parseInteger();
                if (s5 !== peg$FAILED) {
                  s6 = peg$parse_();
                  if (s6 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 16) === peg$c616) {
                      s7 = peg$c616;
                      peg$currPos += 16;
                    } else {
                      s7 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c617); }
                    }
                    if (s7 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c623(s5);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.substr(peg$currPos, 10).toLowerCase() === peg$c624) {
            s1 = input.substr(peg$currPos, 10);
            peg$currPos += 10;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c625); }
          }
          if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            if (s2 !== peg$FAILED) {
              if (input.substr(peg$currPos, 25) === peg$c626) {
                s3 = peg$c626;
                peg$currPos += 25;
              } else {
                s3 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c627); }
              }
              if (s3 !== peg$FAILED) {
                s4 = peg$parse_();
                if (s4 !== peg$FAILED) {
                  s5 = peg$parseInteger();
                  if (s5 !== peg$FAILED) {
                    s6 = peg$parse_();
                    if (s6 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 62) === peg$c628) {
                        s7 = peg$c628;
                        peg$currPos += 62;
                      } else {
                        s7 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c629); }
                      }
                      if (s7 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c622(s5);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseGainLb() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 255 + 114,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c248) {
      s1 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c249); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseInteger();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 9) === peg$c630) {
              s5 = peg$c630;
              peg$currPos += 9;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c631); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 8) === peg$c472) {
                  s7 = peg$c472;
                  peg$currPos += 8;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c473); }
                }
                if (s7 === peg$FAILED) {
                  s7 = null;
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c632(s3);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 7).toLowerCase() === peg$c556) {
        s1 = input.substr(peg$currPos, 7);
        peg$currPos += 7;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c612); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseInteger();
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            if (s4 !== peg$FAILED) {
              if (input.substr(peg$currPos, 9) === peg$c630) {
                s5 = peg$c630;
                peg$currPos += 9;
              } else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c631); }
              }
              if (s5 !== peg$FAILED) {
                s6 = peg$parse_();
                if (s6 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 8) === peg$c472) {
                    s7 = peg$c472;
                    peg$currPos += 8;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c473); }
                  }
                  if (s7 === peg$FAILED) {
                    s7 = null;
                  }
                  if (s7 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c633(s3);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseTaunt() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 255 + 115,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c634) {
      s1 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c635); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 13) === peg$c484) {
          s3 = peg$c484;
          peg$currPos += 13;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c485); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseSkillTypeAndList();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 7) === peg$c35) {
                  s7 = peg$c35;
                  peg$currPos += 7;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c36); }
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c636(s5);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseRunic() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 255 + 116,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseTaunt();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 9).toLowerCase() === peg$c637) {
        s2 = input.substr(peg$currPos, 9);
        peg$currPos += 9;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c638); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          s4 = peg$parseSkillTypeAndList();
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 41) === peg$c639) {
                s6 = peg$c639;
                peg$currPos += 41;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c640); }
              }
              if (s6 !== peg$FAILED) {
                peg$savedPos = peg$currPos;
                s7 = peg$c641(s1, s4);
                if (s7) {
                  s7 = void 0;
                } else {
                  s7 = peg$FAILED;
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c642(s1, s4);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseImmuneAttackSkills() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11;

    var key    = peg$currPos * 255 + 117,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c643) {
      s1 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c644); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 9) === peg$c645) {
          s3 = peg$c645;
          peg$currPos += 9;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c646); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 6) === peg$c647) {
              s5 = peg$c647;
              peg$currPos += 6;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c648); }
            }
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 10) === peg$c649) {
                  s7 = peg$c649;
                  peg$currPos += 10;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c650); }
                }
                if (s7 === peg$FAILED) {
                  s7 = null;
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseSkillTypeList();
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parse_();
                      if (s10 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 7) === peg$c35) {
                          s11 = peg$c35;
                          peg$currPos += 7;
                        } else {
                          s11 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c36); }
                        }
                        if (s11 !== peg$FAILED) {
                          peg$savedPos = s0;
                          s1 = peg$c651(s5, s7, s9);
                          s0 = s1;
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseImmuneAttacks() {
    var s0, s1;

    var key    = peg$currPos * 255 + 118,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 26) === peg$c652) {
      s1 = peg$c652;
      peg$currPos += 26;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c653); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c654();
    }
    s0 = s1;

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseZeroDamage() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 119,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7).toLowerCase() === peg$c169) {
      s1 = input.substr(peg$currPos, 7);
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c170); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 8) === peg$c364) {
          s3 = peg$c364;
          peg$currPos += 8;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c506); }
        }
        if (s3 === peg$FAILED) {
          if (input.substr(peg$currPos, 7) === peg$c367) {
            s3 = peg$c367;
            peg$currPos += 7;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c507); }
          }
          if (s3 === peg$FAILED) {
            if (input.substr(peg$currPos, 3) === peg$c212) {
              s3 = peg$c212;
              peg$currPos += 3;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c213); }
            }
          }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 20) === peg$c655) {
              s5 = peg$c655;
              peg$currPos += 20;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c656); }
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c657(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 136) === peg$c658) {
        s1 = peg$c658;
        peg$currPos += 136;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c659); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c660();
      }
      s0 = s1;
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 120) === peg$c661) {
          s1 = peg$c661;
          peg$currPos += 120;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c662); }
        }
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c663();
        }
        s0 = s1;
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.substr(peg$currPos, 49) === peg$c664) {
            s1 = peg$c664;
            peg$currPos += 49;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c665); }
          }
          if (s1 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c666();
          }
          s0 = s1;
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseEvadeAll() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 120,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c146) {
      s1 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c147); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 11) === peg$c667) {
          s3 = peg$c667;
          peg$currPos += 11;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c668); }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c669();
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseMultiplyDamage() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 121,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 33) === peg$c670) {
      s1 = peg$c670;
      peg$currPos += 33;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c671); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseIntegerOrX();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c672(s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseBerserk() {
    var s0, s1, s2, s3, s4;

    var key    = peg$currPos * 255 + 122,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c673) {
      s1 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c674); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 33) === peg$c675) {
          s3 = peg$c675;
          peg$currPos += 33;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c676); }
        }
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 32) === peg$c677) {
            s4 = peg$c677;
            peg$currPos += 32;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c678); }
          }
          if (s4 === peg$FAILED) {
            s4 = null;
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c679();
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAbilityBerserk() {
    var s0, s1;

    var key    = peg$currPos * 255 + 123,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 119) === peg$c680) {
      s1 = peg$c680;
      peg$currPos += 119;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c681); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c682();
    }
    s0 = s1;

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseRage() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 124,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c673) {
      s1 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c674); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 62) === peg$c683) {
          s3 = peg$c683;
          peg$currPos += 62;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c684); }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c685();
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseTurnDuration() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    var key    = peg$currPos * 255 + 125,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5) === peg$c686) {
      s1 = peg$c686;
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c687); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c16) {
          s3 = peg$c16;
          peg$currPos += 3;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c17); }
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseInteger();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 4) === peg$c688) {
                  s7 = peg$c688;
                  peg$currPos += 4;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c689); }
                }
                if (s7 !== peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 115) {
                    s8 = peg$c164;
                    peg$currPos++;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c165); }
                  }
                  if (s8 === peg$FAILED) {
                    s8 = null;
                  }
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c690(s5);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseRemovedUnlessStatus() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13;

    var key    = peg$currPos * 255 + 126,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7).toLowerCase() === peg$c691) {
      s1 = input.substr(peg$currPos, 7);
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c692); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c693) {
          s3 = peg$c693;
          peg$currPos += 2;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c694); }
        }
        if (s3 === peg$FAILED) {
          if (input.substr(peg$currPos, 4) === peg$c695) {
            s3 = peg$c695;
            peg$currPos += 4;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c696); }
          }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 3) === peg$c576) {
              s5 = peg$c576;
              peg$currPos += 3;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c577); }
            }
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 4) === peg$c578) {
                  s7 = peg$c578;
                  peg$currPos += 4;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c579); }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 6) === peg$c697) {
                      s9 = peg$c697;
                      peg$currPos += 6;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c698); }
                    }
                    if (s9 === peg$FAILED) {
                      if (input.substr(peg$currPos, 12) === peg$c699) {
                        s9 = peg$c699;
                        peg$currPos += 12;
                      } else {
                        s9 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c700); }
                      }
                    }
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parse_();
                      if (s10 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 3) === peg$c701) {
                          s11 = peg$c701;
                          peg$currPos += 3;
                        } else {
                          s11 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c702); }
                        }
                        if (s11 === peg$FAILED) {
                          s11 = null;
                        }
                        if (s11 !== peg$FAILED) {
                          s12 = peg$parse_();
                          if (s12 !== peg$FAILED) {
                            s13 = peg$parseGenericName();
                            if (s13 !== peg$FAILED) {
                              peg$savedPos = s0;
                              s1 = peg$c703(s11, s13);
                              s0 = s1;
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseOnceOnly() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12;

    var key    = peg$currPos * 255 + 127,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7).toLowerCase() === peg$c691) {
      s1 = input.substr(peg$currPos, 7);
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c692); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 16) === peg$c704) {
          s3 = peg$c704;
          peg$currPos += 16;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c705); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseOccurrence();
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$currPos;
                if (input.substr(peg$currPos, 5) === peg$c706) {
                  s8 = peg$c706;
                  peg$currPos += 5;
                } else {
                  s8 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c707); }
                }
                if (s8 !== peg$FAILED) {
                  s9 = peg$parse_();
                  if (s9 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 3) === peg$c576) {
                      s10 = peg$c576;
                      peg$currPos += 3;
                    } else {
                      s10 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c577); }
                    }
                    if (s10 === peg$FAILED) {
                      s10 = null;
                    }
                    if (s10 !== peg$FAILED) {
                      s11 = peg$parse_();
                      if (s11 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 24) === peg$c708) {
                          s12 = peg$c708;
                          peg$currPos += 24;
                        } else {
                          s12 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c709); }
                        }
                        if (s12 !== peg$FAILED) {
                          s8 = [s8, s9, s10, s11, s12];
                          s7 = s8;
                        } else {
                          peg$currPos = s7;
                          s7 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s7;
                        s7 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s7;
                      s7 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s7;
                    s7 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s7;
                  s7 = peg$FAILED;
                }
                if (s7 === peg$FAILED) {
                  s7 = null;
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c710(s5);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 7).toLowerCase() === peg$c691) {
        s1 = input.substr(peg$currPos, 7);
        peg$currPos += 7;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c692); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        if (s2 !== peg$FAILED) {
          if (input.substr(peg$currPos, 5) === peg$c711) {
            s3 = peg$c711;
            peg$currPos += 5;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c712); }
          }
          if (s3 === peg$FAILED) {
            if (input.substr(peg$currPos, 4) === peg$c695) {
              s3 = peg$c695;
              peg$currPos += 4;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c696); }
            }
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            if (s4 !== peg$FAILED) {
              s5 = peg$parseAnySkillName();
              if (s5 !== peg$FAILED) {
                s6 = peg$parse_();
                if (s6 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 7) === peg$c713) {
                    s7 = peg$c713;
                    peg$currPos += 7;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c714); }
                  }
                  if (s7 !== peg$FAILED) {
                    s8 = peg$parse_();
                    if (s8 !== peg$FAILED) {
                      s9 = peg$parseOccurrence();
                      if (s9 === peg$FAILED) {
                        s9 = null;
                      }
                      if (s9 !== peg$FAILED) {
                        s10 = peg$parse_();
                        if (s10 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 30) === peg$c715) {
                            s11 = peg$c715;
                            peg$currPos += 30;
                          } else {
                            s11 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c716); }
                          }
                          if (s11 === peg$FAILED) {
                            s11 = null;
                          }
                          if (s11 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c717(s5, s9);
                            s0 = s1;
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 7).toLowerCase() === peg$c691) {
          s1 = input.substr(peg$currPos, 7);
          peg$currPos += 7;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c692); }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          if (s2 !== peg$FAILED) {
            if (input.substr(peg$currPos, 13) === peg$c718) {
              s3 = peg$c718;
              peg$currPos += 13;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c719); }
            }
            if (s3 !== peg$FAILED) {
              s4 = peg$parse_();
              if (s4 !== peg$FAILED) {
                s5 = peg$parseAnySkillName();
                if (s5 !== peg$FAILED) {
                  s6 = peg$parse_();
                  if (s6 !== peg$FAILED) {
                    s7 = peg$parseOccurrence();
                    if (s7 === peg$FAILED) {
                      s7 = null;
                    }
                    if (s7 !== peg$FAILED) {
                      s8 = peg$parse_();
                      if (s8 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 30) === peg$c715) {
                          s9 = peg$c715;
                          peg$currPos += 30;
                        } else {
                          s9 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c716); }
                        }
                        if (s9 === peg$FAILED) {
                          s9 = null;
                        }
                        if (s9 !== peg$FAILED) {
                          peg$savedPos = s0;
                          s1 = peg$c717(s5, s7);
                          s0 = s1;
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.substr(peg$currPos, 7).toLowerCase() === peg$c691) {
            s1 = input.substr(peg$currPos, 7);
            peg$currPos += 7;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c692); }
          }
          if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            if (s2 !== peg$FAILED) {
              if (input.substr(peg$currPos, 24) === peg$c720) {
                s3 = peg$c720;
                peg$currPos += 24;
              } else {
                s3 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c721); }
              }
              if (s3 !== peg$FAILED) {
                s4 = peg$parse_();
                if (s4 !== peg$FAILED) {
                  s5 = peg$parseOccurrence();
                  if (s5 === peg$FAILED) {
                    s5 = null;
                  }
                  if (s5 !== peg$FAILED) {
                    s6 = peg$parse_();
                    if (s6 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 30) === peg$c715) {
                        s7 = peg$c715;
                        peg$currPos += 30;
                      } else {
                        s7 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c716); }
                      }
                      if (s7 === peg$FAILED) {
                        s7 = null;
                      }
                      if (s7 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c710(s5);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            if (input.substr(peg$currPos, 15) === peg$c722) {
              s1 = peg$c722;
              peg$currPos += 15;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c723); }
            }
            if (s1 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c724();
            }
            s0 = s1;
          }
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseRemovedAfterTrigger() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 128,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7).toLowerCase() === peg$c691) {
      s1 = input.substr(peg$currPos, 7);
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c692); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseTrigger();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c725(s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseTrackStatusLevel() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13;

    var key    = peg$currPos * 255 + 129,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c726) {
      s1 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c727); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 12) === peg$c728) {
          s3 = peg$c728;
          peg$currPos += 12;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c729); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseGenericName();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 18) === peg$c730) {
                  s7 = peg$c730;
                  peg$currPos += 18;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c731); }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseInteger();
                    if (s9 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c732(s5, s9);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 4).toLowerCase() === peg$c733) {
        s1 = input.substr(peg$currPos, 4);
        peg$currPos += 4;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c734); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        if (s2 !== peg$FAILED) {
          if (input.substr(peg$currPos, 12) === peg$c735) {
            s3 = peg$c735;
            peg$currPos += 12;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c736); }
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            if (s4 !== peg$FAILED) {
              s5 = peg$parseGenericName();
              if (s5 !== peg$FAILED) {
                s6 = peg$parse_();
                if (s6 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 5) === peg$c737) {
                    s7 = peg$c737;
                    peg$currPos += 5;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c738); }
                  }
                  if (s7 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c739(s5);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 5).toLowerCase() === peg$c726) {
          s1 = input.substr(peg$currPos, 5);
          peg$currPos += 5;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c727); }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          if (s2 !== peg$FAILED) {
            if (input.substr(peg$currPos, 8) === peg$c740) {
              s3 = peg$c740;
              peg$currPos += 8;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c741); }
            }
            if (s3 !== peg$FAILED) {
              s4 = peg$parse_();
              if (s4 !== peg$FAILED) {
                s5 = peg$parseGenericName();
                if (s5 !== peg$FAILED) {
                  s6 = peg$parse_();
                  if (s6 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 29) === peg$c742) {
                      s7 = peg$c742;
                      peg$currPos += 29;
                    } else {
                      s7 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c743); }
                    }
                    if (s7 !== peg$FAILED) {
                      s8 = peg$parse_();
                      if (s8 !== peg$FAILED) {
                        s9 = peg$parseInteger();
                        if (s9 !== peg$FAILED) {
                          s10 = peg$parse_();
                          if (s10 !== peg$FAILED) {
                            if (input.substr(peg$currPos, 23) === peg$c744) {
                              s11 = peg$c744;
                              peg$currPos += 23;
                            } else {
                              s11 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c745); }
                            }
                            if (s11 !== peg$FAILED) {
                              s12 = peg$parse_();
                              if (s12 !== peg$FAILED) {
                                s13 = peg$parseInteger();
                                if (s13 !== peg$FAILED) {
                                  peg$savedPos = s0;
                                  s1 = peg$c746(s5, s9, s13);
                                  s0 = s1;
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseChangeStatusLevel() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11;

    var key    = peg$currPos * 255 + 130,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseIncreasesOrReduces();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c576) {
          s3 = peg$c576;
          peg$currPos += 3;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c577); }
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseGenericName();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 8) === peg$c747) {
                  s7 = peg$c747;
                  peg$currPos += 8;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c748); }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseInteger();
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parse_();
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parseTriggerOrWhenSet();
                        if (s11 !== peg$FAILED) {
                          peg$savedPos = s0;
                          s1 = peg$c749(s1, s5, s9, s11);
                          s0 = s1;
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSetStatusLevel() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11;

    var key    = peg$currPos * 255 + 131,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c373) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c374); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c576) {
          s3 = peg$c576;
          peg$currPos += 3;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c577); }
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseGenericName();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 8) === peg$c750) {
                  s7 = peg$c750;
                  peg$currPos += 8;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c751); }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseInteger();
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parse_();
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parseTriggerOrWhenSet();
                        if (s11 !== peg$FAILED) {
                          peg$savedPos = s0;
                          s1 = peg$c752(s5, s9, s11);
                          s0 = s1;
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatusLevelBooster() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16;

    var key    = peg$currPos * 255 + 132,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 9).toLowerCase() === peg$c317) {
      s1 = input.substr(peg$currPos, 9);
      peg$currPos += 9;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c318); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c576) {
          s3 = peg$c576;
          peg$currPos += 3;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c577); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseGenericName();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 8) === peg$c747) {
                  s7 = peg$c747;
                  peg$currPos += 8;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c748); }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseInteger();
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parse_();
                      if (s10 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 8) === peg$c753) {
                          s11 = peg$c753;
                          peg$currPos += 8;
                        } else {
                          s11 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c754); }
                        }
                        if (s11 !== peg$FAILED) {
                          s12 = peg$parse_();
                          if (s12 !== peg$FAILED) {
                            s13 = peg$parseGenericName();
                            if (s13 !== peg$FAILED) {
                              s14 = peg$parse_();
                              if (s14 !== peg$FAILED) {
                                if (input.substr(peg$currPos, 18) === peg$c755) {
                                  s15 = peg$c755;
                                  peg$currPos += 18;
                                } else {
                                  s15 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c756); }
                                }
                                if (s15 !== peg$FAILED) {
                                  peg$savedPos = peg$currPos;
                                  s16 = peg$c757(s5, s9, s13);
                                  if (s16) {
                                    s16 = void 0;
                                  } else {
                                    s16 = peg$FAILED;
                                  }
                                  if (s16 !== peg$FAILED) {
                                    peg$savedPos = s0;
                                    s1 = peg$c758(s5, s9, s13);
                                    s0 = s1;
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseBurstToggle() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 133,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7).toLowerCase() === peg$c759) {
      s1 = input.substr(peg$currPos, 7);
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c760); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 22) === peg$c761) {
          s3 = peg$c761;
          peg$currPos += 22;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c762); }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c763();
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseTrackUses() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    var key    = peg$currPos * 255 + 134,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c726) {
      s1 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c727); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 12) === peg$c728) {
          s3 = peg$c728;
          peg$currPos += 12;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c729); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 9) === peg$c764) {
              s5 = peg$c764;
              peg$currPos += 9;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c765); }
            }
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 7) === peg$c766) {
                  s7 = peg$c766;
                  peg$currPos += 7;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c767); }
                }
                if (s7 === peg$FAILED) {
                  if (input.substr(peg$currPos, 8) === peg$c768) {
                    s7 = peg$c768;
                    peg$currPos += 8;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c769); }
                  }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseAnySkillName();
                    if (s9 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c770(s9);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 31) === peg$c771) {
        s1 = peg$c771;
        peg$currPos += 31;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c772); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseAnySkillName();
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c770(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 4).toLowerCase() === peg$c733) {
          s1 = input.substr(peg$currPos, 4);
          peg$currPos += 4;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c734); }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          if (s2 !== peg$FAILED) {
            if (input.substr(peg$currPos, 12) === peg$c735) {
              s3 = peg$c735;
              peg$currPos += 12;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c736); }
            }
            if (s3 !== peg$FAILED) {
              s4 = peg$parse_();
              if (s4 !== peg$FAILED) {
                s5 = peg$parseAnySkillName();
                if (s5 !== peg$FAILED) {
                  s6 = peg$parse_();
                  if (s6 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 5) === peg$c773) {
                      s7 = peg$c773;
                      peg$currPos += 5;
                    } else {
                      s7 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c774); }
                    }
                    if (s7 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c770(s5);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.substr(peg$currPos, 6).toLowerCase() === peg$c775) {
            s1 = input.substr(peg$currPos, 6);
            peg$currPos += 6;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c776); }
          }
          if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            if (s2 !== peg$FAILED) {
              s3 = peg$parseAnySkillName();
              if (s3 !== peg$FAILED) {
                s4 = peg$parse_();
                if (s4 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 5) === peg$c552) {
                    s5 = peg$c552;
                    peg$currPos += 5;
                  } else {
                    s5 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c553); }
                  }
                  if (s5 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c770(s3);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            if (input.substr(peg$currPos, 5).toLowerCase() === peg$c726) {
              s1 = input.substr(peg$currPos, 5);
              peg$currPos += 5;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c727); }
            }
            if (s1 !== peg$FAILED) {
              s2 = peg$parse_();
              if (s2 !== peg$FAILED) {
                if (input.substr(peg$currPos, 22) === peg$c777) {
                  s3 = peg$c777;
                  peg$currPos += 22;
                } else {
                  s3 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c778); }
                }
                if (s3 !== peg$FAILED) {
                  s4 = peg$parse_();
                  if (s4 !== peg$FAILED) {
                    s5 = peg$parseElementAndList();
                    if (s5 !== peg$FAILED) {
                      s6 = peg$parse_();
                      if (s6 !== peg$FAILED) {
                        s7 = peg$parseAbilityOrAttack();
                        if (s7 !== peg$FAILED) {
                          s8 = peg$parse_();
                          if (s8 !== peg$FAILED) {
                            if (input.substr(peg$currPos, 4) === peg$c733) {
                              s9 = peg$c733;
                              peg$currPos += 4;
                            } else {
                              s9 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c779); }
                            }
                            if (s9 !== peg$FAILED) {
                              peg$savedPos = s0;
                              s1 = peg$c780(s5);
                              s0 = s1;
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              if (input.substr(peg$currPos, 5).toLowerCase() === peg$c726) {
                s1 = input.substr(peg$currPos, 5);
                peg$currPos += 5;
              } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c727); }
              }
              if (s1 !== peg$FAILED) {
                s2 = peg$parse_();
                if (s2 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 8) === peg$c740) {
                    s3 = peg$c740;
                    peg$currPos += 8;
                  } else {
                    s3 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c741); }
                  }
                  if (s3 !== peg$FAILED) {
                    s4 = peg$parse_();
                    if (s4 !== peg$FAILED) {
                      s5 = peg$parseAnySkillName();
                      if (s5 !== peg$FAILED) {
                        s6 = peg$parse_();
                        if (s6 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 4) === peg$c781) {
                            s7 = peg$c781;
                            peg$currPos += 4;
                          } else {
                            s7 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c782); }
                          }
                          if (s7 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c770(s5);
                            s0 = s1;
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            }
          }
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSharedCount() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 135,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 23) === peg$c783) {
      s1 = peg$c783;
      peg$currPos += 23;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c784); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseElementAndList();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 8) === peg$c785) {
              s5 = peg$c785;
              peg$currPos += 8;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c786); }
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c787(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseModifiesSkill() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 136,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8).toLowerCase() === peg$c788) {
      s1 = input.substr(peg$currPos, 8);
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c789); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 11) === peg$c790) {
          s3 = peg$c790;
          peg$currPos += 11;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c791); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseAnySkillName();
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c792(s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseBurstOnly() {
    var s0, s1;

    var key    = peg$currPos * 255 + 137,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 37) === peg$c793) {
      s1 = peg$c793;
      peg$currPos += 37;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c794); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c795();
    }
    s0 = s1;

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseBurstReset() {
    var s0, s1;

    var key    = peg$currPos * 255 + 138,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 32) === peg$c796) {
      s1 = peg$c796;
      peg$currPos += 32;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c797); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c798();
    }
    s0 = s1;

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatusReset() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 139,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 21) === peg$c799) {
      s1 = peg$c799;
      peg$currPos += 21;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c800); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseGenericName();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c801(s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseTrackGuardian() {
    var s0, s1;

    var key    = peg$currPos * 255 + 140,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 49) === peg$c802) {
      s1 = peg$c802;
      peg$currPos += 49;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c803); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c804();
    }
    s0 = s1;

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseGuardianReset() {
    var s0, s1;

    var key    = peg$currPos * 255 + 141,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 134) === peg$c805) {
      s1 = peg$c805;
      peg$currPos += 134;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c806); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c807();
    }
    s0 = s1;

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseReplaceAttack() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 142,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8).toLowerCase() === peg$c808) {
      s1 = input.substr(peg$currPos, 8);
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c809); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 18) === peg$c810) {
          s3 = peg$c810;
          peg$currPos += 18;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c811); }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c812();
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseReplaceAttackDefend() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 143,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8).toLowerCase() === peg$c808) {
      s1 = input.substr(peg$currPos, 8);
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c809); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 30) === peg$c813) {
          s3 = peg$c813;
          peg$currPos += 30;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c814); }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c812();
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseDisableAttacks() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 255 + 144,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8).toLowerCase() === peg$c815) {
      s1 = input.substr(peg$currPos, 8);
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c816); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseSkillTypeAndList();
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 4).toLowerCase() === peg$c31) {
              s5 = input.substr(peg$currPos, 4);
              peg$currPos += 4;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c369); }
            }
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 7) === peg$c35) {
                  s7 = peg$c35;
                  peg$currPos += 7;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c36); }
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c817(s3, s5);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAi() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 145,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7).toLowerCase() === peg$c759) {
      s1 = input.substr(peg$currPos, 7);
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c760); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseGenericName();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 9) === peg$c818) {
              s5 = peg$c818;
              peg$currPos += 9;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c819); }
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c812();
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseParalyze() {
    var s0, s1, s2, s3, s4;

    var key    = peg$currPos * 255 + 146,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7).toLowerCase() === peg$c820) {
      s1 = input.substr(peg$currPos, 7);
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c821); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 26) === peg$c822) {
          s3 = peg$c822;
          peg$currPos += 26;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c823); }
        }
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 21) === peg$c824) {
            s4 = peg$c824;
            peg$currPos += 21;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c825); }
          }
          if (s4 === peg$FAILED) {
            s4 = null;
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c826();
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 5).toLowerCase() === peg$c643) {
        s1 = input.substr(peg$currPos, 5);
        peg$currPos += 5;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c644); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        if (s2 !== peg$FAILED) {
          if (input.substr(peg$currPos, 3) === peg$c827) {
            s3 = peg$c827;
            peg$currPos += 3;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c828); }
          }
          if (s3 !== peg$FAILED) {
            if (input.substr(peg$currPos, 32) === peg$c677) {
              s4 = peg$c677;
              peg$currPos += 32;
            } else {
              s4 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c678); }
            }
            if (s4 === peg$FAILED) {
              s4 = null;
            }
            if (s4 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c826();
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStun() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 147,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c829) {
      s1 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c830); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 12) === peg$c831) {
          s3 = peg$c831;
          peg$currPos += 12;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c832); }
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c833();
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseResetTarget() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 255 + 148,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c834) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c835); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 10) === peg$c836) {
          s3 = peg$c836;
          peg$currPos += 10;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c837); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 16).toLowerCase() === peg$c838) {
              s5 = input.substr(peg$currPos, 16);
              peg$currPos += 16;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c839); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 32) === peg$c840) {
                  s7 = peg$c840;
                  peg$currPos += 32;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c841); }
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c812();
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseNoEffect() {
    var s0, s1;

    var key    = peg$currPos * 255 + 149,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 19) === peg$c842) {
      s1 = peg$c842;
      peg$currPos += 19;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c843); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c812();
    }
    s0 = s1;

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parsePersists() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 150,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8).toLowerCase() === peg$c844) {
      s1 = input.substr(peg$currPos, 8);
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c845); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 12) === peg$c846) {
          s3 = peg$c846;
          peg$currPos += 12;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c847); }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c812();
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseGameOver() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 151,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c848) {
      s1 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c849); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 17) === peg$c850) {
          s3 = peg$c850;
          peg$currPos += 17;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c851); }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c812();
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseUnknown() {
    var s0, s1;

    var key    = peg$currPos * 255 + 152,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 63) {
      s1 = peg$c9;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c10); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c812();
    }
    s0 = s1;

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseGilUpLm() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 255 + 153,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseInteger();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 55) === peg$c852) {
        s2 = peg$c852;
        peg$currPos += 55;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c853); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          s4 = peg$parseInteger();
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 37) {
              s5 = peg$c12;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c13); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseCondition();
                if (s7 === peg$FAILED) {
                  s7 = null;
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c854(s1, s4, s7);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseTrigger() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17, s18, s19, s20, s21, s22, s23, s24, s25, s26, s27;

    var key    = peg$currPos * 255 + 154,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c711) {
      s1 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c855); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 5) === peg$c856) {
          s3 = peg$c856;
          peg$currPos += 5;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c857); }
        }
        if (s3 === peg$FAILED) {
          if (input.substr(peg$currPos, 7) === peg$c858) {
            s3 = peg$c858;
            peg$currPos += 7;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c859); }
          }
          if (s3 === peg$FAILED) {
            if (input.substr(peg$currPos, 19) === peg$c860) {
              s3 = peg$c860;
              peg$currPos += 19;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c861); }
            }
            if (s3 === peg$FAILED) {
              if (input.substr(peg$currPos, 13) === peg$c862) {
                s3 = peg$c862;
                peg$currPos += 13;
              } else {
                s3 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c863); }
              }
            }
          }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseTriggerCount();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 8) === peg$c29) {
                  s7 = peg$c29;
                  peg$currPos += 8;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c30); }
                }
                if (s7 === peg$FAILED) {
                  s7 = null;
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseSkillType();
                    if (s9 === peg$FAILED) {
                      s9 = null;
                    }
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parse_();
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parseElementListOrOptions();
                        if (s11 === peg$FAILED) {
                          s11 = null;
                        }
                        if (s11 !== peg$FAILED) {
                          s12 = peg$parse_();
                          if (s12 !== peg$FAILED) {
                            s13 = peg$parseSchoolAndOrSlashList();
                            if (s13 === peg$FAILED) {
                              s13 = null;
                            }
                            if (s13 !== peg$FAILED) {
                              s14 = peg$parse_();
                              if (s14 !== peg$FAILED) {
                                if (input.substr(peg$currPos, 4) === peg$c31) {
                                  s15 = peg$c31;
                                  peg$currPos += 4;
                                } else {
                                  s15 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c32); }
                                }
                                if (s15 === peg$FAILED) {
                                  s15 = null;
                                }
                                if (s15 !== peg$FAILED) {
                                  s16 = peg$parse_();
                                  if (s16 !== peg$FAILED) {
                                    if (input.substr(peg$currPos, 5) === peg$c33) {
                                      s17 = peg$c33;
                                      peg$currPos += 5;
                                    } else {
                                      s17 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c34); }
                                    }
                                    if (s17 === peg$FAILED) {
                                      s17 = null;
                                    }
                                    if (s17 !== peg$FAILED) {
                                      s18 = peg$parse_();
                                      if (s18 !== peg$FAILED) {
                                        s19 = peg$parseAbilityOrAttack();
                                        if (s19 !== peg$FAILED) {
                                          s20 = peg$parse_();
                                          if (s20 !== peg$FAILED) {
                                            if (input.substr(peg$currPos, 12) === peg$c864) {
                                              s21 = peg$c864;
                                              peg$currPos += 12;
                                            } else {
                                              s21 = peg$FAILED;
                                              if (peg$silentFails === 0) { peg$fail(peg$c865); }
                                            }
                                            if (s21 === peg$FAILED) {
                                              s21 = null;
                                            }
                                            if (s21 !== peg$FAILED) {
                                              s22 = peg$parse_();
                                              if (s22 !== peg$FAILED) {
                                                if (input.substr(peg$currPos, 11) === peg$c866) {
                                                  s23 = peg$c866;
                                                  peg$currPos += 11;
                                                } else {
                                                  s23 = peg$FAILED;
                                                  if (peg$silentFails === 0) { peg$fail(peg$c867); }
                                                }
                                                if (s23 === peg$FAILED) {
                                                  s23 = null;
                                                }
                                                if (s23 !== peg$FAILED) {
                                                  s24 = peg$currPos;
                                                  peg$silentFails++;
                                                  s25 = peg$currPos;
                                                  s26 = peg$parse_();
                                                  if (s26 !== peg$FAILED) {
                                                    if (input.substr(peg$currPos, 4) === peg$c868) {
                                                      s27 = peg$c868;
                                                      peg$currPos += 4;
                                                    } else {
                                                      s27 = peg$FAILED;
                                                      if (peg$silentFails === 0) { peg$fail(peg$c869); }
                                                    }
                                                    if (s27 !== peg$FAILED) {
                                                      s26 = [s26, s27];
                                                      s25 = s26;
                                                    } else {
                                                      peg$currPos = s25;
                                                      s25 = peg$FAILED;
                                                    }
                                                  } else {
                                                    peg$currPos = s25;
                                                    s25 = peg$FAILED;
                                                  }
                                                  peg$silentFails--;
                                                  if (s25 === peg$FAILED) {
                                                    s24 = void 0;
                                                  } else {
                                                    peg$currPos = s24;
                                                    s24 = peg$FAILED;
                                                  }
                                                  if (s24 !== peg$FAILED) {
                                                    peg$savedPos = s0;
                                                    s1 = peg$c870(s3, s5, s7, s9, s11, s13, s15, s17, s19, s21);
                                                    s0 = s1;
                                                  } else {
                                                    peg$currPos = s0;
                                                    s0 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s0;
                                                  s0 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s0;
                                          s0 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 2).toLowerCase() === peg$c693) {
        s1 = input.substr(peg$currPos, 2);
        peg$currPos += 2;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c871); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        if (s2 !== peg$FAILED) {
          if (input.substr(peg$currPos, 26) === peg$c872) {
            s3 = peg$c872;
            peg$currPos += 26;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c873); }
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            if (s4 !== peg$FAILED) {
              s5 = peg$parseTriggerCount();
              if (s5 !== peg$FAILED) {
                s6 = peg$parse_();
                if (s6 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 8) === peg$c29) {
                    s7 = peg$c29;
                    peg$currPos += 8;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c30); }
                  }
                  if (s7 === peg$FAILED) {
                    s7 = null;
                  }
                  if (s7 !== peg$FAILED) {
                    s8 = peg$parse_();
                    if (s8 !== peg$FAILED) {
                      s9 = peg$parseElementListOrOptions();
                      if (s9 === peg$FAILED) {
                        s9 = null;
                      }
                      if (s9 !== peg$FAILED) {
                        s10 = peg$parse_();
                        if (s10 !== peg$FAILED) {
                          s11 = peg$parseSchoolAndOrList();
                          if (s11 === peg$FAILED) {
                            s11 = null;
                          }
                          if (s11 !== peg$FAILED) {
                            s12 = peg$parse_();
                            if (s12 !== peg$FAILED) {
                              if (input.substr(peg$currPos, 4) === peg$c31) {
                                s13 = peg$c31;
                                peg$currPos += 4;
                              } else {
                                s13 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c32); }
                              }
                              if (s13 === peg$FAILED) {
                                s13 = null;
                              }
                              if (s13 !== peg$FAILED) {
                                s14 = peg$parse_();
                                if (s14 !== peg$FAILED) {
                                  s15 = peg$parseAbilityOrAttack();
                                  if (s15 !== peg$FAILED) {
                                    peg$savedPos = s0;
                                    s1 = peg$c874(s5, s7, s9, s11, s13, s15);
                                    s0 = s1;
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 4).toLowerCase() === peg$c695) {
          s1 = input.substr(peg$currPos, 4);
          peg$currPos += 4;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c875); }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          if (s2 !== peg$FAILED) {
            if (input.substr(peg$currPos, 13) === peg$c876) {
              s3 = peg$c876;
              peg$currPos += 13;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c877); }
            }
            if (s3 !== peg$FAILED) {
              s4 = peg$parse_();
              if (s4 !== peg$FAILED) {
                s5 = peg$parseTriggerCount();
                if (s5 !== peg$FAILED) {
                  s6 = peg$parse_();
                  if (s6 !== peg$FAILED) {
                    s7 = peg$parseElementListOrOptions();
                    if (s7 === peg$FAILED) {
                      s7 = null;
                    }
                    if (s7 !== peg$FAILED) {
                      s8 = peg$parse_();
                      if (s8 !== peg$FAILED) {
                        s9 = peg$parseSchoolAndOrList();
                        if (s9 === peg$FAILED) {
                          s9 = null;
                        }
                        if (s9 !== peg$FAILED) {
                          s10 = peg$parse_();
                          if (s10 !== peg$FAILED) {
                            if (input.substr(peg$currPos, 4) === peg$c31) {
                              s11 = peg$c31;
                              peg$currPos += 4;
                            } else {
                              s11 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c32); }
                            }
                            if (s11 === peg$FAILED) {
                              s11 = null;
                            }
                            if (s11 !== peg$FAILED) {
                              s12 = peg$parse_();
                              if (s12 !== peg$FAILED) {
                                s13 = peg$parseAbilityOrAttack();
                                if (s13 !== peg$FAILED) {
                                  s14 = peg$parse_();
                                  if (s14 !== peg$FAILED) {
                                    if (input.substr(peg$currPos, 17) === peg$c878) {
                                      s15 = peg$c878;
                                      peg$currPos += 17;
                                    } else {
                                      s15 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c879); }
                                    }
                                    if (s15 !== peg$FAILED) {
                                      peg$savedPos = s0;
                                      s1 = peg$c880(s5, s7, s9, s11, s13);
                                      s0 = s1;
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.substr(peg$currPos, 5).toLowerCase() === peg$c353) {
            s1 = input.substr(peg$currPos, 5);
            peg$currPos += 5;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c881); }
          }
          if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            if (s2 !== peg$FAILED) {
              s3 = peg$parseOrdinal();
              if (s3 !== peg$FAILED) {
                s4 = peg$parse_();
                if (s4 !== peg$FAILED) {
                  s5 = peg$parseElementListOrOptions();
                  if (s5 === peg$FAILED) {
                    s5 = null;
                  }
                  if (s5 !== peg$FAILED) {
                    s6 = peg$parse_();
                    if (s6 !== peg$FAILED) {
                      s7 = peg$parseSchoolAndOrList();
                      if (s7 === peg$FAILED) {
                        s7 = null;
                      }
                      if (s7 !== peg$FAILED) {
                        s8 = peg$parse_();
                        if (s8 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 4) === peg$c31) {
                            s9 = peg$c31;
                            peg$currPos += 4;
                          } else {
                            s9 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c32); }
                          }
                          if (s9 === peg$FAILED) {
                            s9 = null;
                          }
                          if (s9 !== peg$FAILED) {
                            s10 = peg$parse_();
                            if (s10 !== peg$FAILED) {
                              s11 = peg$parseAbilityOrAttack();
                              if (s11 !== peg$FAILED) {
                                peg$savedPos = s0;
                                s1 = peg$c882(s3, s5, s7, s9, s11);
                                s0 = s1;
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            if (input.substr(peg$currPos, 5).toLowerCase() === peg$c711) {
              s1 = input.substr(peg$currPos, 5);
              peg$currPos += 5;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c855); }
            }
            if (s1 !== peg$FAILED) {
              s2 = peg$parse_();
              if (s2 !== peg$FAILED) {
                if (input.substr(peg$currPos, 22) === peg$c883) {
                  s3 = peg$c883;
                  peg$currPos += 22;
                } else {
                  s3 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c884); }
                }
                if (s3 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c885();
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              if (input.substr(peg$currPos, 5).toLowerCase() === peg$c711) {
                s1 = input.substr(peg$currPos, 5);
                peg$currPos += 5;
              } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c855); }
              }
              if (s1 !== peg$FAILED) {
                s2 = peg$parse_();
                if (s2 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 29) === peg$c886) {
                    s3 = peg$c886;
                    peg$currPos += 29;
                  } else {
                    s3 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c887); }
                  }
                  if (s3 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c888();
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
              if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (input.substr(peg$currPos, 4).toLowerCase() === peg$c695) {
                  s1 = input.substr(peg$currPos, 4);
                  peg$currPos += 4;
                } else {
                  s1 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c875); }
                }
                if (s1 !== peg$FAILED) {
                  s2 = peg$parse_();
                  if (s2 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 7) === peg$c691) {
                      s3 = peg$c691;
                      peg$currPos += 7;
                    } else {
                      s3 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c889); }
                    }
                    if (s3 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c890();
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                  s0 = peg$currPos;
                  if (input.substr(peg$currPos, 5).toLowerCase() === peg$c353) {
                    s1 = input.substr(peg$currPos, 5);
                    peg$currPos += 5;
                  } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c881); }
                  }
                  if (s1 !== peg$FAILED) {
                    s2 = peg$parse_();
                    if (s2 !== peg$FAILED) {
                      s3 = peg$parseDecimalNumber();
                      if (s3 !== peg$FAILED) {
                        s4 = peg$parse_();
                        if (s4 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 7) === peg$c444) {
                            s5 = peg$c444;
                            peg$currPos += 7;
                          } else {
                            s5 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c445); }
                          }
                          if (s5 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c891(s3);
                            s0 = s1;
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                  if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c892) {
                      s1 = input.substr(peg$currPos, 4);
                      peg$currPos += 4;
                    } else {
                      s1 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c893); }
                    }
                    if (s1 !== peg$FAILED) {
                      s2 = peg$parse_();
                      if (s2 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 13) === peg$c894) {
                          s3 = peg$c894;
                          peg$currPos += 13;
                        } else {
                          s3 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c895); }
                        }
                        if (s3 !== peg$FAILED) {
                          s4 = peg$currPos;
                          s5 = peg$parse_();
                          if (s5 !== peg$FAILED) {
                            if (input.substr(peg$currPos, 2) === peg$c51) {
                              s6 = peg$c51;
                              peg$currPos += 2;
                            } else {
                              s6 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c52); }
                            }
                            if (s6 !== peg$FAILED) {
                              s7 = peg$parse_();
                              if (s7 !== peg$FAILED) {
                                s8 = peg$parseSkillType();
                                if (s8 !== peg$FAILED) {
                                  s9 = peg$parse_();
                                  if (s9 !== peg$FAILED) {
                                    if (input.substr(peg$currPos, 6) === peg$c162) {
                                      s10 = peg$c162;
                                      peg$currPos += 6;
                                    } else {
                                      s10 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c163); }
                                    }
                                    if (s10 !== peg$FAILED) {
                                      peg$savedPos = s4;
                                      s5 = peg$c420(s8);
                                      s4 = s5;
                                    } else {
                                      peg$currPos = s4;
                                      s4 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s4;
                                    s4 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s4;
                                  s4 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s4;
                                s4 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s4;
                              s4 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s4;
                            s4 = peg$FAILED;
                          }
                          if (s4 === peg$FAILED) {
                            s4 = null;
                          }
                          if (s4 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c896(s4);
                            s0 = s1;
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                    if (s0 === peg$FAILED) {
                      s0 = peg$currPos;
                      if (input.substr(peg$currPos, 2).toLowerCase() === peg$c51) {
                        s1 = input.substr(peg$currPos, 2);
                        peg$currPos += 2;
                      } else {
                        s1 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c897); }
                      }
                      if (s1 !== peg$FAILED) {
                        s2 = peg$parse_();
                        if (s2 !== peg$FAILED) {
                          s3 = peg$parseSkillType();
                          if (s3 !== peg$FAILED) {
                            s4 = peg$parse_();
                            if (s4 !== peg$FAILED) {
                              if (input.substr(peg$currPos, 7) === peg$c35) {
                                s5 = peg$c35;
                                peg$currPos += 7;
                              } else {
                                s5 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c36); }
                              }
                              if (s5 !== peg$FAILED) {
                                peg$savedPos = s0;
                                s1 = peg$c896(s3);
                                s0 = s1;
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                      if (s0 === peg$FAILED) {
                        s0 = peg$currPos;
                        if (input.substr(peg$currPos, 4).toLowerCase() === peg$c892) {
                          s1 = input.substr(peg$currPos, 4);
                          peg$currPos += 4;
                        } else {
                          s1 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c893); }
                        }
                        if (s1 !== peg$FAILED) {
                          s2 = peg$parse_();
                          if (s2 !== peg$FAILED) {
                            if (input.substr(peg$currPos, 14) === peg$c898) {
                              s3 = peg$c898;
                              peg$currPos += 14;
                            } else {
                              s3 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c899); }
                            }
                            if (s3 !== peg$FAILED) {
                              peg$savedPos = s0;
                              s1 = peg$c900();
                              s0 = s1;
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                        if (s0 === peg$FAILED) {
                          s0 = peg$currPos;
                          if (input.substr(peg$currPos, 25) === peg$c901) {
                            s1 = peg$c901;
                            peg$currPos += 25;
                          } else {
                            s1 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c902); }
                          }
                          if (s1 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c903();
                          }
                          s0 = s1;
                          if (s0 === peg$FAILED) {
                            s0 = peg$currPos;
                            if (input.substr(peg$currPos, 4).toLowerCase() === peg$c695) {
                              s1 = input.substr(peg$currPos, 4);
                              peg$currPos += 4;
                            } else {
                              s1 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c875); }
                            }
                            if (s1 !== peg$FAILED) {
                              s2 = peg$parse_();
                              if (s2 !== peg$FAILED) {
                                if (input.substr(peg$currPos, 3) === peg$c701) {
                                  s3 = peg$c701;
                                  peg$currPos += 3;
                                } else {
                                  s3 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c702); }
                                }
                                if (s3 === peg$FAILED) {
                                  s3 = null;
                                }
                                if (s3 !== peg$FAILED) {
                                  s4 = peg$parse_();
                                  if (s4 !== peg$FAILED) {
                                    s5 = peg$parseStatusName();
                                    if (s5 !== peg$FAILED) {
                                      s6 = peg$parse_();
                                      if (s6 !== peg$FAILED) {
                                        if (input.substr(peg$currPos, 10) === peg$c904) {
                                          s7 = peg$c904;
                                          peg$currPos += 10;
                                        } else {
                                          s7 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c905); }
                                        }
                                        if (s7 !== peg$FAILED) {
                                          peg$savedPos = s0;
                                          s1 = peg$c906(s5);
                                          s0 = s1;
                                        } else {
                                          peg$currPos = s0;
                                          s0 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                            if (s0 === peg$FAILED) {
                              s0 = peg$currPos;
                              if (input.substr(peg$currPos, 4).toLowerCase() === peg$c695) {
                                s1 = input.substr(peg$currPos, 4);
                                peg$currPos += 4;
                              } else {
                                s1 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c875); }
                              }
                              if (s1 === peg$FAILED) {
                                if (input.substr(peg$currPos, 5).toLowerCase() === peg$c711) {
                                  s1 = input.substr(peg$currPos, 5);
                                  peg$currPos += 5;
                                } else {
                                  s1 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c855); }
                                }
                              }
                              if (s1 !== peg$FAILED) {
                                s2 = peg$parse_();
                                if (s2 !== peg$FAILED) {
                                  if (input.substr(peg$currPos, 3) === peg$c701) {
                                    s3 = peg$c701;
                                    peg$currPos += 3;
                                  } else {
                                    s3 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c702); }
                                  }
                                  if (s3 === peg$FAILED) {
                                    s3 = null;
                                  }
                                  if (s3 !== peg$FAILED) {
                                    s4 = peg$parse_();
                                    if (s4 !== peg$FAILED) {
                                      s5 = peg$parseGenericName();
                                      if (s5 !== peg$FAILED) {
                                        s6 = peg$parse_();
                                        if (s6 !== peg$FAILED) {
                                          if (input.substr(peg$currPos, 10) === peg$c904) {
                                            s7 = peg$c904;
                                            peg$currPos += 10;
                                          } else {
                                            s7 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c905); }
                                          }
                                          if (s7 !== peg$FAILED) {
                                            peg$savedPos = s0;
                                            s1 = peg$c906(s5);
                                            s0 = s1;
                                          } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s0;
                                          s0 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                              if (s0 === peg$FAILED) {
                                s0 = peg$currPos;
                                if (input.substr(peg$currPos, 5) === peg$c711) {
                                  s1 = peg$c711;
                                  peg$currPos += 5;
                                } else {
                                  s1 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c712); }
                                }
                                if (s1 !== peg$FAILED) {
                                  s2 = peg$parse_();
                                  if (s2 !== peg$FAILED) {
                                    if (input.substr(peg$currPos, 5) === peg$c856) {
                                      s3 = peg$c856;
                                      peg$currPos += 5;
                                    } else {
                                      s3 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c857); }
                                    }
                                    if (s3 === peg$FAILED) {
                                      if (input.substr(peg$currPos, 7) === peg$c858) {
                                        s3 = peg$c858;
                                        peg$currPos += 7;
                                      } else {
                                        s3 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c859); }
                                      }
                                    }
                                    if (s3 !== peg$FAILED) {
                                      s4 = peg$parse_();
                                      if (s4 !== peg$FAILED) {
                                        s5 = peg$parseAnySkillName();
                                        if (s5 !== peg$FAILED) {
                                          s6 = peg$parse_();
                                          if (s6 !== peg$FAILED) {
                                            if (input.substr(peg$currPos, 5) === peg$c907) {
                                              s7 = peg$c907;
                                              peg$currPos += 5;
                                            } else {
                                              s7 = peg$FAILED;
                                              if (peg$silentFails === 0) { peg$fail(peg$c908); }
                                            }
                                            if (s7 !== peg$FAILED) {
                                              peg$savedPos = peg$currPos;
                                              s8 = peg$c909(s5);
                                              if (s8) {
                                                s8 = void 0;
                                              } else {
                                                s8 = peg$FAILED;
                                              }
                                              if (s8 !== peg$FAILED) {
                                                peg$savedPos = s0;
                                                s1 = peg$c910(s5);
                                                s0 = s1;
                                              } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s0;
                                          s0 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                                if (s0 === peg$FAILED) {
                                  s0 = peg$currPos;
                                  if (input.substr(peg$currPos, 4).toLowerCase() === peg$c695) {
                                    s1 = input.substr(peg$currPos, 4);
                                    peg$currPos += 4;
                                  } else {
                                    s1 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c875); }
                                  }
                                  if (s1 === peg$FAILED) {
                                    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c711) {
                                      s1 = input.substr(peg$currPos, 5);
                                      peg$currPos += 5;
                                    } else {
                                      s1 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c855); }
                                    }
                                  }
                                  if (s1 !== peg$FAILED) {
                                    s2 = peg$parse_();
                                    if (s2 !== peg$FAILED) {
                                      if (input.substr(peg$currPos, 5) === peg$c856) {
                                        s3 = peg$c856;
                                        peg$currPos += 5;
                                      } else {
                                        s3 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c857); }
                                      }
                                      if (s3 === peg$FAILED) {
                                        if (input.substr(peg$currPos, 7) === peg$c858) {
                                          s3 = peg$c858;
                                          peg$currPos += 7;
                                        } else {
                                          s3 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c859); }
                                        }
                                      }
                                      if (s3 !== peg$FAILED) {
                                        s4 = peg$parse_();
                                        if (s4 !== peg$FAILED) {
                                          s5 = peg$parseAnySkillName();
                                          if (s5 !== peg$FAILED) {
                                            s6 = peg$parse_();
                                            if (s6 !== peg$FAILED) {
                                              s7 = peg$parseOccurrence();
                                              if (s7 === peg$FAILED) {
                                                s7 = null;
                                              }
                                              if (s7 !== peg$FAILED) {
                                                peg$savedPos = s0;
                                                s1 = peg$c911(s5, s7);
                                                s0 = s1;
                                              } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s0;
                                          s0 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                  if (s0 === peg$FAILED) {
                                    s0 = peg$currPos;
                                    if (input.substr(peg$currPos, 4) === peg$c695) {
                                      s1 = peg$c695;
                                      peg$currPos += 4;
                                    } else {
                                      s1 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c696); }
                                    }
                                    if (s1 !== peg$FAILED) {
                                      s2 = peg$parse_();
                                      if (s2 !== peg$FAILED) {
                                        s3 = peg$parseAnySkillName();
                                        if (s3 !== peg$FAILED) {
                                          s4 = peg$parse_();
                                          if (s4 !== peg$FAILED) {
                                            if (input.substr(peg$currPos, 12) === peg$c912) {
                                              s5 = peg$c912;
                                              peg$currPos += 12;
                                            } else {
                                              s5 = peg$FAILED;
                                              if (peg$silentFails === 0) { peg$fail(peg$c913); }
                                            }
                                            if (s5 !== peg$FAILED) {
                                              s6 = peg$parse_();
                                              if (s6 !== peg$FAILED) {
                                                s7 = peg$parseInteger();
                                                if (s7 !== peg$FAILED) {
                                                  s8 = peg$parse_();
                                                  if (s8 !== peg$FAILED) {
                                                    if (input.substr(peg$currPos, 5) === peg$c907) {
                                                      s9 = peg$c907;
                                                      peg$currPos += 5;
                                                    } else {
                                                      s9 = peg$FAILED;
                                                      if (peg$silentFails === 0) { peg$fail(peg$c908); }
                                                    }
                                                    if (s9 !== peg$FAILED) {
                                                      peg$savedPos = s0;
                                                      s1 = peg$c914(s3, s7);
                                                      s0 = s1;
                                                    } else {
                                                      peg$currPos = s0;
                                                      s0 = peg$FAILED;
                                                    }
                                                  } else {
                                                    peg$currPos = s0;
                                                    s0 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s0;
                                                  s0 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s0;
                                          s0 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                    if (s0 === peg$FAILED) {
                                      s0 = peg$currPos;
                                      if (input.substr(peg$currPos, 21) === peg$c915) {
                                        s1 = peg$c915;
                                        peg$currPos += 21;
                                      } else {
                                        s1 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c916); }
                                      }
                                      if (s1 !== peg$FAILED) {
                                        s2 = peg$parse_();
                                        if (s2 !== peg$FAILED) {
                                          s3 = peg$parseAnySkillName();
                                          if (s3 !== peg$FAILED) {
                                            s4 = peg$parse_();
                                            if (s4 !== peg$FAILED) {
                                              s5 = peg$parseNumberString();
                                              if (s5 !== peg$FAILED) {
                                                s6 = peg$parse_();
                                                if (s6 !== peg$FAILED) {
                                                  if (input.substr(peg$currPos, 5) === peg$c907) {
                                                    s7 = peg$c907;
                                                    peg$currPos += 5;
                                                  } else {
                                                    s7 = peg$FAILED;
                                                    if (peg$silentFails === 0) { peg$fail(peg$c908); }
                                                  }
                                                  if (s7 !== peg$FAILED) {
                                                    peg$savedPos = s0;
                                                    s1 = peg$c914(s3, s5);
                                                    s0 = s1;
                                                  } else {
                                                    peg$currPos = s0;
                                                    s0 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s0;
                                                  s0 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s0;
                                          s0 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                      if (s0 === peg$FAILED) {
                                        s0 = peg$currPos;
                                        if (input.substr(peg$currPos, 5).toLowerCase() === peg$c711) {
                                          s1 = input.substr(peg$currPos, 5);
                                          peg$currPos += 5;
                                        } else {
                                          s1 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c855); }
                                        }
                                        if (s1 !== peg$FAILED) {
                                          s2 = peg$parse_();
                                          if (s2 !== peg$FAILED) {
                                            if (input.substr(peg$currPos, 5) === peg$c856) {
                                              s3 = peg$c856;
                                              peg$currPos += 5;
                                            } else {
                                              s3 = peg$FAILED;
                                              if (peg$silentFails === 0) { peg$fail(peg$c857); }
                                            }
                                            if (s3 === peg$FAILED) {
                                              if (input.substr(peg$currPos, 7) === peg$c858) {
                                                s3 = peg$c858;
                                                peg$currPos += 7;
                                              } else {
                                                s3 = peg$FAILED;
                                                if (peg$silentFails === 0) { peg$fail(peg$c859); }
                                              }
                                            }
                                            if (s3 !== peg$FAILED) {
                                              s4 = peg$parse_();
                                              if (s4 !== peg$FAILED) {
                                                s5 = peg$parseNumberString();
                                                if (s5 === peg$FAILED) {
                                                  s5 = peg$parseIntegerSlashList();
                                                }
                                                if (s5 !== peg$FAILED) {
                                                  if (input.charCodeAt(peg$currPos) === 43) {
                                                    s6 = peg$c19;
                                                    peg$currPos++;
                                                  } else {
                                                    s6 = peg$FAILED;
                                                    if (peg$silentFails === 0) { peg$fail(peg$c20); }
                                                  }
                                                  if (s6 === peg$FAILED) {
                                                    s6 = null;
                                                  }
                                                  if (s6 !== peg$FAILED) {
                                                    s7 = peg$parse_();
                                                    if (s7 !== peg$FAILED) {
                                                      if (input.substr(peg$currPos, 2) === peg$c214) {
                                                        s8 = peg$c214;
                                                        peg$currPos += 2;
                                                      } else {
                                                        s8 = peg$FAILED;
                                                        if (peg$silentFails === 0) { peg$fail(peg$c215); }
                                                      }
                                                      if (s8 !== peg$FAILED) {
                                                        s9 = peg$parse_();
                                                        if (s9 !== peg$FAILED) {
                                                          if (input.substr(peg$currPos, 6) === peg$c917) {
                                                            s10 = peg$c917;
                                                            peg$currPos += 6;
                                                          } else {
                                                            s10 = peg$FAILED;
                                                            if (peg$silentFails === 0) { peg$fail(peg$c918); }
                                                          }
                                                          if (s10 === peg$FAILED) {
                                                            s10 = null;
                                                          }
                                                          if (s10 !== peg$FAILED) {
                                                            s11 = peg$parse_();
                                                            if (s11 !== peg$FAILED) {
                                                              s12 = peg$parseSkill1Or2();
                                                              if (s12 !== peg$FAILED) {
                                                                peg$savedPos = s0;
                                                                s1 = peg$c919(s5, s6, s12);
                                                                s0 = s1;
                                                              } else {
                                                                peg$currPos = s0;
                                                                s0 = peg$FAILED;
                                                              }
                                                            } else {
                                                              peg$currPos = s0;
                                                              s0 = peg$FAILED;
                                                            }
                                                          } else {
                                                            peg$currPos = s0;
                                                            s0 = peg$FAILED;
                                                          }
                                                        } else {
                                                          peg$currPos = s0;
                                                          s0 = peg$FAILED;
                                                        }
                                                      } else {
                                                        peg$currPos = s0;
                                                        s0 = peg$FAILED;
                                                      }
                                                    } else {
                                                      peg$currPos = s0;
                                                      s0 = peg$FAILED;
                                                    }
                                                  } else {
                                                    peg$currPos = s0;
                                                    s0 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s0;
                                                  s0 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s0;
                                          s0 = peg$FAILED;
                                        }
                                        if (s0 === peg$FAILED) {
                                          s0 = peg$currPos;
                                          if (input.substr(peg$currPos, 5).toLowerCase() === peg$c711) {
                                            s1 = input.substr(peg$currPos, 5);
                                            peg$currPos += 5;
                                          } else {
                                            s1 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c855); }
                                          }
                                          if (s1 !== peg$FAILED) {
                                            s2 = peg$parse_();
                                            if (s2 !== peg$FAILED) {
                                              if (input.substr(peg$currPos, 5) === peg$c856) {
                                                s3 = peg$c856;
                                                peg$currPos += 5;
                                              } else {
                                                s3 = peg$FAILED;
                                                if (peg$silentFails === 0) { peg$fail(peg$c857); }
                                              }
                                              if (s3 === peg$FAILED) {
                                                if (input.substr(peg$currPos, 7) === peg$c858) {
                                                  s3 = peg$c858;
                                                  peg$currPos += 7;
                                                } else {
                                                  s3 = peg$FAILED;
                                                  if (peg$silentFails === 0) { peg$fail(peg$c859); }
                                                }
                                              }
                                              if (s3 !== peg$FAILED) {
                                                s4 = peg$parse_();
                                                if (s4 !== peg$FAILED) {
                                                  s5 = peg$parseNumberString();
                                                  if (s5 !== peg$FAILED) {
                                                    s6 = peg$parse_();
                                                    if (s6 !== peg$FAILED) {
                                                      if (input.substr(peg$currPos, 2) === peg$c214) {
                                                        s7 = peg$c214;
                                                        peg$currPos += 2;
                                                      } else {
                                                        s7 = peg$FAILED;
                                                        if (peg$silentFails === 0) { peg$fail(peg$c215); }
                                                      }
                                                      if (s7 !== peg$FAILED) {
                                                        s8 = peg$parse_();
                                                        if (s8 !== peg$FAILED) {
                                                          if (input.substr(peg$currPos, 6) === peg$c917) {
                                                            s9 = peg$c917;
                                                            peg$currPos += 6;
                                                          } else {
                                                            s9 = peg$FAILED;
                                                            if (peg$silentFails === 0) { peg$fail(peg$c918); }
                                                          }
                                                          if (s9 === peg$FAILED) {
                                                            s9 = null;
                                                          }
                                                          if (s9 !== peg$FAILED) {
                                                            s10 = peg$parse_();
                                                            if (s10 !== peg$FAILED) {
                                                              s11 = peg$parseSkill1Or2();
                                                              if (s11 !== peg$FAILED) {
                                                                peg$savedPos = s0;
                                                                s1 = peg$c920(s5, s11);
                                                                s0 = s1;
                                                              } else {
                                                                peg$currPos = s0;
                                                                s0 = peg$FAILED;
                                                              }
                                                            } else {
                                                              peg$currPos = s0;
                                                              s0 = peg$FAILED;
                                                            }
                                                          } else {
                                                            peg$currPos = s0;
                                                            s0 = peg$FAILED;
                                                          }
                                                        } else {
                                                          peg$currPos = s0;
                                                          s0 = peg$FAILED;
                                                        }
                                                      } else {
                                                        peg$currPos = s0;
                                                        s0 = peg$FAILED;
                                                      }
                                                    } else {
                                                      peg$currPos = s0;
                                                      s0 = peg$FAILED;
                                                    }
                                                  } else {
                                                    peg$currPos = s0;
                                                    s0 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s0;
                                                  s0 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                          }
                                          if (s0 === peg$FAILED) {
                                            s0 = peg$currPos;
                                            if (input.substr(peg$currPos, 11) === peg$c921) {
                                              s1 = peg$c921;
                                              peg$currPos += 11;
                                            } else {
                                              s1 = peg$FAILED;
                                              if (peg$silentFails === 0) { peg$fail(peg$c922); }
                                            }
                                            if (s1 !== peg$FAILED) {
                                              s2 = peg$parse_();
                                              if (s2 !== peg$FAILED) {
                                                s3 = peg$parseIntegerSlashList();
                                                if (s3 !== peg$FAILED) {
                                                  s4 = peg$parse_();
                                                  if (s4 !== peg$FAILED) {
                                                    if (input.substr(peg$currPos, 2) === peg$c214) {
                                                      s5 = peg$c214;
                                                      peg$currPos += 2;
                                                    } else {
                                                      s5 = peg$FAILED;
                                                      if (peg$silentFails === 0) { peg$fail(peg$c215); }
                                                    }
                                                    if (s5 !== peg$FAILED) {
                                                      s6 = peg$parse_();
                                                      if (s6 !== peg$FAILED) {
                                                        s7 = peg$parseAnySkillName();
                                                        if (s7 !== peg$FAILED) {
                                                          peg$savedPos = s0;
                                                          s1 = peg$c920(s3, s7);
                                                          s0 = s1;
                                                        } else {
                                                          peg$currPos = s0;
                                                          s0 = peg$FAILED;
                                                        }
                                                      } else {
                                                        peg$currPos = s0;
                                                        s0 = peg$FAILED;
                                                      }
                                                    } else {
                                                      peg$currPos = s0;
                                                      s0 = peg$FAILED;
                                                    }
                                                  } else {
                                                    peg$currPos = s0;
                                                    s0 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s0;
                                                  s0 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                            if (s0 === peg$FAILED) {
                                              s0 = peg$currPos;
                                              if (input.substr(peg$currPos, 5).toLowerCase() === peg$c711) {
                                                s1 = input.substr(peg$currPos, 5);
                                                peg$currPos += 5;
                                              } else {
                                                s1 = peg$FAILED;
                                                if (peg$silentFails === 0) { peg$fail(peg$c855); }
                                              }
                                              if (s1 !== peg$FAILED) {
                                                s2 = peg$parse_();
                                                if (s2 !== peg$FAILED) {
                                                  if (input.substr(peg$currPos, 6) === peg$c923) {
                                                    s3 = peg$c923;
                                                    peg$currPos += 6;
                                                  } else {
                                                    s3 = peg$FAILED;
                                                    if (peg$silentFails === 0) { peg$fail(peg$c924); }
                                                  }
                                                  if (s3 !== peg$FAILED) {
                                                    s4 = peg$parse_();
                                                    if (s4 !== peg$FAILED) {
                                                      s5 = peg$parseElementListOrOptions();
                                                      if (s5 !== peg$FAILED) {
                                                        s6 = peg$parse_();
                                                        if (s6 !== peg$FAILED) {
                                                          if (input.substr(peg$currPos, 13) === peg$c925) {
                                                            s7 = peg$c925;
                                                            peg$currPos += 13;
                                                          } else {
                                                            s7 = peg$FAILED;
                                                            if (peg$silentFails === 0) { peg$fail(peg$c926); }
                                                          }
                                                          if (s7 !== peg$FAILED) {
                                                            s8 = peg$parse_();
                                                            if (s8 !== peg$FAILED) {
                                                              s9 = peg$parseSkillTypeList();
                                                              if (s9 !== peg$FAILED) {
                                                                s10 = peg$parse_();
                                                                if (s10 !== peg$FAILED) {
                                                                  if (input.substr(peg$currPos, 27) === peg$c927) {
                                                                    s11 = peg$c927;
                                                                    peg$currPos += 27;
                                                                  } else {
                                                                    s11 = peg$FAILED;
                                                                    if (peg$silentFails === 0) { peg$fail(peg$c928); }
                                                                  }
                                                                  if (s11 !== peg$FAILED) {
                                                                    peg$savedPos = s0;
                                                                    s1 = peg$c929(s5, s9);
                                                                    s0 = s1;
                                                                  } else {
                                                                    peg$currPos = s0;
                                                                    s0 = peg$FAILED;
                                                                  }
                                                                } else {
                                                                  peg$currPos = s0;
                                                                  s0 = peg$FAILED;
                                                                }
                                                              } else {
                                                                peg$currPos = s0;
                                                                s0 = peg$FAILED;
                                                              }
                                                            } else {
                                                              peg$currPos = s0;
                                                              s0 = peg$FAILED;
                                                            }
                                                          } else {
                                                            peg$currPos = s0;
                                                            s0 = peg$FAILED;
                                                          }
                                                        } else {
                                                          peg$currPos = s0;
                                                          s0 = peg$FAILED;
                                                        }
                                                      } else {
                                                        peg$currPos = s0;
                                                        s0 = peg$FAILED;
                                                      }
                                                    } else {
                                                      peg$currPos = s0;
                                                      s0 = peg$FAILED;
                                                    }
                                                  } else {
                                                    peg$currPos = s0;
                                                    s0 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s0;
                                                  s0 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                              }
                                              if (s0 === peg$FAILED) {
                                                s0 = peg$currPos;
                                                if (input.substr(peg$currPos, 5).toLowerCase() === peg$c711) {
                                                  s1 = input.substr(peg$currPos, 5);
                                                  peg$currPos += 5;
                                                } else {
                                                  s1 = peg$FAILED;
                                                  if (peg$silentFails === 0) { peg$fail(peg$c855); }
                                                }
                                                if (s1 !== peg$FAILED) {
                                                  s2 = peg$parse_();
                                                  if (s2 !== peg$FAILED) {
                                                    if (input.substr(peg$currPos, 26) === peg$c930) {
                                                      s3 = peg$c930;
                                                      peg$currPos += 26;
                                                    } else {
                                                      s3 = peg$FAILED;
                                                      if (peg$silentFails === 0) { peg$fail(peg$c931); }
                                                    }
                                                    if (s3 !== peg$FAILED) {
                                                      peg$savedPos = s0;
                                                      s1 = peg$c932();
                                                      s0 = s1;
                                                    } else {
                                                      peg$currPos = s0;
                                                      s0 = peg$FAILED;
                                                    }
                                                  } else {
                                                    peg$currPos = s0;
                                                    s0 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s0;
                                                  s0 = peg$FAILED;
                                                }
                                                if (s0 === peg$FAILED) {
                                                  s0 = peg$currPos;
                                                  if (input.substr(peg$currPos, 4).toLowerCase() === peg$c695) {
                                                    s1 = input.substr(peg$currPos, 4);
                                                    peg$currPos += 4;
                                                  } else {
                                                    s1 = peg$FAILED;
                                                    if (peg$silentFails === 0) { peg$fail(peg$c875); }
                                                  }
                                                  if (s1 !== peg$FAILED) {
                                                    s2 = peg$parse_();
                                                    if (s2 !== peg$FAILED) {
                                                      if (input.substr(peg$currPos, 10) === peg$c567) {
                                                        s3 = peg$c567;
                                                        peg$currPos += 10;
                                                      } else {
                                                        s3 = peg$FAILED;
                                                        if (peg$silentFails === 0) { peg$fail(peg$c568); }
                                                      }
                                                      if (s3 === peg$FAILED) {
                                                        s3 = null;
                                                      }
                                                      if (s3 !== peg$FAILED) {
                                                        s4 = peg$parse_();
                                                        if (s4 !== peg$FAILED) {
                                                          if (input.substr(peg$currPos, 7) === peg$c933) {
                                                            s5 = peg$c933;
                                                            peg$currPos += 7;
                                                          } else {
                                                            s5 = peg$FAILED;
                                                            if (peg$silentFails === 0) { peg$fail(peg$c934); }
                                                          }
                                                          if (s5 !== peg$FAILED) {
                                                            if (input.charCodeAt(peg$currPos) === 115) {
                                                              s6 = peg$c164;
                                                              peg$currPos++;
                                                            } else {
                                                              s6 = peg$FAILED;
                                                              if (peg$silentFails === 0) { peg$fail(peg$c165); }
                                                            }
                                                            if (s6 === peg$FAILED) {
                                                              s6 = null;
                                                            }
                                                            if (s6 !== peg$FAILED) {
                                                              s7 = peg$parse_();
                                                              if (s7 !== peg$FAILED) {
                                                                if (input.substr(peg$currPos, 5) === peg$c935) {
                                                                  s8 = peg$c935;
                                                                  peg$currPos += 5;
                                                                } else {
                                                                  s8 = peg$FAILED;
                                                                  if (peg$silentFails === 0) { peg$fail(peg$c936); }
                                                                }
                                                                if (s8 !== peg$FAILED) {
                                                                  s9 = peg$parse_();
                                                                  if (s9 !== peg$FAILED) {
                                                                    s10 = peg$parseInteger();
                                                                    if (s10 !== peg$FAILED) {
                                                                      if (input.charCodeAt(peg$currPos) === 37) {
                                                                        s11 = peg$c12;
                                                                        peg$currPos++;
                                                                      } else {
                                                                        s11 = peg$FAILED;
                                                                        if (peg$silentFails === 0) { peg$fail(peg$c13); }
                                                                      }
                                                                      if (s11 !== peg$FAILED) {
                                                                        peg$savedPos = s0;
                                                                        s1 = peg$c937(s10);
                                                                        s0 = s1;
                                                                      } else {
                                                                        peg$currPos = s0;
                                                                        s0 = peg$FAILED;
                                                                      }
                                                                    } else {
                                                                      peg$currPos = s0;
                                                                      s0 = peg$FAILED;
                                                                    }
                                                                  } else {
                                                                    peg$currPos = s0;
                                                                    s0 = peg$FAILED;
                                                                  }
                                                                } else {
                                                                  peg$currPos = s0;
                                                                  s0 = peg$FAILED;
                                                                }
                                                              } else {
                                                                peg$currPos = s0;
                                                                s0 = peg$FAILED;
                                                              }
                                                            } else {
                                                              peg$currPos = s0;
                                                              s0 = peg$FAILED;
                                                            }
                                                          } else {
                                                            peg$currPos = s0;
                                                            s0 = peg$FAILED;
                                                          }
                                                        } else {
                                                          peg$currPos = s0;
                                                          s0 = peg$FAILED;
                                                        }
                                                      } else {
                                                        peg$currPos = s0;
                                                        s0 = peg$FAILED;
                                                      }
                                                    } else {
                                                      peg$currPos = s0;
                                                      s0 = peg$FAILED;
                                                    }
                                                  } else {
                                                    peg$currPos = s0;
                                                    s0 = peg$FAILED;
                                                  }
                                                  if (s0 === peg$FAILED) {
                                                    s0 = peg$currPos;
                                                    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c695) {
                                                      s1 = input.substr(peg$currPos, 4);
                                                      peg$currPos += 4;
                                                    } else {
                                                      s1 = peg$FAILED;
                                                      if (peg$silentFails === 0) { peg$fail(peg$c875); }
                                                    }
                                                    if (s1 !== peg$FAILED) {
                                                      s2 = peg$parse_();
                                                      if (s2 !== peg$FAILED) {
                                                        if (input.substr(peg$currPos, 12) === peg$c938) {
                                                          s3 = peg$c938;
                                                          peg$currPos += 12;
                                                        } else {
                                                          s3 = peg$FAILED;
                                                          if (peg$silentFails === 0) { peg$fail(peg$c939); }
                                                        }
                                                        if (s3 !== peg$FAILED) {
                                                          s4 = peg$parse_();
                                                          if (s4 !== peg$FAILED) {
                                                            s5 = peg$parseInteger();
                                                            if (s5 !== peg$FAILED) {
                                                              s6 = peg$parse_();
                                                              if (s6 !== peg$FAILED) {
                                                                if (input.substr(peg$currPos, 3) === peg$c530) {
                                                                  s7 = peg$c530;
                                                                  peg$currPos += 3;
                                                                } else {
                                                                  s7 = peg$FAILED;
                                                                  if (peg$silentFails === 0) { peg$fail(peg$c531); }
                                                                }
                                                                if (s7 !== peg$FAILED) {
                                                                  s8 = peg$parse_();
                                                                  if (s8 !== peg$FAILED) {
                                                                    s9 = peg$parseInteger();
                                                                    if (s9 !== peg$FAILED) {
                                                                      s10 = peg$parse_();
                                                                      if (s10 !== peg$FAILED) {
                                                                        if (input.substr(peg$currPos, 30) === peg$c940) {
                                                                          s11 = peg$c940;
                                                                          peg$currPos += 30;
                                                                        } else {
                                                                          s11 = peg$FAILED;
                                                                          if (peg$silentFails === 0) { peg$fail(peg$c941); }
                                                                        }
                                                                        if (s11 !== peg$FAILED) {
                                                                          peg$savedPos = s0;
                                                                          s1 = peg$c942(s5, s9);
                                                                          s0 = s1;
                                                                        } else {
                                                                          peg$currPos = s0;
                                                                          s0 = peg$FAILED;
                                                                        }
                                                                      } else {
                                                                        peg$currPos = s0;
                                                                        s0 = peg$FAILED;
                                                                      }
                                                                    } else {
                                                                      peg$currPos = s0;
                                                                      s0 = peg$FAILED;
                                                                    }
                                                                  } else {
                                                                    peg$currPos = s0;
                                                                    s0 = peg$FAILED;
                                                                  }
                                                                } else {
                                                                  peg$currPos = s0;
                                                                  s0 = peg$FAILED;
                                                                }
                                                              } else {
                                                                peg$currPos = s0;
                                                                s0 = peg$FAILED;
                                                              }
                                                            } else {
                                                              peg$currPos = s0;
                                                              s0 = peg$FAILED;
                                                            }
                                                          } else {
                                                            peg$currPos = s0;
                                                            s0 = peg$FAILED;
                                                          }
                                                        } else {
                                                          peg$currPos = s0;
                                                          s0 = peg$FAILED;
                                                        }
                                                      } else {
                                                        peg$currPos = s0;
                                                        s0 = peg$FAILED;
                                                      }
                                                    } else {
                                                      peg$currPos = s0;
                                                      s0 = peg$FAILED;
                                                    }
                                                    if (s0 === peg$FAILED) {
                                                      s0 = peg$currPos;
                                                      if (input.substr(peg$currPos, 4).toLowerCase() === peg$c695) {
                                                        s1 = input.substr(peg$currPos, 4);
                                                        peg$currPos += 4;
                                                      } else {
                                                        s1 = peg$FAILED;
                                                        if (peg$silentFails === 0) { peg$fail(peg$c875); }
                                                      }
                                                      if (s1 !== peg$FAILED) {
                                                        s2 = peg$parse_();
                                                        if (s2 !== peg$FAILED) {
                                                          if (input.substr(peg$currPos, 12) === peg$c938) {
                                                            s3 = peg$c938;
                                                            peg$currPos += 12;
                                                          } else {
                                                            s3 = peg$FAILED;
                                                            if (peg$silentFails === 0) { peg$fail(peg$c939); }
                                                          }
                                                          if (s3 !== peg$FAILED) {
                                                            s4 = peg$parse_();
                                                            if (s4 !== peg$FAILED) {
                                                              s5 = peg$parseInteger();
                                                              if (s5 !== peg$FAILED) {
                                                                s6 = peg$parse_();
                                                                if (s6 !== peg$FAILED) {
                                                                  if (input.substr(peg$currPos, 30) === peg$c940) {
                                                                    s7 = peg$c940;
                                                                    peg$currPos += 30;
                                                                  } else {
                                                                    s7 = peg$FAILED;
                                                                    if (peg$silentFails === 0) { peg$fail(peg$c941); }
                                                                  }
                                                                  if (s7 !== peg$FAILED) {
                                                                    peg$savedPos = s0;
                                                                    s1 = peg$c943(s5);
                                                                    s0 = s1;
                                                                  } else {
                                                                    peg$currPos = s0;
                                                                    s0 = peg$FAILED;
                                                                  }
                                                                } else {
                                                                  peg$currPos = s0;
                                                                  s0 = peg$FAILED;
                                                                }
                                                              } else {
                                                                peg$currPos = s0;
                                                                s0 = peg$FAILED;
                                                              }
                                                            } else {
                                                              peg$currPos = s0;
                                                              s0 = peg$FAILED;
                                                            }
                                                          } else {
                                                            peg$currPos = s0;
                                                            s0 = peg$FAILED;
                                                          }
                                                        } else {
                                                          peg$currPos = s0;
                                                          s0 = peg$FAILED;
                                                        }
                                                      } else {
                                                        peg$currPos = s0;
                                                        s0 = peg$FAILED;
                                                      }
                                                      if (s0 === peg$FAILED) {
                                                        s0 = peg$currPos;
                                                        if (input.substr(peg$currPos, 5).toLowerCase() === peg$c711) {
                                                          s1 = input.substr(peg$currPos, 5);
                                                          peg$currPos += 5;
                                                        } else {
                                                          s1 = peg$FAILED;
                                                          if (peg$silentFails === 0) { peg$fail(peg$c855); }
                                                        }
                                                        if (s1 !== peg$FAILED) {
                                                          s2 = peg$parse_();
                                                          if (s2 !== peg$FAILED) {
                                                            if (input.substr(peg$currPos, 7) === peg$c944) {
                                                              s3 = peg$c944;
                                                              peg$currPos += 7;
                                                            } else {
                                                              s3 = peg$FAILED;
                                                              if (peg$silentFails === 0) { peg$fail(peg$c945); }
                                                            }
                                                            if (s3 !== peg$FAILED) {
                                                              s4 = peg$parse_();
                                                              if (s4 !== peg$FAILED) {
                                                                if (input.substr(peg$currPos, 4) === peg$c781) {
                                                                  s5 = peg$c781;
                                                                  peg$currPos += 4;
                                                                } else {
                                                                  s5 = peg$FAILED;
                                                                  if (peg$silentFails === 0) { peg$fail(peg$c782); }
                                                                }
                                                                if (s5 === peg$FAILED) {
                                                                  if (input.substr(peg$currPos, 5) === peg$c552) {
                                                                    s5 = peg$c552;
                                                                    peg$currPos += 5;
                                                                  } else {
                                                                    s5 = peg$FAILED;
                                                                    if (peg$silentFails === 0) { peg$fail(peg$c553); }
                                                                  }
                                                                }
                                                                if (s5 !== peg$FAILED) {
                                                                  s6 = peg$parse_();
                                                                  if (s6 !== peg$FAILED) {
                                                                    s7 = peg$parseTriggerCount();
                                                                    if (s7 !== peg$FAILED) {
                                                                      s8 = peg$parse_();
                                                                      if (s8 !== peg$FAILED) {
                                                                        if (input.substr(peg$currPos, 8) === peg$c29) {
                                                                          s9 = peg$c29;
                                                                          peg$currPos += 8;
                                                                        } else {
                                                                          s9 = peg$FAILED;
                                                                          if (peg$silentFails === 0) { peg$fail(peg$c30); }
                                                                        }
                                                                        if (s9 === peg$FAILED) {
                                                                          s9 = null;
                                                                        }
                                                                        if (s9 !== peg$FAILED) {
                                                                          s10 = peg$parse_();
                                                                          if (s10 !== peg$FAILED) {
                                                                            s11 = peg$parseElementListOrOptions();
                                                                            if (s11 === peg$FAILED) {
                                                                              s11 = null;
                                                                            }
                                                                            if (s11 !== peg$FAILED) {
                                                                              s12 = peg$parse_();
                                                                              if (s12 !== peg$FAILED) {
                                                                                s13 = peg$parseSchoolAndOrList();
                                                                                if (s13 === peg$FAILED) {
                                                                                  s13 = null;
                                                                                }
                                                                                if (s13 !== peg$FAILED) {
                                                                                  s14 = peg$parse_();
                                                                                  if (s14 !== peg$FAILED) {
                                                                                    if (input.substr(peg$currPos, 4) === peg$c31) {
                                                                                      s15 = peg$c31;
                                                                                      peg$currPos += 4;
                                                                                    } else {
                                                                                      s15 = peg$FAILED;
                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c32); }
                                                                                    }
                                                                                    if (s15 === peg$FAILED) {
                                                                                      s15 = null;
                                                                                    }
                                                                                    if (s15 !== peg$FAILED) {
                                                                                      s16 = peg$parse_();
                                                                                      if (s16 !== peg$FAILED) {
                                                                                        s17 = peg$parseAbilityOrAttack();
                                                                                        if (s17 !== peg$FAILED) {
                                                                                          s18 = peg$parse_();
                                                                                          if (s18 !== peg$FAILED) {
                                                                                            if (input.substr(peg$currPos, 42) === peg$c946) {
                                                                                              s19 = peg$c946;
                                                                                              peg$currPos += 42;
                                                                                            } else {
                                                                                              s19 = peg$FAILED;
                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c947); }
                                                                                            }
                                                                                            if (s19 === peg$FAILED) {
                                                                                              s19 = null;
                                                                                            }
                                                                                            if (s19 !== peg$FAILED) {
                                                                                              peg$savedPos = s0;
                                                                                              s1 = peg$c948(s7, s9, s11, s13, s15, s17, s19);
                                                                                              s0 = s1;
                                                                                            } else {
                                                                                              peg$currPos = s0;
                                                                                              s0 = peg$FAILED;
                                                                                            }
                                                                                          } else {
                                                                                            peg$currPos = s0;
                                                                                            s0 = peg$FAILED;
                                                                                          }
                                                                                        } else {
                                                                                          peg$currPos = s0;
                                                                                          s0 = peg$FAILED;
                                                                                        }
                                                                                      } else {
                                                                                        peg$currPos = s0;
                                                                                        s0 = peg$FAILED;
                                                                                      }
                                                                                    } else {
                                                                                      peg$currPos = s0;
                                                                                      s0 = peg$FAILED;
                                                                                    }
                                                                                  } else {
                                                                                    peg$currPos = s0;
                                                                                    s0 = peg$FAILED;
                                                                                  }
                                                                                } else {
                                                                                  peg$currPos = s0;
                                                                                  s0 = peg$FAILED;
                                                                                }
                                                                              } else {
                                                                                peg$currPos = s0;
                                                                                s0 = peg$FAILED;
                                                                              }
                                                                            } else {
                                                                              peg$currPos = s0;
                                                                              s0 = peg$FAILED;
                                                                            }
                                                                          } else {
                                                                            peg$currPos = s0;
                                                                            s0 = peg$FAILED;
                                                                          }
                                                                        } else {
                                                                          peg$currPos = s0;
                                                                          s0 = peg$FAILED;
                                                                        }
                                                                      } else {
                                                                        peg$currPos = s0;
                                                                        s0 = peg$FAILED;
                                                                      }
                                                                    } else {
                                                                      peg$currPos = s0;
                                                                      s0 = peg$FAILED;
                                                                    }
                                                                  } else {
                                                                    peg$currPos = s0;
                                                                    s0 = peg$FAILED;
                                                                  }
                                                                } else {
                                                                  peg$currPos = s0;
                                                                  s0 = peg$FAILED;
                                                                }
                                                              } else {
                                                                peg$currPos = s0;
                                                                s0 = peg$FAILED;
                                                              }
                                                            } else {
                                                              peg$currPos = s0;
                                                              s0 = peg$FAILED;
                                                            }
                                                          } else {
                                                            peg$currPos = s0;
                                                            s0 = peg$FAILED;
                                                          }
                                                        } else {
                                                          peg$currPos = s0;
                                                          s0 = peg$FAILED;
                                                        }
                                                        if (s0 === peg$FAILED) {
                                                          s0 = peg$currPos;
                                                          if (input.substr(peg$currPos, 27) === peg$c949) {
                                                            s1 = peg$c949;
                                                            peg$currPos += 27;
                                                          } else {
                                                            s1 = peg$FAILED;
                                                            if (peg$silentFails === 0) { peg$fail(peg$c950); }
                                                          }
                                                          if (s1 !== peg$FAILED) {
                                                            s2 = peg$parse_();
                                                            if (s2 !== peg$FAILED) {
                                                              s3 = peg$parseSchool();
                                                              if (s3 !== peg$FAILED) {
                                                                s4 = peg$parse_();
                                                                if (s4 !== peg$FAILED) {
                                                                  if (input.substr(peg$currPos, 35) === peg$c951) {
                                                                    s5 = peg$c951;
                                                                    peg$currPos += 35;
                                                                  } else {
                                                                    s5 = peg$FAILED;
                                                                    if (peg$silentFails === 0) { peg$fail(peg$c952); }
                                                                  }
                                                                  if (s5 !== peg$FAILED) {
                                                                    peg$savedPos = s0;
                                                                    s1 = peg$c953(s3);
                                                                    s0 = s1;
                                                                  } else {
                                                                    peg$currPos = s0;
                                                                    s0 = peg$FAILED;
                                                                  }
                                                                } else {
                                                                  peg$currPos = s0;
                                                                  s0 = peg$FAILED;
                                                                }
                                                              } else {
                                                                peg$currPos = s0;
                                                                s0 = peg$FAILED;
                                                              }
                                                            } else {
                                                              peg$currPos = s0;
                                                              s0 = peg$FAILED;
                                                            }
                                                          } else {
                                                            peg$currPos = s0;
                                                            s0 = peg$FAILED;
                                                          }
                                                          if (s0 === peg$FAILED) {
                                                            s0 = peg$currPos;
                                                            if (input.substr(peg$currPos, 33) === peg$c954) {
                                                              s1 = peg$c954;
                                                              peg$currPos += 33;
                                                            } else {
                                                              s1 = peg$FAILED;
                                                              if (peg$silentFails === 0) { peg$fail(peg$c955); }
                                                            }
                                                            if (s1 !== peg$FAILED) {
                                                              peg$savedPos = s0;
                                                              s1 = peg$c956();
                                                            }
                                                            s0 = s1;
                                                            if (s0 === peg$FAILED) {
                                                              s0 = peg$currPos;
                                                              if (input.substr(peg$currPos, 11) === peg$c921) {
                                                                s1 = peg$c921;
                                                                peg$currPos += 11;
                                                              } else {
                                                                s1 = peg$FAILED;
                                                                if (peg$silentFails === 0) { peg$fail(peg$c922); }
                                                              }
                                                              if (s1 !== peg$FAILED) {
                                                                s2 = peg$parse_();
                                                                if (s2 !== peg$FAILED) {
                                                                  s3 = peg$parseTriggerCount();
                                                                  if (s3 !== peg$FAILED) {
                                                                    s4 = peg$parse_();
                                                                    if (s4 !== peg$FAILED) {
                                                                      if (input.substr(peg$currPos, 18) === peg$c957) {
                                                                        s5 = peg$c957;
                                                                        peg$currPos += 18;
                                                                      } else {
                                                                        s5 = peg$FAILED;
                                                                        if (peg$silentFails === 0) { peg$fail(peg$c958); }
                                                                      }
                                                                      if (s5 === peg$FAILED) {
                                                                        if (input.substr(peg$currPos, 19) === peg$c350) {
                                                                          s5 = peg$c350;
                                                                          peg$currPos += 19;
                                                                        } else {
                                                                          s5 = peg$FAILED;
                                                                          if (peg$silentFails === 0) { peg$fail(peg$c351); }
                                                                        }
                                                                      }
                                                                      if (s5 !== peg$FAILED) {
                                                                        s6 = peg$parse_();
                                                                        if (s6 !== peg$FAILED) {
                                                                          s7 = peg$parseElementList();
                                                                          if (s7 !== peg$FAILED) {
                                                                            s8 = peg$parse_();
                                                                            if (s8 !== peg$FAILED) {
                                                                              if (input.substr(peg$currPos, 6) === peg$c39) {
                                                                                s9 = peg$c39;
                                                                                peg$currPos += 6;
                                                                              } else {
                                                                                s9 = peg$FAILED;
                                                                                if (peg$silentFails === 0) { peg$fail(peg$c40); }
                                                                              }
                                                                              if (s9 !== peg$FAILED) {
                                                                                peg$savedPos = s0;
                                                                                s1 = peg$c959(s3, s7);
                                                                                s0 = s1;
                                                                              } else {
                                                                                peg$currPos = s0;
                                                                                s0 = peg$FAILED;
                                                                              }
                                                                            } else {
                                                                              peg$currPos = s0;
                                                                              s0 = peg$FAILED;
                                                                            }
                                                                          } else {
                                                                            peg$currPos = s0;
                                                                            s0 = peg$FAILED;
                                                                          }
                                                                        } else {
                                                                          peg$currPos = s0;
                                                                          s0 = peg$FAILED;
                                                                        }
                                                                      } else {
                                                                        peg$currPos = s0;
                                                                        s0 = peg$FAILED;
                                                                      }
                                                                    } else {
                                                                      peg$currPos = s0;
                                                                      s0 = peg$FAILED;
                                                                    }
                                                                  } else {
                                                                    peg$currPos = s0;
                                                                    s0 = peg$FAILED;
                                                                  }
                                                                } else {
                                                                  peg$currPos = s0;
                                                                  s0 = peg$FAILED;
                                                                }
                                                              } else {
                                                                peg$currPos = s0;
                                                                s0 = peg$FAILED;
                                                              }
                                                              if (s0 === peg$FAILED) {
                                                                s0 = peg$currPos;
                                                                if (input.substr(peg$currPos, 11) === peg$c921) {
                                                                  s1 = peg$c921;
                                                                  peg$currPos += 11;
                                                                } else {
                                                                  s1 = peg$FAILED;
                                                                  if (peg$silentFails === 0) { peg$fail(peg$c922); }
                                                                }
                                                                if (s1 !== peg$FAILED) {
                                                                  s2 = peg$parse_();
                                                                  if (s2 !== peg$FAILED) {
                                                                    s3 = peg$parseTriggerCount();
                                                                    if (s3 !== peg$FAILED) {
                                                                      s4 = peg$parse_();
                                                                      if (s4 !== peg$FAILED) {
                                                                        s5 = peg$parseSchool();
                                                                        if (s5 !== peg$FAILED) {
                                                                          s6 = peg$parse_();
                                                                          if (s6 !== peg$FAILED) {
                                                                            if (input.substr(peg$currPos, 9) === peg$c223) {
                                                                              s7 = peg$c223;
                                                                              peg$currPos += 9;
                                                                            } else {
                                                                              s7 = peg$FAILED;
                                                                              if (peg$silentFails === 0) { peg$fail(peg$c224); }
                                                                            }
                                                                            if (s7 !== peg$FAILED) {
                                                                              s8 = peg$parse_();
                                                                              if (s8 !== peg$FAILED) {
                                                                                s9 = peg$parseOnceOnly();
                                                                                if (s9 === peg$FAILED) {
                                                                                  s9 = null;
                                                                                }
                                                                                if (s9 !== peg$FAILED) {
                                                                                  peg$savedPos = s0;
                                                                                  s1 = peg$c960(s3, s5, s9);
                                                                                  s0 = s1;
                                                                                } else {
                                                                                  peg$currPos = s0;
                                                                                  s0 = peg$FAILED;
                                                                                }
                                                                              } else {
                                                                                peg$currPos = s0;
                                                                                s0 = peg$FAILED;
                                                                              }
                                                                            } else {
                                                                              peg$currPos = s0;
                                                                              s0 = peg$FAILED;
                                                                            }
                                                                          } else {
                                                                            peg$currPos = s0;
                                                                            s0 = peg$FAILED;
                                                                          }
                                                                        } else {
                                                                          peg$currPos = s0;
                                                                          s0 = peg$FAILED;
                                                                        }
                                                                      } else {
                                                                        peg$currPos = s0;
                                                                        s0 = peg$FAILED;
                                                                      }
                                                                    } else {
                                                                      peg$currPos = s0;
                                                                      s0 = peg$FAILED;
                                                                    }
                                                                  } else {
                                                                    peg$currPos = s0;
                                                                    s0 = peg$FAILED;
                                                                  }
                                                                } else {
                                                                  peg$currPos = s0;
                                                                  s0 = peg$FAILED;
                                                                }
                                                                if (s0 === peg$FAILED) {
                                                                  s0 = peg$currPos;
                                                                  if (input.substr(peg$currPos, 13) === peg$c718) {
                                                                    s1 = peg$c718;
                                                                    peg$currPos += 13;
                                                                  } else {
                                                                    s1 = peg$FAILED;
                                                                    if (peg$silentFails === 0) { peg$fail(peg$c719); }
                                                                  }
                                                                  if (s1 !== peg$FAILED) {
                                                                    s2 = peg$parse_();
                                                                    if (s2 !== peg$FAILED) {
                                                                      s3 = peg$parseSkill1Or2();
                                                                      if (s3 === peg$FAILED) {
                                                                        s3 = peg$parseAnySkillName();
                                                                      }
                                                                      if (s3 !== peg$FAILED) {
                                                                        peg$savedPos = s0;
                                                                        s1 = peg$c503(s3);
                                                                        s0 = s1;
                                                                      } else {
                                                                        peg$currPos = s0;
                                                                        s0 = peg$FAILED;
                                                                      }
                                                                    } else {
                                                                      peg$currPos = s0;
                                                                      s0 = peg$FAILED;
                                                                    }
                                                                  } else {
                                                                    peg$currPos = s0;
                                                                    s0 = peg$FAILED;
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAbilityOrAttack() {
    var s0, s1, s2;

    var key    = peg$currPos * 255 + 155,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7) === peg$c256) {
      s1 = peg$c256;
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c257); }
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 9) === peg$c223) {
        s1 = peg$c223;
        peg$currPos += 9;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c224); }
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c961();
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 6) === peg$c162) {
        s1 = peg$c162;
        peg$currPos += 6;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c163); }
      }
      if (s1 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 115) {
          s2 = peg$c164;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c165); }
        }
        if (s2 === peg$FAILED) {
          s2 = null;
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c962();
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseTriggerCount() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 156,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseUseCount();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c963(s1);
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseInteger();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        if (s2 !== peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c964) {
            s3 = peg$c964;
            peg$currPos += 2;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c965); }
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            if (s4 !== peg$FAILED) {
              s5 = peg$parseInteger();
              if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c966(s1, s5);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseArticleOrNumberString();
        if (s1 === peg$FAILED) {
          s1 = peg$parseInteger();
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$currPos;
          peg$silentFails++;
          if (input.charCodeAt(peg$currPos) === 47) {
            s3 = peg$c967;
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c968); }
          }
          peg$silentFails--;
          if (s3 === peg$FAILED) {
            s2 = void 0;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
          if (s2 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c969(s1);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parseIntegerSlashList();
          if (s1 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 43) {
              s2 = peg$c19;
              peg$currPos++;
            } else {
              s2 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c20); }
            }
            if (s2 === peg$FAILED) {
              s2 = null;
            }
            if (s2 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c970(s1, s2);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = peg$c5;
            if (s1 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c971();
            }
            s0 = s1;
          }
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseTriggerOrWhenSet() {
    var s0, s1;

    var key    = peg$currPos * 255 + 157,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$parseTrigger();
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 8) === peg$c472) {
        s1 = peg$c472;
        peg$currPos += 8;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c473); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c502();
      }
      s0 = s1;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseTriggerDetail() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 158,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 11) === peg$c972) {
      s1 = peg$c972;
      peg$currPos += 11;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c973); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseGenericName();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c974(s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 28) === peg$c975) {
        s1 = peg$c975;
        peg$currPos += 28;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c976); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseElementSlashList();
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c139(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 28) === peg$c975) {
          s1 = peg$c975;
          peg$currPos += 28;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c976); }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          if (s2 !== peg$FAILED) {
            s3 = peg$parseSkill1Or2();
            if (s3 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c977(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.substr(peg$currPos, 10) === peg$c978) {
            s1 = peg$c978;
            peg$currPos += 10;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c979); }
          }
          if (s1 === peg$FAILED) {
            if (input.substr(peg$currPos, 31) === peg$c980) {
              s1 = peg$c980;
              peg$currPos += 31;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c981); }
            }
          }
          if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            if (s2 !== peg$FAILED) {
              s3 = peg$parseElementSlashList();
              if (s3 !== peg$FAILED) {
                s4 = peg$parse_();
                if (s4 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 6) === peg$c39) {
                    s5 = peg$c39;
                    peg$currPos += 6;
                  } else {
                    s5 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c40); }
                  }
                  if (s5 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c139(s3);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            if (input.substr(peg$currPos, 26) === peg$c982) {
              s1 = peg$c982;
              peg$currPos += 26;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c983); }
            }
            if (s1 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c812();
            }
            s0 = s1;
          }
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSkill1Or2() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 159,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseAnySkillName();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 6) === peg$c984) {
          s3 = peg$c984;
          peg$currPos += 6;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c985); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseAnySkillName();
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c986(s1, s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseAnySkillName();
      if (s1 !== peg$FAILED) {
        peg$savedPos = peg$currPos;
        s2 = peg$c987(s1);
        if (s2) {
          s2 = void 0;
        } else {
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c988(s1);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSimpleSkill() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13;

    var key    = peg$currPos * 255 + 160,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseSkillTypeSlashList();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 58) {
        s2 = peg$c540;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c541); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 6) === peg$c989) {
            s4 = peg$c989;
            peg$currPos += 6;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c990); }
          }
          if (s4 === peg$FAILED) {
            if (input.substr(peg$currPos, 5) === peg$c991) {
              s4 = peg$c991;
              peg$currPos += 5;
            } else {
              s4 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c992); }
            }
            if (s4 === peg$FAILED) {
              if (input.substr(peg$currPos, 6) === peg$c993) {
                s4 = peg$c993;
                peg$currPos += 6;
              } else {
                s4 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c994); }
              }
            }
          }
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 44) {
              s5 = peg$c0;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c1); }
            }
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = peg$currPos;
              s6 = peg$c995(s1, s4);
              if (s6) {
                s6 = void 0;
              } else {
                s6 = peg$FAILED;
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseSimpleSkillEffect();
                  if (s8 !== peg$FAILED) {
                    s9 = [];
                    s10 = peg$currPos;
                    if (input.charCodeAt(peg$currPos) === 44) {
                      s11 = peg$c0;
                      peg$currPos++;
                    } else {
                      s11 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c1); }
                    }
                    if (s11 !== peg$FAILED) {
                      s12 = peg$parse_();
                      if (s12 !== peg$FAILED) {
                        s13 = peg$parseSimpleSkillEffect();
                        if (s13 !== peg$FAILED) {
                          s11 = [s11, s12, s13];
                          s10 = s11;
                        } else {
                          peg$currPos = s10;
                          s10 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s10;
                        s10 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s10;
                      s10 = peg$FAILED;
                    }
                    while (s10 !== peg$FAILED) {
                      s9.push(s10);
                      s10 = peg$currPos;
                      if (input.charCodeAt(peg$currPos) === 44) {
                        s11 = peg$c0;
                        peg$currPos++;
                      } else {
                        s11 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c1); }
                      }
                      if (s11 !== peg$FAILED) {
                        s12 = peg$parse_();
                        if (s12 !== peg$FAILED) {
                          s13 = peg$parseSimpleSkillEffect();
                          if (s13 !== peg$FAILED) {
                            s11 = [s11, s12, s13];
                            s10 = s11;
                          } else {
                            peg$currPos = s10;
                            s10 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s10;
                          s10 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s10;
                        s10 = peg$FAILED;
                      }
                    }
                    if (s9 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c996(s1, s4, s8, s9);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSimpleSkillEffect() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14;

    var key    = peg$currPos * 255 + 161,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$currPos;
    s2 = peg$parse_();
    if (s2 !== peg$FAILED) {
      if (input.substr(peg$currPos, 6) === peg$c997) {
        s3 = peg$c997;
        peg$currPos += 6;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c998); }
      }
      if (s3 !== peg$FAILED) {
        s2 = [s2, s3];
        s1 = s2;
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      s1 = null;
    }
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 44) {
        s2 = peg$c0;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1); }
      }
      if (s2 === peg$FAILED) {
        s2 = null;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          s4 = peg$currPos;
          s5 = peg$parseInteger();
          if (s5 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 120) {
              s6 = peg$c999;
              peg$currPos++;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c1000); }
            }
            if (s6 !== peg$FAILED) {
              peg$savedPos = s4;
              s5 = peg$c1001(s1, s5);
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 === peg$FAILED) {
            s4 = null;
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseDecimalNumber();
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  s8 = peg$currPos;
                  if (input.substr(peg$currPos, 2) === peg$c964) {
                    s9 = peg$c964;
                    peg$currPos += 2;
                  } else {
                    s9 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c965); }
                  }
                  if (s9 !== peg$FAILED) {
                    s10 = peg$parse_();
                    if (s10 !== peg$FAILED) {
                      s11 = peg$parseDecimalNumber();
                      if (s11 !== peg$FAILED) {
                        peg$savedPos = s8;
                        s9 = peg$c1002(s1, s4, s6, s11);
                        s8 = s9;
                      } else {
                        peg$currPos = s8;
                        s8 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s8;
                      s8 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s8;
                    s8 = peg$FAILED;
                  }
                  if (s8 === peg$FAILED) {
                    s8 = null;
                  }
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parse_();
                    if (s9 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 6) === peg$c647) {
                        s10 = peg$c647;
                        peg$currPos += 6;
                      } else {
                        s10 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c648); }
                      }
                      if (s10 === peg$FAILED) {
                        s10 = null;
                      }
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parse_();
                        if (s11 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 8) === peg$c364) {
                            s12 = peg$c364;
                            peg$currPos += 8;
                          } else {
                            s12 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c506); }
                          }
                          if (s12 === peg$FAILED) {
                            if (input.substr(peg$currPos, 7) === peg$c367) {
                              s12 = peg$c367;
                              peg$currPos += 7;
                            } else {
                              s12 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c507); }
                            }
                          }
                          if (s12 === peg$FAILED) {
                            s12 = null;
                          }
                          if (s12 !== peg$FAILED) {
                            s13 = peg$parse_();
                            if (s13 !== peg$FAILED) {
                              s14 = peg$parseElementSlashList();
                              if (s14 === peg$FAILED) {
                                s14 = null;
                              }
                              if (s14 !== peg$FAILED) {
                                peg$savedPos = s0;
                                s1 = peg$c1003(s1, s4, s6, s8, s10, s14);
                                s0 = s1;
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 13) === peg$c1004) {
        s1 = peg$c1004;
        peg$currPos += 13;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1005); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseInteger();
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            if (s4 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 41) {
                s5 = peg$c508;
                peg$currPos++;
              } else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c509); }
              }
              if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c1006(s3);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 8) === peg$c475) {
          s1 = peg$c475;
          peg$currPos += 8;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1007); }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          if (s2 !== peg$FAILED) {
            s3 = peg$parseInteger();
            if (s3 !== peg$FAILED) {
              s4 = peg$parse_();
              if (s4 !== peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c394) {
                  s5 = peg$c394;
                  peg$currPos += 2;
                } else {
                  s5 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c395); }
                }
                if (s5 !== peg$FAILED) {
                  s6 = peg$parse_();
                  if (s6 !== peg$FAILED) {
                    s7 = peg$parseWho();
                    if (s7 === peg$FAILED) {
                      s7 = null;
                    }
                    if (s7 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c1008(s3, s7);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.substr(peg$currPos, 15) === peg$c1009) {
            s1 = peg$c1009;
            peg$currPos += 15;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1010); }
          }
          if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            if (s2 !== peg$FAILED) {
              s3 = peg$parseInteger();
              if (s3 !== peg$FAILED) {
                s4 = peg$parse_();
                if (s4 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 28) === peg$c1011) {
                    s5 = peg$c1011;
                    peg$currPos += 28;
                  } else {
                    s5 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c1012); }
                  }
                  if (s5 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c1013(s3);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = peg$parseStat();
            if (s1 !== peg$FAILED) {
              s2 = peg$parse_();
              if (s2 !== peg$FAILED) {
                s3 = [];
                s4 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 47) {
                  s5 = peg$c967;
                  peg$currPos++;
                } else {
                  s5 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c968); }
                }
                if (s5 !== peg$FAILED) {
                  s6 = peg$parseStat();
                  if (s6 !== peg$FAILED) {
                    s5 = [s5, s6];
                    s4 = s5;
                  } else {
                    peg$currPos = s4;
                    s4 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s4;
                  s4 = peg$FAILED;
                }
                while (s4 !== peg$FAILED) {
                  s3.push(s4);
                  s4 = peg$currPos;
                  if (input.charCodeAt(peg$currPos) === 47) {
                    s5 = peg$c967;
                    peg$currPos++;
                  } else {
                    s5 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c968); }
                  }
                  if (s5 !== peg$FAILED) {
                    s6 = peg$parseStat();
                    if (s6 !== peg$FAILED) {
                      s5 = [s5, s6];
                      s4 = s5;
                    } else {
                      peg$currPos = s4;
                      s4 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s4;
                    s4 = peg$FAILED;
                  }
                }
                if (s3 !== peg$FAILED) {
                  s4 = peg$parse_();
                  if (s4 !== peg$FAILED) {
                    s5 = peg$parseSignedIntegerOrX();
                    if (s5 !== peg$FAILED) {
                      if (input.charCodeAt(peg$currPos) === 37) {
                        s6 = peg$c12;
                        peg$currPos++;
                      } else {
                        s6 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c13); }
                      }
                      if (s6 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c1014(s1, s3, s5);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              if (input.substr(peg$currPos, 15) === peg$c1015) {
                s1 = peg$c1015;
                peg$currPos += 15;
              } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c1016); }
              }
              if (s1 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c1017();
              }
              s0 = s1;
              if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parseStatusVerb();
                if (s1 !== peg$FAILED) {
                  s2 = peg$parse_();
                  if (s2 !== peg$FAILED) {
                    s3 = peg$parseStatusList();
                    if (s3 !== peg$FAILED) {
                      s4 = [];
                      s5 = peg$parseStatusClause();
                      while (s5 !== peg$FAILED) {
                        s4.push(s5);
                        s5 = peg$parseStatusClause();
                      }
                      if (s4 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c1018(s1, s3, s4);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                  s0 = peg$currPos;
                  s1 = peg$parseSmartEtherStatus();
                  if (s1 !== peg$FAILED) {
                    s2 = peg$parse_();
                    if (s2 !== peg$FAILED) {
                      s3 = peg$parseWho();
                      if (s3 === peg$FAILED) {
                        s3 = null;
                      }
                      if (s3 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c1019(s1, s3);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                  if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    s1 = peg$currPos;
                    if (input.substr(peg$currPos, 5) === peg$c397) {
                      s2 = peg$c397;
                      peg$currPos += 5;
                    } else {
                      s2 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c1020); }
                    }
                    if (s2 !== peg$FAILED) {
                      s3 = peg$parse_();
                      if (s3 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 2) === peg$c1021) {
                          s4 = peg$c1021;
                          peg$currPos += 2;
                        } else {
                          s4 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c1022); }
                        }
                        if (s4 === peg$FAILED) {
                          s4 = null;
                        }
                        if (s4 !== peg$FAILED) {
                          s2 = [s2, s3, s4];
                          s1 = s2;
                        } else {
                          peg$currPos = s1;
                          s1 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s1;
                        s1 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s1;
                      s1 = peg$FAILED;
                    }
                    if (s1 === peg$FAILED) {
                      if (input.substr(peg$currPos, 14) === peg$c1023) {
                        s1 = peg$c1023;
                        peg$currPos += 14;
                      } else {
                        s1 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c1024); }
                      }
                    }
                    if (s1 !== peg$FAILED) {
                      s2 = peg$parse_();
                      if (s2 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 12) === peg$c1025) {
                          s3 = peg$c1025;
                          peg$currPos += 12;
                        } else {
                          s3 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c1026); }
                        }
                        if (s3 !== peg$FAILED) {
                          s4 = peg$parse_();
                          if (s4 !== peg$FAILED) {
                            s5 = peg$parseInteger();
                            if (s5 !== peg$FAILED) {
                              if (input.substr(peg$currPos, 21) === peg$c1027) {
                                s6 = peg$c1027;
                                peg$currPos += 21;
                              } else {
                                s6 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c1028); }
                              }
                              if (s6 !== peg$FAILED) {
                                peg$savedPos = s0;
                                s1 = peg$c1029(s5);
                                s0 = s1;
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatusClause() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 255 + 162,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parse_();
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      s3 = peg$parseDuration();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s2;
        s3 = peg$c1030(s3);
      }
      s2 = s3;
      if (s2 === peg$FAILED) {
        s2 = peg$currPos;
        s3 = peg$parseWho();
        if (s3 !== peg$FAILED) {
          s4 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 44) {
            s5 = peg$c0;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1); }
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            if (s6 !== peg$FAILED) {
              s5 = [s5, s6];
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 === peg$FAILED) {
            s4 = peg$currPos;
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 3) === peg$c530) {
                s6 = peg$c530;
                peg$currPos += 3;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c531); }
              }
              if (s6 !== peg$FAILED) {
                s5 = [s5, s6];
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseCharacterNameAndList();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 18) === peg$c1031) {
                  s7 = peg$c1031;
                  peg$currPos += 18;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c1032); }
                }
                if (s7 === peg$FAILED) {
                  s7 = null;
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s2;
                  s3 = peg$c1033(s3, s6);
                  s2 = s3;
                } else {
                  peg$currPos = s2;
                  s2 = peg$FAILED;
                }
              } else {
                peg$currPos = s2;
                s2 = peg$FAILED;
              }
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 === peg$FAILED) {
          s2 = peg$currPos;
          s3 = peg$parseWho();
          if (s3 !== peg$FAILED) {
            peg$savedPos = s2;
            s3 = peg$c1034(s3);
          }
          s2 = s3;
          if (s2 === peg$FAILED) {
            s2 = peg$currPos;
            if (input.substr(peg$currPos, 2) === peg$c1021) {
              s3 = peg$c1021;
              peg$currPos += 2;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c1022); }
            }
            if (s3 !== peg$FAILED) {
              s4 = peg$parse_();
              if (s4 !== peg$FAILED) {
                s5 = peg$parseCharacterNameAndList();
                if (s5 !== peg$FAILED) {
                  peg$savedPos = s2;
                  s3 = peg$c1035(s5);
                  s2 = s3;
                } else {
                  peg$currPos = s2;
                  s2 = peg$FAILED;
                }
              } else {
                peg$currPos = s2;
                s2 = peg$FAILED;
              }
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
            if (s2 === peg$FAILED) {
              s2 = peg$currPos;
              if (input.substr(peg$currPos, 10) === peg$c1036) {
                s3 = peg$c1036;
                peg$currPos += 10;
              } else {
                s3 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c1037); }
              }
              if (s3 !== peg$FAILED) {
                peg$savedPos = s2;
                s3 = peg$c1038();
              }
              s2 = s3;
              if (s2 === peg$FAILED) {
                s2 = peg$currPos;
                s3 = peg$parseCondition();
                if (s3 !== peg$FAILED) {
                  peg$savedPos = s2;
                  s3 = peg$c1039(s3);
                }
                s2 = s3;
                if (s2 === peg$FAILED) {
                  s2 = peg$currPos;
                  s3 = peg$parseConditionDetail();
                  if (s3 !== peg$FAILED) {
                    peg$savedPos = s2;
                    s3 = peg$c494();
                  }
                  s2 = s3;
                }
              }
            }
          }
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1040(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatusList() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 163,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseStatusWithPercent();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parseStatusListConjunction();
      if (s4 !== peg$FAILED) {
        s5 = peg$parseStatusWithPercent();
        if (s5 !== peg$FAILED) {
          peg$savedPos = s3;
          s4 = peg$c1041(s1, s4, s5);
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parseStatusListConjunction();
        if (s4 !== peg$FAILED) {
          s5 = peg$parseStatusWithPercent();
          if (s5 !== peg$FAILED) {
            peg$savedPos = s3;
            s4 = peg$c1041(s1, s4, s5);
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1042(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatusWithPercent() {
    var s0, s1, s2, s3, s4, s5, s6;

    var key    = peg$currPos * 255 + 164,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseStatusItem();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 40) {
          s4 = peg$c1043;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1044); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseInteger();
          if (s5 !== peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c1045) {
              s6 = peg$c1045;
              peg$currPos += 2;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c1046); }
            }
            if (s6 !== peg$FAILED) {
              peg$savedPos = s3;
              s4 = peg$c1047(s1, s5);
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseDuration();
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c1048(s1, s3, s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseInteger();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        if (s2 !== peg$FAILED) {
          if (input.substr(peg$currPos, 9) === peg$c609) {
            s3 = peg$c609;
            peg$currPos += 9;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c610); }
          }
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c611(s1);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatusLevel() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 255 + 165,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseGenericName();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 5) === peg$c737) {
          s3 = peg$c737;
          peg$currPos += 5;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c738); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseInteger();
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c1050(s1, s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseGenericName();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        if (s2 !== peg$FAILED) {
          if (input.substr(peg$currPos, 5) === peg$c737) {
            s3 = peg$c737;
            peg$currPos += 5;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c738); }
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            if (s4 !== peg$FAILED) {
              s5 = peg$parseSignedInteger();
              if (s5 !== peg$FAILED) {
                s6 = peg$parse_();
                if (s6 !== peg$FAILED) {
                  s7 = peg$parseStatusLevelMax();
                  if (s7 === peg$FAILED) {
                    s7 = null;
                  }
                  if (s7 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c1051(s1, s5, s7);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseSignedInteger();
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          if (s2 !== peg$FAILED) {
            s3 = peg$parseGenericName();
            if (s3 !== peg$FAILED) {
              s4 = peg$parse_();
              if (s4 !== peg$FAILED) {
                s5 = peg$parseStatusLevelMax();
                if (s5 === peg$FAILED) {
                  s5 = null;
                }
                if (s5 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c1052(s1, s3, s5);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parseGenericName();
          if (s1 !== peg$FAILED) {
            peg$savedPos = peg$currPos;
            s2 = peg$c1053(s1);
            if (s2) {
              s2 = void 0;
            } else {
              s2 = peg$FAILED;
            }
            if (s2 !== peg$FAILED) {
              s3 = peg$parse_();
              if (s3 !== peg$FAILED) {
                s4 = peg$parseStatusLevelMax();
                if (s4 === peg$FAILED) {
                  s4 = null;
                }
                if (s4 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c1054(s1, s4);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = peg$parseGenericName();
            if (s1 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c1055(s1);
            }
            s0 = s1;
          }
        }
      }
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1049); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatusLevelMax() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 166,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4) === peg$c1056) {
      s1 = peg$c1056;
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1057); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseInteger();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 41) {
              s5 = peg$c508;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c509); }
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c219(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStandardStatus() {
    var s0, s1;

    var key    = peg$currPos * 255 + 167,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseStatusName();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c1058(s1);
    }
    s0 = s1;

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatusItem() {
    var s0;

    var key    = peg$currPos * 255 + 168,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$parseSmartEtherStatus();
    if (s0 === peg$FAILED) {
      s0 = peg$parseStatusLevel();
      if (s0 === peg$FAILED) {
        s0 = peg$parseStandardStatus();
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseCondition() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17, s18, s19;

    var key    = peg$currPos * 255 + 169,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4) === peg$c695) {
      s1 = peg$c695;
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c696); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 9) === peg$c1059) {
          s3 = peg$c1059;
          peg$currPos += 9;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1060); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 97) {
              s6 = peg$c1061;
              peg$currPos++;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c1062); }
            }
            if (s6 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 110) {
                s7 = peg$c254;
                peg$currPos++;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c255); }
              }
              if (s7 === peg$FAILED) {
                s7 = null;
              }
              if (s7 !== peg$FAILED) {
                peg$savedPos = s5;
                s6 = peg$c1063();
                s5 = s6;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = [];
                if (peg$c1064.test(input.charAt(peg$currPos))) {
                  s8 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s8 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c1065); }
                }
                if (s8 !== peg$FAILED) {
                  while (s8 !== peg$FAILED) {
                    s7.push(s8);
                    if (peg$c1064.test(input.charAt(peg$currPos))) {
                      s8 = input.charAt(peg$currPos);
                      peg$currPos++;
                    } else {
                      s8 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c1065); }
                    }
                  }
                } else {
                  s7 = peg$FAILED;
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c1066(s5, s7);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 12) === peg$c1067) {
        s1 = peg$c1067;
        peg$currPos += 12;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1068); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseGenericName();
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            if (s4 !== peg$FAILED) {
              if (input.substr(peg$currPos, 5) === peg$c737) {
                s5 = peg$c737;
                peg$currPos += 5;
              } else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c738); }
              }
              if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c1069(s3);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 2) === peg$c1070) {
          s1 = peg$c1070;
          peg$currPos += 2;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1071); }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          if (s2 !== peg$FAILED) {
            s3 = peg$parseGenericName();
            if (s3 !== peg$FAILED) {
              s4 = peg$parse_();
              if (s4 !== peg$FAILED) {
                if (input.substr(peg$currPos, 6) === peg$c1072) {
                  s5 = peg$c1072;
                  peg$currPos += 6;
                } else {
                  s5 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c1073); }
                }
                if (s5 !== peg$FAILED) {
                  s6 = peg$parse_();
                  if (s6 !== peg$FAILED) {
                    s7 = peg$parseIntegerAndList();
                    if (s7 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c1074(s3, s7);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.substr(peg$currPos, 2) === peg$c1070) {
            s1 = peg$c1070;
            peg$currPos += 2;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1071); }
          }
          if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            if (s2 !== peg$FAILED) {
              s3 = peg$parseGenericName();
              if (s3 !== peg$FAILED) {
                s4 = peg$parse_();
                if (s4 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 5) === peg$c737) {
                    s5 = peg$c737;
                    peg$currPos += 5;
                  } else {
                    s5 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c738); }
                  }
                  if (s5 !== peg$FAILED) {
                    s6 = peg$parse_();
                    if (s6 !== peg$FAILED) {
                      s7 = peg$parseIntegerSlashList();
                      if (s7 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c1074(s3, s7);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            if (input.substr(peg$currPos, 2) === peg$c693) {
              s1 = peg$c693;
              peg$currPos += 2;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c694); }
            }
            if (s1 !== peg$FAILED) {
              s2 = peg$parse_();
              if (s2 !== peg$FAILED) {
                if (input.substr(peg$currPos, 3) === peg$c576) {
                  s3 = peg$c576;
                  peg$currPos += 3;
                } else {
                  s3 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c577); }
                }
                if (s3 === peg$FAILED) {
                  s3 = null;
                }
                if (s3 !== peg$FAILED) {
                  s4 = peg$parse_();
                  if (s4 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 4) === peg$c578) {
                      s5 = peg$c578;
                      peg$currPos += 4;
                    } else {
                      s5 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c579); }
                    }
                    if (s5 !== peg$FAILED) {
                      s6 = peg$parse_();
                      if (s6 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 3) === peg$c1075) {
                          s7 = peg$c1075;
                          peg$currPos += 3;
                        } else {
                          s7 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c1076); }
                        }
                        if (s7 !== peg$FAILED) {
                          s8 = peg$parse_();
                          if (s8 !== peg$FAILED) {
                            s9 = peg$parseGenericName();
                            if (s9 !== peg$FAILED) {
                              s10 = peg$parse_();
                              if (s10 !== peg$FAILED) {
                                if (input.substr(peg$currPos, 5) === peg$c737) {
                                  s11 = peg$c737;
                                  peg$currPos += 5;
                                } else {
                                  s11 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c738); }
                                }
                                if (s11 !== peg$FAILED) {
                                  s12 = peg$parse_();
                                  if (s12 !== peg$FAILED) {
                                    s13 = peg$parseIntegerSlashList();
                                    if (s13 !== peg$FAILED) {
                                      if (input.charCodeAt(peg$currPos) === 43) {
                                        s14 = peg$c19;
                                        peg$currPos++;
                                      } else {
                                        s14 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c20); }
                                      }
                                      if (s14 === peg$FAILED) {
                                        s14 = null;
                                      }
                                      if (s14 !== peg$FAILED) {
                                        peg$savedPos = s0;
                                        s1 = peg$c1077(s9, s13, s14);
                                        s0 = s1;
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              if (input.substr(peg$currPos, 2) === peg$c693) {
                s1 = peg$c693;
                peg$currPos += 2;
              } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c694); }
              }
              if (s1 !== peg$FAILED) {
                s2 = peg$parse_();
                if (s2 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 3) === peg$c576) {
                    s3 = peg$c576;
                    peg$currPos += 3;
                  } else {
                    s3 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c577); }
                  }
                  if (s3 === peg$FAILED) {
                    s3 = null;
                  }
                  if (s3 !== peg$FAILED) {
                    s4 = peg$parse_();
                    if (s4 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 4) === peg$c578) {
                        s5 = peg$c578;
                        peg$currPos += 4;
                      } else {
                        s5 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c579); }
                      }
                      if (s5 !== peg$FAILED) {
                        s6 = peg$parse_();
                        if (s6 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 3) === peg$c1075) {
                            s7 = peg$c1075;
                            peg$currPos += 3;
                          } else {
                            s7 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c1076); }
                          }
                          if (s7 !== peg$FAILED) {
                            s8 = peg$parse_();
                            if (s8 !== peg$FAILED) {
                              s9 = peg$parseGenericName();
                              if (s9 !== peg$FAILED) {
                                s10 = peg$parse_();
                                if (s10 !== peg$FAILED) {
                                  if (input.charCodeAt(peg$currPos) === 61) {
                                    s11 = peg$c1078;
                                    peg$currPos++;
                                  } else {
                                    s11 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c1079); }
                                  }
                                  if (s11 !== peg$FAILED) {
                                    s12 = peg$parse_();
                                    if (s12 !== peg$FAILED) {
                                      s13 = peg$parseInteger();
                                      if (s13 !== peg$FAILED) {
                                        peg$savedPos = s0;
                                        s1 = peg$c1074(s9, s13);
                                        s0 = s1;
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
              if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (input.substr(peg$currPos, 2) === peg$c693) {
                  s1 = peg$c693;
                  peg$currPos += 2;
                } else {
                  s1 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c694); }
                }
                if (s1 !== peg$FAILED) {
                  s2 = peg$parse_();
                  if (s2 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 3) === peg$c576) {
                      s3 = peg$c576;
                      peg$currPos += 3;
                    } else {
                      s3 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c577); }
                    }
                    if (s3 === peg$FAILED) {
                      s3 = null;
                    }
                    if (s3 !== peg$FAILED) {
                      s4 = peg$parse_();
                      if (s4 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 4) === peg$c578) {
                          s5 = peg$c578;
                          peg$currPos += 4;
                        } else {
                          s5 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c579); }
                        }
                        if (s5 !== peg$FAILED) {
                          s6 = peg$parse_();
                          if (s6 !== peg$FAILED) {
                            if (input.substr(peg$currPos, 3) === peg$c1075) {
                              s7 = peg$c1075;
                              peg$currPos += 3;
                            } else {
                              s7 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c1076); }
                            }
                            if (s7 !== peg$FAILED) {
                              s8 = peg$parse_();
                              if (s8 !== peg$FAILED) {
                                s9 = peg$parseGenericName();
                                if (s9 !== peg$FAILED) {
                                  s10 = peg$parse_();
                                  if (s10 !== peg$FAILED) {
                                    if (input.substr(peg$currPos, 5) === peg$c737) {
                                      s11 = peg$c737;
                                      peg$currPos += 5;
                                    } else {
                                      s11 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c738); }
                                    }
                                    if (s11 === peg$FAILED) {
                                      s11 = null;
                                    }
                                    if (s11 !== peg$FAILED) {
                                      s12 = peg$parse_();
                                      if (s12 !== peg$FAILED) {
                                        if (input.charCodeAt(peg$currPos) === 62) {
                                          s13 = peg$c1080;
                                          peg$currPos++;
                                        } else {
                                          s13 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c1081); }
                                        }
                                        if (s13 !== peg$FAILED) {
                                          s14 = peg$parse_();
                                          if (s14 !== peg$FAILED) {
                                            s15 = peg$parseInteger();
                                            if (s15 !== peg$FAILED) {
                                              peg$savedPos = s0;
                                              s1 = peg$c1082(s9, s15);
                                              s0 = s1;
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s0;
                                          s0 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                  s0 = peg$currPos;
                  if (input.substr(peg$currPos, 2) === peg$c693) {
                    s1 = peg$c693;
                    peg$currPos += 2;
                  } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c694); }
                  }
                  if (s1 !== peg$FAILED) {
                    s2 = peg$parse_();
                    if (s2 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 3) === peg$c576) {
                        s3 = peg$c576;
                        peg$currPos += 3;
                      } else {
                        s3 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c577); }
                      }
                      if (s3 === peg$FAILED) {
                        s3 = null;
                      }
                      if (s3 !== peg$FAILED) {
                        s4 = peg$parse_();
                        if (s4 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 4) === peg$c578) {
                            s5 = peg$c578;
                            peg$currPos += 4;
                          } else {
                            s5 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c579); }
                          }
                          if (s5 !== peg$FAILED) {
                            s6 = peg$parse_();
                            if (s6 !== peg$FAILED) {
                              if (input.substr(peg$currPos, 3) === peg$c1075) {
                                s7 = peg$c1075;
                                peg$currPos += 3;
                              } else {
                                s7 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c1076); }
                              }
                              if (s7 !== peg$FAILED) {
                                s8 = peg$parse_();
                                if (s8 !== peg$FAILED) {
                                  if (input.substr(peg$currPos, 2) === peg$c1070) {
                                    s9 = peg$c1070;
                                    peg$currPos += 2;
                                  } else {
                                    s9 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c1071); }
                                  }
                                  if (s9 !== peg$FAILED) {
                                    s10 = peg$parse_();
                                    if (s10 !== peg$FAILED) {
                                      if (input.substr(peg$currPos, 5) === peg$c1083) {
                                        s11 = peg$c1083;
                                        peg$currPos += 5;
                                      } else {
                                        s11 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c1084); }
                                      }
                                      if (s11 !== peg$FAILED) {
                                        s12 = peg$parse_();
                                        if (s12 !== peg$FAILED) {
                                          s13 = peg$parseInteger();
                                          if (s13 !== peg$FAILED) {
                                            s14 = peg$parse_();
                                            if (s14 !== peg$FAILED) {
                                              s15 = peg$parseGenericName();
                                              if (s15 !== peg$FAILED) {
                                                peg$savedPos = s0;
                                                s1 = peg$c1085(s13, s15);
                                                s0 = s1;
                                              } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s0;
                                          s0 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                  if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    s1 = peg$currPos;
                    if (input.substr(peg$currPos, 2) === peg$c693) {
                      s2 = peg$c693;
                      peg$currPos += 2;
                    } else {
                      s2 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c694); }
                    }
                    if (s2 !== peg$FAILED) {
                      s3 = peg$parse_();
                      if (s3 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 3) === peg$c576) {
                          s4 = peg$c576;
                          peg$currPos += 3;
                        } else {
                          s4 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c577); }
                        }
                        if (s4 !== peg$FAILED) {
                          s5 = peg$parse_();
                          if (s5 !== peg$FAILED) {
                            if (input.substr(peg$currPos, 4) === peg$c578) {
                              s6 = peg$c578;
                              peg$currPos += 4;
                            } else {
                              s6 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c579); }
                            }
                            if (s6 !== peg$FAILED) {
                              s7 = peg$parse_();
                              if (s7 !== peg$FAILED) {
                                if (input.substr(peg$currPos, 3) === peg$c1075) {
                                  s8 = peg$c1075;
                                  peg$currPos += 3;
                                } else {
                                  s8 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c1076); }
                                }
                                if (s8 !== peg$FAILED) {
                                  s9 = peg$parse_();
                                  if (s9 !== peg$FAILED) {
                                    if (input.substr(peg$currPos, 3) === peg$c701) {
                                      s10 = peg$c701;
                                      peg$currPos += 3;
                                    } else {
                                      s10 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c702); }
                                    }
                                    if (s10 !== peg$FAILED) {
                                      s11 = peg$parse_();
                                      if (s11 !== peg$FAILED) {
                                        if (input.substr(peg$currPos, 6) === peg$c1086) {
                                          s12 = peg$c1086;
                                          peg$currPos += 6;
                                        } else {
                                          s12 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c1087); }
                                        }
                                        if (s12 === peg$FAILED) {
                                          if (input.substr(peg$currPos, 4) === peg$c1088) {
                                            s12 = peg$c1088;
                                            peg$currPos += 4;
                                          } else {
                                            s12 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c1089); }
                                          }
                                        }
                                        if (s12 !== peg$FAILED) {
                                          s2 = [s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12];
                                          s1 = s2;
                                        } else {
                                          peg$currPos = s1;
                                          s1 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s1;
                                        s1 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s1;
                                      s1 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s1;
                                    s1 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s1;
                                  s1 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s1;
                                s1 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s1;
                              s1 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s1;
                            s1 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s1;
                          s1 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s1;
                        s1 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s1;
                      s1 = peg$FAILED;
                    }
                    if (s1 === peg$FAILED) {
                      s1 = peg$currPos;
                      if (input.substr(peg$currPos, 4) === peg$c1090) {
                        s2 = peg$c1090;
                        peg$currPos += 4;
                      } else {
                        s2 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c1091); }
                      }
                      if (s2 !== peg$FAILED) {
                        s3 = peg$parse_();
                        if (s3 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 3) === peg$c701) {
                            s4 = peg$c701;
                            peg$currPos += 3;
                          } else {
                            s4 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c702); }
                          }
                          if (s4 !== peg$FAILED) {
                            s5 = peg$parse_();
                            if (s5 !== peg$FAILED) {
                              if (input.substr(peg$currPos, 6) === peg$c1086) {
                                s6 = peg$c1086;
                                peg$currPos += 6;
                              } else {
                                s6 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c1087); }
                              }
                              if (s6 === peg$FAILED) {
                                if (input.substr(peg$currPos, 4) === peg$c1088) {
                                  s6 = peg$c1088;
                                  peg$currPos += 4;
                                } else {
                                  s6 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c1089); }
                                }
                              }
                              if (s6 !== peg$FAILED) {
                                s2 = [s2, s3, s4, s5, s6];
                                s1 = s2;
                              } else {
                                peg$currPos = s1;
                                s1 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s1;
                              s1 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s1;
                            s1 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s1;
                          s1 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s1;
                        s1 = peg$FAILED;
                      }
                    }
                    if (s1 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c1092();
                    }
                    s0 = s1;
                    if (s0 === peg$FAILED) {
                      s0 = peg$currPos;
                      if (input.substr(peg$currPos, 2) === peg$c693) {
                        s1 = peg$c693;
                        peg$currPos += 2;
                      } else {
                        s1 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c694); }
                      }
                      if (s1 !== peg$FAILED) {
                        s2 = peg$parse_();
                        if (s2 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 3) === peg$c576) {
                            s3 = peg$c576;
                            peg$currPos += 3;
                          } else {
                            s3 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c577); }
                          }
                          if (s3 === peg$FAILED) {
                            s3 = null;
                          }
                          if (s3 !== peg$FAILED) {
                            s4 = peg$parse_();
                            if (s4 !== peg$FAILED) {
                              if (input.substr(peg$currPos, 4) === peg$c578) {
                                s5 = peg$c578;
                                peg$currPos += 4;
                              } else {
                                s5 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c579); }
                              }
                              if (s5 === peg$FAILED) {
                                if (input.substr(peg$currPos, 6) === peg$c1093) {
                                  s5 = peg$c1093;
                                  peg$currPos += 6;
                                } else {
                                  s5 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c1094); }
                                }
                              }
                              if (s5 !== peg$FAILED) {
                                s6 = peg$parse_();
                                if (s6 !== peg$FAILED) {
                                  if (input.substr(peg$currPos, 3) === peg$c1075) {
                                    s7 = peg$c1075;
                                    peg$currPos += 3;
                                  } else {
                                    s7 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c1076); }
                                  }
                                  if (s7 !== peg$FAILED) {
                                    s8 = peg$parse_();
                                    if (s8 !== peg$FAILED) {
                                      s9 = peg$currPos;
                                      s10 = peg$parseStandardStatus();
                                      if (s10 !== peg$FAILED) {
                                        s11 = [];
                                        s12 = peg$currPos;
                                        if (input.charCodeAt(peg$currPos) === 47) {
                                          s13 = peg$c967;
                                          peg$currPos++;
                                        } else {
                                          s13 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c968); }
                                        }
                                        if (s13 !== peg$FAILED) {
                                          s14 = peg$parseStandardStatus();
                                          if (s14 !== peg$FAILED) {
                                            s13 = [s13, s14];
                                            s12 = s13;
                                          } else {
                                            peg$currPos = s12;
                                            s12 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s12;
                                          s12 = peg$FAILED;
                                        }
                                        if (s12 !== peg$FAILED) {
                                          while (s12 !== peg$FAILED) {
                                            s11.push(s12);
                                            s12 = peg$currPos;
                                            if (input.charCodeAt(peg$currPos) === 47) {
                                              s13 = peg$c967;
                                              peg$currPos++;
                                            } else {
                                              s13 = peg$FAILED;
                                              if (peg$silentFails === 0) { peg$fail(peg$c968); }
                                            }
                                            if (s13 !== peg$FAILED) {
                                              s14 = peg$parseStandardStatus();
                                              if (s14 !== peg$FAILED) {
                                                s13 = [s13, s14];
                                                s12 = s13;
                                              } else {
                                                peg$currPos = s12;
                                                s12 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s12;
                                              s12 = peg$FAILED;
                                            }
                                          }
                                        } else {
                                          s11 = peg$FAILED;
                                        }
                                        if (s11 !== peg$FAILED) {
                                          peg$savedPos = s9;
                                          s10 = peg$c1095(s5, s10, s11);
                                          s9 = s10;
                                        } else {
                                          peg$currPos = s9;
                                          s9 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s9;
                                        s9 = peg$FAILED;
                                      }
                                      if (s9 !== peg$FAILED) {
                                        peg$savedPos = s0;
                                        s1 = peg$c1096(s5, s9);
                                        s0 = s1;
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                      if (s0 === peg$FAILED) {
                        s0 = peg$parseOnceOnly();
                        if (s0 === peg$FAILED) {
                          s0 = peg$currPos;
                          if (input.substr(peg$currPos, 2) === peg$c693) {
                            s1 = peg$c693;
                            peg$currPos += 2;
                          } else {
                            s1 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c694); }
                          }
                          if (s1 !== peg$FAILED) {
                            s2 = peg$parse_();
                            if (s2 !== peg$FAILED) {
                              if (input.substr(peg$currPos, 3) === peg$c576) {
                                s3 = peg$c576;
                                peg$currPos += 3;
                              } else {
                                s3 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c577); }
                              }
                              if (s3 === peg$FAILED) {
                                s3 = null;
                              }
                              if (s3 !== peg$FAILED) {
                                s4 = peg$parse_();
                                if (s4 !== peg$FAILED) {
                                  if (input.substr(peg$currPos, 4) === peg$c578) {
                                    s5 = peg$c578;
                                    peg$currPos += 4;
                                  } else {
                                    s5 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c579); }
                                  }
                                  if (s5 === peg$FAILED) {
                                    if (input.substr(peg$currPos, 6) === peg$c1093) {
                                      s5 = peg$c1093;
                                      peg$currPos += 6;
                                    } else {
                                      s5 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c1094); }
                                    }
                                  }
                                  if (s5 !== peg$FAILED) {
                                    s6 = peg$parse_();
                                    if (s6 !== peg$FAILED) {
                                      s7 = peg$parseWithoutWith();
                                      if (s7 !== peg$FAILED) {
                                        s8 = peg$parse_();
                                        if (s8 !== peg$FAILED) {
                                          if (input.substr(peg$currPos, 3) === peg$c701) {
                                            s9 = peg$c701;
                                            peg$currPos += 3;
                                          } else {
                                            s9 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c702); }
                                          }
                                          if (s9 === peg$FAILED) {
                                            s9 = null;
                                          }
                                          if (s9 !== peg$FAILED) {
                                            s10 = peg$parse_();
                                            if (s10 !== peg$FAILED) {
                                              s11 = peg$currPos;
                                              s12 = peg$parseGenericName();
                                              if (s12 !== peg$FAILED) {
                                                s13 = [];
                                                s14 = peg$currPos;
                                                s15 = peg$parseOrList();
                                                if (s15 !== peg$FAILED) {
                                                  s16 = peg$parseGenericName();
                                                  if (s16 !== peg$FAILED) {
                                                    s15 = [s15, s16];
                                                    s14 = s15;
                                                  } else {
                                                    peg$currPos = s14;
                                                    s14 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s14;
                                                  s14 = peg$FAILED;
                                                }
                                                while (s14 !== peg$FAILED) {
                                                  s13.push(s14);
                                                  s14 = peg$currPos;
                                                  s15 = peg$parseOrList();
                                                  if (s15 !== peg$FAILED) {
                                                    s16 = peg$parseGenericName();
                                                    if (s16 !== peg$FAILED) {
                                                      s15 = [s15, s16];
                                                      s14 = s15;
                                                    } else {
                                                      peg$currPos = s14;
                                                      s14 = peg$FAILED;
                                                    }
                                                  } else {
                                                    peg$currPos = s14;
                                                    s14 = peg$FAILED;
                                                  }
                                                }
                                                if (s13 !== peg$FAILED) {
                                                  peg$savedPos = s11;
                                                  s12 = peg$c1097(s5, s7, s9, s12, s13);
                                                  s11 = s12;
                                                } else {
                                                  peg$currPos = s11;
                                                  s11 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s11;
                                                s11 = peg$FAILED;
                                              }
                                              if (s11 !== peg$FAILED) {
                                                peg$savedPos = s0;
                                                s1 = peg$c1098(s5, s7, s9, s11);
                                                s0 = s1;
                                              } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s0;
                                          s0 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                          if (s0 === peg$FAILED) {
                            s0 = peg$currPos;
                            if (input.substr(peg$currPos, 2) === peg$c693) {
                              s1 = peg$c693;
                              peg$currPos += 2;
                            } else {
                              s1 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c694); }
                            }
                            if (s1 !== peg$FAILED) {
                              s2 = peg$parse_();
                              if (s2 !== peg$FAILED) {
                                if (input.substr(peg$currPos, 3) === peg$c576) {
                                  s3 = peg$c576;
                                  peg$currPos += 3;
                                } else {
                                  s3 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c577); }
                                }
                                if (s3 === peg$FAILED) {
                                  s3 = null;
                                }
                                if (s3 !== peg$FAILED) {
                                  s4 = peg$parse_();
                                  if (s4 !== peg$FAILED) {
                                    if (input.substr(peg$currPos, 4) === peg$c578) {
                                      s5 = peg$c578;
                                      peg$currPos += 4;
                                    } else {
                                      s5 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c579); }
                                    }
                                    if (s5 === peg$FAILED) {
                                      if (input.substr(peg$currPos, 6) === peg$c1093) {
                                        s5 = peg$c1093;
                                        peg$currPos += 6;
                                      } else {
                                        s5 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c1094); }
                                      }
                                    }
                                    if (s5 !== peg$FAILED) {
                                      s6 = peg$parse_();
                                      if (s6 !== peg$FAILED) {
                                        s7 = peg$parseWithoutWith();
                                        if (s7 !== peg$FAILED) {
                                          s8 = peg$parse_();
                                          if (s8 !== peg$FAILED) {
                                            if (input.substr(peg$currPos, 3) === peg$c701) {
                                              s9 = peg$c701;
                                              peg$currPos += 3;
                                            } else {
                                              s9 = peg$FAILED;
                                              if (peg$silentFails === 0) { peg$fail(peg$c702); }
                                            }
                                            if (s9 === peg$FAILED) {
                                              s9 = null;
                                            }
                                            if (s9 !== peg$FAILED) {
                                              s10 = peg$parse_();
                                              if (s10 !== peg$FAILED) {
                                                s11 = peg$currPos;
                                                s12 = peg$parseStatusName();
                                                if (s12 !== peg$FAILED) {
                                                  s13 = [];
                                                  s14 = peg$currPos;
                                                  s15 = peg$parseOrList();
                                                  if (s15 !== peg$FAILED) {
                                                    s16 = peg$parseStatusName();
                                                    if (s16 !== peg$FAILED) {
                                                      s15 = [s15, s16];
                                                      s14 = s15;
                                                    } else {
                                                      peg$currPos = s14;
                                                      s14 = peg$FAILED;
                                                    }
                                                  } else {
                                                    peg$currPos = s14;
                                                    s14 = peg$FAILED;
                                                  }
                                                  while (s14 !== peg$FAILED) {
                                                    s13.push(s14);
                                                    s14 = peg$currPos;
                                                    s15 = peg$parseOrList();
                                                    if (s15 !== peg$FAILED) {
                                                      s16 = peg$parseStatusName();
                                                      if (s16 !== peg$FAILED) {
                                                        s15 = [s15, s16];
                                                        s14 = s15;
                                                      } else {
                                                        peg$currPos = s14;
                                                        s14 = peg$FAILED;
                                                      }
                                                    } else {
                                                      peg$currPos = s14;
                                                      s14 = peg$FAILED;
                                                    }
                                                  }
                                                  if (s13 !== peg$FAILED) {
                                                    peg$savedPos = s11;
                                                    s12 = peg$c1097(s5, s7, s9, s12, s13);
                                                    s11 = s12;
                                                  } else {
                                                    peg$currPos = s11;
                                                    s11 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s11;
                                                  s11 = peg$FAILED;
                                                }
                                                if (s11 !== peg$FAILED) {
                                                  peg$savedPos = s0;
                                                  s1 = peg$c1098(s5, s7, s9, s11);
                                                  s0 = s1;
                                                } else {
                                                  peg$currPos = s0;
                                                  s0 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s0;
                                          s0 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                            if (s0 === peg$FAILED) {
                              s0 = peg$currPos;
                              if (input.substr(peg$currPos, 92) === peg$c1099) {
                                s1 = peg$c1099;
                                peg$currPos += 92;
                              } else {
                                s1 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c1100); }
                              }
                              if (s1 !== peg$FAILED) {
                                s2 = peg$parse_();
                                if (s2 !== peg$FAILED) {
                                  s3 = peg$parseElementSlashList();
                                  if (s3 !== peg$FAILED) {
                                    s4 = peg$parse_();
                                    if (s4 !== peg$FAILED) {
                                      if (input.substr(peg$currPos, 80) === peg$c1101) {
                                        s5 = peg$c1101;
                                        peg$currPos += 80;
                                      } else {
                                        s5 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c1102); }
                                      }
                                      if (s5 === peg$FAILED) {
                                        if (input.substr(peg$currPos, 168) === peg$c1103) {
                                          s5 = peg$c1103;
                                          peg$currPos += 168;
                                        } else {
                                          s5 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c1104); }
                                        }
                                      }
                                      if (s5 !== peg$FAILED) {
                                        peg$savedPos = s0;
                                        s1 = peg$c1105(s3);
                                        s0 = s1;
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                              if (s0 === peg$FAILED) {
                                s0 = peg$currPos;
                                if (input.substr(peg$currPos, 2) === peg$c1070) {
                                  s1 = peg$c1070;
                                  peg$currPos += 2;
                                } else {
                                  s1 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c1071); }
                                }
                                if (s1 === peg$FAILED) {
                                  if (input.substr(peg$currPos, 12) === peg$c1067) {
                                    s1 = peg$c1067;
                                    peg$currPos += 12;
                                  } else {
                                    s1 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c1068); }
                                  }
                                  if (s1 === peg$FAILED) {
                                    if (input.substr(peg$currPos, 5) === peg$c711) {
                                      s1 = peg$c711;
                                      peg$currPos += 5;
                                    } else {
                                      s1 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c712); }
                                    }
                                  }
                                }
                                if (s1 !== peg$FAILED) {
                                  s2 = peg$parse_();
                                  if (s2 !== peg$FAILED) {
                                    s3 = peg$parseIntegerSlashList();
                                    if (s3 !== peg$FAILED) {
                                      if (input.charCodeAt(peg$currPos) === 43) {
                                        s4 = peg$c19;
                                        peg$currPos++;
                                      } else {
                                        s4 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c20); }
                                      }
                                      if (s4 === peg$FAILED) {
                                        s4 = null;
                                      }
                                      if (s4 !== peg$FAILED) {
                                        s5 = peg$parse_();
                                        if (s5 !== peg$FAILED) {
                                          if (input.substr(peg$currPos, 4) === peg$c781) {
                                            s6 = peg$c781;
                                            peg$currPos += 4;
                                          } else {
                                            s6 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c782); }
                                          }
                                          if (s6 === peg$FAILED) {
                                            if (input.substr(peg$currPos, 5) === peg$c552) {
                                              s6 = peg$c552;
                                              peg$currPos += 5;
                                            } else {
                                              s6 = peg$FAILED;
                                              if (peg$silentFails === 0) { peg$fail(peg$c553); }
                                            }
                                          }
                                          if (s6 !== peg$FAILED) {
                                            peg$savedPos = s0;
                                            s1 = peg$c1106(s3);
                                            s0 = s1;
                                          } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s0;
                                          s0 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                                if (s0 === peg$FAILED) {
                                  s0 = peg$currPos;
                                  if (input.substr(peg$currPos, 12) === peg$c1067) {
                                    s1 = peg$c1067;
                                    peg$currPos += 12;
                                  } else {
                                    s1 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c1068); }
                                  }
                                  if (s1 !== peg$FAILED) {
                                    s2 = peg$parse_();
                                    if (s2 !== peg$FAILED) {
                                      if (input.substr(peg$currPos, 4) === peg$c781) {
                                        s3 = peg$c781;
                                        peg$currPos += 4;
                                      } else {
                                        s3 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c782); }
                                      }
                                      if (s3 === peg$FAILED) {
                                        if (input.substr(peg$currPos, 11) === peg$c1107) {
                                          s3 = peg$c1107;
                                          peg$currPos += 11;
                                        } else {
                                          s3 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c1108); }
                                        }
                                      }
                                      if (s3 !== peg$FAILED) {
                                        peg$savedPos = s0;
                                        s1 = peg$c1109();
                                        s0 = s1;
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                  if (s0 === peg$FAILED) {
                                    s0 = peg$currPos;
                                    if (input.substr(peg$currPos, 7) === peg$c1110) {
                                      s1 = peg$c1110;
                                      peg$currPos += 7;
                                    } else {
                                      s1 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c1111); }
                                    }
                                    if (s1 === peg$FAILED) {
                                      if (input.substr(peg$currPos, 5) === peg$c1112) {
                                        s1 = peg$c1112;
                                        peg$currPos += 5;
                                      } else {
                                        s1 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c1113); }
                                      }
                                    }
                                    if (s1 !== peg$FAILED) {
                                      s2 = peg$parse_();
                                      if (s2 !== peg$FAILED) {
                                        if (input.substr(peg$currPos, 4) === peg$c1090) {
                                          s3 = peg$c1090;
                                          peg$currPos += 4;
                                        } else {
                                          s3 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c1091); }
                                        }
                                        if (s3 !== peg$FAILED) {
                                          s4 = peg$parse_();
                                          if (s4 !== peg$FAILED) {
                                            s5 = peg$parseAnySkillName();
                                            if (s5 !== peg$FAILED) {
                                              s6 = peg$parse_();
                                              if (s6 !== peg$FAILED) {
                                                if (input.substr(peg$currPos, 4) === peg$c781) {
                                                  s7 = peg$c781;
                                                  peg$currPos += 4;
                                                } else {
                                                  s7 = peg$FAILED;
                                                  if (peg$silentFails === 0) { peg$fail(peg$c782); }
                                                }
                                                if (s7 !== peg$FAILED) {
                                                  peg$savedPos = s0;
                                                  s1 = peg$c1114(s5);
                                                  s0 = s1;
                                                } else {
                                                  peg$currPos = s0;
                                                  s0 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s0;
                                          s0 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                    if (s0 === peg$FAILED) {
                                      s0 = peg$currPos;
                                      if (input.substr(peg$currPos, 5) === peg$c711) {
                                        s1 = peg$c711;
                                        peg$currPos += 5;
                                      } else {
                                        s1 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c712); }
                                      }
                                      if (s1 === peg$FAILED) {
                                        if (input.substr(peg$currPos, 5) === peg$c353) {
                                          s1 = peg$c353;
                                          peg$currPos += 5;
                                        } else {
                                          s1 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c354); }
                                        }
                                      }
                                      if (s1 !== peg$FAILED) {
                                        s2 = peg$parse_();
                                        if (s2 !== peg$FAILED) {
                                          s3 = peg$parseUseCount();
                                          if (s3 !== peg$FAILED) {
                                            s4 = peg$parse_();
                                            if (s4 !== peg$FAILED) {
                                              s5 = peg$parseAnySkillName();
                                              if (s5 === peg$FAILED) {
                                                s5 = null;
                                              }
                                              if (s5 !== peg$FAILED) {
                                                s6 = peg$parse_();
                                                if (s6 !== peg$FAILED) {
                                                  if (input.substr(peg$currPos, 4) === peg$c781) {
                                                    s7 = peg$c781;
                                                    peg$currPos += 4;
                                                  } else {
                                                    s7 = peg$FAILED;
                                                    if (peg$silentFails === 0) { peg$fail(peg$c782); }
                                                  }
                                                  if (s7 === peg$FAILED) {
                                                    if (input.substr(peg$currPos, 11) === peg$c1107) {
                                                      s7 = peg$c1107;
                                                      peg$currPos += 11;
                                                    } else {
                                                      s7 = peg$FAILED;
                                                      if (peg$silentFails === 0) { peg$fail(peg$c1108); }
                                                    }
                                                  }
                                                  if (s7 !== peg$FAILED) {
                                                    peg$savedPos = s0;
                                                    s1 = peg$c1115(s3, s5);
                                                    s0 = s1;
                                                  } else {
                                                    peg$currPos = s0;
                                                    s0 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s0;
                                                  s0 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s0;
                                          s0 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                      if (s0 === peg$FAILED) {
                                        s0 = peg$currPos;
                                        if (input.substr(peg$currPos, 5) === peg$c711) {
                                          s1 = peg$c711;
                                          peg$currPos += 5;
                                        } else {
                                          s1 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c712); }
                                        }
                                        if (s1 !== peg$FAILED) {
                                          s2 = peg$parse_();
                                          if (s2 !== peg$FAILED) {
                                            s3 = peg$parseNumberString();
                                            if (s3 !== peg$FAILED) {
                                              s4 = peg$parse_();
                                              if (s4 !== peg$FAILED) {
                                                if (input.substr(peg$currPos, 4) === peg$c781) {
                                                  s5 = peg$c781;
                                                  peg$currPos += 4;
                                                } else {
                                                  s5 = peg$FAILED;
                                                  if (peg$silentFails === 0) { peg$fail(peg$c782); }
                                                }
                                                if (s5 !== peg$FAILED) {
                                                  peg$savedPos = s0;
                                                  s1 = peg$c1116(s3);
                                                  s0 = s1;
                                                } else {
                                                  peg$currPos = s0;
                                                  s0 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s0;
                                          s0 = peg$FAILED;
                                        }
                                        if (s0 === peg$FAILED) {
                                          s0 = peg$currPos;
                                          if (input.substr(peg$currPos, 2) === peg$c1117) {
                                            s1 = peg$c1117;
                                            peg$currPos += 2;
                                          } else {
                                            s1 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c1118); }
                                          }
                                          if (s1 !== peg$FAILED) {
                                            s2 = peg$parse_();
                                            if (s2 !== peg$FAILED) {
                                              if (input.substr(peg$currPos, 5) === peg$c1119) {
                                                s3 = peg$c1119;
                                                peg$currPos += 5;
                                              } else {
                                                s3 = peg$FAILED;
                                                if (peg$silentFails === 0) { peg$fail(peg$c1120); }
                                              }
                                              if (s3 !== peg$FAILED) {
                                                s4 = peg$parse_();
                                                if (s4 !== peg$FAILED) {
                                                  if (input.substr(peg$currPos, 3) === peg$c1121) {
                                                    s5 = peg$c1121;
                                                    peg$currPos += 3;
                                                  } else {
                                                    s5 = peg$FAILED;
                                                    if (peg$silentFails === 0) { peg$fail(peg$c1122); }
                                                  }
                                                  if (s5 !== peg$FAILED) {
                                                    peg$savedPos = s0;
                                                    s1 = peg$c1123();
                                                    s0 = s1;
                                                  } else {
                                                    peg$currPos = s0;
                                                    s0 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s0;
                                                  s0 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                          }
                                          if (s0 === peg$FAILED) {
                                            s0 = peg$currPos;
                                            if (input.substr(peg$currPos, 2) === peg$c1117) {
                                              s1 = peg$c1117;
                                              peg$currPos += 2;
                                            } else {
                                              s1 = peg$FAILED;
                                              if (peg$silentFails === 0) { peg$fail(peg$c1118); }
                                            }
                                            if (s1 !== peg$FAILED) {
                                              s2 = peg$parse_();
                                              if (s2 !== peg$FAILED) {
                                                s3 = peg$parseInteger();
                                                if (s3 !== peg$FAILED) {
                                                  if (input.charCodeAt(peg$currPos) === 43) {
                                                    s4 = peg$c19;
                                                    peg$currPos++;
                                                  } else {
                                                    s4 = peg$FAILED;
                                                    if (peg$silentFails === 0) { peg$fail(peg$c20); }
                                                  }
                                                  if (s4 !== peg$FAILED) {
                                                    s5 = peg$parse_();
                                                    if (s5 !== peg$FAILED) {
                                                      if (input.substr(peg$currPos, 3) === peg$c1121) {
                                                        s6 = peg$c1121;
                                                        peg$currPos += 3;
                                                      } else {
                                                        s6 = peg$FAILED;
                                                        if (peg$silentFails === 0) { peg$fail(peg$c1122); }
                                                      }
                                                      if (s6 !== peg$FAILED) {
                                                        if (input.charCodeAt(peg$currPos) === 115) {
                                                          s7 = peg$c164;
                                                          peg$currPos++;
                                                        } else {
                                                          s7 = peg$FAILED;
                                                          if (peg$silentFails === 0) { peg$fail(peg$c165); }
                                                        }
                                                        if (s7 === peg$FAILED) {
                                                          s7 = null;
                                                        }
                                                        if (s7 !== peg$FAILED) {
                                                          peg$savedPos = s0;
                                                          s1 = peg$c1124(s3);
                                                          s0 = s1;
                                                        } else {
                                                          peg$currPos = s0;
                                                          s0 = peg$FAILED;
                                                        }
                                                      } else {
                                                        peg$currPos = s0;
                                                        s0 = peg$FAILED;
                                                      }
                                                    } else {
                                                      peg$currPos = s0;
                                                      s0 = peg$FAILED;
                                                    }
                                                  } else {
                                                    peg$currPos = s0;
                                                    s0 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s0;
                                                  s0 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                            if (s0 === peg$FAILED) {
                                              s0 = peg$currPos;
                                              if (input.substr(peg$currPos, 2) === peg$c693) {
                                                s1 = peg$c693;
                                                peg$currPos += 2;
                                              } else {
                                                s1 = peg$FAILED;
                                                if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                              }
                                              if (s1 !== peg$FAILED) {
                                                s2 = peg$parse_();
                                                if (s2 !== peg$FAILED) {
                                                  if (input.substr(peg$currPos, 3) === peg$c212) {
                                                    s3 = peg$c212;
                                                    peg$currPos += 3;
                                                  } else {
                                                    s3 = peg$FAILED;
                                                    if (peg$silentFails === 0) { peg$fail(peg$c213); }
                                                  }
                                                  if (s3 !== peg$FAILED) {
                                                    s4 = peg$parse_();
                                                    if (s4 !== peg$FAILED) {
                                                      if (input.substr(peg$currPos, 6) === peg$c523) {
                                                        s5 = peg$c523;
                                                        peg$currPos += 6;
                                                      } else {
                                                        s5 = peg$FAILED;
                                                        if (peg$silentFails === 0) { peg$fail(peg$c524); }
                                                      }
                                                      if (s5 !== peg$FAILED) {
                                                        s6 = peg$parse_();
                                                        if (s6 !== peg$FAILED) {
                                                          if (input.substr(peg$currPos, 3) === peg$c1125) {
                                                            s7 = peg$c1125;
                                                            peg$currPos += 3;
                                                          } else {
                                                            s7 = peg$FAILED;
                                                            if (peg$silentFails === 0) { peg$fail(peg$c1126); }
                                                          }
                                                          if (s7 !== peg$FAILED) {
                                                            s8 = peg$parse_();
                                                            if (s8 !== peg$FAILED) {
                                                              if (input.substr(peg$currPos, 5) === peg$c1127) {
                                                                s9 = peg$c1127;
                                                                peg$currPos += 5;
                                                              } else {
                                                                s9 = peg$FAILED;
                                                                if (peg$silentFails === 0) { peg$fail(peg$c1128); }
                                                              }
                                                              if (s9 !== peg$FAILED) {
                                                                peg$savedPos = s0;
                                                                s1 = peg$c1129();
                                                                s0 = s1;
                                                              } else {
                                                                peg$currPos = s0;
                                                                s0 = peg$FAILED;
                                                              }
                                                            } else {
                                                              peg$currPos = s0;
                                                              s0 = peg$FAILED;
                                                            }
                                                          } else {
                                                            peg$currPos = s0;
                                                            s0 = peg$FAILED;
                                                          }
                                                        } else {
                                                          peg$currPos = s0;
                                                          s0 = peg$FAILED;
                                                        }
                                                      } else {
                                                        peg$currPos = s0;
                                                        s0 = peg$FAILED;
                                                      }
                                                    } else {
                                                      peg$currPos = s0;
                                                      s0 = peg$FAILED;
                                                    }
                                                  } else {
                                                    peg$currPos = s0;
                                                    s0 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s0;
                                                  s0 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                              }
                                              if (s0 === peg$FAILED) {
                                                s0 = peg$currPos;
                                                if (input.substr(peg$currPos, 2) === peg$c693) {
                                                  s1 = peg$c693;
                                                  peg$currPos += 2;
                                                } else {
                                                  s1 = peg$FAILED;
                                                  if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                }
                                                if (s1 !== peg$FAILED) {
                                                  s2 = peg$parse_();
                                                  if (s2 !== peg$FAILED) {
                                                    s3 = peg$parseCharacterNameListOrPronoun();
                                                    if (s3 !== peg$FAILED) {
                                                      s4 = peg$parse_();
                                                      if (s4 !== peg$FAILED) {
                                                        if (input.substr(peg$currPos, 2) === peg$c1130) {
                                                          s5 = peg$c1130;
                                                          peg$currPos += 2;
                                                        } else {
                                                          s5 = peg$FAILED;
                                                          if (peg$silentFails === 0) { peg$fail(peg$c1131); }
                                                        }
                                                        if (s5 === peg$FAILED) {
                                                          if (input.substr(peg$currPos, 3) === peg$c1125) {
                                                            s5 = peg$c1125;
                                                            peg$currPos += 3;
                                                          } else {
                                                            s5 = peg$FAILED;
                                                            if (peg$silentFails === 0) { peg$fail(peg$c1126); }
                                                          }
                                                        }
                                                        if (s5 !== peg$FAILED) {
                                                          s6 = peg$parse_();
                                                          if (s6 !== peg$FAILED) {
                                                            if (input.substr(peg$currPos, 5) === peg$c1127) {
                                                              s7 = peg$c1127;
                                                              peg$currPos += 5;
                                                            } else {
                                                              s7 = peg$FAILED;
                                                              if (peg$silentFails === 0) { peg$fail(peg$c1128); }
                                                            }
                                                            if (s7 !== peg$FAILED) {
                                                              peg$savedPos = s0;
                                                              s1 = peg$c1132(s3);
                                                              s0 = s1;
                                                            } else {
                                                              peg$currPos = s0;
                                                              s0 = peg$FAILED;
                                                            }
                                                          } else {
                                                            peg$currPos = s0;
                                                            s0 = peg$FAILED;
                                                          }
                                                        } else {
                                                          peg$currPos = s0;
                                                          s0 = peg$FAILED;
                                                        }
                                                      } else {
                                                        peg$currPos = s0;
                                                        s0 = peg$FAILED;
                                                      }
                                                    } else {
                                                      peg$currPos = s0;
                                                      s0 = peg$FAILED;
                                                    }
                                                  } else {
                                                    peg$currPos = s0;
                                                    s0 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s0;
                                                  s0 = peg$FAILED;
                                                }
                                                if (s0 === peg$FAILED) {
                                                  s0 = peg$currPos;
                                                  if (input.substr(peg$currPos, 2) === peg$c693) {
                                                    s1 = peg$c693;
                                                    peg$currPos += 2;
                                                  } else {
                                                    s1 = peg$FAILED;
                                                    if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                  }
                                                  if (s1 !== peg$FAILED) {
                                                    s2 = peg$parse_();
                                                    if (s2 !== peg$FAILED) {
                                                      s3 = peg$parseCharacterNameAndList();
                                                      if (s3 !== peg$FAILED) {
                                                        s4 = peg$parse_();
                                                        if (s4 !== peg$FAILED) {
                                                          if (input.substr(peg$currPos, 2) === peg$c1130) {
                                                            s5 = peg$c1130;
                                                            peg$currPos += 2;
                                                          } else {
                                                            s5 = peg$FAILED;
                                                            if (peg$silentFails === 0) { peg$fail(peg$c1131); }
                                                          }
                                                          if (s5 === peg$FAILED) {
                                                            if (input.substr(peg$currPos, 3) === peg$c1125) {
                                                              s5 = peg$c1125;
                                                              peg$currPos += 3;
                                                            } else {
                                                              s5 = peg$FAILED;
                                                              if (peg$silentFails === 0) { peg$fail(peg$c1126); }
                                                            }
                                                          }
                                                          if (s5 !== peg$FAILED) {
                                                            s6 = peg$parse_();
                                                            if (s6 !== peg$FAILED) {
                                                              if (input.substr(peg$currPos, 5) === peg$c1127) {
                                                                s7 = peg$c1127;
                                                                peg$currPos += 5;
                                                              } else {
                                                                s7 = peg$FAILED;
                                                                if (peg$silentFails === 0) { peg$fail(peg$c1128); }
                                                              }
                                                              if (s7 !== peg$FAILED) {
                                                                peg$savedPos = s0;
                                                                s1 = peg$c1133(s3);
                                                                s0 = s1;
                                                              } else {
                                                                peg$currPos = s0;
                                                                s0 = peg$FAILED;
                                                              }
                                                            } else {
                                                              peg$currPos = s0;
                                                              s0 = peg$FAILED;
                                                            }
                                                          } else {
                                                            peg$currPos = s0;
                                                            s0 = peg$FAILED;
                                                          }
                                                        } else {
                                                          peg$currPos = s0;
                                                          s0 = peg$FAILED;
                                                        }
                                                      } else {
                                                        peg$currPos = s0;
                                                        s0 = peg$FAILED;
                                                      }
                                                    } else {
                                                      peg$currPos = s0;
                                                      s0 = peg$FAILED;
                                                    }
                                                  } else {
                                                    peg$currPos = s0;
                                                    s0 = peg$FAILED;
                                                  }
                                                  if (s0 === peg$FAILED) {
                                                    s0 = peg$currPos;
                                                    if (input.substr(peg$currPos, 2) === peg$c693) {
                                                      s1 = peg$c693;
                                                      peg$currPos += 2;
                                                    } else {
                                                      s1 = peg$FAILED;
                                                      if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                    }
                                                    if (s1 !== peg$FAILED) {
                                                      s2 = peg$parse_();
                                                      if (s2 !== peg$FAILED) {
                                                        s3 = peg$parseCharacterNameListOrPronoun();
                                                        if (s3 !== peg$FAILED) {
                                                          s4 = peg$parse_();
                                                          if (s4 !== peg$FAILED) {
                                                            if (input.substr(peg$currPos, 2) === peg$c1130) {
                                                              s5 = peg$c1130;
                                                              peg$currPos += 2;
                                                            } else {
                                                              s5 = peg$FAILED;
                                                              if (peg$silentFails === 0) { peg$fail(peg$c1131); }
                                                            }
                                                            if (s5 === peg$FAILED) {
                                                              if (input.substr(peg$currPos, 3) === peg$c1125) {
                                                                s5 = peg$c1125;
                                                                peg$currPos += 3;
                                                              } else {
                                                                s5 = peg$FAILED;
                                                                if (peg$silentFails === 0) { peg$fail(peg$c1126); }
                                                              }
                                                            }
                                                            if (s5 !== peg$FAILED) {
                                                              s6 = peg$parse_();
                                                              if (s6 !== peg$FAILED) {
                                                                if (input.substr(peg$currPos, 15) === peg$c1134) {
                                                                  s7 = peg$c1134;
                                                                  peg$currPos += 15;
                                                                } else {
                                                                  s7 = peg$FAILED;
                                                                  if (peg$silentFails === 0) { peg$fail(peg$c1135); }
                                                                }
                                                                if (s7 !== peg$FAILED) {
                                                                  peg$savedPos = s0;
                                                                  s1 = peg$c1136(s3);
                                                                  s0 = s1;
                                                                } else {
                                                                  peg$currPos = s0;
                                                                  s0 = peg$FAILED;
                                                                }
                                                              } else {
                                                                peg$currPos = s0;
                                                                s0 = peg$FAILED;
                                                              }
                                                            } else {
                                                              peg$currPos = s0;
                                                              s0 = peg$FAILED;
                                                            }
                                                          } else {
                                                            peg$currPos = s0;
                                                            s0 = peg$FAILED;
                                                          }
                                                        } else {
                                                          peg$currPos = s0;
                                                          s0 = peg$FAILED;
                                                        }
                                                      } else {
                                                        peg$currPos = s0;
                                                        s0 = peg$FAILED;
                                                      }
                                                    } else {
                                                      peg$currPos = s0;
                                                      s0 = peg$FAILED;
                                                    }
                                                    if (s0 === peg$FAILED) {
                                                      s0 = peg$currPos;
                                                      if (input.substr(peg$currPos, 2) === peg$c693) {
                                                        s1 = peg$c693;
                                                        peg$currPos += 2;
                                                      } else {
                                                        s1 = peg$FAILED;
                                                        if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                      }
                                                      if (s1 !== peg$FAILED) {
                                                        s2 = peg$parse_();
                                                        if (s2 !== peg$FAILED) {
                                                          s3 = peg$parseIntegerSlashList();
                                                          if (s3 !== peg$FAILED) {
                                                            if (input.charCodeAt(peg$currPos) === 43) {
                                                              s4 = peg$c19;
                                                              peg$currPos++;
                                                            } else {
                                                              s4 = peg$FAILED;
                                                              if (peg$silentFails === 0) { peg$fail(peg$c20); }
                                                            }
                                                            if (s4 === peg$FAILED) {
                                                              s4 = null;
                                                            }
                                                            if (s4 !== peg$FAILED) {
                                                              s5 = peg$parse_();
                                                              if (s5 !== peg$FAILED) {
                                                                if (input.substr(peg$currPos, 2) === peg$c214) {
                                                                  s6 = peg$c214;
                                                                  peg$currPos += 2;
                                                                } else {
                                                                  s6 = peg$FAILED;
                                                                  if (peg$silentFails === 0) { peg$fail(peg$c215); }
                                                                }
                                                                if (s6 !== peg$FAILED) {
                                                                  s7 = peg$parse_();
                                                                  if (s7 !== peg$FAILED) {
                                                                    s8 = peg$parseCharacterNameList();
                                                                    if (s8 !== peg$FAILED) {
                                                                      s9 = peg$parse_();
                                                                      if (s9 !== peg$FAILED) {
                                                                        if (input.substr(peg$currPos, 3) === peg$c1125) {
                                                                          s10 = peg$c1125;
                                                                          peg$currPos += 3;
                                                                        } else {
                                                                          s10 = peg$FAILED;
                                                                          if (peg$silentFails === 0) { peg$fail(peg$c1126); }
                                                                        }
                                                                        if (s10 !== peg$FAILED) {
                                                                          s11 = peg$parse_();
                                                                          if (s11 !== peg$FAILED) {
                                                                            if (input.substr(peg$currPos, 5) === peg$c1127) {
                                                                              s12 = peg$c1127;
                                                                              peg$currPos += 5;
                                                                            } else {
                                                                              s12 = peg$FAILED;
                                                                              if (peg$silentFails === 0) { peg$fail(peg$c1128); }
                                                                            }
                                                                            if (s12 !== peg$FAILED) {
                                                                              peg$savedPos = s0;
                                                                              s1 = peg$c1137(s3, s8);
                                                                              s0 = s1;
                                                                            } else {
                                                                              peg$currPos = s0;
                                                                              s0 = peg$FAILED;
                                                                            }
                                                                          } else {
                                                                            peg$currPos = s0;
                                                                            s0 = peg$FAILED;
                                                                          }
                                                                        } else {
                                                                          peg$currPos = s0;
                                                                          s0 = peg$FAILED;
                                                                        }
                                                                      } else {
                                                                        peg$currPos = s0;
                                                                        s0 = peg$FAILED;
                                                                      }
                                                                    } else {
                                                                      peg$currPos = s0;
                                                                      s0 = peg$FAILED;
                                                                    }
                                                                  } else {
                                                                    peg$currPos = s0;
                                                                    s0 = peg$FAILED;
                                                                  }
                                                                } else {
                                                                  peg$currPos = s0;
                                                                  s0 = peg$FAILED;
                                                                }
                                                              } else {
                                                                peg$currPos = s0;
                                                                s0 = peg$FAILED;
                                                              }
                                                            } else {
                                                              peg$currPos = s0;
                                                              s0 = peg$FAILED;
                                                            }
                                                          } else {
                                                            peg$currPos = s0;
                                                            s0 = peg$FAILED;
                                                          }
                                                        } else {
                                                          peg$currPos = s0;
                                                          s0 = peg$FAILED;
                                                        }
                                                      } else {
                                                        peg$currPos = s0;
                                                        s0 = peg$FAILED;
                                                      }
                                                      if (s0 === peg$FAILED) {
                                                        s0 = peg$currPos;
                                                        if (input.substr(peg$currPos, 2) === peg$c693) {
                                                          s1 = peg$c693;
                                                          peg$currPos += 2;
                                                        } else {
                                                          s1 = peg$FAILED;
                                                          if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                        }
                                                        if (s1 !== peg$FAILED) {
                                                          s2 = peg$parse_();
                                                          if (s2 !== peg$FAILED) {
                                                            s3 = peg$parseCharacterNameAndList();
                                                            if (s3 !== peg$FAILED) {
                                                              s4 = peg$parse_();
                                                              if (s4 !== peg$FAILED) {
                                                                if (input.substr(peg$currPos, 2) === peg$c1130) {
                                                                  s5 = peg$c1130;
                                                                  peg$currPos += 2;
                                                                } else {
                                                                  s5 = peg$FAILED;
                                                                  if (peg$silentFails === 0) { peg$fail(peg$c1131); }
                                                                }
                                                                if (s5 === peg$FAILED) {
                                                                  if (input.substr(peg$currPos, 3) === peg$c1125) {
                                                                    s5 = peg$c1125;
                                                                    peg$currPos += 3;
                                                                  } else {
                                                                    s5 = peg$FAILED;
                                                                    if (peg$silentFails === 0) { peg$fail(peg$c1126); }
                                                                  }
                                                                }
                                                                if (s5 !== peg$FAILED) {
                                                                  s6 = peg$parse_();
                                                                  if (s6 !== peg$FAILED) {
                                                                    if (input.substr(peg$currPos, 9) === peg$c1138) {
                                                                      s7 = peg$c1138;
                                                                      peg$currPos += 9;
                                                                    } else {
                                                                      s7 = peg$FAILED;
                                                                      if (peg$silentFails === 0) { peg$fail(peg$c1139); }
                                                                    }
                                                                    if (s7 !== peg$FAILED) {
                                                                      peg$savedPos = s0;
                                                                      s1 = peg$c1140(s3);
                                                                      s0 = s1;
                                                                    } else {
                                                                      peg$currPos = s0;
                                                                      s0 = peg$FAILED;
                                                                    }
                                                                  } else {
                                                                    peg$currPos = s0;
                                                                    s0 = peg$FAILED;
                                                                  }
                                                                } else {
                                                                  peg$currPos = s0;
                                                                  s0 = peg$FAILED;
                                                                }
                                                              } else {
                                                                peg$currPos = s0;
                                                                s0 = peg$FAILED;
                                                              }
                                                            } else {
                                                              peg$currPos = s0;
                                                              s0 = peg$FAILED;
                                                            }
                                                          } else {
                                                            peg$currPos = s0;
                                                            s0 = peg$FAILED;
                                                          }
                                                        } else {
                                                          peg$currPos = s0;
                                                          s0 = peg$FAILED;
                                                        }
                                                        if (s0 === peg$FAILED) {
                                                          s0 = peg$currPos;
                                                          if (input.substr(peg$currPos, 2) === peg$c693) {
                                                            s1 = peg$c693;
                                                            peg$currPos += 2;
                                                          } else {
                                                            s1 = peg$FAILED;
                                                            if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                          }
                                                          if (s1 !== peg$FAILED) {
                                                            s2 = peg$parse_();
                                                            if (s2 !== peg$FAILED) {
                                                              s3 = peg$parseIntegerSlashList();
                                                              if (s3 === peg$FAILED) {
                                                                s3 = null;
                                                              }
                                                              if (s3 !== peg$FAILED) {
                                                                s4 = peg$parse_();
                                                                if (s4 !== peg$FAILED) {
                                                                  s5 = peg$parseCharacterNameList();
                                                                  if (s5 !== peg$FAILED) {
                                                                    s6 = peg$parse_();
                                                                    if (s6 !== peg$FAILED) {
                                                                      if (input.substr(peg$currPos, 2) === peg$c1130) {
                                                                        s7 = peg$c1130;
                                                                        peg$currPos += 2;
                                                                      } else {
                                                                        s7 = peg$FAILED;
                                                                        if (peg$silentFails === 0) { peg$fail(peg$c1131); }
                                                                      }
                                                                      if (s7 === peg$FAILED) {
                                                                        if (input.substr(peg$currPos, 3) === peg$c1125) {
                                                                          s7 = peg$c1125;
                                                                          peg$currPos += 3;
                                                                        } else {
                                                                          s7 = peg$FAILED;
                                                                          if (peg$silentFails === 0) { peg$fail(peg$c1126); }
                                                                        }
                                                                      }
                                                                      if (s7 !== peg$FAILED) {
                                                                        s8 = peg$parse_();
                                                                        if (s8 !== peg$FAILED) {
                                                                          if (input.substr(peg$currPos, 2) === peg$c1141) {
                                                                            s9 = peg$c1141;
                                                                            peg$currPos += 2;
                                                                          } else {
                                                                            s9 = peg$FAILED;
                                                                            if (peg$silentFails === 0) { peg$fail(peg$c1142); }
                                                                          }
                                                                          if (s9 !== peg$FAILED) {
                                                                            s10 = peg$parse_();
                                                                            if (s10 !== peg$FAILED) {
                                                                              if (input.substr(peg$currPos, 3) === peg$c576) {
                                                                                s11 = peg$c576;
                                                                                peg$currPos += 3;
                                                                              } else {
                                                                                s11 = peg$FAILED;
                                                                                if (peg$silentFails === 0) { peg$fail(peg$c577); }
                                                                              }
                                                                              if (s11 !== peg$FAILED) {
                                                                                s12 = peg$parse_();
                                                                                if (s12 !== peg$FAILED) {
                                                                                  if (input.substr(peg$currPos, 5) === peg$c1143) {
                                                                                    s13 = peg$c1143;
                                                                                    peg$currPos += 5;
                                                                                  } else {
                                                                                    s13 = peg$FAILED;
                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c1144); }
                                                                                  }
                                                                                  if (s13 !== peg$FAILED) {
                                                                                    peg$savedPos = s0;
                                                                                    s1 = peg$c1145(s3, s5);
                                                                                    s0 = s1;
                                                                                  } else {
                                                                                    peg$currPos = s0;
                                                                                    s0 = peg$FAILED;
                                                                                  }
                                                                                } else {
                                                                                  peg$currPos = s0;
                                                                                  s0 = peg$FAILED;
                                                                                }
                                                                              } else {
                                                                                peg$currPos = s0;
                                                                                s0 = peg$FAILED;
                                                                              }
                                                                            } else {
                                                                              peg$currPos = s0;
                                                                              s0 = peg$FAILED;
                                                                            }
                                                                          } else {
                                                                            peg$currPos = s0;
                                                                            s0 = peg$FAILED;
                                                                          }
                                                                        } else {
                                                                          peg$currPos = s0;
                                                                          s0 = peg$FAILED;
                                                                        }
                                                                      } else {
                                                                        peg$currPos = s0;
                                                                        s0 = peg$FAILED;
                                                                      }
                                                                    } else {
                                                                      peg$currPos = s0;
                                                                      s0 = peg$FAILED;
                                                                    }
                                                                  } else {
                                                                    peg$currPos = s0;
                                                                    s0 = peg$FAILED;
                                                                  }
                                                                } else {
                                                                  peg$currPos = s0;
                                                                  s0 = peg$FAILED;
                                                                }
                                                              } else {
                                                                peg$currPos = s0;
                                                                s0 = peg$FAILED;
                                                              }
                                                            } else {
                                                              peg$currPos = s0;
                                                              s0 = peg$FAILED;
                                                            }
                                                          } else {
                                                            peg$currPos = s0;
                                                            s0 = peg$FAILED;
                                                          }
                                                          if (s0 === peg$FAILED) {
                                                            s0 = peg$currPos;
                                                            if (input.substr(peg$currPos, 2) === peg$c693) {
                                                              s1 = peg$c693;
                                                              peg$currPos += 2;
                                                            } else {
                                                              s1 = peg$FAILED;
                                                              if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                            }
                                                            if (s1 !== peg$FAILED) {
                                                              s2 = peg$parse_();
                                                              if (s2 !== peg$FAILED) {
                                                                s3 = peg$parseIntegerSlashList();
                                                                if (s3 === peg$FAILED) {
                                                                  s3 = null;
                                                                }
                                                                if (s3 !== peg$FAILED) {
                                                                  s4 = peg$parse_();
                                                                  if (s4 !== peg$FAILED) {
                                                                    s5 = peg$parseCharacterNameAndList();
                                                                    if (s5 !== peg$FAILED) {
                                                                      s6 = peg$parse_();
                                                                      if (s6 !== peg$FAILED) {
                                                                        if (input.substr(peg$currPos, 2) === peg$c1130) {
                                                                          s7 = peg$c1130;
                                                                          peg$currPos += 2;
                                                                        } else {
                                                                          s7 = peg$FAILED;
                                                                          if (peg$silentFails === 0) { peg$fail(peg$c1131); }
                                                                        }
                                                                        if (s7 === peg$FAILED) {
                                                                          if (input.substr(peg$currPos, 3) === peg$c1125) {
                                                                            s7 = peg$c1125;
                                                                            peg$currPos += 3;
                                                                          } else {
                                                                            s7 = peg$FAILED;
                                                                            if (peg$silentFails === 0) { peg$fail(peg$c1126); }
                                                                          }
                                                                        }
                                                                        if (s7 !== peg$FAILED) {
                                                                          s8 = peg$parse_();
                                                                          if (s8 !== peg$FAILED) {
                                                                            if (input.substr(peg$currPos, 2) === peg$c1141) {
                                                                              s9 = peg$c1141;
                                                                              peg$currPos += 2;
                                                                            } else {
                                                                              s9 = peg$FAILED;
                                                                              if (peg$silentFails === 0) { peg$fail(peg$c1142); }
                                                                            }
                                                                            if (s9 !== peg$FAILED) {
                                                                              s10 = peg$parse_();
                                                                              if (s10 !== peg$FAILED) {
                                                                                if (input.substr(peg$currPos, 3) === peg$c576) {
                                                                                  s11 = peg$c576;
                                                                                  peg$currPos += 3;
                                                                                } else {
                                                                                  s11 = peg$FAILED;
                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c577); }
                                                                                }
                                                                                if (s11 !== peg$FAILED) {
                                                                                  s12 = peg$parse_();
                                                                                  if (s12 !== peg$FAILED) {
                                                                                    if (input.substr(peg$currPos, 5) === peg$c1143) {
                                                                                      s13 = peg$c1143;
                                                                                      peg$currPos += 5;
                                                                                    } else {
                                                                                      s13 = peg$FAILED;
                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c1144); }
                                                                                    }
                                                                                    if (s13 !== peg$FAILED) {
                                                                                      peg$savedPos = s0;
                                                                                      s1 = peg$c1146(s3, s5);
                                                                                      s0 = s1;
                                                                                    } else {
                                                                                      peg$currPos = s0;
                                                                                      s0 = peg$FAILED;
                                                                                    }
                                                                                  } else {
                                                                                    peg$currPos = s0;
                                                                                    s0 = peg$FAILED;
                                                                                  }
                                                                                } else {
                                                                                  peg$currPos = s0;
                                                                                  s0 = peg$FAILED;
                                                                                }
                                                                              } else {
                                                                                peg$currPos = s0;
                                                                                s0 = peg$FAILED;
                                                                              }
                                                                            } else {
                                                                              peg$currPos = s0;
                                                                              s0 = peg$FAILED;
                                                                            }
                                                                          } else {
                                                                            peg$currPos = s0;
                                                                            s0 = peg$FAILED;
                                                                          }
                                                                        } else {
                                                                          peg$currPos = s0;
                                                                          s0 = peg$FAILED;
                                                                        }
                                                                      } else {
                                                                        peg$currPos = s0;
                                                                        s0 = peg$FAILED;
                                                                      }
                                                                    } else {
                                                                      peg$currPos = s0;
                                                                      s0 = peg$FAILED;
                                                                    }
                                                                  } else {
                                                                    peg$currPos = s0;
                                                                    s0 = peg$FAILED;
                                                                  }
                                                                } else {
                                                                  peg$currPos = s0;
                                                                  s0 = peg$FAILED;
                                                                }
                                                              } else {
                                                                peg$currPos = s0;
                                                                s0 = peg$FAILED;
                                                              }
                                                            } else {
                                                              peg$currPos = s0;
                                                              s0 = peg$FAILED;
                                                            }
                                                            if (s0 === peg$FAILED) {
                                                              s0 = peg$currPos;
                                                              if (input.substr(peg$currPos, 2) === peg$c693) {
                                                                s1 = peg$c693;
                                                                peg$currPos += 2;
                                                              } else {
                                                                s1 = peg$FAILED;
                                                                if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                              }
                                                              if (s1 !== peg$FAILED) {
                                                                s2 = peg$parse_();
                                                                if (s2 !== peg$FAILED) {
                                                                  s3 = peg$parseIntegerSlashList();
                                                                  if (s3 !== peg$FAILED) {
                                                                    s4 = peg$parse_();
                                                                    if (s4 !== peg$FAILED) {
                                                                      if (input.substr(peg$currPos, 24) === peg$c1147) {
                                                                        s5 = peg$c1147;
                                                                        peg$currPos += 24;
                                                                      } else {
                                                                        s5 = peg$FAILED;
                                                                        if (peg$silentFails === 0) { peg$fail(peg$c1148); }
                                                                      }
                                                                      if (s5 !== peg$FAILED) {
                                                                        peg$savedPos = s0;
                                                                        s1 = peg$c1149(s3);
                                                                        s0 = s1;
                                                                      } else {
                                                                        peg$currPos = s0;
                                                                        s0 = peg$FAILED;
                                                                      }
                                                                    } else {
                                                                      peg$currPos = s0;
                                                                      s0 = peg$FAILED;
                                                                    }
                                                                  } else {
                                                                    peg$currPos = s0;
                                                                    s0 = peg$FAILED;
                                                                  }
                                                                } else {
                                                                  peg$currPos = s0;
                                                                  s0 = peg$FAILED;
                                                                }
                                                              } else {
                                                                peg$currPos = s0;
                                                                s0 = peg$FAILED;
                                                              }
                                                              if (s0 === peg$FAILED) {
                                                                s0 = peg$currPos;
                                                                if (input.substr(peg$currPos, 2) === peg$c693) {
                                                                  s1 = peg$c693;
                                                                  peg$currPos += 2;
                                                                } else {
                                                                  s1 = peg$FAILED;
                                                                  if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                                }
                                                                if (s1 !== peg$FAILED) {
                                                                  s2 = peg$parse_();
                                                                  if (s2 !== peg$FAILED) {
                                                                    s3 = peg$parseIntegerSlashList();
                                                                    if (s3 !== peg$FAILED) {
                                                                      if (input.charCodeAt(peg$currPos) === 43) {
                                                                        s4 = peg$c19;
                                                                        peg$currPos++;
                                                                      } else {
                                                                        s4 = peg$FAILED;
                                                                        if (peg$silentFails === 0) { peg$fail(peg$c20); }
                                                                      }
                                                                      if (s4 === peg$FAILED) {
                                                                        s4 = null;
                                                                      }
                                                                      if (s4 !== peg$FAILED) {
                                                                        s5 = peg$parse_();
                                                                        if (s5 !== peg$FAILED) {
                                                                          if (input.substr(peg$currPos, 7) === peg$c1150) {
                                                                            s6 = peg$c1150;
                                                                            peg$currPos += 7;
                                                                          } else {
                                                                            s6 = peg$FAILED;
                                                                            if (peg$silentFails === 0) { peg$fail(peg$c1151); }
                                                                          }
                                                                          if (s6 === peg$FAILED) {
                                                                            if (input.substr(peg$currPos, 13) === peg$c1152) {
                                                                              s6 = peg$c1152;
                                                                              peg$currPos += 13;
                                                                            } else {
                                                                              s6 = peg$FAILED;
                                                                              if (peg$silentFails === 0) { peg$fail(peg$c1153); }
                                                                            }
                                                                          }
                                                                          if (s6 !== peg$FAILED) {
                                                                            s7 = peg$parse_();
                                                                            if (s7 !== peg$FAILED) {
                                                                              if (input.substr(peg$currPos, 9) === peg$c1154) {
                                                                                s8 = peg$c1154;
                                                                                peg$currPos += 9;
                                                                              } else {
                                                                                s8 = peg$FAILED;
                                                                                if (peg$silentFails === 0) { peg$fail(peg$c1155); }
                                                                              }
                                                                              if (s8 !== peg$FAILED) {
                                                                                peg$savedPos = s0;
                                                                                s1 = peg$c1156(s3);
                                                                                s0 = s1;
                                                                              } else {
                                                                                peg$currPos = s0;
                                                                                s0 = peg$FAILED;
                                                                              }
                                                                            } else {
                                                                              peg$currPos = s0;
                                                                              s0 = peg$FAILED;
                                                                            }
                                                                          } else {
                                                                            peg$currPos = s0;
                                                                            s0 = peg$FAILED;
                                                                          }
                                                                        } else {
                                                                          peg$currPos = s0;
                                                                          s0 = peg$FAILED;
                                                                        }
                                                                      } else {
                                                                        peg$currPos = s0;
                                                                        s0 = peg$FAILED;
                                                                      }
                                                                    } else {
                                                                      peg$currPos = s0;
                                                                      s0 = peg$FAILED;
                                                                    }
                                                                  } else {
                                                                    peg$currPos = s0;
                                                                    s0 = peg$FAILED;
                                                                  }
                                                                } else {
                                                                  peg$currPos = s0;
                                                                  s0 = peg$FAILED;
                                                                }
                                                                if (s0 === peg$FAILED) {
                                                                  s0 = peg$currPos;
                                                                  if (input.substr(peg$currPos, 2) === peg$c693) {
                                                                    s1 = peg$c693;
                                                                    peg$currPos += 2;
                                                                  } else {
                                                                    s1 = peg$FAILED;
                                                                    if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                                  }
                                                                  if (s1 !== peg$FAILED) {
                                                                    s2 = peg$parse_();
                                                                    if (s2 !== peg$FAILED) {
                                                                      s3 = peg$parseInteger();
                                                                      if (s3 !== peg$FAILED) {
                                                                        s4 = peg$parse_();
                                                                        if (s4 !== peg$FAILED) {
                                                                          if (input.substr(peg$currPos, 31) === peg$c1157) {
                                                                            s5 = peg$c1157;
                                                                            peg$currPos += 31;
                                                                          } else {
                                                                            s5 = peg$FAILED;
                                                                            if (peg$silentFails === 0) { peg$fail(peg$c1158); }
                                                                          }
                                                                          if (s5 !== peg$FAILED) {
                                                                            peg$savedPos = s0;
                                                                            s1 = peg$c1159(s3);
                                                                            s0 = s1;
                                                                          } else {
                                                                            peg$currPos = s0;
                                                                            s0 = peg$FAILED;
                                                                          }
                                                                        } else {
                                                                          peg$currPos = s0;
                                                                          s0 = peg$FAILED;
                                                                        }
                                                                      } else {
                                                                        peg$currPos = s0;
                                                                        s0 = peg$FAILED;
                                                                      }
                                                                    } else {
                                                                      peg$currPos = s0;
                                                                      s0 = peg$FAILED;
                                                                    }
                                                                  } else {
                                                                    peg$currPos = s0;
                                                                    s0 = peg$FAILED;
                                                                  }
                                                                  if (s0 === peg$FAILED) {
                                                                    s0 = peg$currPos;
                                                                    if (input.substr(peg$currPos, 2) === peg$c693) {
                                                                      s1 = peg$c693;
                                                                      peg$currPos += 2;
                                                                    } else {
                                                                      s1 = peg$FAILED;
                                                                      if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                                    }
                                                                    if (s1 !== peg$FAILED) {
                                                                      s2 = peg$parse_();
                                                                      if (s2 !== peg$FAILED) {
                                                                        if (input.substr(peg$currPos, 5) === peg$c1160) {
                                                                          s3 = peg$c1160;
                                                                          peg$currPos += 5;
                                                                        } else {
                                                                          s3 = peg$FAILED;
                                                                          if (peg$silentFails === 0) { peg$fail(peg$c1161); }
                                                                        }
                                                                        if (s3 !== peg$FAILED) {
                                                                          s4 = peg$parse_();
                                                                          if (s4 !== peg$FAILED) {
                                                                            if (input.substr(peg$currPos, 3) === peg$c1125) {
                                                                              s5 = peg$c1125;
                                                                              peg$currPos += 3;
                                                                            } else {
                                                                              s5 = peg$FAILED;
                                                                              if (peg$silentFails === 0) { peg$fail(peg$c1126); }
                                                                            }
                                                                            if (s5 !== peg$FAILED) {
                                                                              s6 = peg$parse_();
                                                                              if (s6 !== peg$FAILED) {
                                                                                s7 = peg$parseIntegerSlashList();
                                                                                if (s7 !== peg$FAILED) {
                                                                                  if (input.charCodeAt(peg$currPos) === 43) {
                                                                                    s8 = peg$c19;
                                                                                    peg$currPos++;
                                                                                  } else {
                                                                                    s8 = peg$FAILED;
                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c20); }
                                                                                  }
                                                                                  if (s8 === peg$FAILED) {
                                                                                    s8 = null;
                                                                                  }
                                                                                  if (s8 !== peg$FAILED) {
                                                                                    s9 = peg$parse_();
                                                                                    if (s9 !== peg$FAILED) {
                                                                                      s10 = peg$parseRealm();
                                                                                      if (s10 !== peg$FAILED) {
                                                                                        s11 = peg$parse_();
                                                                                        if (s11 !== peg$FAILED) {
                                                                                          if (input.substr(peg$currPos, 10) === peg$c1162) {
                                                                                            s12 = peg$c1162;
                                                                                            peg$currPos += 10;
                                                                                          } else {
                                                                                            s12 = peg$FAILED;
                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c1163); }
                                                                                          }
                                                                                          if (s12 !== peg$FAILED) {
                                                                                            s13 = peg$parse_();
                                                                                            if (s13 !== peg$FAILED) {
                                                                                              if (input.substr(peg$currPos, 2) === peg$c1141) {
                                                                                                s14 = peg$c1141;
                                                                                                peg$currPos += 2;
                                                                                              } else {
                                                                                                s14 = peg$FAILED;
                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c1142); }
                                                                                              }
                                                                                              if (s14 !== peg$FAILED) {
                                                                                                s15 = peg$parse_();
                                                                                                if (s15 !== peg$FAILED) {
                                                                                                  if (input.substr(peg$currPos, 3) === peg$c576) {
                                                                                                    s16 = peg$c576;
                                                                                                    peg$currPos += 3;
                                                                                                  } else {
                                                                                                    s16 = peg$FAILED;
                                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c577); }
                                                                                                  }
                                                                                                  if (s16 !== peg$FAILED) {
                                                                                                    s17 = peg$parse_();
                                                                                                    if (s17 !== peg$FAILED) {
                                                                                                      if (input.substr(peg$currPos, 5) === peg$c1143) {
                                                                                                        s18 = peg$c1143;
                                                                                                        peg$currPos += 5;
                                                                                                      } else {
                                                                                                        s18 = peg$FAILED;
                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c1144); }
                                                                                                      }
                                                                                                      if (s18 !== peg$FAILED) {
                                                                                                        peg$savedPos = s0;
                                                                                                        s1 = peg$c1164(s7, s10);
                                                                                                        s0 = s1;
                                                                                                      } else {
                                                                                                        peg$currPos = s0;
                                                                                                        s0 = peg$FAILED;
                                                                                                      }
                                                                                                    } else {
                                                                                                      peg$currPos = s0;
                                                                                                      s0 = peg$FAILED;
                                                                                                    }
                                                                                                  } else {
                                                                                                    peg$currPos = s0;
                                                                                                    s0 = peg$FAILED;
                                                                                                  }
                                                                                                } else {
                                                                                                  peg$currPos = s0;
                                                                                                  s0 = peg$FAILED;
                                                                                                }
                                                                                              } else {
                                                                                                peg$currPos = s0;
                                                                                                s0 = peg$FAILED;
                                                                                              }
                                                                                            } else {
                                                                                              peg$currPos = s0;
                                                                                              s0 = peg$FAILED;
                                                                                            }
                                                                                          } else {
                                                                                            peg$currPos = s0;
                                                                                            s0 = peg$FAILED;
                                                                                          }
                                                                                        } else {
                                                                                          peg$currPos = s0;
                                                                                          s0 = peg$FAILED;
                                                                                        }
                                                                                      } else {
                                                                                        peg$currPos = s0;
                                                                                        s0 = peg$FAILED;
                                                                                      }
                                                                                    } else {
                                                                                      peg$currPos = s0;
                                                                                      s0 = peg$FAILED;
                                                                                    }
                                                                                  } else {
                                                                                    peg$currPos = s0;
                                                                                    s0 = peg$FAILED;
                                                                                  }
                                                                                } else {
                                                                                  peg$currPos = s0;
                                                                                  s0 = peg$FAILED;
                                                                                }
                                                                              } else {
                                                                                peg$currPos = s0;
                                                                                s0 = peg$FAILED;
                                                                              }
                                                                            } else {
                                                                              peg$currPos = s0;
                                                                              s0 = peg$FAILED;
                                                                            }
                                                                          } else {
                                                                            peg$currPos = s0;
                                                                            s0 = peg$FAILED;
                                                                          }
                                                                        } else {
                                                                          peg$currPos = s0;
                                                                          s0 = peg$FAILED;
                                                                        }
                                                                      } else {
                                                                        peg$currPos = s0;
                                                                        s0 = peg$FAILED;
                                                                      }
                                                                    } else {
                                                                      peg$currPos = s0;
                                                                      s0 = peg$FAILED;
                                                                    }
                                                                    if (s0 === peg$FAILED) {
                                                                      s0 = peg$currPos;
                                                                      if (input.substr(peg$currPos, 2) === peg$c693) {
                                                                        s1 = peg$c693;
                                                                        peg$currPos += 2;
                                                                      } else {
                                                                        s1 = peg$FAILED;
                                                                        if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                                      }
                                                                      if (s1 !== peg$FAILED) {
                                                                        s2 = peg$parse_();
                                                                        if (s2 !== peg$FAILED) {
                                                                          s3 = peg$parseIntegerRangeSlashList();
                                                                          if (s3 !== peg$FAILED) {
                                                                            if (input.charCodeAt(peg$currPos) === 43) {
                                                                              s4 = peg$c19;
                                                                              peg$currPos++;
                                                                            } else {
                                                                              s4 = peg$FAILED;
                                                                              if (peg$silentFails === 0) { peg$fail(peg$c20); }
                                                                            }
                                                                            if (s4 === peg$FAILED) {
                                                                              s4 = null;
                                                                            }
                                                                            if (s4 !== peg$FAILED) {
                                                                              s5 = peg$parse_();
                                                                              if (s5 !== peg$FAILED) {
                                                                                s6 = peg$parseRealm();
                                                                                if (s6 !== peg$FAILED) {
                                                                                  s7 = peg$parse_();
                                                                                  if (s7 !== peg$FAILED) {
                                                                                    if (input.substr(peg$currPos, 20) === peg$c1165) {
                                                                                      s8 = peg$c1165;
                                                                                      peg$currPos += 20;
                                                                                    } else {
                                                                                      s8 = peg$FAILED;
                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c1166); }
                                                                                    }
                                                                                    if (s8 === peg$FAILED) {
                                                                                      if (input.substr(peg$currPos, 18) === peg$c1167) {
                                                                                        s8 = peg$c1167;
                                                                                        peg$currPos += 18;
                                                                                      } else {
                                                                                        s8 = peg$FAILED;
                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c1168); }
                                                                                      }
                                                                                      if (s8 === peg$FAILED) {
                                                                                        if (input.substr(peg$currPos, 16) === peg$c1169) {
                                                                                          s8 = peg$c1169;
                                                                                          peg$currPos += 16;
                                                                                        } else {
                                                                                          s8 = peg$FAILED;
                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c1170); }
                                                                                        }
                                                                                        if (s8 === peg$FAILED) {
                                                                                          if (input.substr(peg$currPos, 17) === peg$c1171) {
                                                                                            s8 = peg$c1171;
                                                                                            peg$currPos += 17;
                                                                                          } else {
                                                                                            s8 = peg$FAILED;
                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c1172); }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                    if (s8 !== peg$FAILED) {
                                                                                      peg$savedPos = s0;
                                                                                      s1 = peg$c1173(s3, s4, s6);
                                                                                      s0 = s1;
                                                                                    } else {
                                                                                      peg$currPos = s0;
                                                                                      s0 = peg$FAILED;
                                                                                    }
                                                                                  } else {
                                                                                    peg$currPos = s0;
                                                                                    s0 = peg$FAILED;
                                                                                  }
                                                                                } else {
                                                                                  peg$currPos = s0;
                                                                                  s0 = peg$FAILED;
                                                                                }
                                                                              } else {
                                                                                peg$currPos = s0;
                                                                                s0 = peg$FAILED;
                                                                              }
                                                                            } else {
                                                                              peg$currPos = s0;
                                                                              s0 = peg$FAILED;
                                                                            }
                                                                          } else {
                                                                            peg$currPos = s0;
                                                                            s0 = peg$FAILED;
                                                                          }
                                                                        } else {
                                                                          peg$currPos = s0;
                                                                          s0 = peg$FAILED;
                                                                        }
                                                                      } else {
                                                                        peg$currPos = s0;
                                                                        s0 = peg$FAILED;
                                                                      }
                                                                      if (s0 === peg$FAILED) {
                                                                        s0 = peg$currPos;
                                                                        if (input.substr(peg$currPos, 2) === peg$c693) {
                                                                          s1 = peg$c693;
                                                                          peg$currPos += 2;
                                                                        } else {
                                                                          s1 = peg$FAILED;
                                                                          if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                                        }
                                                                        if (s1 !== peg$FAILED) {
                                                                          s2 = peg$parse_();
                                                                          if (s2 !== peg$FAILED) {
                                                                            s3 = peg$parseInteger();
                                                                            if (s3 !== peg$FAILED) {
                                                                              s4 = peg$parse_();
                                                                              if (s4 !== peg$FAILED) {
                                                                                if (input.substr(peg$currPos, 32) === peg$c1174) {
                                                                                  s5 = peg$c1174;
                                                                                  peg$currPos += 32;
                                                                                } else {
                                                                                  s5 = peg$FAILED;
                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c1175); }
                                                                                }
                                                                                if (s5 !== peg$FAILED) {
                                                                                  peg$savedPos = s0;
                                                                                  s1 = peg$c1149(s3);
                                                                                  s0 = s1;
                                                                                } else {
                                                                                  peg$currPos = s0;
                                                                                  s0 = peg$FAILED;
                                                                                }
                                                                              } else {
                                                                                peg$currPos = s0;
                                                                                s0 = peg$FAILED;
                                                                              }
                                                                            } else {
                                                                              peg$currPos = s0;
                                                                              s0 = peg$FAILED;
                                                                            }
                                                                          } else {
                                                                            peg$currPos = s0;
                                                                            s0 = peg$FAILED;
                                                                          }
                                                                        } else {
                                                                          peg$currPos = s0;
                                                                          s0 = peg$FAILED;
                                                                        }
                                                                        if (s0 === peg$FAILED) {
                                                                          s0 = peg$currPos;
                                                                          if (input.substr(peg$currPos, 2) === peg$c693) {
                                                                            s1 = peg$c693;
                                                                            peg$currPos += 2;
                                                                          } else {
                                                                            s1 = peg$FAILED;
                                                                            if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                                          }
                                                                          if (s1 !== peg$FAILED) {
                                                                            s2 = peg$parse_();
                                                                            if (s2 !== peg$FAILED) {
                                                                              s3 = peg$parseIntegerSlashList();
                                                                              if (s3 !== peg$FAILED) {
                                                                                if (input.charCodeAt(peg$currPos) === 43) {
                                                                                  s4 = peg$c19;
                                                                                  peg$currPos++;
                                                                                } else {
                                                                                  s4 = peg$FAILED;
                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c20); }
                                                                                }
                                                                                if (s4 === peg$FAILED) {
                                                                                  s4 = null;
                                                                                }
                                                                                if (s4 !== peg$FAILED) {
                                                                                  s5 = peg$parse_();
                                                                                  if (s5 !== peg$FAILED) {
                                                                                    if (input.substr(peg$currPos, 13) === peg$c1176) {
                                                                                      s6 = peg$c1176;
                                                                                      peg$currPos += 13;
                                                                                    } else {
                                                                                      s6 = peg$FAILED;
                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c1177); }
                                                                                    }
                                                                                    if (s6 === peg$FAILED) {
                                                                                      if (input.substr(peg$currPos, 6) === peg$c523) {
                                                                                        s6 = peg$c523;
                                                                                        peg$currPos += 6;
                                                                                      } else {
                                                                                        s6 = peg$FAILED;
                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c524); }
                                                                                      }
                                                                                    }
                                                                                    if (s6 !== peg$FAILED) {
                                                                                      s7 = peg$parse_();
                                                                                      if (s7 !== peg$FAILED) {
                                                                                        if (input.substr(peg$currPos, 9) === peg$c1154) {
                                                                                          s8 = peg$c1154;
                                                                                          peg$currPos += 9;
                                                                                        } else {
                                                                                          s8 = peg$FAILED;
                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c1155); }
                                                                                        }
                                                                                        if (s8 !== peg$FAILED) {
                                                                                          peg$savedPos = s0;
                                                                                          s1 = peg$c1178(s3);
                                                                                          s0 = s1;
                                                                                        } else {
                                                                                          peg$currPos = s0;
                                                                                          s0 = peg$FAILED;
                                                                                        }
                                                                                      } else {
                                                                                        peg$currPos = s0;
                                                                                        s0 = peg$FAILED;
                                                                                      }
                                                                                    } else {
                                                                                      peg$currPos = s0;
                                                                                      s0 = peg$FAILED;
                                                                                    }
                                                                                  } else {
                                                                                    peg$currPos = s0;
                                                                                    s0 = peg$FAILED;
                                                                                  }
                                                                                } else {
                                                                                  peg$currPos = s0;
                                                                                  s0 = peg$FAILED;
                                                                                }
                                                                              } else {
                                                                                peg$currPos = s0;
                                                                                s0 = peg$FAILED;
                                                                              }
                                                                            } else {
                                                                              peg$currPos = s0;
                                                                              s0 = peg$FAILED;
                                                                            }
                                                                          } else {
                                                                            peg$currPos = s0;
                                                                            s0 = peg$FAILED;
                                                                          }
                                                                          if (s0 === peg$FAILED) {
                                                                            s0 = peg$currPos;
                                                                            if (input.substr(peg$currPos, 2) === peg$c693) {
                                                                              s1 = peg$c693;
                                                                              peg$currPos += 2;
                                                                            } else {
                                                                              s1 = peg$FAILED;
                                                                              if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                                            }
                                                                            if (s1 !== peg$FAILED) {
                                                                              s2 = peg$parse_();
                                                                              if (s2 !== peg$FAILED) {
                                                                                s3 = peg$parseIntegerSlashList();
                                                                                if (s3 !== peg$FAILED) {
                                                                                  s4 = peg$parse_();
                                                                                  if (s4 !== peg$FAILED) {
                                                                                    if (input.substr(peg$currPos, 6) === peg$c523) {
                                                                                      s5 = peg$c523;
                                                                                      peg$currPos += 6;
                                                                                    } else {
                                                                                      s5 = peg$FAILED;
                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c524); }
                                                                                    }
                                                                                    if (s5 !== peg$FAILED) {
                                                                                      s6 = peg$parse_();
                                                                                      if (s6 !== peg$FAILED) {
                                                                                        if (input.substr(peg$currPos, 2) === peg$c1141) {
                                                                                          s7 = peg$c1141;
                                                                                          peg$currPos += 2;
                                                                                        } else {
                                                                                          s7 = peg$FAILED;
                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c1142); }
                                                                                        }
                                                                                        if (s7 !== peg$FAILED) {
                                                                                          s8 = peg$parse_();
                                                                                          if (s8 !== peg$FAILED) {
                                                                                            if (input.substr(peg$currPos, 3) === peg$c1179) {
                                                                                              s9 = peg$c1179;
                                                                                              peg$currPos += 3;
                                                                                            } else {
                                                                                              s9 = peg$FAILED;
                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c1180); }
                                                                                            }
                                                                                            if (s9 !== peg$FAILED) {
                                                                                              peg$savedPos = s0;
                                                                                              s1 = peg$c1181(s3);
                                                                                              s0 = s1;
                                                                                            } else {
                                                                                              peg$currPos = s0;
                                                                                              s0 = peg$FAILED;
                                                                                            }
                                                                                          } else {
                                                                                            peg$currPos = s0;
                                                                                            s0 = peg$FAILED;
                                                                                          }
                                                                                        } else {
                                                                                          peg$currPos = s0;
                                                                                          s0 = peg$FAILED;
                                                                                        }
                                                                                      } else {
                                                                                        peg$currPos = s0;
                                                                                        s0 = peg$FAILED;
                                                                                      }
                                                                                    } else {
                                                                                      peg$currPos = s0;
                                                                                      s0 = peg$FAILED;
                                                                                    }
                                                                                  } else {
                                                                                    peg$currPos = s0;
                                                                                    s0 = peg$FAILED;
                                                                                  }
                                                                                } else {
                                                                                  peg$currPos = s0;
                                                                                  s0 = peg$FAILED;
                                                                                }
                                                                              } else {
                                                                                peg$currPos = s0;
                                                                                s0 = peg$FAILED;
                                                                              }
                                                                            } else {
                                                                              peg$currPos = s0;
                                                                              s0 = peg$FAILED;
                                                                            }
                                                                            if (s0 === peg$FAILED) {
                                                                              s0 = peg$currPos;
                                                                              if (input.substr(peg$currPos, 2) === peg$c693) {
                                                                                s1 = peg$c693;
                                                                                peg$currPos += 2;
                                                                              } else {
                                                                                s1 = peg$FAILED;
                                                                                if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                                              }
                                                                              if (s1 !== peg$FAILED) {
                                                                                s2 = peg$parse_();
                                                                                if (s2 !== peg$FAILED) {
                                                                                  if (input.substr(peg$currPos, 3) === peg$c576) {
                                                                                    s3 = peg$c576;
                                                                                    peg$currPos += 3;
                                                                                  } else {
                                                                                    s3 = peg$FAILED;
                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c577); }
                                                                                  }
                                                                                  if (s3 !== peg$FAILED) {
                                                                                    s4 = peg$parse_();
                                                                                    if (s4 !== peg$FAILED) {
                                                                                      if (input.substr(peg$currPos, 6) === peg$c1182) {
                                                                                        s5 = peg$c1182;
                                                                                        peg$currPos += 6;
                                                                                      } else {
                                                                                        s5 = peg$FAILED;
                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c1183); }
                                                                                      }
                                                                                      if (s5 !== peg$FAILED) {
                                                                                        s6 = peg$parse_();
                                                                                        if (s6 !== peg$FAILED) {
                                                                                          if (input.substr(peg$currPos, 6) === peg$c1086) {
                                                                                            s7 = peg$c1086;
                                                                                            peg$currPos += 6;
                                                                                          } else {
                                                                                            s7 = peg$FAILED;
                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c1087); }
                                                                                          }
                                                                                          if (s7 === peg$FAILED) {
                                                                                            if (input.substr(peg$currPos, 4) === peg$c1088) {
                                                                                              s7 = peg$c1088;
                                                                                              peg$currPos += 4;
                                                                                            } else {
                                                                                              s7 = peg$FAILED;
                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c1089); }
                                                                                            }
                                                                                          }
                                                                                          if (s7 !== peg$FAILED) {
                                                                                            s8 = peg$parse_();
                                                                                            if (s8 !== peg$FAILED) {
                                                                                              if (input.substr(peg$currPos, 5) === peg$c1184) {
                                                                                                s9 = peg$c1184;
                                                                                                peg$currPos += 5;
                                                                                              } else {
                                                                                                s9 = peg$FAILED;
                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c1185); }
                                                                                              }
                                                                                              if (s9 !== peg$FAILED) {
                                                                                                s10 = peg$parse_();
                                                                                                if (s10 !== peg$FAILED) {
                                                                                                  if (input.substr(peg$currPos, 2) === peg$c1130) {
                                                                                                    s11 = peg$c1130;
                                                                                                    peg$currPos += 2;
                                                                                                  } else {
                                                                                                    s11 = peg$FAILED;
                                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c1131); }
                                                                                                  }
                                                                                                  if (s11 !== peg$FAILED) {
                                                                                                    s12 = peg$parse_();
                                                                                                    if (s12 !== peg$FAILED) {
                                                                                                      if (input.substr(peg$currPos, 5) === peg$c935) {
                                                                                                        s13 = peg$c935;
                                                                                                        peg$currPos += 5;
                                                                                                      } else {
                                                                                                        s13 = peg$FAILED;
                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c936); }
                                                                                                      }
                                                                                                      if (s13 !== peg$FAILED) {
                                                                                                        s14 = peg$parse_();
                                                                                                        if (s14 !== peg$FAILED) {
                                                                                                          s15 = peg$parseIntegerSlashList();
                                                                                                          if (s15 !== peg$FAILED) {
                                                                                                            peg$savedPos = s0;
                                                                                                            s1 = peg$c1186(s15);
                                                                                                            s0 = s1;
                                                                                                          } else {
                                                                                                            peg$currPos = s0;
                                                                                                            s0 = peg$FAILED;
                                                                                                          }
                                                                                                        } else {
                                                                                                          peg$currPos = s0;
                                                                                                          s0 = peg$FAILED;
                                                                                                        }
                                                                                                      } else {
                                                                                                        peg$currPos = s0;
                                                                                                        s0 = peg$FAILED;
                                                                                                      }
                                                                                                    } else {
                                                                                                      peg$currPos = s0;
                                                                                                      s0 = peg$FAILED;
                                                                                                    }
                                                                                                  } else {
                                                                                                    peg$currPos = s0;
                                                                                                    s0 = peg$FAILED;
                                                                                                  }
                                                                                                } else {
                                                                                                  peg$currPos = s0;
                                                                                                  s0 = peg$FAILED;
                                                                                                }
                                                                                              } else {
                                                                                                peg$currPos = s0;
                                                                                                s0 = peg$FAILED;
                                                                                              }
                                                                                            } else {
                                                                                              peg$currPos = s0;
                                                                                              s0 = peg$FAILED;
                                                                                            }
                                                                                          } else {
                                                                                            peg$currPos = s0;
                                                                                            s0 = peg$FAILED;
                                                                                          }
                                                                                        } else {
                                                                                          peg$currPos = s0;
                                                                                          s0 = peg$FAILED;
                                                                                        }
                                                                                      } else {
                                                                                        peg$currPos = s0;
                                                                                        s0 = peg$FAILED;
                                                                                      }
                                                                                    } else {
                                                                                      peg$currPos = s0;
                                                                                      s0 = peg$FAILED;
                                                                                    }
                                                                                  } else {
                                                                                    peg$currPos = s0;
                                                                                    s0 = peg$FAILED;
                                                                                  }
                                                                                } else {
                                                                                  peg$currPos = s0;
                                                                                  s0 = peg$FAILED;
                                                                                }
                                                                              } else {
                                                                                peg$currPos = s0;
                                                                                s0 = peg$FAILED;
                                                                              }
                                                                              if (s0 === peg$FAILED) {
                                                                                s0 = peg$currPos;
                                                                                if (input.substr(peg$currPos, 2) === peg$c693) {
                                                                                  s1 = peg$c693;
                                                                                  peg$currPos += 2;
                                                                                } else {
                                                                                  s1 = peg$FAILED;
                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                                                }
                                                                                if (s1 !== peg$FAILED) {
                                                                                  s2 = peg$parse_();
                                                                                  if (s2 !== peg$FAILED) {
                                                                                    if (input.substr(peg$currPos, 3) === peg$c576) {
                                                                                      s3 = peg$c576;
                                                                                      peg$currPos += 3;
                                                                                    } else {
                                                                                      s3 = peg$FAILED;
                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c577); }
                                                                                    }
                                                                                    if (s3 !== peg$FAILED) {
                                                                                      s4 = peg$parse_();
                                                                                      if (s4 !== peg$FAILED) {
                                                                                        if (input.substr(peg$currPos, 6) === peg$c1182) {
                                                                                          s5 = peg$c1182;
                                                                                          peg$currPos += 6;
                                                                                        } else {
                                                                                          s5 = peg$FAILED;
                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c1183); }
                                                                                        }
                                                                                        if (s5 !== peg$FAILED) {
                                                                                          s6 = peg$parse_();
                                                                                          if (s6 !== peg$FAILED) {
                                                                                            if (input.substr(peg$currPos, 2) === peg$c394) {
                                                                                              s7 = peg$c394;
                                                                                              peg$currPos += 2;
                                                                                            } else {
                                                                                              s7 = peg$FAILED;
                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c395); }
                                                                                            }
                                                                                            if (s7 !== peg$FAILED) {
                                                                                              s8 = peg$parse_();
                                                                                              if (s8 !== peg$FAILED) {
                                                                                                if (input.substr(peg$currPos, 2) === peg$c1130) {
                                                                                                  s9 = peg$c1130;
                                                                                                  peg$currPos += 2;
                                                                                                } else {
                                                                                                  s9 = peg$FAILED;
                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c1131); }
                                                                                                }
                                                                                                if (s9 === peg$FAILED) {
                                                                                                  if (input.substr(peg$currPos, 3) === peg$c1125) {
                                                                                                    s9 = peg$c1125;
                                                                                                    peg$currPos += 3;
                                                                                                  } else {
                                                                                                    s9 = peg$FAILED;
                                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c1126); }
                                                                                                  }
                                                                                                }
                                                                                                if (s9 !== peg$FAILED) {
                                                                                                  s10 = peg$parse_();
                                                                                                  if (s10 !== peg$FAILED) {
                                                                                                    if (input.substr(peg$currPos, 5) === peg$c935) {
                                                                                                      s11 = peg$c935;
                                                                                                      peg$currPos += 5;
                                                                                                    } else {
                                                                                                      s11 = peg$FAILED;
                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c936); }
                                                                                                    }
                                                                                                    if (s11 !== peg$FAILED) {
                                                                                                      s12 = peg$parse_();
                                                                                                      if (s12 !== peg$FAILED) {
                                                                                                        s13 = peg$parseIntegerSlashList();
                                                                                                        if (s13 !== peg$FAILED) {
                                                                                                          if (input.charCodeAt(peg$currPos) === 37) {
                                                                                                            s14 = peg$c12;
                                                                                                            peg$currPos++;
                                                                                                          } else {
                                                                                                            s14 = peg$FAILED;
                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c13); }
                                                                                                          }
                                                                                                          if (s14 !== peg$FAILED) {
                                                                                                            peg$savedPos = s0;
                                                                                                            s1 = peg$c1187(s13);
                                                                                                            s0 = s1;
                                                                                                          } else {
                                                                                                            peg$currPos = s0;
                                                                                                            s0 = peg$FAILED;
                                                                                                          }
                                                                                                        } else {
                                                                                                          peg$currPos = s0;
                                                                                                          s0 = peg$FAILED;
                                                                                                        }
                                                                                                      } else {
                                                                                                        peg$currPos = s0;
                                                                                                        s0 = peg$FAILED;
                                                                                                      }
                                                                                                    } else {
                                                                                                      peg$currPos = s0;
                                                                                                      s0 = peg$FAILED;
                                                                                                    }
                                                                                                  } else {
                                                                                                    peg$currPos = s0;
                                                                                                    s0 = peg$FAILED;
                                                                                                  }
                                                                                                } else {
                                                                                                  peg$currPos = s0;
                                                                                                  s0 = peg$FAILED;
                                                                                                }
                                                                                              } else {
                                                                                                peg$currPos = s0;
                                                                                                s0 = peg$FAILED;
                                                                                              }
                                                                                            } else {
                                                                                              peg$currPos = s0;
                                                                                              s0 = peg$FAILED;
                                                                                            }
                                                                                          } else {
                                                                                            peg$currPos = s0;
                                                                                            s0 = peg$FAILED;
                                                                                          }
                                                                                        } else {
                                                                                          peg$currPos = s0;
                                                                                          s0 = peg$FAILED;
                                                                                        }
                                                                                      } else {
                                                                                        peg$currPos = s0;
                                                                                        s0 = peg$FAILED;
                                                                                      }
                                                                                    } else {
                                                                                      peg$currPos = s0;
                                                                                      s0 = peg$FAILED;
                                                                                    }
                                                                                  } else {
                                                                                    peg$currPos = s0;
                                                                                    s0 = peg$FAILED;
                                                                                  }
                                                                                } else {
                                                                                  peg$currPos = s0;
                                                                                  s0 = peg$FAILED;
                                                                                }
                                                                                if (s0 === peg$FAILED) {
                                                                                  s0 = peg$currPos;
                                                                                  if (input.substr(peg$currPos, 2) === peg$c693) {
                                                                                    s1 = peg$c693;
                                                                                    peg$currPos += 2;
                                                                                  } else {
                                                                                    s1 = peg$FAILED;
                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                                                  }
                                                                                  if (s1 !== peg$FAILED) {
                                                                                    s2 = peg$parse_();
                                                                                    if (s2 !== peg$FAILED) {
                                                                                      if (input.substr(peg$currPos, 3) === peg$c576) {
                                                                                        s3 = peg$c576;
                                                                                        peg$currPos += 3;
                                                                                      } else {
                                                                                        s3 = peg$FAILED;
                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c577); }
                                                                                      }
                                                                                      if (s3 !== peg$FAILED) {
                                                                                        s4 = peg$parse_();
                                                                                        if (s4 !== peg$FAILED) {
                                                                                          if (input.substr(peg$currPos, 6) === peg$c1182) {
                                                                                            s5 = peg$c1182;
                                                                                            peg$currPos += 6;
                                                                                          } else {
                                                                                            s5 = peg$FAILED;
                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c1183); }
                                                                                          }
                                                                                          if (s5 !== peg$FAILED) {
                                                                                            s6 = peg$parse_();
                                                                                            if (s6 !== peg$FAILED) {
                                                                                              if (input.substr(peg$currPos, 2) === peg$c394) {
                                                                                                s7 = peg$c394;
                                                                                                peg$currPos += 2;
                                                                                              } else {
                                                                                                s7 = peg$FAILED;
                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c395); }
                                                                                              }
                                                                                              if (s7 !== peg$FAILED) {
                                                                                                s8 = peg$parse_();
                                                                                                if (s8 !== peg$FAILED) {
                                                                                                  if (input.substr(peg$currPos, 2) === peg$c1130) {
                                                                                                    s9 = peg$c1130;
                                                                                                    peg$currPos += 2;
                                                                                                  } else {
                                                                                                    s9 = peg$FAILED;
                                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c1131); }
                                                                                                  }
                                                                                                  if (s9 === peg$FAILED) {
                                                                                                    if (input.substr(peg$currPos, 3) === peg$c1125) {
                                                                                                      s9 = peg$c1125;
                                                                                                      peg$currPos += 3;
                                                                                                    } else {
                                                                                                      s9 = peg$FAILED;
                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c1126); }
                                                                                                    }
                                                                                                  }
                                                                                                  if (s9 !== peg$FAILED) {
                                                                                                    s10 = peg$parse_();
                                                                                                    if (s10 !== peg$FAILED) {
                                                                                                      if (input.substr(peg$currPos, 2) === peg$c1070) {
                                                                                                        s11 = peg$c1070;
                                                                                                        peg$currPos += 2;
                                                                                                      } else {
                                                                                                        s11 = peg$FAILED;
                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c1071); }
                                                                                                      }
                                                                                                      if (s11 !== peg$FAILED) {
                                                                                                        s12 = peg$parse_();
                                                                                                        if (s12 !== peg$FAILED) {
                                                                                                          if (input.substr(peg$currPos, 5) === peg$c1083) {
                                                                                                            s13 = peg$c1083;
                                                                                                            peg$currPos += 5;
                                                                                                          } else {
                                                                                                            s13 = peg$FAILED;
                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c1084); }
                                                                                                          }
                                                                                                          if (s13 !== peg$FAILED) {
                                                                                                            s14 = peg$parse_();
                                                                                                            if (s14 !== peg$FAILED) {
                                                                                                              s15 = peg$parseIntegerSlashList();
                                                                                                              if (s15 !== peg$FAILED) {
                                                                                                                if (input.charCodeAt(peg$currPos) === 37) {
                                                                                                                  s16 = peg$c12;
                                                                                                                  peg$currPos++;
                                                                                                                } else {
                                                                                                                  s16 = peg$FAILED;
                                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c13); }
                                                                                                                }
                                                                                                                if (s16 !== peg$FAILED) {
                                                                                                                  peg$savedPos = s0;
                                                                                                                  s1 = peg$c1188(s15);
                                                                                                                  s0 = s1;
                                                                                                                } else {
                                                                                                                  peg$currPos = s0;
                                                                                                                  s0 = peg$FAILED;
                                                                                                                }
                                                                                                              } else {
                                                                                                                peg$currPos = s0;
                                                                                                                s0 = peg$FAILED;
                                                                                                              }
                                                                                                            } else {
                                                                                                              peg$currPos = s0;
                                                                                                              s0 = peg$FAILED;
                                                                                                            }
                                                                                                          } else {
                                                                                                            peg$currPos = s0;
                                                                                                            s0 = peg$FAILED;
                                                                                                          }
                                                                                                        } else {
                                                                                                          peg$currPos = s0;
                                                                                                          s0 = peg$FAILED;
                                                                                                        }
                                                                                                      } else {
                                                                                                        peg$currPos = s0;
                                                                                                        s0 = peg$FAILED;
                                                                                                      }
                                                                                                    } else {
                                                                                                      peg$currPos = s0;
                                                                                                      s0 = peg$FAILED;
                                                                                                    }
                                                                                                  } else {
                                                                                                    peg$currPos = s0;
                                                                                                    s0 = peg$FAILED;
                                                                                                  }
                                                                                                } else {
                                                                                                  peg$currPos = s0;
                                                                                                  s0 = peg$FAILED;
                                                                                                }
                                                                                              } else {
                                                                                                peg$currPos = s0;
                                                                                                s0 = peg$FAILED;
                                                                                              }
                                                                                            } else {
                                                                                              peg$currPos = s0;
                                                                                              s0 = peg$FAILED;
                                                                                            }
                                                                                          } else {
                                                                                            peg$currPos = s0;
                                                                                            s0 = peg$FAILED;
                                                                                          }
                                                                                        } else {
                                                                                          peg$currPos = s0;
                                                                                          s0 = peg$FAILED;
                                                                                        }
                                                                                      } else {
                                                                                        peg$currPos = s0;
                                                                                        s0 = peg$FAILED;
                                                                                      }
                                                                                    } else {
                                                                                      peg$currPos = s0;
                                                                                      s0 = peg$FAILED;
                                                                                    }
                                                                                  } else {
                                                                                    peg$currPos = s0;
                                                                                    s0 = peg$FAILED;
                                                                                  }
                                                                                  if (s0 === peg$FAILED) {
                                                                                    s0 = peg$currPos;
                                                                                    if (input.substr(peg$currPos, 2) === peg$c693) {
                                                                                      s1 = peg$c693;
                                                                                      peg$currPos += 2;
                                                                                    } else {
                                                                                      s1 = peg$FAILED;
                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                                                    }
                                                                                    if (s1 !== peg$FAILED) {
                                                                                      s2 = peg$parse_();
                                                                                      if (s2 !== peg$FAILED) {
                                                                                        if (input.substr(peg$currPos, 3) === peg$c576) {
                                                                                          s3 = peg$c576;
                                                                                          peg$currPos += 3;
                                                                                        } else {
                                                                                          s3 = peg$FAILED;
                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c577); }
                                                                                        }
                                                                                        if (s3 === peg$FAILED) {
                                                                                          s3 = null;
                                                                                        }
                                                                                        if (s3 !== peg$FAILED) {
                                                                                          s4 = peg$parse_();
                                                                                          if (s4 !== peg$FAILED) {
                                                                                            if (input.substr(peg$currPos, 4) === peg$c578) {
                                                                                              s5 = peg$c578;
                                                                                              peg$currPos += 4;
                                                                                            } else {
                                                                                              s5 = peg$FAILED;
                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c579); }
                                                                                            }
                                                                                            if (s5 !== peg$FAILED) {
                                                                                              s6 = peg$parse_();
                                                                                              if (s6 !== peg$FAILED) {
                                                                                                if (input.substr(peg$currPos, 3) === peg$c1075) {
                                                                                                  s7 = peg$c1075;
                                                                                                  peg$currPos += 3;
                                                                                                } else {
                                                                                                  s7 = peg$FAILED;
                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c1076); }
                                                                                                }
                                                                                                if (s7 !== peg$FAILED) {
                                                                                                  s8 = peg$parse_();
                                                                                                  if (s8 !== peg$FAILED) {
                                                                                                    s9 = peg$parseIntegerSlashListOrRange();
                                                                                                    if (s9 !== peg$FAILED) {
                                                                                                      if (input.charCodeAt(peg$currPos) === 43) {
                                                                                                        s10 = peg$c19;
                                                                                                        peg$currPos++;
                                                                                                      } else {
                                                                                                        s10 = peg$FAILED;
                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c20); }
                                                                                                      }
                                                                                                      if (s10 === peg$FAILED) {
                                                                                                        s10 = null;
                                                                                                      }
                                                                                                      if (s10 !== peg$FAILED) {
                                                                                                        s11 = peg$parse_();
                                                                                                        if (s11 !== peg$FAILED) {
                                                                                                          s12 = peg$parseSB();
                                                                                                          if (s12 !== peg$FAILED) {
                                                                                                            s13 = peg$parse_();
                                                                                                            if (s13 !== peg$FAILED) {
                                                                                                              if (input.substr(peg$currPos, 6) === peg$c1189) {
                                                                                                                s14 = peg$c1189;
                                                                                                                peg$currPos += 6;
                                                                                                              } else {
                                                                                                                s14 = peg$FAILED;
                                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c1190); }
                                                                                                              }
                                                                                                              if (s14 !== peg$FAILED) {
                                                                                                                peg$savedPos = s0;
                                                                                                                s1 = peg$c1191(s9, s10);
                                                                                                                s0 = s1;
                                                                                                              } else {
                                                                                                                peg$currPos = s0;
                                                                                                                s0 = peg$FAILED;
                                                                                                              }
                                                                                                            } else {
                                                                                                              peg$currPos = s0;
                                                                                                              s0 = peg$FAILED;
                                                                                                            }
                                                                                                          } else {
                                                                                                            peg$currPos = s0;
                                                                                                            s0 = peg$FAILED;
                                                                                                          }
                                                                                                        } else {
                                                                                                          peg$currPos = s0;
                                                                                                          s0 = peg$FAILED;
                                                                                                        }
                                                                                                      } else {
                                                                                                        peg$currPos = s0;
                                                                                                        s0 = peg$FAILED;
                                                                                                      }
                                                                                                    } else {
                                                                                                      peg$currPos = s0;
                                                                                                      s0 = peg$FAILED;
                                                                                                    }
                                                                                                  } else {
                                                                                                    peg$currPos = s0;
                                                                                                    s0 = peg$FAILED;
                                                                                                  }
                                                                                                } else {
                                                                                                  peg$currPos = s0;
                                                                                                  s0 = peg$FAILED;
                                                                                                }
                                                                                              } else {
                                                                                                peg$currPos = s0;
                                                                                                s0 = peg$FAILED;
                                                                                              }
                                                                                            } else {
                                                                                              peg$currPos = s0;
                                                                                              s0 = peg$FAILED;
                                                                                            }
                                                                                          } else {
                                                                                            peg$currPos = s0;
                                                                                            s0 = peg$FAILED;
                                                                                          }
                                                                                        } else {
                                                                                          peg$currPos = s0;
                                                                                          s0 = peg$FAILED;
                                                                                        }
                                                                                      } else {
                                                                                        peg$currPos = s0;
                                                                                        s0 = peg$FAILED;
                                                                                      }
                                                                                    } else {
                                                                                      peg$currPos = s0;
                                                                                      s0 = peg$FAILED;
                                                                                    }
                                                                                    if (s0 === peg$FAILED) {
                                                                                      s0 = peg$currPos;
                                                                                      if (input.substr(peg$currPos, 2) === peg$c693) {
                                                                                        s1 = peg$c693;
                                                                                        peg$currPos += 2;
                                                                                      } else {
                                                                                        s1 = peg$FAILED;
                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                                                      }
                                                                                      if (s1 !== peg$FAILED) {
                                                                                        s2 = peg$parse_();
                                                                                        if (s2 !== peg$FAILED) {
                                                                                          s3 = peg$parseIntegerSlashList();
                                                                                          if (s3 !== peg$FAILED) {
                                                                                            s4 = peg$parse_();
                                                                                            if (s4 !== peg$FAILED) {
                                                                                              if (input.substr(peg$currPos, 33) === peg$c1192) {
                                                                                                s5 = peg$c1192;
                                                                                                peg$currPos += 33;
                                                                                              } else {
                                                                                                s5 = peg$FAILED;
                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c1193); }
                                                                                              }
                                                                                              if (s5 !== peg$FAILED) {
                                                                                                peg$savedPos = s0;
                                                                                                s1 = peg$c1194(s3);
                                                                                                s0 = s1;
                                                                                              } else {
                                                                                                peg$currPos = s0;
                                                                                                s0 = peg$FAILED;
                                                                                              }
                                                                                            } else {
                                                                                              peg$currPos = s0;
                                                                                              s0 = peg$FAILED;
                                                                                            }
                                                                                          } else {
                                                                                            peg$currPos = s0;
                                                                                            s0 = peg$FAILED;
                                                                                          }
                                                                                        } else {
                                                                                          peg$currPos = s0;
                                                                                          s0 = peg$FAILED;
                                                                                        }
                                                                                      } else {
                                                                                        peg$currPos = s0;
                                                                                        s0 = peg$FAILED;
                                                                                      }
                                                                                      if (s0 === peg$FAILED) {
                                                                                        s0 = peg$currPos;
                                                                                        if (input.substr(peg$currPos, 13) === peg$c1195) {
                                                                                          s1 = peg$c1195;
                                                                                          peg$currPos += 13;
                                                                                        } else {
                                                                                          s1 = peg$FAILED;
                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c1196); }
                                                                                        }
                                                                                        if (s1 !== peg$FAILED) {
                                                                                          s2 = peg$parse_();
                                                                                          if (s2 !== peg$FAILED) {
                                                                                            s3 = peg$parseIntegerSlashList();
                                                                                            if (s3 !== peg$FAILED) {
                                                                                              s4 = peg$parse_();
                                                                                              if (s4 !== peg$FAILED) {
                                                                                                if (input.substr(peg$currPos, 36) === peg$c1197) {
                                                                                                  s5 = peg$c1197;
                                                                                                  peg$currPos += 36;
                                                                                                } else {
                                                                                                  s5 = peg$FAILED;
                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c1198); }
                                                                                                }
                                                                                                if (s5 !== peg$FAILED) {
                                                                                                  peg$savedPos = s0;
                                                                                                  s1 = peg$c1194(s3);
                                                                                                  s0 = s1;
                                                                                                } else {
                                                                                                  peg$currPos = s0;
                                                                                                  s0 = peg$FAILED;
                                                                                                }
                                                                                              } else {
                                                                                                peg$currPos = s0;
                                                                                                s0 = peg$FAILED;
                                                                                              }
                                                                                            } else {
                                                                                              peg$currPos = s0;
                                                                                              s0 = peg$FAILED;
                                                                                            }
                                                                                          } else {
                                                                                            peg$currPos = s0;
                                                                                            s0 = peg$FAILED;
                                                                                          }
                                                                                        } else {
                                                                                          peg$currPos = s0;
                                                                                          s0 = peg$FAILED;
                                                                                        }
                                                                                        if (s0 === peg$FAILED) {
                                                                                          s0 = peg$currPos;
                                                                                          if (input.substr(peg$currPos, 2) === peg$c693) {
                                                                                            s1 = peg$c693;
                                                                                            peg$currPos += 2;
                                                                                          } else {
                                                                                            s1 = peg$FAILED;
                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                                                          }
                                                                                          if (s1 !== peg$FAILED) {
                                                                                            s2 = peg$parse_();
                                                                                            if (s2 !== peg$FAILED) {
                                                                                              if (input.substr(peg$currPos, 3) === peg$c576) {
                                                                                                s3 = peg$c576;
                                                                                                peg$currPos += 3;
                                                                                              } else {
                                                                                                s3 = peg$FAILED;
                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c577); }
                                                                                              }
                                                                                              if (s3 !== peg$FAILED) {
                                                                                                s4 = peg$parse_();
                                                                                                if (s4 !== peg$FAILED) {
                                                                                                  if (input.substr(peg$currPos, 6) === peg$c1093) {
                                                                                                    s5 = peg$c1093;
                                                                                                    peg$currPos += 6;
                                                                                                  } else {
                                                                                                    s5 = peg$FAILED;
                                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c1094); }
                                                                                                  }
                                                                                                  if (s5 !== peg$FAILED) {
                                                                                                    s6 = peg$parse_();
                                                                                                    if (s6 !== peg$FAILED) {
                                                                                                      if (input.substr(peg$currPos, 3) === peg$c1075) {
                                                                                                        s7 = peg$c1075;
                                                                                                        peg$currPos += 3;
                                                                                                      } else {
                                                                                                        s7 = peg$FAILED;
                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c1076); }
                                                                                                      }
                                                                                                      if (s7 !== peg$FAILED) {
                                                                                                        s8 = peg$parse_();
                                                                                                        if (s8 !== peg$FAILED) {
                                                                                                          s9 = peg$parseIntegerSlashList();
                                                                                                          if (s9 !== peg$FAILED) {
                                                                                                            s10 = peg$parse_();
                                                                                                            if (s10 !== peg$FAILED) {
                                                                                                              if (input.substr(peg$currPos, 8) === peg$c1199) {
                                                                                                                s11 = peg$c1199;
                                                                                                                peg$currPos += 8;
                                                                                                              } else {
                                                                                                                s11 = peg$FAILED;
                                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c1200); }
                                                                                                              }
                                                                                                              if (s11 !== peg$FAILED) {
                                                                                                                peg$savedPos = s0;
                                                                                                                s1 = peg$c1201(s9);
                                                                                                                s0 = s1;
                                                                                                              } else {
                                                                                                                peg$currPos = s0;
                                                                                                                s0 = peg$FAILED;
                                                                                                              }
                                                                                                            } else {
                                                                                                              peg$currPos = s0;
                                                                                                              s0 = peg$FAILED;
                                                                                                            }
                                                                                                          } else {
                                                                                                            peg$currPos = s0;
                                                                                                            s0 = peg$FAILED;
                                                                                                          }
                                                                                                        } else {
                                                                                                          peg$currPos = s0;
                                                                                                          s0 = peg$FAILED;
                                                                                                        }
                                                                                                      } else {
                                                                                                        peg$currPos = s0;
                                                                                                        s0 = peg$FAILED;
                                                                                                      }
                                                                                                    } else {
                                                                                                      peg$currPos = s0;
                                                                                                      s0 = peg$FAILED;
                                                                                                    }
                                                                                                  } else {
                                                                                                    peg$currPos = s0;
                                                                                                    s0 = peg$FAILED;
                                                                                                  }
                                                                                                } else {
                                                                                                  peg$currPos = s0;
                                                                                                  s0 = peg$FAILED;
                                                                                                }
                                                                                              } else {
                                                                                                peg$currPos = s0;
                                                                                                s0 = peg$FAILED;
                                                                                              }
                                                                                            } else {
                                                                                              peg$currPos = s0;
                                                                                              s0 = peg$FAILED;
                                                                                            }
                                                                                          } else {
                                                                                            peg$currPos = s0;
                                                                                            s0 = peg$FAILED;
                                                                                          }
                                                                                          if (s0 === peg$FAILED) {
                                                                                            s0 = peg$currPos;
                                                                                            if (input.substr(peg$currPos, 32) === peg$c1202) {
                                                                                              s1 = peg$c1202;
                                                                                              peg$currPos += 32;
                                                                                            } else {
                                                                                              s1 = peg$FAILED;
                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c1203); }
                                                                                            }
                                                                                            if (s1 !== peg$FAILED) {
                                                                                              peg$savedPos = s0;
                                                                                              s1 = peg$c888();
                                                                                            }
                                                                                            s0 = s1;
                                                                                            if (s0 === peg$FAILED) {
                                                                                              s0 = peg$currPos;
                                                                                              if (input.substr(peg$currPos, 13) === peg$c1204) {
                                                                                                s1 = peg$c1204;
                                                                                                peg$currPos += 13;
                                                                                              } else {
                                                                                                s1 = peg$FAILED;
                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c1205); }
                                                                                              }
                                                                                              if (s1 !== peg$FAILED) {
                                                                                                s2 = peg$parse_();
                                                                                                if (s2 !== peg$FAILED) {
                                                                                                  s3 = peg$parseElementList();
                                                                                                  if (s3 !== peg$FAILED) {
                                                                                                    s4 = peg$parse_();
                                                                                                    if (s4 !== peg$FAILED) {
                                                                                                      if (input.substr(peg$currPos, 8) === peg$c1206) {
                                                                                                        s5 = peg$c1206;
                                                                                                        peg$currPos += 8;
                                                                                                      } else {
                                                                                                        s5 = peg$FAILED;
                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c1207); }
                                                                                                      }
                                                                                                      if (s5 !== peg$FAILED) {
                                                                                                        peg$savedPos = s0;
                                                                                                        s1 = peg$c1208(s3);
                                                                                                        s0 = s1;
                                                                                                      } else {
                                                                                                        peg$currPos = s0;
                                                                                                        s0 = peg$FAILED;
                                                                                                      }
                                                                                                    } else {
                                                                                                      peg$currPos = s0;
                                                                                                      s0 = peg$FAILED;
                                                                                                    }
                                                                                                  } else {
                                                                                                    peg$currPos = s0;
                                                                                                    s0 = peg$FAILED;
                                                                                                  }
                                                                                                } else {
                                                                                                  peg$currPos = s0;
                                                                                                  s0 = peg$FAILED;
                                                                                                }
                                                                                              } else {
                                                                                                peg$currPos = s0;
                                                                                                s0 = peg$FAILED;
                                                                                              }
                                                                                              if (s0 === peg$FAILED) {
                                                                                                s0 = peg$currPos;
                                                                                                if (input.substr(peg$currPos, 2) === peg$c693) {
                                                                                                  s1 = peg$c693;
                                                                                                  peg$currPos += 2;
                                                                                                } else {
                                                                                                  s1 = peg$FAILED;
                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                                                                }
                                                                                                if (s1 !== peg$FAILED) {
                                                                                                  s2 = peg$parse_();
                                                                                                  if (s2 !== peg$FAILED) {
                                                                                                    if (input.substr(peg$currPos, 3) === peg$c576) {
                                                                                                      s3 = peg$c576;
                                                                                                      peg$currPos += 3;
                                                                                                    } else {
                                                                                                      s3 = peg$FAILED;
                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c577); }
                                                                                                    }
                                                                                                    if (s3 === peg$FAILED) {
                                                                                                      s3 = null;
                                                                                                    }
                                                                                                    if (s3 !== peg$FAILED) {
                                                                                                      s4 = peg$parse_();
                                                                                                      if (s4 !== peg$FAILED) {
                                                                                                        if (input.substr(peg$currPos, 4) === peg$c578) {
                                                                                                          s5 = peg$c578;
                                                                                                          peg$currPos += 4;
                                                                                                        } else {
                                                                                                          s5 = peg$FAILED;
                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c579); }
                                                                                                        }
                                                                                                        if (s5 !== peg$FAILED) {
                                                                                                          s6 = peg$parse_();
                                                                                                          if (s6 !== peg$FAILED) {
                                                                                                            if (input.substr(peg$currPos, 2) === peg$c1130) {
                                                                                                              s7 = peg$c1130;
                                                                                                              peg$currPos += 2;
                                                                                                            } else {
                                                                                                              s7 = peg$FAILED;
                                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c1131); }
                                                                                                            }
                                                                                                            if (s7 !== peg$FAILED) {
                                                                                                              s8 = peg$parse_();
                                                                                                              if (s8 !== peg$FAILED) {
                                                                                                                if (input.substr(peg$currPos, 2) === peg$c1141) {
                                                                                                                  s9 = peg$c1141;
                                                                                                                  peg$currPos += 2;
                                                                                                                } else {
                                                                                                                  s9 = peg$FAILED;
                                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c1142); }
                                                                                                                }
                                                                                                                if (s9 !== peg$FAILED) {
                                                                                                                  s10 = peg$parse_();
                                                                                                                  if (s10 !== peg$FAILED) {
                                                                                                                    if (input.substr(peg$currPos, 3) === peg$c576) {
                                                                                                                      s11 = peg$c576;
                                                                                                                      peg$currPos += 3;
                                                                                                                    } else {
                                                                                                                      s11 = peg$FAILED;
                                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c577); }
                                                                                                                    }
                                                                                                                    if (s11 === peg$FAILED) {
                                                                                                                      s11 = null;
                                                                                                                    }
                                                                                                                    if (s11 !== peg$FAILED) {
                                                                                                                      s12 = peg$parse_();
                                                                                                                      if (s12 !== peg$FAILED) {
                                                                                                                        if (input.substr(peg$currPos, 5) === peg$c1209) {
                                                                                                                          s13 = peg$c1209;
                                                                                                                          peg$currPos += 5;
                                                                                                                        } else {
                                                                                                                          s13 = peg$FAILED;
                                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c1210); }
                                                                                                                        }
                                                                                                                        if (s13 !== peg$FAILED) {
                                                                                                                          s14 = peg$parse_();
                                                                                                                          if (s14 !== peg$FAILED) {
                                                                                                                            if (input.substr(peg$currPos, 3) === peg$c1211) {
                                                                                                                              s15 = peg$c1211;
                                                                                                                              peg$currPos += 3;
                                                                                                                            } else {
                                                                                                                              s15 = peg$FAILED;
                                                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c1212); }
                                                                                                                            }
                                                                                                                            if (s15 !== peg$FAILED) {
                                                                                                                              peg$savedPos = s0;
                                                                                                                              s1 = peg$c1213();
                                                                                                                              s0 = s1;
                                                                                                                            } else {
                                                                                                                              peg$currPos = s0;
                                                                                                                              s0 = peg$FAILED;
                                                                                                                            }
                                                                                                                          } else {
                                                                                                                            peg$currPos = s0;
                                                                                                                            s0 = peg$FAILED;
                                                                                                                          }
                                                                                                                        } else {
                                                                                                                          peg$currPos = s0;
                                                                                                                          s0 = peg$FAILED;
                                                                                                                        }
                                                                                                                      } else {
                                                                                                                        peg$currPos = s0;
                                                                                                                        s0 = peg$FAILED;
                                                                                                                      }
                                                                                                                    } else {
                                                                                                                      peg$currPos = s0;
                                                                                                                      s0 = peg$FAILED;
                                                                                                                    }
                                                                                                                  } else {
                                                                                                                    peg$currPos = s0;
                                                                                                                    s0 = peg$FAILED;
                                                                                                                  }
                                                                                                                } else {
                                                                                                                  peg$currPos = s0;
                                                                                                                  s0 = peg$FAILED;
                                                                                                                }
                                                                                                              } else {
                                                                                                                peg$currPos = s0;
                                                                                                                s0 = peg$FAILED;
                                                                                                              }
                                                                                                            } else {
                                                                                                              peg$currPos = s0;
                                                                                                              s0 = peg$FAILED;
                                                                                                            }
                                                                                                          } else {
                                                                                                            peg$currPos = s0;
                                                                                                            s0 = peg$FAILED;
                                                                                                          }
                                                                                                        } else {
                                                                                                          peg$currPos = s0;
                                                                                                          s0 = peg$FAILED;
                                                                                                        }
                                                                                                      } else {
                                                                                                        peg$currPos = s0;
                                                                                                        s0 = peg$FAILED;
                                                                                                      }
                                                                                                    } else {
                                                                                                      peg$currPos = s0;
                                                                                                      s0 = peg$FAILED;
                                                                                                    }
                                                                                                  } else {
                                                                                                    peg$currPos = s0;
                                                                                                    s0 = peg$FAILED;
                                                                                                  }
                                                                                                } else {
                                                                                                  peg$currPos = s0;
                                                                                                  s0 = peg$FAILED;
                                                                                                }
                                                                                                if (s0 === peg$FAILED) {
                                                                                                  s0 = peg$currPos;
                                                                                                  if (input.substr(peg$currPos, 2) === peg$c693) {
                                                                                                    s1 = peg$c693;
                                                                                                    peg$currPos += 2;
                                                                                                  } else {
                                                                                                    s1 = peg$FAILED;
                                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                                                                  }
                                                                                                  if (s1 !== peg$FAILED) {
                                                                                                    s2 = peg$parse_();
                                                                                                    if (s2 !== peg$FAILED) {
                                                                                                      if (input.substr(peg$currPos, 3) === peg$c576) {
                                                                                                        s3 = peg$c576;
                                                                                                        peg$currPos += 3;
                                                                                                      } else {
                                                                                                        s3 = peg$FAILED;
                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c577); }
                                                                                                      }
                                                                                                      if (s3 !== peg$FAILED) {
                                                                                                        s4 = peg$parse_();
                                                                                                        if (s4 !== peg$FAILED) {
                                                                                                          if (input.substr(peg$currPos, 4) === peg$c578) {
                                                                                                            s5 = peg$c578;
                                                                                                            peg$currPos += 4;
                                                                                                          } else {
                                                                                                            s5 = peg$FAILED;
                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c579); }
                                                                                                          }
                                                                                                          if (s5 !== peg$FAILED) {
                                                                                                            s6 = peg$parse_();
                                                                                                            if (s6 !== peg$FAILED) {
                                                                                                              if (input.substr(peg$currPos, 4) === peg$c1214) {
                                                                                                                s7 = peg$c1214;
                                                                                                                peg$currPos += 4;
                                                                                                              } else {
                                                                                                                s7 = peg$FAILED;
                                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c1215); }
                                                                                                              }
                                                                                                              if (s7 === peg$FAILED) {
                                                                                                                s7 = peg$currPos;
                                                                                                                if (input.substr(peg$currPos, 3) === peg$c1075) {
                                                                                                                  s8 = peg$c1075;
                                                                                                                  peg$currPos += 3;
                                                                                                                } else {
                                                                                                                  s8 = peg$FAILED;
                                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c1076); }
                                                                                                                }
                                                                                                                if (s8 !== peg$FAILED) {
                                                                                                                  s9 = peg$parse_();
                                                                                                                  if (s9 !== peg$FAILED) {
                                                                                                                    if (input.substr(peg$currPos, 5) === peg$c1216) {
                                                                                                                      s10 = peg$c1216;
                                                                                                                      peg$currPos += 5;
                                                                                                                    } else {
                                                                                                                      s10 = peg$FAILED;
                                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c1217); }
                                                                                                                    }
                                                                                                                    if (s10 !== peg$FAILED) {
                                                                                                                      s8 = [s8, s9, s10];
                                                                                                                      s7 = s8;
                                                                                                                    } else {
                                                                                                                      peg$currPos = s7;
                                                                                                                      s7 = peg$FAILED;
                                                                                                                    }
                                                                                                                  } else {
                                                                                                                    peg$currPos = s7;
                                                                                                                    s7 = peg$FAILED;
                                                                                                                  }
                                                                                                                } else {
                                                                                                                  peg$currPos = s7;
                                                                                                                  s7 = peg$FAILED;
                                                                                                                }
                                                                                                              }
                                                                                                              if (s7 !== peg$FAILED) {
                                                                                                                s8 = peg$parse_();
                                                                                                                if (s8 !== peg$FAILED) {
                                                                                                                  s9 = peg$parseIntegerSlashList();
                                                                                                                  if (s9 !== peg$FAILED) {
                                                                                                                    s10 = peg$parse_();
                                                                                                                    if (s10 !== peg$FAILED) {
                                                                                                                      s11 = peg$parseSkillTypeList();
                                                                                                                      if (s11 !== peg$FAILED) {
                                                                                                                        s12 = peg$parse_();
                                                                                                                        if (s12 !== peg$FAILED) {
                                                                                                                          if (input.substr(peg$currPos, 4) === peg$c1218) {
                                                                                                                            s13 = peg$c1218;
                                                                                                                            peg$currPos += 4;
                                                                                                                          } else {
                                                                                                                            s13 = peg$FAILED;
                                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c1219); }
                                                                                                                          }
                                                                                                                          if (s13 !== peg$FAILED) {
                                                                                                                            peg$savedPos = s0;
                                                                                                                            s1 = peg$c1220(s9, s11);
                                                                                                                            s0 = s1;
                                                                                                                          } else {
                                                                                                                            peg$currPos = s0;
                                                                                                                            s0 = peg$FAILED;
                                                                                                                          }
                                                                                                                        } else {
                                                                                                                          peg$currPos = s0;
                                                                                                                          s0 = peg$FAILED;
                                                                                                                        }
                                                                                                                      } else {
                                                                                                                        peg$currPos = s0;
                                                                                                                        s0 = peg$FAILED;
                                                                                                                      }
                                                                                                                    } else {
                                                                                                                      peg$currPos = s0;
                                                                                                                      s0 = peg$FAILED;
                                                                                                                    }
                                                                                                                  } else {
                                                                                                                    peg$currPos = s0;
                                                                                                                    s0 = peg$FAILED;
                                                                                                                  }
                                                                                                                } else {
                                                                                                                  peg$currPos = s0;
                                                                                                                  s0 = peg$FAILED;
                                                                                                                }
                                                                                                              } else {
                                                                                                                peg$currPos = s0;
                                                                                                                s0 = peg$FAILED;
                                                                                                              }
                                                                                                            } else {
                                                                                                              peg$currPos = s0;
                                                                                                              s0 = peg$FAILED;
                                                                                                            }
                                                                                                          } else {
                                                                                                            peg$currPos = s0;
                                                                                                            s0 = peg$FAILED;
                                                                                                          }
                                                                                                        } else {
                                                                                                          peg$currPos = s0;
                                                                                                          s0 = peg$FAILED;
                                                                                                        }
                                                                                                      } else {
                                                                                                        peg$currPos = s0;
                                                                                                        s0 = peg$FAILED;
                                                                                                      }
                                                                                                    } else {
                                                                                                      peg$currPos = s0;
                                                                                                      s0 = peg$FAILED;
                                                                                                    }
                                                                                                  } else {
                                                                                                    peg$currPos = s0;
                                                                                                    s0 = peg$FAILED;
                                                                                                  }
                                                                                                  if (s0 === peg$FAILED) {
                                                                                                    s0 = peg$currPos;
                                                                                                    if (input.substr(peg$currPos, 2) === peg$c693) {
                                                                                                      s1 = peg$c693;
                                                                                                      peg$currPos += 2;
                                                                                                    } else {
                                                                                                      s1 = peg$FAILED;
                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                                                                    }
                                                                                                    if (s1 !== peg$FAILED) {
                                                                                                      s2 = peg$parse_();
                                                                                                      if (s2 !== peg$FAILED) {
                                                                                                        if (input.substr(peg$currPos, 3) === peg$c576) {
                                                                                                          s3 = peg$c576;
                                                                                                          peg$currPos += 3;
                                                                                                        } else {
                                                                                                          s3 = peg$FAILED;
                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c577); }
                                                                                                        }
                                                                                                        if (s3 !== peg$FAILED) {
                                                                                                          s4 = peg$parse_();
                                                                                                          if (s4 !== peg$FAILED) {
                                                                                                            if (input.substr(peg$currPos, 4) === peg$c578) {
                                                                                                              s5 = peg$c578;
                                                                                                              peg$currPos += 4;
                                                                                                            } else {
                                                                                                              s5 = peg$FAILED;
                                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c579); }
                                                                                                            }
                                                                                                            if (s5 !== peg$FAILED) {
                                                                                                              s6 = peg$parse_();
                                                                                                              if (s6 !== peg$FAILED) {
                                                                                                                if (input.substr(peg$currPos, 4) === peg$c1214) {
                                                                                                                  s7 = peg$c1214;
                                                                                                                  peg$currPos += 4;
                                                                                                                } else {
                                                                                                                  s7 = peg$FAILED;
                                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c1215); }
                                                                                                                }
                                                                                                                if (s7 === peg$FAILED) {
                                                                                                                  s7 = peg$currPos;
                                                                                                                  if (input.substr(peg$currPos, 3) === peg$c1075) {
                                                                                                                    s8 = peg$c1075;
                                                                                                                    peg$currPos += 3;
                                                                                                                  } else {
                                                                                                                    s8 = peg$FAILED;
                                                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c1076); }
                                                                                                                  }
                                                                                                                  if (s8 !== peg$FAILED) {
                                                                                                                    s9 = peg$parse_();
                                                                                                                    if (s9 !== peg$FAILED) {
                                                                                                                      if (input.substr(peg$currPos, 5) === peg$c1216) {
                                                                                                                        s10 = peg$c1216;
                                                                                                                        peg$currPos += 5;
                                                                                                                      } else {
                                                                                                                        s10 = peg$FAILED;
                                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c1217); }
                                                                                                                      }
                                                                                                                      if (s10 !== peg$FAILED) {
                                                                                                                        s8 = [s8, s9, s10];
                                                                                                                        s7 = s8;
                                                                                                                      } else {
                                                                                                                        peg$currPos = s7;
                                                                                                                        s7 = peg$FAILED;
                                                                                                                      }
                                                                                                                    } else {
                                                                                                                      peg$currPos = s7;
                                                                                                                      s7 = peg$FAILED;
                                                                                                                    }
                                                                                                                  } else {
                                                                                                                    peg$currPos = s7;
                                                                                                                    s7 = peg$FAILED;
                                                                                                                  }
                                                                                                                }
                                                                                                                if (s7 !== peg$FAILED) {
                                                                                                                  s8 = peg$parse_();
                                                                                                                  if (s8 !== peg$FAILED) {
                                                                                                                    s9 = peg$parseIntegerSlashList();
                                                                                                                    if (s9 !== peg$FAILED) {
                                                                                                                      s10 = peg$parse_();
                                                                                                                      if (s10 !== peg$FAILED) {
                                                                                                                        if (input.substr(peg$currPos, 7) === peg$c35) {
                                                                                                                          s11 = peg$c35;
                                                                                                                          peg$currPos += 7;
                                                                                                                        } else {
                                                                                                                          s11 = peg$FAILED;
                                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c36); }
                                                                                                                        }
                                                                                                                        if (s11 !== peg$FAILED) {
                                                                                                                          peg$savedPos = s0;
                                                                                                                          s1 = peg$c1221(s9);
                                                                                                                          s0 = s1;
                                                                                                                        } else {
                                                                                                                          peg$currPos = s0;
                                                                                                                          s0 = peg$FAILED;
                                                                                                                        }
                                                                                                                      } else {
                                                                                                                        peg$currPos = s0;
                                                                                                                        s0 = peg$FAILED;
                                                                                                                      }
                                                                                                                    } else {
                                                                                                                      peg$currPos = s0;
                                                                                                                      s0 = peg$FAILED;
                                                                                                                    }
                                                                                                                  } else {
                                                                                                                    peg$currPos = s0;
                                                                                                                    s0 = peg$FAILED;
                                                                                                                  }
                                                                                                                } else {
                                                                                                                  peg$currPos = s0;
                                                                                                                  s0 = peg$FAILED;
                                                                                                                }
                                                                                                              } else {
                                                                                                                peg$currPos = s0;
                                                                                                                s0 = peg$FAILED;
                                                                                                              }
                                                                                                            } else {
                                                                                                              peg$currPos = s0;
                                                                                                              s0 = peg$FAILED;
                                                                                                            }
                                                                                                          } else {
                                                                                                            peg$currPos = s0;
                                                                                                            s0 = peg$FAILED;
                                                                                                          }
                                                                                                        } else {
                                                                                                          peg$currPos = s0;
                                                                                                          s0 = peg$FAILED;
                                                                                                        }
                                                                                                      } else {
                                                                                                        peg$currPos = s0;
                                                                                                        s0 = peg$FAILED;
                                                                                                      }
                                                                                                    } else {
                                                                                                      peg$currPos = s0;
                                                                                                      s0 = peg$FAILED;
                                                                                                    }
                                                                                                    if (s0 === peg$FAILED) {
                                                                                                      s0 = peg$currPos;
                                                                                                      if (input.substr(peg$currPos, 2) === peg$c693) {
                                                                                                        s1 = peg$c693;
                                                                                                        peg$currPos += 2;
                                                                                                      } else {
                                                                                                        s1 = peg$FAILED;
                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                                                                      }
                                                                                                      if (s1 !== peg$FAILED) {
                                                                                                        s2 = peg$parse_();
                                                                                                        if (s2 !== peg$FAILED) {
                                                                                                          if (input.substr(peg$currPos, 3) === peg$c576) {
                                                                                                            s3 = peg$c576;
                                                                                                            peg$currPos += 3;
                                                                                                          } else {
                                                                                                            s3 = peg$FAILED;
                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c577); }
                                                                                                          }
                                                                                                          if (s3 !== peg$FAILED) {
                                                                                                            s4 = peg$parse_();
                                                                                                            if (s4 !== peg$FAILED) {
                                                                                                              if (input.substr(peg$currPos, 4) === peg$c578) {
                                                                                                                s5 = peg$c578;
                                                                                                                peg$currPos += 4;
                                                                                                              } else {
                                                                                                                s5 = peg$FAILED;
                                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c579); }
                                                                                                              }
                                                                                                              if (s5 !== peg$FAILED) {
                                                                                                                s6 = peg$parse_();
                                                                                                                if (s6 !== peg$FAILED) {
                                                                                                                  if (input.substr(peg$currPos, 4) === peg$c733) {
                                                                                                                    s7 = peg$c733;
                                                                                                                    peg$currPos += 4;
                                                                                                                  } else {
                                                                                                                    s7 = peg$FAILED;
                                                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c779); }
                                                                                                                  }
                                                                                                                  if (s7 !== peg$FAILED) {
                                                                                                                    s8 = peg$parse_();
                                                                                                                    if (s8 !== peg$FAILED) {
                                                                                                                      s9 = peg$parseIntegerSlashList();
                                                                                                                      if (s9 !== peg$FAILED) {
                                                                                                                        s10 = peg$parse_();
                                                                                                                        if (s10 !== peg$FAILED) {
                                                                                                                          if (input.substr(peg$currPos, 8) === peg$c29) {
                                                                                                                            s11 = peg$c29;
                                                                                                                            peg$currPos += 8;
                                                                                                                          } else {
                                                                                                                            s11 = peg$FAILED;
                                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c30); }
                                                                                                                          }
                                                                                                                          if (s11 !== peg$FAILED) {
                                                                                                                            s12 = peg$parse_();
                                                                                                                            if (s12 !== peg$FAILED) {
                                                                                                                              if (input.substr(peg$currPos, 7) === peg$c1222) {
                                                                                                                                s13 = peg$c1222;
                                                                                                                                peg$currPos += 7;
                                                                                                                              } else {
                                                                                                                                s13 = peg$FAILED;
                                                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c1223); }
                                                                                                                              }
                                                                                                                              if (s13 !== peg$FAILED) {
                                                                                                                                peg$savedPos = s0;
                                                                                                                                s1 = peg$c1224(s9);
                                                                                                                                s0 = s1;
                                                                                                                              } else {
                                                                                                                                peg$currPos = s0;
                                                                                                                                s0 = peg$FAILED;
                                                                                                                              }
                                                                                                                            } else {
                                                                                                                              peg$currPos = s0;
                                                                                                                              s0 = peg$FAILED;
                                                                                                                            }
                                                                                                                          } else {
                                                                                                                            peg$currPos = s0;
                                                                                                                            s0 = peg$FAILED;
                                                                                                                          }
                                                                                                                        } else {
                                                                                                                          peg$currPos = s0;
                                                                                                                          s0 = peg$FAILED;
                                                                                                                        }
                                                                                                                      } else {
                                                                                                                        peg$currPos = s0;
                                                                                                                        s0 = peg$FAILED;
                                                                                                                      }
                                                                                                                    } else {
                                                                                                                      peg$currPos = s0;
                                                                                                                      s0 = peg$FAILED;
                                                                                                                    }
                                                                                                                  } else {
                                                                                                                    peg$currPos = s0;
                                                                                                                    s0 = peg$FAILED;
                                                                                                                  }
                                                                                                                } else {
                                                                                                                  peg$currPos = s0;
                                                                                                                  s0 = peg$FAILED;
                                                                                                                }
                                                                                                              } else {
                                                                                                                peg$currPos = s0;
                                                                                                                s0 = peg$FAILED;
                                                                                                              }
                                                                                                            } else {
                                                                                                              peg$currPos = s0;
                                                                                                              s0 = peg$FAILED;
                                                                                                            }
                                                                                                          } else {
                                                                                                            peg$currPos = s0;
                                                                                                            s0 = peg$FAILED;
                                                                                                          }
                                                                                                        } else {
                                                                                                          peg$currPos = s0;
                                                                                                          s0 = peg$FAILED;
                                                                                                        }
                                                                                                      } else {
                                                                                                        peg$currPos = s0;
                                                                                                        s0 = peg$FAILED;
                                                                                                      }
                                                                                                      if (s0 === peg$FAILED) {
                                                                                                        s0 = peg$currPos;
                                                                                                        if (input.substr(peg$currPos, 4) === peg$c1090) {
                                                                                                          s1 = peg$c1090;
                                                                                                          peg$currPos += 4;
                                                                                                        } else {
                                                                                                          s1 = peg$FAILED;
                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c1091); }
                                                                                                        }
                                                                                                        if (s1 !== peg$FAILED) {
                                                                                                          s2 = peg$parse_();
                                                                                                          if (s2 !== peg$FAILED) {
                                                                                                            s3 = peg$parseIntegerSlashList();
                                                                                                            if (s3 !== peg$FAILED) {
                                                                                                              s4 = peg$parse_();
                                                                                                              if (s4 !== peg$FAILED) {
                                                                                                                if (input.substr(peg$currPos, 5) === peg$c1225) {
                                                                                                                  s5 = peg$c1225;
                                                                                                                  peg$currPos += 5;
                                                                                                                } else {
                                                                                                                  s5 = peg$FAILED;
                                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c1226); }
                                                                                                                }
                                                                                                                if (s5 !== peg$FAILED) {
                                                                                                                  s6 = peg$parse_();
                                                                                                                  if (s6 !== peg$FAILED) {
                                                                                                                    s7 = peg$parseSchool();
                                                                                                                    if (s7 !== peg$FAILED) {
                                                                                                                      s8 = peg$parse_();
                                                                                                                      if (s8 !== peg$FAILED) {
                                                                                                                        if (input.substr(peg$currPos, 5) === peg$c1227) {
                                                                                                                          s9 = peg$c1227;
                                                                                                                          peg$currPos += 5;
                                                                                                                        } else {
                                                                                                                          s9 = peg$FAILED;
                                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c1228); }
                                                                                                                        }
                                                                                                                        if (s9 !== peg$FAILED) {
                                                                                                                          peg$savedPos = s0;
                                                                                                                          s1 = peg$c1229(s3, s7);
                                                                                                                          s0 = s1;
                                                                                                                        } else {
                                                                                                                          peg$currPos = s0;
                                                                                                                          s0 = peg$FAILED;
                                                                                                                        }
                                                                                                                      } else {
                                                                                                                        peg$currPos = s0;
                                                                                                                        s0 = peg$FAILED;
                                                                                                                      }
                                                                                                                    } else {
                                                                                                                      peg$currPos = s0;
                                                                                                                      s0 = peg$FAILED;
                                                                                                                    }
                                                                                                                  } else {
                                                                                                                    peg$currPos = s0;
                                                                                                                    s0 = peg$FAILED;
                                                                                                                  }
                                                                                                                } else {
                                                                                                                  peg$currPos = s0;
                                                                                                                  s0 = peg$FAILED;
                                                                                                                }
                                                                                                              } else {
                                                                                                                peg$currPos = s0;
                                                                                                                s0 = peg$FAILED;
                                                                                                              }
                                                                                                            } else {
                                                                                                              peg$currPos = s0;
                                                                                                              s0 = peg$FAILED;
                                                                                                            }
                                                                                                          } else {
                                                                                                            peg$currPos = s0;
                                                                                                            s0 = peg$FAILED;
                                                                                                          }
                                                                                                        } else {
                                                                                                          peg$currPos = s0;
                                                                                                          s0 = peg$FAILED;
                                                                                                        }
                                                                                                        if (s0 === peg$FAILED) {
                                                                                                          s0 = peg$currPos;
                                                                                                          if (input.substr(peg$currPos, 2) === peg$c1070) {
                                                                                                            s1 = peg$c1070;
                                                                                                            peg$currPos += 2;
                                                                                                          } else {
                                                                                                            s1 = peg$FAILED;
                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c1071); }
                                                                                                          }
                                                                                                          if (s1 !== peg$FAILED) {
                                                                                                            s2 = peg$parse_();
                                                                                                            if (s2 !== peg$FAILED) {
                                                                                                              s3 = peg$parseIntegerSlashList();
                                                                                                              if (s3 !== peg$FAILED) {
                                                                                                                s4 = peg$parse_();
                                                                                                                if (s4 !== peg$FAILED) {
                                                                                                                  if (input.substr(peg$currPos, 9) === peg$c1230) {
                                                                                                                    s5 = peg$c1230;
                                                                                                                    peg$currPos += 9;
                                                                                                                  } else {
                                                                                                                    s5 = peg$FAILED;
                                                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c1231); }
                                                                                                                  }
                                                                                                                  if (s5 !== peg$FAILED) {
                                                                                                                    s6 = peg$parse_();
                                                                                                                    if (s6 !== peg$FAILED) {
                                                                                                                      s7 = peg$parseSchool();
                                                                                                                      if (s7 !== peg$FAILED) {
                                                                                                                        s8 = peg$parse_();
                                                                                                                        if (s8 !== peg$FAILED) {
                                                                                                                          if (input.substr(peg$currPos, 9) === peg$c223) {
                                                                                                                            s9 = peg$c223;
                                                                                                                            peg$currPos += 9;
                                                                                                                          } else {
                                                                                                                            s9 = peg$FAILED;
                                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c224); }
                                                                                                                          }
                                                                                                                          if (s9 !== peg$FAILED) {
                                                                                                                            s10 = peg$parse_();
                                                                                                                            if (s10 !== peg$FAILED) {
                                                                                                                              if (input.substr(peg$currPos, 4) === peg$c733) {
                                                                                                                                s11 = peg$c733;
                                                                                                                                peg$currPos += 4;
                                                                                                                              } else {
                                                                                                                                s11 = peg$FAILED;
                                                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c779); }
                                                                                                                              }
                                                                                                                              if (s11 !== peg$FAILED) {
                                                                                                                                peg$savedPos = s0;
                                                                                                                                s1 = peg$c1232(s3, s7);
                                                                                                                                s0 = s1;
                                                                                                                              } else {
                                                                                                                                peg$currPos = s0;
                                                                                                                                s0 = peg$FAILED;
                                                                                                                              }
                                                                                                                            } else {
                                                                                                                              peg$currPos = s0;
                                                                                                                              s0 = peg$FAILED;
                                                                                                                            }
                                                                                                                          } else {
                                                                                                                            peg$currPos = s0;
                                                                                                                            s0 = peg$FAILED;
                                                                                                                          }
                                                                                                                        } else {
                                                                                                                          peg$currPos = s0;
                                                                                                                          s0 = peg$FAILED;
                                                                                                                        }
                                                                                                                      } else {
                                                                                                                        peg$currPos = s0;
                                                                                                                        s0 = peg$FAILED;
                                                                                                                      }
                                                                                                                    } else {
                                                                                                                      peg$currPos = s0;
                                                                                                                      s0 = peg$FAILED;
                                                                                                                    }
                                                                                                                  } else {
                                                                                                                    peg$currPos = s0;
                                                                                                                    s0 = peg$FAILED;
                                                                                                                  }
                                                                                                                } else {
                                                                                                                  peg$currPos = s0;
                                                                                                                  s0 = peg$FAILED;
                                                                                                                }
                                                                                                              } else {
                                                                                                                peg$currPos = s0;
                                                                                                                s0 = peg$FAILED;
                                                                                                              }
                                                                                                            } else {
                                                                                                              peg$currPos = s0;
                                                                                                              s0 = peg$FAILED;
                                                                                                            }
                                                                                                          } else {
                                                                                                            peg$currPos = s0;
                                                                                                            s0 = peg$FAILED;
                                                                                                          }
                                                                                                          if (s0 === peg$FAILED) {
                                                                                                            s0 = peg$currPos;
                                                                                                            if (input.substr(peg$currPos, 2) === peg$c693) {
                                                                                                              s1 = peg$c693;
                                                                                                              peg$currPos += 2;
                                                                                                            } else {
                                                                                                              s1 = peg$FAILED;
                                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                                                                            }
                                                                                                            if (s1 !== peg$FAILED) {
                                                                                                              s2 = peg$parse_();
                                                                                                              if (s2 !== peg$FAILED) {
                                                                                                                if (input.substr(peg$currPos, 3) === peg$c576) {
                                                                                                                  s3 = peg$c576;
                                                                                                                  peg$currPos += 3;
                                                                                                                } else {
                                                                                                                  s3 = peg$FAILED;
                                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c577); }
                                                                                                                }
                                                                                                                if (s3 !== peg$FAILED) {
                                                                                                                  s4 = peg$parse_();
                                                                                                                  if (s4 !== peg$FAILED) {
                                                                                                                    if (input.substr(peg$currPos, 4) === peg$c578) {
                                                                                                                      s5 = peg$c578;
                                                                                                                      peg$currPos += 4;
                                                                                                                    } else {
                                                                                                                      s5 = peg$FAILED;
                                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c579); }
                                                                                                                    }
                                                                                                                    if (s5 !== peg$FAILED) {
                                                                                                                      s6 = peg$parse_();
                                                                                                                      if (s6 !== peg$FAILED) {
                                                                                                                        if (input.substr(peg$currPos, 4) === peg$c733) {
                                                                                                                          s7 = peg$c733;
                                                                                                                          peg$currPos += 4;
                                                                                                                        } else {
                                                                                                                          s7 = peg$FAILED;
                                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c779); }
                                                                                                                        }
                                                                                                                        if (s7 !== peg$FAILED) {
                                                                                                                          s8 = peg$parse_();
                                                                                                                          if (s8 !== peg$FAILED) {
                                                                                                                            s9 = peg$parseIntegerSlashList();
                                                                                                                            if (s9 !== peg$FAILED) {
                                                                                                                              s10 = peg$parse_();
                                                                                                                              if (s10 !== peg$FAILED) {
                                                                                                                                s11 = peg$parseSchoolList();
                                                                                                                                if (s11 !== peg$FAILED) {
                                                                                                                                  s12 = peg$parse_();
                                                                                                                                  if (s12 !== peg$FAILED) {
                                                                                                                                    if (input.substr(peg$currPos, 9) === peg$c223) {
                                                                                                                                      s13 = peg$c223;
                                                                                                                                      peg$currPos += 9;
                                                                                                                                    } else {
                                                                                                                                      s13 = peg$FAILED;
                                                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c224); }
                                                                                                                                    }
                                                                                                                                    if (s13 !== peg$FAILED) {
                                                                                                                                      s14 = peg$parse_();
                                                                                                                                      if (s14 !== peg$FAILED) {
                                                                                                                                        if (input.substr(peg$currPos, 6) === peg$c1233) {
                                                                                                                                          s15 = peg$c1233;
                                                                                                                                          peg$currPos += 6;
                                                                                                                                        } else {
                                                                                                                                          s15 = peg$FAILED;
                                                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c1234); }
                                                                                                                                        }
                                                                                                                                        if (s15 !== peg$FAILED) {
                                                                                                                                          s16 = peg$parse_();
                                                                                                                                          if (s16 !== peg$FAILED) {
                                                                                                                                            if (input.substr(peg$currPos, 3) === peg$c576) {
                                                                                                                                              s17 = peg$c576;
                                                                                                                                              peg$currPos += 3;
                                                                                                                                            } else {
                                                                                                                                              s17 = peg$FAILED;
                                                                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c577); }
                                                                                                                                            }
                                                                                                                                            if (s17 !== peg$FAILED) {
                                                                                                                                              s18 = peg$parse_();
                                                                                                                                              if (s18 !== peg$FAILED) {
                                                                                                                                                if (input.substr(peg$currPos, 6) === peg$c590) {
                                                                                                                                                  s19 = peg$c590;
                                                                                                                                                  peg$currPos += 6;
                                                                                                                                                } else {
                                                                                                                                                  s19 = peg$FAILED;
                                                                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c591); }
                                                                                                                                                }
                                                                                                                                                if (s19 !== peg$FAILED) {
                                                                                                                                                  peg$savedPos = s0;
                                                                                                                                                  s1 = peg$c1235(s9, s11);
                                                                                                                                                  s0 = s1;
                                                                                                                                                } else {
                                                                                                                                                  peg$currPos = s0;
                                                                                                                                                  s0 = peg$FAILED;
                                                                                                                                                }
                                                                                                                                              } else {
                                                                                                                                                peg$currPos = s0;
                                                                                                                                                s0 = peg$FAILED;
                                                                                                                                              }
                                                                                                                                            } else {
                                                                                                                                              peg$currPos = s0;
                                                                                                                                              s0 = peg$FAILED;
                                                                                                                                            }
                                                                                                                                          } else {
                                                                                                                                            peg$currPos = s0;
                                                                                                                                            s0 = peg$FAILED;
                                                                                                                                          }
                                                                                                                                        } else {
                                                                                                                                          peg$currPos = s0;
                                                                                                                                          s0 = peg$FAILED;
                                                                                                                                        }
                                                                                                                                      } else {
                                                                                                                                        peg$currPos = s0;
                                                                                                                                        s0 = peg$FAILED;
                                                                                                                                      }
                                                                                                                                    } else {
                                                                                                                                      peg$currPos = s0;
                                                                                                                                      s0 = peg$FAILED;
                                                                                                                                    }
                                                                                                                                  } else {
                                                                                                                                    peg$currPos = s0;
                                                                                                                                    s0 = peg$FAILED;
                                                                                                                                  }
                                                                                                                                } else {
                                                                                                                                  peg$currPos = s0;
                                                                                                                                  s0 = peg$FAILED;
                                                                                                                                }
                                                                                                                              } else {
                                                                                                                                peg$currPos = s0;
                                                                                                                                s0 = peg$FAILED;
                                                                                                                              }
                                                                                                                            } else {
                                                                                                                              peg$currPos = s0;
                                                                                                                              s0 = peg$FAILED;
                                                                                                                            }
                                                                                                                          } else {
                                                                                                                            peg$currPos = s0;
                                                                                                                            s0 = peg$FAILED;
                                                                                                                          }
                                                                                                                        } else {
                                                                                                                          peg$currPos = s0;
                                                                                                                          s0 = peg$FAILED;
                                                                                                                        }
                                                                                                                      } else {
                                                                                                                        peg$currPos = s0;
                                                                                                                        s0 = peg$FAILED;
                                                                                                                      }
                                                                                                                    } else {
                                                                                                                      peg$currPos = s0;
                                                                                                                      s0 = peg$FAILED;
                                                                                                                    }
                                                                                                                  } else {
                                                                                                                    peg$currPos = s0;
                                                                                                                    s0 = peg$FAILED;
                                                                                                                  }
                                                                                                                } else {
                                                                                                                  peg$currPos = s0;
                                                                                                                  s0 = peg$FAILED;
                                                                                                                }
                                                                                                              } else {
                                                                                                                peg$currPos = s0;
                                                                                                                s0 = peg$FAILED;
                                                                                                              }
                                                                                                            } else {
                                                                                                              peg$currPos = s0;
                                                                                                              s0 = peg$FAILED;
                                                                                                            }
                                                                                                            if (s0 === peg$FAILED) {
                                                                                                              s0 = peg$currPos;
                                                                                                              if (input.substr(peg$currPos, 2) === peg$c693) {
                                                                                                                s1 = peg$c693;
                                                                                                                peg$currPos += 2;
                                                                                                              } else {
                                                                                                                s1 = peg$FAILED;
                                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                                                                              }
                                                                                                              if (s1 !== peg$FAILED) {
                                                                                                                s2 = peg$parse_();
                                                                                                                if (s2 !== peg$FAILED) {
                                                                                                                  if (input.substr(peg$currPos, 3) === peg$c576) {
                                                                                                                    s3 = peg$c576;
                                                                                                                    peg$currPos += 3;
                                                                                                                  } else {
                                                                                                                    s3 = peg$FAILED;
                                                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c577); }
                                                                                                                  }
                                                                                                                  if (s3 !== peg$FAILED) {
                                                                                                                    s4 = peg$parse_();
                                                                                                                    if (s4 !== peg$FAILED) {
                                                                                                                      if (input.substr(peg$currPos, 4) === peg$c578) {
                                                                                                                        s5 = peg$c578;
                                                                                                                        peg$currPos += 4;
                                                                                                                      } else {
                                                                                                                        s5 = peg$FAILED;
                                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c579); }
                                                                                                                      }
                                                                                                                      if (s5 !== peg$FAILED) {
                                                                                                                        s6 = peg$parse_();
                                                                                                                        if (s6 !== peg$FAILED) {
                                                                                                                          if (input.substr(peg$currPos, 4) === peg$c733) {
                                                                                                                            s7 = peg$c733;
                                                                                                                            peg$currPos += 4;
                                                                                                                          } else {
                                                                                                                            s7 = peg$FAILED;
                                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c779); }
                                                                                                                          }
                                                                                                                          if (s7 !== peg$FAILED) {
                                                                                                                            s8 = peg$parse_();
                                                                                                                            if (s8 !== peg$FAILED) {
                                                                                                                              s9 = peg$parseIntegerSlashList();
                                                                                                                              if (s9 !== peg$FAILED) {
                                                                                                                                s10 = peg$parse_();
                                                                                                                                if (s10 !== peg$FAILED) {
                                                                                                                                  s11 = peg$parseSchoolList();
                                                                                                                                  if (s11 !== peg$FAILED) {
                                                                                                                                    s12 = peg$parse_();
                                                                                                                                    if (s12 !== peg$FAILED) {
                                                                                                                                      if (input.substr(peg$currPos, 9) === peg$c223) {
                                                                                                                                        s13 = peg$c223;
                                                                                                                                        peg$currPos += 9;
                                                                                                                                      } else {
                                                                                                                                        s13 = peg$FAILED;
                                                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c224); }
                                                                                                                                      }
                                                                                                                                      if (s13 !== peg$FAILED) {
                                                                                                                                        peg$savedPos = s0;
                                                                                                                                        s1 = peg$c1236(s9, s11);
                                                                                                                                        s0 = s1;
                                                                                                                                      } else {
                                                                                                                                        peg$currPos = s0;
                                                                                                                                        s0 = peg$FAILED;
                                                                                                                                      }
                                                                                                                                    } else {
                                                                                                                                      peg$currPos = s0;
                                                                                                                                      s0 = peg$FAILED;
                                                                                                                                    }
                                                                                                                                  } else {
                                                                                                                                    peg$currPos = s0;
                                                                                                                                    s0 = peg$FAILED;
                                                                                                                                  }
                                                                                                                                } else {
                                                                                                                                  peg$currPos = s0;
                                                                                                                                  s0 = peg$FAILED;
                                                                                                                                }
                                                                                                                              } else {
                                                                                                                                peg$currPos = s0;
                                                                                                                                s0 = peg$FAILED;
                                                                                                                              }
                                                                                                                            } else {
                                                                                                                              peg$currPos = s0;
                                                                                                                              s0 = peg$FAILED;
                                                                                                                            }
                                                                                                                          } else {
                                                                                                                            peg$currPos = s0;
                                                                                                                            s0 = peg$FAILED;
                                                                                                                          }
                                                                                                                        } else {
                                                                                                                          peg$currPos = s0;
                                                                                                                          s0 = peg$FAILED;
                                                                                                                        }
                                                                                                                      } else {
                                                                                                                        peg$currPos = s0;
                                                                                                                        s0 = peg$FAILED;
                                                                                                                      }
                                                                                                                    } else {
                                                                                                                      peg$currPos = s0;
                                                                                                                      s0 = peg$FAILED;
                                                                                                                    }
                                                                                                                  } else {
                                                                                                                    peg$currPos = s0;
                                                                                                                    s0 = peg$FAILED;
                                                                                                                  }
                                                                                                                } else {
                                                                                                                  peg$currPos = s0;
                                                                                                                  s0 = peg$FAILED;
                                                                                                                }
                                                                                                              } else {
                                                                                                                peg$currPos = s0;
                                                                                                                s0 = peg$FAILED;
                                                                                                              }
                                                                                                              if (s0 === peg$FAILED) {
                                                                                                                s0 = peg$currPos;
                                                                                                                if (input.substr(peg$currPos, 2) === peg$c693) {
                                                                                                                  s1 = peg$c693;
                                                                                                                  peg$currPos += 2;
                                                                                                                } else {
                                                                                                                  s1 = peg$FAILED;
                                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                                                                                }
                                                                                                                if (s1 !== peg$FAILED) {
                                                                                                                  s2 = peg$parse_();
                                                                                                                  if (s2 !== peg$FAILED) {
                                                                                                                    if (input.substr(peg$currPos, 3) === peg$c576) {
                                                                                                                      s3 = peg$c576;
                                                                                                                      peg$currPos += 3;
                                                                                                                    } else {
                                                                                                                      s3 = peg$FAILED;
                                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c577); }
                                                                                                                    }
                                                                                                                    if (s3 !== peg$FAILED) {
                                                                                                                      s4 = peg$parse_();
                                                                                                                      if (s4 !== peg$FAILED) {
                                                                                                                        if (input.substr(peg$currPos, 4) === peg$c578) {
                                                                                                                          s5 = peg$c578;
                                                                                                                          peg$currPos += 4;
                                                                                                                        } else {
                                                                                                                          s5 = peg$FAILED;
                                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c579); }
                                                                                                                        }
                                                                                                                        if (s5 !== peg$FAILED) {
                                                                                                                          s6 = peg$parse_();
                                                                                                                          if (s6 !== peg$FAILED) {
                                                                                                                            if (input.substr(peg$currPos, 4) === peg$c733) {
                                                                                                                              s7 = peg$c733;
                                                                                                                              peg$currPos += 4;
                                                                                                                            } else {
                                                                                                                              s7 = peg$FAILED;
                                                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c779); }
                                                                                                                            }
                                                                                                                            if (s7 !== peg$FAILED) {
                                                                                                                              s8 = peg$parse_();
                                                                                                                              if (s8 !== peg$FAILED) {
                                                                                                                                s9 = peg$parseIntegerSlashList();
                                                                                                                                if (s9 !== peg$FAILED) {
                                                                                                                                  s10 = peg$parse_();
                                                                                                                                  if (s10 !== peg$FAILED) {
                                                                                                                                    s11 = peg$parseElementList();
                                                                                                                                    if (s11 !== peg$FAILED) {
                                                                                                                                      s12 = peg$parse_();
                                                                                                                                      if (s12 !== peg$FAILED) {
                                                                                                                                        if (input.substr(peg$currPos, 7) === peg$c35) {
                                                                                                                                          s13 = peg$c35;
                                                                                                                                          peg$currPos += 7;
                                                                                                                                        } else {
                                                                                                                                          s13 = peg$FAILED;
                                                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c36); }
                                                                                                                                        }
                                                                                                                                        if (s13 !== peg$FAILED) {
                                                                                                                                          s14 = peg$parse_();
                                                                                                                                          if (s14 !== peg$FAILED) {
                                                                                                                                            if (input.substr(peg$currPos, 6) === peg$c1233) {
                                                                                                                                              s15 = peg$c1233;
                                                                                                                                              peg$currPos += 6;
                                                                                                                                            } else {
                                                                                                                                              s15 = peg$FAILED;
                                                                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c1234); }
                                                                                                                                            }
                                                                                                                                            if (s15 !== peg$FAILED) {
                                                                                                                                              s16 = peg$parse_();
                                                                                                                                              if (s16 !== peg$FAILED) {
                                                                                                                                                if (input.substr(peg$currPos, 3) === peg$c576) {
                                                                                                                                                  s17 = peg$c576;
                                                                                                                                                  peg$currPos += 3;
                                                                                                                                                } else {
                                                                                                                                                  s17 = peg$FAILED;
                                                                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c577); }
                                                                                                                                                }
                                                                                                                                                if (s17 !== peg$FAILED) {
                                                                                                                                                  s18 = peg$parse_();
                                                                                                                                                  if (s18 !== peg$FAILED) {
                                                                                                                                                    if (input.substr(peg$currPos, 6) === peg$c590) {
                                                                                                                                                      s19 = peg$c590;
                                                                                                                                                      peg$currPos += 6;
                                                                                                                                                    } else {
                                                                                                                                                      s19 = peg$FAILED;
                                                                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c591); }
                                                                                                                                                    }
                                                                                                                                                    if (s19 !== peg$FAILED) {
                                                                                                                                                      peg$savedPos = s0;
                                                                                                                                                      s1 = peg$c1237(s9, s11);
                                                                                                                                                      s0 = s1;
                                                                                                                                                    } else {
                                                                                                                                                      peg$currPos = s0;
                                                                                                                                                      s0 = peg$FAILED;
                                                                                                                                                    }
                                                                                                                                                  } else {
                                                                                                                                                    peg$currPos = s0;
                                                                                                                                                    s0 = peg$FAILED;
                                                                                                                                                  }
                                                                                                                                                } else {
                                                                                                                                                  peg$currPos = s0;
                                                                                                                                                  s0 = peg$FAILED;
                                                                                                                                                }
                                                                                                                                              } else {
                                                                                                                                                peg$currPos = s0;
                                                                                                                                                s0 = peg$FAILED;
                                                                                                                                              }
                                                                                                                                            } else {
                                                                                                                                              peg$currPos = s0;
                                                                                                                                              s0 = peg$FAILED;
                                                                                                                                            }
                                                                                                                                          } else {
                                                                                                                                            peg$currPos = s0;
                                                                                                                                            s0 = peg$FAILED;
                                                                                                                                          }
                                                                                                                                        } else {
                                                                                                                                          peg$currPos = s0;
                                                                                                                                          s0 = peg$FAILED;
                                                                                                                                        }
                                                                                                                                      } else {
                                                                                                                                        peg$currPos = s0;
                                                                                                                                        s0 = peg$FAILED;
                                                                                                                                      }
                                                                                                                                    } else {
                                                                                                                                      peg$currPos = s0;
                                                                                                                                      s0 = peg$FAILED;
                                                                                                                                    }
                                                                                                                                  } else {
                                                                                                                                    peg$currPos = s0;
                                                                                                                                    s0 = peg$FAILED;
                                                                                                                                  }
                                                                                                                                } else {
                                                                                                                                  peg$currPos = s0;
                                                                                                                                  s0 = peg$FAILED;
                                                                                                                                }
                                                                                                                              } else {
                                                                                                                                peg$currPos = s0;
                                                                                                                                s0 = peg$FAILED;
                                                                                                                              }
                                                                                                                            } else {
                                                                                                                              peg$currPos = s0;
                                                                                                                              s0 = peg$FAILED;
                                                                                                                            }
                                                                                                                          } else {
                                                                                                                            peg$currPos = s0;
                                                                                                                            s0 = peg$FAILED;
                                                                                                                          }
                                                                                                                        } else {
                                                                                                                          peg$currPos = s0;
                                                                                                                          s0 = peg$FAILED;
                                                                                                                        }
                                                                                                                      } else {
                                                                                                                        peg$currPos = s0;
                                                                                                                        s0 = peg$FAILED;
                                                                                                                      }
                                                                                                                    } else {
                                                                                                                      peg$currPos = s0;
                                                                                                                      s0 = peg$FAILED;
                                                                                                                    }
                                                                                                                  } else {
                                                                                                                    peg$currPos = s0;
                                                                                                                    s0 = peg$FAILED;
                                                                                                                  }
                                                                                                                } else {
                                                                                                                  peg$currPos = s0;
                                                                                                                  s0 = peg$FAILED;
                                                                                                                }
                                                                                                                if (s0 === peg$FAILED) {
                                                                                                                  s0 = peg$currPos;
                                                                                                                  if (input.substr(peg$currPos, 2) === peg$c693) {
                                                                                                                    s1 = peg$c693;
                                                                                                                    peg$currPos += 2;
                                                                                                                  } else {
                                                                                                                    s1 = peg$FAILED;
                                                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                                                                                  }
                                                                                                                  if (s1 !== peg$FAILED) {
                                                                                                                    s2 = peg$parse_();
                                                                                                                    if (s2 !== peg$FAILED) {
                                                                                                                      s3 = peg$parseIntegerSlashList();
                                                                                                                      if (s3 !== peg$FAILED) {
                                                                                                                        s4 = peg$parse_();
                                                                                                                        if (s4 !== peg$FAILED) {
                                                                                                                          if (input.substr(peg$currPos, 34) === peg$c1238) {
                                                                                                                            s5 = peg$c1238;
                                                                                                                            peg$currPos += 34;
                                                                                                                          } else {
                                                                                                                            s5 = peg$FAILED;
                                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c1239); }
                                                                                                                          }
                                                                                                                          if (s5 !== peg$FAILED) {
                                                                                                                            peg$savedPos = s0;
                                                                                                                            s1 = peg$c1240(s3);
                                                                                                                            s0 = s1;
                                                                                                                          } else {
                                                                                                                            peg$currPos = s0;
                                                                                                                            s0 = peg$FAILED;
                                                                                                                          }
                                                                                                                        } else {
                                                                                                                          peg$currPos = s0;
                                                                                                                          s0 = peg$FAILED;
                                                                                                                        }
                                                                                                                      } else {
                                                                                                                        peg$currPos = s0;
                                                                                                                        s0 = peg$FAILED;
                                                                                                                      }
                                                                                                                    } else {
                                                                                                                      peg$currPos = s0;
                                                                                                                      s0 = peg$FAILED;
                                                                                                                    }
                                                                                                                  } else {
                                                                                                                    peg$currPos = s0;
                                                                                                                    s0 = peg$FAILED;
                                                                                                                  }
                                                                                                                  if (s0 === peg$FAILED) {
                                                                                                                    s0 = peg$currPos;
                                                                                                                    if (input.substr(peg$currPos, 2) === peg$c693) {
                                                                                                                      s1 = peg$c693;
                                                                                                                      peg$currPos += 2;
                                                                                                                    } else {
                                                                                                                      s1 = peg$FAILED;
                                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                                                                                    }
                                                                                                                    if (s1 !== peg$FAILED) {
                                                                                                                      s2 = peg$parse_();
                                                                                                                      if (s2 !== peg$FAILED) {
                                                                                                                        if (input.substr(peg$currPos, 3) === peg$c576) {
                                                                                                                          s3 = peg$c576;
                                                                                                                          peg$currPos += 3;
                                                                                                                        } else {
                                                                                                                          s3 = peg$FAILED;
                                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c577); }
                                                                                                                        }
                                                                                                                        if (s3 === peg$FAILED) {
                                                                                                                          s3 = null;
                                                                                                                        }
                                                                                                                        if (s3 !== peg$FAILED) {
                                                                                                                          s4 = peg$parse_();
                                                                                                                          if (s4 !== peg$FAILED) {
                                                                                                                            if (input.substr(peg$currPos, 10) === peg$c1241) {
                                                                                                                              s5 = peg$c1241;
                                                                                                                              peg$currPos += 10;
                                                                                                                            } else {
                                                                                                                              s5 = peg$FAILED;
                                                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c1242); }
                                                                                                                            }
                                                                                                                            if (s5 !== peg$FAILED) {
                                                                                                                              s6 = peg$parse_();
                                                                                                                              if (s6 !== peg$FAILED) {
                                                                                                                                s7 = peg$parseIntegerSlashList();
                                                                                                                                if (s7 !== peg$FAILED) {
                                                                                                                                  s8 = peg$parse_();
                                                                                                                                  if (s8 !== peg$FAILED) {
                                                                                                                                    if (input.substr(peg$currPos, 24) === peg$c1243) {
                                                                                                                                      s9 = peg$c1243;
                                                                                                                                      peg$currPos += 24;
                                                                                                                                    } else {
                                                                                                                                      s9 = peg$FAILED;
                                                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c1244); }
                                                                                                                                    }
                                                                                                                                    if (s9 !== peg$FAILED) {
                                                                                                                                      peg$savedPos = s0;
                                                                                                                                      s1 = peg$c1240(s7);
                                                                                                                                      s0 = s1;
                                                                                                                                    } else {
                                                                                                                                      peg$currPos = s0;
                                                                                                                                      s0 = peg$FAILED;
                                                                                                                                    }
                                                                                                                                  } else {
                                                                                                                                    peg$currPos = s0;
                                                                                                                                    s0 = peg$FAILED;
                                                                                                                                  }
                                                                                                                                } else {
                                                                                                                                  peg$currPos = s0;
                                                                                                                                  s0 = peg$FAILED;
                                                                                                                                }
                                                                                                                              } else {
                                                                                                                                peg$currPos = s0;
                                                                                                                                s0 = peg$FAILED;
                                                                                                                              }
                                                                                                                            } else {
                                                                                                                              peg$currPos = s0;
                                                                                                                              s0 = peg$FAILED;
                                                                                                                            }
                                                                                                                          } else {
                                                                                                                            peg$currPos = s0;
                                                                                                                            s0 = peg$FAILED;
                                                                                                                          }
                                                                                                                        } else {
                                                                                                                          peg$currPos = s0;
                                                                                                                          s0 = peg$FAILED;
                                                                                                                        }
                                                                                                                      } else {
                                                                                                                        peg$currPos = s0;
                                                                                                                        s0 = peg$FAILED;
                                                                                                                      }
                                                                                                                    } else {
                                                                                                                      peg$currPos = s0;
                                                                                                                      s0 = peg$FAILED;
                                                                                                                    }
                                                                                                                    if (s0 === peg$FAILED) {
                                                                                                                      s0 = peg$currPos;
                                                                                                                      if (input.substr(peg$currPos, 2) === peg$c693) {
                                                                                                                        s1 = peg$c693;
                                                                                                                        peg$currPos += 2;
                                                                                                                      } else {
                                                                                                                        s1 = peg$FAILED;
                                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                                                                                      }
                                                                                                                      if (s1 !== peg$FAILED) {
                                                                                                                        s2 = peg$parse_();
                                                                                                                        if (s2 !== peg$FAILED) {
                                                                                                                          if (input.substr(peg$currPos, 3) === peg$c576) {
                                                                                                                            s3 = peg$c576;
                                                                                                                            peg$currPos += 3;
                                                                                                                          } else {
                                                                                                                            s3 = peg$FAILED;
                                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c577); }
                                                                                                                          }
                                                                                                                          if (s3 === peg$FAILED) {
                                                                                                                            s3 = null;
                                                                                                                          }
                                                                                                                          if (s3 !== peg$FAILED) {
                                                                                                                            s4 = peg$parse_();
                                                                                                                            if (s4 !== peg$FAILED) {
                                                                                                                              if (input.substr(peg$currPos, 10) === peg$c1241) {
                                                                                                                                s5 = peg$c1241;
                                                                                                                                peg$currPos += 10;
                                                                                                                              } else {
                                                                                                                                s5 = peg$FAILED;
                                                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c1242); }
                                                                                                                              }
                                                                                                                              if (s5 !== peg$FAILED) {
                                                                                                                                s6 = peg$parse_();
                                                                                                                                if (s6 !== peg$FAILED) {
                                                                                                                                  s7 = peg$parseIntegerSlashList();
                                                                                                                                  if (s7 !== peg$FAILED) {
                                                                                                                                    s8 = peg$parse_();
                                                                                                                                    if (s8 !== peg$FAILED) {
                                                                                                                                      if (input.substr(peg$currPos, 11) === peg$c1245) {
                                                                                                                                        s9 = peg$c1245;
                                                                                                                                        peg$currPos += 11;
                                                                                                                                      } else {
                                                                                                                                        s9 = peg$FAILED;
                                                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c1246); }
                                                                                                                                      }
                                                                                                                                      if (s9 !== peg$FAILED) {
                                                                                                                                        s10 = peg$parse_();
                                                                                                                                        if (s10 !== peg$FAILED) {
                                                                                                                                          s11 = peg$parseElementList();
                                                                                                                                          if (s11 !== peg$FAILED) {
                                                                                                                                            s12 = peg$parse_();
                                                                                                                                            if (s12 !== peg$FAILED) {
                                                                                                                                              if (input.substr(peg$currPos, 25) === peg$c1247) {
                                                                                                                                                s13 = peg$c1247;
                                                                                                                                                peg$currPos += 25;
                                                                                                                                              } else {
                                                                                                                                                s13 = peg$FAILED;
                                                                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c1248); }
                                                                                                                                              }
                                                                                                                                              if (s13 !== peg$FAILED) {
                                                                                                                                                peg$savedPos = s0;
                                                                                                                                                s1 = peg$c1249(s7, s11);
                                                                                                                                                s0 = s1;
                                                                                                                                              } else {
                                                                                                                                                peg$currPos = s0;
                                                                                                                                                s0 = peg$FAILED;
                                                                                                                                              }
                                                                                                                                            } else {
                                                                                                                                              peg$currPos = s0;
                                                                                                                                              s0 = peg$FAILED;
                                                                                                                                            }
                                                                                                                                          } else {
                                                                                                                                            peg$currPos = s0;
                                                                                                                                            s0 = peg$FAILED;
                                                                                                                                          }
                                                                                                                                        } else {
                                                                                                                                          peg$currPos = s0;
                                                                                                                                          s0 = peg$FAILED;
                                                                                                                                        }
                                                                                                                                      } else {
                                                                                                                                        peg$currPos = s0;
                                                                                                                                        s0 = peg$FAILED;
                                                                                                                                      }
                                                                                                                                    } else {
                                                                                                                                      peg$currPos = s0;
                                                                                                                                      s0 = peg$FAILED;
                                                                                                                                    }
                                                                                                                                  } else {
                                                                                                                                    peg$currPos = s0;
                                                                                                                                    s0 = peg$FAILED;
                                                                                                                                  }
                                                                                                                                } else {
                                                                                                                                  peg$currPos = s0;
                                                                                                                                  s0 = peg$FAILED;
                                                                                                                                }
                                                                                                                              } else {
                                                                                                                                peg$currPos = s0;
                                                                                                                                s0 = peg$FAILED;
                                                                                                                              }
                                                                                                                            } else {
                                                                                                                              peg$currPos = s0;
                                                                                                                              s0 = peg$FAILED;
                                                                                                                            }
                                                                                                                          } else {
                                                                                                                            peg$currPos = s0;
                                                                                                                            s0 = peg$FAILED;
                                                                                                                          }
                                                                                                                        } else {
                                                                                                                          peg$currPos = s0;
                                                                                                                          s0 = peg$FAILED;
                                                                                                                        }
                                                                                                                      } else {
                                                                                                                        peg$currPos = s0;
                                                                                                                        s0 = peg$FAILED;
                                                                                                                      }
                                                                                                                      if (s0 === peg$FAILED) {
                                                                                                                        s0 = peg$currPos;
                                                                                                                        if (input.substr(peg$currPos, 2) === peg$c693) {
                                                                                                                          s1 = peg$c693;
                                                                                                                          peg$currPos += 2;
                                                                                                                        } else {
                                                                                                                          s1 = peg$FAILED;
                                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c694); }
                                                                                                                        }
                                                                                                                        if (s1 !== peg$FAILED) {
                                                                                                                          s2 = peg$parse_();
                                                                                                                          if (s2 !== peg$FAILED) {
                                                                                                                            if (input.substr(peg$currPos, 3) === peg$c576) {
                                                                                                                              s3 = peg$c576;
                                                                                                                              peg$currPos += 3;
                                                                                                                            } else {
                                                                                                                              s3 = peg$FAILED;
                                                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c577); }
                                                                                                                            }
                                                                                                                            if (s3 !== peg$FAILED) {
                                                                                                                              s4 = peg$parse_();
                                                                                                                              if (s4 !== peg$FAILED) {
                                                                                                                                if (input.substr(peg$currPos, 5) === peg$c1250) {
                                                                                                                                  s5 = peg$c1250;
                                                                                                                                  peg$currPos += 5;
                                                                                                                                } else {
                                                                                                                                  s5 = peg$FAILED;
                                                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c1251); }
                                                                                                                                }
                                                                                                                                if (s5 !== peg$FAILED) {
                                                                                                                                  s6 = peg$parse_();
                                                                                                                                  if (s6 !== peg$FAILED) {
                                                                                                                                    if (input.substr(peg$currPos, 6) === peg$c39) {
                                                                                                                                      s7 = peg$c39;
                                                                                                                                      peg$currPos += 6;
                                                                                                                                    } else {
                                                                                                                                      s7 = peg$FAILED;
                                                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c40); }
                                                                                                                                    }
                                                                                                                                    if (s7 !== peg$FAILED) {
                                                                                                                                      s8 = peg$parse_();
                                                                                                                                      if (s8 !== peg$FAILED) {
                                                                                                                                        if (input.substr(peg$currPos, 9) === peg$c1252) {
                                                                                                                                          s9 = peg$c1252;
                                                                                                                                          peg$currPos += 9;
                                                                                                                                        } else {
                                                                                                                                          s9 = peg$FAILED;
                                                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c1253); }
                                                                                                                                        }
                                                                                                                                        if (s9 !== peg$FAILED) {
                                                                                                                                          s10 = peg$parse_();
                                                                                                                                          if (s10 !== peg$FAILED) {
                                                                                                                                            if (input.substr(peg$currPos, 3) === peg$c1254) {
                                                                                                                                              s11 = peg$c1254;
                                                                                                                                              peg$currPos += 3;
                                                                                                                                            } else {
                                                                                                                                              s11 = peg$FAILED;
                                                                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c1255); }
                                                                                                                                            }
                                                                                                                                            if (s11 !== peg$FAILED) {
                                                                                                                                              s12 = peg$parse_();
                                                                                                                                              if (s12 !== peg$FAILED) {
                                                                                                                                                if (input.substr(peg$currPos, 3) === peg$c1256) {
                                                                                                                                                  s13 = peg$c1256;
                                                                                                                                                  peg$currPos += 3;
                                                                                                                                                } else {
                                                                                                                                                  s13 = peg$FAILED;
                                                                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c1257); }
                                                                                                                                                }
                                                                                                                                                if (s13 !== peg$FAILED) {
                                                                                                                                                  peg$savedPos = s0;
                                                                                                                                                  s1 = peg$c1258();
                                                                                                                                                  s0 = s1;
                                                                                                                                                } else {
                                                                                                                                                  peg$currPos = s0;
                                                                                                                                                  s0 = peg$FAILED;
                                                                                                                                                }
                                                                                                                                              } else {
                                                                                                                                                peg$currPos = s0;
                                                                                                                                                s0 = peg$FAILED;
                                                                                                                                              }
                                                                                                                                            } else {
                                                                                                                                              peg$currPos = s0;
                                                                                                                                              s0 = peg$FAILED;
                                                                                                                                            }
                                                                                                                                          } else {
                                                                                                                                            peg$currPos = s0;
                                                                                                                                            s0 = peg$FAILED;
                                                                                                                                          }
                                                                                                                                        } else {
                                                                                                                                          peg$currPos = s0;
                                                                                                                                          s0 = peg$FAILED;
                                                                                                                                        }
                                                                                                                                      } else {
                                                                                                                                        peg$currPos = s0;
                                                                                                                                        s0 = peg$FAILED;
                                                                                                                                      }
                                                                                                                                    } else {
                                                                                                                                      peg$currPos = s0;
                                                                                                                                      s0 = peg$FAILED;
                                                                                                                                    }
                                                                                                                                  } else {
                                                                                                                                    peg$currPos = s0;
                                                                                                                                    s0 = peg$FAILED;
                                                                                                                                  }
                                                                                                                                } else {
                                                                                                                                  peg$currPos = s0;
                                                                                                                                  s0 = peg$FAILED;
                                                                                                                                }
                                                                                                                              } else {
                                                                                                                                peg$currPos = s0;
                                                                                                                                s0 = peg$FAILED;
                                                                                                                              }
                                                                                                                            } else {
                                                                                                                              peg$currPos = s0;
                                                                                                                              s0 = peg$FAILED;
                                                                                                                            }
                                                                                                                          } else {
                                                                                                                            peg$currPos = s0;
                                                                                                                            s0 = peg$FAILED;
                                                                                                                          }
                                                                                                                        } else {
                                                                                                                          peg$currPos = s0;
                                                                                                                          s0 = peg$FAILED;
                                                                                                                        }
                                                                                                                        if (s0 === peg$FAILED) {
                                                                                                                          s0 = peg$currPos;
                                                                                                                          if (input.substr(peg$currPos, 7) === peg$c1110) {
                                                                                                                            s1 = peg$c1110;
                                                                                                                            peg$currPos += 7;
                                                                                                                          } else {
                                                                                                                            s1 = peg$FAILED;
                                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c1111); }
                                                                                                                          }
                                                                                                                          if (s1 !== peg$FAILED) {
                                                                                                                            s2 = peg$parse_();
                                                                                                                            if (s2 !== peg$FAILED) {
                                                                                                                              if (input.substr(peg$currPos, 4) === peg$c1090) {
                                                                                                                                s3 = peg$c1090;
                                                                                                                                peg$currPos += 4;
                                                                                                                              } else {
                                                                                                                                s3 = peg$FAILED;
                                                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c1091); }
                                                                                                                              }
                                                                                                                              if (s3 !== peg$FAILED) {
                                                                                                                                s4 = peg$parse_();
                                                                                                                                if (s4 !== peg$FAILED) {
                                                                                                                                  s5 = peg$parseSchool();
                                                                                                                                  if (s5 !== peg$FAILED) {
                                                                                                                                    s6 = peg$parse_();
                                                                                                                                    if (s6 !== peg$FAILED) {
                                                                                                                                      if (input.substr(peg$currPos, 7) === peg$c35) {
                                                                                                                                        s7 = peg$c35;
                                                                                                                                        peg$currPos += 7;
                                                                                                                                      } else {
                                                                                                                                        s7 = peg$FAILED;
                                                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c36); }
                                                                                                                                      }
                                                                                                                                      if (s7 !== peg$FAILED) {
                                                                                                                                        s8 = peg$parse_();
                                                                                                                                        if (s8 !== peg$FAILED) {
                                                                                                                                          if (input.substr(peg$currPos, 4) === peg$c733) {
                                                                                                                                            s9 = peg$c733;
                                                                                                                                            peg$currPos += 4;
                                                                                                                                          } else {
                                                                                                                                            s9 = peg$FAILED;
                                                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c779); }
                                                                                                                                          }
                                                                                                                                          if (s9 !== peg$FAILED) {
                                                                                                                                            s10 = peg$parse_();
                                                                                                                                            if (s10 !== peg$FAILED) {
                                                                                                                                              if (input.charCodeAt(peg$currPos) === 40) {
                                                                                                                                                s11 = peg$c1043;
                                                                                                                                                peg$currPos++;
                                                                                                                                              } else {
                                                                                                                                                s11 = peg$FAILED;
                                                                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c1044); }
                                                                                                                                              }
                                                                                                                                              if (s11 !== peg$FAILED) {
                                                                                                                                                s12 = peg$parse_();
                                                                                                                                                if (s12 !== peg$FAILED) {
                                                                                                                                                  s13 = peg$parseIntegerSlashList();
                                                                                                                                                  if (s13 !== peg$FAILED) {
                                                                                                                                                    s14 = peg$parse_();
                                                                                                                                                    if (s14 !== peg$FAILED) {
                                                                                                                                                      if (input.charCodeAt(peg$currPos) === 41) {
                                                                                                                                                        s15 = peg$c508;
                                                                                                                                                        peg$currPos++;
                                                                                                                                                      } else {
                                                                                                                                                        s15 = peg$FAILED;
                                                                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c509); }
                                                                                                                                                      }
                                                                                                                                                      if (s15 !== peg$FAILED) {
                                                                                                                                                        peg$savedPos = s0;
                                                                                                                                                        s1 = peg$c1259(s5, s13);
                                                                                                                                                        s0 = s1;
                                                                                                                                                      } else {
                                                                                                                                                        peg$currPos = s0;
                                                                                                                                                        s0 = peg$FAILED;
                                                                                                                                                      }
                                                                                                                                                    } else {
                                                                                                                                                      peg$currPos = s0;
                                                                                                                                                      s0 = peg$FAILED;
                                                                                                                                                    }
                                                                                                                                                  } else {
                                                                                                                                                    peg$currPos = s0;
                                                                                                                                                    s0 = peg$FAILED;
                                                                                                                                                  }
                                                                                                                                                } else {
                                                                                                                                                  peg$currPos = s0;
                                                                                                                                                  s0 = peg$FAILED;
                                                                                                                                                }
                                                                                                                                              } else {
                                                                                                                                                peg$currPos = s0;
                                                                                                                                                s0 = peg$FAILED;
                                                                                                                                              }
                                                                                                                                            } else {
                                                                                                                                              peg$currPos = s0;
                                                                                                                                              s0 = peg$FAILED;
                                                                                                                                            }
                                                                                                                                          } else {
                                                                                                                                            peg$currPos = s0;
                                                                                                                                            s0 = peg$FAILED;
                                                                                                                                          }
                                                                                                                                        } else {
                                                                                                                                          peg$currPos = s0;
                                                                                                                                          s0 = peg$FAILED;
                                                                                                                                        }
                                                                                                                                      } else {
                                                                                                                                        peg$currPos = s0;
                                                                                                                                        s0 = peg$FAILED;
                                                                                                                                      }
                                                                                                                                    } else {
                                                                                                                                      peg$currPos = s0;
                                                                                                                                      s0 = peg$FAILED;
                                                                                                                                    }
                                                                                                                                  } else {
                                                                                                                                    peg$currPos = s0;
                                                                                                                                    s0 = peg$FAILED;
                                                                                                                                  }
                                                                                                                                } else {
                                                                                                                                  peg$currPos = s0;
                                                                                                                                  s0 = peg$FAILED;
                                                                                                                                }
                                                                                                                              } else {
                                                                                                                                peg$currPos = s0;
                                                                                                                                s0 = peg$FAILED;
                                                                                                                              }
                                                                                                                            } else {
                                                                                                                              peg$currPos = s0;
                                                                                                                              s0 = peg$FAILED;
                                                                                                                            }
                                                                                                                          } else {
                                                                                                                            peg$currPos = s0;
                                                                                                                            s0 = peg$FAILED;
                                                                                                                          }
                                                                                                                          if (s0 === peg$FAILED) {
                                                                                                                            s0 = peg$currPos;
                                                                                                                            if (input.substr(peg$currPos, 2) === peg$c1070) {
                                                                                                                              s1 = peg$c1070;
                                                                                                                              peg$currPos += 2;
                                                                                                                            } else {
                                                                                                                              s1 = peg$FAILED;
                                                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c1071); }
                                                                                                                            }
                                                                                                                            if (s1 !== peg$FAILED) {
                                                                                                                              s2 = peg$parse_();
                                                                                                                              if (s2 !== peg$FAILED) {
                                                                                                                                if (input.substr(peg$currPos, 4) === peg$c1260) {
                                                                                                                                  s3 = peg$c1260;
                                                                                                                                  peg$currPos += 4;
                                                                                                                                } else {
                                                                                                                                  s3 = peg$FAILED;
                                                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c1261); }
                                                                                                                                }
                                                                                                                                if (s3 !== peg$FAILED) {
                                                                                                                                  s4 = peg$parse_();
                                                                                                                                  if (s4 !== peg$FAILED) {
                                                                                                                                    if (input.substr(peg$currPos, 9) === peg$c1262) {
                                                                                                                                      s5 = peg$c1262;
                                                                                                                                      peg$currPos += 9;
                                                                                                                                    } else {
                                                                                                                                      s5 = peg$FAILED;
                                                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c1263); }
                                                                                                                                    }
                                                                                                                                    if (s5 !== peg$FAILED) {
                                                                                                                                      s6 = peg$currPos;
                                                                                                                                      s7 = peg$parse_();
                                                                                                                                      if (s7 !== peg$FAILED) {
                                                                                                                                        if (input.substr(peg$currPos, 2) === peg$c214) {
                                                                                                                                          s8 = peg$c214;
                                                                                                                                          peg$currPos += 2;
                                                                                                                                        } else {
                                                                                                                                          s8 = peg$FAILED;
                                                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c215); }
                                                                                                                                        }
                                                                                                                                        if (s8 !== peg$FAILED) {
                                                                                                                                          s9 = peg$parse_();
                                                                                                                                          if (s9 !== peg$FAILED) {
                                                                                                                                            if (input.substr(peg$currPos, 3) === peg$c576) {
                                                                                                                                              s10 = peg$c576;
                                                                                                                                              peg$currPos += 3;
                                                                                                                                            } else {
                                                                                                                                              s10 = peg$FAILED;
                                                                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c577); }
                                                                                                                                            }
                                                                                                                                            if (s10 !== peg$FAILED) {
                                                                                                                                              s11 = peg$parse_();
                                                                                                                                              if (s11 !== peg$FAILED) {
                                                                                                                                                if (input.substr(peg$currPos, 10) === peg$c1264) {
                                                                                                                                                  s12 = peg$c1264;
                                                                                                                                                  peg$currPos += 10;
                                                                                                                                                } else {
                                                                                                                                                  s12 = peg$FAILED;
                                                                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c1265); }
                                                                                                                                                }
                                                                                                                                                if (s12 !== peg$FAILED) {
                                                                                                                                                  s13 = peg$parse_();
                                                                                                                                                  if (s13 !== peg$FAILED) {
                                                                                                                                                    if (input.substr(peg$currPos, 7) === peg$c256) {
                                                                                                                                                      s14 = peg$c256;
                                                                                                                                                      peg$currPos += 7;
                                                                                                                                                    } else {
                                                                                                                                                      s14 = peg$FAILED;
                                                                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c257); }
                                                                                                                                                    }
                                                                                                                                                    if (s14 !== peg$FAILED) {
                                                                                                                                                      s7 = [s7, s8, s9, s10, s11, s12, s13, s14];
                                                                                                                                                      s6 = s7;
                                                                                                                                                    } else {
                                                                                                                                                      peg$currPos = s6;
                                                                                                                                                      s6 = peg$FAILED;
                                                                                                                                                    }
                                                                                                                                                  } else {
                                                                                                                                                    peg$currPos = s6;
                                                                                                                                                    s6 = peg$FAILED;
                                                                                                                                                  }
                                                                                                                                                } else {
                                                                                                                                                  peg$currPos = s6;
                                                                                                                                                  s6 = peg$FAILED;
                                                                                                                                                }
                                                                                                                                              } else {
                                                                                                                                                peg$currPos = s6;
                                                                                                                                                s6 = peg$FAILED;
                                                                                                                                              }
                                                                                                                                            } else {
                                                                                                                                              peg$currPos = s6;
                                                                                                                                              s6 = peg$FAILED;
                                                                                                                                            }
                                                                                                                                          } else {
                                                                                                                                            peg$currPos = s6;
                                                                                                                                            s6 = peg$FAILED;
                                                                                                                                          }
                                                                                                                                        } else {
                                                                                                                                          peg$currPos = s6;
                                                                                                                                          s6 = peg$FAILED;
                                                                                                                                        }
                                                                                                                                      } else {
                                                                                                                                        peg$currPos = s6;
                                                                                                                                        s6 = peg$FAILED;
                                                                                                                                      }
                                                                                                                                      if (s6 === peg$FAILED) {
                                                                                                                                        s6 = null;
                                                                                                                                      }
                                                                                                                                      if (s6 !== peg$FAILED) {
                                                                                                                                        peg$savedPos = s0;
                                                                                                                                        s1 = peg$c1266();
                                                                                                                                        s0 = s1;
                                                                                                                                      } else {
                                                                                                                                        peg$currPos = s0;
                                                                                                                                        s0 = peg$FAILED;
                                                                                                                                      }
                                                                                                                                    } else {
                                                                                                                                      peg$currPos = s0;
                                                                                                                                      s0 = peg$FAILED;
                                                                                                                                    }
                                                                                                                                  } else {
                                                                                                                                    peg$currPos = s0;
                                                                                                                                    s0 = peg$FAILED;
                                                                                                                                  }
                                                                                                                                } else {
                                                                                                                                  peg$currPos = s0;
                                                                                                                                  s0 = peg$FAILED;
                                                                                                                                }
                                                                                                                              } else {
                                                                                                                                peg$currPos = s0;
                                                                                                                                s0 = peg$FAILED;
                                                                                                                              }
                                                                                                                            } else {
                                                                                                                              peg$currPos = s0;
                                                                                                                              s0 = peg$FAILED;
                                                                                                                            }
                                                                                                                            if (s0 === peg$FAILED) {
                                                                                                                              s0 = peg$currPos;
                                                                                                                              if (input.substr(peg$currPos, 2) === peg$c1070) {
                                                                                                                                s1 = peg$c1070;
                                                                                                                                peg$currPos += 2;
                                                                                                                              } else {
                                                                                                                                s1 = peg$FAILED;
                                                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c1071); }
                                                                                                                              }
                                                                                                                              if (s1 !== peg$FAILED) {
                                                                                                                                s2 = peg$parse_();
                                                                                                                                if (s2 !== peg$FAILED) {
                                                                                                                                  if (input.substr(peg$currPos, 7) === peg$c256) {
                                                                                                                                    s3 = peg$c256;
                                                                                                                                    peg$currPos += 7;
                                                                                                                                  } else {
                                                                                                                                    s3 = peg$FAILED;
                                                                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c257); }
                                                                                                                                  }
                                                                                                                                  if (s3 !== peg$FAILED) {
                                                                                                                                    s4 = peg$parse_();
                                                                                                                                    if (s4 !== peg$FAILED) {
                                                                                                                                      if (input.substr(peg$currPos, 4) === peg$c1260) {
                                                                                                                                        s5 = peg$c1260;
                                                                                                                                        peg$currPos += 4;
                                                                                                                                      } else {
                                                                                                                                        s5 = peg$FAILED;
                                                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c1261); }
                                                                                                                                      }
                                                                                                                                      if (s5 !== peg$FAILED) {
                                                                                                                                        s6 = peg$parse_();
                                                                                                                                        if (s6 !== peg$FAILED) {
                                                                                                                                          if (input.substr(peg$currPos, 9) === peg$c1262) {
                                                                                                                                            s7 = peg$c1262;
                                                                                                                                            peg$currPos += 9;
                                                                                                                                          } else {
                                                                                                                                            s7 = peg$FAILED;
                                                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c1263); }
                                                                                                                                          }
                                                                                                                                          if (s7 !== peg$FAILED) {
                                                                                                                                            peg$savedPos = s0;
                                                                                                                                            s1 = peg$c1266();
                                                                                                                                            s0 = s1;
                                                                                                                                          } else {
                                                                                                                                            peg$currPos = s0;
                                                                                                                                            s0 = peg$FAILED;
                                                                                                                                          }
                                                                                                                                        } else {
                                                                                                                                          peg$currPos = s0;
                                                                                                                                          s0 = peg$FAILED;
                                                                                                                                        }
                                                                                                                                      } else {
                                                                                                                                        peg$currPos = s0;
                                                                                                                                        s0 = peg$FAILED;
                                                                                                                                      }
                                                                                                                                    } else {
                                                                                                                                      peg$currPos = s0;
                                                                                                                                      s0 = peg$FAILED;
                                                                                                                                    }
                                                                                                                                  } else {
                                                                                                                                    peg$currPos = s0;
                                                                                                                                    s0 = peg$FAILED;
                                                                                                                                  }
                                                                                                                                } else {
                                                                                                                                  peg$currPos = s0;
                                                                                                                                  s0 = peg$FAILED;
                                                                                                                                }
                                                                                                                              } else {
                                                                                                                                peg$currPos = s0;
                                                                                                                                s0 = peg$FAILED;
                                                                                                                              }
                                                                                                                              if (s0 === peg$FAILED) {
                                                                                                                                s0 = peg$currPos;
                                                                                                                                if (input.substr(peg$currPos, 2) === peg$c1070) {
                                                                                                                                  s1 = peg$c1070;
                                                                                                                                  peg$currPos += 2;
                                                                                                                                } else {
                                                                                                                                  s1 = peg$FAILED;
                                                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c1071); }
                                                                                                                                }
                                                                                                                                if (s1 !== peg$FAILED) {
                                                                                                                                  s2 = peg$parse_();
                                                                                                                                  if (s2 !== peg$FAILED) {
                                                                                                                                    s3 = peg$parseGenericName();
                                                                                                                                    if (s3 !== peg$FAILED) {
                                                                                                                                      peg$savedPos = s0;
                                                                                                                                      s1 = peg$c1267(s3);
                                                                                                                                      s0 = s1;
                                                                                                                                    } else {
                                                                                                                                      peg$currPos = s0;
                                                                                                                                      s0 = peg$FAILED;
                                                                                                                                    }
                                                                                                                                  } else {
                                                                                                                                    peg$currPos = s0;
                                                                                                                                    s0 = peg$FAILED;
                                                                                                                                  }
                                                                                                                                } else {
                                                                                                                                  peg$currPos = s0;
                                                                                                                                  s0 = peg$FAILED;
                                                                                                                                }
                                                                                                                                if (s0 === peg$FAILED) {
                                                                                                                                  s0 = peg$currPos;
                                                                                                                                  if (input.substr(peg$currPos, 2) === peg$c1070) {
                                                                                                                                    s1 = peg$c1070;
                                                                                                                                    peg$currPos += 2;
                                                                                                                                  } else {
                                                                                                                                    s1 = peg$FAILED;
                                                                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c1071); }
                                                                                                                                  }
                                                                                                                                  if (s1 !== peg$FAILED) {
                                                                                                                                    s2 = peg$parse_();
                                                                                                                                    if (s2 !== peg$FAILED) {
                                                                                                                                      s3 = peg$parseIntegerSlashList();
                                                                                                                                      if (s3 !== peg$FAILED) {
                                                                                                                                        s4 = peg$parse_();
                                                                                                                                        if (s4 !== peg$FAILED) {
                                                                                                                                          s5 = peg$parseStat();
                                                                                                                                          if (s5 !== peg$FAILED) {
                                                                                                                                            peg$savedPos = s0;
                                                                                                                                            s1 = peg$c1268(s3, s5);
                                                                                                                                            s0 = s1;
                                                                                                                                          } else {
                                                                                                                                            peg$currPos = s0;
                                                                                                                                            s0 = peg$FAILED;
                                                                                                                                          }
                                                                                                                                        } else {
                                                                                                                                          peg$currPos = s0;
                                                                                                                                          s0 = peg$FAILED;
                                                                                                                                        }
                                                                                                                                      } else {
                                                                                                                                        peg$currPos = s0;
                                                                                                                                        s0 = peg$FAILED;
                                                                                                                                      }
                                                                                                                                    } else {
                                                                                                                                      peg$currPos = s0;
                                                                                                                                      s0 = peg$FAILED;
                                                                                                                                    }
                                                                                                                                  } else {
                                                                                                                                    peg$currPos = s0;
                                                                                                                                    s0 = peg$FAILED;
                                                                                                                                  }
                                                                                                                                  if (s0 === peg$FAILED) {
                                                                                                                                    s0 = peg$currPos;
                                                                                                                                    if (input.substr(peg$currPos, 30) === peg$c1269) {
                                                                                                                                      s1 = peg$c1269;
                                                                                                                                      peg$currPos += 30;
                                                                                                                                    } else {
                                                                                                                                      s1 = peg$FAILED;
                                                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c1270); }
                                                                                                                                    }
                                                                                                                                    if (s1 !== peg$FAILED) {
                                                                                                                                      peg$savedPos = s0;
                                                                                                                                      s1 = peg$c1271();
                                                                                                                                    }
                                                                                                                                    s0 = s1;
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseWithoutWith() {
    var s0, s1;

    var key    = peg$currPos * 255 + 170,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 10) === peg$c1272) {
      s1 = peg$c1272;
      peg$currPos += 10;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1273); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c1274();
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 6) === peg$c697) {
        s1 = peg$c697;
        peg$currPos += 6;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c698); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1275();
      }
      s0 = s1;
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 3) === peg$c1075) {
          s1 = peg$c1075;
          peg$currPos += 3;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1076); }
        }
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c502();
        }
        s0 = s1;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseConditionDetail() {
    var s0;

    var key    = peg$currPos * 255 + 171,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    if (input.substr(peg$currPos, 37) === peg$c1276) {
      s0 = peg$c1276;
      peg$currPos += 37;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1277); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseS() {
    var s0, s1, s2;

    var key    = peg$currPos * 255 + 172,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 115) {
      s1 = peg$c164;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c165); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = peg$currPos;
      s2 = peg$c1278();
      if (s2) {
        s2 = void 0;
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$c5;
      if (s1 !== peg$FAILED) {
        peg$savedPos = peg$currPos;
        s2 = peg$c1279();
        if (s2) {
          s2 = void 0;
        } else {
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSmartEtherStatus() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 255 + 173,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseSchool();
    if (s1 === peg$FAILED) {
      s1 = null;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 5).toLowerCase() === peg$c1280) {
          s3 = input.substr(peg$currPos, 5);
          peg$currPos += 5;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1281); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 5) === peg$c1282) {
              s5 = peg$c1282;
              peg$currPos += 5;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c1283); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseIntegerSlashList();
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c1284(s1, s7);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatusVerb() {
    var s0, s1, s2, s3, s4;

    var key    = peg$currPos * 255 + 174,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$currPos;
    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c614) {
      s2 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1285); }
    }
    if (s2 !== peg$FAILED) {
      s3 = peg$parseS();
      if (s3 !== peg$FAILED) {
        s2 = [s2, s3];
        s1 = s2;
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      s1 = peg$currPos;
      if (input.substr(peg$currPos, 5).toLowerCase() === peg$c1286) {
        s2 = input.substr(peg$currPos, 5);
        peg$currPos += 5;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1287); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseS();
        if (s3 !== peg$FAILED) {
          s2 = [s2, s3];
          s1 = s2;
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
      if (s1 === peg$FAILED) {
        s1 = peg$currPos;
        if (input.substr(peg$currPos, 6).toLowerCase() === peg$c584) {
          s2 = input.substr(peg$currPos, 6);
          peg$currPos += 6;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c585); }
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parseS();
          if (s3 !== peg$FAILED) {
            s2 = [s2, s3];
            s1 = s2;
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
        if (s1 === peg$FAILED) {
          s1 = peg$currPos;
          if (input.substr(peg$currPos, 7).toLowerCase() === peg$c1288) {
            s2 = input.substr(peg$currPos, 7);
            peg$currPos += 7;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1289); }
          }
          if (s2 !== peg$FAILED) {
            s3 = peg$parse_();
            if (s3 !== peg$FAILED) {
              if (input.substr(peg$currPos, 6) === peg$c584) {
                s4 = peg$c584;
                peg$currPos += 6;
              } else {
                s4 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c1290); }
              }
              if (s4 !== peg$FAILED) {
                s2 = [s2, s3, s4];
                s1 = s2;
              } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
              }
            } else {
              peg$currPos = s1;
              s1 = peg$FAILED;
            }
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        }
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c1291();
    }
    s0 = s1;

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatusName() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 175,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 91) {
      s1 = peg$c1293;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1294); }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      if (peg$c1295.test(input.charAt(peg$currPos))) {
        s3 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1296); }
      }
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          if (peg$c1295.test(input.charAt(peg$currPos))) {
            s3 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1296); }
          }
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 93) {
          s3 = peg$c1297;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1298); }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c1299(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 63) {
        s1 = peg$c9;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c10); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1063();
      }
      s0 = s1;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1292); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatModDuration() {
    var s0, s1, s2, s3, s4;

    var key    = peg$currPos * 255 + 176,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parse_();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 40) {
        s2 = peg$c1043;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1044); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseInteger();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c1300) {
            s4 = peg$c1300;
            peg$currPos += 2;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1301); }
          }
          if (s4 !== peg$FAILED) {
            s1 = [s1, s2, s3, s4];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAbilityName() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 177,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseUppercaseWord();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parse_();
      if (s4 !== peg$FAILED) {
        s5 = peg$parseUppercaseWord();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parse_();
        if (s4 !== peg$FAILED) {
          s5 = peg$parseUppercaseWord();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1063();
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseCharacterName() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    var key    = peg$currPos * 255 + 178,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseUppercaseWord();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parse_();
      if (s4 !== peg$FAILED) {
        s5 = peg$parseUppercaseWord();
        if (s5 === peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c214) {
            s5 = peg$c214;
            peg$currPos += 2;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c215); }
          }
        }
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parse_();
        if (s4 !== peg$FAILED) {
          s5 = peg$parseUppercaseWord();
          if (s5 === peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c214) {
              s5 = peg$c214;
              peg$currPos += 2;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c215); }
            }
          }
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        s4 = peg$parse_();
        if (s4 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 40) {
            s5 = peg$c1043;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1044); }
          }
          if (s5 !== peg$FAILED) {
            if (peg$c1302.test(input.charAt(peg$currPos))) {
              s6 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c1303); }
            }
            if (s6 !== peg$FAILED) {
              s7 = [];
              if (peg$c1304.test(input.charAt(peg$currPos))) {
                s8 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s8 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c1305); }
              }
              if (s8 !== peg$FAILED) {
                while (s8 !== peg$FAILED) {
                  s7.push(s8);
                  if (peg$c1304.test(input.charAt(peg$currPos))) {
                    s8 = input.charAt(peg$currPos);
                    peg$currPos++;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c1305); }
                  }
                }
              } else {
                s7 = peg$FAILED;
              }
              if (s7 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 41) {
                  s8 = peg$c508;
                  peg$currPos++;
                } else {
                  s8 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c509); }
                }
                if (s8 !== peg$FAILED) {
                  s4 = [s4, s5, s6, s7, s8];
                  s3 = s4;
                } else {
                  peg$currPos = s3;
                  s3 = peg$FAILED;
                }
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c1063();
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseCharacterNameOrSelf() {
    var s0, s1;

    var key    = peg$currPos * 255 + 179,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$parseCharacterName();
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 8) === peg$c1306) {
        s1 = peg$c1306;
        peg$currPos += 8;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1307); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1308();
      }
      s0 = s1;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseCharacterNameList() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 255 + 180,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseCharacterName();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 47) {
        s4 = peg$c967;
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c968); }
      }
      if (s4 === peg$FAILED) {
        s4 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 44) {
          s5 = peg$c0;
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1); }
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          if (s6 !== peg$FAILED) {
            s5 = [s5, s6];
            s4 = s5;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        if (s4 === peg$FAILED) {
          s4 = peg$currPos;
          s5 = peg$parse_();
          if (s5 !== peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c964) {
              s6 = peg$c964;
              peg$currPos += 2;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c965); }
            }
            if (s6 !== peg$FAILED) {
              s7 = peg$parse_();
              if (s7 !== peg$FAILED) {
                s5 = [s5, s6, s7];
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        }
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parseCharacterNameOrSelf();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 47) {
          s4 = peg$c967;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c968); }
        }
        if (s4 === peg$FAILED) {
          s4 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 44) {
            s5 = peg$c0;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1); }
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            if (s6 !== peg$FAILED) {
              s5 = [s5, s6];
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 === peg$FAILED) {
            s4 = peg$currPos;
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 2) === peg$c964) {
                s6 = peg$c964;
                peg$currPos += 2;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c965); }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  s5 = [s5, s6, s7];
                  s4 = s5;
                } else {
                  peg$currPos = s4;
                  s4 = peg$FAILED;
                }
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseCharacterNameOrSelf();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1309(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseCharacterNameAndList() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 255 + 181,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseCharacterName();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 47) {
        s4 = peg$c967;
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c968); }
      }
      if (s4 === peg$FAILED) {
        s4 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 44) {
          s5 = peg$c0;
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1); }
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          if (s6 !== peg$FAILED) {
            s5 = [s5, s6];
            s4 = s5;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        if (s4 === peg$FAILED) {
          s4 = peg$currPos;
          s5 = peg$parse_();
          if (s5 !== peg$FAILED) {
            if (input.substr(peg$currPos, 3) === peg$c530) {
              s6 = peg$c530;
              peg$currPos += 3;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c531); }
            }
            if (s6 !== peg$FAILED) {
              s7 = peg$parse_();
              if (s7 !== peg$FAILED) {
                s5 = [s5, s6, s7];
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        }
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parseCharacterNameOrSelf();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 47) {
          s4 = peg$c967;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c968); }
        }
        if (s4 === peg$FAILED) {
          s4 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 44) {
            s5 = peg$c0;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1); }
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            if (s6 !== peg$FAILED) {
              s5 = [s5, s6];
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 === peg$FAILED) {
            s4 = peg$currPos;
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 3) === peg$c530) {
                s6 = peg$c530;
                peg$currPos += 3;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c531); }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  s5 = [s5, s6, s7];
                  s4 = s5;
                } else {
                  peg$currPos = s4;
                  s4 = peg$FAILED;
                }
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseCharacterNameOrSelf();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1309(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseCharacterNameListOrPronoun() {
    var s0, s1;

    var key    = peg$currPos * 255 + 182,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$parseCharacterNameList();
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 2) === peg$c1310) {
        s1 = peg$c1310;
        peg$currPos += 2;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1311); }
      }
      if (s1 === peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c1312) {
          s1 = peg$c1312;
          peg$currPos += 3;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1313); }
        }
        if (s1 === peg$FAILED) {
          if (input.substr(peg$currPos, 4) === peg$c1314) {
            s1 = peg$c1314;
            peg$currPos += 4;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1315); }
          }
        }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c502();
      }
      s0 = s1;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAnySkillName() {
    var s0;

    var key    = peg$currPos * 255 + 183,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$parseGenericName();
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 3) === peg$c1316) {
        s0 = peg$c1316;
        peg$currPos += 3;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1317); }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAnySkillOrOptions() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 255 + 184,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseAnySkillName();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parse_();
      if (s4 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 47) {
          s5 = peg$c967;
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c968); }
        }
        if (s5 === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 44) {
            s5 = peg$c0;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1); }
          }
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          if (s6 !== peg$FAILED) {
            s7 = peg$parseAnySkillName();
            if (s7 !== peg$FAILED) {
              s4 = [s4, s5, s6, s7];
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$currPos;
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 47) {
              s5 = peg$c967;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c968); }
            }
            if (s5 === peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 44) {
                s5 = peg$c0;
                peg$currPos++;
              } else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c1); }
              }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseAnySkillName();
                if (s7 !== peg$FAILED) {
                  s4 = [s4, s5, s6, s7];
                  s3 = s4;
                } else {
                  peg$currPos = s3;
                  s3 = peg$FAILED;
                }
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1318(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseAnySkillName();
      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$silentFails++;
        s3 = peg$currPos;
        s4 = peg$parse_();
        if (s4 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 47) {
            s5 = peg$c967;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c968); }
          }
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        peg$silentFails--;
        if (s3 === peg$FAILED) {
          s2 = void 0;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c1319(s1);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseGenericName() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14;

    var key    = peg$currPos * 255 + 185,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$currPos;
    s2 = peg$parseGenericNameWord();
    if (s2 === peg$FAILED) {
      s2 = peg$currPos;
      s3 = peg$parseIntegerSlashList();
      if (s3 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 37) {
          s4 = peg$c12;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c13); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$currPos;
          peg$silentFails++;
          s6 = peg$currPos;
          s7 = peg$parse_();
          if (s7 !== peg$FAILED) {
            if (input.substr(peg$currPos, 3) === peg$c1320) {
              s8 = peg$c1320;
              peg$currPos += 3;
            } else {
              s8 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c1321); }
            }
            if (s8 !== peg$FAILED) {
              s9 = peg$parse_();
              if (s9 !== peg$FAILED) {
                if (input.substr(peg$currPos, 4) === peg$c1322) {
                  s10 = peg$c1322;
                  peg$currPos += 4;
                } else {
                  s10 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c1323); }
                }
                if (s10 !== peg$FAILED) {
                  s7 = [s7, s8, s9, s10];
                  s6 = s7;
                } else {
                  peg$currPos = s6;
                  s6 = peg$FAILED;
                }
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
          } else {
            peg$currPos = s6;
            s6 = peg$FAILED;
          }
          peg$silentFails--;
          if (s6 === peg$FAILED) {
            s5 = void 0;
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            if (s6 !== peg$FAILED) {
              s7 = peg$parseGenericNameWord();
              if (s7 !== peg$FAILED) {
                s3 = [s3, s4, s5, s6, s7];
                s2 = s3;
              } else {
                peg$currPos = s2;
                s2 = peg$FAILED;
              }
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 === peg$FAILED) {
        s2 = peg$currPos;
        s3 = peg$parseSignedIntegerSlashList();
        if (s3 !== peg$FAILED) {
          if (peg$c1324.test(input.charAt(peg$currPos))) {
            s4 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1325); }
          }
          if (s4 === peg$FAILED) {
            s4 = null;
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseGenericNameWord();
              if (s6 !== peg$FAILED) {
                s3 = [s3, s4, s5, s6];
                s2 = s3;
              } else {
                peg$currPos = s2;
                s2 = peg$FAILED;
              }
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 === peg$FAILED) {
          s2 = peg$currPos;
          if (input.substr(peg$currPos, 3) === peg$c1326) {
            s3 = peg$c1326;
            peg$currPos += 3;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1327); }
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parseGenericNameWord();
            if (s4 !== peg$FAILED) {
              s3 = [s3, s4];
              s2 = s3;
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        }
      }
    }
    if (s2 !== peg$FAILED) {
      s3 = [];
      s4 = peg$currPos;
      s5 = peg$parse_();
      if (s5 !== peg$FAILED) {
        s6 = peg$parseGenericNameWord();
        if (s6 === peg$FAILED) {
          s6 = peg$currPos;
          if (input.substr(peg$currPos, 2) === peg$c1141) {
            s7 = peg$c1141;
            peg$currPos += 2;
          } else {
            s7 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1142); }
          }
          if (s7 === peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c964) {
              s7 = peg$c964;
              peg$currPos += 2;
            } else {
              s7 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c965); }
            }
            if (s7 === peg$FAILED) {
              if (input.substr(peg$currPos, 2) === peg$c214) {
                s7 = peg$c214;
                peg$currPos += 2;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c215); }
              }
              if (s7 === peg$FAILED) {
                if (input.substr(peg$currPos, 3) === peg$c576) {
                  s7 = peg$c576;
                  peg$currPos += 3;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c577); }
                }
                if (s7 === peg$FAILED) {
                  if (input.substr(peg$currPos, 4) === peg$c1090) {
                    s7 = peg$c1090;
                    peg$currPos += 4;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c1091); }
                  }
                  if (s7 === peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 38) {
                      s7 = peg$c1328;
                      peg$currPos++;
                    } else {
                      s7 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c1329); }
                    }
                    if (s7 === peg$FAILED) {
                      if (input.charCodeAt(peg$currPos) === 97) {
                        s7 = peg$c1061;
                        peg$currPos++;
                      } else {
                        s7 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c1062); }
                      }
                    }
                  }
                }
              }
            }
          }
          if (s7 !== peg$FAILED) {
            s8 = peg$currPos;
            peg$silentFails++;
            if (input.charCodeAt(peg$currPos) === 32) {
              s9 = peg$c1330;
              peg$currPos++;
            } else {
              s9 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c1331); }
            }
            peg$silentFails--;
            if (s9 !== peg$FAILED) {
              peg$currPos = s8;
              s8 = void 0;
            } else {
              s8 = peg$FAILED;
            }
            if (s8 !== peg$FAILED) {
              s7 = [s7, s8];
              s6 = s7;
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
          } else {
            peg$currPos = s6;
            s6 = peg$FAILED;
          }
          if (s6 === peg$FAILED) {
            s6 = peg$currPos;
            if (input.substr(peg$currPos, 3) === peg$c16) {
              s7 = peg$c16;
              peg$currPos += 3;
            } else {
              s7 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c17); }
            }
            if (s7 === peg$FAILED) {
              if (input.substr(peg$currPos, 2) === peg$c1021) {
                s7 = peg$c1021;
                peg$currPos += 2;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c1022); }
              }
              if (s7 === peg$FAILED) {
                if (input.substr(peg$currPos, 3) === peg$c530) {
                  s7 = peg$c530;
                  peg$currPos += 3;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c531); }
                }
                if (s7 === peg$FAILED) {
                  if (input.substr(peg$currPos, 2) === peg$c51) {
                    s7 = peg$c51;
                    peg$currPos += 2;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c52); }
                  }
                }
              }
            }
            if (s7 !== peg$FAILED) {
              s8 = peg$parse_();
              if (s8 !== peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c1332) {
                  s9 = peg$c1332;
                  peg$currPos += 2;
                } else {
                  s9 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c1333); }
                }
                if (s9 === peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 97) {
                    s9 = peg$c1061;
                    peg$currPos++;
                  } else {
                    s9 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c1062); }
                  }
                }
                if (s9 === peg$FAILED) {
                  s9 = null;
                }
                if (s9 !== peg$FAILED) {
                  s10 = peg$parse_();
                  if (s10 !== peg$FAILED) {
                    s11 = peg$parseGenericNameWord();
                    if (s11 !== peg$FAILED) {
                      s7 = [s7, s8, s9, s10, s11];
                      s6 = s7;
                    } else {
                      peg$currPos = s6;
                      s6 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s6;
                    s6 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s6;
                  s6 = peg$FAILED;
                }
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
            if (s6 === peg$FAILED) {
              s6 = peg$currPos;
              if (peg$c1334.test(input.charAt(peg$currPos))) {
                s7 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c1335); }
              }
              if (s7 === peg$FAILED) {
                s7 = null;
              }
              if (s7 !== peg$FAILED) {
                s8 = peg$parseInteger();
                if (s8 !== peg$FAILED) {
                  s9 = [];
                  s10 = peg$currPos;
                  if (peg$c1336.test(input.charAt(peg$currPos))) {
                    s11 = input.charAt(peg$currPos);
                    peg$currPos++;
                  } else {
                    s11 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c1337); }
                  }
                  if (s11 === peg$FAILED) {
                    s11 = null;
                  }
                  if (s11 !== peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 47) {
                      s12 = peg$c967;
                      peg$currPos++;
                    } else {
                      s12 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c968); }
                    }
                    if (s12 !== peg$FAILED) {
                      if (peg$c7.test(input.charAt(peg$currPos))) {
                        s13 = input.charAt(peg$currPos);
                        peg$currPos++;
                      } else {
                        s13 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c8); }
                      }
                      if (s13 === peg$FAILED) {
                        s13 = null;
                      }
                      if (s13 !== peg$FAILED) {
                        s14 = peg$parseInteger();
                        if (s14 !== peg$FAILED) {
                          s11 = [s11, s12, s13, s14];
                          s10 = s11;
                        } else {
                          peg$currPos = s10;
                          s10 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s10;
                        s10 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s10;
                      s10 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s10;
                    s10 = peg$FAILED;
                  }
                  while (s10 !== peg$FAILED) {
                    s9.push(s10);
                    s10 = peg$currPos;
                    if (peg$c1336.test(input.charAt(peg$currPos))) {
                      s11 = input.charAt(peg$currPos);
                      peg$currPos++;
                    } else {
                      s11 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c1337); }
                    }
                    if (s11 === peg$FAILED) {
                      s11 = null;
                    }
                    if (s11 !== peg$FAILED) {
                      if (input.charCodeAt(peg$currPos) === 47) {
                        s12 = peg$c967;
                        peg$currPos++;
                      } else {
                        s12 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c968); }
                      }
                      if (s12 !== peg$FAILED) {
                        if (peg$c7.test(input.charAt(peg$currPos))) {
                          s13 = input.charAt(peg$currPos);
                          peg$currPos++;
                        } else {
                          s13 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c8); }
                        }
                        if (s13 === peg$FAILED) {
                          s13 = null;
                        }
                        if (s13 !== peg$FAILED) {
                          s14 = peg$parseInteger();
                          if (s14 !== peg$FAILED) {
                            s11 = [s11, s12, s13, s14];
                            s10 = s11;
                          } else {
                            peg$currPos = s10;
                            s10 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s10;
                          s10 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s10;
                        s10 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s10;
                      s10 = peg$FAILED;
                    }
                  }
                  if (s9 !== peg$FAILED) {
                    if (peg$c1324.test(input.charAt(peg$currPos))) {
                      s10 = input.charAt(peg$currPos);
                      peg$currPos++;
                    } else {
                      s10 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c1325); }
                    }
                    if (s10 === peg$FAILED) {
                      s10 = null;
                    }
                    if (s10 !== peg$FAILED) {
                      s7 = [s7, s8, s9, s10];
                      s6 = s7;
                    } else {
                      peg$currPos = s6;
                      s6 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s6;
                    s6 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s6;
                  s6 = peg$FAILED;
                }
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
              if (s6 === peg$FAILED) {
                s6 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 40) {
                  s7 = peg$c1043;
                  peg$currPos++;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c1044); }
                }
                if (s7 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 11) === peg$c1338) {
                    s8 = peg$c1338;
                    peg$currPos += 11;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c1339); }
                  }
                  if (s8 === peg$FAILED) {
                    if (input.substr(peg$currPos, 11) === peg$c1340) {
                      s8 = peg$c1340;
                      peg$currPos += 11;
                    } else {
                      s8 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c1341); }
                    }
                    if (s8 === peg$FAILED) {
                      s8 = [];
                      if (peg$c1342.test(input.charAt(peg$currPos))) {
                        s9 = input.charAt(peg$currPos);
                        peg$currPos++;
                      } else {
                        s9 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c1343); }
                      }
                      if (s9 !== peg$FAILED) {
                        while (s9 !== peg$FAILED) {
                          s8.push(s9);
                          if (peg$c1342.test(input.charAt(peg$currPos))) {
                            s9 = input.charAt(peg$currPos);
                            peg$currPos++;
                          } else {
                            s9 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c1343); }
                          }
                        }
                      } else {
                        s8 = peg$FAILED;
                      }
                    }
                  }
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parse_();
                    if (s9 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 4) === peg$c1344) {
                        s10 = peg$c1344;
                        peg$currPos += 4;
                      } else {
                        s10 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c1345); }
                      }
                      if (s10 === peg$FAILED) {
                        s10 = null;
                      }
                      if (s10 !== peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 41) {
                          s11 = peg$c508;
                          peg$currPos++;
                        } else {
                          s11 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c509); }
                        }
                        if (s11 !== peg$FAILED) {
                          s7 = [s7, s8, s9, s10, s11];
                          s6 = s7;
                        } else {
                          peg$currPos = s6;
                          s6 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s6;
                        s6 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s6;
                      s6 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s6;
                    s6 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s6;
                  s6 = peg$FAILED;
                }
              }
            }
          }
        }
        if (s6 !== peg$FAILED) {
          s5 = [s5, s6];
          s4 = s5;
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
      } else {
        peg$currPos = s4;
        s4 = peg$FAILED;
      }
      while (s4 !== peg$FAILED) {
        s3.push(s4);
        s4 = peg$currPos;
        s5 = peg$parse_();
        if (s5 !== peg$FAILED) {
          s6 = peg$parseGenericNameWord();
          if (s6 === peg$FAILED) {
            s6 = peg$currPos;
            if (input.substr(peg$currPos, 2) === peg$c1141) {
              s7 = peg$c1141;
              peg$currPos += 2;
            } else {
              s7 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c1142); }
            }
            if (s7 === peg$FAILED) {
              if (input.substr(peg$currPos, 2) === peg$c964) {
                s7 = peg$c964;
                peg$currPos += 2;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c965); }
              }
              if (s7 === peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c214) {
                  s7 = peg$c214;
                  peg$currPos += 2;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c215); }
                }
                if (s7 === peg$FAILED) {
                  if (input.substr(peg$currPos, 3) === peg$c576) {
                    s7 = peg$c576;
                    peg$currPos += 3;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c577); }
                  }
                  if (s7 === peg$FAILED) {
                    if (input.substr(peg$currPos, 4) === peg$c1090) {
                      s7 = peg$c1090;
                      peg$currPos += 4;
                    } else {
                      s7 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c1091); }
                    }
                    if (s7 === peg$FAILED) {
                      if (input.charCodeAt(peg$currPos) === 38) {
                        s7 = peg$c1328;
                        peg$currPos++;
                      } else {
                        s7 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c1329); }
                      }
                      if (s7 === peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 97) {
                          s7 = peg$c1061;
                          peg$currPos++;
                        } else {
                          s7 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c1062); }
                        }
                      }
                    }
                  }
                }
              }
            }
            if (s7 !== peg$FAILED) {
              s8 = peg$currPos;
              peg$silentFails++;
              if (input.charCodeAt(peg$currPos) === 32) {
                s9 = peg$c1330;
                peg$currPos++;
              } else {
                s9 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c1331); }
              }
              peg$silentFails--;
              if (s9 !== peg$FAILED) {
                peg$currPos = s8;
                s8 = void 0;
              } else {
                s8 = peg$FAILED;
              }
              if (s8 !== peg$FAILED) {
                s7 = [s7, s8];
                s6 = s7;
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
            if (s6 === peg$FAILED) {
              s6 = peg$currPos;
              if (input.substr(peg$currPos, 3) === peg$c16) {
                s7 = peg$c16;
                peg$currPos += 3;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c17); }
              }
              if (s7 === peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c1021) {
                  s7 = peg$c1021;
                  peg$currPos += 2;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c1022); }
                }
                if (s7 === peg$FAILED) {
                  if (input.substr(peg$currPos, 3) === peg$c530) {
                    s7 = peg$c530;
                    peg$currPos += 3;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c531); }
                  }
                  if (s7 === peg$FAILED) {
                    if (input.substr(peg$currPos, 2) === peg$c51) {
                      s7 = peg$c51;
                      peg$currPos += 2;
                    } else {
                      s7 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c52); }
                    }
                  }
                }
              }
              if (s7 !== peg$FAILED) {
                s8 = peg$parse_();
                if (s8 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 2) === peg$c1332) {
                    s9 = peg$c1332;
                    peg$currPos += 2;
                  } else {
                    s9 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c1333); }
                  }
                  if (s9 === peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 97) {
                      s9 = peg$c1061;
                      peg$currPos++;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c1062); }
                    }
                  }
                  if (s9 === peg$FAILED) {
                    s9 = null;
                  }
                  if (s9 !== peg$FAILED) {
                    s10 = peg$parse_();
                    if (s10 !== peg$FAILED) {
                      s11 = peg$parseGenericNameWord();
                      if (s11 !== peg$FAILED) {
                        s7 = [s7, s8, s9, s10, s11];
                        s6 = s7;
                      } else {
                        peg$currPos = s6;
                        s6 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s6;
                      s6 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s6;
                    s6 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s6;
                  s6 = peg$FAILED;
                }
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
              if (s6 === peg$FAILED) {
                s6 = peg$currPos;
                if (peg$c1334.test(input.charAt(peg$currPos))) {
                  s7 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c1335); }
                }
                if (s7 === peg$FAILED) {
                  s7 = null;
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseInteger();
                  if (s8 !== peg$FAILED) {
                    s9 = [];
                    s10 = peg$currPos;
                    if (peg$c1336.test(input.charAt(peg$currPos))) {
                      s11 = input.charAt(peg$currPos);
                      peg$currPos++;
                    } else {
                      s11 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c1337); }
                    }
                    if (s11 === peg$FAILED) {
                      s11 = null;
                    }
                    if (s11 !== peg$FAILED) {
                      if (input.charCodeAt(peg$currPos) === 47) {
                        s12 = peg$c967;
                        peg$currPos++;
                      } else {
                        s12 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c968); }
                      }
                      if (s12 !== peg$FAILED) {
                        if (peg$c7.test(input.charAt(peg$currPos))) {
                          s13 = input.charAt(peg$currPos);
                          peg$currPos++;
                        } else {
                          s13 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c8); }
                        }
                        if (s13 === peg$FAILED) {
                          s13 = null;
                        }
                        if (s13 !== peg$FAILED) {
                          s14 = peg$parseInteger();
                          if (s14 !== peg$FAILED) {
                            s11 = [s11, s12, s13, s14];
                            s10 = s11;
                          } else {
                            peg$currPos = s10;
                            s10 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s10;
                          s10 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s10;
                        s10 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s10;
                      s10 = peg$FAILED;
                    }
                    while (s10 !== peg$FAILED) {
                      s9.push(s10);
                      s10 = peg$currPos;
                      if (peg$c1336.test(input.charAt(peg$currPos))) {
                        s11 = input.charAt(peg$currPos);
                        peg$currPos++;
                      } else {
                        s11 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c1337); }
                      }
                      if (s11 === peg$FAILED) {
                        s11 = null;
                      }
                      if (s11 !== peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 47) {
                          s12 = peg$c967;
                          peg$currPos++;
                        } else {
                          s12 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c968); }
                        }
                        if (s12 !== peg$FAILED) {
                          if (peg$c7.test(input.charAt(peg$currPos))) {
                            s13 = input.charAt(peg$currPos);
                            peg$currPos++;
                          } else {
                            s13 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c8); }
                          }
                          if (s13 === peg$FAILED) {
                            s13 = null;
                          }
                          if (s13 !== peg$FAILED) {
                            s14 = peg$parseInteger();
                            if (s14 !== peg$FAILED) {
                              s11 = [s11, s12, s13, s14];
                              s10 = s11;
                            } else {
                              peg$currPos = s10;
                              s10 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s10;
                            s10 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s10;
                          s10 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s10;
                        s10 = peg$FAILED;
                      }
                    }
                    if (s9 !== peg$FAILED) {
                      if (peg$c1324.test(input.charAt(peg$currPos))) {
                        s10 = input.charAt(peg$currPos);
                        peg$currPos++;
                      } else {
                        s10 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c1325); }
                      }
                      if (s10 === peg$FAILED) {
                        s10 = null;
                      }
                      if (s10 !== peg$FAILED) {
                        s7 = [s7, s8, s9, s10];
                        s6 = s7;
                      } else {
                        peg$currPos = s6;
                        s6 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s6;
                      s6 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s6;
                    s6 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s6;
                  s6 = peg$FAILED;
                }
                if (s6 === peg$FAILED) {
                  s6 = peg$currPos;
                  if (input.charCodeAt(peg$currPos) === 40) {
                    s7 = peg$c1043;
                    peg$currPos++;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c1044); }
                  }
                  if (s7 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 11) === peg$c1338) {
                      s8 = peg$c1338;
                      peg$currPos += 11;
                    } else {
                      s8 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c1339); }
                    }
                    if (s8 === peg$FAILED) {
                      if (input.substr(peg$currPos, 11) === peg$c1340) {
                        s8 = peg$c1340;
                        peg$currPos += 11;
                      } else {
                        s8 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c1341); }
                      }
                      if (s8 === peg$FAILED) {
                        s8 = [];
                        if (peg$c1342.test(input.charAt(peg$currPos))) {
                          s9 = input.charAt(peg$currPos);
                          peg$currPos++;
                        } else {
                          s9 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c1343); }
                        }
                        if (s9 !== peg$FAILED) {
                          while (s9 !== peg$FAILED) {
                            s8.push(s9);
                            if (peg$c1342.test(input.charAt(peg$currPos))) {
                              s9 = input.charAt(peg$currPos);
                              peg$currPos++;
                            } else {
                              s9 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c1343); }
                            }
                          }
                        } else {
                          s8 = peg$FAILED;
                        }
                      }
                    }
                    if (s8 !== peg$FAILED) {
                      s9 = peg$parse_();
                      if (s9 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 4) === peg$c1344) {
                          s10 = peg$c1344;
                          peg$currPos += 4;
                        } else {
                          s10 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c1345); }
                        }
                        if (s10 === peg$FAILED) {
                          s10 = null;
                        }
                        if (s10 !== peg$FAILED) {
                          if (input.charCodeAt(peg$currPos) === 41) {
                            s11 = peg$c508;
                            peg$currPos++;
                          } else {
                            s11 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c509); }
                          }
                          if (s11 !== peg$FAILED) {
                            s7 = [s7, s8, s9, s10, s11];
                            s6 = s7;
                          } else {
                            peg$currPos = s6;
                            s6 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s6;
                          s6 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s6;
                        s6 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s6;
                      s6 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s6;
                    s6 = peg$FAILED;
                  }
                }
              }
            }
          }
          if (s6 !== peg$FAILED) {
            s5 = [s5, s6];
            s4 = s5;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
      }
      if (s3 !== peg$FAILED) {
        s2 = [s2, s3];
        s1 = s2;
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c1346();
    }
    s0 = s1;

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseGenericNameWord() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 186,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (peg$c1302.test(input.charAt(peg$currPos))) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1303); }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      if (peg$c1347.test(input.charAt(peg$currPos))) {
        s3 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1348); }
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        if (peg$c1347.test(input.charAt(peg$currPos))) {
          s3 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1348); }
        }
      }
      if (s2 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 58) {
          s3 = peg$c540;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c541); }
        }
        if (s3 === peg$FAILED) {
          if (input.substr(peg$currPos, 3) === peg$c1326) {
            s3 = peg$c1326;
            peg$currPos += 3;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1327); }
          }
          if (s3 === peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 33) {
              s3 = peg$c1349;
              peg$currPos++;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c1350); }
            }
            if (s3 === peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 43) {
                s3 = peg$c19;
                peg$currPos++;
              } else {
                s3 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c20); }
              }
            }
          }
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          s1 = [s1, s2, s3];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseDuration() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 255 + 187,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3) === peg$c16) {
      s1 = peg$c16;
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c17); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseInteger();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 63) {
              s5 = peg$c9;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c10); }
            }
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseDurationUnits();
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c1351(s3, s5, s7);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseDurationUnits() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 188,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$currPos;
    if (input.substr(peg$currPos, 6) === peg$c441) {
      s2 = peg$c441;
      peg$currPos += 6;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c442); }
    }
    if (s2 === peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c688) {
        s2 = peg$c688;
        peg$currPos += 4;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c689); }
      }
    }
    if (s2 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 115) {
        s3 = peg$c164;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c165); }
      }
      if (s3 === peg$FAILED) {
        s3 = null;
      }
      if (s3 !== peg$FAILED) {
        s2 = [s2, s3];
        s1 = s2;
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c1352) {
        s1 = peg$c1352;
        peg$currPos += 4;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1353); }
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c1354();
    }
    s0 = s1;

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStat() {
    var s0, s1;

    var key    = peg$currPos * 255 + 189,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3) === peg$c1356) {
      s1 = peg$c1356;
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1357); }
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 3) === peg$c1358) {
        s1 = peg$c1358;
        peg$currPos += 3;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1359); }
      }
      if (s1 === peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c1360) {
          s1 = peg$c1360;
          peg$currPos += 3;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1361); }
        }
        if (s1 === peg$FAILED) {
          if (input.substr(peg$currPos, 3) === peg$c1362) {
            s1 = peg$c1362;
            peg$currPos += 3;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1363); }
          }
          if (s1 === peg$FAILED) {
            if (input.substr(peg$currPos, 3) === peg$c1364) {
              s1 = peg$c1364;
              peg$currPos += 3;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c1365); }
            }
            if (s1 === peg$FAILED) {
              if (input.substr(peg$currPos, 3) === peg$c1366) {
                s1 = peg$c1366;
                peg$currPos += 3;
              } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c1367); }
              }
              if (s1 === peg$FAILED) {
                if (input.substr(peg$currPos, 3) === peg$c1368) {
                  s1 = peg$c1368;
                  peg$currPos += 3;
                } else {
                  s1 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c1369); }
                }
                if (s1 === peg$FAILED) {
                  if (input.substr(peg$currPos, 3) === peg$c1370) {
                    s1 = peg$c1370;
                    peg$currPos += 3;
                  } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c1371); }
                  }
                }
              }
            }
          }
        }
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c1372();
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1355); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatList() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 190,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseStat();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parseAndList();
      if (s4 !== peg$FAILED) {
        s5 = peg$parseStat();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parseAndList();
        if (s4 !== peg$FAILED) {
          s5 = peg$parseStat();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1309(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1373); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatListOrPlaceholder() {
    var s0, s1;

    var key    = peg$currPos * 255 + 191,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$parseStatList();
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 7) === peg$c1374) {
        s1 = peg$c1374;
        peg$currPos += 7;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1375); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1376();
      }
      s0 = s1;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseWho() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 192,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2) === peg$c1021) {
      s1 = peg$c1021;
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1022); }
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c1377) {
        s1 = peg$c1377;
        peg$currPos += 4;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1378); }
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseWhoClause();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c1379(s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseWhoClause() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    var key    = peg$currPos * 255 + 193,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3) === peg$c576) {
      s1 = peg$c576;
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c577); }
    }
    if (s1 === peg$FAILED) {
      s1 = null;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 4) === peg$c578) {
          s3 = peg$c578;
          peg$currPos += 4;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c579); }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c1308();
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 3) === peg$c576) {
        s1 = peg$c576;
        peg$currPos += 3;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c577); }
      }
      if (s1 === peg$FAILED) {
        s1 = null;
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        if (s2 !== peg$FAILED) {
          if (input.substr(peg$currPos, 4) === peg$c578) {
            s3 = peg$c578;
            peg$currPos += 4;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c579); }
          }
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c1308();
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 10) === peg$c1380) {
          s1 = peg$c1380;
          peg$currPos += 10;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1381); }
        }
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c1382();
        }
        s0 = s1;
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.substr(peg$currPos, 11) === peg$c1383) {
            s1 = peg$c1383;
            peg$currPos += 11;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1384); }
          }
          if (s1 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c1385();
          }
          s0 = s1;
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            if (input.substr(peg$currPos, 13) === peg$c1386) {
              s1 = peg$c1386;
              peg$currPos += 13;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c1387); }
            }
            if (s1 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c1388();
            }
            s0 = s1;
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              if (input.substr(peg$currPos, 10) === peg$c1389) {
                s1 = peg$c1389;
                peg$currPos += 10;
              } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c1390); }
              }
              if (s1 !== peg$FAILED) {
                s2 = peg$currPos;
                s3 = peg$parse_();
                if (s3 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 6) === peg$c1391) {
                    s4 = peg$c1391;
                    peg$currPos += 6;
                  } else {
                    s4 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c1392); }
                  }
                  if (s4 !== peg$FAILED) {
                    s5 = peg$parse_();
                    if (s5 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 5) === peg$c1209) {
                        s6 = peg$c1209;
                        peg$currPos += 5;
                      } else {
                        s6 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c1210); }
                      }
                      if (s6 === peg$FAILED) {
                        if (input.substr(peg$currPos, 4) === peg$c1393) {
                          s6 = peg$c1393;
                          peg$currPos += 4;
                        } else {
                          s6 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c1394); }
                        }
                        if (s6 === peg$FAILED) {
                          if (input.substr(peg$currPos, 11) === peg$c1395) {
                            s6 = peg$c1395;
                            peg$currPos += 11;
                          } else {
                            s6 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c1396); }
                          }
                        }
                      }
                      if (s6 !== peg$FAILED) {
                        s7 = peg$parse_();
                        if (s7 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 3) === peg$c1211) {
                            s8 = peg$c1211;
                            peg$currPos += 3;
                          } else {
                            s8 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c1212); }
                          }
                          if (s8 !== peg$FAILED) {
                            peg$savedPos = s2;
                            s3 = peg$c1397(s6);
                            s2 = s3;
                          } else {
                            peg$currPos = s2;
                            s2 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s2;
                          s2 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s2;
                        s2 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s2;
                      s2 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s2;
                    s2 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s2;
                  s2 = peg$FAILED;
                }
                if (s2 === peg$FAILED) {
                  s2 = null;
                }
                if (s2 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c1398(s2);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
              if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (input.substr(peg$currPos, 22) === peg$c1399) {
                  s1 = peg$c1399;
                  peg$currPos += 22;
                } else {
                  s1 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c1400); }
                }
                if (s1 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c1401();
                }
                s0 = s1;
                if (s0 === peg$FAILED) {
                  s0 = peg$currPos;
                  if (input.substr(peg$currPos, 19) === peg$c1402) {
                    s1 = peg$c1402;
                    peg$currPos += 19;
                  } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c1403); }
                  }
                  if (s1 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c1404();
                  }
                  s0 = s1;
                  if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    if (input.substr(peg$currPos, 28) === peg$c1405) {
                      s1 = peg$c1405;
                      peg$currPos += 28;
                    } else {
                      s1 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c1406); }
                    }
                    if (s1 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c1407();
                    }
                    s0 = s1;
                    if (s0 === peg$FAILED) {
                      s0 = peg$currPos;
                      if (input.substr(peg$currPos, 27) === peg$c1408) {
                        s1 = peg$c1408;
                        peg$currPos += 27;
                      } else {
                        s1 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c1409); }
                      }
                      if (s1 !== peg$FAILED) {
                        s2 = peg$parse_();
                        if (s2 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 6) === peg$c590) {
                            s3 = peg$c590;
                            peg$currPos += 6;
                          } else {
                            s3 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c591); }
                          }
                          if (s3 === peg$FAILED) {
                            s3 = null;
                          }
                          if (s3 !== peg$FAILED) {
                            s4 = peg$parse_();
                            if (s4 !== peg$FAILED) {
                              if (input.substr(peg$currPos, 7) === peg$c592) {
                                s5 = peg$c592;
                                peg$currPos += 7;
                              } else {
                                s5 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c593); }
                              }
                              if (s5 !== peg$FAILED) {
                                peg$savedPos = s0;
                                s1 = peg$c1410();
                                s0 = s1;
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                      if (s0 === peg$FAILED) {
                        s0 = peg$currPos;
                        if (input.substr(peg$currPos, 21) === peg$c1411) {
                          s1 = peg$c1411;
                          peg$currPos += 21;
                        } else {
                          s1 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c1412); }
                        }
                        if (s1 !== peg$FAILED) {
                          peg$savedPos = s0;
                          s1 = peg$c1413();
                        }
                        s0 = s1;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseWhoList() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    var key    = peg$currPos * 255 + 194,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2) === peg$c1021) {
      s1 = peg$c1021;
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1022); }
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c1377) {
        s1 = peg$c1377;
        peg$currPos += 4;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1378); }
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseWhoClause();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = [];
            s6 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 47) {
              s7 = peg$c967;
              peg$currPos++;
            } else {
              s7 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c968); }
            }
            if (s7 !== peg$FAILED) {
              s8 = peg$parseWhoClause();
              if (s8 !== peg$FAILED) {
                s7 = [s7, s8];
                s6 = s7;
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
            if (s6 !== peg$FAILED) {
              while (s6 !== peg$FAILED) {
                s5.push(s6);
                s6 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 47) {
                  s7 = peg$c967;
                  peg$currPos++;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c968); }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseWhoClause();
                  if (s8 !== peg$FAILED) {
                    s7 = [s7, s8];
                    s6 = s7;
                  } else {
                    peg$currPos = s6;
                    s6 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s6;
                  s6 = peg$FAILED;
                }
              }
            } else {
              s5 = peg$FAILED;
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c1414(s3, s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parsePerUses() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 255 + 195,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2) === peg$c1117) {
      s1 = peg$c1117;
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1118); }
    }
    if (s1 === peg$FAILED) {
      s1 = null;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 5) === peg$c353) {
          s3 = peg$c353;
          peg$currPos += 5;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c354); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseNumberString();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 4) === peg$c781) {
                  s7 = peg$c781;
                  peg$currPos += 4;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c782); }
                }
                if (s7 === peg$FAILED) {
                  if (input.substr(peg$currPos, 11) === peg$c1107) {
                    s7 = peg$c1107;
                    peg$currPos += 11;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c1108); }
                  }
                  if (s7 === peg$FAILED) {
                    if (input.substr(peg$currPos, 4) === peg$c94) {
                      s7 = peg$c94;
                      peg$currPos += 4;
                    } else {
                      s7 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c357); }
                    }
                  }
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c1415(s5);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 2) === peg$c1117) {
        s1 = peg$c1117;
        peg$currPos += 2;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1118); }
      }
      if (s1 === peg$FAILED) {
        s1 = null;
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        if (s2 !== peg$FAILED) {
          if (input.substr(peg$currPos, 5) === peg$c353) {
            s3 = peg$c353;
            peg$currPos += 5;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c354); }
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            if (s4 !== peg$FAILED) {
              s5 = peg$parseOrdinal();
              if (s5 !== peg$FAILED) {
                s6 = peg$parse_();
                if (s6 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 3) === peg$c1121) {
                    s7 = peg$c1121;
                    peg$currPos += 3;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c1122); }
                  }
                  if (s7 === peg$FAILED) {
                    if (input.substr(peg$currPos, 10) === peg$c1416) {
                      s7 = peg$c1416;
                      peg$currPos += 10;
                    } else {
                      s7 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c1417); }
                    }
                    if (s7 === peg$FAILED) {
                      if (input.substr(peg$currPos, 4) === peg$c94) {
                        s7 = peg$c94;
                        peg$currPos += 4;
                      } else {
                        s7 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c357); }
                      }
                    }
                  }
                  if (s7 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c1415(s5);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSkillType() {
    var s0, s1;

    var key    = peg$currPos * 255 + 196,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    if (input.substr(peg$currPos, 3) === peg$c150) {
      s0 = peg$c150;
      peg$currPos += 3;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c151); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 3) === peg$c1419) {
        s0 = peg$c1419;
        peg$currPos += 3;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1420); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c1421) {
          s0 = peg$c1421;
          peg$currPos += 3;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1422); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 3) === peg$c1423) {
            s0 = peg$c1423;
            peg$currPos += 3;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1424); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 3) === peg$c1425) {
              s0 = peg$c1425;
              peg$currPos += 3;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c1426); }
            }
            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 3) === peg$c1427) {
                s0 = peg$c1427;
                peg$currPos += 3;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c1428); }
              }
              if (s0 === peg$FAILED) {
                if (input.substr(peg$currPos, 3) === peg$c1429) {
                  s0 = peg$c1429;
                  peg$currPos += 3;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c1430); }
                }
                if (s0 === peg$FAILED) {
                  s0 = peg$currPos;
                  if (input.substr(peg$currPos, 8) === peg$c364) {
                    s1 = peg$c364;
                    peg$currPos += 8;
                  } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c506); }
                  }
                  if (s1 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c1431();
                  }
                  s0 = s1;
                }
              }
            }
          }
        }
      }
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1418); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSkillTypeList() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 197,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseSkillType();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parseOrList();
      if (s4 !== peg$FAILED) {
        s5 = peg$parseSkillType();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parseOrList();
        if (s4 !== peg$FAILED) {
          s5 = peg$parseSkillType();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1309(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1432); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSkillTypeAndList() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 198,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseSkillType();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parseAndList();
      if (s4 !== peg$FAILED) {
        s5 = peg$parseSkillType();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parseAndList();
        if (s4 !== peg$FAILED) {
          s5 = peg$parseSkillType();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1309(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1432); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSkillTypeAndOrList() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 199,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseSkillType();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parseAndOrList();
      if (s4 !== peg$FAILED) {
        s5 = peg$parseSkillType();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parseAndOrList();
        if (s4 !== peg$FAILED) {
          s5 = peg$parseSkillType();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1309(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1432); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSkillTypeSlashList() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 200,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseSkillType();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 47) {
        s4 = peg$c967;
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c968); }
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parseSkillType();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 47) {
          s4 = peg$c967;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c968); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseSkillType();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1309(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1432); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseElement() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 201,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    if (input.substr(peg$currPos, 4) === peg$c1434) {
      s0 = peg$c1434;
      peg$currPos += 4;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1435); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 3) === peg$c1436) {
        s0 = peg$c1436;
        peg$currPos += 3;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1437); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 9) === peg$c1438) {
          s0 = peg$c1438;
          peg$currPos += 9;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1439); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 5) === peg$c1440) {
            s0 = peg$c1440;
            peg$currPos += 5;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1441); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 4) === peg$c1442) {
              s0 = peg$c1442;
              peg$currPos += 4;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c1443); }
            }
            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 5) === peg$c1444) {
                s0 = peg$c1444;
                peg$currPos += 5;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c1445); }
              }
              if (s0 === peg$FAILED) {
                if (input.substr(peg$currPos, 4) === peg$c1446) {
                  s0 = peg$c1446;
                  peg$currPos += 4;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c1447); }
                }
                if (s0 === peg$FAILED) {
                  s0 = peg$currPos;
                  if (input.substr(peg$currPos, 4) === peg$c1448) {
                    s1 = peg$c1448;
                    peg$currPos += 4;
                  } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c1449); }
                  }
                  if (s1 !== peg$FAILED) {
                    s2 = peg$currPos;
                    peg$silentFails++;
                    if (input.substr(peg$currPos, 4) === peg$c1450) {
                      s3 = peg$c1450;
                      peg$currPos += 4;
                    } else {
                      s3 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c1451); }
                    }
                    peg$silentFails--;
                    if (s3 === peg$FAILED) {
                      s2 = void 0;
                    } else {
                      peg$currPos = s2;
                      s2 = peg$FAILED;
                    }
                    if (s2 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c1452();
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                  if (s0 === peg$FAILED) {
                    if (input.substr(peg$currPos, 6) === peg$c1453) {
                      s0 = peg$c1453;
                      peg$currPos += 6;
                    } else {
                      s0 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c1454); }
                    }
                    if (s0 === peg$FAILED) {
                      if (input.substr(peg$currPos, 2) === peg$c1455) {
                        s0 = peg$c1455;
                        peg$currPos += 2;
                      } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c1456); }
                      }
                      if (s0 === peg$FAILED) {
                        s0 = peg$currPos;
                        if (input.substr(peg$currPos, 13) === peg$c1457) {
                          s1 = peg$c1457;
                          peg$currPos += 13;
                        } else {
                          s1 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c1458); }
                        }
                        if (s1 !== peg$FAILED) {
                          peg$savedPos = s0;
                          s1 = peg$c1459();
                        }
                        s0 = s1;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1433); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseElementOrPlaceholder() {
    var s0, s1;

    var key    = peg$currPos * 255 + 202,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$parseElement();
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 9) === peg$c1460) {
        s1 = peg$c1460;
        peg$currPos += 9;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1461); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1376();
      }
      s0 = s1;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseElementList() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 203,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseElement();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parseOrList();
      if (s4 !== peg$FAILED) {
        s5 = peg$parseElement();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parseOrList();
        if (s4 !== peg$FAILED) {
          s5 = peg$parseElement();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1309(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1462); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseElementAndList() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 204,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseElement();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parseAndList();
      if (s4 !== peg$FAILED) {
        s5 = peg$parseElement();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parseAndList();
        if (s4 !== peg$FAILED) {
          s5 = peg$parseElement();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1309(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1462); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseElementAndOrList() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 205,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseElement();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parseAndOrList();
      if (s4 !== peg$FAILED) {
        s5 = peg$parseElement();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parseAndOrList();
        if (s4 !== peg$FAILED) {
          s5 = peg$parseElement();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1309(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1462); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseElementSlashList() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 206,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseElement();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 47) {
        s4 = peg$c967;
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c968); }
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parseElement();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 47) {
          s4 = peg$c967;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c968); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseElement();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1309(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1462); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseElementAndOrSlashList() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 207,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseElement();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parseAndOrList();
      if (s4 === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 47) {
          s4 = peg$c967;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c968); }
        }
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parseElement();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parseAndOrList();
        if (s4 === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 47) {
            s4 = peg$c967;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c968); }
          }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseElement();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1309(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1462); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseElementListOrOptions() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 208,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseElementList();
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$silentFails++;
      if (input.charCodeAt(peg$currPos) === 47) {
        s3 = peg$c967;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c968); }
      }
      peg$silentFails--;
      if (s3 === peg$FAILED) {
        s2 = void 0;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1464(s1);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseElementSlashList();
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1465(s1);
      }
      s0 = s1;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1463); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseEnElementSlashList() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12;

    var key    = peg$currPos * 255 + 209,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7) === peg$c250) {
      s1 = peg$c250;
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c251); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseElement();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 93) {
              s5 = peg$c1297;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c1298); }
            }
            if (s5 !== peg$FAILED) {
              s6 = [];
              s7 = peg$currPos;
              if (input.substr(peg$currPos, 8) === peg$c1466) {
                s8 = peg$c1466;
                peg$currPos += 8;
              } else {
                s8 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c1467); }
              }
              if (s8 !== peg$FAILED) {
                s9 = peg$parse_();
                if (s9 !== peg$FAILED) {
                  s10 = peg$parseElement();
                  if (s10 !== peg$FAILED) {
                    s11 = peg$parse_();
                    if (s11 !== peg$FAILED) {
                      if (input.charCodeAt(peg$currPos) === 93) {
                        s12 = peg$c1297;
                        peg$currPos++;
                      } else {
                        s12 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c1298); }
                      }
                      if (s12 !== peg$FAILED) {
                        s8 = [s8, s9, s10, s11, s12];
                        s7 = s8;
                      } else {
                        peg$currPos = s7;
                        s7 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s7;
                      s7 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s7;
                    s7 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s7;
                  s7 = peg$FAILED;
                }
              } else {
                peg$currPos = s7;
                s7 = peg$FAILED;
              }
              while (s7 !== peg$FAILED) {
                s6.push(s7);
                s7 = peg$currPos;
                if (input.substr(peg$currPos, 8) === peg$c1466) {
                  s8 = peg$c1466;
                  peg$currPos += 8;
                } else {
                  s8 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c1467); }
                }
                if (s8 !== peg$FAILED) {
                  s9 = peg$parse_();
                  if (s9 !== peg$FAILED) {
                    s10 = peg$parseElement();
                    if (s10 !== peg$FAILED) {
                      s11 = peg$parse_();
                      if (s11 !== peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 93) {
                          s12 = peg$c1297;
                          peg$currPos++;
                        } else {
                          s12 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c1298); }
                        }
                        if (s12 !== peg$FAILED) {
                          s8 = [s8, s9, s10, s11, s12];
                          s7 = s8;
                        } else {
                          peg$currPos = s7;
                          s7 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s7;
                        s7 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s7;
                      s7 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s7;
                    s7 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s7;
                  s7 = peg$FAILED;
                }
              }
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c1468(s3, s6);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseEnElementStackingSlashList() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16;

    var key    = peg$currPos * 255 + 210,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7) === peg$c250) {
      s1 = peg$c250;
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c251); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseElement();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseInteger();
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 14) === peg$c1469) {
                  s7 = peg$c1469;
                  peg$currPos += 14;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c1470); }
                }
                if (s7 !== peg$FAILED) {
                  s8 = [];
                  s9 = peg$currPos;
                  if (input.substr(peg$currPos, 8) === peg$c1466) {
                    s10 = peg$c1466;
                    peg$currPos += 8;
                  } else {
                    s10 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c1467); }
                  }
                  if (s10 !== peg$FAILED) {
                    s11 = peg$parse_();
                    if (s11 !== peg$FAILED) {
                      s12 = peg$parseElement();
                      if (s12 !== peg$FAILED) {
                        s13 = peg$parse_();
                        if (s13 !== peg$FAILED) {
                          s14 = peg$parseInteger();
                          if (s14 === peg$FAILED) {
                            s14 = null;
                          }
                          if (s14 !== peg$FAILED) {
                            s15 = peg$parse_();
                            if (s15 !== peg$FAILED) {
                              if (input.substr(peg$currPos, 14) === peg$c1469) {
                                s16 = peg$c1469;
                                peg$currPos += 14;
                              } else {
                                s16 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c1470); }
                              }
                              if (s16 !== peg$FAILED) {
                                s10 = [s10, s11, s12, s13, s14, s15, s16];
                                s9 = s10;
                              } else {
                                peg$currPos = s9;
                                s9 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s9;
                              s9 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s9;
                            s9 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s9;
                          s9 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s9;
                        s9 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s9;
                      s9 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s9;
                    s9 = peg$FAILED;
                  }
                  while (s9 !== peg$FAILED) {
                    s8.push(s9);
                    s9 = peg$currPos;
                    if (input.substr(peg$currPos, 8) === peg$c1466) {
                      s10 = peg$c1466;
                      peg$currPos += 8;
                    } else {
                      s10 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c1467); }
                    }
                    if (s10 !== peg$FAILED) {
                      s11 = peg$parse_();
                      if (s11 !== peg$FAILED) {
                        s12 = peg$parseElement();
                        if (s12 !== peg$FAILED) {
                          s13 = peg$parse_();
                          if (s13 !== peg$FAILED) {
                            s14 = peg$parseInteger();
                            if (s14 === peg$FAILED) {
                              s14 = null;
                            }
                            if (s14 !== peg$FAILED) {
                              s15 = peg$parse_();
                              if (s15 !== peg$FAILED) {
                                if (input.substr(peg$currPos, 14) === peg$c1469) {
                                  s16 = peg$c1469;
                                  peg$currPos += 14;
                                } else {
                                  s16 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c1470); }
                                }
                                if (s16 !== peg$FAILED) {
                                  s10 = [s10, s11, s12, s13, s14, s15, s16];
                                  s9 = s10;
                                } else {
                                  peg$currPos = s9;
                                  s9 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s9;
                                s9 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s9;
                              s9 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s9;
                            s9 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s9;
                          s9 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s9;
                        s9 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s9;
                      s9 = peg$FAILED;
                    }
                  }
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c1471(s3, s5, s8);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSchool() {
    var s0, s1;

    var key    = peg$currPos * 255 + 211,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    if (input.substr(peg$currPos, 4) === peg$c1473) {
      s0 = peg$c1473;
      peg$currPos += 4;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1474); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 11) === peg$c1338) {
        s0 = peg$c1338;
        peg$currPos += 11;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1339); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 8) === peg$c1475) {
          s0 = peg$c1475;
          peg$currPos += 8;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1476); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 6) === peg$c1477) {
            s0 = peg$c1477;
            peg$currPos += 6;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1478); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 6) === peg$c1479) {
              s0 = peg$c1479;
              peg$currPos += 6;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c1480); }
            }
            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 8) === peg$c1481) {
                s0 = peg$c1481;
                peg$currPos += 8;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c1482); }
              }
              if (s0 === peg$FAILED) {
                if (input.substr(peg$currPos, 7) === peg$c1483) {
                  s0 = peg$c1483;
                  peg$currPos += 7;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c1484); }
                }
                if (s0 === peg$FAILED) {
                  if (input.substr(peg$currPos, 5) === peg$c1485) {
                    s0 = peg$c1485;
                    peg$currPos += 5;
                  } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c1486); }
                  }
                  if (s0 === peg$FAILED) {
                    if (input.substr(peg$currPos, 6) === peg$c1487) {
                      s0 = peg$c1487;
                      peg$currPos += 6;
                    } else {
                      s0 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c1488); }
                    }
                    if (s0 === peg$FAILED) {
                      if (input.substr(peg$currPos, 9) === peg$c1489) {
                        s0 = peg$c1489;
                        peg$currPos += 9;
                      } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c1490); }
                      }
                      if (s0 === peg$FAILED) {
                        if (input.substr(peg$currPos, 4) === peg$c1491) {
                          s0 = peg$c1491;
                          peg$currPos += 4;
                        } else {
                          s0 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c1492); }
                        }
                        if (s0 === peg$FAILED) {
                          if (input.substr(peg$currPos, 5) === peg$c1493) {
                            s0 = peg$c1493;
                            peg$currPos += 5;
                          } else {
                            s0 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c1494); }
                          }
                          if (s0 === peg$FAILED) {
                            if (input.substr(peg$currPos, 7) === peg$c1495) {
                              s0 = peg$c1495;
                              peg$currPos += 7;
                            } else {
                              s0 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c1496); }
                            }
                            if (s0 === peg$FAILED) {
                              if (input.substr(peg$currPos, 12) === peg$c1497) {
                                s0 = peg$c1497;
                                peg$currPos += 12;
                              } else {
                                s0 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c1498); }
                              }
                              if (s0 === peg$FAILED) {
                                if (input.substr(peg$currPos, 7) === peg$c1499) {
                                  s0 = peg$c1499;
                                  peg$currPos += 7;
                                } else {
                                  s0 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c1500); }
                                }
                                if (s0 === peg$FAILED) {
                                  if (input.substr(peg$currPos, 10) === peg$c1501) {
                                    s0 = peg$c1501;
                                    peg$currPos += 10;
                                  } else {
                                    s0 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c1502); }
                                  }
                                  if (s0 === peg$FAILED) {
                                    if (input.substr(peg$currPos, 9) === peg$c1503) {
                                      s0 = peg$c1503;
                                      peg$currPos += 9;
                                    } else {
                                      s0 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c1504); }
                                    }
                                    if (s0 === peg$FAILED) {
                                      if (input.substr(peg$currPos, 7) === peg$c1505) {
                                        s0 = peg$c1505;
                                        peg$currPos += 7;
                                      } else {
                                        s0 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c1506); }
                                      }
                                      if (s0 === peg$FAILED) {
                                        if (input.substr(peg$currPos, 5) === peg$c1507) {
                                          s0 = peg$c1507;
                                          peg$currPos += 5;
                                        } else {
                                          s0 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c1508); }
                                        }
                                        if (s0 === peg$FAILED) {
                                          if (input.substr(peg$currPos, 11) === peg$c1340) {
                                            s0 = peg$c1340;
                                            peg$currPos += 11;
                                          } else {
                                            s0 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c1341); }
                                          }
                                          if (s0 === peg$FAILED) {
                                            if (input.substr(peg$currPos, 5) === peg$c1509) {
                                              s0 = peg$c1509;
                                              peg$currPos += 5;
                                            } else {
                                              s0 = peg$FAILED;
                                              if (peg$silentFails === 0) { peg$fail(peg$c1510); }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1472); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSchoolList() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 212,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseSchool();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parseOrList();
      if (s4 !== peg$FAILED) {
        s5 = peg$parseSchool();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parseOrList();
        if (s4 !== peg$FAILED) {
          s5 = peg$parseSchool();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1309(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1511); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSchoolAndList() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 213,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseSchool();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parseAndList();
      if (s4 !== peg$FAILED) {
        s5 = peg$parseSchool();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parseAndList();
        if (s4 !== peg$FAILED) {
          s5 = peg$parseSchool();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1309(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1511); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSchoolAndOrList() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 214,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseSchool();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parseAndOrList();
      if (s4 !== peg$FAILED) {
        s5 = peg$parseSchool();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parseAndOrList();
        if (s4 !== peg$FAILED) {
          s5 = peg$parseSchool();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1309(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1511); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSchoolAndOrSlashList() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 215,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseSchool();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parseAndOrList();
      if (s4 === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 47) {
          s4 = peg$c967;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c968); }
        }
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parseSchool();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parseAndOrList();
        if (s4 === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 47) {
            s4 = peg$c967;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c968); }
          }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseSchool();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1309(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1511); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSB() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 216,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4) === peg$c1512) {
      s1 = peg$c1512;
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1513); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 5) === peg$c1514) {
          s3 = peg$c1514;
          peg$currPos += 5;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1515); }
        }
        if (s3 !== peg$FAILED) {
          s1 = [s1, s2, s3];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c1516) {
        s0 = peg$c1516;
        peg$currPos += 2;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1517); }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseMaximum() {
    var s0, s1, s2;

    var key    = peg$currPos * 255 + 217,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    if (input.substr(peg$currPos, 7) === peg$c1518) {
      s0 = peg$c1518;
      peg$currPos += 7;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1519); }
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 3) === peg$c1520) {
        s1 = peg$c1520;
        peg$currPos += 3;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1521); }
      }
      if (s1 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 46) {
          s2 = peg$c2;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c3); }
        }
        if (s2 === peg$FAILED) {
          s2 = null;
        }
        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseUseCount() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 255 + 218,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseUseCountTerm();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 47) {
        s4 = peg$c967;
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c968); }
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parseUseCountTerm();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 47) {
          s4 = peg$c967;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c968); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseUseCountTerm();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = peg$currPos;
        s3 = peg$c1522(s1, s2);
        if (s3) {
          s3 = void 0;
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c1523(s1, s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseInteger();
      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 47) {
          s4 = peg$c967;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c968); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseInteger();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 47) {
            s4 = peg$c967;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c968); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parseInteger();
            if (s5 !== peg$FAILED) {
              s4 = [s4, s5];
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parse_();
          if (s3 === peg$FAILED) {
            s3 = null;
          }
          if (s3 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 43) {
              s4 = peg$c19;
              peg$currPos++;
            } else {
              s4 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c20); }
            }
            if (s4 !== peg$FAILED) {
              s5 = peg$parse_();
              if (s5 === peg$FAILED) {
                s5 = null;
              }
              if (s5 !== peg$FAILED) {
                s6 = peg$parseInteger();
                if (s6 !== peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 110) {
                    s7 = peg$c254;
                    peg$currPos++;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c255); }
                  }
                  if (s7 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c1524(s1, s2, s6);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseUseCountTerm() {
    var s0, s1, s2, s3, s4;

    var key    = peg$currPos * 255 + 219,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseInteger();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 43) {
        s2 = peg$c19;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c20); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseInteger();
        if (s3 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 110) {
            s4 = peg$c254;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c255); }
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c1525(s1, s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseElementOrSchoolList() {
    var s0, s1;

    var key    = peg$currPos * 255 + 220,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseSchoolAndOrList();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c232(s1);
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseElementAndOrList();
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c139(s1);
      }
      s0 = s1;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseElementOrSchoolAndOrSlashList() {
    var s0, s1;

    var key    = peg$currPos * 255 + 221,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseSchoolAndOrSlashList();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c232(s1);
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseElementAndOrSlashList();
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c139(s1);
      }
      s0 = s1;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseElementSchoolOrSkillTypeList() {
    var s0, s1;

    var key    = peg$currPos * 255 + 222,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseSchoolAndOrList();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c232(s1);
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseElementAndOrList();
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c139(s1);
      }
      s0 = s1;
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseSkillTypeAndOrList();
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c136(s1);
        }
        s0 = s1;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseRealm() {
    var s0, s1;

    var key    = peg$currPos * 255 + 223,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    if (input.substr(peg$currPos, 11) === peg$c1527) {
      s0 = peg$c1527;
      peg$currPos += 11;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1528); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 6) === peg$c1529) {
        s0 = peg$c1529;
        peg$currPos += 6;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1530); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 4) === peg$c1531) {
          s0 = peg$c1531;
          peg$currPos += 4;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1532); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c1533) {
            s0 = peg$c1533;
            peg$currPos += 2;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1534); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c1535) {
              s0 = peg$c1535;
              peg$currPos += 2;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c1536); }
            }
            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 3) === peg$c1537) {
                s0 = peg$c1537;
                peg$currPos += 3;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c1538); }
              }
              if (s0 === peg$FAILED) {
                if (input.substr(peg$currPos, 3) === peg$c1539) {
                  s0 = peg$c1539;
                  peg$currPos += 3;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c1540); }
                }
                if (s0 === peg$FAILED) {
                  if (input.substr(peg$currPos, 2) === peg$c1541) {
                    s0 = peg$c1541;
                    peg$currPos += 2;
                  } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c1542); }
                  }
                  if (s0 === peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 73) {
                      s0 = peg$c1543;
                      peg$currPos++;
                    } else {
                      s0 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c1544); }
                    }
                    if (s0 === peg$FAILED) {
                      if (input.substr(peg$currPos, 4) === peg$c1545) {
                        s0 = peg$c1545;
                        peg$currPos += 4;
                      } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c1546); }
                      }
                      if (s0 === peg$FAILED) {
                        if (input.substr(peg$currPos, 3) === peg$c1547) {
                          s0 = peg$c1547;
                          peg$currPos += 3;
                        } else {
                          s0 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c1548); }
                        }
                        if (s0 === peg$FAILED) {
                          if (input.substr(peg$currPos, 2) === peg$c1549) {
                            s0 = peg$c1549;
                            peg$currPos += 2;
                          } else {
                            s0 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c1550); }
                          }
                          if (s0 === peg$FAILED) {
                            if (input.charCodeAt(peg$currPos) === 86) {
                              s0 = peg$c1551;
                              peg$currPos++;
                            } else {
                              s0 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c1552); }
                            }
                            if (s0 === peg$FAILED) {
                              if (input.substr(peg$currPos, 2) === peg$c1553) {
                                s0 = peg$c1553;
                                peg$currPos += 2;
                              } else {
                                s0 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c1554); }
                              }
                              if (s0 === peg$FAILED) {
                                if (input.substr(peg$currPos, 3) === peg$c1555) {
                                  s0 = peg$c1555;
                                  peg$currPos += 3;
                                } else {
                                  s0 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c1556); }
                                }
                                if (s0 === peg$FAILED) {
                                  if (input.substr(peg$currPos, 4) === peg$c1557) {
                                    s0 = peg$c1557;
                                    peg$currPos += 4;
                                  } else {
                                    s0 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c1558); }
                                  }
                                  if (s0 === peg$FAILED) {
                                    if (input.substr(peg$currPos, 3) === peg$c1559) {
                                      s0 = peg$c1559;
                                      peg$currPos += 3;
                                    } else {
                                      s0 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c1560); }
                                    }
                                    if (s0 === peg$FAILED) {
                                      if (input.substr(peg$currPos, 2) === peg$c1561) {
                                        s0 = peg$c1561;
                                        peg$currPos += 2;
                                      } else {
                                        s0 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c1562); }
                                      }
                                      if (s0 === peg$FAILED) {
                                        if (input.charCodeAt(peg$currPos) === 88) {
                                          s0 = peg$c1563;
                                          peg$currPos++;
                                        } else {
                                          s0 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c1564); }
                                        }
                                        if (s0 === peg$FAILED) {
                                          if (input.substr(peg$currPos, 2) === peg$c1565) {
                                            s0 = peg$c1565;
                                            peg$currPos += 2;
                                          } else {
                                            s0 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c1566); }
                                          }
                                          if (s0 === peg$FAILED) {
                                            if (input.substr(peg$currPos, 6) === peg$c1567) {
                                              s0 = peg$c1567;
                                              peg$currPos += 6;
                                            } else {
                                              s0 = peg$FAILED;
                                              if (peg$silentFails === 0) { peg$fail(peg$c1568); }
                                            }
                                            if (s0 === peg$FAILED) {
                                              s0 = peg$currPos;
                                              if (input.substr(peg$currPos, 4) === peg$c1569) {
                                                s1 = peg$c1569;
                                                peg$currPos += 4;
                                              } else {
                                                s1 = peg$FAILED;
                                                if (peg$silentFails === 0) { peg$fail(peg$c1570); }
                                              }
                                              if (s1 !== peg$FAILED) {
                                                peg$savedPos = s0;
                                                s1 = peg$c1571();
                                              }
                                              s0 = s1;
                                              if (s0 === peg$FAILED) {
                                                s0 = peg$currPos;
                                                if (input.substr(peg$currPos, 4) === peg$c1572) {
                                                  s1 = peg$c1572;
                                                  peg$currPos += 4;
                                                } else {
                                                  s1 = peg$FAILED;
                                                  if (peg$silentFails === 0) { peg$fail(peg$c1573); }
                                                }
                                                if (s1 !== peg$FAILED) {
                                                  peg$savedPos = s0;
                                                  s1 = peg$c1574();
                                                }
                                                s0 = s1;
                                                if (s0 === peg$FAILED) {
                                                  s0 = peg$currPos;
                                                  if (input.substr(peg$currPos, 4) === peg$c1575) {
                                                    s1 = peg$c1575;
                                                    peg$currPos += 4;
                                                  } else {
                                                    s1 = peg$FAILED;
                                                    if (peg$silentFails === 0) { peg$fail(peg$c1576); }
                                                  }
                                                  if (s1 !== peg$FAILED) {
                                                    peg$savedPos = s0;
                                                    s1 = peg$c1577();
                                                  }
                                                  s0 = s1;
                                                  if (s0 === peg$FAILED) {
                                                    s0 = peg$currPos;
                                                    if (input.substr(peg$currPos, 4) === peg$c1578) {
                                                      s1 = peg$c1578;
                                                      peg$currPos += 4;
                                                    } else {
                                                      s1 = peg$FAILED;
                                                      if (peg$silentFails === 0) { peg$fail(peg$c1579); }
                                                    }
                                                    if (s1 !== peg$FAILED) {
                                                      peg$savedPos = s0;
                                                      s1 = peg$c1580();
                                                    }
                                                    s0 = s1;
                                                    if (s0 === peg$FAILED) {
                                                      s0 = peg$currPos;
                                                      if (input.substr(peg$currPos, 4) === peg$c1581) {
                                                        s1 = peg$c1581;
                                                        peg$currPos += 4;
                                                      } else {
                                                        s1 = peg$FAILED;
                                                        if (peg$silentFails === 0) { peg$fail(peg$c1582); }
                                                      }
                                                      if (s1 !== peg$FAILED) {
                                                        peg$savedPos = s0;
                                                        s1 = peg$c1583();
                                                      }
                                                      s0 = s1;
                                                      if (s0 === peg$FAILED) {
                                                        s0 = peg$currPos;
                                                        if (input.substr(peg$currPos, 4) === peg$c1584) {
                                                          s1 = peg$c1584;
                                                          peg$currPos += 4;
                                                        } else {
                                                          s1 = peg$FAILED;
                                                          if (peg$silentFails === 0) { peg$fail(peg$c1585); }
                                                        }
                                                        if (s1 !== peg$FAILED) {
                                                          peg$savedPos = s0;
                                                          s1 = peg$c1586();
                                                        }
                                                        s0 = s1;
                                                        if (s0 === peg$FAILED) {
                                                          s0 = peg$currPos;
                                                          if (input.substr(peg$currPos, 3) === peg$c1587) {
                                                            s1 = peg$c1587;
                                                            peg$currPos += 3;
                                                          } else {
                                                            s1 = peg$FAILED;
                                                            if (peg$silentFails === 0) { peg$fail(peg$c1588); }
                                                          }
                                                          if (s1 !== peg$FAILED) {
                                                            peg$savedPos = s0;
                                                            s1 = peg$c1589();
                                                          }
                                                          s0 = s1;
                                                          if (s0 === peg$FAILED) {
                                                            s0 = peg$currPos;
                                                            if (input.substr(peg$currPos, 3) === peg$c1590) {
                                                              s1 = peg$c1590;
                                                              peg$currPos += 3;
                                                            } else {
                                                              s1 = peg$FAILED;
                                                              if (peg$silentFails === 0) { peg$fail(peg$c1591); }
                                                            }
                                                            if (s1 !== peg$FAILED) {
                                                              peg$savedPos = s0;
                                                              s1 = peg$c1592();
                                                            }
                                                            s0 = s1;
                                                            if (s0 === peg$FAILED) {
                                                              s0 = peg$currPos;
                                                              if (input.substr(peg$currPos, 3) === peg$c1593) {
                                                                s1 = peg$c1593;
                                                                peg$currPos += 3;
                                                              } else {
                                                                s1 = peg$FAILED;
                                                                if (peg$silentFails === 0) { peg$fail(peg$c1594); }
                                                              }
                                                              if (s1 !== peg$FAILED) {
                                                                peg$savedPos = s0;
                                                                s1 = peg$c1595();
                                                              }
                                                              s0 = s1;
                                                              if (s0 === peg$FAILED) {
                                                                s0 = peg$currPos;
                                                                if (input.substr(peg$currPos, 3) === peg$c1596) {
                                                                  s1 = peg$c1596;
                                                                  peg$currPos += 3;
                                                                } else {
                                                                  s1 = peg$FAILED;
                                                                  if (peg$silentFails === 0) { peg$fail(peg$c1597); }
                                                                }
                                                                if (s1 !== peg$FAILED) {
                                                                  peg$savedPos = s0;
                                                                  s1 = peg$c1598();
                                                                }
                                                                s0 = s1;
                                                                if (s0 === peg$FAILED) {
                                                                  s0 = peg$currPos;
                                                                  if (input.substr(peg$currPos, 3) === peg$c1599) {
                                                                    s1 = peg$c1599;
                                                                    peg$currPos += 3;
                                                                  } else {
                                                                    s1 = peg$FAILED;
                                                                    if (peg$silentFails === 0) { peg$fail(peg$c1600); }
                                                                  }
                                                                  if (s1 !== peg$FAILED) {
                                                                    peg$savedPos = s0;
                                                                    s1 = peg$c1601();
                                                                  }
                                                                  s0 = s1;
                                                                  if (s0 === peg$FAILED) {
                                                                    s0 = peg$currPos;
                                                                    if (input.substr(peg$currPos, 3) === peg$c1602) {
                                                                      s1 = peg$c1602;
                                                                      peg$currPos += 3;
                                                                    } else {
                                                                      s1 = peg$FAILED;
                                                                      if (peg$silentFails === 0) { peg$fail(peg$c1603); }
                                                                    }
                                                                    if (s1 !== peg$FAILED) {
                                                                      peg$savedPos = s0;
                                                                      s1 = peg$c1604();
                                                                    }
                                                                    s0 = s1;
                                                                    if (s0 === peg$FAILED) {
                                                                      s0 = peg$currPos;
                                                                      if (input.substr(peg$currPos, 3) === peg$c1605) {
                                                                        s1 = peg$c1605;
                                                                        peg$currPos += 3;
                                                                      } else {
                                                                        s1 = peg$FAILED;
                                                                        if (peg$silentFails === 0) { peg$fail(peg$c1606); }
                                                                      }
                                                                      if (s1 !== peg$FAILED) {
                                                                        peg$savedPos = s0;
                                                                        s1 = peg$c1607();
                                                                      }
                                                                      s0 = s1;
                                                                      if (s0 === peg$FAILED) {
                                                                        s0 = peg$currPos;
                                                                        if (input.substr(peg$currPos, 3) === peg$c1608) {
                                                                          s1 = peg$c1608;
                                                                          peg$currPos += 3;
                                                                        } else {
                                                                          s1 = peg$FAILED;
                                                                          if (peg$silentFails === 0) { peg$fail(peg$c1609); }
                                                                        }
                                                                        if (s1 !== peg$FAILED) {
                                                                          peg$savedPos = s0;
                                                                          s1 = peg$c1610();
                                                                        }
                                                                        s0 = s1;
                                                                        if (s0 === peg$FAILED) {
                                                                          s0 = peg$currPos;
                                                                          if (input.substr(peg$currPos, 3) === peg$c1611) {
                                                                            s1 = peg$c1611;
                                                                            peg$currPos += 3;
                                                                          } else {
                                                                            s1 = peg$FAILED;
                                                                            if (peg$silentFails === 0) { peg$fail(peg$c1612); }
                                                                          }
                                                                          if (s1 !== peg$FAILED) {
                                                                            peg$savedPos = s0;
                                                                            s1 = peg$c1613();
                                                                          }
                                                                          s0 = s1;
                                                                          if (s0 === peg$FAILED) {
                                                                            s0 = peg$currPos;
                                                                            if (input.substr(peg$currPos, 5) === peg$c1614) {
                                                                              s1 = peg$c1614;
                                                                              peg$currPos += 5;
                                                                            } else {
                                                                              s1 = peg$FAILED;
                                                                              if (peg$silentFails === 0) { peg$fail(peg$c1615); }
                                                                            }
                                                                            if (s1 !== peg$FAILED) {
                                                                              peg$savedPos = s0;
                                                                              s1 = peg$c1616();
                                                                            }
                                                                            s0 = s1;
                                                                            if (s0 === peg$FAILED) {
                                                                              s0 = peg$currPos;
                                                                              if (input.substr(peg$currPos, 5) === peg$c1617) {
                                                                                s1 = peg$c1617;
                                                                                peg$currPos += 5;
                                                                              } else {
                                                                                s1 = peg$FAILED;
                                                                                if (peg$silentFails === 0) { peg$fail(peg$c1618); }
                                                                              }
                                                                              if (s1 !== peg$FAILED) {
                                                                                peg$savedPos = s0;
                                                                                s1 = peg$c1619();
                                                                              }
                                                                              s0 = s1;
                                                                              if (s0 === peg$FAILED) {
                                                                                s0 = peg$currPos;
                                                                                if (input.substr(peg$currPos, 6) === peg$c1620) {
                                                                                  s1 = peg$c1620;
                                                                                  peg$currPos += 6;
                                                                                } else {
                                                                                  s1 = peg$FAILED;
                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c1621); }
                                                                                }
                                                                                if (s1 !== peg$FAILED) {
                                                                                  peg$savedPos = s0;
                                                                                  s1 = peg$c1622();
                                                                                }
                                                                                s0 = s1;
                                                                                if (s0 === peg$FAILED) {
                                                                                  s0 = peg$currPos;
                                                                                  if (input.substr(peg$currPos, 6) === peg$c1623) {
                                                                                    s1 = peg$c1623;
                                                                                    peg$currPos += 6;
                                                                                  } else {
                                                                                    s1 = peg$FAILED;
                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c1624); }
                                                                                  }
                                                                                  if (s1 !== peg$FAILED) {
                                                                                    peg$savedPos = s0;
                                                                                    s1 = peg$c1625();
                                                                                  }
                                                                                  s0 = s1;
                                                                                  if (s0 === peg$FAILED) {
                                                                                    s0 = peg$currPos;
                                                                                    if (input.substr(peg$currPos, 5) === peg$c1626) {
                                                                                      s1 = peg$c1626;
                                                                                      peg$currPos += 5;
                                                                                    } else {
                                                                                      s1 = peg$FAILED;
                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c1627); }
                                                                                    }
                                                                                    if (s1 !== peg$FAILED) {
                                                                                      peg$savedPos = s0;
                                                                                      s1 = peg$c1628();
                                                                                    }
                                                                                    s0 = s1;
                                                                                    if (s0 === peg$FAILED) {
                                                                                      s0 = peg$currPos;
                                                                                      if (input.substr(peg$currPos, 4) === peg$c1629) {
                                                                                        s1 = peg$c1629;
                                                                                        peg$currPos += 4;
                                                                                      } else {
                                                                                        s1 = peg$FAILED;
                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c1630); }
                                                                                      }
                                                                                      if (s1 !== peg$FAILED) {
                                                                                        peg$savedPos = s0;
                                                                                        s1 = peg$c1631();
                                                                                      }
                                                                                      s0 = s1;
                                                                                      if (s0 === peg$FAILED) {
                                                                                        s0 = peg$currPos;
                                                                                        if (input.substr(peg$currPos, 7) === peg$c1632) {
                                                                                          s1 = peg$c1632;
                                                                                          peg$currPos += 7;
                                                                                        } else {
                                                                                          s1 = peg$FAILED;
                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c1633); }
                                                                                        }
                                                                                        if (s1 !== peg$FAILED) {
                                                                                          peg$savedPos = s0;
                                                                                          s1 = peg$c1634();
                                                                                        }
                                                                                        s0 = s1;
                                                                                        if (s0 === peg$FAILED) {
                                                                                          s0 = peg$currPos;
                                                                                          if (input.substr(peg$currPos, 6) === peg$c1635) {
                                                                                            s1 = peg$c1635;
                                                                                            peg$currPos += 6;
                                                                                          } else {
                                                                                            s1 = peg$FAILED;
                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c1636); }
                                                                                          }
                                                                                          if (s1 !== peg$FAILED) {
                                                                                            peg$savedPos = s0;
                                                                                            s1 = peg$c1637();
                                                                                          }
                                                                                          s0 = s1;
                                                                                          if (s0 === peg$FAILED) {
                                                                                            s0 = peg$currPos;
                                                                                            if (input.substr(peg$currPos, 5) === peg$c1638) {
                                                                                              s1 = peg$c1638;
                                                                                              peg$currPos += 5;
                                                                                            } else {
                                                                                              s1 = peg$FAILED;
                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c1639); }
                                                                                            }
                                                                                            if (s1 !== peg$FAILED) {
                                                                                              peg$savedPos = s0;
                                                                                              s1 = peg$c1640();
                                                                                            }
                                                                                            s0 = s1;
                                                                                            if (s0 === peg$FAILED) {
                                                                                              s0 = peg$currPos;
                                                                                              if (input.substr(peg$currPos, 4) === peg$c1641) {
                                                                                                s1 = peg$c1641;
                                                                                                peg$currPos += 4;
                                                                                              } else {
                                                                                                s1 = peg$FAILED;
                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c1642); }
                                                                                              }
                                                                                              if (s1 !== peg$FAILED) {
                                                                                                peg$savedPos = s0;
                                                                                                s1 = peg$c1643();
                                                                                              }
                                                                                              s0 = s1;
                                                                                              if (s0 === peg$FAILED) {
                                                                                                s0 = peg$currPos;
                                                                                                if (input.substr(peg$currPos, 5) === peg$c1644) {
                                                                                                  s1 = peg$c1644;
                                                                                                  peg$currPos += 5;
                                                                                                } else {
                                                                                                  s1 = peg$FAILED;
                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c1645); }
                                                                                                }
                                                                                                if (s1 !== peg$FAILED) {
                                                                                                  peg$savedPos = s0;
                                                                                                  s1 = peg$c1646();
                                                                                                }
                                                                                                s0 = s1;
                                                                                                if (s0 === peg$FAILED) {
                                                                                                  s0 = peg$currPos;
                                                                                                  if (input.substr(peg$currPos, 6) === peg$c1647) {
                                                                                                    s1 = peg$c1647;
                                                                                                    peg$currPos += 6;
                                                                                                  } else {
                                                                                                    s1 = peg$FAILED;
                                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c1648); }
                                                                                                  }
                                                                                                  if (s1 !== peg$FAILED) {
                                                                                                    peg$savedPos = s0;
                                                                                                    s1 = peg$c1649();
                                                                                                  }
                                                                                                  s0 = s1;
                                                                                                  if (s0 === peg$FAILED) {
                                                                                                    s0 = peg$currPos;
                                                                                                    if (input.substr(peg$currPos, 7) === peg$c1650) {
                                                                                                      s1 = peg$c1650;
                                                                                                      peg$currPos += 7;
                                                                                                    } else {
                                                                                                      s1 = peg$FAILED;
                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c1651); }
                                                                                                    }
                                                                                                    if (s1 !== peg$FAILED) {
                                                                                                      peg$savedPos = s0;
                                                                                                      s1 = peg$c1652();
                                                                                                    }
                                                                                                    s0 = s1;
                                                                                                    if (s0 === peg$FAILED) {
                                                                                                      s0 = peg$currPos;
                                                                                                      if (input.substr(peg$currPos, 6) === peg$c1653) {
                                                                                                        s1 = peg$c1653;
                                                                                                        peg$currPos += 6;
                                                                                                      } else {
                                                                                                        s1 = peg$FAILED;
                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c1654); }
                                                                                                      }
                                                                                                      if (s1 !== peg$FAILED) {
                                                                                                        peg$savedPos = s0;
                                                                                                        s1 = peg$c1655();
                                                                                                      }
                                                                                                      s0 = s1;
                                                                                                      if (s0 === peg$FAILED) {
                                                                                                        s0 = peg$currPos;
                                                                                                        if (input.substr(peg$currPos, 5) === peg$c1656) {
                                                                                                          s1 = peg$c1656;
                                                                                                          peg$currPos += 5;
                                                                                                        } else {
                                                                                                          s1 = peg$FAILED;
                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c1657); }
                                                                                                        }
                                                                                                        if (s1 !== peg$FAILED) {
                                                                                                          peg$savedPos = s0;
                                                                                                          s1 = peg$c1658();
                                                                                                        }
                                                                                                        s0 = s1;
                                                                                                        if (s0 === peg$FAILED) {
                                                                                                          s0 = peg$currPos;
                                                                                                          if (input.substr(peg$currPos, 4) === peg$c1659) {
                                                                                                            s1 = peg$c1659;
                                                                                                            peg$currPos += 4;
                                                                                                          } else {
                                                                                                            s1 = peg$FAILED;
                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c1660); }
                                                                                                          }
                                                                                                          if (s1 !== peg$FAILED) {
                                                                                                            peg$savedPos = s0;
                                                                                                            s1 = peg$c1661();
                                                                                                          }
                                                                                                          s0 = s1;
                                                                                                          if (s0 === peg$FAILED) {
                                                                                                            s0 = peg$currPos;
                                                                                                            if (input.substr(peg$currPos, 7) === peg$c1662) {
                                                                                                              s1 = peg$c1662;
                                                                                                              peg$currPos += 7;
                                                                                                            } else {
                                                                                                              s1 = peg$FAILED;
                                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c1663); }
                                                                                                            }
                                                                                                            if (s1 !== peg$FAILED) {
                                                                                                              peg$savedPos = s0;
                                                                                                              s1 = peg$c1622();
                                                                                                            }
                                                                                                            s0 = s1;
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1526); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseDamageCapValue() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 224,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4) === peg$c1664) {
      s1 = peg$c1664;
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1665); }
    }
    if (s1 === peg$FAILED) {
      s1 = peg$currPos;
      if (peg$c1666.test(input.charAt(peg$currPos))) {
        s2 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1667); }
      }
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 4) === peg$c1664) {
          s3 = peg$c1664;
          peg$currPos += 4;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1665); }
        }
        if (s3 !== peg$FAILED) {
          s2 = [s2, s3];
          s1 = s2;
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c1668();
    }
    s0 = s1;

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseIncreasesOrReduces() {
    var s0, s1;

    var key    = peg$currPos * 255 + 225,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 9).toLowerCase() === peg$c317) {
      s1 = input.substr(peg$currPos, 9);
      peg$currPos += 9;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1669); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c443();
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 7).toLowerCase() === peg$c169) {
        s1 = input.substr(peg$currPos, 7);
        peg$currPos += 7;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1670); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1671();
      }
      s0 = s1;
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 9).toLowerCase() === peg$c1672) {
          s1 = input.substr(peg$currPos, 9);
          peg$currPos += 9;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1673); }
        }
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c1671();
        }
        s0 = s1;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAndList() {
    var s0, s1, s2, s3, s4;

    var key    = peg$currPos * 255 + 226,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 44) {
      s1 = peg$c0;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c530) {
          s3 = peg$c530;
          peg$currPos += 3;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c531); }
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s1 = [s1, s2, s3, s4];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parse_();
      if (s1 !== peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c530) {
          s2 = peg$c530;
          peg$currPos += 3;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c531); }
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parse_();
          if (s3 !== peg$FAILED) {
            s1 = [s1, s2, s3];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseOrList() {
    var s0, s1, s2, s3, s4;

    var key    = peg$currPos * 255 + 227,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 44) {
      s1 = peg$c0;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c964) {
          s3 = peg$c964;
          peg$currPos += 2;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c965); }
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s1 = [s1, s2, s3, s4];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parse_();
      if (s1 !== peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c964) {
          s2 = peg$c964;
          peg$currPos += 2;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c965); }
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parse_();
          if (s3 !== peg$FAILED) {
            s1 = [s1, s2, s3];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAndOrList() {
    var s0, s1, s2, s3, s4;

    var key    = peg$currPos * 255 + 228,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 44) {
      s1 = peg$c0;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c530) {
          s3 = peg$c530;
          peg$currPos += 3;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c531); }
        }
        if (s3 === peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c964) {
            s3 = peg$c964;
            peg$currPos += 2;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c965); }
          }
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s1 = [s1, s2, s3, s4];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parse_();
      if (s1 !== peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c530) {
          s2 = peg$c530;
          peg$currPos += 3;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c531); }
        }
        if (s2 === peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c964) {
            s2 = peg$c964;
            peg$currPos += 2;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c965); }
          }
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parse_();
          if (s3 !== peg$FAILED) {
            s1 = [s1, s2, s3];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatusListConjunction() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 229,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5) === peg$c1674) {
      s1 = peg$c1674;
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1675); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1676();
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parse_();
      if (s1 !== peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c530) {
          s2 = peg$c530;
          peg$currPos += 3;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c531); }
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parse_();
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c1676();
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parse_();
        if (s1 !== peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c964) {
            s2 = peg$c964;
            peg$currPos += 2;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c965); }
          }
          if (s2 !== peg$FAILED) {
            s3 = peg$parse_();
            if (s3 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c1677();
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 44) {
            s1 = peg$c0;
            peg$currPos++;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1); }
          }
          if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            if (s2 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c1678();
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 47) {
              s1 = peg$c967;
              peg$currPos++;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c968); }
            }
            if (s1 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c1679();
            }
            s0 = s1;
          }
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseNumberString() {
    var s0, s1, s2;

    var key    = peg$currPos * 255 + 230,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = [];
    if (peg$c1681.test(input.charAt(peg$currPos))) {
      s2 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1682); }
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        if (peg$c1681.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1682); }
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = peg$currPos;
      s2 = peg$c1683(s1);
      if (s2) {
        s2 = void 0;
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1684(s1);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1680); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseArticleOrNumberString() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 231,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$parseNumberString();
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 97) {
        s2 = peg$c1061;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1062); }
      }
      if (s2 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 110) {
          s3 = peg$c254;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c255); }
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          s2 = [s2, s3];
          s1 = s2;
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c443();
      }
      s0 = s1;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseDecimalNumber() {
    var s0, s1, s2;

    var key    = peg$currPos * 255 + 232,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = [];
    if (peg$c1686.test(input.charAt(peg$currPos))) {
      s2 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1687); }
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        if (peg$c1686.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1687); }
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 63) {
        s1 = peg$c9;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c10); }
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c1688();
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1685); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseInteger() {
    var s0, s1, s2;

    var key    = peg$currPos * 255 + 233,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = [];
    if (peg$c1690.test(input.charAt(peg$currPos))) {
      s2 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1691); }
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        if (peg$c1690.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1691); }
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 63) {
        s1 = peg$c9;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c10); }
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c1692();
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1689); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseIntegerOrX() {
    var s0, s1;

    var key    = peg$currPos * 255 + 234,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseInteger();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c1694(s1);
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 88) {
        s1 = peg$c1563;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1564); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1376();
      }
      s0 = s1;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1693); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parsePercentInteger() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 235,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$currPos;
    s2 = [];
    if (peg$c1690.test(input.charAt(peg$currPos))) {
      s3 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s3 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1691); }
    }
    if (s3 !== peg$FAILED) {
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        if (peg$c1690.test(input.charAt(peg$currPos))) {
          s3 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1691); }
        }
      }
    } else {
      s2 = peg$FAILED;
    }
    if (s2 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 37) {
        s3 = peg$c12;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c13); }
      }
      if (s3 !== peg$FAILED) {
        s2 = [s2, s3];
        s1 = s2;
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 63) {
        s1 = peg$c9;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c10); }
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c1692();
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1695); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parsePercentOrX() {
    var s0, s1;

    var key    = peg$currPos * 255 + 236,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parsePercentInteger();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c1694(s1);
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 2) === peg$c1696) {
        s1 = peg$c1696;
        peg$currPos += 2;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1697); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1376();
      }
      s0 = s1;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1693); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSignedInteger() {
    var s0, s1, s2, s3, s4;

    var key    = peg$currPos * 255 + 237,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    if (peg$c7.test(input.charAt(peg$currPos))) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c8); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = [];
        if (peg$c1690.test(input.charAt(peg$currPos))) {
          s4 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1691); }
        }
        if (s4 !== peg$FAILED) {
          while (s4 !== peg$FAILED) {
            s3.push(s4);
            if (peg$c1690.test(input.charAt(peg$currPos))) {
              s4 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s4 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c1691); }
            }
          }
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c1699(s1, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1698); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSignedIntegerOrX() {
    var s0, s1, s2;

    var key    = peg$currPos * 255 + 238,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$parseSignedInteger();
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 43) {
        s1 = peg$c19;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c20); }
      }
      if (s1 === peg$FAILED) {
        s1 = null;
      }
      if (s1 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 88) {
          s2 = peg$c1563;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1564); }
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c1701();
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 2) === peg$c1702) {
          s1 = peg$c1702;
          peg$currPos += 2;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1703); }
        }
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c1704();
        }
        s0 = s1;
      }
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1700); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseIntegerWithNegatives() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 239,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 45) {
      s1 = peg$c1706;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1707); }
    }
    if (s1 === peg$FAILED) {
      s1 = null;
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      if (peg$c1690.test(input.charAt(peg$currPos))) {
        s3 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1691); }
      }
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          if (peg$c1690.test(input.charAt(peg$currPos))) {
            s3 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1691); }
          }
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1708(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1705); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseDecimalNumberSlashList() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 240,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseDecimalNumber();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 47) {
        s4 = peg$c967;
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c968); }
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parseDecimalNumber();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 47) {
          s4 = peg$c967;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c968); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseDecimalNumber();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1710(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1709); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseMultiplierSlashList() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 255 + 241,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 120) {
      s1 = peg$c999;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1000); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseDecimalNumber();
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$currPos;
        s5 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 47) {
          s6 = peg$c967;
          peg$currPos++;
        } else {
          s6 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c968); }
        }
        if (s6 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 120) {
            s7 = peg$c999;
            peg$currPos++;
          } else {
            s7 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1000); }
          }
          if (s7 === peg$FAILED) {
            s7 = null;
          }
          if (s7 !== peg$FAILED) {
            s6 = [s6, s7];
            s5 = s6;
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
        } else {
          peg$currPos = s5;
          s5 = peg$FAILED;
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$parseDecimalNumber();
          if (s6 !== peg$FAILED) {
            s5 = [s5, s6];
            s4 = s5;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          s4 = peg$currPos;
          s5 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 47) {
            s6 = peg$c967;
            peg$currPos++;
          } else {
            s6 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c968); }
          }
          if (s6 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 120) {
              s7 = peg$c999;
              peg$currPos++;
            } else {
              s7 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c1000); }
            }
            if (s7 === peg$FAILED) {
              s7 = null;
            }
            if (s7 !== peg$FAILED) {
              s6 = [s6, s7];
              s5 = s6;
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parseDecimalNumber();
            if (s6 !== peg$FAILED) {
              s5 = [s5, s6];
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c1710(s2, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1711); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseIntegerSlashList() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 242,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseInteger();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 47) {
        s4 = peg$c967;
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c968); }
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parseInteger();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 47) {
          s4 = peg$c967;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c968); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseInteger();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1710(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1712); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseIntegerSlashListOrRange() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 243,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseInteger();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 45) {
        s2 = peg$c1706;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1707); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseInteger();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c1714(s1, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$parseIntegerSlashList();
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1713); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parsePercentSlashList() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 244,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parsePercentInteger();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 47) {
        s4 = peg$c967;
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c968); }
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parsePercentInteger();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 47) {
          s4 = peg$c967;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c968); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parsePercentInteger();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1710(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseIntegerSlashList();
      if (s1 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 37) {
          s2 = peg$c12;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c13); }
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c1716(s1);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1715); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseIntegerRangeSlashList() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 255 + 245,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseIntegerOrRange();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 47) {
        s4 = peg$c967;
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c968); }
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parseIntegerOrRange();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 47) {
          s4 = peg$c967;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c968); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseIntegerOrRange();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1710(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1717); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseIntegerOrRange() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 246,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseInteger();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 45) {
        s2 = peg$c1706;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1707); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseInteger();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c1718(s1, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$parseInteger();
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSignedIntegerSlashList() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 247,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    if (peg$c7.test(input.charAt(peg$currPos))) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c8); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseIntegerSlashList();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c1720(s1, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1719); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseIntegerAndList() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    var key    = peg$currPos * 255 + 248,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseInteger();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 44) {
        s5 = peg$c0;
        peg$currPos++;
      } else {
        s5 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1); }
      }
      if (s5 === peg$FAILED) {
        s5 = null;
      }
      if (s5 !== peg$FAILED) {
        s6 = peg$parse_();
        if (s6 !== peg$FAILED) {
          if (input.substr(peg$currPos, 3) === peg$c530) {
            s7 = peg$c530;
            peg$currPos += 3;
          } else {
            s7 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c531); }
          }
          if (s7 !== peg$FAILED) {
            s8 = peg$parse_();
            if (s8 !== peg$FAILED) {
              s5 = [s5, s6, s7, s8];
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
      } else {
        peg$currPos = s4;
        s4 = peg$FAILED;
      }
      if (s4 === peg$FAILED) {
        s4 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 44) {
          s5 = peg$c0;
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1); }
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          if (s6 !== peg$FAILED) {
            s5 = [s5, s6];
            s4 = s5;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parseInteger();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 44) {
          s5 = peg$c0;
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1); }
        }
        if (s5 === peg$FAILED) {
          s5 = null;
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          if (s6 !== peg$FAILED) {
            if (input.substr(peg$currPos, 3) === peg$c530) {
              s7 = peg$c530;
              peg$currPos += 3;
            } else {
              s7 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c531); }
            }
            if (s7 !== peg$FAILED) {
              s8 = peg$parse_();
              if (s8 !== peg$FAILED) {
                s5 = [s5, s6, s7, s8];
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        if (s4 === peg$FAILED) {
          s4 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 44) {
            s5 = peg$c0;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1); }
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            if (s6 !== peg$FAILED) {
              s5 = [s5, s6];
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseInteger();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1710(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1721); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseOccurrence() {
    var s0, s1, s2, s3, s4;

    var key    = peg$currPos * 255 + 249,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4) === peg$c86) {
      s1 = peg$c86;
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c87); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c443();
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 5) === peg$c1722) {
        s1 = peg$c1722;
        peg$currPos += 5;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1723); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1724();
      }
      s0 = s1;
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseNumberString();
        if (s1 === peg$FAILED) {
          s1 = peg$parseInteger();
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          if (s2 !== peg$FAILED) {
            if (input.substr(peg$currPos, 4) === peg$c1725) {
              s3 = peg$c1725;
              peg$currPos += 4;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c1726); }
            }
            if (s3 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 115) {
                s4 = peg$c164;
                peg$currPos++;
              } else {
                s4 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c165); }
              }
              if (s4 === peg$FAILED) {
                s4 = null;
              }
              if (s4 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c358(s1);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseOrdinal() {
    var s0, s1;

    var key    = peg$currPos * 255 + 250,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5) === peg$c1119) {
      s1 = peg$c1119;
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1120); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c443();
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 6) === peg$c441) {
        s1 = peg$c441;
        peg$currPos += 6;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c442); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1724();
      }
      s0 = s1;
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 5) === peg$c1727) {
          s1 = peg$c1727;
          peg$currPos += 5;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1728); }
        }
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c1729();
        }
        s0 = s1;
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.substr(peg$currPos, 6) === peg$c1730) {
            s1 = peg$c1730;
            peg$currPos += 6;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1731); }
          }
          if (s1 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c1732();
          }
          s0 = s1;
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            if (input.substr(peg$currPos, 5) === peg$c1733) {
              s1 = peg$c1733;
              peg$currPos += 5;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c1734); }
            }
            if (s1 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c1735();
            }
            s0 = s1;
          }
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseTuple() {
    var s0, s1;

    var key    = peg$currPos * 255 + 251,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c1736) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1737); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c1724();
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 6).toLowerCase() === peg$c1738) {
        s1 = input.substr(peg$currPos, 6);
        peg$currPos += 6;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1739); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1729();
      }
      s0 = s1;
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 4).toLowerCase() === peg$c1740) {
          s1 = input.substr(peg$currPos, 4);
          peg$currPos += 4;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1741); }
        }
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c1732();
        }
        s0 = s1;
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.substr(peg$currPos, 5).toLowerCase() === peg$c1742) {
            s1 = input.substr(peg$currPos, 5);
            peg$currPos += 5;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1743); }
          }
          if (s1 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c1735();
          }
          s0 = s1;
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            if (input.substr(peg$currPos, 4).toLowerCase() === peg$c1744) {
              s1 = input.substr(peg$currPos, 4);
              peg$currPos += 4;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c1745); }
            }
            if (s1 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c1746();
            }
            s0 = s1;
          }
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseFraction() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 252,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseInteger();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 47) {
        s2 = peg$c967;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c968); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseInteger();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c1747(s1, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseUppercaseWord() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 255 + 253,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (peg$c1302.test(input.charAt(peg$currPos))) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1303); }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      if (peg$c1748.test(input.charAt(peg$currPos))) {
        s3 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1749); }
      }
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          if (peg$c1748.test(input.charAt(peg$currPos))) {
            s3 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1749); }
          }
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1063();
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parse_() {
    var s0, s1;

    var key    = peg$currPos * 255 + 254,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = [];
    if (peg$c1751.test(input.charAt(peg$currPos))) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1752); }
    }
    while (s1 !== peg$FAILED) {
      s0.push(s1);
      if (peg$c1751.test(input.charAt(peg$currPos))) {
        s1 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1752); }
      }
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1750); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }


    let parsedNumberResult = null;
    let statusLevelMatch = null;
    let wantInfinitive = [false];

    // Hack: Suppress warnings about unused functions.
    location;
    expected;
    error;
    peg$anyExpectation;


  peg$result = peg$startRuleFunction();

  if (peg$result !== peg$FAILED && peg$currPos === input.length) {
    return peg$result;
  } else {
    if (peg$result !== peg$FAILED && peg$currPos < input.length) {
      peg$fail(peg$endExpectation());
    }

    throw peg$buildStructuredError(
      peg$maxFailExpected,
      peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
      peg$maxFailPos < input.length
        ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
        : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
    );
  }
}

module.exports = {
  SyntaxError: peg$SyntaxError,
  parse:       peg$parse
};
