/*
 * Generated by PEG.js 0.10.0.
 *
 * http://pegjs.org/
 */

"use strict";

var util = require("./parserHelpers");

function peg$subclass(child, parent) {
  function ctor() { this.constructor = child; }
  ctor.prototype = parent.prototype;
  child.prototype = new ctor();
}

function peg$SyntaxError(message, expected, found, location) {
  this.message  = message;
  this.expected = expected;
  this.found    = found;
  this.location = location;
  this.name     = "SyntaxError";

  if (typeof Error.captureStackTrace === "function") {
    Error.captureStackTrace(this, peg$SyntaxError);
  }
}

peg$subclass(peg$SyntaxError, Error);

peg$SyntaxError.buildMessage = function(expected, found) {
  var DESCRIBE_EXPECTATION_FNS = {
        literal: function(expectation) {
          return "\"" + literalEscape(expectation.text) + "\"";
        },

        "class": function(expectation) {
          var escapedParts = "",
              i;

          for (i = 0; i < expectation.parts.length; i++) {
            escapedParts += expectation.parts[i] instanceof Array
              ? classEscape(expectation.parts[i][0]) + "-" + classEscape(expectation.parts[i][1])
              : classEscape(expectation.parts[i]);
          }

          return "[" + (expectation.inverted ? "^" : "") + escapedParts + "]";
        },

        any: function(expectation) {
          return "any character";
        },

        end: function(expectation) {
          return "end of input";
        },

        other: function(expectation) {
          return expectation.description;
        }
      };

  function hex(ch) {
    return ch.charCodeAt(0).toString(16).toUpperCase();
  }

  function literalEscape(s) {
    return s
      .replace(/\\/g, '\\\\')
      .replace(/"/g,  '\\"')
      .replace(/\0/g, '\\0')
      .replace(/\t/g, '\\t')
      .replace(/\n/g, '\\n')
      .replace(/\r/g, '\\r')
      .replace(/[\x00-\x0F]/g,          function(ch) { return '\\x0' + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return '\\x'  + hex(ch); });
  }

  function classEscape(s) {
    return s
      .replace(/\\/g, '\\\\')
      .replace(/\]/g, '\\]')
      .replace(/\^/g, '\\^')
      .replace(/-/g,  '\\-')
      .replace(/\0/g, '\\0')
      .replace(/\t/g, '\\t')
      .replace(/\n/g, '\\n')
      .replace(/\r/g, '\\r')
      .replace(/[\x00-\x0F]/g,          function(ch) { return '\\x0' + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return '\\x'  + hex(ch); });
  }

  function describeExpectation(expectation) {
    return DESCRIBE_EXPECTATION_FNS[expectation.type](expectation);
  }

  function describeExpected(expected) {
    var descriptions = new Array(expected.length),
        i, j;

    for (i = 0; i < expected.length; i++) {
      descriptions[i] = describeExpectation(expected[i]);
    }

    descriptions.sort();

    if (descriptions.length > 0) {
      for (i = 1, j = 1; i < descriptions.length; i++) {
        if (descriptions[i - 1] !== descriptions[i]) {
          descriptions[j] = descriptions[i];
          j++;
        }
      }
      descriptions.length = j;
    }

    switch (descriptions.length) {
      case 1:
        return descriptions[0];

      case 2:
        return descriptions[0] + " or " + descriptions[1];

      default:
        return descriptions.slice(0, -1).join(", ")
          + ", or "
          + descriptions[descriptions.length - 1];
    }
  }

  function describeFound(found) {
    return found ? "\"" + literalEscape(found) + "\"" : "end of input";
  }

  return "Expected " + describeExpected(expected) + " but " + describeFound(found) + " found.";
};

function peg$parse(input, options) {
  options = options !== void 0 ? options : {};

  var peg$FAILED = {},

      peg$startRuleFunctions = { SkillEffect: peg$parseSkillEffect },
      peg$startRuleFunction  = peg$parseSkillEffect,

      peg$c0 = ",",
      peg$c1 = peg$literalExpectation(",", false),
      peg$c2 = ".",
      peg$c3 = peg$literalExpectation(".", false),
      peg$c4 = "Also",
      peg$c5 = peg$literalExpectation("Also", false),
      peg$c6 = "Additional",
      peg$c7 = peg$literalExpectation("Additional", false),
      peg$c8 = "and",
      peg$c9 = peg$literalExpectation("and", false),
      peg$c10 = function(head, conj, effect) { return [conj, effect]; },
      peg$c11 = function(head, tail) {
          const effects = [head];
          for (let [conj, effect] of tail) {
            if (conj === 'and') {
              effect.andEffect = true;
            }
            effects.push(effect);
          }
          return util.mergeAttackExtrasAndEffects(effects);
        },
      peg$c12 = "",
      peg$c13 = function() { return []; },
      peg$c14 = function(attack, extras) {
          return Object.assign({}, attack, extras);
        },
      peg$c15 = "attack",
      peg$c16 = peg$literalExpectation("attack", false),
      peg$c17 = "s",
      peg$c18 = peg$literalExpectation("s", false),
      peg$c19 = "(",
      peg$c20 = peg$literalExpectation("(", false),
      peg$c21 = ")",
      peg$c22 = peg$literalExpectation(")", false),
      peg$c23 = function(numAttacks, attackType, modifiers, group) { return group; },
      peg$c24 = "with additional",
      peg$c25 = peg$literalExpectation("with additional", false),
      peg$c26 = "% critical damage",
      peg$c27 = peg$literalExpectation("% critical damage", false),
      peg$c28 = function(numAttacks, attackType, modifiers, attackMultiplierGroup, value) { return value; },
      peg$c29 = function(numAttacks, attackType, modifiers, attackMultiplierGroup, additionalCritDamage, value) { return value },
      peg$c30 = "capped at",
      peg$c31 = peg$literalExpectation("capped at", false),
      peg$c32 = function(numAttacks, attackType, modifiers, attackMultiplierGroup, additionalCritDamage, scalingOverstrike, value) { return value; },
      peg$c33 = "that ignores DEF",
      peg$c34 = peg$literalExpectation("that ignores DEF", false),
      peg$c35 = "that ignores RES",
      peg$c36 = peg$literalExpectation("that ignores RES", false),
      peg$c37 = function(numAttacks, attackType, modifiers, attackMultiplierGroup, additionalCritDamage, scalingOverstrike, overstrikeCap, isPiercingDef, isPiercingRes) {
          const result = Object.assign({
            type: 'attack',
            numAttacks,
          }, attackMultiplierGroup || {}, scalingOverstrike || {});
          if (overstrikeCap) {
            result.overstrikeCap = overstrikeCap;
          }
          if (additionalCritDamage) {
            result.additionalCritDamage = additionalCritDamage;
          }

          // Alternate isPiercingDef / isPiercingRes format that's only used for
          // "followed by" attacks.  These are normally handled within AttackExtras.
          if (isPiercingDef) {
            result.isPiercingDef = true;
          }
          if (isPiercingRes) {
            result.isPiercingRes = true;
          }

          if (attackType === 'group') {
            result.isAoE = true;
          }
          if (modifiers.hybrid) {
            result.isHybrid = true;
          }
          if (modifiers.jump) {
            result.isJump = true;
          }
          if (modifiers.ranged) {
            result.isRanged = true;
          }
          return result;
        },
      peg$c38 = "that",
      peg$c39 = peg$literalExpectation("that", false),
      peg$c40 = "deal",
      peg$c41 = peg$literalExpectation("deal", false),
      peg$c42 = "damage",
      peg$c43 = peg$literalExpectation("damage", false),
      peg$c44 = "each",
      peg$c45 = peg$literalExpectation("each", false),
      peg$c46 = function(numAttacks, attackType, fixedDamage) {
          const result = {
            type: 'fixedAttack',
            fixedDamage,
            numAttacks,
          };
          if (attackType === 'group') {
            result.isAoE = true;
          }
          return result;
        },
      peg$c47 = "randomly",
      peg$c48 = peg$literalExpectation("Randomly", true),
      peg$c49 = "deals",
      peg$c50 = peg$literalExpectation("deals", false),
      peg$c51 = function(head, tail) {
          return {
            type: 'randomFixedAttack',
            fixedDamage: util.pegList(head, tail, 1),
          };
        },
      peg$c52 = function(value) { return { type: 'randomNumAttacks', value }; },
      peg$c53 = function(head, tail) {
          // Returns Array<number | [number, number]>, giving either numbers of attacks
          // with identical chances, or tuples of number of attacks with percent chance.
          return util.pegList(head, tail, 1);
        },
      peg$c54 = function(value, chance) { return value.length === chance.length; },
      peg$c55 = function(value, chance) { return value.map((v, i) => [v, chance[i]]); },
      peg$c56 = "%)",
      peg$c57 = peg$literalExpectation("%)", false),
      peg$c58 = function(value, c) { return c; },
      peg$c59 = function(value, chance) { return [value, chance]; },
      peg$c60 = peg$literalExpectation("randomly", false),
      peg$c61 = "or",
      peg$c62 = peg$literalExpectation("or", false),
      peg$c63 = function(randomAttackMultiplier, attackMultiplier, n) { return n; },
      peg$c64 = "~",
      peg$c65 = peg$literalExpectation("~", false),
      peg$c66 = function(randomAttackMultiplier, attackMultiplier, hybridMultiplier, n) { return n; },
      peg$c67 = "physical",
      peg$c68 = peg$literalExpectation("physical", false),
      peg$c69 = "magical",
      peg$c70 = peg$literalExpectation("magical", false),
      peg$c71 = function(randomAttackMultiplier, attackMultiplier, hybridMultiplier, scaleToMultiplier, multiplierScaleType, damageType) {
          const result = {
            attackMultiplier,
          };
          if (randomAttackMultiplier) {
            result.isRandomAttackMultiplier = true;
          }
          if (hybridMultiplier != null) {
            result.hybridMultiplier = hybridMultiplier;
          }
          if (scaleToMultiplier != null) {
            result.scaleToMultiplier = scaleToMultiplier;
          }
          if (multiplierScaleType) {
            result.multiplierScaleType = multiplierScaleType;
          }
          if (damageType) {
            // Skill types are only overridden for Dirty Trick.  That's very niche.
            // Assume PHY and BLK.
            result.overrideSkillType = damageType === 'physical' ? 'PHY' : 'BLK';
          }
          return result;
        },
      peg$c72 = "group",
      peg$c73 = peg$literalExpectation("group", false),
      peg$c74 = "random",
      peg$c75 = peg$literalExpectation("random", false),
      peg$c76 = "single",
      peg$c77 = peg$literalExpectation("single", false),
      peg$c78 = "hybrid",
      peg$c79 = peg$literalExpectation("hybrid", false),
      peg$c80 = "rang.",
      peg$c81 = peg$literalExpectation("rang.", false),
      peg$c82 = "ranged",
      peg$c83 = peg$literalExpectation("ranged", false),
      peg$c84 = "jump",
      peg$c85 = peg$literalExpectation("jump", false),
      peg$c86 = function(modifier) { return modifier; },
      peg$c87 = function(modifiers) {
          return {
            hybrid: modifiers.indexOf('hybrid') !== -1,
            ranged: modifiers.indexOf('ranged') !== -1 || modifiers.indexOf('rang.') !== -1,
            jump: modifiers.indexOf('jump') !== -1,
          };
        },
      peg$c88 = "scaling",
      peg$c89 = peg$literalExpectation("scaling", false),
      peg$c90 = "with",
      peg$c91 = peg$literalExpectation("with", false),
      peg$c92 = "current",
      peg$c93 = peg$literalExpectation("current", false),
      peg$c94 = "HP%",
      peg$c95 = peg$literalExpectation("HP%", false),
      peg$c96 = function() { return { type: 'percentHp' }; },
      peg$c97 = "targets",
      peg$c98 = peg$literalExpectation("targets", false),
      peg$c99 = function() { return { type: 'convergent' }; },
      peg$c100 = function(stat) { return { type: 'stat', stat }; },
      peg$c101 = "hits",
      peg$c102 = peg$literalExpectation("hits", false),
      peg$c103 = "taken",
      peg$c104 = peg$literalExpectation("taken", false),
      peg$c105 = function() { return { type: 'hitsTaken' }; },
      peg$c106 = "abilities",
      peg$c107 = peg$literalExpectation("abilities", false),
      peg$c108 = "used",
      peg$c109 = peg$literalExpectation("used", false),
      peg$c110 = function(school) { return { type: 'abilitiesUsed', school }; },
      peg$c111 = "attacks",
      peg$c112 = peg$literalExpectation("attacks", false),
      peg$c113 = function(element) { return { type: 'attacksUsed', element }; },
      peg$c114 = "[Doom]",
      peg$c115 = peg$literalExpectation("[Doom]", false),
      peg$c116 = "Doom",
      peg$c117 = peg$literalExpectation("Doom", false),
      peg$c118 = "timer,",
      peg$c119 = peg$literalExpectation("timer,", false),
      peg$c120 = "default",
      peg$c121 = peg$literalExpectation("default", false),
      peg$c122 = function(defaultMultiplier) { return { type: 'doomTimer', defaultMultiplier }; },
      peg$c123 = "scaling with LB gauge and LB honing level",
      peg$c124 = peg$literalExpectation("scaling with LB gauge and LB honing level", false),
      peg$c125 = function() { return { type: 'limitBreak' }; },
      peg$c126 = function(extras) {
          // "and" likely means two clauses (e.g., AdditionalCrit and AdditionalCritDamage)
          // that share a condition clause.  We do not currently attempt to capture this;
          // our generated text makes it clear enough to a human.
          return extras.reduce((result, element) => Object.assign(result, element[2]), {});
        },
      peg$c127 = "additional",
      peg$c128 = peg$literalExpectation("additional", false),
      peg$c129 = "add.",
      peg$c130 = peg$literalExpectation("add.", false),
      peg$c131 = "critical",
      peg$c132 = peg$literalExpectation("critical", false),
      peg$c133 = "crit.",
      peg$c134 = peg$literalExpectation("crit.", false),
      peg$c135 = function(additionalCritDamage, condition) {
          return util.addCondition({ additionalCritDamage }, condition, 'additionalCritDamageCondition');
        },
      peg$c136 = "chance",
      peg$c137 = peg$literalExpectation("chance", false),
      peg$c138 = function(additionalCrit, condition) {
          return util.addCondition({ additionalCrit }, condition, 'additionalCritCondition');
        },
      peg$c139 = "air",
      peg$c140 = peg$literalExpectation("air", false),
      peg$c141 = "time",
      peg$c142 = peg$literalExpectation("time", false),
      peg$c143 = "sec.",
      peg$c144 = peg$literalExpectation("sec.", false),
      peg$c145 = function(airTime, condition) { return util.addCondition({ airTime }, condition, 'airTimeCondition'); },
      peg$c146 = "capped",
      peg$c147 = peg$literalExpectation("capped", false),
      peg$c148 = "at",
      peg$c149 = peg$literalExpectation("at", false),
      peg$c150 = "99999",
      peg$c151 = peg$literalExpectation("99999", false),
      peg$c152 = function() { return { overstrikeCap: 99999 }; },
      peg$c153 = "always",
      peg$c154 = peg$literalExpectation("always", false),
      peg$c155 = "a",
      peg$c156 = peg$literalExpectation("a", false),
      peg$c157 = "hit",
      peg$c158 = peg$literalExpectation("hit", false),
      peg$c159 = function() { return { alwaysCrits: true }; },
      peg$c160 = "ATK",
      peg$c161 = peg$literalExpectation("ATK", false),
      peg$c162 = "increases",
      peg$c163 = peg$literalExpectation("increases", false),
      peg$c164 = "as",
      peg$c165 = peg$literalExpectation("as", false),
      peg$c166 = "HP",
      peg$c167 = peg$literalExpectation("HP", false),
      peg$c168 = "decrease",
      peg$c169 = peg$literalExpectation("decrease", false),
      peg$c170 = function() { return { atkUpWithLowHp: true }; },
      peg$c171 = function(scaleType) { return { scaleType } },
      peg$c172 = "%",
      peg$c173 = peg$literalExpectation("%", false),
      peg$c174 = "to",
      peg$c175 = peg$literalExpectation("to", false),
      peg$c176 = "cause",
      peg$c177 = peg$literalExpectation("cause", false),
      peg$c178 = function(chance, status, duration, condition) {
          return { status: util.addCondition({ status, chance, duration }, condition) };
        },
      peg$c179 = "more",
      peg$c180 = peg$literalExpectation("more", false),
      peg$c181 = function(damageModifier, condition) { return { damageModifier, damageModifierCondition: condition }; },
      peg$c182 = "for",
      peg$c183 = peg$literalExpectation("for", false),
      peg$c184 = "of",
      peg$c185 = peg$literalExpectation("of", false),
      peg$c186 = "the",
      peg$c187 = peg$literalExpectation("the", false),
      peg$c188 = "dealt",
      peg$c189 = peg$literalExpectation("dealt", false),
      peg$c190 = "during",
      peg$c191 = peg$literalExpectation("during", false),
      peg$c192 = "status",
      peg$c193 = peg$literalExpectation("status", false),
      peg$c194 = function(value, criteria) {
          return { finisherPercentDamage: value, finisherPercentCriteria: criteria };
        },
      peg$c195 = "followed",
      peg$c196 = peg$literalExpectation("followed", false),
      peg$c197 = "by",
      peg$c198 = peg$literalExpectation("by", false),
      peg$c199 = function(followedBy) { return { followedBy }; },
      peg$c200 = "rate",
      peg$c201 = peg$literalExpectation("rate", false),
      peg$c202 = function(hitRate) { return { hitRate }; },
      peg$c203 = "if",
      peg$c204 = peg$literalExpectation("if", false),
      peg$c205 = "triggered)",
      peg$c206 = peg$literalExpectation("triggered)", false),
      peg$c207 = "minimum",
      peg$c208 = peg$literalExpectation("minimum", false),
      peg$c209 = "min.",
      peg$c210 = peg$literalExpectation("min.", false),
      peg$c211 = "(SUM only)",
      peg$c212 = peg$literalExpectation("(SUM only)", false),
      peg$c213 = function(minDamage) { return { minDamage }; },
      peg$c214 = function(orMultiplier, m) { return m; },
      peg$c215 = "multiplier",
      peg$c216 = peg$literalExpectation("multiplier", false),
      peg$c217 = "mult.",
      peg$c218 = peg$literalExpectation("mult.", false),
      peg$c219 = function(orMultiplier, orHybridMultiplier, orMultiplierCondition) {
          return { orMultiplier, orHybridMultiplier, orMultiplierCondition };
        },
      peg$c220 = function(orNumAttacks, orNumAttacksCondition) {
          return { orNumAttacks, orNumAttacksCondition };
        },
      peg$c221 = function(overrideElement) {
          return { overrideElement };
        },
      peg$c222 = "ignores DEF",
      peg$c223 = peg$literalExpectation("ignores DEF", false),
      peg$c224 = function() { return { isPiercingDef: true }; },
      peg$c225 = "ignores RES",
      peg$c226 = peg$literalExpectation("ignores RES", false),
      peg$c227 = function() { return { isPiercingRes: true }; },
      peg$c228 = "ignores DEF/RES",
      peg$c229 = peg$literalExpectation("ignores DEF/RES", false),
      peg$c230 = "ignores DEF and RES",
      peg$c231 = peg$literalExpectation("ignores DEF and RES", false),
      peg$c232 = function() { return { isPiercingDef: true, isPiercingRes: true }; },
      peg$c233 = "scales",
      peg$c234 = peg$literalExpectation("scales", false),
      peg$c235 = "both",
      peg$c236 = peg$literalExpectation("both", false),
      peg$c237 = "DEF",
      peg$c238 = peg$literalExpectation("DEF", false),
      peg$c239 = function() { return { scalesWithAtkAndDef: true }; },
      peg$c240 = "/",
      peg$c241 = peg$literalExpectation("/", false),
      peg$c242 = function(head, tail) { return { scalingOverstrike: util.pegList(head, tail, 1) }; },
      peg$c243 = "increased",
      peg$c244 = peg$literalExpectation("increased", false),
      peg$c245 = "decreased",
      peg$c246 = peg$literalExpectation("decreased", false),
      peg$c247 = "every",
      peg$c248 = peg$literalExpectation("every", false),
      peg$c249 = "SB",
      peg$c250 = peg$literalExpectation("SB", false),
      peg$c251 = "point",
      peg$c252 = peg$literalExpectation("point", false),
      peg$c253 = function(verb, value) {
          return { sbMultiplierChange: value * (verb === 'increased' ? 1 : -1) };
        },
      peg$c254 = "heals",
      peg$c255 = peg$literalExpectation("heals", false),
      peg$c256 = "restores HP to",
      peg$c257 = peg$literalExpectation("restores HP to", false),
      peg$c258 = "the user for",
      peg$c259 = peg$literalExpectation("the user for", false),
      peg$c260 = "% of the damage dealt",
      peg$c261 = peg$literalExpectation("% of the damage dealt", false),
      peg$c262 = function(healPercent, condition) {
          return util.addCondition({
            type: 'drainHp',
            healPercent,
          }, condition);
        },
      peg$c263 = "damages the user for",
      peg$c264 = peg$literalExpectation("damages the user for", false),
      peg$c265 = function(damagePercent) { return text().startsWith('max') ? 'max' : 'curr'; },
      peg$c266 = function(damagePercent, maxOrCurrent, condition) {
          return util.addCondition({
            type: 'recoilHp',
            damagePercent,
            maxOrCurrent,
          }, condition);
        },
      peg$c267 = "sets the user's HP to",
      peg$c268 = peg$literalExpectation("sets the user's HP to", false),
      peg$c269 = function(hp, condition) {
          return util.addCondition({ type: 'recoilSetHp', hp }, condition);
        },
      peg$c270 = function(value, skillType, who) {
          return {
            type: 'fixedRecoilHp',
            value,
            skillType,
            who,
          };
        },
      peg$c271 = "damages",
      peg$c272 = peg$literalExpectation("damages", true),
      peg$c273 = "target's",
      peg$c274 = peg$literalExpectation("target's", false),
      peg$c275 = function(damagePercent) {
          return { type: 'gravityAttack', damagePercent };
        },
      peg$c276 = "*",
      peg$c277 = peg$literalExpectation("*", false),
      peg$c278 = "(user's",
      peg$c279 = peg$literalExpectation("(user's", false),
      peg$c280 = "-",
      peg$c281 = peg$literalExpectation("-", false),
      peg$c282 = "user's",
      peg$c283 = peg$literalExpectation("user's", false),
      peg$c284 = "HP)",
      peg$c285 = peg$literalExpectation("HP)", false),
      peg$c286 = function(multiplier) {
          return {
            type: 'hpAttack',
            multiplier
          }
        },
      peg$c287 = "removes",
      peg$c288 = peg$literalExpectation("removes", true),
      peg$c289 = "KO",
      peg$c290 = peg$literalExpectation("KO", false),
      peg$c291 = "[Raise:",
      peg$c292 = peg$literalExpectation("[Raise:", false),
      peg$c293 = "%]",
      peg$c294 = peg$literalExpectation("%]", false),
      peg$c295 = function(percentHp, who) {
          const result = {
            type: 'revive',
            percentHp,
          };
          if (who) {
            result.who = who;
          }
          return result;
        },
      peg$c296 = "restores",
      peg$c297 = peg$literalExpectation("restores", true),
      peg$c298 = function(healFactor) { return { healFactor }; },
      peg$c299 = function(fixedHp) { return { fixedHp }; },
      peg$c300 = function(amount, who, condition, perUses) {
          return util.addCondition({
            type: 'heal',
            amount,
            who,
            perUses,
          }, condition);
        },
      peg$c301 = "% of",
      peg$c302 = peg$literalExpectation("% of", false),
      peg$c303 = "the user's",
      peg$c304 = peg$literalExpectation("the user's", false),
      peg$c305 = "the target's",
      peg$c306 = peg$literalExpectation("the target's", false),
      peg$c307 = "their",
      peg$c308 = peg$literalExpectation("their", false),
      peg$c309 = function(who, healPercent) {
          return {
            type: 'healPercent',
            healPercent,
            who,
          }
        },
      peg$c310 = peg$literalExpectation("damages", false),
      peg$c311 = "undead",
      peg$c312 = peg$literalExpectation("undead", false),
      peg$c313 = function() {
          return { type: 'damagesUndead' };
        },
      peg$c314 = "negative",
      peg$c315 = peg$literalExpectation("negative", false),
      peg$c316 = "positive",
      peg$c317 = peg$literalExpectation("positive", false),
      peg$c318 = "effects",
      peg$c319 = peg$literalExpectation("effects", false),
      peg$c320 = function(dispelOrEsuna, who, perUses) {
          return { type: 'dispelOrEsuna', dispelOrEsuna, who, perUses };
        },
      peg$c321 = "consumed",
      peg$c322 = peg$literalExpectation("consumed", false),
      peg$c323 = "ability",
      peg$c324 = peg$literalExpectation("ability", false),
      peg$c325 = "use",
      peg$c326 = peg$literalExpectation("use", false),
      peg$c327 = function(amount, who, perUses) {
          return { type: 'randomEther', amount, who, perUses };
        },
      peg$c328 = function(status, who, perUses) { return Object.assign({}, status, { who, perUses }); },
      peg$c329 = peg$literalExpectation("randomly", true),
      peg$c330 = "casts",
      peg$c331 = peg$literalExpectation("casts", false),
      peg$c332 = function(abilities) { return { type: 'randomCastAbility', abilities }; },
      peg$c333 = function(head, tail) { return util.pegList(head, tail, 1); },
      peg$c334 = function(ability, chance) {
          return {
            ability,
            chance: chance ? chance[1] : undefined
          };
        },
      peg$c335 = peg$literalExpectation("casts", true),
      peg$c336 = function(other) { return { type: 'randomCastOther', other }; },
      peg$c337 = "activates",
      peg$c338 = peg$literalExpectation("activates", true),
      peg$c339 = /^[a-zA-Z0-9\-\/]/,
      peg$c340 = peg$classExpectation([["a", "z"], ["A", "Z"], ["0", "9"], "-", "/"], false, false),
      peg$c341 = "Chain",
      peg$c342 = peg$literalExpectation("Chain", false),
      peg$c343 = "(max",
      peg$c344 = peg$literalExpectation("(max", false),
      peg$c345 = "field",
      peg$c346 = peg$literalExpectation("field", false),
      peg$c347 = function(chainType, max, fieldBonus) {
          return {
            type: 'chain',
            chainType: chainType.join(''),
            max,
            fieldBonus,
          }
        },
      peg$c348 = function(c) { return c; },
      peg$c349 = "cast",
      peg$c350 = peg$literalExpectation("cast", true),
      peg$c351 = "the last ability used by any ally",
      peg$c352 = peg$literalExpectation("the last ability used by any ally", false),
      peg$c353 = "(PHY:",
      peg$c354 = peg$literalExpectation("(PHY:", false),
      peg$c355 = "single,",
      peg$c356 = peg$literalExpectation("single,", false),
      peg$c357 = function(chance, occurrence, defaultPower, c) { return c; },
      peg$c358 = function(chance, occurrence, defaultPower, defaultCritChance) {
          const result = {
            type: 'mimic',
            count: occurrence,
            defaultPower
          };
          if (chance) {
            result.chance = chance;
          }
          if (defaultCritChance) {
            result.defaultCritChance = defaultCritChance;
          }
          return result;
        },
      peg$c359 = "summons",
      peg$c360 = peg$literalExpectation("Summons", true),
      peg$c361 = "seconds",
      peg$c362 = peg$literalExpectation("seconds", false),
      peg$c363 = function(name, duration) { return { type: 'summon', name, duration: { value: duration, units: 'seconds' } }; },
      peg$c364 = "all",
      peg$c365 = peg$literalExpectation("all", false),
      peg$c366 = function(verb, all, statuses, statusClauses) {
          const result = { type: 'status', verb, statuses };
          if (all) {
            result.all = true;
          }
          for (const i of statusClauses) {
            Object.assign(result, i);
          }
          if (result.duration) {
            util.applyDuration(result.statuses, result.duration);
            delete result.duration;
          }
          return result;
        },
      peg$c367 = "[",
      peg$c368 = peg$literalExpectation("[", false),
      peg$c369 = function(statuses, statusClauses) {
          const result = { type: 'status', statuses };
          for (const i of statusClauses) {
            Object.assign(result, i);
          }
          if (result.duration) {
            util.applyDuration(result.statuses, result.duration);
            delete result.duration;
          }
          return result;
        },
      peg$c370 = "set",
      peg$c371 = peg$literalExpectation("set", true),
      peg$c372 = "level",
      peg$c373 = peg$literalExpectation("level", false),
      peg$c374 = function(status, value) {
          return { type: 'setStatusLevel', status, value };
        },
      peg$c375 = function(verb, head, tail) {
          return { type: 'randomStatus', verb, statuses: util.pegList(head, tail, 3) };
        },
      peg$c376 = function(head, tail, chance, who) {
          return { status: util.pegList(head, tail, 1, true), chance, who };
        },
      peg$c377 = "transfers",
      peg$c378 = peg$literalExpectation("transfers", true),
      peg$c379 = "points to the target",
      peg$c380 = peg$literalExpectation("points to the target", false),
      peg$c381 = " (maximum of",
      peg$c382 = peg$literalExpectation(" (maximum of", false),
      peg$c383 = "Soul Break points)",
      peg$c384 = peg$literalExpectation("Soul Break points)", false),
      peg$c385 = function(max) { return max; },
      peg$c386 = function(max) { return { type: 'entrust', max }; },
      peg$c387 = "grants",
      peg$c388 = peg$literalExpectation("grants", true),
      peg$c389 = "points",
      peg$c390 = peg$literalExpectation("points", false),
      peg$c391 = function(points, who) { return { type: 'gainSB', points, who }; },
      peg$c392 = "successful",
      peg$c393 = peg$literalExpectation("successful", false),
      peg$c394 = "?",
      peg$c395 = peg$literalExpectation("?", false),
      peg$c396 = function(points, who) { return { type: 'gainSBOnSuccess', points, who }; },
      peg$c397 = "resets",
      peg$c398 = peg$literalExpectation("resets", false),
      peg$c399 = "KO'd",
      peg$c400 = peg$literalExpectation("KO'd", false),
      peg$c401 = function() { return { type: 'resetIfKO' }; },
      peg$c402 = "resisted",
      peg$c403 = peg$literalExpectation("resisted", false),
      peg$c404 = "via",
      peg$c405 = peg$literalExpectation("via", false),
      peg$c406 = "Instant",
      peg$c407 = peg$literalExpectation("Instant", false),
      peg$c408 = function() { return { type: 'resistViaKO' }; },
      peg$c409 = "reset",
      peg$c410 = peg$literalExpectation("reset", false),
      peg$c411 = function() { return { type: 'reset' }; },
      peg$c412 = peg$literalExpectation("cast", false),
      peg$c413 = function(castTime, condition) { return { type: 'castTime', castTime, condition }; },
      peg$c414 = "previous",
      peg$c415 = peg$literalExpectation("previous", false),
      peg$c416 = function(castTime) { return { type: 'castTimePerUse', castTimePerUse: -castTime }; },
      peg$c417 = function(extra) { return { type: 'attackExtra', extra }; },
      peg$c418 = "Randomly",
      peg$c419 = peg$literalExpectation("Randomly", false),
      peg$c420 = function(head, tail) {
          return { type: 'randomSkillEffect', effects: util.pegList(head, tail, 2) };
        },
      peg$c421 = function(effect, chance) { return { effect, chance }; },
      peg$c422 = function(duration) { return { duration }; },
      peg$c423 = ", if in the party,",
      peg$c424 = peg$literalExpectation(", if in the party,", false),
      peg$c425 = function(who, toCharacter) { return { who, toCharacter }; },
      peg$c426 = function(who) { return { who }; },
      peg$c427 = function(toCharacter) { return { toCharacter }; },
      peg$c428 = function(perUses) { return { perUses }; },
      peg$c429 = "if successful",
      peg$c430 = peg$literalExpectation("if successful", false),
      peg$c431 = function() { return { ifSuccessful: true }; },
      peg$c432 = "to undeads",
      peg$c433 = peg$literalExpectation("to undeads", false),
      peg$c434 = function() { return { ifUndead: true }; },
      peg$c435 = function(condition) { return { condition }; },
      peg$c436 = function() { return {}; },
      peg$c437 = function(clause) {
          return clause;
        },
      peg$c438 = function(head, conj, status) { return { ...status, conj }; },
      peg$c439 = function(head, tail) {
          return [head, ...tail];
        },
      peg$c440 = function(status, n) { return n; },
      peg$c441 = function(status, chance, duration) {
          const result = {
            status
          };
          if (chance) {
            result.chance = chance;
          }
          if (duration) {
            result.duration = duration;
          }
          return result;
        },
      peg$c442 = peg$otherExpectation("status with level"),
      peg$c443 = function(name, value) {
          return { type:'statusLevel', name, value, set: true };
        },
      peg$c444 = function(name, value, max) {
          return { type:'statusLevel', name, value, max };
        },
      peg$c445 = function(value, name, max) { return { type:'statusLevel', name, value, max }; },
      peg$c446 = function(name) {
              statusLevelMatch = name.match(/(.*) ((?:[+-]?\d+)(?:\/[+-]?\d+)*)$/);
              return statusLevelMatch;
            },
      peg$c447 = function(name, max) { return { type:'statusLevel', name: statusLevelMatch[1], value: util.scalarify(statusLevelMatch[2].split('/').map(i => +i)), max }; },
      peg$c448 = function(name) { return { type:'statusLevel', name, value: 1, set: true }; },
      peg$c449 = function(value) { return value; },
      peg$c450 = function(name) { return { type: 'standardStatus', name }; },
      peg$c451 = "when",
      peg$c452 = peg$literalExpectation("when", false),
      peg$c453 = "equipping",
      peg$c454 = peg$literalExpectation("equipping", false),
      peg$c455 = "n",
      peg$c456 = peg$literalExpectation("n", false),
      peg$c457 = function() { return text(); },
      peg$c458 = /^[a-z\- ]/,
      peg$c459 = peg$classExpectation([["a", "z"], "-", " "], false, false),
      peg$c460 = function(article, equipped) { return { type: 'equipped', article, equipped: equipped.join('') }; },
      peg$c461 = "scaling with",
      peg$c462 = peg$literalExpectation("scaling with", false),
      peg$c463 = function(status) { return { type: 'scaleWithStatusLevel', status }; },
      peg$c464 = "levels",
      peg$c465 = peg$literalExpectation("levels", false),
      peg$c466 = function(status, value) { return { type: 'statusLevel', status, value }; },
      peg$c467 = "user",
      peg$c468 = peg$literalExpectation("user", false),
      peg$c469 = "has",
      peg$c470 = peg$literalExpectation("has", false),
      peg$c471 = "+",
      peg$c472 = peg$literalExpectation("+", false),
      peg$c473 = function(status, value, plus) { return { type: 'statusLevel', status, value, plus: !!plus }; },
      peg$c474 = "=",
      peg$c475 = peg$literalExpectation("=", false),
      peg$c476 = ">",
      peg$c477 = peg$literalExpectation(">", false),
      peg$c478 = function(status, value) { return { type: 'statusLevel', status, value: value + 1, plus: true }; },
      peg$c479 = "least",
      peg$c480 = peg$literalExpectation("least", false),
      peg$c481 = function(value, status) { return { type: 'statusLevel', status, value }; },
      peg$c482 = "any",
      peg$c483 = peg$literalExpectation("any", false),
      peg$c484 = function() { return { type: 'ifDoomed' }; },
      peg$c485 = "target",
      peg$c486 = peg$literalExpectation("target", false),
      peg$c487 = function(who, head, tail) { return util.pegList(head, tail, 1); },
      peg$c488 = function(who, status) {
          return {
            type: 'statusList',
            status: status.map(i => ({ status: i })), // Convert to StatusWithPercent[]
            who: who === 'user' ? 'self' : 'target',
          };
        },
      peg$c489 = function(who, withoutWith, any, head, tail) { return util.pegList(head, tail, 1, true); },
      peg$c490 = function(who, withoutWith, any, status) {
          return {
            type: 'status',
            status,  // In string form - callers must separate by comma, "or", etc.
            who: who === 'user' ? 'self' : 'target',
            any: !!any,
            withoutWith,
          };
        },
      peg$c491 = "if current number of combined Attach Element statuses on party members are a majority Attach",
      peg$c492 = peg$literalExpectation("if current number of combined Attach Element statuses on party members are a majority Attach", false),
      peg$c493 = ". In the case of ties the prior listed order is used to determine status granted",
      peg$c494 = peg$literalExpectation(". In the case of ties the prior listed order is used to determine status granted", false),
      peg$c495 = ". Considers number of stacks on a character as well (Attach Fire at level 2 counts as 2). In the case of ties the prior listed order is used to determine status granted",
      peg$c496 = peg$literalExpectation(". Considers number of stacks on a character as well (Attach Fire at level 2 counts as 2). In the case of ties the prior listed order is used to determine status granted", false),
      peg$c497 = function(element) {
          return { type: 'conditionalEnElement', element };
        },
      peg$c498 = "after",
      peg$c499 = peg$literalExpectation("after", false),
      peg$c500 = "uses",
      peg$c501 = peg$literalExpectation("uses", false),
      peg$c502 = function(useCount) { return { type: 'scaleUseCount', useCount }; },
      peg$c503 = "activations",
      peg$c504 = peg$literalExpectation("activations", false),
      peg$c505 = function() { return { type: 'scaleWithUses' }; },
      peg$c506 = "scal.",
      peg$c507 = peg$literalExpectation("scal.", false),
      peg$c508 = function(skill) { return { type: 'scaleWithSkillUses', skill }; },
      peg$c509 = function(useCount, skill) { return { type: 'afterUseCount', skill, useCount }; },
      peg$c510 = function(count) { return { type: 'afterUseCount', useCount: { from: count, to: count } }; },
      peg$c511 = "on",
      peg$c512 = peg$literalExpectation("on", false),
      peg$c513 = "first",
      peg$c514 = peg$literalExpectation("first", false),
      peg$c515 = function() { return { type: 'afterUseCount', useCount: { from: 1, to: 1 } }; },
      peg$c516 = function(first) { return { type: 'afterUseCount', useCount: { from: first } }; },
      peg$c517 = "allies",
      peg$c518 = peg$literalExpectation("allies", false),
      peg$c519 = "are",
      peg$c520 = peg$literalExpectation("are", false),
      peg$c521 = "alive",
      peg$c522 = peg$literalExpectation("alive", false),
      peg$c523 = function() { return { type: 'alliesAlive' }; },
      peg$c524 = "is",
      peg$c525 = peg$literalExpectation("is", false),
      peg$c526 = function(character) { return { type: 'characterAlive', character }; },
      peg$c527 = function(character) { return { type: 'characterAlive', character, all: true }; },
      peg$c528 = "not alive/alive",
      peg$c529 = peg$literalExpectation("not alive/alive", false),
      peg$c530 = function(character) { return { type: 'characterAlive', character, withoutWith: 'withoutWith' }; },
      peg$c531 = function(count, character) { return { type: 'characterAlive', character, count }; },
      peg$c532 = "not alive",
      peg$c533 = peg$literalExpectation("not alive", false),
      peg$c534 = function(character) { return { type: 'characterAlive', character, withoutWith: 'without' }; },
      peg$c535 = "in",
      peg$c536 = peg$literalExpectation("in", false),
      peg$c537 = "party",
      peg$c538 = peg$literalExpectation("party", false),
      peg$c539 = function(count, character) { return { type: 'characterInParty', character, count }; },
      peg$c540 = function(count, character) { return { type: 'characterInParty', character, count, all: true }; },
      peg$c541 = "females are in the party",
      peg$c542 = peg$literalExpectation("females are in the party", false),
      peg$c543 = function(count) { return { type: 'femalesInParty', count }; },
      peg$c544 = "females",
      peg$c545 = peg$literalExpectation("females", false),
      peg$c546 = "female allies",
      peg$c547 = peg$literalExpectation("female allies", false),
      peg$c548 = "are alive",
      peg$c549 = peg$literalExpectation("are alive", false),
      peg$c550 = function(count) { return { type: 'femalesAlive', count }; },
      peg$c551 = "or more female allies are alive",
      peg$c552 = peg$literalExpectation("or more female allies are alive", false),
      peg$c553 = function(count) { return { type: 'femalesAlive', count, plus: true }; },
      peg$c554 = "there",
      peg$c555 = peg$literalExpectation("there", false),
      peg$c556 = "characters",
      peg$c557 = peg$literalExpectation("characters", false),
      peg$c558 = function(count, realm) { return { type: 'realmCharactersInParty', realm, count }; },
      peg$c559 = "characters are alive",
      peg$c560 = peg$literalExpectation("characters are alive", false),
      peg$c561 = "character is alive",
      peg$c562 = peg$literalExpectation("character is alive", false),
      peg$c563 = "allies are alive",
      peg$c564 = peg$literalExpectation("allies are alive", false),
      peg$c565 = "members are alive",
      peg$c566 = peg$literalExpectation("members are alive", false),
      peg$c567 = function(count, plus, realm) { return { type: 'realmCharactersAlive', realm, count, plus: !!plus }; },
      peg$c568 = "allies have any [Attach ",
      peg$c569 = peg$literalExpectation("allies have any [Attach ", false),
      peg$c570 = "]",
      peg$c571 = peg$literalExpectation("]", false),
      peg$c572 = function(count, plus, element) { return {type: 'attachElementInParty', element, count, plus: !!plus}; },
      peg$c573 = "or more females are in the party",
      peg$c574 = peg$literalExpectation("or more females are in the party", false),
      peg$c575 = "party members",
      peg$c576 = peg$literalExpectation("party members", false),
      peg$c577 = function(count) { return { type: 'charactersAlive', count }; },
      peg$c578 = function(count) { return { type: 'alliesJump', count }; },
      peg$c579 = "timer",
      peg$c580 = peg$literalExpectation("timer", false),
      peg$c581 = "below",
      peg$c582 = peg$literalExpectation("below", false),
      peg$c583 = function(value) { return { type: 'doomTimer', value }; },
      peg$c584 = function(value) { return { type: 'hpBelowPercent', value }; },
      peg$c585 = function(value) { return { type: 'hpAtLeastPercent', value }; },
      peg$c586 = function(value, plus) { return { type: 'soulBreakPoints', value, plus: !!plus }; },
      peg$c587 = "of the target's stats are lowered",
      peg$c588 = peg$literalExpectation("of the target's stats are lowered", false),
      peg$c589 = function(count) { return { type: 'targetStatBreaks', count }; },
      peg$c590 = "if target has",
      peg$c591 = peg$literalExpectation("if target has", false),
      peg$c592 = "of ATK, DEF, MAG, RES or MND reduced",
      peg$c593 = peg$literalExpectation("of ATK, DEF, MAG, RES or MND reduced", false),
      peg$c594 = "ailments",
      peg$c595 = peg$literalExpectation("ailments", false),
      peg$c596 = function(count) { return { type: 'targetStatusAilments', count }; },
      peg$c597 = "if exploiting elemental weakness",
      peg$c598 = peg$literalExpectation("if exploiting elemental weakness", false),
      peg$c599 = function() { return { type: 'vsWeak' }; },
      peg$c600 = "if exploiting",
      peg$c601 = peg$literalExpectation("if exploiting", false),
      peg$c602 = "weakness",
      peg$c603 = peg$literalExpectation("weakness", false),
      peg$c604 = function(element) { return { type: 'vsWeak', element }; },
      peg$c605 = "front",
      peg$c606 = peg$literalExpectation("front", false),
      peg$c607 = "row",
      peg$c608 = peg$literalExpectation("row", false),
      peg$c609 = function() { return { type: 'inFrontRow' }; },
      peg$c610 = "took",
      peg$c611 = peg$literalExpectation("took", false),
      peg$c612 = function(count, skillType) { return { type: 'hitsTaken', count, skillType }; },
      peg$c613 = function(count) { return { type: 'attacksTaken', count }; },
      peg$c614 = "damaging",
      peg$c615 = peg$literalExpectation("damaging", false),
      peg$c616 = "actions",
      peg$c617 = peg$literalExpectation("actions", false),
      peg$c618 = function(count) { return { type: 'damagingActions', count }; },
      peg$c619 = "other",
      peg$c620 = peg$literalExpectation("other", false),
      peg$c621 = "users",
      peg$c622 = peg$literalExpectation("users", false),
      peg$c623 = function(count, school) { return { type: 'otherAbilityUsers', count, school }; },
      peg$c624 = "different",
      peg$c625 = peg$literalExpectation("different", false),
      peg$c626 = function(count, school) { return { type: 'differentAbilityUses', count, school }; },
      peg$c627 = function(count, school) { return { type: 'abilitiesUsedDuringStatus', count, school }; },
      peg$c628 = function(count, school) { return { type: 'abilitiesUsed', count, school }; },
      peg$c629 = function(count, element) { return { type: 'attacksDuringStatus', count, element }; },
      peg$c630 = "damage was dealt during the status",
      peg$c631 = peg$literalExpectation("damage was dealt during the status", false),
      peg$c632 = function(value) {
          lastDamageDuringStatus = util.lastValue(value);
          lastDamageDuringStatusElement = undefined;
          return { type: 'damageDuringStatus', value };
        },
      peg$c633 = "user dealt",
      peg$c634 = peg$literalExpectation("user dealt", false),
      peg$c635 = "damage during the status",
      peg$c636 = peg$literalExpectation("damage during the status", false),
      peg$c637 = "damage with",
      peg$c638 = peg$literalExpectation("damage with", false),
      peg$c639 = "attacks during the status",
      peg$c640 = peg$literalExpectation("attacks during the status", false),
      peg$c641 = function(value, element) {
          lastDamageDuringStatus = util.lastValue(value);
          lastDamageDuringStatusElement = element;
          return { type: 'damageDuringStatus', value, element };
        },
      peg$c642 = "final",
      peg$c643 = peg$literalExpectation("final", false),
      peg$c644 = "threshold",
      peg$c645 = peg$literalExpectation("threshold", false),
      peg$c646 = "was",
      peg$c647 = peg$literalExpectation("was", false),
      peg$c648 = "met",
      peg$c649 = peg$literalExpectation("met", false),
      peg$c650 = function() { return { type: 'damageDuringStatus', value: lastDamageDuringStatus, element: lastDamageDuringStatusElement }; },
      peg$c651 = function(school, count) { return { type: 'abilitiesUsed', count, school }; },
      peg$c652 = "rank",
      peg$c653 = peg$literalExpectation("rank", false),
      peg$c654 = "1/2/3/4/5",
      peg$c655 = peg$literalExpectation("1/2/3/4/5", false),
      peg$c656 = "triggering",
      peg$c657 = peg$literalExpectation("triggering", false),
      peg$c658 = function() { return { type: 'rankBased' }; },
      peg$c659 = function(status) { return { type: 'status', status, who: 'self' }; },
      peg$c660 = function(value, stat) { return { type: 'statThreshold', stat, value }; },
      peg$c661 = "at the beginning of the battle",
      peg$c662 = peg$literalExpectation("at the beginning of the battle", false),
      peg$c663 = function() { return { type: 'battleStart' }; },
      peg$c664 = "hasn't/has",
      peg$c665 = peg$literalExpectation("hasn't/has", false),
      peg$c666 = function() { return 'withoutWith'; },
      peg$c667 = "hasn't",
      peg$c668 = peg$literalExpectation("hasn't", false),
      peg$c669 = function() { return 'without'; },
      peg$c670 = function() { return undefined; },
      peg$c671 = "based on triggering ability's element",
      peg$c672 = peg$literalExpectation("based on triggering ability's element", false),
      peg$c673 = "smart",
      peg$c674 = peg$literalExpectation("smart", true),
      peg$c675 = "ether",
      peg$c676 = peg$literalExpectation("ether", false),
      peg$c677 = function(school, amount) {
          const result = { type: 'smartEther', amount };
          if (school) {
            result.school = school;
          }
          return result;
        },
      peg$c678 = "causes",
      peg$c679 = peg$literalExpectation("causes", true),
      peg$c680 = "doesn't",
      peg$c681 = peg$literalExpectation("doesn't", true),
      peg$c682 = "remove",
      peg$c683 = peg$literalExpectation("remove", false),
      peg$c684 = function() {
          return text().toLowerCase().replace(/\s+/g, ' ');
        },
      peg$c685 = peg$otherExpectation("status effect"),
      peg$c686 = /^[^\]]/,
      peg$c687 = peg$classExpectation(["]"], true, false),
      peg$c688 = function(name) { return name.join(''); },
      peg$c689 = "s)",
      peg$c690 = peg$literalExpectation("s)", false),
      peg$c691 = /^[A-Z]/,
      peg$c692 = peg$classExpectation([["A", "Z"]], false, false),
      peg$c693 = /^[A-Za-z0-9\-]/,
      peg$c694 = peg$classExpectation([["A", "Z"], ["a", "z"], ["0", "9"], "-"], false, false),
      peg$c695 = function(head, tail) { return util.pegList(head, tail, 1, true); },
      peg$c696 = "he",
      peg$c697 = peg$literalExpectation("he", false),
      peg$c698 = "she",
      peg$c699 = peg$literalExpectation("she", false),
      peg$c700 = "they",
      peg$c701 = peg$literalExpectation("they", false),
      peg$c702 = "???",
      peg$c703 = peg$literalExpectation("???", false),
      peg$c704 = function(head, tail) { return { options: util.pegList(head, tail, 3) }; },
      peg$c705 = function(skill) { return skill; },
      peg$c706 = /^[%+]/,
      peg$c707 = peg$classExpectation(["%", "+"], false, false),
      peg$c708 = "...",
      peg$c709 = peg$literalExpectation("...", false),
      peg$c710 = "&",
      peg$c711 = peg$literalExpectation("&", false),
      peg$c712 = " ",
      peg$c713 = peg$literalExpectation(" ", false),
      peg$c714 = "an",
      peg$c715 = peg$literalExpectation("an", false),
      peg$c716 = /^[=*+\-]/,
      peg$c717 = peg$classExpectation(["=", "*", "+", "-"], false, false),
      peg$c718 = /^[%]/,
      peg$c719 = peg$classExpectation(["%"], false, false),
      peg$c720 = /^[+\-]/,
      peg$c721 = peg$classExpectation(["+", "-"], false, false),
      peg$c722 = "Black Magic",
      peg$c723 = peg$literalExpectation("Black Magic", false),
      peg$c724 = "White Magic",
      peg$c725 = peg$literalExpectation("White Magic", false),
      peg$c726 = /^[A-Za-z\-0-9\/]/,
      peg$c727 = peg$classExpectation([["A", "Z"], ["a", "z"], "-", ["0", "9"], "/"], false, false),
      peg$c728 = "Only",
      peg$c729 = peg$literalExpectation("Only", false),
      peg$c730 = function() {
          return text();
        },
      peg$c731 = /^[a-zA-Z\-'\/]/,
      peg$c732 = peg$classExpectation([["a", "z"], ["A", "Z"], "-", "'", "/"], false, false),
      peg$c733 = ":",
      peg$c734 = peg$literalExpectation(":", false),
      peg$c735 = "!",
      peg$c736 = peg$literalExpectation("!", false),
      peg$c737 = function(value, valueIsUncertain, units) {
          const result = { value, units };
          if (valueIsUncertain) {
            result.valueIsUncertain = true;
          }
          return result;
        },
      peg$c738 = "second",
      peg$c739 = peg$literalExpectation("second", false),
      peg$c740 = "turn",
      peg$c741 = peg$literalExpectation("turn", false),
      peg$c742 = function() {
          let result = text();
          if (result === 'sec.') {
            return 'seconds';
          }
          if (!result.endsWith('s')) {
            result += 's';
          }
          return result;
        },
      peg$c743 = peg$otherExpectation("stat"),
      peg$c744 = "MAG",
      peg$c745 = peg$literalExpectation("MAG", false),
      peg$c746 = "RES",
      peg$c747 = peg$literalExpectation("RES", false),
      peg$c748 = "MND",
      peg$c749 = peg$literalExpectation("MND", false),
      peg$c750 = "SPD",
      peg$c751 = peg$literalExpectation("SPD", false),
      peg$c752 = "ACC",
      peg$c753 = peg$literalExpectation("ACC", false),
      peg$c754 = "EVA",
      peg$c755 = peg$literalExpectation("EVA", false),
      peg$c756 = function() {
          return text().toLowerCase();
        },
      peg$c757 = peg$literalExpectation("grants", false),
      peg$c758 = peg$literalExpectation("causes", false),
      peg$c759 = peg$literalExpectation("removes", false),
      peg$c760 = peg$literalExpectation("doesn't", false),
      peg$c761 = peg$literalExpectation("restores", false),
      peg$c762 = "last",
      peg$c763 = peg$literalExpectation("last", false),
      peg$c764 = "ally",
      peg$c765 = peg$literalExpectation("ally", false),
      peg$c766 = "from",
      peg$c767 = peg$literalExpectation("from", false),
      peg$c768 = function(who) { return who; },
      peg$c769 = function() { return 'self'; },
      peg$c770 = "the target",
      peg$c771 = peg$literalExpectation("the target", false),
      peg$c772 = function() { return 'target'; },
      peg$c773 = "all enemies",
      peg$c774 = peg$literalExpectation("all enemies", false),
      peg$c775 = function() { return 'enemies'; },
      peg$c776 = "a single ally",
      peg$c777 = peg$literalExpectation("a single ally", false),
      peg$c778 = function() { return 'ally'; },
      peg$c779 = "all allies",
      peg$c780 = peg$literalExpectation("all allies", false),
      peg$c781 = "in the",
      peg$c782 = peg$literalExpectation("in the", false),
      peg$c783 = "back",
      peg$c784 = peg$literalExpectation("back", false),
      peg$c785 = "character's",
      peg$c786 = peg$literalExpectation("character's", false),
      peg$c787 = function(row) { return row === "character's" ? 'sameRow' : row + 'Row'; },
      peg$c788 = function(row) {
          return row || 'party';
        },
      peg$c789 = "allies in the same row",
      peg$c790 = peg$literalExpectation("allies in the same row", false),
      peg$c791 = function() { return 'sameRow'; },
      peg$c792 = "the lowest HP% ally",
      peg$c793 = peg$literalExpectation("the lowest HP% ally", false),
      peg$c794 = function() { return 'lowestHpAlly'; },
      peg$c795 = "a random ally without status",
      peg$c796 = peg$literalExpectation("a random ally without status", false),
      peg$c797 = function() { return 'allyWithoutStatus'; },
      peg$c798 = "a random ally with negative",
      peg$c799 = peg$literalExpectation("a random ally with negative", false),
      peg$c800 = function() { return 'allyWithNegativeStatus'; },
      peg$c801 = "a random ally with KO",
      peg$c802 = peg$literalExpectation("a random ally with KO", false),
      peg$c803 = function() { return 'allyWithKO'; },
      peg$c804 = function(perUses) { return perUses; },
      peg$c805 = "activation",
      peg$c806 = peg$literalExpectation("activation", false),
      peg$c807 = peg$otherExpectation("skill type"),
      peg$c808 = "PHY",
      peg$c809 = peg$literalExpectation("PHY", false),
      peg$c810 = "WHT",
      peg$c811 = peg$literalExpectation("WHT", false),
      peg$c812 = "BLK",
      peg$c813 = peg$literalExpectation("BLK", false),
      peg$c814 = "BLU",
      peg$c815 = peg$literalExpectation("BLU", false),
      peg$c816 = "SUM",
      peg$c817 = peg$literalExpectation("SUM", false),
      peg$c818 = "NAT",
      peg$c819 = peg$literalExpectation("NAT", false),
      peg$c820 = "NIN",
      peg$c821 = peg$literalExpectation("NIN", false),
      peg$c822 = function() { return "PHY"; },
      peg$c823 = peg$otherExpectation("skill type list"),
      peg$c824 = peg$otherExpectation("element"),
      peg$c825 = "Fire",
      peg$c826 = peg$literalExpectation("Fire", false),
      peg$c827 = "Ice",
      peg$c828 = peg$literalExpectation("Ice", false),
      peg$c829 = "Lightning",
      peg$c830 = peg$literalExpectation("Lightning", false),
      peg$c831 = "Earth",
      peg$c832 = peg$literalExpectation("Earth", false),
      peg$c833 = "Wind",
      peg$c834 = peg$literalExpectation("Wind", false),
      peg$c835 = "Water",
      peg$c836 = peg$literalExpectation("Water", false),
      peg$c837 = "Holy",
      peg$c838 = peg$literalExpectation("Holy", false),
      peg$c839 = "Dark",
      peg$c840 = peg$literalExpectation("Dark", false),
      peg$c841 = "ness",
      peg$c842 = peg$literalExpectation("ness", false),
      peg$c843 = function() { return "Dark"; },
      peg$c844 = "Poison",
      peg$c845 = peg$literalExpectation("Poison", false),
      peg$c846 = "NE",
      peg$c847 = peg$literalExpectation("NE", false),
      peg$c848 = "Non-Elemental",
      peg$c849 = peg$literalExpectation("Non-Elemental", false),
      peg$c850 = function() { return "NE"; },
      peg$c851 = peg$otherExpectation("element list"),
      peg$c852 = peg$otherExpectation("ability school"),
      peg$c853 = "Bard",
      peg$c854 = peg$literalExpectation("Bard", false),
      peg$c855 = "Celerity",
      peg$c856 = peg$literalExpectation("Celerity", false),
      peg$c857 = "Combat",
      peg$c858 = peg$literalExpectation("Combat", false),
      peg$c859 = "Dancer",
      peg$c860 = peg$literalExpectation("Dancer", false),
      peg$c861 = "Darkness",
      peg$c862 = peg$literalExpectation("Darkness", false),
      peg$c863 = "Dragoon",
      peg$c864 = peg$literalExpectation("Dragoon", false),
      peg$c865 = "Heavy",
      peg$c866 = peg$literalExpectation("Heavy", false),
      peg$c867 = "Knight",
      peg$c868 = peg$literalExpectation("Knight", false),
      peg$c869 = "Machinist",
      peg$c870 = peg$literalExpectation("Machinist", false),
      peg$c871 = "Monk",
      peg$c872 = peg$literalExpectation("Monk", false),
      peg$c873 = "Ninja",
      peg$c874 = peg$literalExpectation("Ninja", false),
      peg$c875 = "Samurai",
      peg$c876 = peg$literalExpectation("Samurai", false),
      peg$c877 = "Sharpshooter",
      peg$c878 = peg$literalExpectation("Sharpshooter", false),
      peg$c879 = "Special",
      peg$c880 = peg$literalExpectation("Special", false),
      peg$c881 = "Spellblade",
      peg$c882 = peg$literalExpectation("Spellblade", false),
      peg$c883 = "Summoning",
      peg$c884 = peg$literalExpectation("Summoning", false),
      peg$c885 = "Support",
      peg$c886 = peg$literalExpectation("Support", false),
      peg$c887 = "Thief",
      peg$c888 = peg$literalExpectation("Thief", false),
      peg$c889 = "Witch",
      peg$c890 = peg$literalExpectation("Witch", false),
      peg$c891 = peg$otherExpectation("school list"),
      peg$c892 = "Soul",
      peg$c893 = peg$literalExpectation("Soul", false),
      peg$c894 = "Break",
      peg$c895 = peg$literalExpectation("Break", false),
      peg$c896 = "maximum",
      peg$c897 = peg$literalExpectation("maximum", false),
      peg$c898 = "max",
      peg$c899 = peg$literalExpectation("max", false),
      peg$c900 = function(head, tail) { for (const i of tail) { if (i[1].y !== head.y) { return false; } }; return true; },
      peg$c901 = function(head, tail) {
          const list = util.pegList(head, tail, 1, false);
          return { x: util.scalarify(list.map(i => i.x)), y: list[0].y };
        },
      peg$c902 = function(head, tail, y) {
          return { x: util.pegList(head, tail, 1, true), y };
        },
      peg$c903 = function(x, y) { return { x, y }; },
      peg$c904 = peg$otherExpectation("realm"),
      peg$c905 = "Core/Beyond",
      peg$c906 = peg$literalExpectation("Core/Beyond", false),
      peg$c907 = "Beyond",
      peg$c908 = peg$literalExpectation("Beyond", false),
      peg$c909 = "Core",
      peg$c910 = peg$literalExpectation("Core", false),
      peg$c911 = "IX",
      peg$c912 = peg$literalExpectation("IX", false),
      peg$c913 = "IV",
      peg$c914 = peg$literalExpectation("IV", false),
      peg$c915 = "FFT",
      peg$c916 = peg$literalExpectation("FFT", false),
      peg$c917 = "III",
      peg$c918 = peg$literalExpectation("III", false),
      peg$c919 = "II",
      peg$c920 = peg$literalExpectation("II", false),
      peg$c921 = "I",
      peg$c922 = peg$literalExpectation("I", false),
      peg$c923 = "VIII",
      peg$c924 = peg$literalExpectation("VIII", false),
      peg$c925 = "VII",
      peg$c926 = peg$literalExpectation("VII", false),
      peg$c927 = "VI",
      peg$c928 = peg$literalExpectation("VI", false),
      peg$c929 = "V",
      peg$c930 = peg$literalExpectation("V", false),
      peg$c931 = "XV",
      peg$c932 = peg$literalExpectation("XV", false),
      peg$c933 = "XIV",
      peg$c934 = peg$literalExpectation("XIV", false),
      peg$c935 = "XIII",
      peg$c936 = peg$literalExpectation("XIII", false),
      peg$c937 = "XII",
      peg$c938 = peg$literalExpectation("XII", false),
      peg$c939 = "XI",
      peg$c940 = peg$literalExpectation("XI", false),
      peg$c941 = "X",
      peg$c942 = peg$literalExpectation("X", false),
      peg$c943 = "KH",
      peg$c944 = peg$literalExpectation("KH", false),
      peg$c945 = "Type-0",
      peg$c946 = peg$literalExpectation("Type-0", false),
      peg$c947 = "FF10",
      peg$c948 = peg$literalExpectation("FF10", false),
      peg$c949 = function() { return 'X'; },
      peg$c950 = "FF11",
      peg$c951 = peg$literalExpectation("FF11", false),
      peg$c952 = function() { return 'XI'; },
      peg$c953 = "FF12",
      peg$c954 = peg$literalExpectation("FF12", false),
      peg$c955 = function() { return 'XII'; },
      peg$c956 = "FF13",
      peg$c957 = peg$literalExpectation("FF13", false),
      peg$c958 = function() { return 'XIII'; },
      peg$c959 = "FF14",
      peg$c960 = peg$literalExpectation("FF14", false),
      peg$c961 = function() { return 'XIV'; },
      peg$c962 = "FF15",
      peg$c963 = peg$literalExpectation("FF15", false),
      peg$c964 = function() { return 'XV'; },
      peg$c965 = "FF1",
      peg$c966 = peg$literalExpectation("FF1", false),
      peg$c967 = function() { return 'I'; },
      peg$c968 = "FF2",
      peg$c969 = peg$literalExpectation("FF2", false),
      peg$c970 = function() { return 'II'; },
      peg$c971 = "FF3",
      peg$c972 = peg$literalExpectation("FF3", false),
      peg$c973 = function() { return 'III'; },
      peg$c974 = "FF4",
      peg$c975 = peg$literalExpectation("FF4", false),
      peg$c976 = function() { return 'IV'; },
      peg$c977 = "FF5",
      peg$c978 = peg$literalExpectation("FF5", false),
      peg$c979 = function() { return 'V'; },
      peg$c980 = "FF6",
      peg$c981 = peg$literalExpectation("FF6", false),
      peg$c982 = function() { return 'VI'; },
      peg$c983 = "FF7",
      peg$c984 = peg$literalExpectation("FF7", false),
      peg$c985 = function() { return 'VII'; },
      peg$c986 = "FF8",
      peg$c987 = peg$literalExpectation("FF8", false),
      peg$c988 = function() { return 'VIII'; },
      peg$c989 = "FF9",
      peg$c990 = peg$literalExpectation("FF9", false),
      peg$c991 = function() { return 'IX'; },
      peg$c992 = "FF IX",
      peg$c993 = peg$literalExpectation("FF IX", false),
      peg$c994 = function() { return "IX"; },
      peg$c995 = "FF IV",
      peg$c996 = peg$literalExpectation("FF IV", false),
      peg$c997 = function() { return "IV"; },
      peg$c998 = "FF FFT",
      peg$c999 = peg$literalExpectation("FF FFT", false),
      peg$c1000 = function() { return "FFT"; },
      peg$c1001 = "FF III",
      peg$c1002 = peg$literalExpectation("FF III", false),
      peg$c1003 = function() { return "III"; },
      peg$c1004 = "FF II",
      peg$c1005 = peg$literalExpectation("FF II", false),
      peg$c1006 = function() { return "II"; },
      peg$c1007 = "FF I",
      peg$c1008 = peg$literalExpectation("FF I", false),
      peg$c1009 = function() { return "I"; },
      peg$c1010 = "FF VIII",
      peg$c1011 = peg$literalExpectation("FF VIII", false),
      peg$c1012 = function() { return "VIII"; },
      peg$c1013 = "FF VII",
      peg$c1014 = peg$literalExpectation("FF VII", false),
      peg$c1015 = function() { return "VII"; },
      peg$c1016 = "FF VI",
      peg$c1017 = peg$literalExpectation("FF VI", false),
      peg$c1018 = function() { return "VI"; },
      peg$c1019 = "FF V",
      peg$c1020 = peg$literalExpectation("FF V", false),
      peg$c1021 = function() { return "V"; },
      peg$c1022 = "FF XV",
      peg$c1023 = peg$literalExpectation("FF XV", false),
      peg$c1024 = function() { return "XV"; },
      peg$c1025 = "FF XIV",
      peg$c1026 = peg$literalExpectation("FF XIV", false),
      peg$c1027 = function() { return "XIV"; },
      peg$c1028 = "FF XIII",
      peg$c1029 = peg$literalExpectation("FF XIII", false),
      peg$c1030 = function() { return "XIII"; },
      peg$c1031 = "FF XII",
      peg$c1032 = peg$literalExpectation("FF XII", false),
      peg$c1033 = function() { return "XII"; },
      peg$c1034 = "FF XI",
      peg$c1035 = peg$literalExpectation("FF XI", false),
      peg$c1036 = function() { return "XI"; },
      peg$c1037 = "FF X",
      peg$c1038 = peg$literalExpectation("FF X", false),
      peg$c1039 = function() { return "X"; },
      peg$c1040 = "Tactics",
      peg$c1041 = peg$literalExpectation("Tactics", false),
      peg$c1042 = "9999",
      peg$c1043 = peg$literalExpectation("9999", false),
      peg$c1044 = /^[1-9]/,
      peg$c1045 = peg$classExpectation([["1", "9"]], false, false),
      peg$c1046 = function() { return parseInt(text()); },
      peg$c1047 = ", and",
      peg$c1048 = peg$literalExpectation(", and", false),
      peg$c1049 = function() { return 'and'; },
      peg$c1050 = function() { return 'or'; },
      peg$c1051 = function() { return ','; },
      peg$c1052 = function() { return '/'; },
      peg$c1053 = peg$otherExpectation("numeric text"),
      peg$c1054 = /^[a-zA-Z\-]/,
      peg$c1055 = peg$classExpectation([["a", "z"], ["A", "Z"], "-"], false, false),
      peg$c1056 = function(numberString) { parsedNumberResult = util.parseNumberString(numberString.join('')); return parsedNumberResult != null; },
      peg$c1057 = function(numberString) { return parsedNumberResult; },
      peg$c1058 = peg$otherExpectation("decimal number"),
      peg$c1059 = /^[0-9.]/,
      peg$c1060 = peg$classExpectation([["0", "9"], "."], false, false),
      peg$c1061 = function() { return parseFloat(text()) },
      peg$c1062 = peg$otherExpectation("integer"),
      peg$c1063 = /^[0-9]/,
      peg$c1064 = peg$classExpectation([["0", "9"]], false, false),
      peg$c1065 = function() { return parseInt(text(), 10); },
      peg$c1066 = peg$otherExpectation("percentage"),
      peg$c1067 = peg$otherExpectation("signed integer"),
      peg$c1068 = function(sign, value) { return parseInt(sign + value.join(''), 10); },
      peg$c1069 = peg$otherExpectation("integer (optionally negative)"),
      peg$c1070 = function(sign, value) { return parseInt(text(), 10); },
      peg$c1071 = peg$otherExpectation("slash-separated decimal numbers"),
      peg$c1072 = function(head, tail) { return util.pegSlashList(head, tail); },
      peg$c1073 = peg$otherExpectation("slash-separated integers"),
      peg$c1074 = peg$otherExpectation("slash-separated integers or range"),
      peg$c1075 = function(from, to) { return { from, to }; },
      peg$c1076 = peg$otherExpectation("slash-separated percent integers"),
      peg$c1077 = function(list) { return list; },
      peg$c1078 = peg$otherExpectation("slash-separated integer ranges"),
      peg$c1079 = function(from, to) { return from; },
      peg$c1080 = peg$otherExpectation("slash-separated signed integers"),
      peg$c1081 = function(sign, values) {
          const applySign = (i) => sign === '-' ? -i : i;
          if (Array.isArray(values)) {
            return values.map(applySign);
          } else {
            return applySign(values);
          }
        },
      peg$c1082 = peg$otherExpectation("slash-separated integers (optionally negative)"),
      peg$c1083 = peg$otherExpectation("integers separated with commas and 'and'"),
      peg$c1084 = "once",
      peg$c1085 = peg$literalExpectation("once", false),
      peg$c1086 = function() { return 1; },
      peg$c1087 = "twice",
      peg$c1088 = peg$literalExpectation("twice", false),
      peg$c1089 = function() { return 2; },
      peg$c1090 = function(count) { return count; },
      peg$c1091 = "third",
      peg$c1092 = peg$literalExpectation("third", false),
      peg$c1093 = function() { return 3; },
      peg$c1094 = "fourth",
      peg$c1095 = peg$literalExpectation("fourth", false),
      peg$c1096 = function() { return 4; },
      peg$c1097 = "fifth",
      peg$c1098 = peg$literalExpectation("fifth", false),
      peg$c1099 = function() { return 5; },
      peg$c1100 = /^[A-Za-z]/,
      peg$c1101 = peg$classExpectation([["A", "Z"], ["a", "z"]], false, false),
      peg$c1102 = peg$otherExpectation("whitespace"),
      peg$c1103 = /^[ \t\n\r]/,
      peg$c1104 = peg$classExpectation([" ", "\t", "\n", "\r"], false, false),

      peg$currPos          = 0,
      peg$savedPos         = 0,
      peg$posDetailsCache  = [{ line: 1, column: 1 }],
      peg$maxFailPos       = 0,
      peg$maxFailExpected  = [],
      peg$silentFails      = 0,

      peg$resultsCache = {},

      peg$result;

  if ("startRule" in options) {
    if (!(options.startRule in peg$startRuleFunctions)) {
      throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
    }

    peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
  }

  function text() {
    return input.substring(peg$savedPos, peg$currPos);
  }

  function location() {
    return peg$computeLocation(peg$savedPos, peg$currPos);
  }

  function expected(description, location) {
    location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

    throw peg$buildStructuredError(
      [peg$otherExpectation(description)],
      input.substring(peg$savedPos, peg$currPos),
      location
    );
  }

  function error(message, location) {
    location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

    throw peg$buildSimpleError(message, location);
  }

  function peg$literalExpectation(text, ignoreCase) {
    return { type: "literal", text: text, ignoreCase: ignoreCase };
  }

  function peg$classExpectation(parts, inverted, ignoreCase) {
    return { type: "class", parts: parts, inverted: inverted, ignoreCase: ignoreCase };
  }

  function peg$anyExpectation() {
    return { type: "any" };
  }

  function peg$endExpectation() {
    return { type: "end" };
  }

  function peg$otherExpectation(description) {
    return { type: "other", description: description };
  }

  function peg$computePosDetails(pos) {
    var details = peg$posDetailsCache[pos], p;

    if (details) {
      return details;
    } else {
      p = pos - 1;
      while (!peg$posDetailsCache[p]) {
        p--;
      }

      details = peg$posDetailsCache[p];
      details = {
        line:   details.line,
        column: details.column
      };

      while (p < pos) {
        if (input.charCodeAt(p) === 10) {
          details.line++;
          details.column = 1;
        } else {
          details.column++;
        }

        p++;
      }

      peg$posDetailsCache[pos] = details;
      return details;
    }
  }

  function peg$computeLocation(startPos, endPos) {
    var startPosDetails = peg$computePosDetails(startPos),
        endPosDetails   = peg$computePosDetails(endPos);

    return {
      start: {
        offset: startPos,
        line:   startPosDetails.line,
        column: startPosDetails.column
      },
      end: {
        offset: endPos,
        line:   endPosDetails.line,
        column: endPosDetails.column
      }
    };
  }

  function peg$fail(expected) {
    if (peg$currPos < peg$maxFailPos) { return; }

    if (peg$currPos > peg$maxFailPos) {
      peg$maxFailPos = peg$currPos;
      peg$maxFailExpected = [];
    }

    peg$maxFailExpected.push(expected);
  }

  function peg$buildSimpleError(message, location) {
    return new peg$SyntaxError(message, null, null, location);
  }

  function peg$buildStructuredError(expected, found, location) {
    return new peg$SyntaxError(
      peg$SyntaxError.buildMessage(expected, found),
      expected,
      found,
      location
    );
  }

  function peg$parseSkillEffect() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 140 + 0,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseEffectClause();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 44) {
        s4 = peg$c0;
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1); }
      }
      if (s4 === peg$FAILED) {
        s4 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 46) {
          s5 = peg$c2;
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c3); }
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          if (s6 !== peg$FAILED) {
            if (input.substr(peg$currPos, 4) === peg$c4) {
              s7 = peg$c4;
              peg$currPos += 4;
            } else {
              s7 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c5); }
            }
            if (s7 === peg$FAILED) {
              if (input.substr(peg$currPos, 10) === peg$c6) {
                s7 = peg$c6;
                peg$currPos += 10;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c7); }
              }
            }
            if (s7 !== peg$FAILED) {
              s5 = [s5, s6, s7];
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        if (s4 === peg$FAILED) {
          if (input.substr(peg$currPos, 3) === peg$c8) {
            s4 = peg$c8;
            peg$currPos += 3;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c9); }
          }
        }
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parse_();
        if (s5 !== peg$FAILED) {
          s6 = peg$parseEffectClause();
          if (s6 !== peg$FAILED) {
            peg$savedPos = s3;
            s4 = peg$c10(s1, s4, s6);
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 44) {
          s4 = peg$c0;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1); }
        }
        if (s4 === peg$FAILED) {
          s4 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 46) {
            s5 = peg$c2;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c3); }
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            if (s6 !== peg$FAILED) {
              if (input.substr(peg$currPos, 4) === peg$c4) {
                s7 = peg$c4;
                peg$currPos += 4;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c5); }
              }
              if (s7 === peg$FAILED) {
                if (input.substr(peg$currPos, 10) === peg$c6) {
                  s7 = peg$c6;
                  peg$currPos += 10;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c7); }
                }
              }
              if (s7 !== peg$FAILED) {
                s5 = [s5, s6, s7];
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 === peg$FAILED) {
            if (input.substr(peg$currPos, 3) === peg$c8) {
              s4 = peg$c8;
              peg$currPos += 3;
            } else {
              s4 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c9); }
            }
          }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parse_();
          if (s5 !== peg$FAILED) {
            s6 = peg$parseEffectClause();
            if (s6 !== peg$FAILED) {
              peg$savedPos = s3;
              s4 = peg$c10(s1, s4, s6);
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c11(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$c12;
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c13();
      }
      s0 = s1;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseEffectClause() {
    var s0;

    var key    = peg$currPos * 140 + 1,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$parseFixedAttack();
    if (s0 === peg$FAILED) {
      s0 = peg$parseAttack();
      if (s0 === peg$FAILED) {
        s0 = peg$parseRandomFixedAttack();
        if (s0 === peg$FAILED) {
          s0 = peg$parseDrainHp();
          if (s0 === peg$FAILED) {
            s0 = peg$parseRecoilHp();
            if (s0 === peg$FAILED) {
              s0 = peg$parseRecoilSetHp();
              if (s0 === peg$FAILED) {
                s0 = peg$parseFixedRecoilHp();
                if (s0 === peg$FAILED) {
                  s0 = peg$parseHpAttack();
                  if (s0 === peg$FAILED) {
                    s0 = peg$parseGravityAttack();
                    if (s0 === peg$FAILED) {
                      s0 = peg$parseRevive();
                      if (s0 === peg$FAILED) {
                        s0 = peg$parseHeal();
                        if (s0 === peg$FAILED) {
                          s0 = peg$parseHealPercent();
                          if (s0 === peg$FAILED) {
                            s0 = peg$parseDamagesUndead();
                            if (s0 === peg$FAILED) {
                              s0 = peg$parseDispelOrEsuna();
                              if (s0 === peg$FAILED) {
                                s0 = peg$parseRandomEther();
                                if (s0 === peg$FAILED) {
                                  s0 = peg$parseSmartEther();
                                  if (s0 === peg$FAILED) {
                                    s0 = peg$parseRandomCastAbility();
                                    if (s0 === peg$FAILED) {
                                      s0 = peg$parseRandomCastOther();
                                      if (s0 === peg$FAILED) {
                                        s0 = peg$parseChain();
                                        if (s0 === peg$FAILED) {
                                          s0 = peg$parseMimic();
                                          if (s0 === peg$FAILED) {
                                            s0 = peg$parseSummon();
                                            if (s0 === peg$FAILED) {
                                              s0 = peg$parseStatusEffect();
                                              if (s0 === peg$FAILED) {
                                                s0 = peg$parseImplicitStatusEffect();
                                                if (s0 === peg$FAILED) {
                                                  s0 = peg$parseSetStatusLevel();
                                                  if (s0 === peg$FAILED) {
                                                    s0 = peg$parseRandomStatusEffect();
                                                    if (s0 === peg$FAILED) {
                                                      s0 = peg$parseEntrust();
                                                      if (s0 === peg$FAILED) {
                                                        s0 = peg$parseGainSBOnSuccess();
                                                        if (s0 === peg$FAILED) {
                                                          s0 = peg$parseGainSB();
                                                          if (s0 === peg$FAILED) {
                                                            s0 = peg$parseResetIfKO();
                                                            if (s0 === peg$FAILED) {
                                                              s0 = peg$parseResistViaKO();
                                                              if (s0 === peg$FAILED) {
                                                                s0 = peg$parseReset();
                                                                if (s0 === peg$FAILED) {
                                                                  s0 = peg$parseCastTime();
                                                                  if (s0 === peg$FAILED) {
                                                                    s0 = peg$parseCastTimePerUse();
                                                                    if (s0 === peg$FAILED) {
                                                                      s0 = peg$parseStandaloneAttackExtra();
                                                                      if (s0 === peg$FAILED) {
                                                                        s0 = peg$parseRandomSkillEffect();
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAttack() {
    var s0, s1, s2;

    var key    = peg$currPos * 140 + 2,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseSimpleAttack();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseAttackExtras();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c14(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSimpleAttack() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17, s18, s19, s20, s21;

    var key    = peg$currPos * 140 + 3,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseNumAttacks();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseAttackType();
        if (s3 !== peg$FAILED) {
          s4 = peg$parseAttackModifiers();
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 6) === peg$c15) {
                s6 = peg$c15;
                peg$currPos += 6;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c16); }
              }
              if (s6 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 115) {
                  s7 = peg$c17;
                  peg$currPos++;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c18); }
                }
                if (s7 === peg$FAILED) {
                  s7 = null;
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    s9 = peg$currPos;
                    if (input.charCodeAt(peg$currPos) === 40) {
                      s10 = peg$c19;
                      peg$currPos++;
                    } else {
                      s10 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c20); }
                    }
                    if (s10 !== peg$FAILED) {
                      s11 = peg$parseAttackMultiplierGroup();
                      if (s11 !== peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 41) {
                          s12 = peg$c21;
                          peg$currPos++;
                        } else {
                          s12 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c22); }
                        }
                        if (s12 !== peg$FAILED) {
                          peg$savedPos = s9;
                          s10 = peg$c23(s1, s3, s4, s11);
                          s9 = s10;
                        } else {
                          peg$currPos = s9;
                          s9 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s9;
                        s9 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s9;
                      s9 = peg$FAILED;
                    }
                    if (s9 === peg$FAILED) {
                      s9 = null;
                    }
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parse_();
                      if (s10 !== peg$FAILED) {
                        s11 = peg$currPos;
                        if (input.substr(peg$currPos, 15) === peg$c24) {
                          s12 = peg$c24;
                          peg$currPos += 15;
                        } else {
                          s12 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c25); }
                        }
                        if (s12 !== peg$FAILED) {
                          s13 = peg$parse_();
                          if (s13 !== peg$FAILED) {
                            s14 = peg$parseInteger();
                            if (s14 !== peg$FAILED) {
                              if (input.substr(peg$currPos, 17) === peg$c26) {
                                s15 = peg$c26;
                                peg$currPos += 17;
                              } else {
                                s15 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c27); }
                              }
                              if (s15 !== peg$FAILED) {
                                peg$savedPos = s11;
                                s12 = peg$c28(s1, s3, s4, s9, s14);
                                s11 = s12;
                              } else {
                                peg$currPos = s11;
                                s11 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s11;
                              s11 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s11;
                            s11 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s11;
                          s11 = peg$FAILED;
                        }
                        if (s11 === peg$FAILED) {
                          s11 = null;
                        }
                        if (s11 !== peg$FAILED) {
                          s12 = peg$parse_();
                          if (s12 !== peg$FAILED) {
                            s13 = peg$currPos;
                            if (input.charCodeAt(peg$currPos) === 44) {
                              s14 = peg$c0;
                              peg$currPos++;
                            } else {
                              s14 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c1); }
                            }
                            if (s14 === peg$FAILED) {
                              s14 = null;
                            }
                            if (s14 !== peg$FAILED) {
                              s15 = peg$parse_();
                              if (s15 !== peg$FAILED) {
                                s16 = peg$parseScalingOverstrike();
                                if (s16 !== peg$FAILED) {
                                  peg$savedPos = s13;
                                  s14 = peg$c29(s1, s3, s4, s9, s11, s16);
                                  s13 = s14;
                                } else {
                                  peg$currPos = s13;
                                  s13 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s13;
                                s13 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s13;
                              s13 = peg$FAILED;
                            }
                            if (s13 === peg$FAILED) {
                              s13 = null;
                            }
                            if (s13 !== peg$FAILED) {
                              s14 = peg$parse_();
                              if (s14 !== peg$FAILED) {
                                s15 = peg$currPos;
                                if (input.charCodeAt(peg$currPos) === 44) {
                                  s16 = peg$c0;
                                  peg$currPos++;
                                } else {
                                  s16 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c1); }
                                }
                                if (s16 === peg$FAILED) {
                                  s16 = null;
                                }
                                if (s16 !== peg$FAILED) {
                                  s17 = peg$parse_();
                                  if (s17 !== peg$FAILED) {
                                    if (input.substr(peg$currPos, 9) === peg$c30) {
                                      s18 = peg$c30;
                                      peg$currPos += 9;
                                    } else {
                                      s18 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c31); }
                                    }
                                    if (s18 !== peg$FAILED) {
                                      s19 = peg$parse_();
                                      if (s19 !== peg$FAILED) {
                                        s20 = peg$parseInteger();
                                        if (s20 !== peg$FAILED) {
                                          peg$savedPos = s15;
                                          s16 = peg$c32(s1, s3, s4, s9, s11, s13, s20);
                                          s15 = s16;
                                        } else {
                                          peg$currPos = s15;
                                          s15 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s15;
                                        s15 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s15;
                                      s15 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s15;
                                    s15 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s15;
                                  s15 = peg$FAILED;
                                }
                                if (s15 === peg$FAILED) {
                                  s15 = null;
                                }
                                if (s15 !== peg$FAILED) {
                                  s16 = peg$parse_();
                                  if (s16 !== peg$FAILED) {
                                    s17 = peg$currPos;
                                    s18 = peg$parse_();
                                    if (s18 !== peg$FAILED) {
                                      if (input.substr(peg$currPos, 16) === peg$c33) {
                                        s19 = peg$c33;
                                        peg$currPos += 16;
                                      } else {
                                        s19 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c34); }
                                      }
                                      if (s19 !== peg$FAILED) {
                                        s18 = [s18, s19];
                                        s17 = s18;
                                      } else {
                                        peg$currPos = s17;
                                        s17 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s17;
                                      s17 = peg$FAILED;
                                    }
                                    if (s17 === peg$FAILED) {
                                      s17 = null;
                                    }
                                    if (s17 !== peg$FAILED) {
                                      s18 = peg$parse_();
                                      if (s18 !== peg$FAILED) {
                                        s19 = peg$currPos;
                                        s20 = peg$parse_();
                                        if (s20 !== peg$FAILED) {
                                          if (input.substr(peg$currPos, 16) === peg$c35) {
                                            s21 = peg$c35;
                                            peg$currPos += 16;
                                          } else {
                                            s21 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c36); }
                                          }
                                          if (s21 !== peg$FAILED) {
                                            s20 = [s20, s21];
                                            s19 = s20;
                                          } else {
                                            peg$currPos = s19;
                                            s19 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s19;
                                          s19 = peg$FAILED;
                                        }
                                        if (s19 === peg$FAILED) {
                                          s19 = null;
                                        }
                                        if (s19 !== peg$FAILED) {
                                          peg$savedPos = s0;
                                          s1 = peg$c37(s1, s3, s4, s9, s11, s13, s15, s17, s19);
                                          s0 = s1;
                                        } else {
                                          peg$currPos = s0;
                                          s0 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseFixedAttack() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17;

    var key    = peg$currPos * 140 + 4,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseNumAttacks();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseAttackType();
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 6) === peg$c15) {
              s5 = peg$c15;
              peg$currPos += 6;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c16); }
            }
            if (s5 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 115) {
                s6 = peg$c17;
                peg$currPos++;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c18); }
              }
              if (s6 === peg$FAILED) {
                s6 = null;
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 4) === peg$c38) {
                    s8 = peg$c38;
                    peg$currPos += 4;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c39); }
                  }
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parse_();
                    if (s9 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 4) === peg$c40) {
                        s10 = peg$c40;
                        peg$currPos += 4;
                      } else {
                        s10 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c41); }
                      }
                      if (s10 !== peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 115) {
                          s11 = peg$c17;
                          peg$currPos++;
                        } else {
                          s11 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c18); }
                        }
                        if (s11 === peg$FAILED) {
                          s11 = null;
                        }
                        if (s11 !== peg$FAILED) {
                          s12 = peg$parse_();
                          if (s12 !== peg$FAILED) {
                            s13 = peg$parseInteger();
                            if (s13 !== peg$FAILED) {
                              s14 = peg$parse_();
                              if (s14 !== peg$FAILED) {
                                if (input.substr(peg$currPos, 6) === peg$c42) {
                                  s15 = peg$c42;
                                  peg$currPos += 6;
                                } else {
                                  s15 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c43); }
                                }
                                if (s15 !== peg$FAILED) {
                                  s16 = peg$parse_();
                                  if (s16 !== peg$FAILED) {
                                    if (input.substr(peg$currPos, 4) === peg$c44) {
                                      s17 = peg$c44;
                                      peg$currPos += 4;
                                    } else {
                                      s17 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c45); }
                                    }
                                    if (s17 === peg$FAILED) {
                                      s17 = null;
                                    }
                                    if (s17 !== peg$FAILED) {
                                      peg$savedPos = s0;
                                      s1 = peg$c46(s1, s3, s13);
                                      s0 = s1;
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseRandomFixedAttack() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    var key    = peg$currPos * 140 + 5,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8).toLowerCase() === peg$c47) {
      s1 = input.substr(peg$currPos, 8);
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c48); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 5) === peg$c49) {
          s3 = peg$c49;
          peg$currPos += 5;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c50); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseInteger();
            if (s5 !== peg$FAILED) {
              s6 = [];
              s7 = peg$currPos;
              s8 = peg$parseOrList();
              if (s8 !== peg$FAILED) {
                s9 = peg$parseInteger();
                if (s9 !== peg$FAILED) {
                  s8 = [s8, s9];
                  s7 = s8;
                } else {
                  peg$currPos = s7;
                  s7 = peg$FAILED;
                }
              } else {
                peg$currPos = s7;
                s7 = peg$FAILED;
              }
              while (s7 !== peg$FAILED) {
                s6.push(s7);
                s7 = peg$currPos;
                s8 = peg$parseOrList();
                if (s8 !== peg$FAILED) {
                  s9 = peg$parseInteger();
                  if (s9 !== peg$FAILED) {
                    s8 = [s8, s9];
                    s7 = s8;
                  } else {
                    peg$currPos = s7;
                    s7 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s7;
                  s7 = peg$FAILED;
                }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 6) === peg$c42) {
                    s8 = peg$c42;
                    peg$currPos += 6;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c43); }
                  }
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c51(s5, s6);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseNumAttacks() {
    var s0, s1;

    var key    = peg$currPos * 140 + 6,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$parseNumberString();
    if (s0 === peg$FAILED) {
      s0 = peg$parseIntegerSlashList();
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseRandomNumAttacks();
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c52(s1);
        }
        s0 = s1;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseRandomNumAttacks() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10;

    var key    = peg$currPos * 140 + 7,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8).toLowerCase() === peg$c47) {
      s1 = input.substr(peg$currPos, 8);
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c48); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 5) === peg$c49) {
          s3 = peg$c49;
          peg$currPos += 5;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c50); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseRandomOneAttack();
            if (s5 !== peg$FAILED) {
              s6 = [];
              s7 = peg$currPos;
              s8 = peg$parseOrList();
              if (s8 !== peg$FAILED) {
                s9 = peg$parseRandomOneAttack();
                if (s9 !== peg$FAILED) {
                  s8 = [s8, s9];
                  s7 = s8;
                } else {
                  peg$currPos = s7;
                  s7 = peg$FAILED;
                }
              } else {
                peg$currPos = s7;
                s7 = peg$FAILED;
              }
              while (s7 !== peg$FAILED) {
                s6.push(s7);
                s7 = peg$currPos;
                s8 = peg$parseOrList();
                if (s8 !== peg$FAILED) {
                  s9 = peg$parseRandomOneAttack();
                  if (s9 !== peg$FAILED) {
                    s8 = [s8, s9];
                    s7 = s8;
                  } else {
                    peg$currPos = s7;
                    s7 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s7;
                  s7 = peg$FAILED;
                }
              }
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c53(s5, s6);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 8).toLowerCase() === peg$c47) {
        s1 = input.substr(peg$currPos, 8);
        peg$currPos += 8;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c48); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        if (s2 !== peg$FAILED) {
          if (input.substr(peg$currPos, 5) === peg$c49) {
            s3 = peg$c49;
            peg$currPos += 5;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c50); }
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            if (s4 !== peg$FAILED) {
              s5 = peg$parseIntegerSlashList();
              if (s5 !== peg$FAILED) {
                s6 = peg$parse_();
                if (s6 !== peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 40) {
                    s7 = peg$c19;
                    peg$currPos++;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c20); }
                  }
                  if (s7 !== peg$FAILED) {
                    s8 = peg$parsePercentSlashList();
                    if (s8 !== peg$FAILED) {
                      if (input.charCodeAt(peg$currPos) === 41) {
                        s9 = peg$c21;
                        peg$currPos++;
                      } else {
                        s9 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c22); }
                      }
                      if (s9 !== peg$FAILED) {
                        peg$savedPos = peg$currPos;
                        s10 = peg$c54(s5, s8);
                        if (s10) {
                          s10 = void 0;
                        } else {
                          s10 = peg$FAILED;
                        }
                        if (s10 !== peg$FAILED) {
                          peg$savedPos = s0;
                          s1 = peg$c55(s5, s8);
                          s0 = s1;
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseRandomOneAttack() {
    var s0, s1, s2, s3, s4, s5, s6;

    var key    = peg$currPos * 140 + 8,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseNumberString();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 40) {
          s4 = peg$c19;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c20); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseInteger();
          if (s5 !== peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c56) {
              s6 = peg$c56;
              peg$currPos += 2;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c57); }
            }
            if (s6 !== peg$FAILED) {
              peg$savedPos = s3;
              s4 = peg$c58(s1, s5);
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c59(s1, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$parseNumberString();
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAttackMultiplierGroup() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10;

    var key    = peg$currPos * 140 + 9,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$currPos;
    if (input.substr(peg$currPos, 8) === peg$c47) {
      s2 = peg$c47;
      peg$currPos += 8;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c60); }
    }
    if (s2 !== peg$FAILED) {
      s3 = peg$parse_();
      if (s3 !== peg$FAILED) {
        s2 = [s2, s3];
        s1 = s2;
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      s1 = null;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseDecimalNumberSlashList();
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        s4 = peg$parse_();
        if (s4 !== peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c61) {
            s5 = peg$c61;
            peg$currPos += 2;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c62); }
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            if (s6 !== peg$FAILED) {
              s7 = peg$parseDecimalNumberSlashList();
              if (s7 !== peg$FAILED) {
                peg$savedPos = s3;
                s4 = peg$c63(s1, s2, s7);
                s3 = s4;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 126) {
            s5 = peg$c64;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c65); }
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parseDecimalNumber();
            if (s6 !== peg$FAILED) {
              peg$savedPos = s4;
              s5 = peg$c66(s1, s2, s3, s6);
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 === peg$FAILED) {
            s4 = null;
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 4) === peg$c44) {
                s6 = peg$c44;
                peg$currPos += 4;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c45); }
              }
              if (s6 === peg$FAILED) {
                s6 = null;
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseMultiplierScaleType();
                  if (s8 === peg$FAILED) {
                    s8 = null;
                  }
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parse_();
                    if (s9 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 8) === peg$c67) {
                        s10 = peg$c67;
                        peg$currPos += 8;
                      } else {
                        s10 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c68); }
                      }
                      if (s10 === peg$FAILED) {
                        if (input.substr(peg$currPos, 7) === peg$c69) {
                          s10 = peg$c69;
                          peg$currPos += 7;
                        } else {
                          s10 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c70); }
                        }
                      }
                      if (s10 === peg$FAILED) {
                        s10 = null;
                      }
                      if (s10 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c71(s1, s2, s3, s4, s8, s10);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAttackType() {
    var s0;

    var key    = peg$currPos * 140 + 10,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    if (input.substr(peg$currPos, 5) === peg$c72) {
      s0 = peg$c72;
      peg$currPos += 5;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c73); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 6) === peg$c74) {
        s0 = peg$c74;
        peg$currPos += 6;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c75); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 6) === peg$c76) {
          s0 = peg$c76;
          peg$currPos += 6;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c77); }
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAttackModifiers() {
    var s0, s1, s2, s3, s4;

    var key    = peg$currPos * 140 + 11,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$currPos;
    s3 = peg$parse_();
    if (s3 !== peg$FAILED) {
      if (input.substr(peg$currPos, 6) === peg$c78) {
        s4 = peg$c78;
        peg$currPos += 6;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c79); }
      }
      if (s4 === peg$FAILED) {
        if (input.substr(peg$currPos, 5) === peg$c80) {
          s4 = peg$c80;
          peg$currPos += 5;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c81); }
        }
        if (s4 === peg$FAILED) {
          if (input.substr(peg$currPos, 6) === peg$c82) {
            s4 = peg$c82;
            peg$currPos += 6;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c83); }
          }
          if (s4 === peg$FAILED) {
            if (input.substr(peg$currPos, 4) === peg$c84) {
              s4 = peg$c84;
              peg$currPos += 4;
            } else {
              s4 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c85); }
            }
          }
        }
      }
      if (s4 !== peg$FAILED) {
        peg$savedPos = s2;
        s3 = peg$c86(s4);
        s2 = s3;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    } else {
      peg$currPos = s2;
      s2 = peg$FAILED;
    }
    while (s2 !== peg$FAILED) {
      s1.push(s2);
      s2 = peg$currPos;
      s3 = peg$parse_();
      if (s3 !== peg$FAILED) {
        if (input.substr(peg$currPos, 6) === peg$c78) {
          s4 = peg$c78;
          peg$currPos += 6;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c79); }
        }
        if (s4 === peg$FAILED) {
          if (input.substr(peg$currPos, 5) === peg$c80) {
            s4 = peg$c80;
            peg$currPos += 5;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c81); }
          }
          if (s4 === peg$FAILED) {
            if (input.substr(peg$currPos, 6) === peg$c82) {
              s4 = peg$c82;
              peg$currPos += 6;
            } else {
              s4 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c83); }
            }
            if (s4 === peg$FAILED) {
              if (input.substr(peg$currPos, 4) === peg$c84) {
                s4 = peg$c84;
                peg$currPos += 4;
              } else {
                s4 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c85); }
              }
            }
          }
        }
        if (s4 !== peg$FAILED) {
          peg$savedPos = s2;
          s3 = peg$c86(s4);
          s2 = s3;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c87(s1);
    }
    s0 = s1;

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseMultiplierScaleType() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11;

    var key    = peg$currPos * 140 + 12,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7) === peg$c88) {
      s1 = peg$c88;
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c89); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 4) === peg$c90) {
          s3 = peg$c90;
          peg$currPos += 4;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c91); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 7) === peg$c92) {
              s5 = peg$c92;
              peg$currPos += 7;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c93); }
            }
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 3) === peg$c94) {
                  s7 = peg$c94;
                  peg$currPos += 3;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c95); }
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c96();
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 7) === peg$c88) {
        s1 = peg$c88;
        peg$currPos += 7;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c89); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        if (s2 !== peg$FAILED) {
          if (input.substr(peg$currPos, 4) === peg$c90) {
            s3 = peg$c90;
            peg$currPos += 4;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c91); }
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            if (s4 !== peg$FAILED) {
              if (input.substr(peg$currPos, 7) === peg$c97) {
                s5 = peg$c97;
                peg$currPos += 7;
              } else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c98); }
              }
              if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c99();
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 7) === peg$c88) {
          s1 = peg$c88;
          peg$currPos += 7;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c89); }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          if (s2 !== peg$FAILED) {
            if (input.substr(peg$currPos, 4) === peg$c90) {
              s3 = peg$c90;
              peg$currPos += 4;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c91); }
            }
            if (s3 !== peg$FAILED) {
              s4 = peg$parse_();
              if (s4 !== peg$FAILED) {
                s5 = peg$parseStat();
                if (s5 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c100(s5);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.substr(peg$currPos, 7) === peg$c88) {
            s1 = peg$c88;
            peg$currPos += 7;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c89); }
          }
          if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            if (s2 !== peg$FAILED) {
              if (input.substr(peg$currPos, 4) === peg$c90) {
                s3 = peg$c90;
                peg$currPos += 4;
              } else {
                s3 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c91); }
              }
              if (s3 !== peg$FAILED) {
                s4 = peg$parse_();
                if (s4 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 4) === peg$c101) {
                    s5 = peg$c101;
                    peg$currPos += 4;
                  } else {
                    s5 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c102); }
                  }
                  if (s5 !== peg$FAILED) {
                    s6 = peg$parse_();
                    if (s6 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 5) === peg$c103) {
                        s7 = peg$c103;
                        peg$currPos += 5;
                      } else {
                        s7 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c104); }
                      }
                      if (s7 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c105();
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            if (input.substr(peg$currPos, 7) === peg$c88) {
              s1 = peg$c88;
              peg$currPos += 7;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c89); }
            }
            if (s1 !== peg$FAILED) {
              s2 = peg$parse_();
              if (s2 !== peg$FAILED) {
                if (input.substr(peg$currPos, 4) === peg$c90) {
                  s3 = peg$c90;
                  peg$currPos += 4;
                } else {
                  s3 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c91); }
                }
                if (s3 !== peg$FAILED) {
                  s4 = peg$parse_();
                  if (s4 !== peg$FAILED) {
                    s5 = peg$parseSchool();
                    if (s5 !== peg$FAILED) {
                      s6 = peg$parse_();
                      if (s6 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 9) === peg$c106) {
                          s7 = peg$c106;
                          peg$currPos += 9;
                        } else {
                          s7 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c107); }
                        }
                        if (s7 !== peg$FAILED) {
                          s8 = peg$parse_();
                          if (s8 !== peg$FAILED) {
                            if (input.substr(peg$currPos, 4) === peg$c108) {
                              s9 = peg$c108;
                              peg$currPos += 4;
                            } else {
                              s9 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c109); }
                            }
                            if (s9 !== peg$FAILED) {
                              peg$savedPos = s0;
                              s1 = peg$c110(s5);
                              s0 = s1;
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              if (input.substr(peg$currPos, 7) === peg$c88) {
                s1 = peg$c88;
                peg$currPos += 7;
              } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c89); }
              }
              if (s1 !== peg$FAILED) {
                s2 = peg$parse_();
                if (s2 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 4) === peg$c90) {
                    s3 = peg$c90;
                    peg$currPos += 4;
                  } else {
                    s3 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c91); }
                  }
                  if (s3 !== peg$FAILED) {
                    s4 = peg$parse_();
                    if (s4 !== peg$FAILED) {
                      s5 = peg$parseElement();
                      if (s5 !== peg$FAILED) {
                        s6 = peg$parse_();
                        if (s6 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 7) === peg$c111) {
                            s7 = peg$c111;
                            peg$currPos += 7;
                          } else {
                            s7 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c112); }
                          }
                          if (s7 !== peg$FAILED) {
                            s8 = peg$parse_();
                            if (s8 !== peg$FAILED) {
                              if (input.substr(peg$currPos, 4) === peg$c108) {
                                s9 = peg$c108;
                                peg$currPos += 4;
                              } else {
                                s9 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c109); }
                              }
                              if (s9 !== peg$FAILED) {
                                peg$savedPos = s0;
                                s1 = peg$c113(s5);
                                s0 = s1;
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
              if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (input.substr(peg$currPos, 7) === peg$c88) {
                  s1 = peg$c88;
                  peg$currPos += 7;
                } else {
                  s1 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c89); }
                }
                if (s1 !== peg$FAILED) {
                  s2 = peg$parse_();
                  if (s2 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 4) === peg$c90) {
                      s3 = peg$c90;
                      peg$currPos += 4;
                    } else {
                      s3 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c91); }
                    }
                    if (s3 !== peg$FAILED) {
                      s4 = peg$parse_();
                      if (s4 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 6) === peg$c114) {
                          s5 = peg$c114;
                          peg$currPos += 6;
                        } else {
                          s5 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c115); }
                        }
                        if (s5 === peg$FAILED) {
                          if (input.substr(peg$currPos, 4) === peg$c116) {
                            s5 = peg$c116;
                            peg$currPos += 4;
                          } else {
                            s5 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c117); }
                          }
                        }
                        if (s5 !== peg$FAILED) {
                          s6 = peg$parse_();
                          if (s6 !== peg$FAILED) {
                            if (input.substr(peg$currPos, 6) === peg$c118) {
                              s7 = peg$c118;
                              peg$currPos += 6;
                            } else {
                              s7 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c119); }
                            }
                            if (s7 !== peg$FAILED) {
                              s8 = peg$parse_();
                              if (s8 !== peg$FAILED) {
                                s9 = peg$parseDecimalNumber();
                                if (s9 !== peg$FAILED) {
                                  s10 = peg$parse_();
                                  if (s10 !== peg$FAILED) {
                                    if (input.substr(peg$currPos, 7) === peg$c120) {
                                      s11 = peg$c120;
                                      peg$currPos += 7;
                                    } else {
                                      s11 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c121); }
                                    }
                                    if (s11 !== peg$FAILED) {
                                      peg$savedPos = s0;
                                      s1 = peg$c122(s9);
                                      s0 = s1;
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                  s0 = peg$currPos;
                  if (input.substr(peg$currPos, 41) === peg$c123) {
                    s1 = peg$c123;
                    peg$currPos += 41;
                  } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c124); }
                  }
                  if (s1 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c125();
                  }
                  s0 = s1;
                }
              }
            }
          }
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAttackExtras() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 140 + 13,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 44) {
      s3 = peg$c0;
      peg$currPos++;
    } else {
      s3 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1); }
    }
    if (s3 === peg$FAILED) {
      s3 = peg$currPos;
      s4 = peg$parse_();
      if (s4 !== peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c8) {
          s5 = peg$c8;
          peg$currPos += 3;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c9); }
        }
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
    }
    if (s3 === peg$FAILED) {
      s3 = null;
    }
    if (s3 !== peg$FAILED) {
      s4 = peg$parse_();
      if (s4 !== peg$FAILED) {
        s5 = peg$parseAttackExtra();
        if (s5 !== peg$FAILED) {
          s3 = [s3, s4, s5];
          s2 = s3;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    } else {
      peg$currPos = s2;
      s2 = peg$FAILED;
    }
    while (s2 !== peg$FAILED) {
      s1.push(s2);
      s2 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 44) {
        s3 = peg$c0;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1); }
      }
      if (s3 === peg$FAILED) {
        s3 = peg$currPos;
        s4 = peg$parse_();
        if (s4 !== peg$FAILED) {
          if (input.substr(peg$currPos, 3) === peg$c8) {
            s5 = peg$c8;
            peg$currPos += 3;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c9); }
          }
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s3 === peg$FAILED) {
        s3 = null;
      }
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        if (s4 !== peg$FAILED) {
          s5 = peg$parseAttackExtra();
          if (s5 !== peg$FAILED) {
            s3 = [s3, s4, s5];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c126(s1);
    }
    s0 = s1;

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAttackExtra() {
    var s0;

    var key    = peg$currPos * 140 + 14,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$parseAdditionalCritDamage();
    if (s0 === peg$FAILED) {
      s0 = peg$parseAdditionalCrit();
      if (s0 === peg$FAILED) {
        s0 = peg$parseAirTime();
        if (s0 === peg$FAILED) {
          s0 = peg$parseAlternateOverstrike();
          if (s0 === peg$FAILED) {
            s0 = peg$parseAlwaysCrits();
            if (s0 === peg$FAILED) {
              s0 = peg$parseAtkUpWithLowHp();
              if (s0 === peg$FAILED) {
                s0 = peg$parseAttackScaleType();
                if (s0 === peg$FAILED) {
                  s0 = peg$parseAttackStatusChance();
                  if (s0 === peg$FAILED) {
                    s0 = peg$parseDamageModifier();
                    if (s0 === peg$FAILED) {
                      s0 = peg$parseFinisherPercent();
                      if (s0 === peg$FAILED) {
                        s0 = peg$parseFollowedByAttack();
                        if (s0 === peg$FAILED) {
                          s0 = peg$parseHitRate();
                          if (s0 === peg$FAILED) {
                            s0 = peg$parseMinDamage();
                            if (s0 === peg$FAILED) {
                              s0 = peg$parseOrMultiplier();
                              if (s0 === peg$FAILED) {
                                s0 = peg$parseOrNumAttacks();
                                if (s0 === peg$FAILED) {
                                  s0 = peg$parseOverrideElement();
                                  if (s0 === peg$FAILED) {
                                    s0 = peg$parsePiercingDefRes();
                                    if (s0 === peg$FAILED) {
                                      s0 = peg$parsePiercingDef();
                                      if (s0 === peg$FAILED) {
                                        s0 = peg$parsePiercingRes();
                                        if (s0 === peg$FAILED) {
                                          s0 = peg$parseScaleWithAtkAndDef();
                                          if (s0 === peg$FAILED) {
                                            s0 = peg$parseSBMultiplier();
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAdditionalCritDamage() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10;

    var key    = peg$currPos * 140 + 15,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parsePercentSlashList();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 10) === peg$c127) {
          s3 = peg$c127;
          peg$currPos += 10;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c128); }
        }
        if (s3 === peg$FAILED) {
          if (input.substr(peg$currPos, 4) === peg$c129) {
            s3 = peg$c129;
            peg$currPos += 4;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c130); }
          }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 8) === peg$c131) {
              s5 = peg$c131;
              peg$currPos += 8;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c132); }
            }
            if (s5 === peg$FAILED) {
              if (input.substr(peg$currPos, 5) === peg$c133) {
                s5 = peg$c133;
                peg$currPos += 5;
              } else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c134); }
              }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 6) === peg$c42) {
                  s7 = peg$c42;
                  peg$currPos += 6;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c43); }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$currPos;
                  s9 = peg$parse_();
                  if (s9 !== peg$FAILED) {
                    s10 = peg$parseCondition();
                    if (s10 !== peg$FAILED) {
                      s9 = [s9, s10];
                      s8 = s9;
                    } else {
                      peg$currPos = s8;
                      s8 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s8;
                    s8 = peg$FAILED;
                  }
                  if (s8 === peg$FAILED) {
                    s8 = null;
                  }
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c135(s1, s8);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAdditionalCrit() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10;

    var key    = peg$currPos * 140 + 16,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parsePercentSlashList();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 10) === peg$c127) {
          s3 = peg$c127;
          peg$currPos += 10;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c128); }
        }
        if (s3 === peg$FAILED) {
          if (input.substr(peg$currPos, 4) === peg$c129) {
            s3 = peg$c129;
            peg$currPos += 4;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c130); }
          }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 8) === peg$c131) {
              s5 = peg$c131;
              peg$currPos += 8;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c132); }
            }
            if (s5 === peg$FAILED) {
              if (input.substr(peg$currPos, 5) === peg$c133) {
                s5 = peg$c133;
                peg$currPos += 5;
              } else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c134); }
              }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 6) === peg$c136) {
                  s7 = peg$c136;
                  peg$currPos += 6;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c137); }
                }
                if (s7 === peg$FAILED) {
                  s7 = null;
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$currPos;
                  s9 = peg$parse_();
                  if (s9 !== peg$FAILED) {
                    s10 = peg$parseCondition();
                    if (s10 !== peg$FAILED) {
                      s9 = [s9, s10];
                      s8 = s9;
                    } else {
                      peg$currPos = s8;
                      s8 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s8;
                    s8 = peg$FAILED;
                  }
                  if (s8 === peg$FAILED) {
                    s8 = null;
                  }
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c138(s1, s8);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAirTime() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11;

    var key    = peg$currPos * 140 + 17,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3) === peg$c139) {
      s1 = peg$c139;
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c140); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 4) === peg$c141) {
          s3 = peg$c141;
          peg$currPos += 4;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c142); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 40) {
              s5 = peg$c19;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c20); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parseDecimalNumberSlashList();
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 4) === peg$c143) {
                    s8 = peg$c143;
                    peg$currPos += 4;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c144); }
                  }
                  if (s8 === peg$FAILED) {
                    s8 = null;
                  }
                  if (s8 !== peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 41) {
                      s9 = peg$c21;
                      peg$currPos++;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c22); }
                    }
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parse_();
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parseCondition();
                        if (s11 === peg$FAILED) {
                          s11 = null;
                        }
                        if (s11 !== peg$FAILED) {
                          peg$savedPos = s0;
                          s1 = peg$c145(s6, s11);
                          s0 = s1;
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAlternateOverstrike() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 140 + 18,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6) === peg$c146) {
      s1 = peg$c146;
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c147); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c148) {
          s3 = peg$c148;
          peg$currPos += 2;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c149); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 5) === peg$c150) {
              s5 = peg$c150;
              peg$currPos += 5;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c151); }
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c152();
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAlwaysCrits() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    var key    = peg$currPos * 140 + 19,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6) === peg$c153) {
      s1 = peg$c153;
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c154); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 5) === peg$c49) {
          s3 = peg$c49;
          peg$currPos += 5;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c50); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 97) {
              s5 = peg$c155;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c156); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 8) === peg$c131) {
                  s7 = peg$c131;
                  peg$currPos += 8;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c132); }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 3) === peg$c157) {
                      s9 = peg$c157;
                      peg$currPos += 3;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c158); }
                    }
                    if (s9 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c159();
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAtkUpWithLowHp() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10;

    var key    = peg$currPos * 140 + 20,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3) === peg$c160) {
      s1 = peg$c160;
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c161); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 9) === peg$c162) {
          s3 = peg$c162;
          peg$currPos += 9;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c163); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c164) {
              s5 = peg$c164;
              peg$currPos += 2;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c165); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c166) {
                  s7 = peg$c166;
                  peg$currPos += 2;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c167); }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 8) === peg$c168) {
                      s9 = peg$c168;
                      peg$currPos += 8;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c169); }
                    }
                    if (s9 !== peg$FAILED) {
                      if (input.charCodeAt(peg$currPos) === 115) {
                        s10 = peg$c17;
                        peg$currPos++;
                      } else {
                        s10 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c18); }
                      }
                      if (s10 === peg$FAILED) {
                        s10 = null;
                      }
                      if (s10 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c170();
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAttackScaleType() {
    var s0, s1;

    var key    = peg$currPos * 140 + 21,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseCondition();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c171(s1);
    }
    s0 = s1;

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAttackStatusChance() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14;

    var key    = peg$currPos * 140 + 22,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseIntegerSlashList();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 37) {
        s2 = peg$c172;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c173); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 6) === peg$c136) {
            s4 = peg$c136;
            peg$currPos += 6;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c137); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 2) === peg$c174) {
                s6 = peg$c174;
                peg$currPos += 2;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c175); }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 5) === peg$c176) {
                    s8 = peg$c176;
                    peg$currPos += 5;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c177); }
                  }
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parse_();
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parseStatusName();
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parse_();
                        if (s11 !== peg$FAILED) {
                          s12 = peg$parseDuration();
                          if (s12 === peg$FAILED) {
                            s12 = null;
                          }
                          if (s12 !== peg$FAILED) {
                            s13 = peg$parse_();
                            if (s13 !== peg$FAILED) {
                              s14 = peg$parseCondition();
                              if (s14 === peg$FAILED) {
                                s14 = null;
                              }
                              if (s14 !== peg$FAILED) {
                                peg$savedPos = s0;
                                s1 = peg$c178(s1, s10, s12, s14);
                                s0 = s1;
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseDamageModifier() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    var key    = peg$currPos * 140 + 23,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseIntegerWithNegativesSlashList();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 37) {
        s2 = peg$c172;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c173); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 4) === peg$c179) {
            s4 = peg$c179;
            peg$currPos += 4;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c180); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 6) === peg$c42) {
                s6 = peg$c42;
                peg$currPos += 6;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c43); }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseCondition();
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c181(s1, s8);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseFinisherPercent() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17, s18, s19, s20, s21, s22, s23, s24;

    var key    = peg$currPos * 140 + 24,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3) === peg$c182) {
      s1 = peg$c182;
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c183); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseDecimalNumber();
        if (s3 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 37) {
            s4 = peg$c172;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c173); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 2) === peg$c184) {
                s6 = peg$c184;
                peg$currPos += 2;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c185); }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 3) === peg$c186) {
                    s8 = peg$c186;
                    peg$currPos += 3;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c187); }
                  }
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parse_();
                    if (s9 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 6) === peg$c42) {
                        s10 = peg$c42;
                        peg$currPos += 6;
                      } else {
                        s10 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c43); }
                      }
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parse_();
                        if (s11 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 5) === peg$c188) {
                            s12 = peg$c188;
                            peg$currPos += 5;
                          } else {
                            s12 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c189); }
                          }
                          if (s12 !== peg$FAILED) {
                            s13 = peg$parse_();
                            if (s13 !== peg$FAILED) {
                              if (input.substr(peg$currPos, 4) === peg$c90) {
                                s14 = peg$c90;
                                peg$currPos += 4;
                              } else {
                                s14 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c91); }
                              }
                              if (s14 !== peg$FAILED) {
                                s15 = peg$parse_();
                                if (s15 !== peg$FAILED) {
                                  s16 = peg$parseSkillType();
                                  if (s16 === peg$FAILED) {
                                    s16 = peg$parseElement();
                                    if (s16 === peg$FAILED) {
                                      s16 = peg$parseSchool();
                                    }
                                  }
                                  if (s16 !== peg$FAILED) {
                                    s17 = peg$parse_();
                                    if (s17 !== peg$FAILED) {
                                      if (input.substr(peg$currPos, 7) === peg$c111) {
                                        s18 = peg$c111;
                                        peg$currPos += 7;
                                      } else {
                                        s18 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c112); }
                                      }
                                      if (s18 === peg$FAILED) {
                                        if (input.substr(peg$currPos, 9) === peg$c106) {
                                          s18 = peg$c106;
                                          peg$currPos += 9;
                                        } else {
                                          s18 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c107); }
                                        }
                                      }
                                      if (s18 !== peg$FAILED) {
                                        s19 = peg$parse_();
                                        if (s19 !== peg$FAILED) {
                                          if (input.substr(peg$currPos, 6) === peg$c190) {
                                            s20 = peg$c190;
                                            peg$currPos += 6;
                                          } else {
                                            s20 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c191); }
                                          }
                                          if (s20 !== peg$FAILED) {
                                            s21 = peg$parse_();
                                            if (s21 !== peg$FAILED) {
                                              if (input.substr(peg$currPos, 3) === peg$c186) {
                                                s22 = peg$c186;
                                                peg$currPos += 3;
                                              } else {
                                                s22 = peg$FAILED;
                                                if (peg$silentFails === 0) { peg$fail(peg$c187); }
                                              }
                                              if (s22 !== peg$FAILED) {
                                                s23 = peg$parse_();
                                                if (s23 !== peg$FAILED) {
                                                  if (input.substr(peg$currPos, 6) === peg$c192) {
                                                    s24 = peg$c192;
                                                    peg$currPos += 6;
                                                  } else {
                                                    s24 = peg$FAILED;
                                                    if (peg$silentFails === 0) { peg$fail(peg$c193); }
                                                  }
                                                  if (s24 !== peg$FAILED) {
                                                    peg$savedPos = s0;
                                                    s1 = peg$c194(s3, s16);
                                                    s0 = s1;
                                                  } else {
                                                    peg$currPos = s0;
                                                    s0 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s0;
                                                  s0 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s0;
                                          s0 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseFollowedByAttack() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 140 + 25,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8) === peg$c195) {
      s1 = peg$c195;
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c196); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c197) {
          s3 = peg$c197;
          peg$currPos += 2;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c198); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseSimpleAttack();
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c199(s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseHitRate() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11;

    var key    = peg$currPos * 140 + 26,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseInteger();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 37) {
        s2 = peg$c172;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c173); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 3) === peg$c157) {
            s4 = peg$c157;
            peg$currPos += 3;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c158); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 4) === peg$c200) {
                s6 = peg$c200;
                peg$currPos += 4;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c201); }
              }
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c202(s1);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 40) {
        s1 = peg$c19;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c20); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parseInteger();
        if (s2 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 37) {
            s3 = peg$c172;
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c173); }
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            if (s4 !== peg$FAILED) {
              if (input.substr(peg$currPos, 3) === peg$c157) {
                s5 = peg$c157;
                peg$currPos += 3;
              } else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c158); }
              }
              if (s5 !== peg$FAILED) {
                s6 = peg$parse_();
                if (s6 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 4) === peg$c200) {
                    s7 = peg$c200;
                    peg$currPos += 4;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c201); }
                  }
                  if (s7 !== peg$FAILED) {
                    s8 = peg$parse_();
                    if (s8 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 2) === peg$c203) {
                        s9 = peg$c203;
                        peg$currPos += 2;
                      } else {
                        s9 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c204); }
                      }
                      if (s9 !== peg$FAILED) {
                        s10 = peg$parse_();
                        if (s10 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 10) === peg$c205) {
                            s11 = peg$c205;
                            peg$currPos += 10;
                          } else {
                            s11 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c206); }
                          }
                          if (s11 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c202(s2);
                            s0 = s1;
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseMinDamage() {
    var s0, s1, s2, s3, s4, s5, s6;

    var key    = peg$currPos * 140 + 27,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$currPos;
    if (input.substr(peg$currPos, 7) === peg$c207) {
      s2 = peg$c207;
      peg$currPos += 7;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c208); }
    }
    if (s2 !== peg$FAILED) {
      s3 = peg$parse_();
      if (s3 !== peg$FAILED) {
        if (input.substr(peg$currPos, 6) === peg$c42) {
          s4 = peg$c42;
          peg$currPos += 6;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c43); }
        }
        if (s4 !== peg$FAILED) {
          s2 = [s2, s3, s4];
          s1 = s2;
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c209) {
        s1 = peg$c209;
        peg$currPos += 4;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c210); }
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseInteger();
        if (s3 !== peg$FAILED) {
          s4 = peg$currPos;
          s5 = peg$parse_();
          if (s5 !== peg$FAILED) {
            if (input.substr(peg$currPos, 10) === peg$c211) {
              s6 = peg$c211;
              peg$currPos += 10;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c212); }
            }
            if (s6 !== peg$FAILED) {
              s5 = [s5, s6];
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 === peg$FAILED) {
            s4 = null;
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c213(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseOrMultiplier() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 140 + 28,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseDecimalNumberSlashList();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        if (input.substr(peg$currPos, 2) === peg$c61) {
          s4 = peg$c61;
          peg$currPos += 2;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c62); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parse_();
          if (s5 !== peg$FAILED) {
            s6 = peg$parseDecimalNumberSlashList();
            if (s6 !== peg$FAILED) {
              peg$savedPos = s3;
              s4 = peg$c214(s1, s6);
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 10) === peg$c215) {
              s5 = peg$c215;
              peg$currPos += 10;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c216); }
            }
            if (s5 === peg$FAILED) {
              if (input.substr(peg$currPos, 5) === peg$c217) {
                s5 = peg$c217;
                peg$currPos += 5;
              } else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c218); }
              }
              if (s5 === peg$FAILED) {
                if (input.substr(peg$currPos, 4) === peg$c44) {
                  s5 = peg$c44;
                  peg$currPos += 4;
                } else {
                  s5 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c45); }
                }
              }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseCondition();
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c219(s1, s3, s7);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseOrNumAttacks() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 140 + 29,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseNumAttacks();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 7) === peg$c111) {
          s3 = peg$c111;
          peg$currPos += 7;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c112); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseCondition();
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c220(s1, s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseOverrideElement() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 140 + 30,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4) === peg$c38) {
      s1 = peg$c38;
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c39); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 5) === peg$c49) {
          s3 = peg$c49;
          peg$currPos += 5;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c50); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseElement();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 6) === peg$c42) {
                  s7 = peg$c42;
                  peg$currPos += 6;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c43); }
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c221(s5);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parsePiercingDef() {
    var s0, s1;

    var key    = peg$currPos * 140 + 31,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 11) === peg$c222) {
      s1 = peg$c222;
      peg$currPos += 11;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c223); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c224();
    }
    s0 = s1;

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parsePiercingRes() {
    var s0, s1;

    var key    = peg$currPos * 140 + 32,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 11) === peg$c225) {
      s1 = peg$c225;
      peg$currPos += 11;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c226); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c227();
    }
    s0 = s1;

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parsePiercingDefRes() {
    var s0, s1;

    var key    = peg$currPos * 140 + 33,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 15) === peg$c228) {
      s1 = peg$c228;
      peg$currPos += 15;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c229); }
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 19) === peg$c230) {
        s1 = peg$c230;
        peg$currPos += 19;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c231); }
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c232();
    }
    s0 = s1;

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseScaleWithAtkAndDef() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13;

    var key    = peg$currPos * 140 + 34,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6) === peg$c42) {
      s1 = peg$c42;
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c43); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 6) === peg$c233) {
          s3 = peg$c233;
          peg$currPos += 6;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c234); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 4) === peg$c90) {
              s5 = peg$c90;
              peg$currPos += 4;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c91); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 4) === peg$c235) {
                  s7 = peg$c235;
                  peg$currPos += 4;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c236); }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 3) === peg$c160) {
                      s9 = peg$c160;
                      peg$currPos += 3;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c161); }
                    }
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parse_();
                      if (s10 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 3) === peg$c8) {
                          s11 = peg$c8;
                          peg$currPos += 3;
                        } else {
                          s11 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c9); }
                        }
                        if (s11 !== peg$FAILED) {
                          s12 = peg$parse_();
                          if (s12 !== peg$FAILED) {
                            if (input.substr(peg$currPos, 3) === peg$c237) {
                              s13 = peg$c237;
                              peg$currPos += 3;
                            } else {
                              s13 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c238); }
                            }
                            if (s13 !== peg$FAILED) {
                              peg$savedPos = s0;
                              s1 = peg$c239();
                              s0 = s1;
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseScalingOverstrike() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 140 + 35,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 9) === peg$c30) {
      s1 = peg$c30;
      peg$currPos += 9;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c31); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseDamageCapValue();
        if (s3 !== peg$FAILED) {
          s4 = [];
          s5 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 47) {
            s6 = peg$c240;
            peg$currPos++;
          } else {
            s6 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c241); }
          }
          if (s6 !== peg$FAILED) {
            s7 = peg$parseDamageCapValue();
            if (s7 !== peg$FAILED) {
              s6 = [s6, s7];
              s5 = s6;
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
          if (s5 !== peg$FAILED) {
            while (s5 !== peg$FAILED) {
              s4.push(s5);
              s5 = peg$currPos;
              if (input.charCodeAt(peg$currPos) === 47) {
                s6 = peg$c240;
                peg$currPos++;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c241); }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parseDamageCapValue();
                if (s7 !== peg$FAILED) {
                  s6 = [s6, s7];
                  s5 = s6;
                } else {
                  peg$currPos = s5;
                  s5 = peg$FAILED;
                }
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            }
          } else {
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c242(s3, s4);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSBMultiplier() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15;

    var key    = peg$currPos * 140 + 36,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 10) === peg$c215) {
      s1 = peg$c215;
      peg$currPos += 10;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c216); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 9) === peg$c243) {
          s3 = peg$c243;
          peg$currPos += 9;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c244); }
        }
        if (s3 === peg$FAILED) {
          if (input.substr(peg$currPos, 9) === peg$c245) {
            s3 = peg$c245;
            peg$currPos += 9;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c246); }
          }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c197) {
              s5 = peg$c197;
              peg$currPos += 2;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c198); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseDecimalNumber();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 3) === peg$c182) {
                      s9 = peg$c182;
                      peg$currPos += 3;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c183); }
                    }
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parse_();
                      if (s10 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 5) === peg$c247) {
                          s11 = peg$c247;
                          peg$currPos += 5;
                        } else {
                          s11 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c248); }
                        }
                        if (s11 !== peg$FAILED) {
                          s12 = peg$parse_();
                          if (s12 !== peg$FAILED) {
                            if (input.substr(peg$currPos, 2) === peg$c249) {
                              s13 = peg$c249;
                              peg$currPos += 2;
                            } else {
                              s13 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c250); }
                            }
                            if (s13 !== peg$FAILED) {
                              s14 = peg$parse_();
                              if (s14 !== peg$FAILED) {
                                if (input.substr(peg$currPos, 5) === peg$c251) {
                                  s15 = peg$c251;
                                  peg$currPos += 5;
                                } else {
                                  s15 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c252); }
                                }
                                if (s15 !== peg$FAILED) {
                                  peg$savedPos = s0;
                                  s1 = peg$c253(s3, s7);
                                  s0 = s1;
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseDrainHp() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    var key    = peg$currPos * 140 + 37,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$currPos;
    if (input.substr(peg$currPos, 5) === peg$c254) {
      s2 = peg$c254;
      peg$currPos += 5;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c255); }
    }
    if (s2 !== peg$FAILED) {
      s3 = peg$parse_();
      if (s3 !== peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c174) {
          s4 = peg$c174;
          peg$currPos += 2;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c175); }
        }
        if (s4 === peg$FAILED) {
          s4 = null;
        }
        if (s4 !== peg$FAILED) {
          s2 = [s2, s3, s4];
          s1 = s2;
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 14) === peg$c256) {
        s1 = peg$c256;
        peg$currPos += 14;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c257); }
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 12) === peg$c258) {
          s3 = peg$c258;
          peg$currPos += 12;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c259); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseInteger();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 21) === peg$c260) {
                s6 = peg$c260;
                peg$currPos += 21;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c261); }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseCondition();
                  if (s8 === peg$FAILED) {
                    s8 = null;
                  }
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c262(s5, s8);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseRecoilHp() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    var key    = peg$currPos * 140 + 38,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 20) === peg$c263) {
      s1 = peg$c263;
      peg$currPos += 20;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c264); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseDecimalNumberPercentSlashList();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$currPos;
            s6 = peg$parseMaximum();
            if (s6 === peg$FAILED) {
              if (input.substr(peg$currPos, 7) === peg$c92) {
                s6 = peg$c92;
                peg$currPos += 7;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c93); }
              }
            }
            if (s6 !== peg$FAILED) {
              peg$savedPos = s5;
              s6 = peg$c265(s3);
            }
            s5 = s6;
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c166) {
                  s7 = peg$c166;
                  peg$currPos += 2;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c167); }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseCondition();
                    if (s9 === peg$FAILED) {
                      s9 = null;
                    }
                    if (s9 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c266(s3, s5, s9);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseRecoilSetHp() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 140 + 39,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 21) === peg$c267) {
      s1 = peg$c267;
      peg$currPos += 21;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c268); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseInteger();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseCondition();
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c269(s3, s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseFixedRecoilHp() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11;

    var key    = peg$currPos * 140 + 40,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5) === peg$c49) {
      s1 = peg$c49;
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c50); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseInteger();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseSkillType();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 8) === peg$c67) {
                  s7 = peg$c67;
                  peg$currPos += 8;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c68); }
                }
                if (s7 === peg$FAILED) {
                  if (input.substr(peg$currPos, 7) === peg$c69) {
                    s7 = peg$c69;
                    peg$currPos += 7;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c70); }
                  }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 6) === peg$c42) {
                      s9 = peg$c42;
                      peg$currPos += 6;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c43); }
                    }
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parse_();
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parseWho();
                        if (s11 !== peg$FAILED) {
                          peg$savedPos = s0;
                          s1 = peg$c270(s3, s5, s11);
                          s0 = s1;
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseGravityAttack() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16;

    var key    = peg$currPos * 140 + 41,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7).toLowerCase() === peg$c271) {
      s1 = input.substr(peg$currPos, 7);
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c272); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c182) {
          s3 = peg$c182;
          peg$currPos += 3;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c183); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseInteger();
            if (s5 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 37) {
                s6 = peg$c172;
                peg$currPos++;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c173); }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 2) === peg$c184) {
                    s8 = peg$c184;
                    peg$currPos += 2;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c185); }
                  }
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parse_();
                    if (s9 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 3) === peg$c186) {
                        s10 = peg$c186;
                        peg$currPos += 3;
                      } else {
                        s10 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c187); }
                      }
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parse_();
                        if (s11 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 8) === peg$c273) {
                            s12 = peg$c273;
                            peg$currPos += 8;
                          } else {
                            s12 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c274); }
                          }
                          if (s12 !== peg$FAILED) {
                            s13 = peg$parse_();
                            if (s13 !== peg$FAILED) {
                              if (input.substr(peg$currPos, 7) === peg$c92) {
                                s14 = peg$c92;
                                peg$currPos += 7;
                              } else {
                                s14 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c93); }
                              }
                              if (s14 !== peg$FAILED) {
                                s15 = peg$parse_();
                                if (s15 !== peg$FAILED) {
                                  if (input.substr(peg$currPos, 2) === peg$c166) {
                                    s16 = peg$c166;
                                    peg$currPos += 2;
                                  } else {
                                    s16 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c167); }
                                  }
                                  if (s16 !== peg$FAILED) {
                                    peg$savedPos = s0;
                                    s1 = peg$c275(s5);
                                    s0 = s1;
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseHpAttack() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17, s18, s19, s20, s21;

    var key    = peg$currPos * 140 + 42,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7).toLowerCase() === peg$c271) {
      s1 = input.substr(peg$currPos, 7);
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c272); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c182) {
          s3 = peg$c182;
          peg$currPos += 3;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c183); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseInteger();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 42) {
                  s7 = peg$c276;
                  peg$currPos++;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c277); }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 7) === peg$c278) {
                      s9 = peg$c278;
                      peg$currPos += 7;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c279); }
                    }
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parse_();
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parseMaximum();
                        if (s11 !== peg$FAILED) {
                          s12 = peg$parse_();
                          if (s12 !== peg$FAILED) {
                            if (input.substr(peg$currPos, 2) === peg$c166) {
                              s13 = peg$c166;
                              peg$currPos += 2;
                            } else {
                              s13 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c167); }
                            }
                            if (s13 !== peg$FAILED) {
                              s14 = peg$parse_();
                              if (s14 !== peg$FAILED) {
                                if (input.charCodeAt(peg$currPos) === 45) {
                                  s15 = peg$c280;
                                  peg$currPos++;
                                } else {
                                  s15 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c281); }
                                }
                                if (s15 !== peg$FAILED) {
                                  s16 = peg$parse_();
                                  if (s16 !== peg$FAILED) {
                                    if (input.substr(peg$currPos, 6) === peg$c282) {
                                      s17 = peg$c282;
                                      peg$currPos += 6;
                                    } else {
                                      s17 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c283); }
                                    }
                                    if (s17 !== peg$FAILED) {
                                      s18 = peg$parse_();
                                      if (s18 !== peg$FAILED) {
                                        if (input.substr(peg$currPos, 7) === peg$c92) {
                                          s19 = peg$c92;
                                          peg$currPos += 7;
                                        } else {
                                          s19 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c93); }
                                        }
                                        if (s19 !== peg$FAILED) {
                                          s20 = peg$parse_();
                                          if (s20 !== peg$FAILED) {
                                            if (input.substr(peg$currPos, 3) === peg$c284) {
                                              s21 = peg$c284;
                                              peg$currPos += 3;
                                            } else {
                                              s21 = peg$FAILED;
                                              if (peg$silentFails === 0) { peg$fail(peg$c285); }
                                            }
                                            if (s21 !== peg$FAILED) {
                                              peg$savedPos = s0;
                                              s1 = peg$c286(s5);
                                              s0 = s1;
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s0;
                                          s0 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseRevive() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10;

    var key    = peg$currPos * 140 + 43,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7).toLowerCase() === peg$c287) {
      s1 = input.substr(peg$currPos, 7);
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c288); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c289) {
          s3 = peg$c289;
          peg$currPos += 2;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c290); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 7) === peg$c291) {
              s5 = peg$c291;
              peg$currPos += 7;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c292); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseInteger();
                if (s7 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 2) === peg$c293) {
                    s8 = peg$c293;
                    peg$currPos += 2;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c294); }
                  }
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parse_();
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parseWho();
                      if (s10 === peg$FAILED) {
                        s10 = null;
                      }
                      if (s10 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c295(s7, s10);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseHeal() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    var key    = peg$currPos * 140 + 44,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8).toLowerCase() === peg$c296) {
      s1 = input.substr(peg$currPos, 8);
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c297); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        if (input.substr(peg$currPos, 2) === peg$c166) {
          s4 = peg$c166;
          peg$currPos += 2;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c167); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parse_();
          if (s5 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 40) {
              s6 = peg$c19;
              peg$currPos++;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c20); }
            }
            if (s6 !== peg$FAILED) {
              s7 = peg$parseDecimalNumberSlashList();
              if (s7 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 41) {
                  s8 = peg$c21;
                  peg$currPos++;
                } else {
                  s8 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c22); }
                }
                if (s8 !== peg$FAILED) {
                  peg$savedPos = s3;
                  s4 = peg$c298(s7);
                  s3 = s4;
                } else {
                  peg$currPos = s3;
                  s3 = peg$FAILED;
                }
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 === peg$FAILED) {
          s3 = peg$currPos;
          s4 = peg$parseIntegerSlashList();
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 2) === peg$c166) {
                s6 = peg$c166;
                peg$currPos += 2;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c167); }
              }
              if (s6 !== peg$FAILED) {
                peg$savedPos = s3;
                s4 = peg$c299(s4);
                s3 = s4;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseWho();
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseCondition();
                if (s7 === peg$FAILED) {
                  s7 = null;
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parsePerUses();
                    if (s9 === peg$FAILED) {
                      s9 = null;
                    }
                    if (s9 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c300(s3, s5, s7, s9);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseHealPercent() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16;

    var key    = peg$currPos * 140 + 45,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8).toLowerCase() === peg$c296) {
      s1 = input.substr(peg$currPos, 8);
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c297); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c166) {
          s3 = peg$c166;
          peg$currPos += 2;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c167); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseWho();
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 3) === peg$c182) {
                  s7 = peg$c182;
                  peg$currPos += 3;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c183); }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseInteger();
                    if (s9 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 4) === peg$c301) {
                        s10 = peg$c301;
                        peg$currPos += 4;
                      } else {
                        s10 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c302); }
                      }
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parse_();
                        if (s11 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 10) === peg$c303) {
                            s12 = peg$c303;
                            peg$currPos += 10;
                          } else {
                            s12 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c304); }
                          }
                          if (s12 === peg$FAILED) {
                            if (input.substr(peg$currPos, 12) === peg$c305) {
                              s12 = peg$c305;
                              peg$currPos += 12;
                            } else {
                              s12 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c306); }
                            }
                            if (s12 === peg$FAILED) {
                              if (input.substr(peg$currPos, 5) === peg$c307) {
                                s12 = peg$c307;
                                peg$currPos += 5;
                              } else {
                                s12 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c308); }
                              }
                            }
                          }
                          if (s12 !== peg$FAILED) {
                            s13 = peg$parse_();
                            if (s13 !== peg$FAILED) {
                              s14 = peg$parseMaximum();
                              if (s14 !== peg$FAILED) {
                                s15 = peg$parse_();
                                if (s15 !== peg$FAILED) {
                                  if (input.substr(peg$currPos, 2) === peg$c166) {
                                    s16 = peg$c166;
                                    peg$currPos += 2;
                                  } else {
                                    s16 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c167); }
                                  }
                                  if (s16 !== peg$FAILED) {
                                    peg$savedPos = s0;
                                    s1 = peg$c309(s5, s9);
                                    s0 = s1;
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseDamagesUndead() {
    var s0, s1, s2, s3, s4;

    var key    = peg$currPos * 140 + 46,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7) === peg$c271) {
      s1 = peg$c271;
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c310); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 6) === peg$c311) {
          s3 = peg$c311;
          peg$currPos += 6;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c312); }
        }
        if (s3 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 115) {
            s4 = peg$c17;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c18); }
          }
          if (s4 === peg$FAILED) {
            s4 = null;
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c313();
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseDispelOrEsuna() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11;

    var key    = peg$currPos * 140 + 47,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7).toLowerCase() === peg$c287) {
      s1 = input.substr(peg$currPos, 7);
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c288); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 8) === peg$c314) {
          s3 = peg$c314;
          peg$currPos += 8;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c315); }
        }
        if (s3 === peg$FAILED) {
          if (input.substr(peg$currPos, 8) === peg$c316) {
            s3 = peg$c316;
            peg$currPos += 8;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c317); }
          }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 6) === peg$c192) {
              s5 = peg$c192;
              peg$currPos += 6;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c193); }
            }
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 7) === peg$c318) {
                  s7 = peg$c318;
                  peg$currPos += 7;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c319); }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseWho();
                    if (s9 === peg$FAILED) {
                      s9 = null;
                    }
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parse_();
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parsePerUses();
                        if (s11 === peg$FAILED) {
                          s11 = null;
                        }
                        if (s11 !== peg$FAILED) {
                          peg$savedPos = s0;
                          s1 = peg$c320(s3, s9, s11);
                          s0 = s1;
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseRandomEther() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13;

    var key    = peg$currPos * 140 + 48,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8).toLowerCase() === peg$c296) {
      s1 = input.substr(peg$currPos, 8);
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c297); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseInteger();
        if (s3 === peg$FAILED) {
          s3 = peg$parseNumberString();
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 8) === peg$c321) {
              s5 = peg$c321;
              peg$currPos += 8;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c322); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 7) === peg$c323) {
                  s7 = peg$c323;
                  peg$currPos += 7;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c324); }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 3) === peg$c325) {
                      s9 = peg$c325;
                      peg$currPos += 3;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c326); }
                    }
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parse_();
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parseWho();
                        if (s11 === peg$FAILED) {
                          s11 = null;
                        }
                        if (s11 !== peg$FAILED) {
                          s12 = peg$parse_();
                          if (s12 !== peg$FAILED) {
                            s13 = peg$parsePerUses();
                            if (s13 === peg$FAILED) {
                              s13 = null;
                            }
                            if (s13 !== peg$FAILED) {
                              peg$savedPos = s0;
                              s1 = peg$c327(s3, s11, s13);
                              s0 = s1;
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSmartEther() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 140 + 49,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseSmartEtherStatus();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseWho();
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parsePerUses();
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c328(s1, s3, s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseRandomCastAbility() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 140 + 50,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8).toLowerCase() === peg$c47) {
      s1 = input.substr(peg$currPos, 8);
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c329); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 5) === peg$c330) {
          s3 = peg$c330;
          peg$currPos += 5;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c331); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseRandomAbilityList();
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c332(s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseRandomAbilityList() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 140 + 51,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseRandomAbility();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parseOrList();
      if (s4 !== peg$FAILED) {
        s5 = peg$parseRandomAbility();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parseOrList();
        if (s4 !== peg$FAILED) {
          s5 = peg$parseRandomAbility();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c333(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseRandomAbility() {
    var s0, s1, s2, s3, s4, s5, s6;

    var key    = peg$currPos * 140 + 52,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseAbilityName();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 40) {
          s4 = peg$c19;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c20); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseInteger();
          if (s5 !== peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c56) {
              s6 = peg$c56;
              peg$currPos += 2;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c57); }
            }
            if (s6 !== peg$FAILED) {
              s4 = [s4, s5, s6];
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c334(s1, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseRandomCastOther() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    var key    = peg$currPos * 140 + 53,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c330) {
      s1 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c335); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 97) {
          s3 = peg$c155;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c156); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 6) === peg$c74) {
              s5 = peg$c74;
              peg$currPos += 6;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c75); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseAnySkillName();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 6) === peg$c15) {
                      s9 = peg$c15;
                      peg$currPos += 6;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c16); }
                    }
                    if (s9 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c336(s7);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseChain() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15;

    var key    = peg$currPos * 140 + 54,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 9).toLowerCase() === peg$c337) {
      s1 = input.substr(peg$currPos, 9);
      peg$currPos += 9;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c338); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = [];
        if (peg$c339.test(input.charAt(peg$currPos))) {
          s4 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c340); }
        }
        if (s4 !== peg$FAILED) {
          while (s4 !== peg$FAILED) {
            s3.push(s4);
            if (peg$c339.test(input.charAt(peg$currPos))) {
              s4 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s4 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c340); }
            }
          }
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 5) === peg$c341) {
              s5 = peg$c341;
              peg$currPos += 5;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c342); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 4) === peg$c343) {
                  s7 = peg$c343;
                  peg$currPos += 4;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c344); }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseInteger();
                    if (s9 !== peg$FAILED) {
                      if (input.charCodeAt(peg$currPos) === 44) {
                        s10 = peg$c0;
                        peg$currPos++;
                      } else {
                        s10 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c1); }
                      }
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parse_();
                        if (s11 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 5) === peg$c345) {
                            s12 = peg$c345;
                            peg$currPos += 5;
                          } else {
                            s12 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c346); }
                          }
                          if (s12 !== peg$FAILED) {
                            s13 = peg$parse_();
                            if (s13 !== peg$FAILED) {
                              s14 = peg$parseSignedInteger();
                              if (s14 !== peg$FAILED) {
                                if (input.substr(peg$currPos, 2) === peg$c56) {
                                  s15 = peg$c56;
                                  peg$currPos += 2;
                                } else {
                                  s15 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c57); }
                                }
                                if (s15 !== peg$FAILED) {
                                  peg$savedPos = s0;
                                  s1 = peg$c347(s3, s9, s14);
                                  s0 = s1;
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseMimic() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17, s18, s19, s20, s21, s22, s23, s24, s25, s26, s27, s28, s29, s30;

    var key    = peg$currPos * 140 + 55,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$currPos;
    s2 = peg$parseInteger();
    if (s2 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 37) {
        s3 = peg$c172;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c173); }
      }
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        if (s4 !== peg$FAILED) {
          if (input.substr(peg$currPos, 6) === peg$c136) {
            s5 = peg$c136;
            peg$currPos += 6;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c137); }
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            if (s6 !== peg$FAILED) {
              if (input.substr(peg$currPos, 2) === peg$c174) {
                s7 = peg$c174;
                peg$currPos += 2;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c175); }
              }
              if (s7 !== peg$FAILED) {
                s8 = peg$parse_();
                if (s8 !== peg$FAILED) {
                  peg$savedPos = s1;
                  s2 = peg$c348(s2);
                  s1 = s2;
                } else {
                  peg$currPos = s1;
                  s1 = peg$FAILED;
                }
              } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
              }
            } else {
              peg$currPos = s1;
              s1 = peg$FAILED;
            }
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      s1 = null;
    }
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 4).toLowerCase() === peg$c349) {
        s2 = input.substr(peg$currPos, 4);
        peg$currPos += 4;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c350); }
      }
      if (s2 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 115) {
          s3 = peg$c17;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c18); }
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 33) === peg$c351) {
              s5 = peg$c351;
              peg$currPos += 33;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c352); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseOccurrence();
                if (s7 === peg$FAILED) {
                  s7 = null;
                }
                if (s7 !== peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 44) {
                    s8 = peg$c0;
                    peg$currPos++;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c1); }
                  }
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parse_();
                    if (s9 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 7) === peg$c120) {
                        s10 = peg$c120;
                        peg$currPos += 7;
                      } else {
                        s10 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c121); }
                      }
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parse_();
                        if (s11 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 7) === peg$c323) {
                            s12 = peg$c323;
                            peg$currPos += 7;
                          } else {
                            s12 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c324); }
                          }
                          if (s12 !== peg$FAILED) {
                            s13 = peg$parse_();
                            if (s13 !== peg$FAILED) {
                              if (input.substr(peg$currPos, 5) === peg$c353) {
                                s14 = peg$c353;
                                peg$currPos += 5;
                              } else {
                                s14 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c354); }
                              }
                              if (s14 !== peg$FAILED) {
                                s15 = peg$parse_();
                                if (s15 !== peg$FAILED) {
                                  if (input.substr(peg$currPos, 7) === peg$c355) {
                                    s16 = peg$c355;
                                    peg$currPos += 7;
                                  } else {
                                    s16 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c356); }
                                  }
                                  if (s16 !== peg$FAILED) {
                                    s17 = peg$parse_();
                                    if (s17 !== peg$FAILED) {
                                      s18 = peg$parseDecimalNumber();
                                      if (s18 !== peg$FAILED) {
                                        s19 = peg$parse_();
                                        if (s19 !== peg$FAILED) {
                                          if (input.substr(peg$currPos, 8) === peg$c67) {
                                            s20 = peg$c67;
                                            peg$currPos += 8;
                                          } else {
                                            s20 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c68); }
                                          }
                                          if (s20 !== peg$FAILED) {
                                            s21 = peg$currPos;
                                            if (input.charCodeAt(peg$currPos) === 44) {
                                              s22 = peg$c0;
                                              peg$currPos++;
                                            } else {
                                              s22 = peg$FAILED;
                                              if (peg$silentFails === 0) { peg$fail(peg$c1); }
                                            }
                                            if (s22 !== peg$FAILED) {
                                              s23 = peg$parse_();
                                              if (s23 !== peg$FAILED) {
                                                s24 = peg$parseInteger();
                                                if (s24 !== peg$FAILED) {
                                                  s25 = peg$parse_();
                                                  if (s25 !== peg$FAILED) {
                                                    if (input.charCodeAt(peg$currPos) === 37) {
                                                      s26 = peg$c172;
                                                      peg$currPos++;
                                                    } else {
                                                      s26 = peg$FAILED;
                                                      if (peg$silentFails === 0) { peg$fail(peg$c173); }
                                                    }
                                                    if (s26 !== peg$FAILED) {
                                                      s27 = peg$parse_();
                                                      if (s27 !== peg$FAILED) {
                                                        if (input.substr(peg$currPos, 8) === peg$c131) {
                                                          s28 = peg$c131;
                                                          peg$currPos += 8;
                                                        } else {
                                                          s28 = peg$FAILED;
                                                          if (peg$silentFails === 0) { peg$fail(peg$c132); }
                                                        }
                                                        if (s28 !== peg$FAILED) {
                                                          s29 = peg$parse_();
                                                          if (s29 !== peg$FAILED) {
                                                            if (input.substr(peg$currPos, 6) === peg$c136) {
                                                              s30 = peg$c136;
                                                              peg$currPos += 6;
                                                            } else {
                                                              s30 = peg$FAILED;
                                                              if (peg$silentFails === 0) { peg$fail(peg$c137); }
                                                            }
                                                            if (s30 !== peg$FAILED) {
                                                              peg$savedPos = s21;
                                                              s22 = peg$c357(s1, s7, s18, s24);
                                                              s21 = s22;
                                                            } else {
                                                              peg$currPos = s21;
                                                              s21 = peg$FAILED;
                                                            }
                                                          } else {
                                                            peg$currPos = s21;
                                                            s21 = peg$FAILED;
                                                          }
                                                        } else {
                                                          peg$currPos = s21;
                                                          s21 = peg$FAILED;
                                                        }
                                                      } else {
                                                        peg$currPos = s21;
                                                        s21 = peg$FAILED;
                                                      }
                                                    } else {
                                                      peg$currPos = s21;
                                                      s21 = peg$FAILED;
                                                    }
                                                  } else {
                                                    peg$currPos = s21;
                                                    s21 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s21;
                                                  s21 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s21;
                                                s21 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s21;
                                              s21 = peg$FAILED;
                                            }
                                            if (s21 === peg$FAILED) {
                                              s21 = null;
                                            }
                                            if (s21 !== peg$FAILED) {
                                              if (input.charCodeAt(peg$currPos) === 41) {
                                                s22 = peg$c21;
                                                peg$currPos++;
                                              } else {
                                                s22 = peg$FAILED;
                                                if (peg$silentFails === 0) { peg$fail(peg$c22); }
                                              }
                                              if (s22 !== peg$FAILED) {
                                                peg$savedPos = s0;
                                                s1 = peg$c358(s1, s7, s18, s21);
                                                s0 = s1;
                                              } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s0;
                                          s0 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSummon() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    var key    = peg$currPos * 140 + 56,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7).toLowerCase() === peg$c359) {
      s1 = input.substr(peg$currPos, 7);
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c360); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseCharacterName();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 3) === peg$c182) {
              s5 = peg$c182;
              peg$currPos += 3;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c183); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseInteger();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 7) === peg$c361) {
                      s9 = peg$c361;
                      peg$currPos += 7;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c362); }
                    }
                    if (s9 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c363(s3, s7);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatusEffect() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 140 + 57,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseStatusVerb();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c364) {
          s3 = peg$c364;
          peg$currPos += 3;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c365); }
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseStatusList();
            if (s5 !== peg$FAILED) {
              s6 = [];
              s7 = peg$parseStatusClause();
              while (s7 !== peg$FAILED) {
                s6.push(s7);
                s7 = peg$parseStatusClause();
              }
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c366(s1, s3, s5, s6);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseImplicitStatusEffect() {
    var s0, s1, s2, s3, s4;

    var key    = peg$currPos * 140 + 58,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$currPos;
    peg$silentFails++;
    if (input.charCodeAt(peg$currPos) === 91) {
      s2 = peg$c367;
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c368); }
    }
    peg$silentFails--;
    if (s2 !== peg$FAILED) {
      peg$currPos = s1;
      s1 = void 0;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseStatusList();
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$parseStatusClause();
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          s4 = peg$parseStatusClause();
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c369(s2, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSetStatusLevel() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12;

    var key    = peg$currPos * 140 + 59,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3).toLowerCase() === peg$c370) {
      s1 = input.substr(peg$currPos, 3);
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c371); }
    }
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 115) {
        s2 = peg$c17;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c18); }
      }
      if (s2 === peg$FAILED) {
        s2 = null;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 10) === peg$c303) {
            s4 = peg$c303;
            peg$currPos += 10;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c304); }
          }
          if (s4 === peg$FAILED) {
            s4 = null;
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseGenericName();
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 5) === peg$c372) {
                    s8 = peg$c372;
                    peg$currPos += 5;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c373); }
                  }
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parse_();
                    if (s9 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 2) === peg$c174) {
                        s10 = peg$c174;
                        peg$currPos += 2;
                      } else {
                        s10 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c175); }
                      }
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parse_();
                        if (s11 !== peg$FAILED) {
                          s12 = peg$parseInteger();
                          if (s12 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c374(s6, s12);
                            s0 = s1;
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseRandomStatusEffect() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11;

    var key    = peg$currPos * 140 + 60,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8).toLowerCase() === peg$c47) {
      s1 = input.substr(peg$currPos, 8);
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c329); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseStatusVerb();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseRandomStatusList();
            if (s5 !== peg$FAILED) {
              s6 = [];
              s7 = peg$currPos;
              s8 = peg$parse_();
              if (s8 !== peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c61) {
                  s9 = peg$c61;
                  peg$currPos += 2;
                } else {
                  s9 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c62); }
                }
                if (s9 !== peg$FAILED) {
                  s10 = peg$parse_();
                  if (s10 !== peg$FAILED) {
                    s11 = peg$parseRandomStatusList();
                    if (s11 !== peg$FAILED) {
                      s8 = [s8, s9, s10, s11];
                      s7 = s8;
                    } else {
                      peg$currPos = s7;
                      s7 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s7;
                    s7 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s7;
                  s7 = peg$FAILED;
                }
              } else {
                peg$currPos = s7;
                s7 = peg$FAILED;
              }
              if (s7 !== peg$FAILED) {
                while (s7 !== peg$FAILED) {
                  s6.push(s7);
                  s7 = peg$currPos;
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 2) === peg$c61) {
                      s9 = peg$c61;
                      peg$currPos += 2;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c62); }
                    }
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parse_();
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parseRandomStatusList();
                        if (s11 !== peg$FAILED) {
                          s8 = [s8, s9, s10, s11];
                          s7 = s8;
                        } else {
                          peg$currPos = s7;
                          s7 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s7;
                        s7 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s7;
                      s7 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s7;
                    s7 = peg$FAILED;
                  }
                }
              } else {
                s6 = peg$FAILED;
              }
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c375(s3, s5, s6);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseRandomStatusList() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    var key    = peg$currPos * 140 + 61,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseStatusItem();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parseAndList();
      if (s4 !== peg$FAILED) {
        s5 = peg$parseStatusItem();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parseAndList();
        if (s4 !== peg$FAILED) {
          s5 = peg$parseStatusItem();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 40) {
            s4 = peg$c19;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c20); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parseInteger();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 2) === peg$c56) {
                s6 = peg$c56;
                peg$currPos += 2;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c57); }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseWho();
                  if (s8 === peg$FAILED) {
                    s8 = null;
                  }
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c376(s1, s2, s5, s8);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseEntrust() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13;

    var key    = peg$currPos * 140 + 62,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 9).toLowerCase() === peg$c377) {
      s1 = input.substr(peg$currPos, 9);
      peg$currPos += 9;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c378); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 10) === peg$c303) {
          s3 = peg$c303;
          peg$currPos += 10;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c304); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseSB();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 20) === peg$c379) {
                  s7 = peg$c379;
                  peg$currPos += 20;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c380); }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$currPos;
                  if (input.substr(peg$currPos, 12) === peg$c381) {
                    s9 = peg$c381;
                    peg$currPos += 12;
                  } else {
                    s9 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c382); }
                  }
                  if (s9 !== peg$FAILED) {
                    s10 = peg$parse_();
                    if (s10 !== peg$FAILED) {
                      s11 = peg$parseInteger();
                      if (s11 !== peg$FAILED) {
                        s12 = peg$parse_();
                        if (s12 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 18) === peg$c383) {
                            s13 = peg$c383;
                            peg$currPos += 18;
                          } else {
                            s13 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c384); }
                          }
                          if (s13 !== peg$FAILED) {
                            peg$savedPos = s8;
                            s9 = peg$c385(s11);
                            s8 = s9;
                          } else {
                            peg$currPos = s8;
                            s8 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s8;
                          s8 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s8;
                        s8 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s8;
                      s8 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s8;
                    s8 = peg$FAILED;
                  }
                  if (s8 === peg$FAILED) {
                    s8 = null;
                  }
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c386(s8);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseGainSB() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    var key    = peg$currPos * 140 + 63,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c387) {
      s1 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c388); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseInteger();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseSB();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 6) === peg$c389) {
                  s7 = peg$c389;
                  peg$currPos += 6;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c390); }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseWho();
                    if (s9 === peg$FAILED) {
                      s9 = null;
                    }
                    if (s9 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c391(s3, s9);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseGainSBOnSuccess() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14;

    var key    = peg$currPos * 140 + 64,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c387) {
      s1 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c388); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseInteger();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseSB();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 6) === peg$c389) {
                  s7 = peg$c389;
                  peg$currPos += 6;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c390); }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseWho();
                    if (s9 === peg$FAILED) {
                      s9 = null;
                    }
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parse_();
                      if (s10 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 2) === peg$c203) {
                          s11 = peg$c203;
                          peg$currPos += 2;
                        } else {
                          s11 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c204); }
                        }
                        if (s11 !== peg$FAILED) {
                          s12 = peg$parse_();
                          if (s12 !== peg$FAILED) {
                            if (input.substr(peg$currPos, 10) === peg$c392) {
                              s13 = peg$c392;
                              peg$currPos += 10;
                            } else {
                              s13 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c393); }
                            }
                            if (s13 !== peg$FAILED) {
                              if (input.charCodeAt(peg$currPos) === 63) {
                                s14 = peg$c394;
                                peg$currPos++;
                              } else {
                                s14 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c395); }
                              }
                              if (s14 === peg$FAILED) {
                                s14 = null;
                              }
                              if (s14 !== peg$FAILED) {
                                peg$savedPos = s0;
                                s1 = peg$c396(s3, s9);
                                s0 = s1;
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseResetIfKO() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 140 + 65,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6) === peg$c397) {
      s1 = peg$c397;
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c398); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c203) {
          s3 = peg$c203;
          peg$currPos += 2;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c204); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 4) === peg$c399) {
              s5 = peg$c399;
              peg$currPos += 4;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c400); }
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c401();
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseResistViaKO() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 140 + 66,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8) === peg$c402) {
      s1 = peg$c402;
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c403); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c404) {
          s3 = peg$c404;
          peg$currPos += 3;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c405); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 7) === peg$c406) {
              s5 = peg$c406;
              peg$currPos += 7;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c407); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c289) {
                  s7 = peg$c289;
                  peg$currPos += 2;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c290); }
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c408();
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseReset() {
    var s0, s1;

    var key    = peg$currPos * 140 + 67,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5) === peg$c409) {
      s1 = peg$c409;
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c410); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c411();
    }
    s0 = s1;

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseCastTime() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 140 + 68,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4) === peg$c349) {
      s1 = peg$c349;
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c412); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 4) === peg$c141) {
          s3 = peg$c141;
          peg$currPos += 4;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c142); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseDecimalNumberSlashList();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseCondition();
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c413(s5, s7);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseCastTimePerUse() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14;

    var key    = peg$currPos * 140 + 69,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4) === peg$c349) {
      s1 = peg$c349;
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c412); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 4) === peg$c141) {
          s3 = peg$c141;
          peg$currPos += 4;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c142); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 45) {
              s5 = peg$c280;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c281); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parseDecimalNumber();
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 3) === peg$c182) {
                    s8 = peg$c182;
                    peg$currPos += 3;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c183); }
                  }
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parse_();
                    if (s9 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 4) === peg$c44) {
                        s10 = peg$c44;
                        peg$currPos += 4;
                      } else {
                        s10 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c45); }
                      }
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parse_();
                        if (s11 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 8) === peg$c414) {
                            s12 = peg$c414;
                            peg$currPos += 8;
                          } else {
                            s12 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c415); }
                          }
                          if (s12 !== peg$FAILED) {
                            s13 = peg$parse_();
                            if (s13 !== peg$FAILED) {
                              if (input.substr(peg$currPos, 3) === peg$c325) {
                                s14 = peg$c325;
                                peg$currPos += 3;
                              } else {
                                s14 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c326); }
                              }
                              if (s14 !== peg$FAILED) {
                                peg$savedPos = s0;
                                s1 = peg$c416(s6);
                                s0 = s1;
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStandaloneAttackExtra() {
    var s0, s1;

    var key    = peg$currPos * 140 + 70,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseAttackExtra();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c417(s1);
    }
    s0 = s1;

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseRandomSkillEffect() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    var key    = peg$currPos * 140 + 71,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8) === peg$c418) {
      s1 = peg$c418;
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c419); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseRandomSkillEffectClause();
        if (s3 !== peg$FAILED) {
          s4 = [];
          s5 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 44) {
            s6 = peg$c0;
            peg$currPos++;
          } else {
            s6 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1); }
          }
          if (s6 === peg$FAILED) {
            s6 = peg$currPos;
            s7 = peg$parse_();
            if (s7 !== peg$FAILED) {
              if (input.substr(peg$currPos, 2) === peg$c61) {
                s8 = peg$c61;
                peg$currPos += 2;
              } else {
                s8 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c62); }
              }
              if (s8 !== peg$FAILED) {
                s7 = [s7, s8];
                s6 = s7;
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
          }
          if (s6 !== peg$FAILED) {
            s7 = peg$parse_();
            if (s7 !== peg$FAILED) {
              s8 = peg$parseRandomSkillEffectClause();
              if (s8 !== peg$FAILED) {
                s6 = [s6, s7, s8];
                s5 = s6;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
          if (s5 !== peg$FAILED) {
            while (s5 !== peg$FAILED) {
              s4.push(s5);
              s5 = peg$currPos;
              if (input.charCodeAt(peg$currPos) === 44) {
                s6 = peg$c0;
                peg$currPos++;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c1); }
              }
              if (s6 === peg$FAILED) {
                s6 = peg$currPos;
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 2) === peg$c61) {
                    s8 = peg$c61;
                    peg$currPos += 2;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c62); }
                  }
                  if (s8 !== peg$FAILED) {
                    s7 = [s7, s8];
                    s6 = s7;
                  } else {
                    peg$currPos = s6;
                    s6 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s6;
                  s6 = peg$FAILED;
                }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseRandomSkillEffectClause();
                  if (s8 !== peg$FAILED) {
                    s6 = [s6, s7, s8];
                    s5 = s6;
                  } else {
                    peg$currPos = s5;
                    s5 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s5;
                  s5 = peg$FAILED;
                }
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            }
          } else {
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c420(s3, s4);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseRandomSkillEffectClause() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 140 + 72,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseHeal();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 40) {
          s3 = peg$c19;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c20); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseInteger();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c56) {
              s5 = peg$c56;
              peg$currPos += 2;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c57); }
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c421(s1, s4);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatusClause() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 140 + 73,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parse_();
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      s3 = peg$parseDuration();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s2;
        s3 = peg$c422(s3);
      }
      s2 = s3;
      if (s2 === peg$FAILED) {
        s2 = peg$currPos;
        s3 = peg$parseWho();
        if (s3 !== peg$FAILED) {
          s4 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 44) {
            s5 = peg$c0;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1); }
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            if (s6 !== peg$FAILED) {
              s5 = [s5, s6];
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 === peg$FAILED) {
            s4 = peg$currPos;
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 3) === peg$c8) {
                s6 = peg$c8;
                peg$currPos += 3;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c9); }
              }
              if (s6 !== peg$FAILED) {
                s5 = [s5, s6];
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseCharacterNameAndList();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 18) === peg$c423) {
                  s7 = peg$c423;
                  peg$currPos += 18;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c424); }
                }
                if (s7 === peg$FAILED) {
                  s7 = null;
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s2;
                  s3 = peg$c425(s3, s6);
                  s2 = s3;
                } else {
                  peg$currPos = s2;
                  s2 = peg$FAILED;
                }
              } else {
                peg$currPos = s2;
                s2 = peg$FAILED;
              }
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 === peg$FAILED) {
          s2 = peg$currPos;
          s3 = peg$parseWhoList();
          if (s3 !== peg$FAILED) {
            peg$savedPos = s2;
            s3 = peg$c426(s3);
          }
          s2 = s3;
          if (s2 === peg$FAILED) {
            s2 = peg$currPos;
            s3 = peg$parseWho();
            if (s3 !== peg$FAILED) {
              peg$savedPos = s2;
              s3 = peg$c426(s3);
            }
            s2 = s3;
            if (s2 === peg$FAILED) {
              s2 = peg$currPos;
              if (input.substr(peg$currPos, 2) === peg$c174) {
                s3 = peg$c174;
                peg$currPos += 2;
              } else {
                s3 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c175); }
              }
              if (s3 !== peg$FAILED) {
                s4 = peg$parse_();
                if (s4 !== peg$FAILED) {
                  s5 = peg$parseCharacterNameAndList();
                  if (s5 !== peg$FAILED) {
                    peg$savedPos = s2;
                    s3 = peg$c427(s5);
                    s2 = s3;
                  } else {
                    peg$currPos = s2;
                    s2 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s2;
                  s2 = peg$FAILED;
                }
              } else {
                peg$currPos = s2;
                s2 = peg$FAILED;
              }
              if (s2 === peg$FAILED) {
                s2 = peg$currPos;
                s3 = peg$parsePerUses();
                if (s3 !== peg$FAILED) {
                  peg$savedPos = s2;
                  s3 = peg$c428(s3);
                }
                s2 = s3;
                if (s2 === peg$FAILED) {
                  s2 = peg$currPos;
                  if (input.substr(peg$currPos, 13) === peg$c429) {
                    s3 = peg$c429;
                    peg$currPos += 13;
                  } else {
                    s3 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c430); }
                  }
                  if (s3 !== peg$FAILED) {
                    peg$savedPos = s2;
                    s3 = peg$c431();
                  }
                  s2 = s3;
                  if (s2 === peg$FAILED) {
                    s2 = peg$currPos;
                    if (input.substr(peg$currPos, 10) === peg$c432) {
                      s3 = peg$c432;
                      peg$currPos += 10;
                    } else {
                      s3 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c433); }
                    }
                    if (s3 !== peg$FAILED) {
                      peg$savedPos = s2;
                      s3 = peg$c434();
                    }
                    s2 = s3;
                    if (s2 === peg$FAILED) {
                      s2 = peg$currPos;
                      s3 = peg$parseCondition();
                      if (s3 !== peg$FAILED) {
                        peg$savedPos = s2;
                        s3 = peg$c435(s3);
                      }
                      s2 = s3;
                      if (s2 === peg$FAILED) {
                        s2 = peg$currPos;
                        s3 = peg$parseConditionDetail();
                        if (s3 !== peg$FAILED) {
                          peg$savedPos = s2;
                          s3 = peg$c436();
                        }
                        s2 = s3;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c437(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatusList() {
    var s0, s1, s2, s3, s4, s5, s6;

    var key    = peg$currPos * 140 + 74,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseStatusWithPercent();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$currPos;
      peg$silentFails++;
      s5 = peg$parseNextClause();
      peg$silentFails--;
      if (s5 === peg$FAILED) {
        s4 = void 0;
      } else {
        peg$currPos = s4;
        s4 = peg$FAILED;
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parseStatusListConjunction();
        if (s5 !== peg$FAILED) {
          s6 = peg$parseStatusWithPercent();
          if (s6 !== peg$FAILED) {
            peg$savedPos = s3;
            s4 = peg$c438(s1, s5, s6);
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$currPos;
        peg$silentFails++;
        s5 = peg$parseNextClause();
        peg$silentFails--;
        if (s5 === peg$FAILED) {
          s4 = void 0;
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseStatusListConjunction();
          if (s5 !== peg$FAILED) {
            s6 = peg$parseStatusWithPercent();
            if (s6 !== peg$FAILED) {
              peg$savedPos = s3;
              s4 = peg$c438(s1, s5, s6);
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c439(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatusWithPercent() {
    var s0, s1, s2, s3, s4, s5, s6;

    var key    = peg$currPos * 140 + 75,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseStatusItem();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 40) {
          s4 = peg$c19;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c20); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseInteger();
          if (s5 !== peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c56) {
              s6 = peg$c56;
              peg$currPos += 2;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c57); }
            }
            if (s6 !== peg$FAILED) {
              peg$savedPos = s3;
              s4 = peg$c440(s1, s5);
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseDuration();
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c441(s1, s3, s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatusLevel() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 140 + 76,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseGenericName();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 5) === peg$c372) {
          s3 = peg$c372;
          peg$currPos += 5;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c373); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseInteger();
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c443(s1, s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseGenericName();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        if (s2 !== peg$FAILED) {
          if (input.substr(peg$currPos, 5) === peg$c372) {
            s3 = peg$c372;
            peg$currPos += 5;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c373); }
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            if (s4 !== peg$FAILED) {
              s5 = peg$parseSignedInteger();
              if (s5 !== peg$FAILED) {
                s6 = peg$parse_();
                if (s6 !== peg$FAILED) {
                  s7 = peg$parseStatusLevelMax();
                  if (s7 === peg$FAILED) {
                    s7 = null;
                  }
                  if (s7 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c444(s1, s5, s7);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseSignedInteger();
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          if (s2 !== peg$FAILED) {
            s3 = peg$parseGenericName();
            if (s3 !== peg$FAILED) {
              s4 = peg$parse_();
              if (s4 !== peg$FAILED) {
                s5 = peg$parseStatusLevelMax();
                if (s5 === peg$FAILED) {
                  s5 = null;
                }
                if (s5 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c445(s1, s3, s5);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parseGenericName();
          if (s1 !== peg$FAILED) {
            peg$savedPos = peg$currPos;
            s2 = peg$c446(s1);
            if (s2) {
              s2 = void 0;
            } else {
              s2 = peg$FAILED;
            }
            if (s2 !== peg$FAILED) {
              s3 = peg$parse_();
              if (s3 !== peg$FAILED) {
                s4 = peg$parseStatusLevelMax();
                if (s4 === peg$FAILED) {
                  s4 = null;
                }
                if (s4 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c447(s1, s4);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = peg$parseGenericName();
            if (s1 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c448(s1);
            }
            s0 = s1;
          }
        }
      }
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c442); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatusLevelMax() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 140 + 77,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4) === peg$c343) {
      s1 = peg$c343;
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c344); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseInteger();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 41) {
              s5 = peg$c21;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c22); }
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c449(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStandardStatus() {
    var s0, s1;

    var key    = peg$currPos * 140 + 78,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseStatusName();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c450(s1);
    }
    s0 = s1;

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatusItem() {
    var s0;

    var key    = peg$currPos * 140 + 79,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$parseSmartEtherStatus();
    if (s0 === peg$FAILED) {
      s0 = peg$parseStatusLevel();
      if (s0 === peg$FAILED) {
        s0 = peg$parseStandardStatus();
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseCondition() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17, s18, s19;

    var key    = peg$currPos * 140 + 80,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4) === peg$c451) {
      s1 = peg$c451;
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c452); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 9) === peg$c453) {
          s3 = peg$c453;
          peg$currPos += 9;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c454); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 97) {
              s6 = peg$c155;
              peg$currPos++;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c156); }
            }
            if (s6 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 110) {
                s7 = peg$c455;
                peg$currPos++;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c456); }
              }
              if (s7 === peg$FAILED) {
                s7 = null;
              }
              if (s7 !== peg$FAILED) {
                peg$savedPos = s5;
                s6 = peg$c457();
                s5 = s6;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = [];
                if (peg$c458.test(input.charAt(peg$currPos))) {
                  s8 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s8 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c459); }
                }
                if (s8 !== peg$FAILED) {
                  while (s8 !== peg$FAILED) {
                    s7.push(s8);
                    if (peg$c458.test(input.charAt(peg$currPos))) {
                      s8 = input.charAt(peg$currPos);
                      peg$currPos++;
                    } else {
                      s8 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c459); }
                    }
                  }
                } else {
                  s7 = peg$FAILED;
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c460(s5, s7);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 12) === peg$c461) {
        s1 = peg$c461;
        peg$currPos += 12;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c462); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseGenericName();
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            if (s4 !== peg$FAILED) {
              if (input.substr(peg$currPos, 5) === peg$c372) {
                s5 = peg$c372;
                peg$currPos += 5;
              } else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c373); }
              }
              if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c463(s3);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 2) === peg$c148) {
          s1 = peg$c148;
          peg$currPos += 2;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c149); }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          if (s2 !== peg$FAILED) {
            s3 = peg$parseGenericName();
            if (s3 !== peg$FAILED) {
              s4 = peg$parse_();
              if (s4 !== peg$FAILED) {
                if (input.substr(peg$currPos, 6) === peg$c464) {
                  s5 = peg$c464;
                  peg$currPos += 6;
                } else {
                  s5 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c465); }
                }
                if (s5 !== peg$FAILED) {
                  s6 = peg$parse_();
                  if (s6 !== peg$FAILED) {
                    s7 = peg$parseIntegerAndList();
                    if (s7 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c466(s3, s7);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.substr(peg$currPos, 2) === peg$c148) {
            s1 = peg$c148;
            peg$currPos += 2;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c149); }
          }
          if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            if (s2 !== peg$FAILED) {
              s3 = peg$parseGenericName();
              if (s3 !== peg$FAILED) {
                s4 = peg$parse_();
                if (s4 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 5) === peg$c372) {
                    s5 = peg$c372;
                    peg$currPos += 5;
                  } else {
                    s5 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c373); }
                  }
                  if (s5 !== peg$FAILED) {
                    s6 = peg$parse_();
                    if (s6 !== peg$FAILED) {
                      s7 = peg$parseIntegerSlashList();
                      if (s7 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c466(s3, s7);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            if (input.substr(peg$currPos, 2) === peg$c203) {
              s1 = peg$c203;
              peg$currPos += 2;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c204); }
            }
            if (s1 !== peg$FAILED) {
              s2 = peg$parse_();
              if (s2 !== peg$FAILED) {
                if (input.substr(peg$currPos, 3) === peg$c186) {
                  s3 = peg$c186;
                  peg$currPos += 3;
                } else {
                  s3 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c187); }
                }
                if (s3 === peg$FAILED) {
                  s3 = null;
                }
                if (s3 !== peg$FAILED) {
                  s4 = peg$parse_();
                  if (s4 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 4) === peg$c467) {
                      s5 = peg$c467;
                      peg$currPos += 4;
                    } else {
                      s5 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c468); }
                    }
                    if (s5 !== peg$FAILED) {
                      s6 = peg$parse_();
                      if (s6 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 3) === peg$c469) {
                          s7 = peg$c469;
                          peg$currPos += 3;
                        } else {
                          s7 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c470); }
                        }
                        if (s7 !== peg$FAILED) {
                          s8 = peg$parse_();
                          if (s8 !== peg$FAILED) {
                            s9 = peg$parseGenericName();
                            if (s9 !== peg$FAILED) {
                              s10 = peg$parse_();
                              if (s10 !== peg$FAILED) {
                                if (input.substr(peg$currPos, 5) === peg$c372) {
                                  s11 = peg$c372;
                                  peg$currPos += 5;
                                } else {
                                  s11 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c373); }
                                }
                                if (s11 !== peg$FAILED) {
                                  s12 = peg$parse_();
                                  if (s12 !== peg$FAILED) {
                                    s13 = peg$parseIntegerSlashList();
                                    if (s13 !== peg$FAILED) {
                                      if (input.charCodeAt(peg$currPos) === 43) {
                                        s14 = peg$c471;
                                        peg$currPos++;
                                      } else {
                                        s14 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c472); }
                                      }
                                      if (s14 === peg$FAILED) {
                                        s14 = null;
                                      }
                                      if (s14 !== peg$FAILED) {
                                        peg$savedPos = s0;
                                        s1 = peg$c473(s9, s13, s14);
                                        s0 = s1;
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              if (input.substr(peg$currPos, 2) === peg$c203) {
                s1 = peg$c203;
                peg$currPos += 2;
              } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c204); }
              }
              if (s1 !== peg$FAILED) {
                s2 = peg$parse_();
                if (s2 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 3) === peg$c186) {
                    s3 = peg$c186;
                    peg$currPos += 3;
                  } else {
                    s3 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c187); }
                  }
                  if (s3 === peg$FAILED) {
                    s3 = null;
                  }
                  if (s3 !== peg$FAILED) {
                    s4 = peg$parse_();
                    if (s4 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 4) === peg$c467) {
                        s5 = peg$c467;
                        peg$currPos += 4;
                      } else {
                        s5 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c468); }
                      }
                      if (s5 !== peg$FAILED) {
                        s6 = peg$parse_();
                        if (s6 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 3) === peg$c469) {
                            s7 = peg$c469;
                            peg$currPos += 3;
                          } else {
                            s7 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c470); }
                          }
                          if (s7 !== peg$FAILED) {
                            s8 = peg$parse_();
                            if (s8 !== peg$FAILED) {
                              s9 = peg$parseGenericName();
                              if (s9 !== peg$FAILED) {
                                s10 = peg$parse_();
                                if (s10 !== peg$FAILED) {
                                  if (input.charCodeAt(peg$currPos) === 61) {
                                    s11 = peg$c474;
                                    peg$currPos++;
                                  } else {
                                    s11 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c475); }
                                  }
                                  if (s11 !== peg$FAILED) {
                                    s12 = peg$parse_();
                                    if (s12 !== peg$FAILED) {
                                      s13 = peg$parseInteger();
                                      if (s13 !== peg$FAILED) {
                                        peg$savedPos = s0;
                                        s1 = peg$c466(s9, s13);
                                        s0 = s1;
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
              if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (input.substr(peg$currPos, 2) === peg$c203) {
                  s1 = peg$c203;
                  peg$currPos += 2;
                } else {
                  s1 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c204); }
                }
                if (s1 !== peg$FAILED) {
                  s2 = peg$parse_();
                  if (s2 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 3) === peg$c186) {
                      s3 = peg$c186;
                      peg$currPos += 3;
                    } else {
                      s3 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c187); }
                    }
                    if (s3 === peg$FAILED) {
                      s3 = null;
                    }
                    if (s3 !== peg$FAILED) {
                      s4 = peg$parse_();
                      if (s4 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 4) === peg$c467) {
                          s5 = peg$c467;
                          peg$currPos += 4;
                        } else {
                          s5 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c468); }
                        }
                        if (s5 !== peg$FAILED) {
                          s6 = peg$parse_();
                          if (s6 !== peg$FAILED) {
                            if (input.substr(peg$currPos, 3) === peg$c469) {
                              s7 = peg$c469;
                              peg$currPos += 3;
                            } else {
                              s7 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c470); }
                            }
                            if (s7 !== peg$FAILED) {
                              s8 = peg$parse_();
                              if (s8 !== peg$FAILED) {
                                s9 = peg$parseGenericName();
                                if (s9 !== peg$FAILED) {
                                  s10 = peg$parse_();
                                  if (s10 !== peg$FAILED) {
                                    if (input.substr(peg$currPos, 5) === peg$c372) {
                                      s11 = peg$c372;
                                      peg$currPos += 5;
                                    } else {
                                      s11 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c373); }
                                    }
                                    if (s11 === peg$FAILED) {
                                      s11 = null;
                                    }
                                    if (s11 !== peg$FAILED) {
                                      s12 = peg$parse_();
                                      if (s12 !== peg$FAILED) {
                                        if (input.charCodeAt(peg$currPos) === 62) {
                                          s13 = peg$c476;
                                          peg$currPos++;
                                        } else {
                                          s13 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c477); }
                                        }
                                        if (s13 !== peg$FAILED) {
                                          s14 = peg$parse_();
                                          if (s14 !== peg$FAILED) {
                                            s15 = peg$parseInteger();
                                            if (s15 !== peg$FAILED) {
                                              peg$savedPos = s0;
                                              s1 = peg$c478(s9, s15);
                                              s0 = s1;
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s0;
                                          s0 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                  s0 = peg$currPos;
                  if (input.substr(peg$currPos, 2) === peg$c203) {
                    s1 = peg$c203;
                    peg$currPos += 2;
                  } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c204); }
                  }
                  if (s1 !== peg$FAILED) {
                    s2 = peg$parse_();
                    if (s2 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 3) === peg$c186) {
                        s3 = peg$c186;
                        peg$currPos += 3;
                      } else {
                        s3 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c187); }
                      }
                      if (s3 === peg$FAILED) {
                        s3 = null;
                      }
                      if (s3 !== peg$FAILED) {
                        s4 = peg$parse_();
                        if (s4 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 4) === peg$c467) {
                            s5 = peg$c467;
                            peg$currPos += 4;
                          } else {
                            s5 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c468); }
                          }
                          if (s5 !== peg$FAILED) {
                            s6 = peg$parse_();
                            if (s6 !== peg$FAILED) {
                              if (input.substr(peg$currPos, 3) === peg$c469) {
                                s7 = peg$c469;
                                peg$currPos += 3;
                              } else {
                                s7 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c470); }
                              }
                              if (s7 !== peg$FAILED) {
                                s8 = peg$parse_();
                                if (s8 !== peg$FAILED) {
                                  if (input.substr(peg$currPos, 2) === peg$c148) {
                                    s9 = peg$c148;
                                    peg$currPos += 2;
                                  } else {
                                    s9 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c149); }
                                  }
                                  if (s9 !== peg$FAILED) {
                                    s10 = peg$parse_();
                                    if (s10 !== peg$FAILED) {
                                      if (input.substr(peg$currPos, 5) === peg$c479) {
                                        s11 = peg$c479;
                                        peg$currPos += 5;
                                      } else {
                                        s11 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c480); }
                                      }
                                      if (s11 !== peg$FAILED) {
                                        s12 = peg$parse_();
                                        if (s12 !== peg$FAILED) {
                                          s13 = peg$parseInteger();
                                          if (s13 !== peg$FAILED) {
                                            s14 = peg$parse_();
                                            if (s14 !== peg$FAILED) {
                                              s15 = peg$parseGenericName();
                                              if (s15 !== peg$FAILED) {
                                                peg$savedPos = s0;
                                                s1 = peg$c481(s13, s15);
                                                s0 = s1;
                                              } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s0;
                                          s0 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                  if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    s1 = peg$currPos;
                    if (input.substr(peg$currPos, 2) === peg$c203) {
                      s2 = peg$c203;
                      peg$currPos += 2;
                    } else {
                      s2 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c204); }
                    }
                    if (s2 !== peg$FAILED) {
                      s3 = peg$parse_();
                      if (s3 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 3) === peg$c186) {
                          s4 = peg$c186;
                          peg$currPos += 3;
                        } else {
                          s4 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c187); }
                        }
                        if (s4 !== peg$FAILED) {
                          s5 = peg$parse_();
                          if (s5 !== peg$FAILED) {
                            if (input.substr(peg$currPos, 4) === peg$c467) {
                              s6 = peg$c467;
                              peg$currPos += 4;
                            } else {
                              s6 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c468); }
                            }
                            if (s6 !== peg$FAILED) {
                              s7 = peg$parse_();
                              if (s7 !== peg$FAILED) {
                                if (input.substr(peg$currPos, 3) === peg$c469) {
                                  s8 = peg$c469;
                                  peg$currPos += 3;
                                } else {
                                  s8 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c470); }
                                }
                                if (s8 !== peg$FAILED) {
                                  s9 = peg$parse_();
                                  if (s9 !== peg$FAILED) {
                                    if (input.substr(peg$currPos, 3) === peg$c482) {
                                      s10 = peg$c482;
                                      peg$currPos += 3;
                                    } else {
                                      s10 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c483); }
                                    }
                                    if (s10 !== peg$FAILED) {
                                      s11 = peg$parse_();
                                      if (s11 !== peg$FAILED) {
                                        if (input.substr(peg$currPos, 6) === peg$c114) {
                                          s12 = peg$c114;
                                          peg$currPos += 6;
                                        } else {
                                          s12 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c115); }
                                        }
                                        if (s12 === peg$FAILED) {
                                          if (input.substr(peg$currPos, 4) === peg$c116) {
                                            s12 = peg$c116;
                                            peg$currPos += 4;
                                          } else {
                                            s12 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c117); }
                                          }
                                        }
                                        if (s12 !== peg$FAILED) {
                                          s2 = [s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12];
                                          s1 = s2;
                                        } else {
                                          peg$currPos = s1;
                                          s1 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s1;
                                        s1 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s1;
                                      s1 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s1;
                                    s1 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s1;
                                  s1 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s1;
                                s1 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s1;
                              s1 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s1;
                            s1 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s1;
                          s1 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s1;
                        s1 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s1;
                      s1 = peg$FAILED;
                    }
                    if (s1 === peg$FAILED) {
                      s1 = peg$currPos;
                      if (input.substr(peg$currPos, 4) === peg$c90) {
                        s2 = peg$c90;
                        peg$currPos += 4;
                      } else {
                        s2 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c91); }
                      }
                      if (s2 !== peg$FAILED) {
                        s3 = peg$parse_();
                        if (s3 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 3) === peg$c482) {
                            s4 = peg$c482;
                            peg$currPos += 3;
                          } else {
                            s4 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c483); }
                          }
                          if (s4 !== peg$FAILED) {
                            s5 = peg$parse_();
                            if (s5 !== peg$FAILED) {
                              if (input.substr(peg$currPos, 6) === peg$c114) {
                                s6 = peg$c114;
                                peg$currPos += 6;
                              } else {
                                s6 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c115); }
                              }
                              if (s6 === peg$FAILED) {
                                if (input.substr(peg$currPos, 4) === peg$c116) {
                                  s6 = peg$c116;
                                  peg$currPos += 4;
                                } else {
                                  s6 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c117); }
                                }
                              }
                              if (s6 !== peg$FAILED) {
                                s2 = [s2, s3, s4, s5, s6];
                                s1 = s2;
                              } else {
                                peg$currPos = s1;
                                s1 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s1;
                              s1 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s1;
                            s1 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s1;
                          s1 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s1;
                        s1 = peg$FAILED;
                      }
                    }
                    if (s1 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c484();
                    }
                    s0 = s1;
                    if (s0 === peg$FAILED) {
                      s0 = peg$currPos;
                      if (input.substr(peg$currPos, 2) === peg$c203) {
                        s1 = peg$c203;
                        peg$currPos += 2;
                      } else {
                        s1 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c204); }
                      }
                      if (s1 !== peg$FAILED) {
                        s2 = peg$parse_();
                        if (s2 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 3) === peg$c186) {
                            s3 = peg$c186;
                            peg$currPos += 3;
                          } else {
                            s3 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c187); }
                          }
                          if (s3 === peg$FAILED) {
                            s3 = null;
                          }
                          if (s3 !== peg$FAILED) {
                            s4 = peg$parse_();
                            if (s4 !== peg$FAILED) {
                              if (input.substr(peg$currPos, 4) === peg$c467) {
                                s5 = peg$c467;
                                peg$currPos += 4;
                              } else {
                                s5 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c468); }
                              }
                              if (s5 === peg$FAILED) {
                                if (input.substr(peg$currPos, 6) === peg$c485) {
                                  s5 = peg$c485;
                                  peg$currPos += 6;
                                } else {
                                  s5 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c486); }
                                }
                              }
                              if (s5 !== peg$FAILED) {
                                s6 = peg$parse_();
                                if (s6 !== peg$FAILED) {
                                  if (input.substr(peg$currPos, 3) === peg$c469) {
                                    s7 = peg$c469;
                                    peg$currPos += 3;
                                  } else {
                                    s7 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c470); }
                                  }
                                  if (s7 !== peg$FAILED) {
                                    s8 = peg$parse_();
                                    if (s8 !== peg$FAILED) {
                                      s9 = peg$currPos;
                                      s10 = peg$parseStandardStatus();
                                      if (s10 !== peg$FAILED) {
                                        s11 = [];
                                        s12 = peg$currPos;
                                        if (input.charCodeAt(peg$currPos) === 47) {
                                          s13 = peg$c240;
                                          peg$currPos++;
                                        } else {
                                          s13 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c241); }
                                        }
                                        if (s13 !== peg$FAILED) {
                                          s14 = peg$parseStandardStatus();
                                          if (s14 !== peg$FAILED) {
                                            s13 = [s13, s14];
                                            s12 = s13;
                                          } else {
                                            peg$currPos = s12;
                                            s12 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s12;
                                          s12 = peg$FAILED;
                                        }
                                        if (s12 !== peg$FAILED) {
                                          while (s12 !== peg$FAILED) {
                                            s11.push(s12);
                                            s12 = peg$currPos;
                                            if (input.charCodeAt(peg$currPos) === 47) {
                                              s13 = peg$c240;
                                              peg$currPos++;
                                            } else {
                                              s13 = peg$FAILED;
                                              if (peg$silentFails === 0) { peg$fail(peg$c241); }
                                            }
                                            if (s13 !== peg$FAILED) {
                                              s14 = peg$parseStandardStatus();
                                              if (s14 !== peg$FAILED) {
                                                s13 = [s13, s14];
                                                s12 = s13;
                                              } else {
                                                peg$currPos = s12;
                                                s12 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s12;
                                              s12 = peg$FAILED;
                                            }
                                          }
                                        } else {
                                          s11 = peg$FAILED;
                                        }
                                        if (s11 !== peg$FAILED) {
                                          peg$savedPos = s9;
                                          s10 = peg$c487(s5, s10, s11);
                                          s9 = s10;
                                        } else {
                                          peg$currPos = s9;
                                          s9 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s9;
                                        s9 = peg$FAILED;
                                      }
                                      if (s9 !== peg$FAILED) {
                                        peg$savedPos = s0;
                                        s1 = peg$c488(s5, s9);
                                        s0 = s1;
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                      if (s0 === peg$FAILED) {
                        s0 = peg$currPos;
                        if (input.substr(peg$currPos, 2) === peg$c203) {
                          s1 = peg$c203;
                          peg$currPos += 2;
                        } else {
                          s1 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c204); }
                        }
                        if (s1 !== peg$FAILED) {
                          s2 = peg$parse_();
                          if (s2 !== peg$FAILED) {
                            if (input.substr(peg$currPos, 3) === peg$c186) {
                              s3 = peg$c186;
                              peg$currPos += 3;
                            } else {
                              s3 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c187); }
                            }
                            if (s3 === peg$FAILED) {
                              s3 = null;
                            }
                            if (s3 !== peg$FAILED) {
                              s4 = peg$parse_();
                              if (s4 !== peg$FAILED) {
                                if (input.substr(peg$currPos, 4) === peg$c467) {
                                  s5 = peg$c467;
                                  peg$currPos += 4;
                                } else {
                                  s5 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c468); }
                                }
                                if (s5 === peg$FAILED) {
                                  if (input.substr(peg$currPos, 6) === peg$c485) {
                                    s5 = peg$c485;
                                    peg$currPos += 6;
                                  } else {
                                    s5 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c486); }
                                  }
                                }
                                if (s5 !== peg$FAILED) {
                                  s6 = peg$parse_();
                                  if (s6 !== peg$FAILED) {
                                    s7 = peg$parseWithoutWith();
                                    if (s7 !== peg$FAILED) {
                                      s8 = peg$parse_();
                                      if (s8 !== peg$FAILED) {
                                        if (input.substr(peg$currPos, 3) === peg$c482) {
                                          s9 = peg$c482;
                                          peg$currPos += 3;
                                        } else {
                                          s9 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c483); }
                                        }
                                        if (s9 === peg$FAILED) {
                                          s9 = null;
                                        }
                                        if (s9 !== peg$FAILED) {
                                          s10 = peg$parse_();
                                          if (s10 !== peg$FAILED) {
                                            s11 = peg$currPos;
                                            s12 = peg$parseGenericName();
                                            if (s12 !== peg$FAILED) {
                                              s13 = [];
                                              s14 = peg$currPos;
                                              s15 = peg$parseOrList();
                                              if (s15 !== peg$FAILED) {
                                                s16 = peg$parseGenericName();
                                                if (s16 !== peg$FAILED) {
                                                  s15 = [s15, s16];
                                                  s14 = s15;
                                                } else {
                                                  peg$currPos = s14;
                                                  s14 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s14;
                                                s14 = peg$FAILED;
                                              }
                                              while (s14 !== peg$FAILED) {
                                                s13.push(s14);
                                                s14 = peg$currPos;
                                                s15 = peg$parseOrList();
                                                if (s15 !== peg$FAILED) {
                                                  s16 = peg$parseGenericName();
                                                  if (s16 !== peg$FAILED) {
                                                    s15 = [s15, s16];
                                                    s14 = s15;
                                                  } else {
                                                    peg$currPos = s14;
                                                    s14 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s14;
                                                  s14 = peg$FAILED;
                                                }
                                              }
                                              if (s13 !== peg$FAILED) {
                                                peg$savedPos = s11;
                                                s12 = peg$c489(s5, s7, s9, s12, s13);
                                                s11 = s12;
                                              } else {
                                                peg$currPos = s11;
                                                s11 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s11;
                                              s11 = peg$FAILED;
                                            }
                                            if (s11 !== peg$FAILED) {
                                              peg$savedPos = s0;
                                              s1 = peg$c490(s5, s7, s9, s11);
                                              s0 = s1;
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s0;
                                          s0 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                        if (s0 === peg$FAILED) {
                          s0 = peg$currPos;
                          if (input.substr(peg$currPos, 2) === peg$c203) {
                            s1 = peg$c203;
                            peg$currPos += 2;
                          } else {
                            s1 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c204); }
                          }
                          if (s1 !== peg$FAILED) {
                            s2 = peg$parse_();
                            if (s2 !== peg$FAILED) {
                              if (input.substr(peg$currPos, 3) === peg$c186) {
                                s3 = peg$c186;
                                peg$currPos += 3;
                              } else {
                                s3 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c187); }
                              }
                              if (s3 === peg$FAILED) {
                                s3 = null;
                              }
                              if (s3 !== peg$FAILED) {
                                s4 = peg$parse_();
                                if (s4 !== peg$FAILED) {
                                  if (input.substr(peg$currPos, 4) === peg$c467) {
                                    s5 = peg$c467;
                                    peg$currPos += 4;
                                  } else {
                                    s5 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c468); }
                                  }
                                  if (s5 === peg$FAILED) {
                                    if (input.substr(peg$currPos, 6) === peg$c485) {
                                      s5 = peg$c485;
                                      peg$currPos += 6;
                                    } else {
                                      s5 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c486); }
                                    }
                                  }
                                  if (s5 !== peg$FAILED) {
                                    s6 = peg$parse_();
                                    if (s6 !== peg$FAILED) {
                                      s7 = peg$parseWithoutWith();
                                      if (s7 !== peg$FAILED) {
                                        s8 = peg$parse_();
                                        if (s8 !== peg$FAILED) {
                                          if (input.substr(peg$currPos, 3) === peg$c482) {
                                            s9 = peg$c482;
                                            peg$currPos += 3;
                                          } else {
                                            s9 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c483); }
                                          }
                                          if (s9 === peg$FAILED) {
                                            s9 = null;
                                          }
                                          if (s9 !== peg$FAILED) {
                                            s10 = peg$parse_();
                                            if (s10 !== peg$FAILED) {
                                              s11 = peg$currPos;
                                              s12 = peg$parseStatusName();
                                              if (s12 !== peg$FAILED) {
                                                s13 = [];
                                                s14 = peg$currPos;
                                                s15 = peg$parseOrList();
                                                if (s15 !== peg$FAILED) {
                                                  s16 = peg$parseStatusName();
                                                  if (s16 !== peg$FAILED) {
                                                    s15 = [s15, s16];
                                                    s14 = s15;
                                                  } else {
                                                    peg$currPos = s14;
                                                    s14 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s14;
                                                  s14 = peg$FAILED;
                                                }
                                                while (s14 !== peg$FAILED) {
                                                  s13.push(s14);
                                                  s14 = peg$currPos;
                                                  s15 = peg$parseOrList();
                                                  if (s15 !== peg$FAILED) {
                                                    s16 = peg$parseStatusName();
                                                    if (s16 !== peg$FAILED) {
                                                      s15 = [s15, s16];
                                                      s14 = s15;
                                                    } else {
                                                      peg$currPos = s14;
                                                      s14 = peg$FAILED;
                                                    }
                                                  } else {
                                                    peg$currPos = s14;
                                                    s14 = peg$FAILED;
                                                  }
                                                }
                                                if (s13 !== peg$FAILED) {
                                                  peg$savedPos = s11;
                                                  s12 = peg$c489(s5, s7, s9, s12, s13);
                                                  s11 = s12;
                                                } else {
                                                  peg$currPos = s11;
                                                  s11 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s11;
                                                s11 = peg$FAILED;
                                              }
                                              if (s11 !== peg$FAILED) {
                                                peg$savedPos = s0;
                                                s1 = peg$c490(s5, s7, s9, s11);
                                                s0 = s1;
                                              } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s0;
                                          s0 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                          if (s0 === peg$FAILED) {
                            s0 = peg$currPos;
                            if (input.substr(peg$currPos, 92) === peg$c491) {
                              s1 = peg$c491;
                              peg$currPos += 92;
                            } else {
                              s1 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c492); }
                            }
                            if (s1 !== peg$FAILED) {
                              s2 = peg$parse_();
                              if (s2 !== peg$FAILED) {
                                s3 = peg$parseElementSlashList();
                                if (s3 !== peg$FAILED) {
                                  s4 = peg$parse_();
                                  if (s4 !== peg$FAILED) {
                                    if (input.substr(peg$currPos, 80) === peg$c493) {
                                      s5 = peg$c493;
                                      peg$currPos += 80;
                                    } else {
                                      s5 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c494); }
                                    }
                                    if (s5 === peg$FAILED) {
                                      if (input.substr(peg$currPos, 168) === peg$c495) {
                                        s5 = peg$c495;
                                        peg$currPos += 168;
                                      } else {
                                        s5 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c496); }
                                      }
                                    }
                                    if (s5 !== peg$FAILED) {
                                      peg$savedPos = s0;
                                      s1 = peg$c497(s3);
                                      s0 = s1;
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                            if (s0 === peg$FAILED) {
                              s0 = peg$currPos;
                              if (input.substr(peg$currPos, 2) === peg$c148) {
                                s1 = peg$c148;
                                peg$currPos += 2;
                              } else {
                                s1 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c149); }
                              }
                              if (s1 === peg$FAILED) {
                                if (input.substr(peg$currPos, 12) === peg$c461) {
                                  s1 = peg$c461;
                                  peg$currPos += 12;
                                } else {
                                  s1 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c462); }
                                }
                                if (s1 === peg$FAILED) {
                                  if (input.substr(peg$currPos, 5) === peg$c498) {
                                    s1 = peg$c498;
                                    peg$currPos += 5;
                                  } else {
                                    s1 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c499); }
                                  }
                                }
                              }
                              if (s1 !== peg$FAILED) {
                                s2 = peg$parse_();
                                if (s2 !== peg$FAILED) {
                                  s3 = peg$parseIntegerSlashList();
                                  if (s3 !== peg$FAILED) {
                                    if (input.charCodeAt(peg$currPos) === 43) {
                                      s4 = peg$c471;
                                      peg$currPos++;
                                    } else {
                                      s4 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c472); }
                                    }
                                    if (s4 === peg$FAILED) {
                                      s4 = null;
                                    }
                                    if (s4 !== peg$FAILED) {
                                      s5 = peg$parse_();
                                      if (s5 !== peg$FAILED) {
                                        if (input.substr(peg$currPos, 4) === peg$c500) {
                                          s6 = peg$c500;
                                          peg$currPos += 4;
                                        } else {
                                          s6 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c501); }
                                        }
                                        if (s6 === peg$FAILED) {
                                          if (input.substr(peg$currPos, 5) === peg$c330) {
                                            s6 = peg$c330;
                                            peg$currPos += 5;
                                          } else {
                                            s6 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c331); }
                                          }
                                        }
                                        if (s6 !== peg$FAILED) {
                                          peg$savedPos = s0;
                                          s1 = peg$c502(s3);
                                          s0 = s1;
                                        } else {
                                          peg$currPos = s0;
                                          s0 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                              if (s0 === peg$FAILED) {
                                s0 = peg$currPos;
                                if (input.substr(peg$currPos, 12) === peg$c461) {
                                  s1 = peg$c461;
                                  peg$currPos += 12;
                                } else {
                                  s1 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c462); }
                                }
                                if (s1 !== peg$FAILED) {
                                  s2 = peg$parse_();
                                  if (s2 !== peg$FAILED) {
                                    if (input.substr(peg$currPos, 4) === peg$c500) {
                                      s3 = peg$c500;
                                      peg$currPos += 4;
                                    } else {
                                      s3 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c501); }
                                    }
                                    if (s3 === peg$FAILED) {
                                      if (input.substr(peg$currPos, 11) === peg$c503) {
                                        s3 = peg$c503;
                                        peg$currPos += 11;
                                      } else {
                                        s3 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c504); }
                                      }
                                    }
                                    if (s3 !== peg$FAILED) {
                                      peg$savedPos = s0;
                                      s1 = peg$c505();
                                      s0 = s1;
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                                if (s0 === peg$FAILED) {
                                  s0 = peg$currPos;
                                  if (input.substr(peg$currPos, 7) === peg$c88) {
                                    s1 = peg$c88;
                                    peg$currPos += 7;
                                  } else {
                                    s1 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c89); }
                                  }
                                  if (s1 === peg$FAILED) {
                                    if (input.substr(peg$currPos, 5) === peg$c506) {
                                      s1 = peg$c506;
                                      peg$currPos += 5;
                                    } else {
                                      s1 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c507); }
                                    }
                                  }
                                  if (s1 !== peg$FAILED) {
                                    s2 = peg$parse_();
                                    if (s2 !== peg$FAILED) {
                                      if (input.substr(peg$currPos, 4) === peg$c90) {
                                        s3 = peg$c90;
                                        peg$currPos += 4;
                                      } else {
                                        s3 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c91); }
                                      }
                                      if (s3 !== peg$FAILED) {
                                        s4 = peg$parse_();
                                        if (s4 !== peg$FAILED) {
                                          s5 = peg$parseAnySkillName();
                                          if (s5 !== peg$FAILED) {
                                            s6 = peg$parse_();
                                            if (s6 !== peg$FAILED) {
                                              if (input.substr(peg$currPos, 4) === peg$c500) {
                                                s7 = peg$c500;
                                                peg$currPos += 4;
                                              } else {
                                                s7 = peg$FAILED;
                                                if (peg$silentFails === 0) { peg$fail(peg$c501); }
                                              }
                                              if (s7 !== peg$FAILED) {
                                                peg$savedPos = s0;
                                                s1 = peg$c508(s5);
                                                s0 = s1;
                                              } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s0;
                                          s0 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                  if (s0 === peg$FAILED) {
                                    s0 = peg$currPos;
                                    if (input.substr(peg$currPos, 5) === peg$c498) {
                                      s1 = peg$c498;
                                      peg$currPos += 5;
                                    } else {
                                      s1 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c499); }
                                    }
                                    if (s1 === peg$FAILED) {
                                      if (input.substr(peg$currPos, 5) === peg$c247) {
                                        s1 = peg$c247;
                                        peg$currPos += 5;
                                      } else {
                                        s1 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c248); }
                                      }
                                    }
                                    if (s1 !== peg$FAILED) {
                                      s2 = peg$parse_();
                                      if (s2 !== peg$FAILED) {
                                        s3 = peg$parseUseCount();
                                        if (s3 !== peg$FAILED) {
                                          s4 = peg$parse_();
                                          if (s4 !== peg$FAILED) {
                                            s5 = peg$parseAnySkillName();
                                            if (s5 === peg$FAILED) {
                                              s5 = null;
                                            }
                                            if (s5 !== peg$FAILED) {
                                              s6 = peg$parse_();
                                              if (s6 !== peg$FAILED) {
                                                if (input.substr(peg$currPos, 4) === peg$c500) {
                                                  s7 = peg$c500;
                                                  peg$currPos += 4;
                                                } else {
                                                  s7 = peg$FAILED;
                                                  if (peg$silentFails === 0) { peg$fail(peg$c501); }
                                                }
                                                if (s7 === peg$FAILED) {
                                                  if (input.substr(peg$currPos, 11) === peg$c503) {
                                                    s7 = peg$c503;
                                                    peg$currPos += 11;
                                                  } else {
                                                    s7 = peg$FAILED;
                                                    if (peg$silentFails === 0) { peg$fail(peg$c504); }
                                                  }
                                                }
                                                if (s7 !== peg$FAILED) {
                                                  peg$savedPos = s0;
                                                  s1 = peg$c509(s3, s5);
                                                  s0 = s1;
                                                } else {
                                                  peg$currPos = s0;
                                                  s0 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s0;
                                          s0 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s0;
                                      s0 = peg$FAILED;
                                    }
                                    if (s0 === peg$FAILED) {
                                      s0 = peg$currPos;
                                      if (input.substr(peg$currPos, 5) === peg$c498) {
                                        s1 = peg$c498;
                                        peg$currPos += 5;
                                      } else {
                                        s1 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c499); }
                                      }
                                      if (s1 !== peg$FAILED) {
                                        s2 = peg$parse_();
                                        if (s2 !== peg$FAILED) {
                                          s3 = peg$parseNumberString();
                                          if (s3 !== peg$FAILED) {
                                            s4 = peg$parse_();
                                            if (s4 !== peg$FAILED) {
                                              if (input.substr(peg$currPos, 4) === peg$c500) {
                                                s5 = peg$c500;
                                                peg$currPos += 4;
                                              } else {
                                                s5 = peg$FAILED;
                                                if (peg$silentFails === 0) { peg$fail(peg$c501); }
                                              }
                                              if (s5 !== peg$FAILED) {
                                                peg$savedPos = s0;
                                                s1 = peg$c510(s3);
                                                s0 = s1;
                                              } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s0;
                                          s0 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                      }
                                      if (s0 === peg$FAILED) {
                                        s0 = peg$currPos;
                                        if (input.substr(peg$currPos, 2) === peg$c511) {
                                          s1 = peg$c511;
                                          peg$currPos += 2;
                                        } else {
                                          s1 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c512); }
                                        }
                                        if (s1 !== peg$FAILED) {
                                          s2 = peg$parse_();
                                          if (s2 !== peg$FAILED) {
                                            if (input.substr(peg$currPos, 5) === peg$c513) {
                                              s3 = peg$c513;
                                              peg$currPos += 5;
                                            } else {
                                              s3 = peg$FAILED;
                                              if (peg$silentFails === 0) { peg$fail(peg$c514); }
                                            }
                                            if (s3 !== peg$FAILED) {
                                              s4 = peg$parse_();
                                              if (s4 !== peg$FAILED) {
                                                if (input.substr(peg$currPos, 3) === peg$c325) {
                                                  s5 = peg$c325;
                                                  peg$currPos += 3;
                                                } else {
                                                  s5 = peg$FAILED;
                                                  if (peg$silentFails === 0) { peg$fail(peg$c326); }
                                                }
                                                if (s5 !== peg$FAILED) {
                                                  peg$savedPos = s0;
                                                  s1 = peg$c515();
                                                  s0 = s1;
                                                } else {
                                                  peg$currPos = s0;
                                                  s0 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s0;
                                          s0 = peg$FAILED;
                                        }
                                        if (s0 === peg$FAILED) {
                                          s0 = peg$currPos;
                                          if (input.substr(peg$currPos, 2) === peg$c511) {
                                            s1 = peg$c511;
                                            peg$currPos += 2;
                                          } else {
                                            s1 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c512); }
                                          }
                                          if (s1 !== peg$FAILED) {
                                            s2 = peg$parse_();
                                            if (s2 !== peg$FAILED) {
                                              s3 = peg$parseInteger();
                                              if (s3 !== peg$FAILED) {
                                                if (input.charCodeAt(peg$currPos) === 43) {
                                                  s4 = peg$c471;
                                                  peg$currPos++;
                                                } else {
                                                  s4 = peg$FAILED;
                                                  if (peg$silentFails === 0) { peg$fail(peg$c472); }
                                                }
                                                if (s4 !== peg$FAILED) {
                                                  s5 = peg$parse_();
                                                  if (s5 !== peg$FAILED) {
                                                    if (input.substr(peg$currPos, 3) === peg$c325) {
                                                      s6 = peg$c325;
                                                      peg$currPos += 3;
                                                    } else {
                                                      s6 = peg$FAILED;
                                                      if (peg$silentFails === 0) { peg$fail(peg$c326); }
                                                    }
                                                    if (s6 !== peg$FAILED) {
                                                      if (input.charCodeAt(peg$currPos) === 115) {
                                                        s7 = peg$c17;
                                                        peg$currPos++;
                                                      } else {
                                                        s7 = peg$FAILED;
                                                        if (peg$silentFails === 0) { peg$fail(peg$c18); }
                                                      }
                                                      if (s7 === peg$FAILED) {
                                                        s7 = null;
                                                      }
                                                      if (s7 !== peg$FAILED) {
                                                        peg$savedPos = s0;
                                                        s1 = peg$c516(s3);
                                                        s0 = s1;
                                                      } else {
                                                        peg$currPos = s0;
                                                        s0 = peg$FAILED;
                                                      }
                                                    } else {
                                                      peg$currPos = s0;
                                                      s0 = peg$FAILED;
                                                    }
                                                  } else {
                                                    peg$currPos = s0;
                                                    s0 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s0;
                                                  s0 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                          }
                                          if (s0 === peg$FAILED) {
                                            s0 = peg$currPos;
                                            if (input.substr(peg$currPos, 2) === peg$c203) {
                                              s1 = peg$c203;
                                              peg$currPos += 2;
                                            } else {
                                              s1 = peg$FAILED;
                                              if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                            }
                                            if (s1 !== peg$FAILED) {
                                              s2 = peg$parse_();
                                              if (s2 !== peg$FAILED) {
                                                if (input.substr(peg$currPos, 3) === peg$c364) {
                                                  s3 = peg$c364;
                                                  peg$currPos += 3;
                                                } else {
                                                  s3 = peg$FAILED;
                                                  if (peg$silentFails === 0) { peg$fail(peg$c365); }
                                                }
                                                if (s3 !== peg$FAILED) {
                                                  s4 = peg$parse_();
                                                  if (s4 !== peg$FAILED) {
                                                    if (input.substr(peg$currPos, 6) === peg$c517) {
                                                      s5 = peg$c517;
                                                      peg$currPos += 6;
                                                    } else {
                                                      s5 = peg$FAILED;
                                                      if (peg$silentFails === 0) { peg$fail(peg$c518); }
                                                    }
                                                    if (s5 !== peg$FAILED) {
                                                      s6 = peg$parse_();
                                                      if (s6 !== peg$FAILED) {
                                                        if (input.substr(peg$currPos, 3) === peg$c519) {
                                                          s7 = peg$c519;
                                                          peg$currPos += 3;
                                                        } else {
                                                          s7 = peg$FAILED;
                                                          if (peg$silentFails === 0) { peg$fail(peg$c520); }
                                                        }
                                                        if (s7 !== peg$FAILED) {
                                                          s8 = peg$parse_();
                                                          if (s8 !== peg$FAILED) {
                                                            if (input.substr(peg$currPos, 5) === peg$c521) {
                                                              s9 = peg$c521;
                                                              peg$currPos += 5;
                                                            } else {
                                                              s9 = peg$FAILED;
                                                              if (peg$silentFails === 0) { peg$fail(peg$c522); }
                                                            }
                                                            if (s9 !== peg$FAILED) {
                                                              peg$savedPos = s0;
                                                              s1 = peg$c523();
                                                              s0 = s1;
                                                            } else {
                                                              peg$currPos = s0;
                                                              s0 = peg$FAILED;
                                                            }
                                                          } else {
                                                            peg$currPos = s0;
                                                            s0 = peg$FAILED;
                                                          }
                                                        } else {
                                                          peg$currPos = s0;
                                                          s0 = peg$FAILED;
                                                        }
                                                      } else {
                                                        peg$currPos = s0;
                                                        s0 = peg$FAILED;
                                                      }
                                                    } else {
                                                      peg$currPos = s0;
                                                      s0 = peg$FAILED;
                                                    }
                                                  } else {
                                                    peg$currPos = s0;
                                                    s0 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s0;
                                                  s0 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s0;
                                              s0 = peg$FAILED;
                                            }
                                            if (s0 === peg$FAILED) {
                                              s0 = peg$currPos;
                                              if (input.substr(peg$currPos, 2) === peg$c203) {
                                                s1 = peg$c203;
                                                peg$currPos += 2;
                                              } else {
                                                s1 = peg$FAILED;
                                                if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                              }
                                              if (s1 !== peg$FAILED) {
                                                s2 = peg$parse_();
                                                if (s2 !== peg$FAILED) {
                                                  s3 = peg$parseCharacterNameListOrPronoun();
                                                  if (s3 !== peg$FAILED) {
                                                    s4 = peg$parse_();
                                                    if (s4 !== peg$FAILED) {
                                                      if (input.substr(peg$currPos, 2) === peg$c524) {
                                                        s5 = peg$c524;
                                                        peg$currPos += 2;
                                                      } else {
                                                        s5 = peg$FAILED;
                                                        if (peg$silentFails === 0) { peg$fail(peg$c525); }
                                                      }
                                                      if (s5 === peg$FAILED) {
                                                        if (input.substr(peg$currPos, 3) === peg$c519) {
                                                          s5 = peg$c519;
                                                          peg$currPos += 3;
                                                        } else {
                                                          s5 = peg$FAILED;
                                                          if (peg$silentFails === 0) { peg$fail(peg$c520); }
                                                        }
                                                      }
                                                      if (s5 !== peg$FAILED) {
                                                        s6 = peg$parse_();
                                                        if (s6 !== peg$FAILED) {
                                                          if (input.substr(peg$currPos, 5) === peg$c521) {
                                                            s7 = peg$c521;
                                                            peg$currPos += 5;
                                                          } else {
                                                            s7 = peg$FAILED;
                                                            if (peg$silentFails === 0) { peg$fail(peg$c522); }
                                                          }
                                                          if (s7 !== peg$FAILED) {
                                                            peg$savedPos = s0;
                                                            s1 = peg$c526(s3);
                                                            s0 = s1;
                                                          } else {
                                                            peg$currPos = s0;
                                                            s0 = peg$FAILED;
                                                          }
                                                        } else {
                                                          peg$currPos = s0;
                                                          s0 = peg$FAILED;
                                                        }
                                                      } else {
                                                        peg$currPos = s0;
                                                        s0 = peg$FAILED;
                                                      }
                                                    } else {
                                                      peg$currPos = s0;
                                                      s0 = peg$FAILED;
                                                    }
                                                  } else {
                                                    peg$currPos = s0;
                                                    s0 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s0;
                                                  s0 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                              }
                                              if (s0 === peg$FAILED) {
                                                s0 = peg$currPos;
                                                if (input.substr(peg$currPos, 2) === peg$c203) {
                                                  s1 = peg$c203;
                                                  peg$currPos += 2;
                                                } else {
                                                  s1 = peg$FAILED;
                                                  if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                }
                                                if (s1 !== peg$FAILED) {
                                                  s2 = peg$parse_();
                                                  if (s2 !== peg$FAILED) {
                                                    s3 = peg$parseCharacterNameAndList();
                                                    if (s3 !== peg$FAILED) {
                                                      s4 = peg$parse_();
                                                      if (s4 !== peg$FAILED) {
                                                        if (input.substr(peg$currPos, 2) === peg$c524) {
                                                          s5 = peg$c524;
                                                          peg$currPos += 2;
                                                        } else {
                                                          s5 = peg$FAILED;
                                                          if (peg$silentFails === 0) { peg$fail(peg$c525); }
                                                        }
                                                        if (s5 === peg$FAILED) {
                                                          if (input.substr(peg$currPos, 3) === peg$c519) {
                                                            s5 = peg$c519;
                                                            peg$currPos += 3;
                                                          } else {
                                                            s5 = peg$FAILED;
                                                            if (peg$silentFails === 0) { peg$fail(peg$c520); }
                                                          }
                                                        }
                                                        if (s5 !== peg$FAILED) {
                                                          s6 = peg$parse_();
                                                          if (s6 !== peg$FAILED) {
                                                            if (input.substr(peg$currPos, 5) === peg$c521) {
                                                              s7 = peg$c521;
                                                              peg$currPos += 5;
                                                            } else {
                                                              s7 = peg$FAILED;
                                                              if (peg$silentFails === 0) { peg$fail(peg$c522); }
                                                            }
                                                            if (s7 !== peg$FAILED) {
                                                              peg$savedPos = s0;
                                                              s1 = peg$c527(s3);
                                                              s0 = s1;
                                                            } else {
                                                              peg$currPos = s0;
                                                              s0 = peg$FAILED;
                                                            }
                                                          } else {
                                                            peg$currPos = s0;
                                                            s0 = peg$FAILED;
                                                          }
                                                        } else {
                                                          peg$currPos = s0;
                                                          s0 = peg$FAILED;
                                                        }
                                                      } else {
                                                        peg$currPos = s0;
                                                        s0 = peg$FAILED;
                                                      }
                                                    } else {
                                                      peg$currPos = s0;
                                                      s0 = peg$FAILED;
                                                    }
                                                  } else {
                                                    peg$currPos = s0;
                                                    s0 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s0;
                                                  s0 = peg$FAILED;
                                                }
                                                if (s0 === peg$FAILED) {
                                                  s0 = peg$currPos;
                                                  if (input.substr(peg$currPos, 2) === peg$c203) {
                                                    s1 = peg$c203;
                                                    peg$currPos += 2;
                                                  } else {
                                                    s1 = peg$FAILED;
                                                    if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                  }
                                                  if (s1 !== peg$FAILED) {
                                                    s2 = peg$parse_();
                                                    if (s2 !== peg$FAILED) {
                                                      s3 = peg$parseCharacterNameListOrPronoun();
                                                      if (s3 !== peg$FAILED) {
                                                        s4 = peg$parse_();
                                                        if (s4 !== peg$FAILED) {
                                                          if (input.substr(peg$currPos, 2) === peg$c524) {
                                                            s5 = peg$c524;
                                                            peg$currPos += 2;
                                                          } else {
                                                            s5 = peg$FAILED;
                                                            if (peg$silentFails === 0) { peg$fail(peg$c525); }
                                                          }
                                                          if (s5 === peg$FAILED) {
                                                            if (input.substr(peg$currPos, 3) === peg$c519) {
                                                              s5 = peg$c519;
                                                              peg$currPos += 3;
                                                            } else {
                                                              s5 = peg$FAILED;
                                                              if (peg$silentFails === 0) { peg$fail(peg$c520); }
                                                            }
                                                          }
                                                          if (s5 !== peg$FAILED) {
                                                            s6 = peg$parse_();
                                                            if (s6 !== peg$FAILED) {
                                                              if (input.substr(peg$currPos, 15) === peg$c528) {
                                                                s7 = peg$c528;
                                                                peg$currPos += 15;
                                                              } else {
                                                                s7 = peg$FAILED;
                                                                if (peg$silentFails === 0) { peg$fail(peg$c529); }
                                                              }
                                                              if (s7 !== peg$FAILED) {
                                                                peg$savedPos = s0;
                                                                s1 = peg$c530(s3);
                                                                s0 = s1;
                                                              } else {
                                                                peg$currPos = s0;
                                                                s0 = peg$FAILED;
                                                              }
                                                            } else {
                                                              peg$currPos = s0;
                                                              s0 = peg$FAILED;
                                                            }
                                                          } else {
                                                            peg$currPos = s0;
                                                            s0 = peg$FAILED;
                                                          }
                                                        } else {
                                                          peg$currPos = s0;
                                                          s0 = peg$FAILED;
                                                        }
                                                      } else {
                                                        peg$currPos = s0;
                                                        s0 = peg$FAILED;
                                                      }
                                                    } else {
                                                      peg$currPos = s0;
                                                      s0 = peg$FAILED;
                                                    }
                                                  } else {
                                                    peg$currPos = s0;
                                                    s0 = peg$FAILED;
                                                  }
                                                  if (s0 === peg$FAILED) {
                                                    s0 = peg$currPos;
                                                    if (input.substr(peg$currPos, 2) === peg$c203) {
                                                      s1 = peg$c203;
                                                      peg$currPos += 2;
                                                    } else {
                                                      s1 = peg$FAILED;
                                                      if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                    }
                                                    if (s1 !== peg$FAILED) {
                                                      s2 = peg$parse_();
                                                      if (s2 !== peg$FAILED) {
                                                        s3 = peg$parseIntegerSlashList();
                                                        if (s3 !== peg$FAILED) {
                                                          if (input.charCodeAt(peg$currPos) === 43) {
                                                            s4 = peg$c471;
                                                            peg$currPos++;
                                                          } else {
                                                            s4 = peg$FAILED;
                                                            if (peg$silentFails === 0) { peg$fail(peg$c472); }
                                                          }
                                                          if (s4 === peg$FAILED) {
                                                            s4 = null;
                                                          }
                                                          if (s4 !== peg$FAILED) {
                                                            s5 = peg$parse_();
                                                            if (s5 !== peg$FAILED) {
                                                              if (input.substr(peg$currPos, 2) === peg$c184) {
                                                                s6 = peg$c184;
                                                                peg$currPos += 2;
                                                              } else {
                                                                s6 = peg$FAILED;
                                                                if (peg$silentFails === 0) { peg$fail(peg$c185); }
                                                              }
                                                              if (s6 !== peg$FAILED) {
                                                                s7 = peg$parse_();
                                                                if (s7 !== peg$FAILED) {
                                                                  s8 = peg$parseCharacterNameList();
                                                                  if (s8 !== peg$FAILED) {
                                                                    s9 = peg$parse_();
                                                                    if (s9 !== peg$FAILED) {
                                                                      if (input.substr(peg$currPos, 3) === peg$c519) {
                                                                        s10 = peg$c519;
                                                                        peg$currPos += 3;
                                                                      } else {
                                                                        s10 = peg$FAILED;
                                                                        if (peg$silentFails === 0) { peg$fail(peg$c520); }
                                                                      }
                                                                      if (s10 !== peg$FAILED) {
                                                                        s11 = peg$parse_();
                                                                        if (s11 !== peg$FAILED) {
                                                                          if (input.substr(peg$currPos, 5) === peg$c521) {
                                                                            s12 = peg$c521;
                                                                            peg$currPos += 5;
                                                                          } else {
                                                                            s12 = peg$FAILED;
                                                                            if (peg$silentFails === 0) { peg$fail(peg$c522); }
                                                                          }
                                                                          if (s12 !== peg$FAILED) {
                                                                            peg$savedPos = s0;
                                                                            s1 = peg$c531(s3, s8);
                                                                            s0 = s1;
                                                                          } else {
                                                                            peg$currPos = s0;
                                                                            s0 = peg$FAILED;
                                                                          }
                                                                        } else {
                                                                          peg$currPos = s0;
                                                                          s0 = peg$FAILED;
                                                                        }
                                                                      } else {
                                                                        peg$currPos = s0;
                                                                        s0 = peg$FAILED;
                                                                      }
                                                                    } else {
                                                                      peg$currPos = s0;
                                                                      s0 = peg$FAILED;
                                                                    }
                                                                  } else {
                                                                    peg$currPos = s0;
                                                                    s0 = peg$FAILED;
                                                                  }
                                                                } else {
                                                                  peg$currPos = s0;
                                                                  s0 = peg$FAILED;
                                                                }
                                                              } else {
                                                                peg$currPos = s0;
                                                                s0 = peg$FAILED;
                                                              }
                                                            } else {
                                                              peg$currPos = s0;
                                                              s0 = peg$FAILED;
                                                            }
                                                          } else {
                                                            peg$currPos = s0;
                                                            s0 = peg$FAILED;
                                                          }
                                                        } else {
                                                          peg$currPos = s0;
                                                          s0 = peg$FAILED;
                                                        }
                                                      } else {
                                                        peg$currPos = s0;
                                                        s0 = peg$FAILED;
                                                      }
                                                    } else {
                                                      peg$currPos = s0;
                                                      s0 = peg$FAILED;
                                                    }
                                                    if (s0 === peg$FAILED) {
                                                      s0 = peg$currPos;
                                                      if (input.substr(peg$currPos, 2) === peg$c203) {
                                                        s1 = peg$c203;
                                                        peg$currPos += 2;
                                                      } else {
                                                        s1 = peg$FAILED;
                                                        if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                      }
                                                      if (s1 !== peg$FAILED) {
                                                        s2 = peg$parse_();
                                                        if (s2 !== peg$FAILED) {
                                                          s3 = peg$parseCharacterNameAndList();
                                                          if (s3 !== peg$FAILED) {
                                                            s4 = peg$parse_();
                                                            if (s4 !== peg$FAILED) {
                                                              if (input.substr(peg$currPos, 2) === peg$c524) {
                                                                s5 = peg$c524;
                                                                peg$currPos += 2;
                                                              } else {
                                                                s5 = peg$FAILED;
                                                                if (peg$silentFails === 0) { peg$fail(peg$c525); }
                                                              }
                                                              if (s5 === peg$FAILED) {
                                                                if (input.substr(peg$currPos, 3) === peg$c519) {
                                                                  s5 = peg$c519;
                                                                  peg$currPos += 3;
                                                                } else {
                                                                  s5 = peg$FAILED;
                                                                  if (peg$silentFails === 0) { peg$fail(peg$c520); }
                                                                }
                                                              }
                                                              if (s5 !== peg$FAILED) {
                                                                s6 = peg$parse_();
                                                                if (s6 !== peg$FAILED) {
                                                                  if (input.substr(peg$currPos, 9) === peg$c532) {
                                                                    s7 = peg$c532;
                                                                    peg$currPos += 9;
                                                                  } else {
                                                                    s7 = peg$FAILED;
                                                                    if (peg$silentFails === 0) { peg$fail(peg$c533); }
                                                                  }
                                                                  if (s7 !== peg$FAILED) {
                                                                    peg$savedPos = s0;
                                                                    s1 = peg$c534(s3);
                                                                    s0 = s1;
                                                                  } else {
                                                                    peg$currPos = s0;
                                                                    s0 = peg$FAILED;
                                                                  }
                                                                } else {
                                                                  peg$currPos = s0;
                                                                  s0 = peg$FAILED;
                                                                }
                                                              } else {
                                                                peg$currPos = s0;
                                                                s0 = peg$FAILED;
                                                              }
                                                            } else {
                                                              peg$currPos = s0;
                                                              s0 = peg$FAILED;
                                                            }
                                                          } else {
                                                            peg$currPos = s0;
                                                            s0 = peg$FAILED;
                                                          }
                                                        } else {
                                                          peg$currPos = s0;
                                                          s0 = peg$FAILED;
                                                        }
                                                      } else {
                                                        peg$currPos = s0;
                                                        s0 = peg$FAILED;
                                                      }
                                                      if (s0 === peg$FAILED) {
                                                        s0 = peg$currPos;
                                                        if (input.substr(peg$currPos, 2) === peg$c203) {
                                                          s1 = peg$c203;
                                                          peg$currPos += 2;
                                                        } else {
                                                          s1 = peg$FAILED;
                                                          if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                        }
                                                        if (s1 !== peg$FAILED) {
                                                          s2 = peg$parse_();
                                                          if (s2 !== peg$FAILED) {
                                                            s3 = peg$parseIntegerSlashList();
                                                            if (s3 === peg$FAILED) {
                                                              s3 = null;
                                                            }
                                                            if (s3 !== peg$FAILED) {
                                                              s4 = peg$parse_();
                                                              if (s4 !== peg$FAILED) {
                                                                s5 = peg$parseCharacterNameList();
                                                                if (s5 !== peg$FAILED) {
                                                                  s6 = peg$parse_();
                                                                  if (s6 !== peg$FAILED) {
                                                                    if (input.substr(peg$currPos, 2) === peg$c524) {
                                                                      s7 = peg$c524;
                                                                      peg$currPos += 2;
                                                                    } else {
                                                                      s7 = peg$FAILED;
                                                                      if (peg$silentFails === 0) { peg$fail(peg$c525); }
                                                                    }
                                                                    if (s7 === peg$FAILED) {
                                                                      if (input.substr(peg$currPos, 3) === peg$c519) {
                                                                        s7 = peg$c519;
                                                                        peg$currPos += 3;
                                                                      } else {
                                                                        s7 = peg$FAILED;
                                                                        if (peg$silentFails === 0) { peg$fail(peg$c520); }
                                                                      }
                                                                    }
                                                                    if (s7 !== peg$FAILED) {
                                                                      s8 = peg$parse_();
                                                                      if (s8 !== peg$FAILED) {
                                                                        if (input.substr(peg$currPos, 2) === peg$c535) {
                                                                          s9 = peg$c535;
                                                                          peg$currPos += 2;
                                                                        } else {
                                                                          s9 = peg$FAILED;
                                                                          if (peg$silentFails === 0) { peg$fail(peg$c536); }
                                                                        }
                                                                        if (s9 !== peg$FAILED) {
                                                                          s10 = peg$parse_();
                                                                          if (s10 !== peg$FAILED) {
                                                                            if (input.substr(peg$currPos, 3) === peg$c186) {
                                                                              s11 = peg$c186;
                                                                              peg$currPos += 3;
                                                                            } else {
                                                                              s11 = peg$FAILED;
                                                                              if (peg$silentFails === 0) { peg$fail(peg$c187); }
                                                                            }
                                                                            if (s11 !== peg$FAILED) {
                                                                              s12 = peg$parse_();
                                                                              if (s12 !== peg$FAILED) {
                                                                                if (input.substr(peg$currPos, 5) === peg$c537) {
                                                                                  s13 = peg$c537;
                                                                                  peg$currPos += 5;
                                                                                } else {
                                                                                  s13 = peg$FAILED;
                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c538); }
                                                                                }
                                                                                if (s13 !== peg$FAILED) {
                                                                                  peg$savedPos = s0;
                                                                                  s1 = peg$c539(s3, s5);
                                                                                  s0 = s1;
                                                                                } else {
                                                                                  peg$currPos = s0;
                                                                                  s0 = peg$FAILED;
                                                                                }
                                                                              } else {
                                                                                peg$currPos = s0;
                                                                                s0 = peg$FAILED;
                                                                              }
                                                                            } else {
                                                                              peg$currPos = s0;
                                                                              s0 = peg$FAILED;
                                                                            }
                                                                          } else {
                                                                            peg$currPos = s0;
                                                                            s0 = peg$FAILED;
                                                                          }
                                                                        } else {
                                                                          peg$currPos = s0;
                                                                          s0 = peg$FAILED;
                                                                        }
                                                                      } else {
                                                                        peg$currPos = s0;
                                                                        s0 = peg$FAILED;
                                                                      }
                                                                    } else {
                                                                      peg$currPos = s0;
                                                                      s0 = peg$FAILED;
                                                                    }
                                                                  } else {
                                                                    peg$currPos = s0;
                                                                    s0 = peg$FAILED;
                                                                  }
                                                                } else {
                                                                  peg$currPos = s0;
                                                                  s0 = peg$FAILED;
                                                                }
                                                              } else {
                                                                peg$currPos = s0;
                                                                s0 = peg$FAILED;
                                                              }
                                                            } else {
                                                              peg$currPos = s0;
                                                              s0 = peg$FAILED;
                                                            }
                                                          } else {
                                                            peg$currPos = s0;
                                                            s0 = peg$FAILED;
                                                          }
                                                        } else {
                                                          peg$currPos = s0;
                                                          s0 = peg$FAILED;
                                                        }
                                                        if (s0 === peg$FAILED) {
                                                          s0 = peg$currPos;
                                                          if (input.substr(peg$currPos, 2) === peg$c203) {
                                                            s1 = peg$c203;
                                                            peg$currPos += 2;
                                                          } else {
                                                            s1 = peg$FAILED;
                                                            if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                          }
                                                          if (s1 !== peg$FAILED) {
                                                            s2 = peg$parse_();
                                                            if (s2 !== peg$FAILED) {
                                                              s3 = peg$parseIntegerSlashList();
                                                              if (s3 === peg$FAILED) {
                                                                s3 = null;
                                                              }
                                                              if (s3 !== peg$FAILED) {
                                                                s4 = peg$parse_();
                                                                if (s4 !== peg$FAILED) {
                                                                  s5 = peg$parseCharacterNameAndList();
                                                                  if (s5 !== peg$FAILED) {
                                                                    s6 = peg$parse_();
                                                                    if (s6 !== peg$FAILED) {
                                                                      if (input.substr(peg$currPos, 2) === peg$c524) {
                                                                        s7 = peg$c524;
                                                                        peg$currPos += 2;
                                                                      } else {
                                                                        s7 = peg$FAILED;
                                                                        if (peg$silentFails === 0) { peg$fail(peg$c525); }
                                                                      }
                                                                      if (s7 === peg$FAILED) {
                                                                        if (input.substr(peg$currPos, 3) === peg$c519) {
                                                                          s7 = peg$c519;
                                                                          peg$currPos += 3;
                                                                        } else {
                                                                          s7 = peg$FAILED;
                                                                          if (peg$silentFails === 0) { peg$fail(peg$c520); }
                                                                        }
                                                                      }
                                                                      if (s7 !== peg$FAILED) {
                                                                        s8 = peg$parse_();
                                                                        if (s8 !== peg$FAILED) {
                                                                          if (input.substr(peg$currPos, 2) === peg$c535) {
                                                                            s9 = peg$c535;
                                                                            peg$currPos += 2;
                                                                          } else {
                                                                            s9 = peg$FAILED;
                                                                            if (peg$silentFails === 0) { peg$fail(peg$c536); }
                                                                          }
                                                                          if (s9 !== peg$FAILED) {
                                                                            s10 = peg$parse_();
                                                                            if (s10 !== peg$FAILED) {
                                                                              if (input.substr(peg$currPos, 3) === peg$c186) {
                                                                                s11 = peg$c186;
                                                                                peg$currPos += 3;
                                                                              } else {
                                                                                s11 = peg$FAILED;
                                                                                if (peg$silentFails === 0) { peg$fail(peg$c187); }
                                                                              }
                                                                              if (s11 !== peg$FAILED) {
                                                                                s12 = peg$parse_();
                                                                                if (s12 !== peg$FAILED) {
                                                                                  if (input.substr(peg$currPos, 5) === peg$c537) {
                                                                                    s13 = peg$c537;
                                                                                    peg$currPos += 5;
                                                                                  } else {
                                                                                    s13 = peg$FAILED;
                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c538); }
                                                                                  }
                                                                                  if (s13 !== peg$FAILED) {
                                                                                    peg$savedPos = s0;
                                                                                    s1 = peg$c540(s3, s5);
                                                                                    s0 = s1;
                                                                                  } else {
                                                                                    peg$currPos = s0;
                                                                                    s0 = peg$FAILED;
                                                                                  }
                                                                                } else {
                                                                                  peg$currPos = s0;
                                                                                  s0 = peg$FAILED;
                                                                                }
                                                                              } else {
                                                                                peg$currPos = s0;
                                                                                s0 = peg$FAILED;
                                                                              }
                                                                            } else {
                                                                              peg$currPos = s0;
                                                                              s0 = peg$FAILED;
                                                                            }
                                                                          } else {
                                                                            peg$currPos = s0;
                                                                            s0 = peg$FAILED;
                                                                          }
                                                                        } else {
                                                                          peg$currPos = s0;
                                                                          s0 = peg$FAILED;
                                                                        }
                                                                      } else {
                                                                        peg$currPos = s0;
                                                                        s0 = peg$FAILED;
                                                                      }
                                                                    } else {
                                                                      peg$currPos = s0;
                                                                      s0 = peg$FAILED;
                                                                    }
                                                                  } else {
                                                                    peg$currPos = s0;
                                                                    s0 = peg$FAILED;
                                                                  }
                                                                } else {
                                                                  peg$currPos = s0;
                                                                  s0 = peg$FAILED;
                                                                }
                                                              } else {
                                                                peg$currPos = s0;
                                                                s0 = peg$FAILED;
                                                              }
                                                            } else {
                                                              peg$currPos = s0;
                                                              s0 = peg$FAILED;
                                                            }
                                                          } else {
                                                            peg$currPos = s0;
                                                            s0 = peg$FAILED;
                                                          }
                                                          if (s0 === peg$FAILED) {
                                                            s0 = peg$currPos;
                                                            if (input.substr(peg$currPos, 2) === peg$c203) {
                                                              s1 = peg$c203;
                                                              peg$currPos += 2;
                                                            } else {
                                                              s1 = peg$FAILED;
                                                              if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                            }
                                                            if (s1 !== peg$FAILED) {
                                                              s2 = peg$parse_();
                                                              if (s2 !== peg$FAILED) {
                                                                s3 = peg$parseIntegerSlashList();
                                                                if (s3 !== peg$FAILED) {
                                                                  s4 = peg$parse_();
                                                                  if (s4 !== peg$FAILED) {
                                                                    if (input.substr(peg$currPos, 24) === peg$c541) {
                                                                      s5 = peg$c541;
                                                                      peg$currPos += 24;
                                                                    } else {
                                                                      s5 = peg$FAILED;
                                                                      if (peg$silentFails === 0) { peg$fail(peg$c542); }
                                                                    }
                                                                    if (s5 !== peg$FAILED) {
                                                                      peg$savedPos = s0;
                                                                      s1 = peg$c543(s3);
                                                                      s0 = s1;
                                                                    } else {
                                                                      peg$currPos = s0;
                                                                      s0 = peg$FAILED;
                                                                    }
                                                                  } else {
                                                                    peg$currPos = s0;
                                                                    s0 = peg$FAILED;
                                                                  }
                                                                } else {
                                                                  peg$currPos = s0;
                                                                  s0 = peg$FAILED;
                                                                }
                                                              } else {
                                                                peg$currPos = s0;
                                                                s0 = peg$FAILED;
                                                              }
                                                            } else {
                                                              peg$currPos = s0;
                                                              s0 = peg$FAILED;
                                                            }
                                                            if (s0 === peg$FAILED) {
                                                              s0 = peg$currPos;
                                                              if (input.substr(peg$currPos, 2) === peg$c203) {
                                                                s1 = peg$c203;
                                                                peg$currPos += 2;
                                                              } else {
                                                                s1 = peg$FAILED;
                                                                if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                              }
                                                              if (s1 !== peg$FAILED) {
                                                                s2 = peg$parse_();
                                                                if (s2 !== peg$FAILED) {
                                                                  s3 = peg$parseIntegerSlashList();
                                                                  if (s3 !== peg$FAILED) {
                                                                    if (input.charCodeAt(peg$currPos) === 43) {
                                                                      s4 = peg$c471;
                                                                      peg$currPos++;
                                                                    } else {
                                                                      s4 = peg$FAILED;
                                                                      if (peg$silentFails === 0) { peg$fail(peg$c472); }
                                                                    }
                                                                    if (s4 === peg$FAILED) {
                                                                      s4 = null;
                                                                    }
                                                                    if (s4 !== peg$FAILED) {
                                                                      s5 = peg$parse_();
                                                                      if (s5 !== peg$FAILED) {
                                                                        if (input.substr(peg$currPos, 7) === peg$c544) {
                                                                          s6 = peg$c544;
                                                                          peg$currPos += 7;
                                                                        } else {
                                                                          s6 = peg$FAILED;
                                                                          if (peg$silentFails === 0) { peg$fail(peg$c545); }
                                                                        }
                                                                        if (s6 === peg$FAILED) {
                                                                          if (input.substr(peg$currPos, 13) === peg$c546) {
                                                                            s6 = peg$c546;
                                                                            peg$currPos += 13;
                                                                          } else {
                                                                            s6 = peg$FAILED;
                                                                            if (peg$silentFails === 0) { peg$fail(peg$c547); }
                                                                          }
                                                                        }
                                                                        if (s6 !== peg$FAILED) {
                                                                          s7 = peg$parse_();
                                                                          if (s7 !== peg$FAILED) {
                                                                            if (input.substr(peg$currPos, 9) === peg$c548) {
                                                                              s8 = peg$c548;
                                                                              peg$currPos += 9;
                                                                            } else {
                                                                              s8 = peg$FAILED;
                                                                              if (peg$silentFails === 0) { peg$fail(peg$c549); }
                                                                            }
                                                                            if (s8 !== peg$FAILED) {
                                                                              peg$savedPos = s0;
                                                                              s1 = peg$c550(s3);
                                                                              s0 = s1;
                                                                            } else {
                                                                              peg$currPos = s0;
                                                                              s0 = peg$FAILED;
                                                                            }
                                                                          } else {
                                                                            peg$currPos = s0;
                                                                            s0 = peg$FAILED;
                                                                          }
                                                                        } else {
                                                                          peg$currPos = s0;
                                                                          s0 = peg$FAILED;
                                                                        }
                                                                      } else {
                                                                        peg$currPos = s0;
                                                                        s0 = peg$FAILED;
                                                                      }
                                                                    } else {
                                                                      peg$currPos = s0;
                                                                      s0 = peg$FAILED;
                                                                    }
                                                                  } else {
                                                                    peg$currPos = s0;
                                                                    s0 = peg$FAILED;
                                                                  }
                                                                } else {
                                                                  peg$currPos = s0;
                                                                  s0 = peg$FAILED;
                                                                }
                                                              } else {
                                                                peg$currPos = s0;
                                                                s0 = peg$FAILED;
                                                              }
                                                              if (s0 === peg$FAILED) {
                                                                s0 = peg$currPos;
                                                                if (input.substr(peg$currPos, 2) === peg$c203) {
                                                                  s1 = peg$c203;
                                                                  peg$currPos += 2;
                                                                } else {
                                                                  s1 = peg$FAILED;
                                                                  if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                                }
                                                                if (s1 !== peg$FAILED) {
                                                                  s2 = peg$parse_();
                                                                  if (s2 !== peg$FAILED) {
                                                                    s3 = peg$parseInteger();
                                                                    if (s3 !== peg$FAILED) {
                                                                      s4 = peg$parse_();
                                                                      if (s4 !== peg$FAILED) {
                                                                        if (input.substr(peg$currPos, 31) === peg$c551) {
                                                                          s5 = peg$c551;
                                                                          peg$currPos += 31;
                                                                        } else {
                                                                          s5 = peg$FAILED;
                                                                          if (peg$silentFails === 0) { peg$fail(peg$c552); }
                                                                        }
                                                                        if (s5 !== peg$FAILED) {
                                                                          peg$savedPos = s0;
                                                                          s1 = peg$c553(s3);
                                                                          s0 = s1;
                                                                        } else {
                                                                          peg$currPos = s0;
                                                                          s0 = peg$FAILED;
                                                                        }
                                                                      } else {
                                                                        peg$currPos = s0;
                                                                        s0 = peg$FAILED;
                                                                      }
                                                                    } else {
                                                                      peg$currPos = s0;
                                                                      s0 = peg$FAILED;
                                                                    }
                                                                  } else {
                                                                    peg$currPos = s0;
                                                                    s0 = peg$FAILED;
                                                                  }
                                                                } else {
                                                                  peg$currPos = s0;
                                                                  s0 = peg$FAILED;
                                                                }
                                                                if (s0 === peg$FAILED) {
                                                                  s0 = peg$currPos;
                                                                  if (input.substr(peg$currPos, 2) === peg$c203) {
                                                                    s1 = peg$c203;
                                                                    peg$currPos += 2;
                                                                  } else {
                                                                    s1 = peg$FAILED;
                                                                    if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                                  }
                                                                  if (s1 !== peg$FAILED) {
                                                                    s2 = peg$parse_();
                                                                    if (s2 !== peg$FAILED) {
                                                                      if (input.substr(peg$currPos, 5) === peg$c554) {
                                                                        s3 = peg$c554;
                                                                        peg$currPos += 5;
                                                                      } else {
                                                                        s3 = peg$FAILED;
                                                                        if (peg$silentFails === 0) { peg$fail(peg$c555); }
                                                                      }
                                                                      if (s3 !== peg$FAILED) {
                                                                        s4 = peg$parse_();
                                                                        if (s4 !== peg$FAILED) {
                                                                          if (input.substr(peg$currPos, 3) === peg$c519) {
                                                                            s5 = peg$c519;
                                                                            peg$currPos += 3;
                                                                          } else {
                                                                            s5 = peg$FAILED;
                                                                            if (peg$silentFails === 0) { peg$fail(peg$c520); }
                                                                          }
                                                                          if (s5 !== peg$FAILED) {
                                                                            s6 = peg$parse_();
                                                                            if (s6 !== peg$FAILED) {
                                                                              s7 = peg$parseIntegerSlashList();
                                                                              if (s7 !== peg$FAILED) {
                                                                                if (input.charCodeAt(peg$currPos) === 43) {
                                                                                  s8 = peg$c471;
                                                                                  peg$currPos++;
                                                                                } else {
                                                                                  s8 = peg$FAILED;
                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c472); }
                                                                                }
                                                                                if (s8 === peg$FAILED) {
                                                                                  s8 = null;
                                                                                }
                                                                                if (s8 !== peg$FAILED) {
                                                                                  s9 = peg$parse_();
                                                                                  if (s9 !== peg$FAILED) {
                                                                                    s10 = peg$parseRealm();
                                                                                    if (s10 !== peg$FAILED) {
                                                                                      s11 = peg$parse_();
                                                                                      if (s11 !== peg$FAILED) {
                                                                                        if (input.substr(peg$currPos, 10) === peg$c556) {
                                                                                          s12 = peg$c556;
                                                                                          peg$currPos += 10;
                                                                                        } else {
                                                                                          s12 = peg$FAILED;
                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c557); }
                                                                                        }
                                                                                        if (s12 !== peg$FAILED) {
                                                                                          s13 = peg$parse_();
                                                                                          if (s13 !== peg$FAILED) {
                                                                                            if (input.substr(peg$currPos, 2) === peg$c535) {
                                                                                              s14 = peg$c535;
                                                                                              peg$currPos += 2;
                                                                                            } else {
                                                                                              s14 = peg$FAILED;
                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c536); }
                                                                                            }
                                                                                            if (s14 !== peg$FAILED) {
                                                                                              s15 = peg$parse_();
                                                                                              if (s15 !== peg$FAILED) {
                                                                                                if (input.substr(peg$currPos, 3) === peg$c186) {
                                                                                                  s16 = peg$c186;
                                                                                                  peg$currPos += 3;
                                                                                                } else {
                                                                                                  s16 = peg$FAILED;
                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c187); }
                                                                                                }
                                                                                                if (s16 !== peg$FAILED) {
                                                                                                  s17 = peg$parse_();
                                                                                                  if (s17 !== peg$FAILED) {
                                                                                                    if (input.substr(peg$currPos, 5) === peg$c537) {
                                                                                                      s18 = peg$c537;
                                                                                                      peg$currPos += 5;
                                                                                                    } else {
                                                                                                      s18 = peg$FAILED;
                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c538); }
                                                                                                    }
                                                                                                    if (s18 !== peg$FAILED) {
                                                                                                      peg$savedPos = s0;
                                                                                                      s1 = peg$c558(s7, s10);
                                                                                                      s0 = s1;
                                                                                                    } else {
                                                                                                      peg$currPos = s0;
                                                                                                      s0 = peg$FAILED;
                                                                                                    }
                                                                                                  } else {
                                                                                                    peg$currPos = s0;
                                                                                                    s0 = peg$FAILED;
                                                                                                  }
                                                                                                } else {
                                                                                                  peg$currPos = s0;
                                                                                                  s0 = peg$FAILED;
                                                                                                }
                                                                                              } else {
                                                                                                peg$currPos = s0;
                                                                                                s0 = peg$FAILED;
                                                                                              }
                                                                                            } else {
                                                                                              peg$currPos = s0;
                                                                                              s0 = peg$FAILED;
                                                                                            }
                                                                                          } else {
                                                                                            peg$currPos = s0;
                                                                                            s0 = peg$FAILED;
                                                                                          }
                                                                                        } else {
                                                                                          peg$currPos = s0;
                                                                                          s0 = peg$FAILED;
                                                                                        }
                                                                                      } else {
                                                                                        peg$currPos = s0;
                                                                                        s0 = peg$FAILED;
                                                                                      }
                                                                                    } else {
                                                                                      peg$currPos = s0;
                                                                                      s0 = peg$FAILED;
                                                                                    }
                                                                                  } else {
                                                                                    peg$currPos = s0;
                                                                                    s0 = peg$FAILED;
                                                                                  }
                                                                                } else {
                                                                                  peg$currPos = s0;
                                                                                  s0 = peg$FAILED;
                                                                                }
                                                                              } else {
                                                                                peg$currPos = s0;
                                                                                s0 = peg$FAILED;
                                                                              }
                                                                            } else {
                                                                              peg$currPos = s0;
                                                                              s0 = peg$FAILED;
                                                                            }
                                                                          } else {
                                                                            peg$currPos = s0;
                                                                            s0 = peg$FAILED;
                                                                          }
                                                                        } else {
                                                                          peg$currPos = s0;
                                                                          s0 = peg$FAILED;
                                                                        }
                                                                      } else {
                                                                        peg$currPos = s0;
                                                                        s0 = peg$FAILED;
                                                                      }
                                                                    } else {
                                                                      peg$currPos = s0;
                                                                      s0 = peg$FAILED;
                                                                    }
                                                                  } else {
                                                                    peg$currPos = s0;
                                                                    s0 = peg$FAILED;
                                                                  }
                                                                  if (s0 === peg$FAILED) {
                                                                    s0 = peg$currPos;
                                                                    if (input.substr(peg$currPos, 2) === peg$c203) {
                                                                      s1 = peg$c203;
                                                                      peg$currPos += 2;
                                                                    } else {
                                                                      s1 = peg$FAILED;
                                                                      if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                                    }
                                                                    if (s1 !== peg$FAILED) {
                                                                      s2 = peg$parse_();
                                                                      if (s2 !== peg$FAILED) {
                                                                        s3 = peg$parseIntegerRangeSlashList();
                                                                        if (s3 !== peg$FAILED) {
                                                                          if (input.charCodeAt(peg$currPos) === 43) {
                                                                            s4 = peg$c471;
                                                                            peg$currPos++;
                                                                          } else {
                                                                            s4 = peg$FAILED;
                                                                            if (peg$silentFails === 0) { peg$fail(peg$c472); }
                                                                          }
                                                                          if (s4 === peg$FAILED) {
                                                                            s4 = null;
                                                                          }
                                                                          if (s4 !== peg$FAILED) {
                                                                            s5 = peg$parse_();
                                                                            if (s5 !== peg$FAILED) {
                                                                              s6 = peg$parseRealm();
                                                                              if (s6 !== peg$FAILED) {
                                                                                s7 = peg$parse_();
                                                                                if (s7 !== peg$FAILED) {
                                                                                  if (input.substr(peg$currPos, 20) === peg$c559) {
                                                                                    s8 = peg$c559;
                                                                                    peg$currPos += 20;
                                                                                  } else {
                                                                                    s8 = peg$FAILED;
                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c560); }
                                                                                  }
                                                                                  if (s8 === peg$FAILED) {
                                                                                    if (input.substr(peg$currPos, 18) === peg$c561) {
                                                                                      s8 = peg$c561;
                                                                                      peg$currPos += 18;
                                                                                    } else {
                                                                                      s8 = peg$FAILED;
                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c562); }
                                                                                    }
                                                                                    if (s8 === peg$FAILED) {
                                                                                      if (input.substr(peg$currPos, 16) === peg$c563) {
                                                                                        s8 = peg$c563;
                                                                                        peg$currPos += 16;
                                                                                      } else {
                                                                                        s8 = peg$FAILED;
                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c564); }
                                                                                      }
                                                                                      if (s8 === peg$FAILED) {
                                                                                        if (input.substr(peg$currPos, 17) === peg$c565) {
                                                                                          s8 = peg$c565;
                                                                                          peg$currPos += 17;
                                                                                        } else {
                                                                                          s8 = peg$FAILED;
                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c566); }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                  if (s8 !== peg$FAILED) {
                                                                                    peg$savedPos = s0;
                                                                                    s1 = peg$c567(s3, s4, s6);
                                                                                    s0 = s1;
                                                                                  } else {
                                                                                    peg$currPos = s0;
                                                                                    s0 = peg$FAILED;
                                                                                  }
                                                                                } else {
                                                                                  peg$currPos = s0;
                                                                                  s0 = peg$FAILED;
                                                                                }
                                                                              } else {
                                                                                peg$currPos = s0;
                                                                                s0 = peg$FAILED;
                                                                              }
                                                                            } else {
                                                                              peg$currPos = s0;
                                                                              s0 = peg$FAILED;
                                                                            }
                                                                          } else {
                                                                            peg$currPos = s0;
                                                                            s0 = peg$FAILED;
                                                                          }
                                                                        } else {
                                                                          peg$currPos = s0;
                                                                          s0 = peg$FAILED;
                                                                        }
                                                                      } else {
                                                                        peg$currPos = s0;
                                                                        s0 = peg$FAILED;
                                                                      }
                                                                    } else {
                                                                      peg$currPos = s0;
                                                                      s0 = peg$FAILED;
                                                                    }
                                                                    if (s0 === peg$FAILED) {
                                                                      s0 = peg$currPos;
                                                                      if (input.substr(peg$currPos, 2) === peg$c203) {
                                                                        s1 = peg$c203;
                                                                        peg$currPos += 2;
                                                                      } else {
                                                                        s1 = peg$FAILED;
                                                                        if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                                      }
                                                                      if (s1 !== peg$FAILED) {
                                                                        s2 = peg$parse_();
                                                                        if (s2 !== peg$FAILED) {
                                                                          s3 = peg$parseIntegerRangeSlashList();
                                                                          if (s3 !== peg$FAILED) {
                                                                            if (input.charCodeAt(peg$currPos) === 43) {
                                                                              s4 = peg$c471;
                                                                              peg$currPos++;
                                                                            } else {
                                                                              s4 = peg$FAILED;
                                                                              if (peg$silentFails === 0) { peg$fail(peg$c472); }
                                                                            }
                                                                            if (s4 === peg$FAILED) {
                                                                              s4 = null;
                                                                            }
                                                                            if (s4 !== peg$FAILED) {
                                                                              s5 = peg$parse_();
                                                                              if (s5 !== peg$FAILED) {
                                                                                if (input.substr(peg$currPos, 24) === peg$c568) {
                                                                                  s6 = peg$c568;
                                                                                  peg$currPos += 24;
                                                                                } else {
                                                                                  s6 = peg$FAILED;
                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c569); }
                                                                                }
                                                                                if (s6 !== peg$FAILED) {
                                                                                  s7 = peg$parse_();
                                                                                  if (s7 !== peg$FAILED) {
                                                                                    s8 = peg$parseElement();
                                                                                    if (s8 !== peg$FAILED) {
                                                                                      s9 = peg$parse_();
                                                                                      if (s9 !== peg$FAILED) {
                                                                                        if (input.charCodeAt(peg$currPos) === 93) {
                                                                                          s10 = peg$c570;
                                                                                          peg$currPos++;
                                                                                        } else {
                                                                                          s10 = peg$FAILED;
                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c571); }
                                                                                        }
                                                                                        if (s10 !== peg$FAILED) {
                                                                                          peg$savedPos = s0;
                                                                                          s1 = peg$c572(s3, s4, s8);
                                                                                          s0 = s1;
                                                                                        } else {
                                                                                          peg$currPos = s0;
                                                                                          s0 = peg$FAILED;
                                                                                        }
                                                                                      } else {
                                                                                        peg$currPos = s0;
                                                                                        s0 = peg$FAILED;
                                                                                      }
                                                                                    } else {
                                                                                      peg$currPos = s0;
                                                                                      s0 = peg$FAILED;
                                                                                    }
                                                                                  } else {
                                                                                    peg$currPos = s0;
                                                                                    s0 = peg$FAILED;
                                                                                  }
                                                                                } else {
                                                                                  peg$currPos = s0;
                                                                                  s0 = peg$FAILED;
                                                                                }
                                                                              } else {
                                                                                peg$currPos = s0;
                                                                                s0 = peg$FAILED;
                                                                              }
                                                                            } else {
                                                                              peg$currPos = s0;
                                                                              s0 = peg$FAILED;
                                                                            }
                                                                          } else {
                                                                            peg$currPos = s0;
                                                                            s0 = peg$FAILED;
                                                                          }
                                                                        } else {
                                                                          peg$currPos = s0;
                                                                          s0 = peg$FAILED;
                                                                        }
                                                                      } else {
                                                                        peg$currPos = s0;
                                                                        s0 = peg$FAILED;
                                                                      }
                                                                      if (s0 === peg$FAILED) {
                                                                        s0 = peg$currPos;
                                                                        if (input.substr(peg$currPos, 2) === peg$c203) {
                                                                          s1 = peg$c203;
                                                                          peg$currPos += 2;
                                                                        } else {
                                                                          s1 = peg$FAILED;
                                                                          if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                                        }
                                                                        if (s1 !== peg$FAILED) {
                                                                          s2 = peg$parse_();
                                                                          if (s2 !== peg$FAILED) {
                                                                            s3 = peg$parseInteger();
                                                                            if (s3 !== peg$FAILED) {
                                                                              s4 = peg$parse_();
                                                                              if (s4 !== peg$FAILED) {
                                                                                if (input.substr(peg$currPos, 32) === peg$c573) {
                                                                                  s5 = peg$c573;
                                                                                  peg$currPos += 32;
                                                                                } else {
                                                                                  s5 = peg$FAILED;
                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c574); }
                                                                                }
                                                                                if (s5 !== peg$FAILED) {
                                                                                  peg$savedPos = s0;
                                                                                  s1 = peg$c543(s3);
                                                                                  s0 = s1;
                                                                                } else {
                                                                                  peg$currPos = s0;
                                                                                  s0 = peg$FAILED;
                                                                                }
                                                                              } else {
                                                                                peg$currPos = s0;
                                                                                s0 = peg$FAILED;
                                                                              }
                                                                            } else {
                                                                              peg$currPos = s0;
                                                                              s0 = peg$FAILED;
                                                                            }
                                                                          } else {
                                                                            peg$currPos = s0;
                                                                            s0 = peg$FAILED;
                                                                          }
                                                                        } else {
                                                                          peg$currPos = s0;
                                                                          s0 = peg$FAILED;
                                                                        }
                                                                        if (s0 === peg$FAILED) {
                                                                          s0 = peg$currPos;
                                                                          if (input.substr(peg$currPos, 2) === peg$c203) {
                                                                            s1 = peg$c203;
                                                                            peg$currPos += 2;
                                                                          } else {
                                                                            s1 = peg$FAILED;
                                                                            if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                                          }
                                                                          if (s1 !== peg$FAILED) {
                                                                            s2 = peg$parse_();
                                                                            if (s2 !== peg$FAILED) {
                                                                              s3 = peg$parseIntegerSlashList();
                                                                              if (s3 !== peg$FAILED) {
                                                                                if (input.charCodeAt(peg$currPos) === 43) {
                                                                                  s4 = peg$c471;
                                                                                  peg$currPos++;
                                                                                } else {
                                                                                  s4 = peg$FAILED;
                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c472); }
                                                                                }
                                                                                if (s4 === peg$FAILED) {
                                                                                  s4 = null;
                                                                                }
                                                                                if (s4 !== peg$FAILED) {
                                                                                  s5 = peg$parse_();
                                                                                  if (s5 !== peg$FAILED) {
                                                                                    if (input.substr(peg$currPos, 13) === peg$c575) {
                                                                                      s6 = peg$c575;
                                                                                      peg$currPos += 13;
                                                                                    } else {
                                                                                      s6 = peg$FAILED;
                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c576); }
                                                                                    }
                                                                                    if (s6 === peg$FAILED) {
                                                                                      if (input.substr(peg$currPos, 6) === peg$c517) {
                                                                                        s6 = peg$c517;
                                                                                        peg$currPos += 6;
                                                                                      } else {
                                                                                        s6 = peg$FAILED;
                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c518); }
                                                                                      }
                                                                                    }
                                                                                    if (s6 !== peg$FAILED) {
                                                                                      s7 = peg$parse_();
                                                                                      if (s7 !== peg$FAILED) {
                                                                                        if (input.substr(peg$currPos, 9) === peg$c548) {
                                                                                          s8 = peg$c548;
                                                                                          peg$currPos += 9;
                                                                                        } else {
                                                                                          s8 = peg$FAILED;
                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c549); }
                                                                                        }
                                                                                        if (s8 !== peg$FAILED) {
                                                                                          peg$savedPos = s0;
                                                                                          s1 = peg$c577(s3);
                                                                                          s0 = s1;
                                                                                        } else {
                                                                                          peg$currPos = s0;
                                                                                          s0 = peg$FAILED;
                                                                                        }
                                                                                      } else {
                                                                                        peg$currPos = s0;
                                                                                        s0 = peg$FAILED;
                                                                                      }
                                                                                    } else {
                                                                                      peg$currPos = s0;
                                                                                      s0 = peg$FAILED;
                                                                                    }
                                                                                  } else {
                                                                                    peg$currPos = s0;
                                                                                    s0 = peg$FAILED;
                                                                                  }
                                                                                } else {
                                                                                  peg$currPos = s0;
                                                                                  s0 = peg$FAILED;
                                                                                }
                                                                              } else {
                                                                                peg$currPos = s0;
                                                                                s0 = peg$FAILED;
                                                                              }
                                                                            } else {
                                                                              peg$currPos = s0;
                                                                              s0 = peg$FAILED;
                                                                            }
                                                                          } else {
                                                                            peg$currPos = s0;
                                                                            s0 = peg$FAILED;
                                                                          }
                                                                          if (s0 === peg$FAILED) {
                                                                            s0 = peg$currPos;
                                                                            if (input.substr(peg$currPos, 2) === peg$c203) {
                                                                              s1 = peg$c203;
                                                                              peg$currPos += 2;
                                                                            } else {
                                                                              s1 = peg$FAILED;
                                                                              if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                                            }
                                                                            if (s1 !== peg$FAILED) {
                                                                              s2 = peg$parse_();
                                                                              if (s2 !== peg$FAILED) {
                                                                                s3 = peg$parseIntegerSlashList();
                                                                                if (s3 !== peg$FAILED) {
                                                                                  s4 = peg$parse_();
                                                                                  if (s4 !== peg$FAILED) {
                                                                                    if (input.substr(peg$currPos, 6) === peg$c517) {
                                                                                      s5 = peg$c517;
                                                                                      peg$currPos += 6;
                                                                                    } else {
                                                                                      s5 = peg$FAILED;
                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c518); }
                                                                                    }
                                                                                    if (s5 !== peg$FAILED) {
                                                                                      s6 = peg$parse_();
                                                                                      if (s6 !== peg$FAILED) {
                                                                                        if (input.substr(peg$currPos, 2) === peg$c535) {
                                                                                          s7 = peg$c535;
                                                                                          peg$currPos += 2;
                                                                                        } else {
                                                                                          s7 = peg$FAILED;
                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c536); }
                                                                                        }
                                                                                        if (s7 !== peg$FAILED) {
                                                                                          s8 = peg$parse_();
                                                                                          if (s8 !== peg$FAILED) {
                                                                                            if (input.substr(peg$currPos, 3) === peg$c139) {
                                                                                              s9 = peg$c139;
                                                                                              peg$currPos += 3;
                                                                                            } else {
                                                                                              s9 = peg$FAILED;
                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c140); }
                                                                                            }
                                                                                            if (s9 !== peg$FAILED) {
                                                                                              peg$savedPos = s0;
                                                                                              s1 = peg$c578(s3);
                                                                                              s0 = s1;
                                                                                            } else {
                                                                                              peg$currPos = s0;
                                                                                              s0 = peg$FAILED;
                                                                                            }
                                                                                          } else {
                                                                                            peg$currPos = s0;
                                                                                            s0 = peg$FAILED;
                                                                                          }
                                                                                        } else {
                                                                                          peg$currPos = s0;
                                                                                          s0 = peg$FAILED;
                                                                                        }
                                                                                      } else {
                                                                                        peg$currPos = s0;
                                                                                        s0 = peg$FAILED;
                                                                                      }
                                                                                    } else {
                                                                                      peg$currPos = s0;
                                                                                      s0 = peg$FAILED;
                                                                                    }
                                                                                  } else {
                                                                                    peg$currPos = s0;
                                                                                    s0 = peg$FAILED;
                                                                                  }
                                                                                } else {
                                                                                  peg$currPos = s0;
                                                                                  s0 = peg$FAILED;
                                                                                }
                                                                              } else {
                                                                                peg$currPos = s0;
                                                                                s0 = peg$FAILED;
                                                                              }
                                                                            } else {
                                                                              peg$currPos = s0;
                                                                              s0 = peg$FAILED;
                                                                            }
                                                                            if (s0 === peg$FAILED) {
                                                                              s0 = peg$currPos;
                                                                              if (input.substr(peg$currPos, 2) === peg$c203) {
                                                                                s1 = peg$c203;
                                                                                peg$currPos += 2;
                                                                              } else {
                                                                                s1 = peg$FAILED;
                                                                                if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                                              }
                                                                              if (s1 !== peg$FAILED) {
                                                                                s2 = peg$parse_();
                                                                                if (s2 !== peg$FAILED) {
                                                                                  if (input.substr(peg$currPos, 3) === peg$c186) {
                                                                                    s3 = peg$c186;
                                                                                    peg$currPos += 3;
                                                                                  } else {
                                                                                    s3 = peg$FAILED;
                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c187); }
                                                                                  }
                                                                                  if (s3 !== peg$FAILED) {
                                                                                    s4 = peg$parse_();
                                                                                    if (s4 !== peg$FAILED) {
                                                                                      if (input.substr(peg$currPos, 6) === peg$c282) {
                                                                                        s5 = peg$c282;
                                                                                        peg$currPos += 6;
                                                                                      } else {
                                                                                        s5 = peg$FAILED;
                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c283); }
                                                                                      }
                                                                                      if (s5 !== peg$FAILED) {
                                                                                        s6 = peg$parse_();
                                                                                        if (s6 !== peg$FAILED) {
                                                                                          if (input.substr(peg$currPos, 6) === peg$c114) {
                                                                                            s7 = peg$c114;
                                                                                            peg$currPos += 6;
                                                                                          } else {
                                                                                            s7 = peg$FAILED;
                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c115); }
                                                                                          }
                                                                                          if (s7 === peg$FAILED) {
                                                                                            if (input.substr(peg$currPos, 4) === peg$c116) {
                                                                                              s7 = peg$c116;
                                                                                              peg$currPos += 4;
                                                                                            } else {
                                                                                              s7 = peg$FAILED;
                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c117); }
                                                                                            }
                                                                                          }
                                                                                          if (s7 !== peg$FAILED) {
                                                                                            s8 = peg$parse_();
                                                                                            if (s8 !== peg$FAILED) {
                                                                                              if (input.substr(peg$currPos, 5) === peg$c579) {
                                                                                                s9 = peg$c579;
                                                                                                peg$currPos += 5;
                                                                                              } else {
                                                                                                s9 = peg$FAILED;
                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c580); }
                                                                                              }
                                                                                              if (s9 !== peg$FAILED) {
                                                                                                s10 = peg$parse_();
                                                                                                if (s10 !== peg$FAILED) {
                                                                                                  if (input.substr(peg$currPos, 2) === peg$c524) {
                                                                                                    s11 = peg$c524;
                                                                                                    peg$currPos += 2;
                                                                                                  } else {
                                                                                                    s11 = peg$FAILED;
                                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c525); }
                                                                                                  }
                                                                                                  if (s11 !== peg$FAILED) {
                                                                                                    s12 = peg$parse_();
                                                                                                    if (s12 !== peg$FAILED) {
                                                                                                      if (input.substr(peg$currPos, 5) === peg$c581) {
                                                                                                        s13 = peg$c581;
                                                                                                        peg$currPos += 5;
                                                                                                      } else {
                                                                                                        s13 = peg$FAILED;
                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c582); }
                                                                                                      }
                                                                                                      if (s13 !== peg$FAILED) {
                                                                                                        s14 = peg$parse_();
                                                                                                        if (s14 !== peg$FAILED) {
                                                                                                          s15 = peg$parseIntegerSlashList();
                                                                                                          if (s15 !== peg$FAILED) {
                                                                                                            peg$savedPos = s0;
                                                                                                            s1 = peg$c583(s15);
                                                                                                            s0 = s1;
                                                                                                          } else {
                                                                                                            peg$currPos = s0;
                                                                                                            s0 = peg$FAILED;
                                                                                                          }
                                                                                                        } else {
                                                                                                          peg$currPos = s0;
                                                                                                          s0 = peg$FAILED;
                                                                                                        }
                                                                                                      } else {
                                                                                                        peg$currPos = s0;
                                                                                                        s0 = peg$FAILED;
                                                                                                      }
                                                                                                    } else {
                                                                                                      peg$currPos = s0;
                                                                                                      s0 = peg$FAILED;
                                                                                                    }
                                                                                                  } else {
                                                                                                    peg$currPos = s0;
                                                                                                    s0 = peg$FAILED;
                                                                                                  }
                                                                                                } else {
                                                                                                  peg$currPos = s0;
                                                                                                  s0 = peg$FAILED;
                                                                                                }
                                                                                              } else {
                                                                                                peg$currPos = s0;
                                                                                                s0 = peg$FAILED;
                                                                                              }
                                                                                            } else {
                                                                                              peg$currPos = s0;
                                                                                              s0 = peg$FAILED;
                                                                                            }
                                                                                          } else {
                                                                                            peg$currPos = s0;
                                                                                            s0 = peg$FAILED;
                                                                                          }
                                                                                        } else {
                                                                                          peg$currPos = s0;
                                                                                          s0 = peg$FAILED;
                                                                                        }
                                                                                      } else {
                                                                                        peg$currPos = s0;
                                                                                        s0 = peg$FAILED;
                                                                                      }
                                                                                    } else {
                                                                                      peg$currPos = s0;
                                                                                      s0 = peg$FAILED;
                                                                                    }
                                                                                  } else {
                                                                                    peg$currPos = s0;
                                                                                    s0 = peg$FAILED;
                                                                                  }
                                                                                } else {
                                                                                  peg$currPos = s0;
                                                                                  s0 = peg$FAILED;
                                                                                }
                                                                              } else {
                                                                                peg$currPos = s0;
                                                                                s0 = peg$FAILED;
                                                                              }
                                                                              if (s0 === peg$FAILED) {
                                                                                s0 = peg$currPos;
                                                                                if (input.substr(peg$currPos, 2) === peg$c203) {
                                                                                  s1 = peg$c203;
                                                                                  peg$currPos += 2;
                                                                                } else {
                                                                                  s1 = peg$FAILED;
                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                                                }
                                                                                if (s1 !== peg$FAILED) {
                                                                                  s2 = peg$parse_();
                                                                                  if (s2 !== peg$FAILED) {
                                                                                    if (input.substr(peg$currPos, 3) === peg$c186) {
                                                                                      s3 = peg$c186;
                                                                                      peg$currPos += 3;
                                                                                    } else {
                                                                                      s3 = peg$FAILED;
                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c187); }
                                                                                    }
                                                                                    if (s3 !== peg$FAILED) {
                                                                                      s4 = peg$parse_();
                                                                                      if (s4 !== peg$FAILED) {
                                                                                        if (input.substr(peg$currPos, 6) === peg$c282) {
                                                                                          s5 = peg$c282;
                                                                                          peg$currPos += 6;
                                                                                        } else {
                                                                                          s5 = peg$FAILED;
                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c283); }
                                                                                        }
                                                                                        if (s5 !== peg$FAILED) {
                                                                                          s6 = peg$parse_();
                                                                                          if (s6 !== peg$FAILED) {
                                                                                            if (input.substr(peg$currPos, 2) === peg$c166) {
                                                                                              s7 = peg$c166;
                                                                                              peg$currPos += 2;
                                                                                            } else {
                                                                                              s7 = peg$FAILED;
                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c167); }
                                                                                            }
                                                                                            if (s7 !== peg$FAILED) {
                                                                                              s8 = peg$parse_();
                                                                                              if (s8 !== peg$FAILED) {
                                                                                                if (input.substr(peg$currPos, 2) === peg$c524) {
                                                                                                  s9 = peg$c524;
                                                                                                  peg$currPos += 2;
                                                                                                } else {
                                                                                                  s9 = peg$FAILED;
                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c525); }
                                                                                                }
                                                                                                if (s9 === peg$FAILED) {
                                                                                                  if (input.substr(peg$currPos, 3) === peg$c519) {
                                                                                                    s9 = peg$c519;
                                                                                                    peg$currPos += 3;
                                                                                                  } else {
                                                                                                    s9 = peg$FAILED;
                                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c520); }
                                                                                                  }
                                                                                                }
                                                                                                if (s9 !== peg$FAILED) {
                                                                                                  s10 = peg$parse_();
                                                                                                  if (s10 !== peg$FAILED) {
                                                                                                    if (input.substr(peg$currPos, 5) === peg$c581) {
                                                                                                      s11 = peg$c581;
                                                                                                      peg$currPos += 5;
                                                                                                    } else {
                                                                                                      s11 = peg$FAILED;
                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c582); }
                                                                                                    }
                                                                                                    if (s11 !== peg$FAILED) {
                                                                                                      s12 = peg$parse_();
                                                                                                      if (s12 !== peg$FAILED) {
                                                                                                        s13 = peg$parseIntegerSlashList();
                                                                                                        if (s13 !== peg$FAILED) {
                                                                                                          if (input.charCodeAt(peg$currPos) === 37) {
                                                                                                            s14 = peg$c172;
                                                                                                            peg$currPos++;
                                                                                                          } else {
                                                                                                            s14 = peg$FAILED;
                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c173); }
                                                                                                          }
                                                                                                          if (s14 !== peg$FAILED) {
                                                                                                            peg$savedPos = s0;
                                                                                                            s1 = peg$c584(s13);
                                                                                                            s0 = s1;
                                                                                                          } else {
                                                                                                            peg$currPos = s0;
                                                                                                            s0 = peg$FAILED;
                                                                                                          }
                                                                                                        } else {
                                                                                                          peg$currPos = s0;
                                                                                                          s0 = peg$FAILED;
                                                                                                        }
                                                                                                      } else {
                                                                                                        peg$currPos = s0;
                                                                                                        s0 = peg$FAILED;
                                                                                                      }
                                                                                                    } else {
                                                                                                      peg$currPos = s0;
                                                                                                      s0 = peg$FAILED;
                                                                                                    }
                                                                                                  } else {
                                                                                                    peg$currPos = s0;
                                                                                                    s0 = peg$FAILED;
                                                                                                  }
                                                                                                } else {
                                                                                                  peg$currPos = s0;
                                                                                                  s0 = peg$FAILED;
                                                                                                }
                                                                                              } else {
                                                                                                peg$currPos = s0;
                                                                                                s0 = peg$FAILED;
                                                                                              }
                                                                                            } else {
                                                                                              peg$currPos = s0;
                                                                                              s0 = peg$FAILED;
                                                                                            }
                                                                                          } else {
                                                                                            peg$currPos = s0;
                                                                                            s0 = peg$FAILED;
                                                                                          }
                                                                                        } else {
                                                                                          peg$currPos = s0;
                                                                                          s0 = peg$FAILED;
                                                                                        }
                                                                                      } else {
                                                                                        peg$currPos = s0;
                                                                                        s0 = peg$FAILED;
                                                                                      }
                                                                                    } else {
                                                                                      peg$currPos = s0;
                                                                                      s0 = peg$FAILED;
                                                                                    }
                                                                                  } else {
                                                                                    peg$currPos = s0;
                                                                                    s0 = peg$FAILED;
                                                                                  }
                                                                                } else {
                                                                                  peg$currPos = s0;
                                                                                  s0 = peg$FAILED;
                                                                                }
                                                                                if (s0 === peg$FAILED) {
                                                                                  s0 = peg$currPos;
                                                                                  if (input.substr(peg$currPos, 2) === peg$c203) {
                                                                                    s1 = peg$c203;
                                                                                    peg$currPos += 2;
                                                                                  } else {
                                                                                    s1 = peg$FAILED;
                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                                                  }
                                                                                  if (s1 !== peg$FAILED) {
                                                                                    s2 = peg$parse_();
                                                                                    if (s2 !== peg$FAILED) {
                                                                                      if (input.substr(peg$currPos, 3) === peg$c186) {
                                                                                        s3 = peg$c186;
                                                                                        peg$currPos += 3;
                                                                                      } else {
                                                                                        s3 = peg$FAILED;
                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c187); }
                                                                                      }
                                                                                      if (s3 !== peg$FAILED) {
                                                                                        s4 = peg$parse_();
                                                                                        if (s4 !== peg$FAILED) {
                                                                                          if (input.substr(peg$currPos, 6) === peg$c282) {
                                                                                            s5 = peg$c282;
                                                                                            peg$currPos += 6;
                                                                                          } else {
                                                                                            s5 = peg$FAILED;
                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c283); }
                                                                                          }
                                                                                          if (s5 !== peg$FAILED) {
                                                                                            s6 = peg$parse_();
                                                                                            if (s6 !== peg$FAILED) {
                                                                                              if (input.substr(peg$currPos, 2) === peg$c166) {
                                                                                                s7 = peg$c166;
                                                                                                peg$currPos += 2;
                                                                                              } else {
                                                                                                s7 = peg$FAILED;
                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c167); }
                                                                                              }
                                                                                              if (s7 !== peg$FAILED) {
                                                                                                s8 = peg$parse_();
                                                                                                if (s8 !== peg$FAILED) {
                                                                                                  if (input.substr(peg$currPos, 2) === peg$c524) {
                                                                                                    s9 = peg$c524;
                                                                                                    peg$currPos += 2;
                                                                                                  } else {
                                                                                                    s9 = peg$FAILED;
                                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c525); }
                                                                                                  }
                                                                                                  if (s9 === peg$FAILED) {
                                                                                                    if (input.substr(peg$currPos, 3) === peg$c519) {
                                                                                                      s9 = peg$c519;
                                                                                                      peg$currPos += 3;
                                                                                                    } else {
                                                                                                      s9 = peg$FAILED;
                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c520); }
                                                                                                    }
                                                                                                  }
                                                                                                  if (s9 !== peg$FAILED) {
                                                                                                    s10 = peg$parse_();
                                                                                                    if (s10 !== peg$FAILED) {
                                                                                                      if (input.substr(peg$currPos, 2) === peg$c148) {
                                                                                                        s11 = peg$c148;
                                                                                                        peg$currPos += 2;
                                                                                                      } else {
                                                                                                        s11 = peg$FAILED;
                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c149); }
                                                                                                      }
                                                                                                      if (s11 !== peg$FAILED) {
                                                                                                        s12 = peg$parse_();
                                                                                                        if (s12 !== peg$FAILED) {
                                                                                                          if (input.substr(peg$currPos, 5) === peg$c479) {
                                                                                                            s13 = peg$c479;
                                                                                                            peg$currPos += 5;
                                                                                                          } else {
                                                                                                            s13 = peg$FAILED;
                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c480); }
                                                                                                          }
                                                                                                          if (s13 !== peg$FAILED) {
                                                                                                            s14 = peg$parse_();
                                                                                                            if (s14 !== peg$FAILED) {
                                                                                                              s15 = peg$parseIntegerSlashList();
                                                                                                              if (s15 !== peg$FAILED) {
                                                                                                                if (input.charCodeAt(peg$currPos) === 37) {
                                                                                                                  s16 = peg$c172;
                                                                                                                  peg$currPos++;
                                                                                                                } else {
                                                                                                                  s16 = peg$FAILED;
                                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c173); }
                                                                                                                }
                                                                                                                if (s16 !== peg$FAILED) {
                                                                                                                  peg$savedPos = s0;
                                                                                                                  s1 = peg$c585(s15);
                                                                                                                  s0 = s1;
                                                                                                                } else {
                                                                                                                  peg$currPos = s0;
                                                                                                                  s0 = peg$FAILED;
                                                                                                                }
                                                                                                              } else {
                                                                                                                peg$currPos = s0;
                                                                                                                s0 = peg$FAILED;
                                                                                                              }
                                                                                                            } else {
                                                                                                              peg$currPos = s0;
                                                                                                              s0 = peg$FAILED;
                                                                                                            }
                                                                                                          } else {
                                                                                                            peg$currPos = s0;
                                                                                                            s0 = peg$FAILED;
                                                                                                          }
                                                                                                        } else {
                                                                                                          peg$currPos = s0;
                                                                                                          s0 = peg$FAILED;
                                                                                                        }
                                                                                                      } else {
                                                                                                        peg$currPos = s0;
                                                                                                        s0 = peg$FAILED;
                                                                                                      }
                                                                                                    } else {
                                                                                                      peg$currPos = s0;
                                                                                                      s0 = peg$FAILED;
                                                                                                    }
                                                                                                  } else {
                                                                                                    peg$currPos = s0;
                                                                                                    s0 = peg$FAILED;
                                                                                                  }
                                                                                                } else {
                                                                                                  peg$currPos = s0;
                                                                                                  s0 = peg$FAILED;
                                                                                                }
                                                                                              } else {
                                                                                                peg$currPos = s0;
                                                                                                s0 = peg$FAILED;
                                                                                              }
                                                                                            } else {
                                                                                              peg$currPos = s0;
                                                                                              s0 = peg$FAILED;
                                                                                            }
                                                                                          } else {
                                                                                            peg$currPos = s0;
                                                                                            s0 = peg$FAILED;
                                                                                          }
                                                                                        } else {
                                                                                          peg$currPos = s0;
                                                                                          s0 = peg$FAILED;
                                                                                        }
                                                                                      } else {
                                                                                        peg$currPos = s0;
                                                                                        s0 = peg$FAILED;
                                                                                      }
                                                                                    } else {
                                                                                      peg$currPos = s0;
                                                                                      s0 = peg$FAILED;
                                                                                    }
                                                                                  } else {
                                                                                    peg$currPos = s0;
                                                                                    s0 = peg$FAILED;
                                                                                  }
                                                                                  if (s0 === peg$FAILED) {
                                                                                    s0 = peg$currPos;
                                                                                    if (input.substr(peg$currPos, 2) === peg$c203) {
                                                                                      s1 = peg$c203;
                                                                                      peg$currPos += 2;
                                                                                    } else {
                                                                                      s1 = peg$FAILED;
                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                                                    }
                                                                                    if (s1 !== peg$FAILED) {
                                                                                      s2 = peg$parse_();
                                                                                      if (s2 !== peg$FAILED) {
                                                                                        if (input.substr(peg$currPos, 3) === peg$c186) {
                                                                                          s3 = peg$c186;
                                                                                          peg$currPos += 3;
                                                                                        } else {
                                                                                          s3 = peg$FAILED;
                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c187); }
                                                                                        }
                                                                                        if (s3 === peg$FAILED) {
                                                                                          s3 = null;
                                                                                        }
                                                                                        if (s3 !== peg$FAILED) {
                                                                                          s4 = peg$parse_();
                                                                                          if (s4 !== peg$FAILED) {
                                                                                            if (input.substr(peg$currPos, 4) === peg$c467) {
                                                                                              s5 = peg$c467;
                                                                                              peg$currPos += 4;
                                                                                            } else {
                                                                                              s5 = peg$FAILED;
                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c468); }
                                                                                            }
                                                                                            if (s5 !== peg$FAILED) {
                                                                                              s6 = peg$parse_();
                                                                                              if (s6 !== peg$FAILED) {
                                                                                                if (input.substr(peg$currPos, 3) === peg$c469) {
                                                                                                  s7 = peg$c469;
                                                                                                  peg$currPos += 3;
                                                                                                } else {
                                                                                                  s7 = peg$FAILED;
                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c470); }
                                                                                                }
                                                                                                if (s7 !== peg$FAILED) {
                                                                                                  s8 = peg$parse_();
                                                                                                  if (s8 !== peg$FAILED) {
                                                                                                    s9 = peg$parseIntegerSlashListOrRange();
                                                                                                    if (s9 !== peg$FAILED) {
                                                                                                      if (input.charCodeAt(peg$currPos) === 43) {
                                                                                                        s10 = peg$c471;
                                                                                                        peg$currPos++;
                                                                                                      } else {
                                                                                                        s10 = peg$FAILED;
                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c472); }
                                                                                                      }
                                                                                                      if (s10 === peg$FAILED) {
                                                                                                        s10 = null;
                                                                                                      }
                                                                                                      if (s10 !== peg$FAILED) {
                                                                                                        s11 = peg$parse_();
                                                                                                        if (s11 !== peg$FAILED) {
                                                                                                          s12 = peg$parseSB();
                                                                                                          if (s12 !== peg$FAILED) {
                                                                                                            s13 = peg$parse_();
                                                                                                            if (s13 !== peg$FAILED) {
                                                                                                              if (input.substr(peg$currPos, 6) === peg$c389) {
                                                                                                                s14 = peg$c389;
                                                                                                                peg$currPos += 6;
                                                                                                              } else {
                                                                                                                s14 = peg$FAILED;
                                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c390); }
                                                                                                              }
                                                                                                              if (s14 !== peg$FAILED) {
                                                                                                                peg$savedPos = s0;
                                                                                                                s1 = peg$c586(s9, s10);
                                                                                                                s0 = s1;
                                                                                                              } else {
                                                                                                                peg$currPos = s0;
                                                                                                                s0 = peg$FAILED;
                                                                                                              }
                                                                                                            } else {
                                                                                                              peg$currPos = s0;
                                                                                                              s0 = peg$FAILED;
                                                                                                            }
                                                                                                          } else {
                                                                                                            peg$currPos = s0;
                                                                                                            s0 = peg$FAILED;
                                                                                                          }
                                                                                                        } else {
                                                                                                          peg$currPos = s0;
                                                                                                          s0 = peg$FAILED;
                                                                                                        }
                                                                                                      } else {
                                                                                                        peg$currPos = s0;
                                                                                                        s0 = peg$FAILED;
                                                                                                      }
                                                                                                    } else {
                                                                                                      peg$currPos = s0;
                                                                                                      s0 = peg$FAILED;
                                                                                                    }
                                                                                                  } else {
                                                                                                    peg$currPos = s0;
                                                                                                    s0 = peg$FAILED;
                                                                                                  }
                                                                                                } else {
                                                                                                  peg$currPos = s0;
                                                                                                  s0 = peg$FAILED;
                                                                                                }
                                                                                              } else {
                                                                                                peg$currPos = s0;
                                                                                                s0 = peg$FAILED;
                                                                                              }
                                                                                            } else {
                                                                                              peg$currPos = s0;
                                                                                              s0 = peg$FAILED;
                                                                                            }
                                                                                          } else {
                                                                                            peg$currPos = s0;
                                                                                            s0 = peg$FAILED;
                                                                                          }
                                                                                        } else {
                                                                                          peg$currPos = s0;
                                                                                          s0 = peg$FAILED;
                                                                                        }
                                                                                      } else {
                                                                                        peg$currPos = s0;
                                                                                        s0 = peg$FAILED;
                                                                                      }
                                                                                    } else {
                                                                                      peg$currPos = s0;
                                                                                      s0 = peg$FAILED;
                                                                                    }
                                                                                    if (s0 === peg$FAILED) {
                                                                                      s0 = peg$currPos;
                                                                                      if (input.substr(peg$currPos, 2) === peg$c203) {
                                                                                        s1 = peg$c203;
                                                                                        peg$currPos += 2;
                                                                                      } else {
                                                                                        s1 = peg$FAILED;
                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                                                      }
                                                                                      if (s1 !== peg$FAILED) {
                                                                                        s2 = peg$parse_();
                                                                                        if (s2 !== peg$FAILED) {
                                                                                          s3 = peg$parseIntegerSlashList();
                                                                                          if (s3 !== peg$FAILED) {
                                                                                            s4 = peg$parse_();
                                                                                            if (s4 !== peg$FAILED) {
                                                                                              if (input.substr(peg$currPos, 33) === peg$c587) {
                                                                                                s5 = peg$c587;
                                                                                                peg$currPos += 33;
                                                                                              } else {
                                                                                                s5 = peg$FAILED;
                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c588); }
                                                                                              }
                                                                                              if (s5 !== peg$FAILED) {
                                                                                                peg$savedPos = s0;
                                                                                                s1 = peg$c589(s3);
                                                                                                s0 = s1;
                                                                                              } else {
                                                                                                peg$currPos = s0;
                                                                                                s0 = peg$FAILED;
                                                                                              }
                                                                                            } else {
                                                                                              peg$currPos = s0;
                                                                                              s0 = peg$FAILED;
                                                                                            }
                                                                                          } else {
                                                                                            peg$currPos = s0;
                                                                                            s0 = peg$FAILED;
                                                                                          }
                                                                                        } else {
                                                                                          peg$currPos = s0;
                                                                                          s0 = peg$FAILED;
                                                                                        }
                                                                                      } else {
                                                                                        peg$currPos = s0;
                                                                                        s0 = peg$FAILED;
                                                                                      }
                                                                                      if (s0 === peg$FAILED) {
                                                                                        s0 = peg$currPos;
                                                                                        if (input.substr(peg$currPos, 13) === peg$c590) {
                                                                                          s1 = peg$c590;
                                                                                          peg$currPos += 13;
                                                                                        } else {
                                                                                          s1 = peg$FAILED;
                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c591); }
                                                                                        }
                                                                                        if (s1 !== peg$FAILED) {
                                                                                          s2 = peg$parse_();
                                                                                          if (s2 !== peg$FAILED) {
                                                                                            s3 = peg$parseIntegerSlashList();
                                                                                            if (s3 !== peg$FAILED) {
                                                                                              s4 = peg$parse_();
                                                                                              if (s4 !== peg$FAILED) {
                                                                                                if (input.substr(peg$currPos, 36) === peg$c592) {
                                                                                                  s5 = peg$c592;
                                                                                                  peg$currPos += 36;
                                                                                                } else {
                                                                                                  s5 = peg$FAILED;
                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c593); }
                                                                                                }
                                                                                                if (s5 !== peg$FAILED) {
                                                                                                  peg$savedPos = s0;
                                                                                                  s1 = peg$c589(s3);
                                                                                                  s0 = s1;
                                                                                                } else {
                                                                                                  peg$currPos = s0;
                                                                                                  s0 = peg$FAILED;
                                                                                                }
                                                                                              } else {
                                                                                                peg$currPos = s0;
                                                                                                s0 = peg$FAILED;
                                                                                              }
                                                                                            } else {
                                                                                              peg$currPos = s0;
                                                                                              s0 = peg$FAILED;
                                                                                            }
                                                                                          } else {
                                                                                            peg$currPos = s0;
                                                                                            s0 = peg$FAILED;
                                                                                          }
                                                                                        } else {
                                                                                          peg$currPos = s0;
                                                                                          s0 = peg$FAILED;
                                                                                        }
                                                                                        if (s0 === peg$FAILED) {
                                                                                          s0 = peg$currPos;
                                                                                          if (input.substr(peg$currPos, 2) === peg$c203) {
                                                                                            s1 = peg$c203;
                                                                                            peg$currPos += 2;
                                                                                          } else {
                                                                                            s1 = peg$FAILED;
                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                                                          }
                                                                                          if (s1 !== peg$FAILED) {
                                                                                            s2 = peg$parse_();
                                                                                            if (s2 !== peg$FAILED) {
                                                                                              if (input.substr(peg$currPos, 3) === peg$c186) {
                                                                                                s3 = peg$c186;
                                                                                                peg$currPos += 3;
                                                                                              } else {
                                                                                                s3 = peg$FAILED;
                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c187); }
                                                                                              }
                                                                                              if (s3 !== peg$FAILED) {
                                                                                                s4 = peg$parse_();
                                                                                                if (s4 !== peg$FAILED) {
                                                                                                  if (input.substr(peg$currPos, 6) === peg$c485) {
                                                                                                    s5 = peg$c485;
                                                                                                    peg$currPos += 6;
                                                                                                  } else {
                                                                                                    s5 = peg$FAILED;
                                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c486); }
                                                                                                  }
                                                                                                  if (s5 !== peg$FAILED) {
                                                                                                    s6 = peg$parse_();
                                                                                                    if (s6 !== peg$FAILED) {
                                                                                                      if (input.substr(peg$currPos, 3) === peg$c469) {
                                                                                                        s7 = peg$c469;
                                                                                                        peg$currPos += 3;
                                                                                                      } else {
                                                                                                        s7 = peg$FAILED;
                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c470); }
                                                                                                      }
                                                                                                      if (s7 !== peg$FAILED) {
                                                                                                        s8 = peg$parse_();
                                                                                                        if (s8 !== peg$FAILED) {
                                                                                                          s9 = peg$parseIntegerSlashList();
                                                                                                          if (s9 !== peg$FAILED) {
                                                                                                            s10 = peg$parse_();
                                                                                                            if (s10 !== peg$FAILED) {
                                                                                                              if (input.substr(peg$currPos, 8) === peg$c594) {
                                                                                                                s11 = peg$c594;
                                                                                                                peg$currPos += 8;
                                                                                                              } else {
                                                                                                                s11 = peg$FAILED;
                                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c595); }
                                                                                                              }
                                                                                                              if (s11 !== peg$FAILED) {
                                                                                                                peg$savedPos = s0;
                                                                                                                s1 = peg$c596(s9);
                                                                                                                s0 = s1;
                                                                                                              } else {
                                                                                                                peg$currPos = s0;
                                                                                                                s0 = peg$FAILED;
                                                                                                              }
                                                                                                            } else {
                                                                                                              peg$currPos = s0;
                                                                                                              s0 = peg$FAILED;
                                                                                                            }
                                                                                                          } else {
                                                                                                            peg$currPos = s0;
                                                                                                            s0 = peg$FAILED;
                                                                                                          }
                                                                                                        } else {
                                                                                                          peg$currPos = s0;
                                                                                                          s0 = peg$FAILED;
                                                                                                        }
                                                                                                      } else {
                                                                                                        peg$currPos = s0;
                                                                                                        s0 = peg$FAILED;
                                                                                                      }
                                                                                                    } else {
                                                                                                      peg$currPos = s0;
                                                                                                      s0 = peg$FAILED;
                                                                                                    }
                                                                                                  } else {
                                                                                                    peg$currPos = s0;
                                                                                                    s0 = peg$FAILED;
                                                                                                  }
                                                                                                } else {
                                                                                                  peg$currPos = s0;
                                                                                                  s0 = peg$FAILED;
                                                                                                }
                                                                                              } else {
                                                                                                peg$currPos = s0;
                                                                                                s0 = peg$FAILED;
                                                                                              }
                                                                                            } else {
                                                                                              peg$currPos = s0;
                                                                                              s0 = peg$FAILED;
                                                                                            }
                                                                                          } else {
                                                                                            peg$currPos = s0;
                                                                                            s0 = peg$FAILED;
                                                                                          }
                                                                                          if (s0 === peg$FAILED) {
                                                                                            s0 = peg$currPos;
                                                                                            if (input.substr(peg$currPos, 32) === peg$c597) {
                                                                                              s1 = peg$c597;
                                                                                              peg$currPos += 32;
                                                                                            } else {
                                                                                              s1 = peg$FAILED;
                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c598); }
                                                                                            }
                                                                                            if (s1 !== peg$FAILED) {
                                                                                              peg$savedPos = s0;
                                                                                              s1 = peg$c599();
                                                                                            }
                                                                                            s0 = s1;
                                                                                            if (s0 === peg$FAILED) {
                                                                                              s0 = peg$currPos;
                                                                                              if (input.substr(peg$currPos, 13) === peg$c600) {
                                                                                                s1 = peg$c600;
                                                                                                peg$currPos += 13;
                                                                                              } else {
                                                                                                s1 = peg$FAILED;
                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c601); }
                                                                                              }
                                                                                              if (s1 !== peg$FAILED) {
                                                                                                s2 = peg$parse_();
                                                                                                if (s2 !== peg$FAILED) {
                                                                                                  s3 = peg$parseElementList();
                                                                                                  if (s3 !== peg$FAILED) {
                                                                                                    s4 = peg$parse_();
                                                                                                    if (s4 !== peg$FAILED) {
                                                                                                      if (input.substr(peg$currPos, 8) === peg$c602) {
                                                                                                        s5 = peg$c602;
                                                                                                        peg$currPos += 8;
                                                                                                      } else {
                                                                                                        s5 = peg$FAILED;
                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c603); }
                                                                                                      }
                                                                                                      if (s5 !== peg$FAILED) {
                                                                                                        peg$savedPos = s0;
                                                                                                        s1 = peg$c604(s3);
                                                                                                        s0 = s1;
                                                                                                      } else {
                                                                                                        peg$currPos = s0;
                                                                                                        s0 = peg$FAILED;
                                                                                                      }
                                                                                                    } else {
                                                                                                      peg$currPos = s0;
                                                                                                      s0 = peg$FAILED;
                                                                                                    }
                                                                                                  } else {
                                                                                                    peg$currPos = s0;
                                                                                                    s0 = peg$FAILED;
                                                                                                  }
                                                                                                } else {
                                                                                                  peg$currPos = s0;
                                                                                                  s0 = peg$FAILED;
                                                                                                }
                                                                                              } else {
                                                                                                peg$currPos = s0;
                                                                                                s0 = peg$FAILED;
                                                                                              }
                                                                                              if (s0 === peg$FAILED) {
                                                                                                s0 = peg$currPos;
                                                                                                if (input.substr(peg$currPos, 2) === peg$c203) {
                                                                                                  s1 = peg$c203;
                                                                                                  peg$currPos += 2;
                                                                                                } else {
                                                                                                  s1 = peg$FAILED;
                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                                                                }
                                                                                                if (s1 !== peg$FAILED) {
                                                                                                  s2 = peg$parse_();
                                                                                                  if (s2 !== peg$FAILED) {
                                                                                                    if (input.substr(peg$currPos, 3) === peg$c186) {
                                                                                                      s3 = peg$c186;
                                                                                                      peg$currPos += 3;
                                                                                                    } else {
                                                                                                      s3 = peg$FAILED;
                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c187); }
                                                                                                    }
                                                                                                    if (s3 === peg$FAILED) {
                                                                                                      s3 = null;
                                                                                                    }
                                                                                                    if (s3 !== peg$FAILED) {
                                                                                                      s4 = peg$parse_();
                                                                                                      if (s4 !== peg$FAILED) {
                                                                                                        if (input.substr(peg$currPos, 4) === peg$c467) {
                                                                                                          s5 = peg$c467;
                                                                                                          peg$currPos += 4;
                                                                                                        } else {
                                                                                                          s5 = peg$FAILED;
                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c468); }
                                                                                                        }
                                                                                                        if (s5 !== peg$FAILED) {
                                                                                                          s6 = peg$parse_();
                                                                                                          if (s6 !== peg$FAILED) {
                                                                                                            if (input.substr(peg$currPos, 2) === peg$c524) {
                                                                                                              s7 = peg$c524;
                                                                                                              peg$currPos += 2;
                                                                                                            } else {
                                                                                                              s7 = peg$FAILED;
                                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c525); }
                                                                                                            }
                                                                                                            if (s7 !== peg$FAILED) {
                                                                                                              s8 = peg$parse_();
                                                                                                              if (s8 !== peg$FAILED) {
                                                                                                                if (input.substr(peg$currPos, 2) === peg$c535) {
                                                                                                                  s9 = peg$c535;
                                                                                                                  peg$currPos += 2;
                                                                                                                } else {
                                                                                                                  s9 = peg$FAILED;
                                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c536); }
                                                                                                                }
                                                                                                                if (s9 !== peg$FAILED) {
                                                                                                                  s10 = peg$parse_();
                                                                                                                  if (s10 !== peg$FAILED) {
                                                                                                                    if (input.substr(peg$currPos, 3) === peg$c186) {
                                                                                                                      s11 = peg$c186;
                                                                                                                      peg$currPos += 3;
                                                                                                                    } else {
                                                                                                                      s11 = peg$FAILED;
                                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c187); }
                                                                                                                    }
                                                                                                                    if (s11 === peg$FAILED) {
                                                                                                                      s11 = null;
                                                                                                                    }
                                                                                                                    if (s11 !== peg$FAILED) {
                                                                                                                      s12 = peg$parse_();
                                                                                                                      if (s12 !== peg$FAILED) {
                                                                                                                        if (input.substr(peg$currPos, 5) === peg$c605) {
                                                                                                                          s13 = peg$c605;
                                                                                                                          peg$currPos += 5;
                                                                                                                        } else {
                                                                                                                          s13 = peg$FAILED;
                                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c606); }
                                                                                                                        }
                                                                                                                        if (s13 !== peg$FAILED) {
                                                                                                                          s14 = peg$parse_();
                                                                                                                          if (s14 !== peg$FAILED) {
                                                                                                                            if (input.substr(peg$currPos, 3) === peg$c607) {
                                                                                                                              s15 = peg$c607;
                                                                                                                              peg$currPos += 3;
                                                                                                                            } else {
                                                                                                                              s15 = peg$FAILED;
                                                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c608); }
                                                                                                                            }
                                                                                                                            if (s15 !== peg$FAILED) {
                                                                                                                              peg$savedPos = s0;
                                                                                                                              s1 = peg$c609();
                                                                                                                              s0 = s1;
                                                                                                                            } else {
                                                                                                                              peg$currPos = s0;
                                                                                                                              s0 = peg$FAILED;
                                                                                                                            }
                                                                                                                          } else {
                                                                                                                            peg$currPos = s0;
                                                                                                                            s0 = peg$FAILED;
                                                                                                                          }
                                                                                                                        } else {
                                                                                                                          peg$currPos = s0;
                                                                                                                          s0 = peg$FAILED;
                                                                                                                        }
                                                                                                                      } else {
                                                                                                                        peg$currPos = s0;
                                                                                                                        s0 = peg$FAILED;
                                                                                                                      }
                                                                                                                    } else {
                                                                                                                      peg$currPos = s0;
                                                                                                                      s0 = peg$FAILED;
                                                                                                                    }
                                                                                                                  } else {
                                                                                                                    peg$currPos = s0;
                                                                                                                    s0 = peg$FAILED;
                                                                                                                  }
                                                                                                                } else {
                                                                                                                  peg$currPos = s0;
                                                                                                                  s0 = peg$FAILED;
                                                                                                                }
                                                                                                              } else {
                                                                                                                peg$currPos = s0;
                                                                                                                s0 = peg$FAILED;
                                                                                                              }
                                                                                                            } else {
                                                                                                              peg$currPos = s0;
                                                                                                              s0 = peg$FAILED;
                                                                                                            }
                                                                                                          } else {
                                                                                                            peg$currPos = s0;
                                                                                                            s0 = peg$FAILED;
                                                                                                          }
                                                                                                        } else {
                                                                                                          peg$currPos = s0;
                                                                                                          s0 = peg$FAILED;
                                                                                                        }
                                                                                                      } else {
                                                                                                        peg$currPos = s0;
                                                                                                        s0 = peg$FAILED;
                                                                                                      }
                                                                                                    } else {
                                                                                                      peg$currPos = s0;
                                                                                                      s0 = peg$FAILED;
                                                                                                    }
                                                                                                  } else {
                                                                                                    peg$currPos = s0;
                                                                                                    s0 = peg$FAILED;
                                                                                                  }
                                                                                                } else {
                                                                                                  peg$currPos = s0;
                                                                                                  s0 = peg$FAILED;
                                                                                                }
                                                                                                if (s0 === peg$FAILED) {
                                                                                                  s0 = peg$currPos;
                                                                                                  if (input.substr(peg$currPos, 2) === peg$c203) {
                                                                                                    s1 = peg$c203;
                                                                                                    peg$currPos += 2;
                                                                                                  } else {
                                                                                                    s1 = peg$FAILED;
                                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                                                                  }
                                                                                                  if (s1 !== peg$FAILED) {
                                                                                                    s2 = peg$parse_();
                                                                                                    if (s2 !== peg$FAILED) {
                                                                                                      if (input.substr(peg$currPos, 3) === peg$c186) {
                                                                                                        s3 = peg$c186;
                                                                                                        peg$currPos += 3;
                                                                                                      } else {
                                                                                                        s3 = peg$FAILED;
                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c187); }
                                                                                                      }
                                                                                                      if (s3 !== peg$FAILED) {
                                                                                                        s4 = peg$parse_();
                                                                                                        if (s4 !== peg$FAILED) {
                                                                                                          if (input.substr(peg$currPos, 4) === peg$c467) {
                                                                                                            s5 = peg$c467;
                                                                                                            peg$currPos += 4;
                                                                                                          } else {
                                                                                                            s5 = peg$FAILED;
                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c468); }
                                                                                                          }
                                                                                                          if (s5 !== peg$FAILED) {
                                                                                                            s6 = peg$parse_();
                                                                                                            if (s6 !== peg$FAILED) {
                                                                                                              if (input.substr(peg$currPos, 4) === peg$c610) {
                                                                                                                s7 = peg$c610;
                                                                                                                peg$currPos += 4;
                                                                                                              } else {
                                                                                                                s7 = peg$FAILED;
                                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c611); }
                                                                                                              }
                                                                                                              if (s7 === peg$FAILED) {
                                                                                                                s7 = peg$currPos;
                                                                                                                if (input.substr(peg$currPos, 3) === peg$c469) {
                                                                                                                  s8 = peg$c469;
                                                                                                                  peg$currPos += 3;
                                                                                                                } else {
                                                                                                                  s8 = peg$FAILED;
                                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c470); }
                                                                                                                }
                                                                                                                if (s8 !== peg$FAILED) {
                                                                                                                  s9 = peg$parse_();
                                                                                                                  if (s9 !== peg$FAILED) {
                                                                                                                    if (input.substr(peg$currPos, 5) === peg$c103) {
                                                                                                                      s10 = peg$c103;
                                                                                                                      peg$currPos += 5;
                                                                                                                    } else {
                                                                                                                      s10 = peg$FAILED;
                                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c104); }
                                                                                                                    }
                                                                                                                    if (s10 !== peg$FAILED) {
                                                                                                                      s8 = [s8, s9, s10];
                                                                                                                      s7 = s8;
                                                                                                                    } else {
                                                                                                                      peg$currPos = s7;
                                                                                                                      s7 = peg$FAILED;
                                                                                                                    }
                                                                                                                  } else {
                                                                                                                    peg$currPos = s7;
                                                                                                                    s7 = peg$FAILED;
                                                                                                                  }
                                                                                                                } else {
                                                                                                                  peg$currPos = s7;
                                                                                                                  s7 = peg$FAILED;
                                                                                                                }
                                                                                                              }
                                                                                                              if (s7 !== peg$FAILED) {
                                                                                                                s8 = peg$parse_();
                                                                                                                if (s8 !== peg$FAILED) {
                                                                                                                  s9 = peg$parseIntegerSlashList();
                                                                                                                  if (s9 !== peg$FAILED) {
                                                                                                                    s10 = peg$parse_();
                                                                                                                    if (s10 !== peg$FAILED) {
                                                                                                                      s11 = peg$parseSkillTypeList();
                                                                                                                      if (s11 !== peg$FAILED) {
                                                                                                                        s12 = peg$parse_();
                                                                                                                        if (s12 !== peg$FAILED) {
                                                                                                                          if (input.substr(peg$currPos, 4) === peg$c101) {
                                                                                                                            s13 = peg$c101;
                                                                                                                            peg$currPos += 4;
                                                                                                                          } else {
                                                                                                                            s13 = peg$FAILED;
                                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c102); }
                                                                                                                          }
                                                                                                                          if (s13 !== peg$FAILED) {
                                                                                                                            peg$savedPos = s0;
                                                                                                                            s1 = peg$c612(s9, s11);
                                                                                                                            s0 = s1;
                                                                                                                          } else {
                                                                                                                            peg$currPos = s0;
                                                                                                                            s0 = peg$FAILED;
                                                                                                                          }
                                                                                                                        } else {
                                                                                                                          peg$currPos = s0;
                                                                                                                          s0 = peg$FAILED;
                                                                                                                        }
                                                                                                                      } else {
                                                                                                                        peg$currPos = s0;
                                                                                                                        s0 = peg$FAILED;
                                                                                                                      }
                                                                                                                    } else {
                                                                                                                      peg$currPos = s0;
                                                                                                                      s0 = peg$FAILED;
                                                                                                                    }
                                                                                                                  } else {
                                                                                                                    peg$currPos = s0;
                                                                                                                    s0 = peg$FAILED;
                                                                                                                  }
                                                                                                                } else {
                                                                                                                  peg$currPos = s0;
                                                                                                                  s0 = peg$FAILED;
                                                                                                                }
                                                                                                              } else {
                                                                                                                peg$currPos = s0;
                                                                                                                s0 = peg$FAILED;
                                                                                                              }
                                                                                                            } else {
                                                                                                              peg$currPos = s0;
                                                                                                              s0 = peg$FAILED;
                                                                                                            }
                                                                                                          } else {
                                                                                                            peg$currPos = s0;
                                                                                                            s0 = peg$FAILED;
                                                                                                          }
                                                                                                        } else {
                                                                                                          peg$currPos = s0;
                                                                                                          s0 = peg$FAILED;
                                                                                                        }
                                                                                                      } else {
                                                                                                        peg$currPos = s0;
                                                                                                        s0 = peg$FAILED;
                                                                                                      }
                                                                                                    } else {
                                                                                                      peg$currPos = s0;
                                                                                                      s0 = peg$FAILED;
                                                                                                    }
                                                                                                  } else {
                                                                                                    peg$currPos = s0;
                                                                                                    s0 = peg$FAILED;
                                                                                                  }
                                                                                                  if (s0 === peg$FAILED) {
                                                                                                    s0 = peg$currPos;
                                                                                                    if (input.substr(peg$currPos, 2) === peg$c203) {
                                                                                                      s1 = peg$c203;
                                                                                                      peg$currPos += 2;
                                                                                                    } else {
                                                                                                      s1 = peg$FAILED;
                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                                                                    }
                                                                                                    if (s1 !== peg$FAILED) {
                                                                                                      s2 = peg$parse_();
                                                                                                      if (s2 !== peg$FAILED) {
                                                                                                        if (input.substr(peg$currPos, 3) === peg$c186) {
                                                                                                          s3 = peg$c186;
                                                                                                          peg$currPos += 3;
                                                                                                        } else {
                                                                                                          s3 = peg$FAILED;
                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c187); }
                                                                                                        }
                                                                                                        if (s3 !== peg$FAILED) {
                                                                                                          s4 = peg$parse_();
                                                                                                          if (s4 !== peg$FAILED) {
                                                                                                            if (input.substr(peg$currPos, 4) === peg$c467) {
                                                                                                              s5 = peg$c467;
                                                                                                              peg$currPos += 4;
                                                                                                            } else {
                                                                                                              s5 = peg$FAILED;
                                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c468); }
                                                                                                            }
                                                                                                            if (s5 !== peg$FAILED) {
                                                                                                              s6 = peg$parse_();
                                                                                                              if (s6 !== peg$FAILED) {
                                                                                                                if (input.substr(peg$currPos, 4) === peg$c610) {
                                                                                                                  s7 = peg$c610;
                                                                                                                  peg$currPos += 4;
                                                                                                                } else {
                                                                                                                  s7 = peg$FAILED;
                                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c611); }
                                                                                                                }
                                                                                                                if (s7 === peg$FAILED) {
                                                                                                                  s7 = peg$currPos;
                                                                                                                  if (input.substr(peg$currPos, 3) === peg$c469) {
                                                                                                                    s8 = peg$c469;
                                                                                                                    peg$currPos += 3;
                                                                                                                  } else {
                                                                                                                    s8 = peg$FAILED;
                                                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c470); }
                                                                                                                  }
                                                                                                                  if (s8 !== peg$FAILED) {
                                                                                                                    s9 = peg$parse_();
                                                                                                                    if (s9 !== peg$FAILED) {
                                                                                                                      if (input.substr(peg$currPos, 5) === peg$c103) {
                                                                                                                        s10 = peg$c103;
                                                                                                                        peg$currPos += 5;
                                                                                                                      } else {
                                                                                                                        s10 = peg$FAILED;
                                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c104); }
                                                                                                                      }
                                                                                                                      if (s10 !== peg$FAILED) {
                                                                                                                        s8 = [s8, s9, s10];
                                                                                                                        s7 = s8;
                                                                                                                      } else {
                                                                                                                        peg$currPos = s7;
                                                                                                                        s7 = peg$FAILED;
                                                                                                                      }
                                                                                                                    } else {
                                                                                                                      peg$currPos = s7;
                                                                                                                      s7 = peg$FAILED;
                                                                                                                    }
                                                                                                                  } else {
                                                                                                                    peg$currPos = s7;
                                                                                                                    s7 = peg$FAILED;
                                                                                                                  }
                                                                                                                }
                                                                                                                if (s7 !== peg$FAILED) {
                                                                                                                  s8 = peg$parse_();
                                                                                                                  if (s8 !== peg$FAILED) {
                                                                                                                    s9 = peg$parseIntegerSlashList();
                                                                                                                    if (s9 !== peg$FAILED) {
                                                                                                                      s10 = peg$parse_();
                                                                                                                      if (s10 !== peg$FAILED) {
                                                                                                                        if (input.substr(peg$currPos, 7) === peg$c111) {
                                                                                                                          s11 = peg$c111;
                                                                                                                          peg$currPos += 7;
                                                                                                                        } else {
                                                                                                                          s11 = peg$FAILED;
                                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c112); }
                                                                                                                        }
                                                                                                                        if (s11 !== peg$FAILED) {
                                                                                                                          peg$savedPos = s0;
                                                                                                                          s1 = peg$c613(s9);
                                                                                                                          s0 = s1;
                                                                                                                        } else {
                                                                                                                          peg$currPos = s0;
                                                                                                                          s0 = peg$FAILED;
                                                                                                                        }
                                                                                                                      } else {
                                                                                                                        peg$currPos = s0;
                                                                                                                        s0 = peg$FAILED;
                                                                                                                      }
                                                                                                                    } else {
                                                                                                                      peg$currPos = s0;
                                                                                                                      s0 = peg$FAILED;
                                                                                                                    }
                                                                                                                  } else {
                                                                                                                    peg$currPos = s0;
                                                                                                                    s0 = peg$FAILED;
                                                                                                                  }
                                                                                                                } else {
                                                                                                                  peg$currPos = s0;
                                                                                                                  s0 = peg$FAILED;
                                                                                                                }
                                                                                                              } else {
                                                                                                                peg$currPos = s0;
                                                                                                                s0 = peg$FAILED;
                                                                                                              }
                                                                                                            } else {
                                                                                                              peg$currPos = s0;
                                                                                                              s0 = peg$FAILED;
                                                                                                            }
                                                                                                          } else {
                                                                                                            peg$currPos = s0;
                                                                                                            s0 = peg$FAILED;
                                                                                                          }
                                                                                                        } else {
                                                                                                          peg$currPos = s0;
                                                                                                          s0 = peg$FAILED;
                                                                                                        }
                                                                                                      } else {
                                                                                                        peg$currPos = s0;
                                                                                                        s0 = peg$FAILED;
                                                                                                      }
                                                                                                    } else {
                                                                                                      peg$currPos = s0;
                                                                                                      s0 = peg$FAILED;
                                                                                                    }
                                                                                                    if (s0 === peg$FAILED) {
                                                                                                      s0 = peg$currPos;
                                                                                                      if (input.substr(peg$currPos, 2) === peg$c203) {
                                                                                                        s1 = peg$c203;
                                                                                                        peg$currPos += 2;
                                                                                                      } else {
                                                                                                        s1 = peg$FAILED;
                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                                                                      }
                                                                                                      if (s1 !== peg$FAILED) {
                                                                                                        s2 = peg$parse_();
                                                                                                        if (s2 !== peg$FAILED) {
                                                                                                          if (input.substr(peg$currPos, 3) === peg$c186) {
                                                                                                            s3 = peg$c186;
                                                                                                            peg$currPos += 3;
                                                                                                          } else {
                                                                                                            s3 = peg$FAILED;
                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c187); }
                                                                                                          }
                                                                                                          if (s3 !== peg$FAILED) {
                                                                                                            s4 = peg$parse_();
                                                                                                            if (s4 !== peg$FAILED) {
                                                                                                              if (input.substr(peg$currPos, 4) === peg$c467) {
                                                                                                                s5 = peg$c467;
                                                                                                                peg$currPos += 4;
                                                                                                              } else {
                                                                                                                s5 = peg$FAILED;
                                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c468); }
                                                                                                              }
                                                                                                              if (s5 !== peg$FAILED) {
                                                                                                                s6 = peg$parse_();
                                                                                                                if (s6 !== peg$FAILED) {
                                                                                                                  if (input.substr(peg$currPos, 4) === peg$c108) {
                                                                                                                    s7 = peg$c108;
                                                                                                                    peg$currPos += 4;
                                                                                                                  } else {
                                                                                                                    s7 = peg$FAILED;
                                                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c109); }
                                                                                                                  }
                                                                                                                  if (s7 !== peg$FAILED) {
                                                                                                                    s8 = peg$parse_();
                                                                                                                    if (s8 !== peg$FAILED) {
                                                                                                                      s9 = peg$parseIntegerSlashList();
                                                                                                                      if (s9 !== peg$FAILED) {
                                                                                                                        s10 = peg$parse_();
                                                                                                                        if (s10 !== peg$FAILED) {
                                                                                                                          if (input.substr(peg$currPos, 8) === peg$c614) {
                                                                                                                            s11 = peg$c614;
                                                                                                                            peg$currPos += 8;
                                                                                                                          } else {
                                                                                                                            s11 = peg$FAILED;
                                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c615); }
                                                                                                                          }
                                                                                                                          if (s11 !== peg$FAILED) {
                                                                                                                            s12 = peg$parse_();
                                                                                                                            if (s12 !== peg$FAILED) {
                                                                                                                              if (input.substr(peg$currPos, 7) === peg$c616) {
                                                                                                                                s13 = peg$c616;
                                                                                                                                peg$currPos += 7;
                                                                                                                              } else {
                                                                                                                                s13 = peg$FAILED;
                                                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c617); }
                                                                                                                              }
                                                                                                                              if (s13 !== peg$FAILED) {
                                                                                                                                peg$savedPos = s0;
                                                                                                                                s1 = peg$c618(s9);
                                                                                                                                s0 = s1;
                                                                                                                              } else {
                                                                                                                                peg$currPos = s0;
                                                                                                                                s0 = peg$FAILED;
                                                                                                                              }
                                                                                                                            } else {
                                                                                                                              peg$currPos = s0;
                                                                                                                              s0 = peg$FAILED;
                                                                                                                            }
                                                                                                                          } else {
                                                                                                                            peg$currPos = s0;
                                                                                                                            s0 = peg$FAILED;
                                                                                                                          }
                                                                                                                        } else {
                                                                                                                          peg$currPos = s0;
                                                                                                                          s0 = peg$FAILED;
                                                                                                                        }
                                                                                                                      } else {
                                                                                                                        peg$currPos = s0;
                                                                                                                        s0 = peg$FAILED;
                                                                                                                      }
                                                                                                                    } else {
                                                                                                                      peg$currPos = s0;
                                                                                                                      s0 = peg$FAILED;
                                                                                                                    }
                                                                                                                  } else {
                                                                                                                    peg$currPos = s0;
                                                                                                                    s0 = peg$FAILED;
                                                                                                                  }
                                                                                                                } else {
                                                                                                                  peg$currPos = s0;
                                                                                                                  s0 = peg$FAILED;
                                                                                                                }
                                                                                                              } else {
                                                                                                                peg$currPos = s0;
                                                                                                                s0 = peg$FAILED;
                                                                                                              }
                                                                                                            } else {
                                                                                                              peg$currPos = s0;
                                                                                                              s0 = peg$FAILED;
                                                                                                            }
                                                                                                          } else {
                                                                                                            peg$currPos = s0;
                                                                                                            s0 = peg$FAILED;
                                                                                                          }
                                                                                                        } else {
                                                                                                          peg$currPos = s0;
                                                                                                          s0 = peg$FAILED;
                                                                                                        }
                                                                                                      } else {
                                                                                                        peg$currPos = s0;
                                                                                                        s0 = peg$FAILED;
                                                                                                      }
                                                                                                      if (s0 === peg$FAILED) {
                                                                                                        s0 = peg$currPos;
                                                                                                        if (input.substr(peg$currPos, 4) === peg$c90) {
                                                                                                          s1 = peg$c90;
                                                                                                          peg$currPos += 4;
                                                                                                        } else {
                                                                                                          s1 = peg$FAILED;
                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c91); }
                                                                                                        }
                                                                                                        if (s1 !== peg$FAILED) {
                                                                                                          s2 = peg$parse_();
                                                                                                          if (s2 !== peg$FAILED) {
                                                                                                            s3 = peg$parseIntegerSlashList();
                                                                                                            if (s3 !== peg$FAILED) {
                                                                                                              s4 = peg$parse_();
                                                                                                              if (s4 !== peg$FAILED) {
                                                                                                                if (input.substr(peg$currPos, 5) === peg$c619) {
                                                                                                                  s5 = peg$c619;
                                                                                                                  peg$currPos += 5;
                                                                                                                } else {
                                                                                                                  s5 = peg$FAILED;
                                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c620); }
                                                                                                                }
                                                                                                                if (s5 !== peg$FAILED) {
                                                                                                                  s6 = peg$parse_();
                                                                                                                  if (s6 !== peg$FAILED) {
                                                                                                                    s7 = peg$parseSchool();
                                                                                                                    if (s7 !== peg$FAILED) {
                                                                                                                      s8 = peg$parse_();
                                                                                                                      if (s8 !== peg$FAILED) {
                                                                                                                        if (input.substr(peg$currPos, 5) === peg$c621) {
                                                                                                                          s9 = peg$c621;
                                                                                                                          peg$currPos += 5;
                                                                                                                        } else {
                                                                                                                          s9 = peg$FAILED;
                                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c622); }
                                                                                                                        }
                                                                                                                        if (s9 !== peg$FAILED) {
                                                                                                                          peg$savedPos = s0;
                                                                                                                          s1 = peg$c623(s3, s7);
                                                                                                                          s0 = s1;
                                                                                                                        } else {
                                                                                                                          peg$currPos = s0;
                                                                                                                          s0 = peg$FAILED;
                                                                                                                        }
                                                                                                                      } else {
                                                                                                                        peg$currPos = s0;
                                                                                                                        s0 = peg$FAILED;
                                                                                                                      }
                                                                                                                    } else {
                                                                                                                      peg$currPos = s0;
                                                                                                                      s0 = peg$FAILED;
                                                                                                                    }
                                                                                                                  } else {
                                                                                                                    peg$currPos = s0;
                                                                                                                    s0 = peg$FAILED;
                                                                                                                  }
                                                                                                                } else {
                                                                                                                  peg$currPos = s0;
                                                                                                                  s0 = peg$FAILED;
                                                                                                                }
                                                                                                              } else {
                                                                                                                peg$currPos = s0;
                                                                                                                s0 = peg$FAILED;
                                                                                                              }
                                                                                                            } else {
                                                                                                              peg$currPos = s0;
                                                                                                              s0 = peg$FAILED;
                                                                                                            }
                                                                                                          } else {
                                                                                                            peg$currPos = s0;
                                                                                                            s0 = peg$FAILED;
                                                                                                          }
                                                                                                        } else {
                                                                                                          peg$currPos = s0;
                                                                                                          s0 = peg$FAILED;
                                                                                                        }
                                                                                                        if (s0 === peg$FAILED) {
                                                                                                          s0 = peg$currPos;
                                                                                                          if (input.substr(peg$currPos, 2) === peg$c148) {
                                                                                                            s1 = peg$c148;
                                                                                                            peg$currPos += 2;
                                                                                                          } else {
                                                                                                            s1 = peg$FAILED;
                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c149); }
                                                                                                          }
                                                                                                          if (s1 !== peg$FAILED) {
                                                                                                            s2 = peg$parse_();
                                                                                                            if (s2 !== peg$FAILED) {
                                                                                                              s3 = peg$parseIntegerSlashList();
                                                                                                              if (s3 !== peg$FAILED) {
                                                                                                                s4 = peg$parse_();
                                                                                                                if (s4 !== peg$FAILED) {
                                                                                                                  if (input.substr(peg$currPos, 9) === peg$c624) {
                                                                                                                    s5 = peg$c624;
                                                                                                                    peg$currPos += 9;
                                                                                                                  } else {
                                                                                                                    s5 = peg$FAILED;
                                                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c625); }
                                                                                                                  }
                                                                                                                  if (s5 !== peg$FAILED) {
                                                                                                                    s6 = peg$parse_();
                                                                                                                    if (s6 !== peg$FAILED) {
                                                                                                                      s7 = peg$parseSchool();
                                                                                                                      if (s7 !== peg$FAILED) {
                                                                                                                        s8 = peg$parse_();
                                                                                                                        if (s8 !== peg$FAILED) {
                                                                                                                          if (input.substr(peg$currPos, 9) === peg$c106) {
                                                                                                                            s9 = peg$c106;
                                                                                                                            peg$currPos += 9;
                                                                                                                          } else {
                                                                                                                            s9 = peg$FAILED;
                                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c107); }
                                                                                                                          }
                                                                                                                          if (s9 !== peg$FAILED) {
                                                                                                                            s10 = peg$parse_();
                                                                                                                            if (s10 !== peg$FAILED) {
                                                                                                                              if (input.substr(peg$currPos, 4) === peg$c108) {
                                                                                                                                s11 = peg$c108;
                                                                                                                                peg$currPos += 4;
                                                                                                                              } else {
                                                                                                                                s11 = peg$FAILED;
                                                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c109); }
                                                                                                                              }
                                                                                                                              if (s11 !== peg$FAILED) {
                                                                                                                                peg$savedPos = s0;
                                                                                                                                s1 = peg$c626(s3, s7);
                                                                                                                                s0 = s1;
                                                                                                                              } else {
                                                                                                                                peg$currPos = s0;
                                                                                                                                s0 = peg$FAILED;
                                                                                                                              }
                                                                                                                            } else {
                                                                                                                              peg$currPos = s0;
                                                                                                                              s0 = peg$FAILED;
                                                                                                                            }
                                                                                                                          } else {
                                                                                                                            peg$currPos = s0;
                                                                                                                            s0 = peg$FAILED;
                                                                                                                          }
                                                                                                                        } else {
                                                                                                                          peg$currPos = s0;
                                                                                                                          s0 = peg$FAILED;
                                                                                                                        }
                                                                                                                      } else {
                                                                                                                        peg$currPos = s0;
                                                                                                                        s0 = peg$FAILED;
                                                                                                                      }
                                                                                                                    } else {
                                                                                                                      peg$currPos = s0;
                                                                                                                      s0 = peg$FAILED;
                                                                                                                    }
                                                                                                                  } else {
                                                                                                                    peg$currPos = s0;
                                                                                                                    s0 = peg$FAILED;
                                                                                                                  }
                                                                                                                } else {
                                                                                                                  peg$currPos = s0;
                                                                                                                  s0 = peg$FAILED;
                                                                                                                }
                                                                                                              } else {
                                                                                                                peg$currPos = s0;
                                                                                                                s0 = peg$FAILED;
                                                                                                              }
                                                                                                            } else {
                                                                                                              peg$currPos = s0;
                                                                                                              s0 = peg$FAILED;
                                                                                                            }
                                                                                                          } else {
                                                                                                            peg$currPos = s0;
                                                                                                            s0 = peg$FAILED;
                                                                                                          }
                                                                                                          if (s0 === peg$FAILED) {
                                                                                                            s0 = peg$currPos;
                                                                                                            if (input.substr(peg$currPos, 2) === peg$c203) {
                                                                                                              s1 = peg$c203;
                                                                                                              peg$currPos += 2;
                                                                                                            } else {
                                                                                                              s1 = peg$FAILED;
                                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                                                                            }
                                                                                                            if (s1 !== peg$FAILED) {
                                                                                                              s2 = peg$parse_();
                                                                                                              if (s2 !== peg$FAILED) {
                                                                                                                if (input.substr(peg$currPos, 3) === peg$c186) {
                                                                                                                  s3 = peg$c186;
                                                                                                                  peg$currPos += 3;
                                                                                                                } else {
                                                                                                                  s3 = peg$FAILED;
                                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c187); }
                                                                                                                }
                                                                                                                if (s3 !== peg$FAILED) {
                                                                                                                  s4 = peg$parse_();
                                                                                                                  if (s4 !== peg$FAILED) {
                                                                                                                    if (input.substr(peg$currPos, 4) === peg$c467) {
                                                                                                                      s5 = peg$c467;
                                                                                                                      peg$currPos += 4;
                                                                                                                    } else {
                                                                                                                      s5 = peg$FAILED;
                                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c468); }
                                                                                                                    }
                                                                                                                    if (s5 !== peg$FAILED) {
                                                                                                                      s6 = peg$parse_();
                                                                                                                      if (s6 !== peg$FAILED) {
                                                                                                                        if (input.substr(peg$currPos, 4) === peg$c108) {
                                                                                                                          s7 = peg$c108;
                                                                                                                          peg$currPos += 4;
                                                                                                                        } else {
                                                                                                                          s7 = peg$FAILED;
                                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c109); }
                                                                                                                        }
                                                                                                                        if (s7 !== peg$FAILED) {
                                                                                                                          s8 = peg$parse_();
                                                                                                                          if (s8 !== peg$FAILED) {
                                                                                                                            s9 = peg$parseIntegerSlashList();
                                                                                                                            if (s9 !== peg$FAILED) {
                                                                                                                              s10 = peg$parse_();
                                                                                                                              if (s10 !== peg$FAILED) {
                                                                                                                                s11 = peg$parseSchoolList();
                                                                                                                                if (s11 !== peg$FAILED) {
                                                                                                                                  s12 = peg$parse_();
                                                                                                                                  if (s12 !== peg$FAILED) {
                                                                                                                                    if (input.substr(peg$currPos, 9) === peg$c106) {
                                                                                                                                      s13 = peg$c106;
                                                                                                                                      peg$currPos += 9;
                                                                                                                                    } else {
                                                                                                                                      s13 = peg$FAILED;
                                                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c107); }
                                                                                                                                    }
                                                                                                                                    if (s13 !== peg$FAILED) {
                                                                                                                                      s14 = peg$parse_();
                                                                                                                                      if (s14 !== peg$FAILED) {
                                                                                                                                        if (input.substr(peg$currPos, 6) === peg$c190) {
                                                                                                                                          s15 = peg$c190;
                                                                                                                                          peg$currPos += 6;
                                                                                                                                        } else {
                                                                                                                                          s15 = peg$FAILED;
                                                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c191); }
                                                                                                                                        }
                                                                                                                                        if (s15 !== peg$FAILED) {
                                                                                                                                          s16 = peg$parse_();
                                                                                                                                          if (s16 !== peg$FAILED) {
                                                                                                                                            if (input.substr(peg$currPos, 3) === peg$c186) {
                                                                                                                                              s17 = peg$c186;
                                                                                                                                              peg$currPos += 3;
                                                                                                                                            } else {
                                                                                                                                              s17 = peg$FAILED;
                                                                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c187); }
                                                                                                                                            }
                                                                                                                                            if (s17 !== peg$FAILED) {
                                                                                                                                              s18 = peg$parse_();
                                                                                                                                              if (s18 !== peg$FAILED) {
                                                                                                                                                if (input.substr(peg$currPos, 6) === peg$c192) {
                                                                                                                                                  s19 = peg$c192;
                                                                                                                                                  peg$currPos += 6;
                                                                                                                                                } else {
                                                                                                                                                  s19 = peg$FAILED;
                                                                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c193); }
                                                                                                                                                }
                                                                                                                                                if (s19 !== peg$FAILED) {
                                                                                                                                                  peg$savedPos = s0;
                                                                                                                                                  s1 = peg$c627(s9, s11);
                                                                                                                                                  s0 = s1;
                                                                                                                                                } else {
                                                                                                                                                  peg$currPos = s0;
                                                                                                                                                  s0 = peg$FAILED;
                                                                                                                                                }
                                                                                                                                              } else {
                                                                                                                                                peg$currPos = s0;
                                                                                                                                                s0 = peg$FAILED;
                                                                                                                                              }
                                                                                                                                            } else {
                                                                                                                                              peg$currPos = s0;
                                                                                                                                              s0 = peg$FAILED;
                                                                                                                                            }
                                                                                                                                          } else {
                                                                                                                                            peg$currPos = s0;
                                                                                                                                            s0 = peg$FAILED;
                                                                                                                                          }
                                                                                                                                        } else {
                                                                                                                                          peg$currPos = s0;
                                                                                                                                          s0 = peg$FAILED;
                                                                                                                                        }
                                                                                                                                      } else {
                                                                                                                                        peg$currPos = s0;
                                                                                                                                        s0 = peg$FAILED;
                                                                                                                                      }
                                                                                                                                    } else {
                                                                                                                                      peg$currPos = s0;
                                                                                                                                      s0 = peg$FAILED;
                                                                                                                                    }
                                                                                                                                  } else {
                                                                                                                                    peg$currPos = s0;
                                                                                                                                    s0 = peg$FAILED;
                                                                                                                                  }
                                                                                                                                } else {
                                                                                                                                  peg$currPos = s0;
                                                                                                                                  s0 = peg$FAILED;
                                                                                                                                }
                                                                                                                              } else {
                                                                                                                                peg$currPos = s0;
                                                                                                                                s0 = peg$FAILED;
                                                                                                                              }
                                                                                                                            } else {
                                                                                                                              peg$currPos = s0;
                                                                                                                              s0 = peg$FAILED;
                                                                                                                            }
                                                                                                                          } else {
                                                                                                                            peg$currPos = s0;
                                                                                                                            s0 = peg$FAILED;
                                                                                                                          }
                                                                                                                        } else {
                                                                                                                          peg$currPos = s0;
                                                                                                                          s0 = peg$FAILED;
                                                                                                                        }
                                                                                                                      } else {
                                                                                                                        peg$currPos = s0;
                                                                                                                        s0 = peg$FAILED;
                                                                                                                      }
                                                                                                                    } else {
                                                                                                                      peg$currPos = s0;
                                                                                                                      s0 = peg$FAILED;
                                                                                                                    }
                                                                                                                  } else {
                                                                                                                    peg$currPos = s0;
                                                                                                                    s0 = peg$FAILED;
                                                                                                                  }
                                                                                                                } else {
                                                                                                                  peg$currPos = s0;
                                                                                                                  s0 = peg$FAILED;
                                                                                                                }
                                                                                                              } else {
                                                                                                                peg$currPos = s0;
                                                                                                                s0 = peg$FAILED;
                                                                                                              }
                                                                                                            } else {
                                                                                                              peg$currPos = s0;
                                                                                                              s0 = peg$FAILED;
                                                                                                            }
                                                                                                            if (s0 === peg$FAILED) {
                                                                                                              s0 = peg$currPos;
                                                                                                              if (input.substr(peg$currPos, 2) === peg$c203) {
                                                                                                                s1 = peg$c203;
                                                                                                                peg$currPos += 2;
                                                                                                              } else {
                                                                                                                s1 = peg$FAILED;
                                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                                                                              }
                                                                                                              if (s1 !== peg$FAILED) {
                                                                                                                s2 = peg$parse_();
                                                                                                                if (s2 !== peg$FAILED) {
                                                                                                                  if (input.substr(peg$currPos, 3) === peg$c186) {
                                                                                                                    s3 = peg$c186;
                                                                                                                    peg$currPos += 3;
                                                                                                                  } else {
                                                                                                                    s3 = peg$FAILED;
                                                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c187); }
                                                                                                                  }
                                                                                                                  if (s3 !== peg$FAILED) {
                                                                                                                    s4 = peg$parse_();
                                                                                                                    if (s4 !== peg$FAILED) {
                                                                                                                      if (input.substr(peg$currPos, 4) === peg$c467) {
                                                                                                                        s5 = peg$c467;
                                                                                                                        peg$currPos += 4;
                                                                                                                      } else {
                                                                                                                        s5 = peg$FAILED;
                                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c468); }
                                                                                                                      }
                                                                                                                      if (s5 !== peg$FAILED) {
                                                                                                                        s6 = peg$parse_();
                                                                                                                        if (s6 !== peg$FAILED) {
                                                                                                                          if (input.substr(peg$currPos, 4) === peg$c108) {
                                                                                                                            s7 = peg$c108;
                                                                                                                            peg$currPos += 4;
                                                                                                                          } else {
                                                                                                                            s7 = peg$FAILED;
                                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c109); }
                                                                                                                          }
                                                                                                                          if (s7 !== peg$FAILED) {
                                                                                                                            s8 = peg$parse_();
                                                                                                                            if (s8 !== peg$FAILED) {
                                                                                                                              s9 = peg$parseIntegerSlashList();
                                                                                                                              if (s9 !== peg$FAILED) {
                                                                                                                                s10 = peg$parse_();
                                                                                                                                if (s10 !== peg$FAILED) {
                                                                                                                                  s11 = peg$parseSchoolList();
                                                                                                                                  if (s11 !== peg$FAILED) {
                                                                                                                                    s12 = peg$parse_();
                                                                                                                                    if (s12 !== peg$FAILED) {
                                                                                                                                      if (input.substr(peg$currPos, 9) === peg$c106) {
                                                                                                                                        s13 = peg$c106;
                                                                                                                                        peg$currPos += 9;
                                                                                                                                      } else {
                                                                                                                                        s13 = peg$FAILED;
                                                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c107); }
                                                                                                                                      }
                                                                                                                                      if (s13 !== peg$FAILED) {
                                                                                                                                        peg$savedPos = s0;
                                                                                                                                        s1 = peg$c628(s9, s11);
                                                                                                                                        s0 = s1;
                                                                                                                                      } else {
                                                                                                                                        peg$currPos = s0;
                                                                                                                                        s0 = peg$FAILED;
                                                                                                                                      }
                                                                                                                                    } else {
                                                                                                                                      peg$currPos = s0;
                                                                                                                                      s0 = peg$FAILED;
                                                                                                                                    }
                                                                                                                                  } else {
                                                                                                                                    peg$currPos = s0;
                                                                                                                                    s0 = peg$FAILED;
                                                                                                                                  }
                                                                                                                                } else {
                                                                                                                                  peg$currPos = s0;
                                                                                                                                  s0 = peg$FAILED;
                                                                                                                                }
                                                                                                                              } else {
                                                                                                                                peg$currPos = s0;
                                                                                                                                s0 = peg$FAILED;
                                                                                                                              }
                                                                                                                            } else {
                                                                                                                              peg$currPos = s0;
                                                                                                                              s0 = peg$FAILED;
                                                                                                                            }
                                                                                                                          } else {
                                                                                                                            peg$currPos = s0;
                                                                                                                            s0 = peg$FAILED;
                                                                                                                          }
                                                                                                                        } else {
                                                                                                                          peg$currPos = s0;
                                                                                                                          s0 = peg$FAILED;
                                                                                                                        }
                                                                                                                      } else {
                                                                                                                        peg$currPos = s0;
                                                                                                                        s0 = peg$FAILED;
                                                                                                                      }
                                                                                                                    } else {
                                                                                                                      peg$currPos = s0;
                                                                                                                      s0 = peg$FAILED;
                                                                                                                    }
                                                                                                                  } else {
                                                                                                                    peg$currPos = s0;
                                                                                                                    s0 = peg$FAILED;
                                                                                                                  }
                                                                                                                } else {
                                                                                                                  peg$currPos = s0;
                                                                                                                  s0 = peg$FAILED;
                                                                                                                }
                                                                                                              } else {
                                                                                                                peg$currPos = s0;
                                                                                                                s0 = peg$FAILED;
                                                                                                              }
                                                                                                              if (s0 === peg$FAILED) {
                                                                                                                s0 = peg$currPos;
                                                                                                                if (input.substr(peg$currPos, 2) === peg$c203) {
                                                                                                                  s1 = peg$c203;
                                                                                                                  peg$currPos += 2;
                                                                                                                } else {
                                                                                                                  s1 = peg$FAILED;
                                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                                                                                }
                                                                                                                if (s1 !== peg$FAILED) {
                                                                                                                  s2 = peg$parse_();
                                                                                                                  if (s2 !== peg$FAILED) {
                                                                                                                    if (input.substr(peg$currPos, 3) === peg$c186) {
                                                                                                                      s3 = peg$c186;
                                                                                                                      peg$currPos += 3;
                                                                                                                    } else {
                                                                                                                      s3 = peg$FAILED;
                                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c187); }
                                                                                                                    }
                                                                                                                    if (s3 !== peg$FAILED) {
                                                                                                                      s4 = peg$parse_();
                                                                                                                      if (s4 !== peg$FAILED) {
                                                                                                                        if (input.substr(peg$currPos, 4) === peg$c467) {
                                                                                                                          s5 = peg$c467;
                                                                                                                          peg$currPos += 4;
                                                                                                                        } else {
                                                                                                                          s5 = peg$FAILED;
                                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c468); }
                                                                                                                        }
                                                                                                                        if (s5 !== peg$FAILED) {
                                                                                                                          s6 = peg$parse_();
                                                                                                                          if (s6 !== peg$FAILED) {
                                                                                                                            if (input.substr(peg$currPos, 4) === peg$c108) {
                                                                                                                              s7 = peg$c108;
                                                                                                                              peg$currPos += 4;
                                                                                                                            } else {
                                                                                                                              s7 = peg$FAILED;
                                                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c109); }
                                                                                                                            }
                                                                                                                            if (s7 !== peg$FAILED) {
                                                                                                                              s8 = peg$parse_();
                                                                                                                              if (s8 !== peg$FAILED) {
                                                                                                                                s9 = peg$parseIntegerSlashList();
                                                                                                                                if (s9 !== peg$FAILED) {
                                                                                                                                  s10 = peg$parse_();
                                                                                                                                  if (s10 !== peg$FAILED) {
                                                                                                                                    s11 = peg$parseElementList();
                                                                                                                                    if (s11 !== peg$FAILED) {
                                                                                                                                      s12 = peg$parse_();
                                                                                                                                      if (s12 !== peg$FAILED) {
                                                                                                                                        if (input.substr(peg$currPos, 7) === peg$c111) {
                                                                                                                                          s13 = peg$c111;
                                                                                                                                          peg$currPos += 7;
                                                                                                                                        } else {
                                                                                                                                          s13 = peg$FAILED;
                                                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c112); }
                                                                                                                                        }
                                                                                                                                        if (s13 !== peg$FAILED) {
                                                                                                                                          s14 = peg$parse_();
                                                                                                                                          if (s14 !== peg$FAILED) {
                                                                                                                                            if (input.substr(peg$currPos, 6) === peg$c190) {
                                                                                                                                              s15 = peg$c190;
                                                                                                                                              peg$currPos += 6;
                                                                                                                                            } else {
                                                                                                                                              s15 = peg$FAILED;
                                                                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c191); }
                                                                                                                                            }
                                                                                                                                            if (s15 !== peg$FAILED) {
                                                                                                                                              s16 = peg$parse_();
                                                                                                                                              if (s16 !== peg$FAILED) {
                                                                                                                                                if (input.substr(peg$currPos, 3) === peg$c186) {
                                                                                                                                                  s17 = peg$c186;
                                                                                                                                                  peg$currPos += 3;
                                                                                                                                                } else {
                                                                                                                                                  s17 = peg$FAILED;
                                                                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c187); }
                                                                                                                                                }
                                                                                                                                                if (s17 !== peg$FAILED) {
                                                                                                                                                  s18 = peg$parse_();
                                                                                                                                                  if (s18 !== peg$FAILED) {
                                                                                                                                                    if (input.substr(peg$currPos, 6) === peg$c192) {
                                                                                                                                                      s19 = peg$c192;
                                                                                                                                                      peg$currPos += 6;
                                                                                                                                                    } else {
                                                                                                                                                      s19 = peg$FAILED;
                                                                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c193); }
                                                                                                                                                    }
                                                                                                                                                    if (s19 !== peg$FAILED) {
                                                                                                                                                      peg$savedPos = s0;
                                                                                                                                                      s1 = peg$c629(s9, s11);
                                                                                                                                                      s0 = s1;
                                                                                                                                                    } else {
                                                                                                                                                      peg$currPos = s0;
                                                                                                                                                      s0 = peg$FAILED;
                                                                                                                                                    }
                                                                                                                                                  } else {
                                                                                                                                                    peg$currPos = s0;
                                                                                                                                                    s0 = peg$FAILED;
                                                                                                                                                  }
                                                                                                                                                } else {
                                                                                                                                                  peg$currPos = s0;
                                                                                                                                                  s0 = peg$FAILED;
                                                                                                                                                }
                                                                                                                                              } else {
                                                                                                                                                peg$currPos = s0;
                                                                                                                                                s0 = peg$FAILED;
                                                                                                                                              }
                                                                                                                                            } else {
                                                                                                                                              peg$currPos = s0;
                                                                                                                                              s0 = peg$FAILED;
                                                                                                                                            }
                                                                                                                                          } else {
                                                                                                                                            peg$currPos = s0;
                                                                                                                                            s0 = peg$FAILED;
                                                                                                                                          }
                                                                                                                                        } else {
                                                                                                                                          peg$currPos = s0;
                                                                                                                                          s0 = peg$FAILED;
                                                                                                                                        }
                                                                                                                                      } else {
                                                                                                                                        peg$currPos = s0;
                                                                                                                                        s0 = peg$FAILED;
                                                                                                                                      }
                                                                                                                                    } else {
                                                                                                                                      peg$currPos = s0;
                                                                                                                                      s0 = peg$FAILED;
                                                                                                                                    }
                                                                                                                                  } else {
                                                                                                                                    peg$currPos = s0;
                                                                                                                                    s0 = peg$FAILED;
                                                                                                                                  }
                                                                                                                                } else {
                                                                                                                                  peg$currPos = s0;
                                                                                                                                  s0 = peg$FAILED;
                                                                                                                                }
                                                                                                                              } else {
                                                                                                                                peg$currPos = s0;
                                                                                                                                s0 = peg$FAILED;
                                                                                                                              }
                                                                                                                            } else {
                                                                                                                              peg$currPos = s0;
                                                                                                                              s0 = peg$FAILED;
                                                                                                                            }
                                                                                                                          } else {
                                                                                                                            peg$currPos = s0;
                                                                                                                            s0 = peg$FAILED;
                                                                                                                          }
                                                                                                                        } else {
                                                                                                                          peg$currPos = s0;
                                                                                                                          s0 = peg$FAILED;
                                                                                                                        }
                                                                                                                      } else {
                                                                                                                        peg$currPos = s0;
                                                                                                                        s0 = peg$FAILED;
                                                                                                                      }
                                                                                                                    } else {
                                                                                                                      peg$currPos = s0;
                                                                                                                      s0 = peg$FAILED;
                                                                                                                    }
                                                                                                                  } else {
                                                                                                                    peg$currPos = s0;
                                                                                                                    s0 = peg$FAILED;
                                                                                                                  }
                                                                                                                } else {
                                                                                                                  peg$currPos = s0;
                                                                                                                  s0 = peg$FAILED;
                                                                                                                }
                                                                                                                if (s0 === peg$FAILED) {
                                                                                                                  s0 = peg$currPos;
                                                                                                                  if (input.substr(peg$currPos, 2) === peg$c203) {
                                                                                                                    s1 = peg$c203;
                                                                                                                    peg$currPos += 2;
                                                                                                                  } else {
                                                                                                                    s1 = peg$FAILED;
                                                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                                                                                  }
                                                                                                                  if (s1 !== peg$FAILED) {
                                                                                                                    s2 = peg$parse_();
                                                                                                                    if (s2 !== peg$FAILED) {
                                                                                                                      s3 = peg$parseIntegerSlashList();
                                                                                                                      if (s3 !== peg$FAILED) {
                                                                                                                        s4 = peg$parse_();
                                                                                                                        if (s4 !== peg$FAILED) {
                                                                                                                          if (input.substr(peg$currPos, 34) === peg$c630) {
                                                                                                                            s5 = peg$c630;
                                                                                                                            peg$currPos += 34;
                                                                                                                          } else {
                                                                                                                            s5 = peg$FAILED;
                                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c631); }
                                                                                                                          }
                                                                                                                          if (s5 !== peg$FAILED) {
                                                                                                                            peg$savedPos = s0;
                                                                                                                            s1 = peg$c632(s3);
                                                                                                                            s0 = s1;
                                                                                                                          } else {
                                                                                                                            peg$currPos = s0;
                                                                                                                            s0 = peg$FAILED;
                                                                                                                          }
                                                                                                                        } else {
                                                                                                                          peg$currPos = s0;
                                                                                                                          s0 = peg$FAILED;
                                                                                                                        }
                                                                                                                      } else {
                                                                                                                        peg$currPos = s0;
                                                                                                                        s0 = peg$FAILED;
                                                                                                                      }
                                                                                                                    } else {
                                                                                                                      peg$currPos = s0;
                                                                                                                      s0 = peg$FAILED;
                                                                                                                    }
                                                                                                                  } else {
                                                                                                                    peg$currPos = s0;
                                                                                                                    s0 = peg$FAILED;
                                                                                                                  }
                                                                                                                  if (s0 === peg$FAILED) {
                                                                                                                    s0 = peg$currPos;
                                                                                                                    if (input.substr(peg$currPos, 2) === peg$c203) {
                                                                                                                      s1 = peg$c203;
                                                                                                                      peg$currPos += 2;
                                                                                                                    } else {
                                                                                                                      s1 = peg$FAILED;
                                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                                                                                    }
                                                                                                                    if (s1 !== peg$FAILED) {
                                                                                                                      s2 = peg$parse_();
                                                                                                                      if (s2 !== peg$FAILED) {
                                                                                                                        if (input.substr(peg$currPos, 3) === peg$c186) {
                                                                                                                          s3 = peg$c186;
                                                                                                                          peg$currPos += 3;
                                                                                                                        } else {
                                                                                                                          s3 = peg$FAILED;
                                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c187); }
                                                                                                                        }
                                                                                                                        if (s3 === peg$FAILED) {
                                                                                                                          s3 = null;
                                                                                                                        }
                                                                                                                        if (s3 !== peg$FAILED) {
                                                                                                                          s4 = peg$parse_();
                                                                                                                          if (s4 !== peg$FAILED) {
                                                                                                                            if (input.substr(peg$currPos, 10) === peg$c633) {
                                                                                                                              s5 = peg$c633;
                                                                                                                              peg$currPos += 10;
                                                                                                                            } else {
                                                                                                                              s5 = peg$FAILED;
                                                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c634); }
                                                                                                                            }
                                                                                                                            if (s5 !== peg$FAILED) {
                                                                                                                              s6 = peg$parse_();
                                                                                                                              if (s6 !== peg$FAILED) {
                                                                                                                                s7 = peg$parseIntegerSlashList();
                                                                                                                                if (s7 !== peg$FAILED) {
                                                                                                                                  s8 = peg$parse_();
                                                                                                                                  if (s8 !== peg$FAILED) {
                                                                                                                                    if (input.substr(peg$currPos, 24) === peg$c635) {
                                                                                                                                      s9 = peg$c635;
                                                                                                                                      peg$currPos += 24;
                                                                                                                                    } else {
                                                                                                                                      s9 = peg$FAILED;
                                                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c636); }
                                                                                                                                    }
                                                                                                                                    if (s9 !== peg$FAILED) {
                                                                                                                                      peg$savedPos = s0;
                                                                                                                                      s1 = peg$c632(s7);
                                                                                                                                      s0 = s1;
                                                                                                                                    } else {
                                                                                                                                      peg$currPos = s0;
                                                                                                                                      s0 = peg$FAILED;
                                                                                                                                    }
                                                                                                                                  } else {
                                                                                                                                    peg$currPos = s0;
                                                                                                                                    s0 = peg$FAILED;
                                                                                                                                  }
                                                                                                                                } else {
                                                                                                                                  peg$currPos = s0;
                                                                                                                                  s0 = peg$FAILED;
                                                                                                                                }
                                                                                                                              } else {
                                                                                                                                peg$currPos = s0;
                                                                                                                                s0 = peg$FAILED;
                                                                                                                              }
                                                                                                                            } else {
                                                                                                                              peg$currPos = s0;
                                                                                                                              s0 = peg$FAILED;
                                                                                                                            }
                                                                                                                          } else {
                                                                                                                            peg$currPos = s0;
                                                                                                                            s0 = peg$FAILED;
                                                                                                                          }
                                                                                                                        } else {
                                                                                                                          peg$currPos = s0;
                                                                                                                          s0 = peg$FAILED;
                                                                                                                        }
                                                                                                                      } else {
                                                                                                                        peg$currPos = s0;
                                                                                                                        s0 = peg$FAILED;
                                                                                                                      }
                                                                                                                    } else {
                                                                                                                      peg$currPos = s0;
                                                                                                                      s0 = peg$FAILED;
                                                                                                                    }
                                                                                                                    if (s0 === peg$FAILED) {
                                                                                                                      s0 = peg$currPos;
                                                                                                                      if (input.substr(peg$currPos, 2) === peg$c203) {
                                                                                                                        s1 = peg$c203;
                                                                                                                        peg$currPos += 2;
                                                                                                                      } else {
                                                                                                                        s1 = peg$FAILED;
                                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                                                                                      }
                                                                                                                      if (s1 !== peg$FAILED) {
                                                                                                                        s2 = peg$parse_();
                                                                                                                        if (s2 !== peg$FAILED) {
                                                                                                                          if (input.substr(peg$currPos, 3) === peg$c186) {
                                                                                                                            s3 = peg$c186;
                                                                                                                            peg$currPos += 3;
                                                                                                                          } else {
                                                                                                                            s3 = peg$FAILED;
                                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c187); }
                                                                                                                          }
                                                                                                                          if (s3 === peg$FAILED) {
                                                                                                                            s3 = null;
                                                                                                                          }
                                                                                                                          if (s3 !== peg$FAILED) {
                                                                                                                            s4 = peg$parse_();
                                                                                                                            if (s4 !== peg$FAILED) {
                                                                                                                              if (input.substr(peg$currPos, 10) === peg$c633) {
                                                                                                                                s5 = peg$c633;
                                                                                                                                peg$currPos += 10;
                                                                                                                              } else {
                                                                                                                                s5 = peg$FAILED;
                                                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c634); }
                                                                                                                              }
                                                                                                                              if (s5 !== peg$FAILED) {
                                                                                                                                s6 = peg$parse_();
                                                                                                                                if (s6 !== peg$FAILED) {
                                                                                                                                  s7 = peg$parseIntegerSlashList();
                                                                                                                                  if (s7 !== peg$FAILED) {
                                                                                                                                    s8 = peg$parse_();
                                                                                                                                    if (s8 !== peg$FAILED) {
                                                                                                                                      if (input.substr(peg$currPos, 11) === peg$c637) {
                                                                                                                                        s9 = peg$c637;
                                                                                                                                        peg$currPos += 11;
                                                                                                                                      } else {
                                                                                                                                        s9 = peg$FAILED;
                                                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c638); }
                                                                                                                                      }
                                                                                                                                      if (s9 !== peg$FAILED) {
                                                                                                                                        s10 = peg$parse_();
                                                                                                                                        if (s10 !== peg$FAILED) {
                                                                                                                                          s11 = peg$parseElementList();
                                                                                                                                          if (s11 !== peg$FAILED) {
                                                                                                                                            s12 = peg$parse_();
                                                                                                                                            if (s12 !== peg$FAILED) {
                                                                                                                                              if (input.substr(peg$currPos, 25) === peg$c639) {
                                                                                                                                                s13 = peg$c639;
                                                                                                                                                peg$currPos += 25;
                                                                                                                                              } else {
                                                                                                                                                s13 = peg$FAILED;
                                                                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c640); }
                                                                                                                                              }
                                                                                                                                              if (s13 !== peg$FAILED) {
                                                                                                                                                peg$savedPos = s0;
                                                                                                                                                s1 = peg$c641(s7, s11);
                                                                                                                                                s0 = s1;
                                                                                                                                              } else {
                                                                                                                                                peg$currPos = s0;
                                                                                                                                                s0 = peg$FAILED;
                                                                                                                                              }
                                                                                                                                            } else {
                                                                                                                                              peg$currPos = s0;
                                                                                                                                              s0 = peg$FAILED;
                                                                                                                                            }
                                                                                                                                          } else {
                                                                                                                                            peg$currPos = s0;
                                                                                                                                            s0 = peg$FAILED;
                                                                                                                                          }
                                                                                                                                        } else {
                                                                                                                                          peg$currPos = s0;
                                                                                                                                          s0 = peg$FAILED;
                                                                                                                                        }
                                                                                                                                      } else {
                                                                                                                                        peg$currPos = s0;
                                                                                                                                        s0 = peg$FAILED;
                                                                                                                                      }
                                                                                                                                    } else {
                                                                                                                                      peg$currPos = s0;
                                                                                                                                      s0 = peg$FAILED;
                                                                                                                                    }
                                                                                                                                  } else {
                                                                                                                                    peg$currPos = s0;
                                                                                                                                    s0 = peg$FAILED;
                                                                                                                                  }
                                                                                                                                } else {
                                                                                                                                  peg$currPos = s0;
                                                                                                                                  s0 = peg$FAILED;
                                                                                                                                }
                                                                                                                              } else {
                                                                                                                                peg$currPos = s0;
                                                                                                                                s0 = peg$FAILED;
                                                                                                                              }
                                                                                                                            } else {
                                                                                                                              peg$currPos = s0;
                                                                                                                              s0 = peg$FAILED;
                                                                                                                            }
                                                                                                                          } else {
                                                                                                                            peg$currPos = s0;
                                                                                                                            s0 = peg$FAILED;
                                                                                                                          }
                                                                                                                        } else {
                                                                                                                          peg$currPos = s0;
                                                                                                                          s0 = peg$FAILED;
                                                                                                                        }
                                                                                                                      } else {
                                                                                                                        peg$currPos = s0;
                                                                                                                        s0 = peg$FAILED;
                                                                                                                      }
                                                                                                                      if (s0 === peg$FAILED) {
                                                                                                                        s0 = peg$currPos;
                                                                                                                        if (input.substr(peg$currPos, 2) === peg$c203) {
                                                                                                                          s1 = peg$c203;
                                                                                                                          peg$currPos += 2;
                                                                                                                        } else {
                                                                                                                          s1 = peg$FAILED;
                                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c204); }
                                                                                                                        }
                                                                                                                        if (s1 !== peg$FAILED) {
                                                                                                                          s2 = peg$parse_();
                                                                                                                          if (s2 !== peg$FAILED) {
                                                                                                                            if (input.substr(peg$currPos, 3) === peg$c186) {
                                                                                                                              s3 = peg$c186;
                                                                                                                              peg$currPos += 3;
                                                                                                                            } else {
                                                                                                                              s3 = peg$FAILED;
                                                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c187); }
                                                                                                                            }
                                                                                                                            if (s3 !== peg$FAILED) {
                                                                                                                              s4 = peg$parse_();
                                                                                                                              if (s4 !== peg$FAILED) {
                                                                                                                                if (input.substr(peg$currPos, 5) === peg$c642) {
                                                                                                                                  s5 = peg$c642;
                                                                                                                                  peg$currPos += 5;
                                                                                                                                } else {
                                                                                                                                  s5 = peg$FAILED;
                                                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c643); }
                                                                                                                                }
                                                                                                                                if (s5 !== peg$FAILED) {
                                                                                                                                  s6 = peg$parse_();
                                                                                                                                  if (s6 !== peg$FAILED) {
                                                                                                                                    if (input.substr(peg$currPos, 6) === peg$c42) {
                                                                                                                                      s7 = peg$c42;
                                                                                                                                      peg$currPos += 6;
                                                                                                                                    } else {
                                                                                                                                      s7 = peg$FAILED;
                                                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c43); }
                                                                                                                                    }
                                                                                                                                    if (s7 !== peg$FAILED) {
                                                                                                                                      s8 = peg$parse_();
                                                                                                                                      if (s8 !== peg$FAILED) {
                                                                                                                                        if (input.substr(peg$currPos, 9) === peg$c644) {
                                                                                                                                          s9 = peg$c644;
                                                                                                                                          peg$currPos += 9;
                                                                                                                                        } else {
                                                                                                                                          s9 = peg$FAILED;
                                                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c645); }
                                                                                                                                        }
                                                                                                                                        if (s9 !== peg$FAILED) {
                                                                                                                                          s10 = peg$parse_();
                                                                                                                                          if (s10 !== peg$FAILED) {
                                                                                                                                            if (input.substr(peg$currPos, 3) === peg$c646) {
                                                                                                                                              s11 = peg$c646;
                                                                                                                                              peg$currPos += 3;
                                                                                                                                            } else {
                                                                                                                                              s11 = peg$FAILED;
                                                                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c647); }
                                                                                                                                            }
                                                                                                                                            if (s11 !== peg$FAILED) {
                                                                                                                                              s12 = peg$parse_();
                                                                                                                                              if (s12 !== peg$FAILED) {
                                                                                                                                                if (input.substr(peg$currPos, 3) === peg$c648) {
                                                                                                                                                  s13 = peg$c648;
                                                                                                                                                  peg$currPos += 3;
                                                                                                                                                } else {
                                                                                                                                                  s13 = peg$FAILED;
                                                                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c649); }
                                                                                                                                                }
                                                                                                                                                if (s13 !== peg$FAILED) {
                                                                                                                                                  peg$savedPos = s0;
                                                                                                                                                  s1 = peg$c650();
                                                                                                                                                  s0 = s1;
                                                                                                                                                } else {
                                                                                                                                                  peg$currPos = s0;
                                                                                                                                                  s0 = peg$FAILED;
                                                                                                                                                }
                                                                                                                                              } else {
                                                                                                                                                peg$currPos = s0;
                                                                                                                                                s0 = peg$FAILED;
                                                                                                                                              }
                                                                                                                                            } else {
                                                                                                                                              peg$currPos = s0;
                                                                                                                                              s0 = peg$FAILED;
                                                                                                                                            }
                                                                                                                                          } else {
                                                                                                                                            peg$currPos = s0;
                                                                                                                                            s0 = peg$FAILED;
                                                                                                                                          }
                                                                                                                                        } else {
                                                                                                                                          peg$currPos = s0;
                                                                                                                                          s0 = peg$FAILED;
                                                                                                                                        }
                                                                                                                                      } else {
                                                                                                                                        peg$currPos = s0;
                                                                                                                                        s0 = peg$FAILED;
                                                                                                                                      }
                                                                                                                                    } else {
                                                                                                                                      peg$currPos = s0;
                                                                                                                                      s0 = peg$FAILED;
                                                                                                                                    }
                                                                                                                                  } else {
                                                                                                                                    peg$currPos = s0;
                                                                                                                                    s0 = peg$FAILED;
                                                                                                                                  }
                                                                                                                                } else {
                                                                                                                                  peg$currPos = s0;
                                                                                                                                  s0 = peg$FAILED;
                                                                                                                                }
                                                                                                                              } else {
                                                                                                                                peg$currPos = s0;
                                                                                                                                s0 = peg$FAILED;
                                                                                                                              }
                                                                                                                            } else {
                                                                                                                              peg$currPos = s0;
                                                                                                                              s0 = peg$FAILED;
                                                                                                                            }
                                                                                                                          } else {
                                                                                                                            peg$currPos = s0;
                                                                                                                            s0 = peg$FAILED;
                                                                                                                          }
                                                                                                                        } else {
                                                                                                                          peg$currPos = s0;
                                                                                                                          s0 = peg$FAILED;
                                                                                                                        }
                                                                                                                        if (s0 === peg$FAILED) {
                                                                                                                          s0 = peg$currPos;
                                                                                                                          if (input.substr(peg$currPos, 7) === peg$c88) {
                                                                                                                            s1 = peg$c88;
                                                                                                                            peg$currPos += 7;
                                                                                                                          } else {
                                                                                                                            s1 = peg$FAILED;
                                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c89); }
                                                                                                                          }
                                                                                                                          if (s1 !== peg$FAILED) {
                                                                                                                            s2 = peg$parse_();
                                                                                                                            if (s2 !== peg$FAILED) {
                                                                                                                              if (input.substr(peg$currPos, 4) === peg$c90) {
                                                                                                                                s3 = peg$c90;
                                                                                                                                peg$currPos += 4;
                                                                                                                              } else {
                                                                                                                                s3 = peg$FAILED;
                                                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c91); }
                                                                                                                              }
                                                                                                                              if (s3 !== peg$FAILED) {
                                                                                                                                s4 = peg$parse_();
                                                                                                                                if (s4 !== peg$FAILED) {
                                                                                                                                  s5 = peg$parseSchool();
                                                                                                                                  if (s5 !== peg$FAILED) {
                                                                                                                                    s6 = peg$parse_();
                                                                                                                                    if (s6 !== peg$FAILED) {
                                                                                                                                      if (input.substr(peg$currPos, 7) === peg$c111) {
                                                                                                                                        s7 = peg$c111;
                                                                                                                                        peg$currPos += 7;
                                                                                                                                      } else {
                                                                                                                                        s7 = peg$FAILED;
                                                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c112); }
                                                                                                                                      }
                                                                                                                                      if (s7 !== peg$FAILED) {
                                                                                                                                        s8 = peg$parse_();
                                                                                                                                        if (s8 !== peg$FAILED) {
                                                                                                                                          if (input.substr(peg$currPos, 4) === peg$c108) {
                                                                                                                                            s9 = peg$c108;
                                                                                                                                            peg$currPos += 4;
                                                                                                                                          } else {
                                                                                                                                            s9 = peg$FAILED;
                                                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c109); }
                                                                                                                                          }
                                                                                                                                          if (s9 !== peg$FAILED) {
                                                                                                                                            s10 = peg$parse_();
                                                                                                                                            if (s10 !== peg$FAILED) {
                                                                                                                                              if (input.charCodeAt(peg$currPos) === 40) {
                                                                                                                                                s11 = peg$c19;
                                                                                                                                                peg$currPos++;
                                                                                                                                              } else {
                                                                                                                                                s11 = peg$FAILED;
                                                                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c20); }
                                                                                                                                              }
                                                                                                                                              if (s11 !== peg$FAILED) {
                                                                                                                                                s12 = peg$parse_();
                                                                                                                                                if (s12 !== peg$FAILED) {
                                                                                                                                                  s13 = peg$parseIntegerSlashList();
                                                                                                                                                  if (s13 !== peg$FAILED) {
                                                                                                                                                    s14 = peg$parse_();
                                                                                                                                                    if (s14 !== peg$FAILED) {
                                                                                                                                                      if (input.charCodeAt(peg$currPos) === 41) {
                                                                                                                                                        s15 = peg$c21;
                                                                                                                                                        peg$currPos++;
                                                                                                                                                      } else {
                                                                                                                                                        s15 = peg$FAILED;
                                                                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c22); }
                                                                                                                                                      }
                                                                                                                                                      if (s15 !== peg$FAILED) {
                                                                                                                                                        peg$savedPos = s0;
                                                                                                                                                        s1 = peg$c651(s5, s13);
                                                                                                                                                        s0 = s1;
                                                                                                                                                      } else {
                                                                                                                                                        peg$currPos = s0;
                                                                                                                                                        s0 = peg$FAILED;
                                                                                                                                                      }
                                                                                                                                                    } else {
                                                                                                                                                      peg$currPos = s0;
                                                                                                                                                      s0 = peg$FAILED;
                                                                                                                                                    }
                                                                                                                                                  } else {
                                                                                                                                                    peg$currPos = s0;
                                                                                                                                                    s0 = peg$FAILED;
                                                                                                                                                  }
                                                                                                                                                } else {
                                                                                                                                                  peg$currPos = s0;
                                                                                                                                                  s0 = peg$FAILED;
                                                                                                                                                }
                                                                                                                                              } else {
                                                                                                                                                peg$currPos = s0;
                                                                                                                                                s0 = peg$FAILED;
                                                                                                                                              }
                                                                                                                                            } else {
                                                                                                                                              peg$currPos = s0;
                                                                                                                                              s0 = peg$FAILED;
                                                                                                                                            }
                                                                                                                                          } else {
                                                                                                                                            peg$currPos = s0;
                                                                                                                                            s0 = peg$FAILED;
                                                                                                                                          }
                                                                                                                                        } else {
                                                                                                                                          peg$currPos = s0;
                                                                                                                                          s0 = peg$FAILED;
                                                                                                                                        }
                                                                                                                                      } else {
                                                                                                                                        peg$currPos = s0;
                                                                                                                                        s0 = peg$FAILED;
                                                                                                                                      }
                                                                                                                                    } else {
                                                                                                                                      peg$currPos = s0;
                                                                                                                                      s0 = peg$FAILED;
                                                                                                                                    }
                                                                                                                                  } else {
                                                                                                                                    peg$currPos = s0;
                                                                                                                                    s0 = peg$FAILED;
                                                                                                                                  }
                                                                                                                                } else {
                                                                                                                                  peg$currPos = s0;
                                                                                                                                  s0 = peg$FAILED;
                                                                                                                                }
                                                                                                                              } else {
                                                                                                                                peg$currPos = s0;
                                                                                                                                s0 = peg$FAILED;
                                                                                                                              }
                                                                                                                            } else {
                                                                                                                              peg$currPos = s0;
                                                                                                                              s0 = peg$FAILED;
                                                                                                                            }
                                                                                                                          } else {
                                                                                                                            peg$currPos = s0;
                                                                                                                            s0 = peg$FAILED;
                                                                                                                          }
                                                                                                                          if (s0 === peg$FAILED) {
                                                                                                                            s0 = peg$currPos;
                                                                                                                            if (input.substr(peg$currPos, 2) === peg$c148) {
                                                                                                                              s1 = peg$c148;
                                                                                                                              peg$currPos += 2;
                                                                                                                            } else {
                                                                                                                              s1 = peg$FAILED;
                                                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c149); }
                                                                                                                            }
                                                                                                                            if (s1 !== peg$FAILED) {
                                                                                                                              s2 = peg$parse_();
                                                                                                                              if (s2 !== peg$FAILED) {
                                                                                                                                if (input.substr(peg$currPos, 4) === peg$c652) {
                                                                                                                                  s3 = peg$c652;
                                                                                                                                  peg$currPos += 4;
                                                                                                                                } else {
                                                                                                                                  s3 = peg$FAILED;
                                                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c653); }
                                                                                                                                }
                                                                                                                                if (s3 !== peg$FAILED) {
                                                                                                                                  s4 = peg$parse_();
                                                                                                                                  if (s4 !== peg$FAILED) {
                                                                                                                                    if (input.substr(peg$currPos, 9) === peg$c654) {
                                                                                                                                      s5 = peg$c654;
                                                                                                                                      peg$currPos += 9;
                                                                                                                                    } else {
                                                                                                                                      s5 = peg$FAILED;
                                                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c655); }
                                                                                                                                    }
                                                                                                                                    if (s5 !== peg$FAILED) {
                                                                                                                                      s6 = peg$currPos;
                                                                                                                                      s7 = peg$parse_();
                                                                                                                                      if (s7 !== peg$FAILED) {
                                                                                                                                        if (input.substr(peg$currPos, 2) === peg$c184) {
                                                                                                                                          s8 = peg$c184;
                                                                                                                                          peg$currPos += 2;
                                                                                                                                        } else {
                                                                                                                                          s8 = peg$FAILED;
                                                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c185); }
                                                                                                                                        }
                                                                                                                                        if (s8 !== peg$FAILED) {
                                                                                                                                          s9 = peg$parse_();
                                                                                                                                          if (s9 !== peg$FAILED) {
                                                                                                                                            if (input.substr(peg$currPos, 3) === peg$c186) {
                                                                                                                                              s10 = peg$c186;
                                                                                                                                              peg$currPos += 3;
                                                                                                                                            } else {
                                                                                                                                              s10 = peg$FAILED;
                                                                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c187); }
                                                                                                                                            }
                                                                                                                                            if (s10 !== peg$FAILED) {
                                                                                                                                              s11 = peg$parse_();
                                                                                                                                              if (s11 !== peg$FAILED) {
                                                                                                                                                if (input.substr(peg$currPos, 10) === peg$c656) {
                                                                                                                                                  s12 = peg$c656;
                                                                                                                                                  peg$currPos += 10;
                                                                                                                                                } else {
                                                                                                                                                  s12 = peg$FAILED;
                                                                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c657); }
                                                                                                                                                }
                                                                                                                                                if (s12 !== peg$FAILED) {
                                                                                                                                                  s13 = peg$parse_();
                                                                                                                                                  if (s13 !== peg$FAILED) {
                                                                                                                                                    if (input.substr(peg$currPos, 7) === peg$c323) {
                                                                                                                                                      s14 = peg$c323;
                                                                                                                                                      peg$currPos += 7;
                                                                                                                                                    } else {
                                                                                                                                                      s14 = peg$FAILED;
                                                                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c324); }
                                                                                                                                                    }
                                                                                                                                                    if (s14 !== peg$FAILED) {
                                                                                                                                                      s7 = [s7, s8, s9, s10, s11, s12, s13, s14];
                                                                                                                                                      s6 = s7;
                                                                                                                                                    } else {
                                                                                                                                                      peg$currPos = s6;
                                                                                                                                                      s6 = peg$FAILED;
                                                                                                                                                    }
                                                                                                                                                  } else {
                                                                                                                                                    peg$currPos = s6;
                                                                                                                                                    s6 = peg$FAILED;
                                                                                                                                                  }
                                                                                                                                                } else {
                                                                                                                                                  peg$currPos = s6;
                                                                                                                                                  s6 = peg$FAILED;
                                                                                                                                                }
                                                                                                                                              } else {
                                                                                                                                                peg$currPos = s6;
                                                                                                                                                s6 = peg$FAILED;
                                                                                                                                              }
                                                                                                                                            } else {
                                                                                                                                              peg$currPos = s6;
                                                                                                                                              s6 = peg$FAILED;
                                                                                                                                            }
                                                                                                                                          } else {
                                                                                                                                            peg$currPos = s6;
                                                                                                                                            s6 = peg$FAILED;
                                                                                                                                          }
                                                                                                                                        } else {
                                                                                                                                          peg$currPos = s6;
                                                                                                                                          s6 = peg$FAILED;
                                                                                                                                        }
                                                                                                                                      } else {
                                                                                                                                        peg$currPos = s6;
                                                                                                                                        s6 = peg$FAILED;
                                                                                                                                      }
                                                                                                                                      if (s6 === peg$FAILED) {
                                                                                                                                        s6 = null;
                                                                                                                                      }
                                                                                                                                      if (s6 !== peg$FAILED) {
                                                                                                                                        peg$savedPos = s0;
                                                                                                                                        s1 = peg$c658();
                                                                                                                                        s0 = s1;
                                                                                                                                      } else {
                                                                                                                                        peg$currPos = s0;
                                                                                                                                        s0 = peg$FAILED;
                                                                                                                                      }
                                                                                                                                    } else {
                                                                                                                                      peg$currPos = s0;
                                                                                                                                      s0 = peg$FAILED;
                                                                                                                                    }
                                                                                                                                  } else {
                                                                                                                                    peg$currPos = s0;
                                                                                                                                    s0 = peg$FAILED;
                                                                                                                                  }
                                                                                                                                } else {
                                                                                                                                  peg$currPos = s0;
                                                                                                                                  s0 = peg$FAILED;
                                                                                                                                }
                                                                                                                              } else {
                                                                                                                                peg$currPos = s0;
                                                                                                                                s0 = peg$FAILED;
                                                                                                                              }
                                                                                                                            } else {
                                                                                                                              peg$currPos = s0;
                                                                                                                              s0 = peg$FAILED;
                                                                                                                            }
                                                                                                                            if (s0 === peg$FAILED) {
                                                                                                                              s0 = peg$currPos;
                                                                                                                              if (input.substr(peg$currPos, 2) === peg$c148) {
                                                                                                                                s1 = peg$c148;
                                                                                                                                peg$currPos += 2;
                                                                                                                              } else {
                                                                                                                                s1 = peg$FAILED;
                                                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c149); }
                                                                                                                              }
                                                                                                                              if (s1 !== peg$FAILED) {
                                                                                                                                s2 = peg$parse_();
                                                                                                                                if (s2 !== peg$FAILED) {
                                                                                                                                  if (input.substr(peg$currPos, 7) === peg$c323) {
                                                                                                                                    s3 = peg$c323;
                                                                                                                                    peg$currPos += 7;
                                                                                                                                  } else {
                                                                                                                                    s3 = peg$FAILED;
                                                                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c324); }
                                                                                                                                  }
                                                                                                                                  if (s3 !== peg$FAILED) {
                                                                                                                                    s4 = peg$parse_();
                                                                                                                                    if (s4 !== peg$FAILED) {
                                                                                                                                      if (input.substr(peg$currPos, 4) === peg$c652) {
                                                                                                                                        s5 = peg$c652;
                                                                                                                                        peg$currPos += 4;
                                                                                                                                      } else {
                                                                                                                                        s5 = peg$FAILED;
                                                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c653); }
                                                                                                                                      }
                                                                                                                                      if (s5 !== peg$FAILED) {
                                                                                                                                        s6 = peg$parse_();
                                                                                                                                        if (s6 !== peg$FAILED) {
                                                                                                                                          if (input.substr(peg$currPos, 9) === peg$c654) {
                                                                                                                                            s7 = peg$c654;
                                                                                                                                            peg$currPos += 9;
                                                                                                                                          } else {
                                                                                                                                            s7 = peg$FAILED;
                                                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c655); }
                                                                                                                                          }
                                                                                                                                          if (s7 !== peg$FAILED) {
                                                                                                                                            peg$savedPos = s0;
                                                                                                                                            s1 = peg$c658();
                                                                                                                                            s0 = s1;
                                                                                                                                          } else {
                                                                                                                                            peg$currPos = s0;
                                                                                                                                            s0 = peg$FAILED;
                                                                                                                                          }
                                                                                                                                        } else {
                                                                                                                                          peg$currPos = s0;
                                                                                                                                          s0 = peg$FAILED;
                                                                                                                                        }
                                                                                                                                      } else {
                                                                                                                                        peg$currPos = s0;
                                                                                                                                        s0 = peg$FAILED;
                                                                                                                                      }
                                                                                                                                    } else {
                                                                                                                                      peg$currPos = s0;
                                                                                                                                      s0 = peg$FAILED;
                                                                                                                                    }
                                                                                                                                  } else {
                                                                                                                                    peg$currPos = s0;
                                                                                                                                    s0 = peg$FAILED;
                                                                                                                                  }
                                                                                                                                } else {
                                                                                                                                  peg$currPos = s0;
                                                                                                                                  s0 = peg$FAILED;
                                                                                                                                }
                                                                                                                              } else {
                                                                                                                                peg$currPos = s0;
                                                                                                                                s0 = peg$FAILED;
                                                                                                                              }
                                                                                                                              if (s0 === peg$FAILED) {
                                                                                                                                s0 = peg$currPos;
                                                                                                                                if (input.substr(peg$currPos, 2) === peg$c148) {
                                                                                                                                  s1 = peg$c148;
                                                                                                                                  peg$currPos += 2;
                                                                                                                                } else {
                                                                                                                                  s1 = peg$FAILED;
                                                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c149); }
                                                                                                                                }
                                                                                                                                if (s1 !== peg$FAILED) {
                                                                                                                                  s2 = peg$parse_();
                                                                                                                                  if (s2 !== peg$FAILED) {
                                                                                                                                    s3 = peg$parseGenericName();
                                                                                                                                    if (s3 !== peg$FAILED) {
                                                                                                                                      peg$savedPos = s0;
                                                                                                                                      s1 = peg$c659(s3);
                                                                                                                                      s0 = s1;
                                                                                                                                    } else {
                                                                                                                                      peg$currPos = s0;
                                                                                                                                      s0 = peg$FAILED;
                                                                                                                                    }
                                                                                                                                  } else {
                                                                                                                                    peg$currPos = s0;
                                                                                                                                    s0 = peg$FAILED;
                                                                                                                                  }
                                                                                                                                } else {
                                                                                                                                  peg$currPos = s0;
                                                                                                                                  s0 = peg$FAILED;
                                                                                                                                }
                                                                                                                                if (s0 === peg$FAILED) {
                                                                                                                                  s0 = peg$currPos;
                                                                                                                                  if (input.substr(peg$currPos, 2) === peg$c148) {
                                                                                                                                    s1 = peg$c148;
                                                                                                                                    peg$currPos += 2;
                                                                                                                                  } else {
                                                                                                                                    s1 = peg$FAILED;
                                                                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c149); }
                                                                                                                                  }
                                                                                                                                  if (s1 !== peg$FAILED) {
                                                                                                                                    s2 = peg$parse_();
                                                                                                                                    if (s2 !== peg$FAILED) {
                                                                                                                                      s3 = peg$parseIntegerSlashList();
                                                                                                                                      if (s3 !== peg$FAILED) {
                                                                                                                                        s4 = peg$parse_();
                                                                                                                                        if (s4 !== peg$FAILED) {
                                                                                                                                          s5 = peg$parseStat();
                                                                                                                                          if (s5 !== peg$FAILED) {
                                                                                                                                            peg$savedPos = s0;
                                                                                                                                            s1 = peg$c660(s3, s5);
                                                                                                                                            s0 = s1;
                                                                                                                                          } else {
                                                                                                                                            peg$currPos = s0;
                                                                                                                                            s0 = peg$FAILED;
                                                                                                                                          }
                                                                                                                                        } else {
                                                                                                                                          peg$currPos = s0;
                                                                                                                                          s0 = peg$FAILED;
                                                                                                                                        }
                                                                                                                                      } else {
                                                                                                                                        peg$currPos = s0;
                                                                                                                                        s0 = peg$FAILED;
                                                                                                                                      }
                                                                                                                                    } else {
                                                                                                                                      peg$currPos = s0;
                                                                                                                                      s0 = peg$FAILED;
                                                                                                                                    }
                                                                                                                                  } else {
                                                                                                                                    peg$currPos = s0;
                                                                                                                                    s0 = peg$FAILED;
                                                                                                                                  }
                                                                                                                                  if (s0 === peg$FAILED) {
                                                                                                                                    s0 = peg$currPos;
                                                                                                                                    if (input.substr(peg$currPos, 30) === peg$c661) {
                                                                                                                                      s1 = peg$c661;
                                                                                                                                      peg$currPos += 30;
                                                                                                                                    } else {
                                                                                                                                      s1 = peg$FAILED;
                                                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c662); }
                                                                                                                                    }
                                                                                                                                    if (s1 !== peg$FAILED) {
                                                                                                                                      peg$savedPos = s0;
                                                                                                                                      s1 = peg$c663();
                                                                                                                                    }
                                                                                                                                    s0 = s1;
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseWithoutWith() {
    var s0, s1;

    var key    = peg$currPos * 140 + 81,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 10) === peg$c664) {
      s1 = peg$c664;
      peg$currPos += 10;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c665); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c666();
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 6) === peg$c667) {
        s1 = peg$c667;
        peg$currPos += 6;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c668); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c669();
      }
      s0 = s1;
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 3) === peg$c469) {
          s1 = peg$c469;
          peg$currPos += 3;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c470); }
        }
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c670();
        }
        s0 = s1;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseConditionDetail() {
    var s0;

    var key    = peg$currPos * 140 + 82,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    if (input.substr(peg$currPos, 37) === peg$c671) {
      s0 = peg$c671;
      peg$currPos += 37;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c672); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSmartEtherStatus() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 140 + 83,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseSchool();
    if (s1 === peg$FAILED) {
      s1 = null;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 5).toLowerCase() === peg$c673) {
          s3 = input.substr(peg$currPos, 5);
          peg$currPos += 5;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c674); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 5) === peg$c675) {
              s5 = peg$c675;
              peg$currPos += 5;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c676); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseIntegerSlashList();
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c677(s1, s7);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatusVerb() {
    var s0, s1, s2, s3, s4;

    var key    = peg$currPos * 140 + 84,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c387) {
      s1 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c388); }
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 6).toLowerCase() === peg$c678) {
        s1 = input.substr(peg$currPos, 6);
        peg$currPos += 6;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c679); }
      }
      if (s1 === peg$FAILED) {
        if (input.substr(peg$currPos, 7).toLowerCase() === peg$c287) {
          s1 = input.substr(peg$currPos, 7);
          peg$currPos += 7;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c288); }
        }
        if (s1 === peg$FAILED) {
          s1 = peg$currPos;
          if (input.substr(peg$currPos, 7).toLowerCase() === peg$c680) {
            s2 = input.substr(peg$currPos, 7);
            peg$currPos += 7;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c681); }
          }
          if (s2 !== peg$FAILED) {
            s3 = peg$parse_();
            if (s3 !== peg$FAILED) {
              if (input.substr(peg$currPos, 6) === peg$c682) {
                s4 = peg$c682;
                peg$currPos += 6;
              } else {
                s4 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c683); }
              }
              if (s4 !== peg$FAILED) {
                s2 = [s2, s3, s4];
                s1 = s2;
              } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
              }
            } else {
              peg$currPos = s1;
              s1 = peg$FAILED;
            }
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        }
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c684();
    }
    s0 = s1;

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatusName() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 140 + 85,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 91) {
      s1 = peg$c367;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c368); }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      if (peg$c686.test(input.charAt(peg$currPos))) {
        s3 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c687); }
      }
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          if (peg$c686.test(input.charAt(peg$currPos))) {
            s3 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c687); }
          }
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 93) {
          s3 = peg$c570;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c571); }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c688(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 63) {
        s1 = peg$c394;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c395); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c457();
      }
      s0 = s1;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c685); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatModDuration() {
    var s0, s1, s2, s3, s4;

    var key    = peg$currPos * 140 + 86,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parse_();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 40) {
        s2 = peg$c19;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c20); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseInteger();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c689) {
            s4 = peg$c689;
            peg$currPos += 2;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c690); }
          }
          if (s4 !== peg$FAILED) {
            s1 = [s1, s2, s3, s4];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAbilityName() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 140 + 87,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseUppercaseWord();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parse_();
      if (s4 !== peg$FAILED) {
        s5 = peg$parseUppercaseWord();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parse_();
        if (s4 !== peg$FAILED) {
          s5 = peg$parseUppercaseWord();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c457();
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseCharacterName() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    var key    = peg$currPos * 140 + 88,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseUppercaseWord();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parse_();
      if (s4 !== peg$FAILED) {
        s5 = peg$parseUppercaseWord();
        if (s5 === peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c184) {
            s5 = peg$c184;
            peg$currPos += 2;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c185); }
          }
        }
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parse_();
        if (s4 !== peg$FAILED) {
          s5 = peg$parseUppercaseWord();
          if (s5 === peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c184) {
              s5 = peg$c184;
              peg$currPos += 2;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c185); }
            }
          }
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        s4 = peg$parse_();
        if (s4 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 40) {
            s5 = peg$c19;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c20); }
          }
          if (s5 !== peg$FAILED) {
            if (peg$c691.test(input.charAt(peg$currPos))) {
              s6 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c692); }
            }
            if (s6 !== peg$FAILED) {
              s7 = [];
              if (peg$c693.test(input.charAt(peg$currPos))) {
                s8 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s8 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c694); }
              }
              if (s8 !== peg$FAILED) {
                while (s8 !== peg$FAILED) {
                  s7.push(s8);
                  if (peg$c693.test(input.charAt(peg$currPos))) {
                    s8 = input.charAt(peg$currPos);
                    peg$currPos++;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c694); }
                  }
                }
              } else {
                s7 = peg$FAILED;
              }
              if (s7 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 41) {
                  s8 = peg$c21;
                  peg$currPos++;
                } else {
                  s8 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c22); }
                }
                if (s8 !== peg$FAILED) {
                  s4 = [s4, s5, s6, s7, s8];
                  s3 = s4;
                } else {
                  peg$currPos = s3;
                  s3 = peg$FAILED;
                }
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c457();
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseCharacterNameList() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 140 + 89,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseCharacterName();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 47) {
        s4 = peg$c240;
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c241); }
      }
      if (s4 === peg$FAILED) {
        s4 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 44) {
          s5 = peg$c0;
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1); }
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          if (s6 !== peg$FAILED) {
            s5 = [s5, s6];
            s4 = s5;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        if (s4 === peg$FAILED) {
          s4 = peg$currPos;
          s5 = peg$parse_();
          if (s5 !== peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c61) {
              s6 = peg$c61;
              peg$currPos += 2;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c62); }
            }
            if (s6 !== peg$FAILED) {
              s7 = peg$parse_();
              if (s7 !== peg$FAILED) {
                s5 = [s5, s6, s7];
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        }
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parseCharacterName();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 47) {
          s4 = peg$c240;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c241); }
        }
        if (s4 === peg$FAILED) {
          s4 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 44) {
            s5 = peg$c0;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1); }
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            if (s6 !== peg$FAILED) {
              s5 = [s5, s6];
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 === peg$FAILED) {
            s4 = peg$currPos;
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 2) === peg$c61) {
                s6 = peg$c61;
                peg$currPos += 2;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c62); }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  s5 = [s5, s6, s7];
                  s4 = s5;
                } else {
                  peg$currPos = s4;
                  s4 = peg$FAILED;
                }
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseCharacterName();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c695(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseCharacterNameAndList() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 140 + 90,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseCharacterName();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 47) {
        s4 = peg$c240;
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c241); }
      }
      if (s4 === peg$FAILED) {
        s4 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 44) {
          s5 = peg$c0;
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1); }
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          if (s6 !== peg$FAILED) {
            s5 = [s5, s6];
            s4 = s5;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        if (s4 === peg$FAILED) {
          s4 = peg$currPos;
          s5 = peg$parse_();
          if (s5 !== peg$FAILED) {
            if (input.substr(peg$currPos, 3) === peg$c8) {
              s6 = peg$c8;
              peg$currPos += 3;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c9); }
            }
            if (s6 !== peg$FAILED) {
              s7 = peg$parse_();
              if (s7 !== peg$FAILED) {
                s5 = [s5, s6, s7];
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        }
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parseCharacterName();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 47) {
          s4 = peg$c240;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c241); }
        }
        if (s4 === peg$FAILED) {
          s4 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 44) {
            s5 = peg$c0;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1); }
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            if (s6 !== peg$FAILED) {
              s5 = [s5, s6];
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 === peg$FAILED) {
            s4 = peg$currPos;
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              if (input.substr(peg$currPos, 3) === peg$c8) {
                s6 = peg$c8;
                peg$currPos += 3;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c9); }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  s5 = [s5, s6, s7];
                  s4 = s5;
                } else {
                  peg$currPos = s4;
                  s4 = peg$FAILED;
                }
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseCharacterName();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c695(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseCharacterNameListOrPronoun() {
    var s0, s1;

    var key    = peg$currPos * 140 + 91,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$parseCharacterNameList();
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 2) === peg$c696) {
        s1 = peg$c696;
        peg$currPos += 2;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c697); }
      }
      if (s1 === peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c698) {
          s1 = peg$c698;
          peg$currPos += 3;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c699); }
        }
        if (s1 === peg$FAILED) {
          if (input.substr(peg$currPos, 4) === peg$c700) {
            s1 = peg$c700;
            peg$currPos += 4;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c701); }
          }
        }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c670();
      }
      s0 = s1;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAnySkillName() {
    var s0;

    var key    = peg$currPos * 140 + 92,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$parseGenericName();
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 3) === peg$c702) {
        s0 = peg$c702;
        peg$currPos += 3;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c703); }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAnySkillOrOptions() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 140 + 93,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseAnySkillName();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parse_();
      if (s4 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 47) {
          s5 = peg$c240;
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c241); }
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          if (s6 !== peg$FAILED) {
            s7 = peg$parseAnySkillName();
            if (s7 !== peg$FAILED) {
              s4 = [s4, s5, s6, s7];
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$currPos;
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 47) {
              s5 = peg$c240;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c241); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseAnySkillName();
                if (s7 !== peg$FAILED) {
                  s4 = [s4, s5, s6, s7];
                  s3 = s4;
                } else {
                  peg$currPos = s3;
                  s3 = peg$FAILED;
                }
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c704(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseAnySkillName();
      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$silentFails++;
        s3 = peg$currPos;
        s4 = peg$parse_();
        if (s4 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 47) {
            s5 = peg$c240;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c241); }
          }
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        peg$silentFails--;
        if (s3 === peg$FAILED) {
          s2 = void 0;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c705(s1);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseGenericName() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14;

    var key    = peg$currPos * 140 + 94,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$currPos;
    s2 = peg$parseGenericNameWord();
    if (s2 === peg$FAILED) {
      s2 = peg$currPos;
      s3 = peg$parseIntegerSlashList();
      if (s3 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 37) {
          s4 = peg$c172;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c173); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$currPos;
          peg$silentFails++;
          s6 = peg$currPos;
          s7 = peg$parse_();
          if (s7 !== peg$FAILED) {
            if (input.substr(peg$currPos, 3) === peg$c157) {
              s8 = peg$c157;
              peg$currPos += 3;
            } else {
              s8 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c158); }
            }
            if (s8 !== peg$FAILED) {
              s9 = peg$parse_();
              if (s9 !== peg$FAILED) {
                if (input.substr(peg$currPos, 4) === peg$c200) {
                  s10 = peg$c200;
                  peg$currPos += 4;
                } else {
                  s10 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c201); }
                }
                if (s10 !== peg$FAILED) {
                  s7 = [s7, s8, s9, s10];
                  s6 = s7;
                } else {
                  peg$currPos = s6;
                  s6 = peg$FAILED;
                }
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
          } else {
            peg$currPos = s6;
            s6 = peg$FAILED;
          }
          peg$silentFails--;
          if (s6 === peg$FAILED) {
            s5 = void 0;
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            if (s6 !== peg$FAILED) {
              s7 = peg$parseGenericNameWord();
              if (s7 !== peg$FAILED) {
                s3 = [s3, s4, s5, s6, s7];
                s2 = s3;
              } else {
                peg$currPos = s2;
                s2 = peg$FAILED;
              }
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 === peg$FAILED) {
        s2 = peg$currPos;
        s3 = peg$parseSignedIntegerSlashList();
        if (s3 !== peg$FAILED) {
          if (peg$c706.test(input.charAt(peg$currPos))) {
            s4 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c707); }
          }
          if (s4 === peg$FAILED) {
            s4 = null;
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseGenericNameWord();
              if (s6 !== peg$FAILED) {
                s3 = [s3, s4, s5, s6];
                s2 = s3;
              } else {
                peg$currPos = s2;
                s2 = peg$FAILED;
              }
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 === peg$FAILED) {
          s2 = peg$currPos;
          if (input.substr(peg$currPos, 3) === peg$c708) {
            s3 = peg$c708;
            peg$currPos += 3;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c709); }
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parseGenericNameWord();
            if (s4 !== peg$FAILED) {
              s3 = [s3, s4];
              s2 = s3;
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        }
      }
    }
    if (s2 !== peg$FAILED) {
      s3 = [];
      s4 = peg$currPos;
      s5 = peg$parse_();
      if (s5 !== peg$FAILED) {
        s6 = peg$parseGenericNameWord();
        if (s6 === peg$FAILED) {
          s6 = peg$currPos;
          if (input.substr(peg$currPos, 2) === peg$c535) {
            s7 = peg$c535;
            peg$currPos += 2;
          } else {
            s7 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c536); }
          }
          if (s7 === peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c61) {
              s7 = peg$c61;
              peg$currPos += 2;
            } else {
              s7 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c62); }
            }
            if (s7 === peg$FAILED) {
              if (input.substr(peg$currPos, 2) === peg$c184) {
                s7 = peg$c184;
                peg$currPos += 2;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c185); }
              }
              if (s7 === peg$FAILED) {
                if (input.substr(peg$currPos, 3) === peg$c186) {
                  s7 = peg$c186;
                  peg$currPos += 3;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c187); }
                }
                if (s7 === peg$FAILED) {
                  if (input.substr(peg$currPos, 4) === peg$c90) {
                    s7 = peg$c90;
                    peg$currPos += 4;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c91); }
                  }
                  if (s7 === peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 38) {
                      s7 = peg$c710;
                      peg$currPos++;
                    } else {
                      s7 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c711); }
                    }
                    if (s7 === peg$FAILED) {
                      if (input.charCodeAt(peg$currPos) === 97) {
                        s7 = peg$c155;
                        peg$currPos++;
                      } else {
                        s7 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c156); }
                      }
                    }
                  }
                }
              }
            }
          }
          if (s7 !== peg$FAILED) {
            s8 = peg$currPos;
            peg$silentFails++;
            if (input.charCodeAt(peg$currPos) === 32) {
              s9 = peg$c712;
              peg$currPos++;
            } else {
              s9 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c713); }
            }
            peg$silentFails--;
            if (s9 !== peg$FAILED) {
              peg$currPos = s8;
              s8 = void 0;
            } else {
              s8 = peg$FAILED;
            }
            if (s8 !== peg$FAILED) {
              s7 = [s7, s8];
              s6 = s7;
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
          } else {
            peg$currPos = s6;
            s6 = peg$FAILED;
          }
          if (s6 === peg$FAILED) {
            s6 = peg$currPos;
            if (input.substr(peg$currPos, 3) === peg$c182) {
              s7 = peg$c182;
              peg$currPos += 3;
            } else {
              s7 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c183); }
            }
            if (s7 === peg$FAILED) {
              if (input.substr(peg$currPos, 2) === peg$c174) {
                s7 = peg$c174;
                peg$currPos += 2;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c175); }
              }
              if (s7 === peg$FAILED) {
                if (input.substr(peg$currPos, 3) === peg$c8) {
                  s7 = peg$c8;
                  peg$currPos += 3;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c9); }
                }
                if (s7 === peg$FAILED) {
                  if (input.substr(peg$currPos, 2) === peg$c197) {
                    s7 = peg$c197;
                    peg$currPos += 2;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c198); }
                  }
                }
              }
            }
            if (s7 !== peg$FAILED) {
              s8 = peg$parse_();
              if (s8 !== peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c714) {
                  s9 = peg$c714;
                  peg$currPos += 2;
                } else {
                  s9 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c715); }
                }
                if (s9 === peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 97) {
                    s9 = peg$c155;
                    peg$currPos++;
                  } else {
                    s9 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c156); }
                  }
                }
                if (s9 === peg$FAILED) {
                  s9 = null;
                }
                if (s9 !== peg$FAILED) {
                  s10 = peg$parse_();
                  if (s10 !== peg$FAILED) {
                    s11 = peg$parseGenericNameWord();
                    if (s11 !== peg$FAILED) {
                      s7 = [s7, s8, s9, s10, s11];
                      s6 = s7;
                    } else {
                      peg$currPos = s6;
                      s6 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s6;
                    s6 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s6;
                  s6 = peg$FAILED;
                }
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
            if (s6 === peg$FAILED) {
              s6 = peg$currPos;
              if (peg$c716.test(input.charAt(peg$currPos))) {
                s7 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c717); }
              }
              if (s7 === peg$FAILED) {
                s7 = null;
              }
              if (s7 !== peg$FAILED) {
                s8 = peg$parseInteger();
                if (s8 !== peg$FAILED) {
                  s9 = [];
                  s10 = peg$currPos;
                  if (peg$c718.test(input.charAt(peg$currPos))) {
                    s11 = input.charAt(peg$currPos);
                    peg$currPos++;
                  } else {
                    s11 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c719); }
                  }
                  if (s11 === peg$FAILED) {
                    s11 = null;
                  }
                  if (s11 !== peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 47) {
                      s12 = peg$c240;
                      peg$currPos++;
                    } else {
                      s12 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c241); }
                    }
                    if (s12 !== peg$FAILED) {
                      if (peg$c720.test(input.charAt(peg$currPos))) {
                        s13 = input.charAt(peg$currPos);
                        peg$currPos++;
                      } else {
                        s13 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c721); }
                      }
                      if (s13 === peg$FAILED) {
                        s13 = null;
                      }
                      if (s13 !== peg$FAILED) {
                        s14 = peg$parseInteger();
                        if (s14 !== peg$FAILED) {
                          s11 = [s11, s12, s13, s14];
                          s10 = s11;
                        } else {
                          peg$currPos = s10;
                          s10 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s10;
                        s10 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s10;
                      s10 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s10;
                    s10 = peg$FAILED;
                  }
                  while (s10 !== peg$FAILED) {
                    s9.push(s10);
                    s10 = peg$currPos;
                    if (peg$c718.test(input.charAt(peg$currPos))) {
                      s11 = input.charAt(peg$currPos);
                      peg$currPos++;
                    } else {
                      s11 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c719); }
                    }
                    if (s11 === peg$FAILED) {
                      s11 = null;
                    }
                    if (s11 !== peg$FAILED) {
                      if (input.charCodeAt(peg$currPos) === 47) {
                        s12 = peg$c240;
                        peg$currPos++;
                      } else {
                        s12 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c241); }
                      }
                      if (s12 !== peg$FAILED) {
                        if (peg$c720.test(input.charAt(peg$currPos))) {
                          s13 = input.charAt(peg$currPos);
                          peg$currPos++;
                        } else {
                          s13 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c721); }
                        }
                        if (s13 === peg$FAILED) {
                          s13 = null;
                        }
                        if (s13 !== peg$FAILED) {
                          s14 = peg$parseInteger();
                          if (s14 !== peg$FAILED) {
                            s11 = [s11, s12, s13, s14];
                            s10 = s11;
                          } else {
                            peg$currPos = s10;
                            s10 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s10;
                          s10 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s10;
                        s10 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s10;
                      s10 = peg$FAILED;
                    }
                  }
                  if (s9 !== peg$FAILED) {
                    if (peg$c706.test(input.charAt(peg$currPos))) {
                      s10 = input.charAt(peg$currPos);
                      peg$currPos++;
                    } else {
                      s10 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c707); }
                    }
                    if (s10 === peg$FAILED) {
                      s10 = null;
                    }
                    if (s10 !== peg$FAILED) {
                      s7 = [s7, s8, s9, s10];
                      s6 = s7;
                    } else {
                      peg$currPos = s6;
                      s6 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s6;
                    s6 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s6;
                  s6 = peg$FAILED;
                }
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
              if (s6 === peg$FAILED) {
                s6 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 40) {
                  s7 = peg$c19;
                  peg$currPos++;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c20); }
                }
                if (s7 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 11) === peg$c722) {
                    s8 = peg$c722;
                    peg$currPos += 11;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c723); }
                  }
                  if (s8 === peg$FAILED) {
                    if (input.substr(peg$currPos, 11) === peg$c724) {
                      s8 = peg$c724;
                      peg$currPos += 11;
                    } else {
                      s8 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c725); }
                    }
                    if (s8 === peg$FAILED) {
                      s8 = [];
                      if (peg$c726.test(input.charAt(peg$currPos))) {
                        s9 = input.charAt(peg$currPos);
                        peg$currPos++;
                      } else {
                        s9 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c727); }
                      }
                      if (s9 !== peg$FAILED) {
                        while (s9 !== peg$FAILED) {
                          s8.push(s9);
                          if (peg$c726.test(input.charAt(peg$currPos))) {
                            s9 = input.charAt(peg$currPos);
                            peg$currPos++;
                          } else {
                            s9 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c727); }
                          }
                        }
                      } else {
                        s8 = peg$FAILED;
                      }
                    }
                  }
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parse_();
                    if (s9 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 4) === peg$c728) {
                        s10 = peg$c728;
                        peg$currPos += 4;
                      } else {
                        s10 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c729); }
                      }
                      if (s10 === peg$FAILED) {
                        s10 = null;
                      }
                      if (s10 !== peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 41) {
                          s11 = peg$c21;
                          peg$currPos++;
                        } else {
                          s11 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c22); }
                        }
                        if (s11 !== peg$FAILED) {
                          s7 = [s7, s8, s9, s10, s11];
                          s6 = s7;
                        } else {
                          peg$currPos = s6;
                          s6 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s6;
                        s6 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s6;
                      s6 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s6;
                    s6 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s6;
                  s6 = peg$FAILED;
                }
              }
            }
          }
        }
        if (s6 !== peg$FAILED) {
          s5 = [s5, s6];
          s4 = s5;
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
      } else {
        peg$currPos = s4;
        s4 = peg$FAILED;
      }
      while (s4 !== peg$FAILED) {
        s3.push(s4);
        s4 = peg$currPos;
        s5 = peg$parse_();
        if (s5 !== peg$FAILED) {
          s6 = peg$parseGenericNameWord();
          if (s6 === peg$FAILED) {
            s6 = peg$currPos;
            if (input.substr(peg$currPos, 2) === peg$c535) {
              s7 = peg$c535;
              peg$currPos += 2;
            } else {
              s7 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c536); }
            }
            if (s7 === peg$FAILED) {
              if (input.substr(peg$currPos, 2) === peg$c61) {
                s7 = peg$c61;
                peg$currPos += 2;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c62); }
              }
              if (s7 === peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c184) {
                  s7 = peg$c184;
                  peg$currPos += 2;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c185); }
                }
                if (s7 === peg$FAILED) {
                  if (input.substr(peg$currPos, 3) === peg$c186) {
                    s7 = peg$c186;
                    peg$currPos += 3;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c187); }
                  }
                  if (s7 === peg$FAILED) {
                    if (input.substr(peg$currPos, 4) === peg$c90) {
                      s7 = peg$c90;
                      peg$currPos += 4;
                    } else {
                      s7 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c91); }
                    }
                    if (s7 === peg$FAILED) {
                      if (input.charCodeAt(peg$currPos) === 38) {
                        s7 = peg$c710;
                        peg$currPos++;
                      } else {
                        s7 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c711); }
                      }
                      if (s7 === peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 97) {
                          s7 = peg$c155;
                          peg$currPos++;
                        } else {
                          s7 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c156); }
                        }
                      }
                    }
                  }
                }
              }
            }
            if (s7 !== peg$FAILED) {
              s8 = peg$currPos;
              peg$silentFails++;
              if (input.charCodeAt(peg$currPos) === 32) {
                s9 = peg$c712;
                peg$currPos++;
              } else {
                s9 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c713); }
              }
              peg$silentFails--;
              if (s9 !== peg$FAILED) {
                peg$currPos = s8;
                s8 = void 0;
              } else {
                s8 = peg$FAILED;
              }
              if (s8 !== peg$FAILED) {
                s7 = [s7, s8];
                s6 = s7;
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
            if (s6 === peg$FAILED) {
              s6 = peg$currPos;
              if (input.substr(peg$currPos, 3) === peg$c182) {
                s7 = peg$c182;
                peg$currPos += 3;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c183); }
              }
              if (s7 === peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c174) {
                  s7 = peg$c174;
                  peg$currPos += 2;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c175); }
                }
                if (s7 === peg$FAILED) {
                  if (input.substr(peg$currPos, 3) === peg$c8) {
                    s7 = peg$c8;
                    peg$currPos += 3;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c9); }
                  }
                  if (s7 === peg$FAILED) {
                    if (input.substr(peg$currPos, 2) === peg$c197) {
                      s7 = peg$c197;
                      peg$currPos += 2;
                    } else {
                      s7 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c198); }
                    }
                  }
                }
              }
              if (s7 !== peg$FAILED) {
                s8 = peg$parse_();
                if (s8 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 2) === peg$c714) {
                    s9 = peg$c714;
                    peg$currPos += 2;
                  } else {
                    s9 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c715); }
                  }
                  if (s9 === peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 97) {
                      s9 = peg$c155;
                      peg$currPos++;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c156); }
                    }
                  }
                  if (s9 === peg$FAILED) {
                    s9 = null;
                  }
                  if (s9 !== peg$FAILED) {
                    s10 = peg$parse_();
                    if (s10 !== peg$FAILED) {
                      s11 = peg$parseGenericNameWord();
                      if (s11 !== peg$FAILED) {
                        s7 = [s7, s8, s9, s10, s11];
                        s6 = s7;
                      } else {
                        peg$currPos = s6;
                        s6 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s6;
                      s6 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s6;
                    s6 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s6;
                  s6 = peg$FAILED;
                }
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
              if (s6 === peg$FAILED) {
                s6 = peg$currPos;
                if (peg$c716.test(input.charAt(peg$currPos))) {
                  s7 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c717); }
                }
                if (s7 === peg$FAILED) {
                  s7 = null;
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseInteger();
                  if (s8 !== peg$FAILED) {
                    s9 = [];
                    s10 = peg$currPos;
                    if (peg$c718.test(input.charAt(peg$currPos))) {
                      s11 = input.charAt(peg$currPos);
                      peg$currPos++;
                    } else {
                      s11 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c719); }
                    }
                    if (s11 === peg$FAILED) {
                      s11 = null;
                    }
                    if (s11 !== peg$FAILED) {
                      if (input.charCodeAt(peg$currPos) === 47) {
                        s12 = peg$c240;
                        peg$currPos++;
                      } else {
                        s12 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c241); }
                      }
                      if (s12 !== peg$FAILED) {
                        if (peg$c720.test(input.charAt(peg$currPos))) {
                          s13 = input.charAt(peg$currPos);
                          peg$currPos++;
                        } else {
                          s13 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c721); }
                        }
                        if (s13 === peg$FAILED) {
                          s13 = null;
                        }
                        if (s13 !== peg$FAILED) {
                          s14 = peg$parseInteger();
                          if (s14 !== peg$FAILED) {
                            s11 = [s11, s12, s13, s14];
                            s10 = s11;
                          } else {
                            peg$currPos = s10;
                            s10 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s10;
                          s10 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s10;
                        s10 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s10;
                      s10 = peg$FAILED;
                    }
                    while (s10 !== peg$FAILED) {
                      s9.push(s10);
                      s10 = peg$currPos;
                      if (peg$c718.test(input.charAt(peg$currPos))) {
                        s11 = input.charAt(peg$currPos);
                        peg$currPos++;
                      } else {
                        s11 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c719); }
                      }
                      if (s11 === peg$FAILED) {
                        s11 = null;
                      }
                      if (s11 !== peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 47) {
                          s12 = peg$c240;
                          peg$currPos++;
                        } else {
                          s12 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c241); }
                        }
                        if (s12 !== peg$FAILED) {
                          if (peg$c720.test(input.charAt(peg$currPos))) {
                            s13 = input.charAt(peg$currPos);
                            peg$currPos++;
                          } else {
                            s13 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c721); }
                          }
                          if (s13 === peg$FAILED) {
                            s13 = null;
                          }
                          if (s13 !== peg$FAILED) {
                            s14 = peg$parseInteger();
                            if (s14 !== peg$FAILED) {
                              s11 = [s11, s12, s13, s14];
                              s10 = s11;
                            } else {
                              peg$currPos = s10;
                              s10 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s10;
                            s10 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s10;
                          s10 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s10;
                        s10 = peg$FAILED;
                      }
                    }
                    if (s9 !== peg$FAILED) {
                      if (peg$c706.test(input.charAt(peg$currPos))) {
                        s10 = input.charAt(peg$currPos);
                        peg$currPos++;
                      } else {
                        s10 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c707); }
                      }
                      if (s10 === peg$FAILED) {
                        s10 = null;
                      }
                      if (s10 !== peg$FAILED) {
                        s7 = [s7, s8, s9, s10];
                        s6 = s7;
                      } else {
                        peg$currPos = s6;
                        s6 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s6;
                      s6 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s6;
                    s6 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s6;
                  s6 = peg$FAILED;
                }
                if (s6 === peg$FAILED) {
                  s6 = peg$currPos;
                  if (input.charCodeAt(peg$currPos) === 40) {
                    s7 = peg$c19;
                    peg$currPos++;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c20); }
                  }
                  if (s7 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 11) === peg$c722) {
                      s8 = peg$c722;
                      peg$currPos += 11;
                    } else {
                      s8 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c723); }
                    }
                    if (s8 === peg$FAILED) {
                      if (input.substr(peg$currPos, 11) === peg$c724) {
                        s8 = peg$c724;
                        peg$currPos += 11;
                      } else {
                        s8 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c725); }
                      }
                      if (s8 === peg$FAILED) {
                        s8 = [];
                        if (peg$c726.test(input.charAt(peg$currPos))) {
                          s9 = input.charAt(peg$currPos);
                          peg$currPos++;
                        } else {
                          s9 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c727); }
                        }
                        if (s9 !== peg$FAILED) {
                          while (s9 !== peg$FAILED) {
                            s8.push(s9);
                            if (peg$c726.test(input.charAt(peg$currPos))) {
                              s9 = input.charAt(peg$currPos);
                              peg$currPos++;
                            } else {
                              s9 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c727); }
                            }
                          }
                        } else {
                          s8 = peg$FAILED;
                        }
                      }
                    }
                    if (s8 !== peg$FAILED) {
                      s9 = peg$parse_();
                      if (s9 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 4) === peg$c728) {
                          s10 = peg$c728;
                          peg$currPos += 4;
                        } else {
                          s10 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c729); }
                        }
                        if (s10 === peg$FAILED) {
                          s10 = null;
                        }
                        if (s10 !== peg$FAILED) {
                          if (input.charCodeAt(peg$currPos) === 41) {
                            s11 = peg$c21;
                            peg$currPos++;
                          } else {
                            s11 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c22); }
                          }
                          if (s11 !== peg$FAILED) {
                            s7 = [s7, s8, s9, s10, s11];
                            s6 = s7;
                          } else {
                            peg$currPos = s6;
                            s6 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s6;
                          s6 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s6;
                        s6 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s6;
                      s6 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s6;
                    s6 = peg$FAILED;
                  }
                }
              }
            }
          }
          if (s6 !== peg$FAILED) {
            s5 = [s5, s6];
            s4 = s5;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
      }
      if (s3 !== peg$FAILED) {
        s2 = [s2, s3];
        s1 = s2;
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c730();
    }
    s0 = s1;

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseGenericNameWord() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 140 + 95,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (peg$c691.test(input.charAt(peg$currPos))) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c692); }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      if (peg$c731.test(input.charAt(peg$currPos))) {
        s3 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c732); }
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        if (peg$c731.test(input.charAt(peg$currPos))) {
          s3 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c732); }
        }
      }
      if (s2 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 58) {
          s3 = peg$c733;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c734); }
        }
        if (s3 === peg$FAILED) {
          if (input.substr(peg$currPos, 3) === peg$c708) {
            s3 = peg$c708;
            peg$currPos += 3;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c709); }
          }
          if (s3 === peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 33) {
              s3 = peg$c735;
              peg$currPos++;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c736); }
            }
            if (s3 === peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 43) {
                s3 = peg$c471;
                peg$currPos++;
              } else {
                s3 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c472); }
              }
            }
          }
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          s1 = [s1, s2, s3];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseDuration() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 140 + 96,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3) === peg$c182) {
      s1 = peg$c182;
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c183); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseInteger();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 63) {
              s5 = peg$c394;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c395); }
            }
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseDurationUnits();
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c737(s3, s5, s7);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseDurationUnits() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 140 + 97,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$currPos;
    if (input.substr(peg$currPos, 6) === peg$c738) {
      s2 = peg$c738;
      peg$currPos += 6;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c739); }
    }
    if (s2 === peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c740) {
        s2 = peg$c740;
        peg$currPos += 4;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c741); }
      }
    }
    if (s2 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 115) {
        s3 = peg$c17;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c18); }
      }
      if (s3 === peg$FAILED) {
        s3 = null;
      }
      if (s3 !== peg$FAILED) {
        s2 = [s2, s3];
        s1 = s2;
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c143) {
        s1 = peg$c143;
        peg$currPos += 4;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c144); }
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c742();
    }
    s0 = s1;

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStat() {
    var s0, s1;

    var key    = peg$currPos * 140 + 98,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3) === peg$c160) {
      s1 = peg$c160;
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c161); }
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 3) === peg$c237) {
        s1 = peg$c237;
        peg$currPos += 3;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c238); }
      }
      if (s1 === peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c744) {
          s1 = peg$c744;
          peg$currPos += 3;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c745); }
        }
        if (s1 === peg$FAILED) {
          if (input.substr(peg$currPos, 3) === peg$c746) {
            s1 = peg$c746;
            peg$currPos += 3;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c747); }
          }
          if (s1 === peg$FAILED) {
            if (input.substr(peg$currPos, 3) === peg$c748) {
              s1 = peg$c748;
              peg$currPos += 3;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c749); }
            }
            if (s1 === peg$FAILED) {
              if (input.substr(peg$currPos, 3) === peg$c750) {
                s1 = peg$c750;
                peg$currPos += 3;
              } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c751); }
              }
              if (s1 === peg$FAILED) {
                if (input.substr(peg$currPos, 3) === peg$c752) {
                  s1 = peg$c752;
                  peg$currPos += 3;
                } else {
                  s1 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c753); }
                }
                if (s1 === peg$FAILED) {
                  if (input.substr(peg$currPos, 3) === peg$c754) {
                    s1 = peg$c754;
                    peg$currPos += 3;
                  } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c755); }
                  }
                }
              }
            }
          }
        }
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c756();
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c743); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseNextClause() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17, s18;

    var key    = peg$currPos * 140 + 99,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 44) {
      s1 = peg$c0;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 6) === peg$c387) {
          s3 = peg$c387;
          peg$currPos += 6;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c757); }
        }
        if (s3 === peg$FAILED) {
          if (input.substr(peg$currPos, 6) === peg$c678) {
            s3 = peg$c678;
            peg$currPos += 6;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c758); }
          }
          if (s3 === peg$FAILED) {
            if (input.substr(peg$currPos, 7) === peg$c287) {
              s3 = peg$c287;
              peg$currPos += 7;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c759); }
            }
            if (s3 === peg$FAILED) {
              s3 = peg$currPos;
              if (input.substr(peg$currPos, 7) === peg$c680) {
                s4 = peg$c680;
                peg$currPos += 7;
              } else {
                s4 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c760); }
              }
              if (s4 !== peg$FAILED) {
                s5 = peg$parse_();
                if (s5 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 6) === peg$c682) {
                    s6 = peg$c682;
                    peg$currPos += 6;
                  } else {
                    s6 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c683); }
                  }
                  if (s6 !== peg$FAILED) {
                    s4 = [s4, s5, s6];
                    s3 = s4;
                  } else {
                    peg$currPos = s3;
                    s3 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s3;
                  s3 = peg$FAILED;
                }
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
              if (s3 === peg$FAILED) {
                s3 = peg$currPos;
                if (input.substr(peg$currPos, 8) === peg$c296) {
                  s4 = peg$c296;
                  peg$currPos += 8;
                } else {
                  s4 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c761); }
                }
                if (s4 !== peg$FAILED) {
                  s5 = peg$parse_();
                  if (s5 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 2) === peg$c166) {
                      s6 = peg$c166;
                      peg$currPos += 2;
                    } else {
                      s6 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c167); }
                    }
                    if (s6 !== peg$FAILED) {
                      s4 = [s4, s5, s6];
                      s3 = s4;
                    } else {
                      peg$currPos = s3;
                      s3 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s3;
                    s3 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s3;
                  s3 = peg$FAILED;
                }
                if (s3 === peg$FAILED) {
                  s3 = peg$currPos;
                  if (input.substr(peg$currPos, 7) === peg$c271) {
                    s4 = peg$c271;
                    peg$currPos += 7;
                  } else {
                    s4 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c310); }
                  }
                  if (s4 !== peg$FAILED) {
                    s5 = peg$parse_();
                    if (s5 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 3) === peg$c186) {
                        s6 = peg$c186;
                        peg$currPos += 3;
                      } else {
                        s6 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c187); }
                      }
                      if (s6 !== peg$FAILED) {
                        s7 = peg$parse_();
                        if (s7 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 4) === peg$c467) {
                            s8 = peg$c467;
                            peg$currPos += 4;
                          } else {
                            s8 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c468); }
                          }
                          if (s8 !== peg$FAILED) {
                            s4 = [s4, s5, s6, s7, s8];
                            s3 = s4;
                          } else {
                            peg$currPos = s3;
                            s3 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s3;
                          s3 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s3;
                        s3 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s3;
                      s3 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s3;
                    s3 = peg$FAILED;
                  }
                  if (s3 === peg$FAILED) {
                    s3 = peg$currPos;
                    if (input.substr(peg$currPos, 5) === peg$c254) {
                      s4 = peg$c254;
                      peg$currPos += 5;
                    } else {
                      s4 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c255); }
                    }
                    if (s4 !== peg$FAILED) {
                      s5 = peg$parse_();
                      if (s5 !== peg$FAILED) {
                        if (input.substr(peg$currPos, 3) === peg$c186) {
                          s6 = peg$c186;
                          peg$currPos += 3;
                        } else {
                          s6 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c187); }
                        }
                        if (s6 !== peg$FAILED) {
                          s7 = peg$parse_();
                          if (s7 !== peg$FAILED) {
                            if (input.substr(peg$currPos, 4) === peg$c467) {
                              s8 = peg$c467;
                              peg$currPos += 4;
                            } else {
                              s8 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c468); }
                            }
                            if (s8 !== peg$FAILED) {
                              s4 = [s4, s5, s6, s7, s8];
                              s3 = s4;
                            } else {
                              peg$currPos = s3;
                              s3 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s3;
                            s3 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s3;
                          s3 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s3;
                        s3 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s3;
                      s3 = peg$FAILED;
                    }
                    if (s3 === peg$FAILED) {
                      s3 = peg$currPos;
                      if (input.substr(peg$currPos, 5) === peg$c330) {
                        s4 = peg$c330;
                        peg$currPos += 5;
                      } else {
                        s4 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c331); }
                      }
                      if (s4 !== peg$FAILED) {
                        s5 = peg$parse_();
                        if (s5 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 3) === peg$c186) {
                            s6 = peg$c186;
                            peg$currPos += 3;
                          } else {
                            s6 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c187); }
                          }
                          if (s6 !== peg$FAILED) {
                            s7 = peg$parse_();
                            if (s7 !== peg$FAILED) {
                              if (input.substr(peg$currPos, 4) === peg$c762) {
                                s8 = peg$c762;
                                peg$currPos += 4;
                              } else {
                                s8 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c763); }
                              }
                              if (s8 !== peg$FAILED) {
                                s9 = peg$parse_();
                                if (s9 !== peg$FAILED) {
                                  if (input.substr(peg$currPos, 7) === peg$c323) {
                                    s10 = peg$c323;
                                    peg$currPos += 7;
                                  } else {
                                    s10 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c324); }
                                  }
                                  if (s10 !== peg$FAILED) {
                                    s11 = peg$parse_();
                                    if (s11 !== peg$FAILED) {
                                      if (input.substr(peg$currPos, 4) === peg$c108) {
                                        s12 = peg$c108;
                                        peg$currPos += 4;
                                      } else {
                                        s12 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c109); }
                                      }
                                      if (s12 !== peg$FAILED) {
                                        s13 = peg$parse_();
                                        if (s13 !== peg$FAILED) {
                                          if (input.substr(peg$currPos, 2) === peg$c197) {
                                            s14 = peg$c197;
                                            peg$currPos += 2;
                                          } else {
                                            s14 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c198); }
                                          }
                                          if (s14 !== peg$FAILED) {
                                            s15 = peg$parse_();
                                            if (s15 !== peg$FAILED) {
                                              if (input.substr(peg$currPos, 2) === peg$c714) {
                                                s16 = peg$c714;
                                                peg$currPos += 2;
                                              } else {
                                                s16 = peg$FAILED;
                                                if (peg$silentFails === 0) { peg$fail(peg$c715); }
                                              }
                                              if (s16 !== peg$FAILED) {
                                                s17 = peg$parse_();
                                                if (s17 !== peg$FAILED) {
                                                  if (input.substr(peg$currPos, 4) === peg$c764) {
                                                    s18 = peg$c764;
                                                    peg$currPos += 4;
                                                  } else {
                                                    s18 = peg$FAILED;
                                                    if (peg$silentFails === 0) { peg$fail(peg$c765); }
                                                  }
                                                  if (s18 !== peg$FAILED) {
                                                    s4 = [s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17, s18];
                                                    s3 = s4;
                                                  } else {
                                                    peg$currPos = s3;
                                                    s3 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s3;
                                                  s3 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s3;
                                                s3 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s3;
                                              s3 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s3;
                                            s3 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s3;
                                          s3 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s3;
                                        s3 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s3;
                                      s3 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s3;
                                    s3 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s3;
                                  s3 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s3;
                                s3 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s3;
                              s3 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s3;
                            s3 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s3;
                          s3 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s3;
                        s3 = peg$FAILED;
                      }
                      if (s3 === peg$FAILED) {
                        if (input.substr(peg$currPos, 5) === peg$c409) {
                          s3 = peg$c409;
                          peg$currPos += 5;
                        } else {
                          s3 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c410); }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        if (s3 !== peg$FAILED) {
          s1 = [s1, s2, s3];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseWho() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 140 + 100,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2) === peg$c174) {
      s1 = peg$c174;
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c175); }
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c766) {
        s1 = peg$c766;
        peg$currPos += 4;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c767); }
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseWhoClause();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c768(s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseWhoClause() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    var key    = peg$currPos * 140 + 101,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3) === peg$c186) {
      s1 = peg$c186;
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c187); }
    }
    if (s1 === peg$FAILED) {
      s1 = null;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 4) === peg$c467) {
          s3 = peg$c467;
          peg$currPos += 4;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c468); }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c769();
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 3) === peg$c186) {
        s1 = peg$c186;
        peg$currPos += 3;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c187); }
      }
      if (s1 === peg$FAILED) {
        s1 = null;
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        if (s2 !== peg$FAILED) {
          if (input.substr(peg$currPos, 4) === peg$c467) {
            s3 = peg$c467;
            peg$currPos += 4;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c468); }
          }
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c769();
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 10) === peg$c770) {
          s1 = peg$c770;
          peg$currPos += 10;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c771); }
        }
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c772();
        }
        s0 = s1;
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.substr(peg$currPos, 11) === peg$c773) {
            s1 = peg$c773;
            peg$currPos += 11;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c774); }
          }
          if (s1 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c775();
          }
          s0 = s1;
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            if (input.substr(peg$currPos, 13) === peg$c776) {
              s1 = peg$c776;
              peg$currPos += 13;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c777); }
            }
            if (s1 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c778();
            }
            s0 = s1;
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              if (input.substr(peg$currPos, 10) === peg$c779) {
                s1 = peg$c779;
                peg$currPos += 10;
              } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c780); }
              }
              if (s1 !== peg$FAILED) {
                s2 = peg$currPos;
                s3 = peg$parse_();
                if (s3 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 6) === peg$c781) {
                    s4 = peg$c781;
                    peg$currPos += 6;
                  } else {
                    s4 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c782); }
                  }
                  if (s4 !== peg$FAILED) {
                    s5 = peg$parse_();
                    if (s5 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 5) === peg$c605) {
                        s6 = peg$c605;
                        peg$currPos += 5;
                      } else {
                        s6 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c606); }
                      }
                      if (s6 === peg$FAILED) {
                        if (input.substr(peg$currPos, 4) === peg$c783) {
                          s6 = peg$c783;
                          peg$currPos += 4;
                        } else {
                          s6 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c784); }
                        }
                        if (s6 === peg$FAILED) {
                          if (input.substr(peg$currPos, 11) === peg$c785) {
                            s6 = peg$c785;
                            peg$currPos += 11;
                          } else {
                            s6 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c786); }
                          }
                        }
                      }
                      if (s6 !== peg$FAILED) {
                        s7 = peg$parse_();
                        if (s7 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 3) === peg$c607) {
                            s8 = peg$c607;
                            peg$currPos += 3;
                          } else {
                            s8 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c608); }
                          }
                          if (s8 !== peg$FAILED) {
                            peg$savedPos = s2;
                            s3 = peg$c787(s6);
                            s2 = s3;
                          } else {
                            peg$currPos = s2;
                            s2 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s2;
                          s2 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s2;
                        s2 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s2;
                      s2 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s2;
                    s2 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s2;
                  s2 = peg$FAILED;
                }
                if (s2 === peg$FAILED) {
                  s2 = null;
                }
                if (s2 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c788(s2);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
              if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (input.substr(peg$currPos, 22) === peg$c789) {
                  s1 = peg$c789;
                  peg$currPos += 22;
                } else {
                  s1 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c790); }
                }
                if (s1 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c791();
                }
                s0 = s1;
                if (s0 === peg$FAILED) {
                  s0 = peg$currPos;
                  if (input.substr(peg$currPos, 19) === peg$c792) {
                    s1 = peg$c792;
                    peg$currPos += 19;
                  } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c793); }
                  }
                  if (s1 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c794();
                  }
                  s0 = s1;
                  if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    if (input.substr(peg$currPos, 28) === peg$c795) {
                      s1 = peg$c795;
                      peg$currPos += 28;
                    } else {
                      s1 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c796); }
                    }
                    if (s1 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c797();
                    }
                    s0 = s1;
                    if (s0 === peg$FAILED) {
                      s0 = peg$currPos;
                      if (input.substr(peg$currPos, 27) === peg$c798) {
                        s1 = peg$c798;
                        peg$currPos += 27;
                      } else {
                        s1 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c799); }
                      }
                      if (s1 !== peg$FAILED) {
                        s2 = peg$parse_();
                        if (s2 !== peg$FAILED) {
                          if (input.substr(peg$currPos, 6) === peg$c192) {
                            s3 = peg$c192;
                            peg$currPos += 6;
                          } else {
                            s3 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c193); }
                          }
                          if (s3 === peg$FAILED) {
                            s3 = null;
                          }
                          if (s3 !== peg$FAILED) {
                            s4 = peg$parse_();
                            if (s4 !== peg$FAILED) {
                              if (input.substr(peg$currPos, 7) === peg$c318) {
                                s5 = peg$c318;
                                peg$currPos += 7;
                              } else {
                                s5 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c319); }
                              }
                              if (s5 !== peg$FAILED) {
                                peg$savedPos = s0;
                                s1 = peg$c800();
                                s0 = s1;
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                      if (s0 === peg$FAILED) {
                        s0 = peg$currPos;
                        if (input.substr(peg$currPos, 21) === peg$c801) {
                          s1 = peg$c801;
                          peg$currPos += 21;
                        } else {
                          s1 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c802); }
                        }
                        if (s1 !== peg$FAILED) {
                          peg$savedPos = s0;
                          s1 = peg$c803();
                        }
                        s0 = s1;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseWhoList() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    var key    = peg$currPos * 140 + 102,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2) === peg$c174) {
      s1 = peg$c174;
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c175); }
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c766) {
        s1 = peg$c766;
        peg$currPos += 4;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c767); }
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseWhoClause();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = [];
            s6 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 47) {
              s7 = peg$c240;
              peg$currPos++;
            } else {
              s7 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c241); }
            }
            if (s7 !== peg$FAILED) {
              s8 = peg$parseWhoClause();
              if (s8 !== peg$FAILED) {
                s7 = [s7, s8];
                s6 = s7;
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
            if (s6 !== peg$FAILED) {
              while (s6 !== peg$FAILED) {
                s5.push(s6);
                s6 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 47) {
                  s7 = peg$c240;
                  peg$currPos++;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c241); }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseWhoClause();
                  if (s8 !== peg$FAILED) {
                    s7 = [s7, s8];
                    s6 = s7;
                  } else {
                    peg$currPos = s6;
                    s6 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s6;
                  s6 = peg$FAILED;
                }
              }
            } else {
              s5 = peg$FAILED;
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c333(s3, s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parsePerUses() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 140 + 103,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2) === peg$c511) {
      s1 = peg$c511;
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c512); }
    }
    if (s1 === peg$FAILED) {
      s1 = null;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 5) === peg$c247) {
          s3 = peg$c247;
          peg$currPos += 5;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c248); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseNumberString();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              if (s6 !== peg$FAILED) {
                if (input.substr(peg$currPos, 4) === peg$c500) {
                  s7 = peg$c500;
                  peg$currPos += 4;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c501); }
                }
                if (s7 === peg$FAILED) {
                  if (input.substr(peg$currPos, 11) === peg$c503) {
                    s7 = peg$c503;
                    peg$currPos += 11;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c504); }
                  }
                  if (s7 === peg$FAILED) {
                    if (input.substr(peg$currPos, 4) === peg$c349) {
                      s7 = peg$c349;
                      peg$currPos += 4;
                    } else {
                      s7 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c412); }
                    }
                  }
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c804(s5);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 2) === peg$c511) {
        s1 = peg$c511;
        peg$currPos += 2;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c512); }
      }
      if (s1 === peg$FAILED) {
        s1 = null;
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        if (s2 !== peg$FAILED) {
          if (input.substr(peg$currPos, 5) === peg$c247) {
            s3 = peg$c247;
            peg$currPos += 5;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c248); }
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            if (s4 !== peg$FAILED) {
              s5 = peg$parseOrdinal();
              if (s5 !== peg$FAILED) {
                s6 = peg$parse_();
                if (s6 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 3) === peg$c325) {
                    s7 = peg$c325;
                    peg$currPos += 3;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c326); }
                  }
                  if (s7 === peg$FAILED) {
                    if (input.substr(peg$currPos, 10) === peg$c805) {
                      s7 = peg$c805;
                      peg$currPos += 10;
                    } else {
                      s7 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c806); }
                    }
                    if (s7 === peg$FAILED) {
                      if (input.substr(peg$currPos, 4) === peg$c349) {
                        s7 = peg$c349;
                        peg$currPos += 4;
                      } else {
                        s7 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c412); }
                      }
                    }
                  }
                  if (s7 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c804(s5);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSkillType() {
    var s0, s1;

    var key    = peg$currPos * 140 + 104,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    if (input.substr(peg$currPos, 3) === peg$c808) {
      s0 = peg$c808;
      peg$currPos += 3;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c809); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 3) === peg$c810) {
        s0 = peg$c810;
        peg$currPos += 3;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c811); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c812) {
          s0 = peg$c812;
          peg$currPos += 3;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c813); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 3) === peg$c814) {
            s0 = peg$c814;
            peg$currPos += 3;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c815); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 3) === peg$c816) {
              s0 = peg$c816;
              peg$currPos += 3;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c817); }
            }
            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 3) === peg$c818) {
                s0 = peg$c818;
                peg$currPos += 3;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c819); }
              }
              if (s0 === peg$FAILED) {
                if (input.substr(peg$currPos, 3) === peg$c820) {
                  s0 = peg$c820;
                  peg$currPos += 3;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c821); }
                }
                if (s0 === peg$FAILED) {
                  s0 = peg$currPos;
                  if (input.substr(peg$currPos, 8) === peg$c67) {
                    s1 = peg$c67;
                    peg$currPos += 8;
                  } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c68); }
                  }
                  if (s1 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c822();
                  }
                  s0 = s1;
                }
              }
            }
          }
        }
      }
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c807); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSkillTypeList() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 140 + 105,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseSkillType();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parseOrList();
      if (s4 !== peg$FAILED) {
        s5 = peg$parseSkillType();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parseOrList();
        if (s4 !== peg$FAILED) {
          s5 = peg$parseSkillType();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c695(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c823); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseElement() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 140 + 106,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    if (input.substr(peg$currPos, 4) === peg$c825) {
      s0 = peg$c825;
      peg$currPos += 4;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c826); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 3) === peg$c827) {
        s0 = peg$c827;
        peg$currPos += 3;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c828); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 9) === peg$c829) {
          s0 = peg$c829;
          peg$currPos += 9;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c830); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 5) === peg$c831) {
            s0 = peg$c831;
            peg$currPos += 5;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c832); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 4) === peg$c833) {
              s0 = peg$c833;
              peg$currPos += 4;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c834); }
            }
            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 5) === peg$c835) {
                s0 = peg$c835;
                peg$currPos += 5;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c836); }
              }
              if (s0 === peg$FAILED) {
                if (input.substr(peg$currPos, 4) === peg$c837) {
                  s0 = peg$c837;
                  peg$currPos += 4;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c838); }
                }
                if (s0 === peg$FAILED) {
                  s0 = peg$currPos;
                  if (input.substr(peg$currPos, 4) === peg$c839) {
                    s1 = peg$c839;
                    peg$currPos += 4;
                  } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c840); }
                  }
                  if (s1 !== peg$FAILED) {
                    s2 = peg$currPos;
                    peg$silentFails++;
                    if (input.substr(peg$currPos, 4) === peg$c841) {
                      s3 = peg$c841;
                      peg$currPos += 4;
                    } else {
                      s3 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c842); }
                    }
                    peg$silentFails--;
                    if (s3 === peg$FAILED) {
                      s2 = void 0;
                    } else {
                      peg$currPos = s2;
                      s2 = peg$FAILED;
                    }
                    if (s2 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c843();
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                  if (s0 === peg$FAILED) {
                    if (input.substr(peg$currPos, 6) === peg$c844) {
                      s0 = peg$c844;
                      peg$currPos += 6;
                    } else {
                      s0 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c845); }
                    }
                    if (s0 === peg$FAILED) {
                      if (input.substr(peg$currPos, 2) === peg$c846) {
                        s0 = peg$c846;
                        peg$currPos += 2;
                      } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c847); }
                      }
                      if (s0 === peg$FAILED) {
                        s0 = peg$currPos;
                        if (input.substr(peg$currPos, 13) === peg$c848) {
                          s1 = peg$c848;
                          peg$currPos += 13;
                        } else {
                          s1 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c849); }
                        }
                        if (s1 !== peg$FAILED) {
                          peg$savedPos = s0;
                          s1 = peg$c850();
                        }
                        s0 = s1;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c824); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseElementList() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 140 + 107,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseElement();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parseOrList();
      if (s4 !== peg$FAILED) {
        s5 = peg$parseElement();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parseOrList();
        if (s4 !== peg$FAILED) {
          s5 = peg$parseElement();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c695(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c851); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseElementSlashList() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 140 + 108,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseElement();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 47) {
        s4 = peg$c240;
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c241); }
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parseElement();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 47) {
          s4 = peg$c240;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c241); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseElement();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c695(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c851); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSchool() {
    var s0, s1;

    var key    = peg$currPos * 140 + 109,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    if (input.substr(peg$currPos, 4) === peg$c853) {
      s0 = peg$c853;
      peg$currPos += 4;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c854); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 11) === peg$c722) {
        s0 = peg$c722;
        peg$currPos += 11;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c723); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 8) === peg$c855) {
          s0 = peg$c855;
          peg$currPos += 8;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c856); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 6) === peg$c857) {
            s0 = peg$c857;
            peg$currPos += 6;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c858); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 6) === peg$c859) {
              s0 = peg$c859;
              peg$currPos += 6;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c860); }
            }
            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 8) === peg$c861) {
                s0 = peg$c861;
                peg$currPos += 8;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c862); }
              }
              if (s0 === peg$FAILED) {
                if (input.substr(peg$currPos, 7) === peg$c863) {
                  s0 = peg$c863;
                  peg$currPos += 7;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c864); }
                }
                if (s0 === peg$FAILED) {
                  if (input.substr(peg$currPos, 5) === peg$c865) {
                    s0 = peg$c865;
                    peg$currPos += 5;
                  } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c866); }
                  }
                  if (s0 === peg$FAILED) {
                    if (input.substr(peg$currPos, 6) === peg$c867) {
                      s0 = peg$c867;
                      peg$currPos += 6;
                    } else {
                      s0 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c868); }
                    }
                    if (s0 === peg$FAILED) {
                      if (input.substr(peg$currPos, 9) === peg$c869) {
                        s0 = peg$c869;
                        peg$currPos += 9;
                      } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c870); }
                      }
                      if (s0 === peg$FAILED) {
                        if (input.substr(peg$currPos, 4) === peg$c871) {
                          s0 = peg$c871;
                          peg$currPos += 4;
                        } else {
                          s0 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c872); }
                        }
                        if (s0 === peg$FAILED) {
                          if (input.substr(peg$currPos, 5) === peg$c873) {
                            s0 = peg$c873;
                            peg$currPos += 5;
                          } else {
                            s0 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c874); }
                          }
                          if (s0 === peg$FAILED) {
                            if (input.substr(peg$currPos, 7) === peg$c875) {
                              s0 = peg$c875;
                              peg$currPos += 7;
                            } else {
                              s0 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c876); }
                            }
                            if (s0 === peg$FAILED) {
                              if (input.substr(peg$currPos, 12) === peg$c877) {
                                s0 = peg$c877;
                                peg$currPos += 12;
                              } else {
                                s0 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c878); }
                              }
                              if (s0 === peg$FAILED) {
                                if (input.substr(peg$currPos, 7) === peg$c879) {
                                  s0 = peg$c879;
                                  peg$currPos += 7;
                                } else {
                                  s0 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c880); }
                                }
                                if (s0 === peg$FAILED) {
                                  if (input.substr(peg$currPos, 10) === peg$c881) {
                                    s0 = peg$c881;
                                    peg$currPos += 10;
                                  } else {
                                    s0 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c882); }
                                  }
                                  if (s0 === peg$FAILED) {
                                    if (input.substr(peg$currPos, 9) === peg$c883) {
                                      s0 = peg$c883;
                                      peg$currPos += 9;
                                    } else {
                                      s0 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c884); }
                                    }
                                    if (s0 === peg$FAILED) {
                                      if (input.substr(peg$currPos, 7) === peg$c885) {
                                        s0 = peg$c885;
                                        peg$currPos += 7;
                                      } else {
                                        s0 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c886); }
                                      }
                                      if (s0 === peg$FAILED) {
                                        if (input.substr(peg$currPos, 5) === peg$c887) {
                                          s0 = peg$c887;
                                          peg$currPos += 5;
                                        } else {
                                          s0 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c888); }
                                        }
                                        if (s0 === peg$FAILED) {
                                          if (input.substr(peg$currPos, 11) === peg$c724) {
                                            s0 = peg$c724;
                                            peg$currPos += 11;
                                          } else {
                                            s0 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c725); }
                                          }
                                          if (s0 === peg$FAILED) {
                                            if (input.substr(peg$currPos, 5) === peg$c889) {
                                              s0 = peg$c889;
                                              peg$currPos += 5;
                                            } else {
                                              s0 = peg$FAILED;
                                              if (peg$silentFails === 0) { peg$fail(peg$c890); }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c852); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSchoolList() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 140 + 110,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseSchool();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parseOrList();
      if (s4 !== peg$FAILED) {
        s5 = peg$parseSchool();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parseOrList();
        if (s4 !== peg$FAILED) {
          s5 = peg$parseSchool();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c695(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c891); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSB() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 140 + 111,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4) === peg$c892) {
      s1 = peg$c892;
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c893); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 5) === peg$c894) {
          s3 = peg$c894;
          peg$currPos += 5;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c895); }
        }
        if (s3 !== peg$FAILED) {
          s1 = [s1, s2, s3];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c249) {
        s0 = peg$c249;
        peg$currPos += 2;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c250); }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseMaximum() {
    var s0, s1, s2;

    var key    = peg$currPos * 140 + 112,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    if (input.substr(peg$currPos, 7) === peg$c896) {
      s0 = peg$c896;
      peg$currPos += 7;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c897); }
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 3) === peg$c898) {
        s1 = peg$c898;
        peg$currPos += 3;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c899); }
      }
      if (s1 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 46) {
          s2 = peg$c2;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c3); }
        }
        if (s2 === peg$FAILED) {
          s2 = null;
        }
        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseUseCount() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 140 + 113,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseUseCountTerm();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 47) {
        s4 = peg$c240;
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c241); }
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parseUseCountTerm();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 47) {
          s4 = peg$c240;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c241); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseUseCountTerm();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = peg$currPos;
        s3 = peg$c900(s1, s2);
        if (s3) {
          s3 = void 0;
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c901(s1, s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseInteger();
      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 47) {
          s4 = peg$c240;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c241); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseInteger();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 47) {
            s4 = peg$c240;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c241); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parseInteger();
            if (s5 !== peg$FAILED) {
              s4 = [s4, s5];
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parse_();
          if (s3 === peg$FAILED) {
            s3 = null;
          }
          if (s3 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 43) {
              s4 = peg$c471;
              peg$currPos++;
            } else {
              s4 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c472); }
            }
            if (s4 !== peg$FAILED) {
              s5 = peg$parse_();
              if (s5 === peg$FAILED) {
                s5 = null;
              }
              if (s5 !== peg$FAILED) {
                s6 = peg$parseInteger();
                if (s6 !== peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 110) {
                    s7 = peg$c455;
                    peg$currPos++;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c456); }
                  }
                  if (s7 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c902(s1, s2, s6);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseUseCountTerm() {
    var s0, s1, s2, s3, s4;

    var key    = peg$currPos * 140 + 114,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseInteger();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 43) {
        s2 = peg$c471;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c472); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseInteger();
        if (s3 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 110) {
            s4 = peg$c455;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c456); }
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c903(s1, s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseRealm() {
    var s0, s1;

    var key    = peg$currPos * 140 + 115,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    if (input.substr(peg$currPos, 11) === peg$c905) {
      s0 = peg$c905;
      peg$currPos += 11;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c906); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 6) === peg$c907) {
        s0 = peg$c907;
        peg$currPos += 6;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c908); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 4) === peg$c909) {
          s0 = peg$c909;
          peg$currPos += 4;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c910); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c911) {
            s0 = peg$c911;
            peg$currPos += 2;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c912); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c913) {
              s0 = peg$c913;
              peg$currPos += 2;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c914); }
            }
            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 3) === peg$c915) {
                s0 = peg$c915;
                peg$currPos += 3;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c916); }
              }
              if (s0 === peg$FAILED) {
                if (input.substr(peg$currPos, 3) === peg$c917) {
                  s0 = peg$c917;
                  peg$currPos += 3;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c918); }
                }
                if (s0 === peg$FAILED) {
                  if (input.substr(peg$currPos, 2) === peg$c919) {
                    s0 = peg$c919;
                    peg$currPos += 2;
                  } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c920); }
                  }
                  if (s0 === peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 73) {
                      s0 = peg$c921;
                      peg$currPos++;
                    } else {
                      s0 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c922); }
                    }
                    if (s0 === peg$FAILED) {
                      if (input.substr(peg$currPos, 4) === peg$c923) {
                        s0 = peg$c923;
                        peg$currPos += 4;
                      } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c924); }
                      }
                      if (s0 === peg$FAILED) {
                        if (input.substr(peg$currPos, 3) === peg$c925) {
                          s0 = peg$c925;
                          peg$currPos += 3;
                        } else {
                          s0 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c926); }
                        }
                        if (s0 === peg$FAILED) {
                          if (input.substr(peg$currPos, 2) === peg$c927) {
                            s0 = peg$c927;
                            peg$currPos += 2;
                          } else {
                            s0 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c928); }
                          }
                          if (s0 === peg$FAILED) {
                            if (input.charCodeAt(peg$currPos) === 86) {
                              s0 = peg$c929;
                              peg$currPos++;
                            } else {
                              s0 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c930); }
                            }
                            if (s0 === peg$FAILED) {
                              if (input.substr(peg$currPos, 2) === peg$c931) {
                                s0 = peg$c931;
                                peg$currPos += 2;
                              } else {
                                s0 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c932); }
                              }
                              if (s0 === peg$FAILED) {
                                if (input.substr(peg$currPos, 3) === peg$c933) {
                                  s0 = peg$c933;
                                  peg$currPos += 3;
                                } else {
                                  s0 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c934); }
                                }
                                if (s0 === peg$FAILED) {
                                  if (input.substr(peg$currPos, 4) === peg$c935) {
                                    s0 = peg$c935;
                                    peg$currPos += 4;
                                  } else {
                                    s0 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c936); }
                                  }
                                  if (s0 === peg$FAILED) {
                                    if (input.substr(peg$currPos, 3) === peg$c937) {
                                      s0 = peg$c937;
                                      peg$currPos += 3;
                                    } else {
                                      s0 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c938); }
                                    }
                                    if (s0 === peg$FAILED) {
                                      if (input.substr(peg$currPos, 2) === peg$c939) {
                                        s0 = peg$c939;
                                        peg$currPos += 2;
                                      } else {
                                        s0 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c940); }
                                      }
                                      if (s0 === peg$FAILED) {
                                        if (input.charCodeAt(peg$currPos) === 88) {
                                          s0 = peg$c941;
                                          peg$currPos++;
                                        } else {
                                          s0 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c942); }
                                        }
                                        if (s0 === peg$FAILED) {
                                          if (input.substr(peg$currPos, 2) === peg$c943) {
                                            s0 = peg$c943;
                                            peg$currPos += 2;
                                          } else {
                                            s0 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c944); }
                                          }
                                          if (s0 === peg$FAILED) {
                                            if (input.substr(peg$currPos, 6) === peg$c945) {
                                              s0 = peg$c945;
                                              peg$currPos += 6;
                                            } else {
                                              s0 = peg$FAILED;
                                              if (peg$silentFails === 0) { peg$fail(peg$c946); }
                                            }
                                            if (s0 === peg$FAILED) {
                                              s0 = peg$currPos;
                                              if (input.substr(peg$currPos, 4) === peg$c947) {
                                                s1 = peg$c947;
                                                peg$currPos += 4;
                                              } else {
                                                s1 = peg$FAILED;
                                                if (peg$silentFails === 0) { peg$fail(peg$c948); }
                                              }
                                              if (s1 !== peg$FAILED) {
                                                peg$savedPos = s0;
                                                s1 = peg$c949();
                                              }
                                              s0 = s1;
                                              if (s0 === peg$FAILED) {
                                                s0 = peg$currPos;
                                                if (input.substr(peg$currPos, 4) === peg$c950) {
                                                  s1 = peg$c950;
                                                  peg$currPos += 4;
                                                } else {
                                                  s1 = peg$FAILED;
                                                  if (peg$silentFails === 0) { peg$fail(peg$c951); }
                                                }
                                                if (s1 !== peg$FAILED) {
                                                  peg$savedPos = s0;
                                                  s1 = peg$c952();
                                                }
                                                s0 = s1;
                                                if (s0 === peg$FAILED) {
                                                  s0 = peg$currPos;
                                                  if (input.substr(peg$currPos, 4) === peg$c953) {
                                                    s1 = peg$c953;
                                                    peg$currPos += 4;
                                                  } else {
                                                    s1 = peg$FAILED;
                                                    if (peg$silentFails === 0) { peg$fail(peg$c954); }
                                                  }
                                                  if (s1 !== peg$FAILED) {
                                                    peg$savedPos = s0;
                                                    s1 = peg$c955();
                                                  }
                                                  s0 = s1;
                                                  if (s0 === peg$FAILED) {
                                                    s0 = peg$currPos;
                                                    if (input.substr(peg$currPos, 4) === peg$c956) {
                                                      s1 = peg$c956;
                                                      peg$currPos += 4;
                                                    } else {
                                                      s1 = peg$FAILED;
                                                      if (peg$silentFails === 0) { peg$fail(peg$c957); }
                                                    }
                                                    if (s1 !== peg$FAILED) {
                                                      peg$savedPos = s0;
                                                      s1 = peg$c958();
                                                    }
                                                    s0 = s1;
                                                    if (s0 === peg$FAILED) {
                                                      s0 = peg$currPos;
                                                      if (input.substr(peg$currPos, 4) === peg$c959) {
                                                        s1 = peg$c959;
                                                        peg$currPos += 4;
                                                      } else {
                                                        s1 = peg$FAILED;
                                                        if (peg$silentFails === 0) { peg$fail(peg$c960); }
                                                      }
                                                      if (s1 !== peg$FAILED) {
                                                        peg$savedPos = s0;
                                                        s1 = peg$c961();
                                                      }
                                                      s0 = s1;
                                                      if (s0 === peg$FAILED) {
                                                        s0 = peg$currPos;
                                                        if (input.substr(peg$currPos, 4) === peg$c962) {
                                                          s1 = peg$c962;
                                                          peg$currPos += 4;
                                                        } else {
                                                          s1 = peg$FAILED;
                                                          if (peg$silentFails === 0) { peg$fail(peg$c963); }
                                                        }
                                                        if (s1 !== peg$FAILED) {
                                                          peg$savedPos = s0;
                                                          s1 = peg$c964();
                                                        }
                                                        s0 = s1;
                                                        if (s0 === peg$FAILED) {
                                                          s0 = peg$currPos;
                                                          if (input.substr(peg$currPos, 3) === peg$c965) {
                                                            s1 = peg$c965;
                                                            peg$currPos += 3;
                                                          } else {
                                                            s1 = peg$FAILED;
                                                            if (peg$silentFails === 0) { peg$fail(peg$c966); }
                                                          }
                                                          if (s1 !== peg$FAILED) {
                                                            peg$savedPos = s0;
                                                            s1 = peg$c967();
                                                          }
                                                          s0 = s1;
                                                          if (s0 === peg$FAILED) {
                                                            s0 = peg$currPos;
                                                            if (input.substr(peg$currPos, 3) === peg$c968) {
                                                              s1 = peg$c968;
                                                              peg$currPos += 3;
                                                            } else {
                                                              s1 = peg$FAILED;
                                                              if (peg$silentFails === 0) { peg$fail(peg$c969); }
                                                            }
                                                            if (s1 !== peg$FAILED) {
                                                              peg$savedPos = s0;
                                                              s1 = peg$c970();
                                                            }
                                                            s0 = s1;
                                                            if (s0 === peg$FAILED) {
                                                              s0 = peg$currPos;
                                                              if (input.substr(peg$currPos, 3) === peg$c971) {
                                                                s1 = peg$c971;
                                                                peg$currPos += 3;
                                                              } else {
                                                                s1 = peg$FAILED;
                                                                if (peg$silentFails === 0) { peg$fail(peg$c972); }
                                                              }
                                                              if (s1 !== peg$FAILED) {
                                                                peg$savedPos = s0;
                                                                s1 = peg$c973();
                                                              }
                                                              s0 = s1;
                                                              if (s0 === peg$FAILED) {
                                                                s0 = peg$currPos;
                                                                if (input.substr(peg$currPos, 3) === peg$c974) {
                                                                  s1 = peg$c974;
                                                                  peg$currPos += 3;
                                                                } else {
                                                                  s1 = peg$FAILED;
                                                                  if (peg$silentFails === 0) { peg$fail(peg$c975); }
                                                                }
                                                                if (s1 !== peg$FAILED) {
                                                                  peg$savedPos = s0;
                                                                  s1 = peg$c976();
                                                                }
                                                                s0 = s1;
                                                                if (s0 === peg$FAILED) {
                                                                  s0 = peg$currPos;
                                                                  if (input.substr(peg$currPos, 3) === peg$c977) {
                                                                    s1 = peg$c977;
                                                                    peg$currPos += 3;
                                                                  } else {
                                                                    s1 = peg$FAILED;
                                                                    if (peg$silentFails === 0) { peg$fail(peg$c978); }
                                                                  }
                                                                  if (s1 !== peg$FAILED) {
                                                                    peg$savedPos = s0;
                                                                    s1 = peg$c979();
                                                                  }
                                                                  s0 = s1;
                                                                  if (s0 === peg$FAILED) {
                                                                    s0 = peg$currPos;
                                                                    if (input.substr(peg$currPos, 3) === peg$c980) {
                                                                      s1 = peg$c980;
                                                                      peg$currPos += 3;
                                                                    } else {
                                                                      s1 = peg$FAILED;
                                                                      if (peg$silentFails === 0) { peg$fail(peg$c981); }
                                                                    }
                                                                    if (s1 !== peg$FAILED) {
                                                                      peg$savedPos = s0;
                                                                      s1 = peg$c982();
                                                                    }
                                                                    s0 = s1;
                                                                    if (s0 === peg$FAILED) {
                                                                      s0 = peg$currPos;
                                                                      if (input.substr(peg$currPos, 3) === peg$c983) {
                                                                        s1 = peg$c983;
                                                                        peg$currPos += 3;
                                                                      } else {
                                                                        s1 = peg$FAILED;
                                                                        if (peg$silentFails === 0) { peg$fail(peg$c984); }
                                                                      }
                                                                      if (s1 !== peg$FAILED) {
                                                                        peg$savedPos = s0;
                                                                        s1 = peg$c985();
                                                                      }
                                                                      s0 = s1;
                                                                      if (s0 === peg$FAILED) {
                                                                        s0 = peg$currPos;
                                                                        if (input.substr(peg$currPos, 3) === peg$c986) {
                                                                          s1 = peg$c986;
                                                                          peg$currPos += 3;
                                                                        } else {
                                                                          s1 = peg$FAILED;
                                                                          if (peg$silentFails === 0) { peg$fail(peg$c987); }
                                                                        }
                                                                        if (s1 !== peg$FAILED) {
                                                                          peg$savedPos = s0;
                                                                          s1 = peg$c988();
                                                                        }
                                                                        s0 = s1;
                                                                        if (s0 === peg$FAILED) {
                                                                          s0 = peg$currPos;
                                                                          if (input.substr(peg$currPos, 3) === peg$c989) {
                                                                            s1 = peg$c989;
                                                                            peg$currPos += 3;
                                                                          } else {
                                                                            s1 = peg$FAILED;
                                                                            if (peg$silentFails === 0) { peg$fail(peg$c990); }
                                                                          }
                                                                          if (s1 !== peg$FAILED) {
                                                                            peg$savedPos = s0;
                                                                            s1 = peg$c991();
                                                                          }
                                                                          s0 = s1;
                                                                          if (s0 === peg$FAILED) {
                                                                            s0 = peg$currPos;
                                                                            if (input.substr(peg$currPos, 5) === peg$c992) {
                                                                              s1 = peg$c992;
                                                                              peg$currPos += 5;
                                                                            } else {
                                                                              s1 = peg$FAILED;
                                                                              if (peg$silentFails === 0) { peg$fail(peg$c993); }
                                                                            }
                                                                            if (s1 !== peg$FAILED) {
                                                                              peg$savedPos = s0;
                                                                              s1 = peg$c994();
                                                                            }
                                                                            s0 = s1;
                                                                            if (s0 === peg$FAILED) {
                                                                              s0 = peg$currPos;
                                                                              if (input.substr(peg$currPos, 5) === peg$c995) {
                                                                                s1 = peg$c995;
                                                                                peg$currPos += 5;
                                                                              } else {
                                                                                s1 = peg$FAILED;
                                                                                if (peg$silentFails === 0) { peg$fail(peg$c996); }
                                                                              }
                                                                              if (s1 !== peg$FAILED) {
                                                                                peg$savedPos = s0;
                                                                                s1 = peg$c997();
                                                                              }
                                                                              s0 = s1;
                                                                              if (s0 === peg$FAILED) {
                                                                                s0 = peg$currPos;
                                                                                if (input.substr(peg$currPos, 6) === peg$c998) {
                                                                                  s1 = peg$c998;
                                                                                  peg$currPos += 6;
                                                                                } else {
                                                                                  s1 = peg$FAILED;
                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c999); }
                                                                                }
                                                                                if (s1 !== peg$FAILED) {
                                                                                  peg$savedPos = s0;
                                                                                  s1 = peg$c1000();
                                                                                }
                                                                                s0 = s1;
                                                                                if (s0 === peg$FAILED) {
                                                                                  s0 = peg$currPos;
                                                                                  if (input.substr(peg$currPos, 6) === peg$c1001) {
                                                                                    s1 = peg$c1001;
                                                                                    peg$currPos += 6;
                                                                                  } else {
                                                                                    s1 = peg$FAILED;
                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c1002); }
                                                                                  }
                                                                                  if (s1 !== peg$FAILED) {
                                                                                    peg$savedPos = s0;
                                                                                    s1 = peg$c1003();
                                                                                  }
                                                                                  s0 = s1;
                                                                                  if (s0 === peg$FAILED) {
                                                                                    s0 = peg$currPos;
                                                                                    if (input.substr(peg$currPos, 5) === peg$c1004) {
                                                                                      s1 = peg$c1004;
                                                                                      peg$currPos += 5;
                                                                                    } else {
                                                                                      s1 = peg$FAILED;
                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c1005); }
                                                                                    }
                                                                                    if (s1 !== peg$FAILED) {
                                                                                      peg$savedPos = s0;
                                                                                      s1 = peg$c1006();
                                                                                    }
                                                                                    s0 = s1;
                                                                                    if (s0 === peg$FAILED) {
                                                                                      s0 = peg$currPos;
                                                                                      if (input.substr(peg$currPos, 4) === peg$c1007) {
                                                                                        s1 = peg$c1007;
                                                                                        peg$currPos += 4;
                                                                                      } else {
                                                                                        s1 = peg$FAILED;
                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c1008); }
                                                                                      }
                                                                                      if (s1 !== peg$FAILED) {
                                                                                        peg$savedPos = s0;
                                                                                        s1 = peg$c1009();
                                                                                      }
                                                                                      s0 = s1;
                                                                                      if (s0 === peg$FAILED) {
                                                                                        s0 = peg$currPos;
                                                                                        if (input.substr(peg$currPos, 7) === peg$c1010) {
                                                                                          s1 = peg$c1010;
                                                                                          peg$currPos += 7;
                                                                                        } else {
                                                                                          s1 = peg$FAILED;
                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c1011); }
                                                                                        }
                                                                                        if (s1 !== peg$FAILED) {
                                                                                          peg$savedPos = s0;
                                                                                          s1 = peg$c1012();
                                                                                        }
                                                                                        s0 = s1;
                                                                                        if (s0 === peg$FAILED) {
                                                                                          s0 = peg$currPos;
                                                                                          if (input.substr(peg$currPos, 6) === peg$c1013) {
                                                                                            s1 = peg$c1013;
                                                                                            peg$currPos += 6;
                                                                                          } else {
                                                                                            s1 = peg$FAILED;
                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c1014); }
                                                                                          }
                                                                                          if (s1 !== peg$FAILED) {
                                                                                            peg$savedPos = s0;
                                                                                            s1 = peg$c1015();
                                                                                          }
                                                                                          s0 = s1;
                                                                                          if (s0 === peg$FAILED) {
                                                                                            s0 = peg$currPos;
                                                                                            if (input.substr(peg$currPos, 5) === peg$c1016) {
                                                                                              s1 = peg$c1016;
                                                                                              peg$currPos += 5;
                                                                                            } else {
                                                                                              s1 = peg$FAILED;
                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c1017); }
                                                                                            }
                                                                                            if (s1 !== peg$FAILED) {
                                                                                              peg$savedPos = s0;
                                                                                              s1 = peg$c1018();
                                                                                            }
                                                                                            s0 = s1;
                                                                                            if (s0 === peg$FAILED) {
                                                                                              s0 = peg$currPos;
                                                                                              if (input.substr(peg$currPos, 4) === peg$c1019) {
                                                                                                s1 = peg$c1019;
                                                                                                peg$currPos += 4;
                                                                                              } else {
                                                                                                s1 = peg$FAILED;
                                                                                                if (peg$silentFails === 0) { peg$fail(peg$c1020); }
                                                                                              }
                                                                                              if (s1 !== peg$FAILED) {
                                                                                                peg$savedPos = s0;
                                                                                                s1 = peg$c1021();
                                                                                              }
                                                                                              s0 = s1;
                                                                                              if (s0 === peg$FAILED) {
                                                                                                s0 = peg$currPos;
                                                                                                if (input.substr(peg$currPos, 5) === peg$c1022) {
                                                                                                  s1 = peg$c1022;
                                                                                                  peg$currPos += 5;
                                                                                                } else {
                                                                                                  s1 = peg$FAILED;
                                                                                                  if (peg$silentFails === 0) { peg$fail(peg$c1023); }
                                                                                                }
                                                                                                if (s1 !== peg$FAILED) {
                                                                                                  peg$savedPos = s0;
                                                                                                  s1 = peg$c1024();
                                                                                                }
                                                                                                s0 = s1;
                                                                                                if (s0 === peg$FAILED) {
                                                                                                  s0 = peg$currPos;
                                                                                                  if (input.substr(peg$currPos, 6) === peg$c1025) {
                                                                                                    s1 = peg$c1025;
                                                                                                    peg$currPos += 6;
                                                                                                  } else {
                                                                                                    s1 = peg$FAILED;
                                                                                                    if (peg$silentFails === 0) { peg$fail(peg$c1026); }
                                                                                                  }
                                                                                                  if (s1 !== peg$FAILED) {
                                                                                                    peg$savedPos = s0;
                                                                                                    s1 = peg$c1027();
                                                                                                  }
                                                                                                  s0 = s1;
                                                                                                  if (s0 === peg$FAILED) {
                                                                                                    s0 = peg$currPos;
                                                                                                    if (input.substr(peg$currPos, 7) === peg$c1028) {
                                                                                                      s1 = peg$c1028;
                                                                                                      peg$currPos += 7;
                                                                                                    } else {
                                                                                                      s1 = peg$FAILED;
                                                                                                      if (peg$silentFails === 0) { peg$fail(peg$c1029); }
                                                                                                    }
                                                                                                    if (s1 !== peg$FAILED) {
                                                                                                      peg$savedPos = s0;
                                                                                                      s1 = peg$c1030();
                                                                                                    }
                                                                                                    s0 = s1;
                                                                                                    if (s0 === peg$FAILED) {
                                                                                                      s0 = peg$currPos;
                                                                                                      if (input.substr(peg$currPos, 6) === peg$c1031) {
                                                                                                        s1 = peg$c1031;
                                                                                                        peg$currPos += 6;
                                                                                                      } else {
                                                                                                        s1 = peg$FAILED;
                                                                                                        if (peg$silentFails === 0) { peg$fail(peg$c1032); }
                                                                                                      }
                                                                                                      if (s1 !== peg$FAILED) {
                                                                                                        peg$savedPos = s0;
                                                                                                        s1 = peg$c1033();
                                                                                                      }
                                                                                                      s0 = s1;
                                                                                                      if (s0 === peg$FAILED) {
                                                                                                        s0 = peg$currPos;
                                                                                                        if (input.substr(peg$currPos, 5) === peg$c1034) {
                                                                                                          s1 = peg$c1034;
                                                                                                          peg$currPos += 5;
                                                                                                        } else {
                                                                                                          s1 = peg$FAILED;
                                                                                                          if (peg$silentFails === 0) { peg$fail(peg$c1035); }
                                                                                                        }
                                                                                                        if (s1 !== peg$FAILED) {
                                                                                                          peg$savedPos = s0;
                                                                                                          s1 = peg$c1036();
                                                                                                        }
                                                                                                        s0 = s1;
                                                                                                        if (s0 === peg$FAILED) {
                                                                                                          s0 = peg$currPos;
                                                                                                          if (input.substr(peg$currPos, 4) === peg$c1037) {
                                                                                                            s1 = peg$c1037;
                                                                                                            peg$currPos += 4;
                                                                                                          } else {
                                                                                                            s1 = peg$FAILED;
                                                                                                            if (peg$silentFails === 0) { peg$fail(peg$c1038); }
                                                                                                          }
                                                                                                          if (s1 !== peg$FAILED) {
                                                                                                            peg$savedPos = s0;
                                                                                                            s1 = peg$c1039();
                                                                                                          }
                                                                                                          s0 = s1;
                                                                                                          if (s0 === peg$FAILED) {
                                                                                                            s0 = peg$currPos;
                                                                                                            if (input.substr(peg$currPos, 7) === peg$c1040) {
                                                                                                              s1 = peg$c1040;
                                                                                                              peg$currPos += 7;
                                                                                                            } else {
                                                                                                              s1 = peg$FAILED;
                                                                                                              if (peg$silentFails === 0) { peg$fail(peg$c1041); }
                                                                                                            }
                                                                                                            if (s1 !== peg$FAILED) {
                                                                                                              peg$savedPos = s0;
                                                                                                              s1 = peg$c1000();
                                                                                                            }
                                                                                                            s0 = s1;
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c904); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseDamageCapValue() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 140 + 116,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4) === peg$c1042) {
      s1 = peg$c1042;
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1043); }
    }
    if (s1 === peg$FAILED) {
      s1 = peg$currPos;
      if (peg$c1044.test(input.charAt(peg$currPos))) {
        s2 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1045); }
      }
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 4) === peg$c1042) {
          s3 = peg$c1042;
          peg$currPos += 4;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1043); }
        }
        if (s3 !== peg$FAILED) {
          s2 = [s2, s3];
          s1 = s2;
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c1046();
    }
    s0 = s1;

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseAndList() {
    var s0, s1, s2, s3, s4;

    var key    = peg$currPos * 140 + 117,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 44) {
      s1 = peg$c0;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c8) {
          s3 = peg$c8;
          peg$currPos += 3;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c9); }
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s1 = [s1, s2, s3, s4];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parse_();
      if (s1 !== peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c8) {
          s2 = peg$c8;
          peg$currPos += 3;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c9); }
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parse_();
          if (s3 !== peg$FAILED) {
            s1 = [s1, s2, s3];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseOrList() {
    var s0, s1, s2, s3, s4;

    var key    = peg$currPos * 140 + 118,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 44) {
      s1 = peg$c0;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c61) {
          s3 = peg$c61;
          peg$currPos += 2;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c62); }
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          if (s4 !== peg$FAILED) {
            s1 = [s1, s2, s3, s4];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parse_();
      if (s1 !== peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c61) {
          s2 = peg$c61;
          peg$currPos += 2;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c62); }
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parse_();
          if (s3 !== peg$FAILED) {
            s1 = [s1, s2, s3];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseStatusListConjunction() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 140 + 119,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5) === peg$c1047) {
      s1 = peg$c1047;
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1048); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1049();
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parse_();
      if (s1 !== peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c8) {
          s2 = peg$c8;
          peg$currPos += 3;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c9); }
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parse_();
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c1049();
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parse_();
        if (s1 !== peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c61) {
            s2 = peg$c61;
            peg$currPos += 2;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c62); }
          }
          if (s2 !== peg$FAILED) {
            s3 = peg$parse_();
            if (s3 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c1050();
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 44) {
            s1 = peg$c0;
            peg$currPos++;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1); }
          }
          if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            if (s2 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c1051();
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 47) {
              s1 = peg$c240;
              peg$currPos++;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c241); }
            }
            if (s1 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c1052();
            }
            s0 = s1;
          }
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseNumberString() {
    var s0, s1, s2;

    var key    = peg$currPos * 140 + 120,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = [];
    if (peg$c1054.test(input.charAt(peg$currPos))) {
      s2 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1055); }
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        if (peg$c1054.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1055); }
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = peg$currPos;
      s2 = peg$c1056(s1);
      if (s2) {
        s2 = void 0;
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1057(s1);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1053); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseDecimalNumber() {
    var s0, s1, s2;

    var key    = peg$currPos * 140 + 121,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = [];
    if (peg$c1059.test(input.charAt(peg$currPos))) {
      s2 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1060); }
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        if (peg$c1059.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1060); }
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 63) {
        s1 = peg$c394;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c395); }
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c1061();
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1058); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseInteger() {
    var s0, s1, s2;

    var key    = peg$currPos * 140 + 122,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = [];
    if (peg$c1063.test(input.charAt(peg$currPos))) {
      s2 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1064); }
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        if (peg$c1063.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1064); }
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 63) {
        s1 = peg$c394;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c395); }
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c1065();
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1062); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parsePercentInteger() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 140 + 123,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$currPos;
    s2 = [];
    if (peg$c1063.test(input.charAt(peg$currPos))) {
      s3 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s3 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1064); }
    }
    if (s3 !== peg$FAILED) {
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        if (peg$c1063.test(input.charAt(peg$currPos))) {
          s3 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1064); }
        }
      }
    } else {
      s2 = peg$FAILED;
    }
    if (s2 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 37) {
        s3 = peg$c172;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c173); }
      }
      if (s3 !== peg$FAILED) {
        s2 = [s2, s3];
        s1 = s2;
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 63) {
        s1 = peg$c394;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c395); }
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c1065();
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1066); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSignedInteger() {
    var s0, s1, s2, s3, s4;

    var key    = peg$currPos * 140 + 124,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    if (peg$c720.test(input.charAt(peg$currPos))) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c721); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = [];
        if (peg$c1063.test(input.charAt(peg$currPos))) {
          s4 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1064); }
        }
        if (s4 !== peg$FAILED) {
          while (s4 !== peg$FAILED) {
            s3.push(s4);
            if (peg$c1063.test(input.charAt(peg$currPos))) {
              s4 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s4 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c1064); }
            }
          }
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c1068(s1, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1067); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseIntegerWithNegatives() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 140 + 125,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 45) {
      s1 = peg$c280;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c281); }
    }
    if (s1 === peg$FAILED) {
      s1 = null;
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      if (peg$c1063.test(input.charAt(peg$currPos))) {
        s3 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1064); }
      }
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          if (peg$c1063.test(input.charAt(peg$currPos))) {
            s3 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1064); }
          }
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1070(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1069); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseDecimalNumberSlashList() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 140 + 126,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseDecimalNumber();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 47) {
        s4 = peg$c240;
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c241); }
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parseDecimalNumber();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 47) {
          s4 = peg$c240;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c241); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseDecimalNumber();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1072(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1071); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseDecimalNumberPercentSlashList() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var key    = peg$currPos * 140 + 127,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseDecimalNumber();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 37) {
        s2 = peg$c172;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c173); }
      }
      if (s2 === peg$FAILED) {
        s2 = null;
      }
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 47) {
          s5 = peg$c240;
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c241); }
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$parseDecimalNumber();
          if (s6 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 37) {
              s7 = peg$c172;
              peg$currPos++;
            } else {
              s7 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c173); }
            }
            if (s7 === peg$FAILED) {
              s7 = null;
            }
            if (s7 !== peg$FAILED) {
              s5 = [s5, s6, s7];
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          s4 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 47) {
            s5 = peg$c240;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c241); }
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parseDecimalNumber();
            if (s6 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 37) {
                s7 = peg$c172;
                peg$currPos++;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c173); }
              }
              if (s7 === peg$FAILED) {
                s7 = null;
              }
              if (s7 !== peg$FAILED) {
                s5 = [s5, s6, s7];
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c1072(s1, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1071); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseIntegerSlashList() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 140 + 128,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseInteger();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 47) {
        s4 = peg$c240;
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c241); }
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parseInteger();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 47) {
          s4 = peg$c240;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c241); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseInteger();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1072(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1073); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseIntegerSlashListOrRange() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 140 + 129,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseInteger();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 45) {
        s2 = peg$c280;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c281); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseInteger();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c1075(s1, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$parseIntegerSlashList();
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1074); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parsePercentSlashList() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 140 + 130,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parsePercentInteger();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 47) {
        s4 = peg$c240;
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c241); }
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parsePercentInteger();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 47) {
          s4 = peg$c240;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c241); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parsePercentInteger();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1072(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseIntegerSlashList();
      if (s1 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 37) {
          s2 = peg$c172;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c173); }
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c1077(s1);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1076); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseIntegerRangeSlashList() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 140 + 131,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseIntegerOrRange();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 47) {
        s4 = peg$c240;
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c241); }
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parseIntegerOrRange();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 47) {
          s4 = peg$c240;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c241); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseIntegerOrRange();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1072(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1078); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseIntegerOrRange() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 140 + 132,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    s1 = peg$parseInteger();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 45) {
        s2 = peg$c280;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c281); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseInteger();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c1079(s1, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$parseInteger();
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseSignedIntegerSlashList() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 140 + 133,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    if (peg$c720.test(input.charAt(peg$currPos))) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c721); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseIntegerSlashList();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c1081(s1, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1080); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseIntegerWithNegativesSlashList() {
    var s0, s1, s2, s3, s4, s5;

    var key    = peg$currPos * 140 + 134,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseIntegerWithNegatives();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 47) {
        s4 = peg$c240;
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c241); }
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parseIntegerWithNegatives();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 47) {
          s4 = peg$c240;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c241); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseIntegerWithNegatives();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1072(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1082); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseIntegerAndList() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    var key    = peg$currPos * 140 + 135,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseInteger();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 44) {
        s5 = peg$c0;
        peg$currPos++;
      } else {
        s5 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1); }
      }
      if (s5 === peg$FAILED) {
        s5 = null;
      }
      if (s5 !== peg$FAILED) {
        s6 = peg$parse_();
        if (s6 !== peg$FAILED) {
          if (input.substr(peg$currPos, 3) === peg$c8) {
            s7 = peg$c8;
            peg$currPos += 3;
          } else {
            s7 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c9); }
          }
          if (s7 !== peg$FAILED) {
            s8 = peg$parse_();
            if (s8 !== peg$FAILED) {
              s5 = [s5, s6, s7, s8];
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
      } else {
        peg$currPos = s4;
        s4 = peg$FAILED;
      }
      if (s4 === peg$FAILED) {
        s4 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 44) {
          s5 = peg$c0;
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1); }
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          if (s6 !== peg$FAILED) {
            s5 = [s5, s6];
            s4 = s5;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parseInteger();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 44) {
          s5 = peg$c0;
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1); }
        }
        if (s5 === peg$FAILED) {
          s5 = null;
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          if (s6 !== peg$FAILED) {
            if (input.substr(peg$currPos, 3) === peg$c8) {
              s7 = peg$c8;
              peg$currPos += 3;
            } else {
              s7 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c9); }
            }
            if (s7 !== peg$FAILED) {
              s8 = peg$parse_();
              if (s8 !== peg$FAILED) {
                s5 = [s5, s6, s7, s8];
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        if (s4 === peg$FAILED) {
          s4 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 44) {
            s5 = peg$c0;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1); }
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            if (s6 !== peg$FAILED) {
              s5 = [s5, s6];
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseInteger();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1072(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1083); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseOccurrence() {
    var s0, s1, s2, s3, s4;

    var key    = peg$currPos * 140 + 136,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4) === peg$c1084) {
      s1 = peg$c1084;
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1085); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c1086();
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 5) === peg$c1087) {
        s1 = peg$c1087;
        peg$currPos += 5;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1088); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1089();
      }
      s0 = s1;
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseNumberString();
        if (s1 === peg$FAILED) {
          s1 = peg$parseInteger();
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          if (s2 !== peg$FAILED) {
            if (input.substr(peg$currPos, 4) === peg$c141) {
              s3 = peg$c141;
              peg$currPos += 4;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c142); }
            }
            if (s3 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 115) {
                s4 = peg$c17;
                peg$currPos++;
              } else {
                s4 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c18); }
              }
              if (s4 === peg$FAILED) {
                s4 = null;
              }
              if (s4 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c1090(s1);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseOrdinal() {
    var s0, s1;

    var key    = peg$currPos * 140 + 137,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5) === peg$c513) {
      s1 = peg$c513;
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c514); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c1086();
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 6) === peg$c738) {
        s1 = peg$c738;
        peg$currPos += 6;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c739); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1089();
      }
      s0 = s1;
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 5) === peg$c1091) {
          s1 = peg$c1091;
          peg$currPos += 5;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1092); }
        }
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c1093();
        }
        s0 = s1;
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.substr(peg$currPos, 6) === peg$c1094) {
            s1 = peg$c1094;
            peg$currPos += 6;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1095); }
          }
          if (s1 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c1096();
          }
          s0 = s1;
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            if (input.substr(peg$currPos, 5) === peg$c1097) {
              s1 = peg$c1097;
              peg$currPos += 5;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c1098); }
            }
            if (s1 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c1099();
            }
            s0 = s1;
          }
        }
      }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parseUppercaseWord() {
    var s0, s1, s2, s3;

    var key    = peg$currPos * 140 + 138,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    s0 = peg$currPos;
    if (peg$c691.test(input.charAt(peg$currPos))) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c692); }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      if (peg$c1100.test(input.charAt(peg$currPos))) {
        s3 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1101); }
      }
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          if (peg$c1100.test(input.charAt(peg$currPos))) {
            s3 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c1101); }
          }
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c457();
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }

  function peg$parse_() {
    var s0, s1;

    var key    = peg$currPos * 140 + 139,
        cached = peg$resultsCache[key];

    if (cached) {
      peg$currPos = cached.nextPos;

      return cached.result;
    }

    peg$silentFails++;
    s0 = [];
    if (peg$c1103.test(input.charAt(peg$currPos))) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1104); }
    }
    while (s1 !== peg$FAILED) {
      s0.push(s1);
      if (peg$c1103.test(input.charAt(peg$currPos))) {
        s1 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c1104); }
      }
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c1102); }
    }

    peg$resultsCache[key] = { nextPos: peg$currPos, result: s0 };

    return s0;
  }


    let parsedNumberResult = null;
    let lastDamageDuringStatus = NaN;
    let lastDamageDuringStatusElement = undefined;
    let statusLevelMatch = null;

    // Hack: Suppress warnings about unused functions.
    location;
    expected;
    error;
    peg$anyExpectation;


  peg$result = peg$startRuleFunction();

  if (peg$result !== peg$FAILED && peg$currPos === input.length) {
    return peg$result;
  } else {
    if (peg$result !== peg$FAILED && peg$currPos < input.length) {
      peg$fail(peg$endExpectation());
    }

    throw peg$buildStructuredError(
      peg$maxFailExpected,
      peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
      peg$maxFailPos < input.length
        ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
        : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
    );
  }
}

module.exports = {
  SyntaxError: peg$SyntaxError,
  parse:       peg$parse
};
